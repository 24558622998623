import AllVendorsContainer from 'components/modules/Vendors/All/All';

/**
 * Primary UI component for user interaction
 */
const AllVendors = () => {
  return <AllVendorsContainer />;
};

export default AllVendors;
