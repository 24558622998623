import React from 'react';
import classNames from 'classnames';
import { Classes } from '../../../../../shared/configs/componentsClasses';

type LABELTYPE = 'default' | 'black' | 'smallSelect' | 'blackSmall' | undefined;

interface LabelProps {
  /**
   * Set the Label Type
   */
  labelType?: LABELTYPE;
  /**
   * Label name
   */
  name: string | undefined;
  /**
   * Label contents
   */
  label?: string | undefined;
  /**
   * Add children
   */
  children?: React.ReactNode;
  /**
   * Optional for additional classes
   */
  additionalClasses?: string | undefined;
  /**
   * inner span Classes
   */
  labelSpanClasses?: string;
}

const handleLabelClasses = (_labelTYpe?: LABELTYPE) => {
  switch (_labelTYpe) {
    case 'default':
      return Classes.label.default;
    case 'black':
      return Classes.label.black;
    case 'blackSmall':
      return Classes.label.blackSmall;
    case 'smallSelect':
      return Classes.label.smallSelect;
    default:
      break;
  }
};

/**
 * Primary UI component for user interaction
 */
const Label: React.FC<LabelProps> = ({
  labelType = 'default',
  name,
  label = 'Label',
  additionalClasses,
  labelSpanClasses,
  children,
  ...rest
}) => {
  const labelClasses = [handleLabelClasses(labelType), additionalClasses];

  return (
    <label htmlFor={name} className={classNames(labelClasses)} {...rest}>
      <span className={classNames([labelSpanClasses, 'text-inherit font-inherit'])}>
        {children ? children : label}
      </span>
    </label>
  );
};

export default Label;
