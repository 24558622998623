import { CONFIG } from '../shared/configs/config';

export const useCurrencyFormat = (value: any) => {
  const converttoNum = Number(value ?? 0.0);
  return CONFIG.BASE_CURRENCY + converttoNum.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

export const useCurrencyFormatWithoutCents = (value: any) => {
  const converttoNum = Number(value ?? 0.0);
  return CONFIG.BASE_CURRENCY + converttoNum.toFixed().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};
