import React, { useState } from 'react';
import Wrapper from '../../layouts/Wrapper/Wrapper';
import Headers from '../../core/Headers/Headers';
import SuperTable from '../../core/SuperTable/SuperTable';
import { useGetPropertiesQuery } from '../../../services/properties';
import {
  useGetGroupedBatchesQuery,
  useReconcileCheckBatchMutation,
} from '../../../services/resmanPayablesApi';
import uuid from 'react-uuid';
import { Button as NewButton } from '../../core/Button/Button';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';
import { ToolBar } from '../../core';
const ReconciliationContainer = () => {
  const propertiesFilterParam = {
    limit: 1000,
    offset: 0,
    orderBy: 'CREATED_AT_ASC',
  };
  const { data: propertyData } = useGetPropertiesQuery(propertiesFilterParam);
  const [mutation] = useReconcileCheckBatchMutation();
  const [entries, setEntries] = useState([]);
  const { data: groupedBatches } = useGetGroupedBatchesQuery({
    first: 200,
    offset: 0,
    orderBy: 'CREATED_AT_ASC',
    /* eslint-disable no-useless-escape */
    filter: {
      pay_id: { in: entries.map((value: any) => `\"${value.Batch}\"`) },
      paid_by: { isNull: false },
    },
  });

  const csvToJson = (data: string, delimiter = ',') => {
    const titles = data.slice(0, data.indexOf('\n')).split(delimiter);
    return data
      .slice(data.indexOf('\n') + 1)
      .split('\n')
      .map((v) => {
        const values = v.split(delimiter);
        return titles.reduce(
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          // eslint-disable-next-line no-sequences
          (obj, title, index) => ((obj[title] = values[index]), obj),
          {}
        );
      });
  };
  const columns = React.useMemo(
    () => [
      {
        Header: 'Description',
        accessor: 'Description',
        filter: 'fuzzy',
      },
      {
        Header: 'Batch',
        accessor: 'Batch',
      },
      {
        Header: 'Property ',
        accessor: (row: any) => row?.Property,
        filter: 'fuzzy',
        Cell: (row: any) => {
          const property = row.value;
          if (property) {
            return property.name;
          }
          const options = propertyData?.properties?.nodes.map((p: any) => {
            return {
              value: p.id,
              label: p.name,
            };
          });
          return (
            <select className="w-[300px] h-8 mt-0 border border-none rounded-lg shadow-none form-select text-12s text-black-760 appearence-none placeholderColor-gray bg-gray-110">
              {options?.map((option: any) => (
                <option key={uuid()} value={option.id}>
                  {option.label}
                </option>
              ))}
            </select>
          );
        },
      },
      {
        Header: 'Reconcile Status',
        accessor: (row: any) => {
          const data = row;
          const matchedKey = groupedBatches?.resmanAccountsPayableData?.groupedAggregates?.find(
            (key: any) => {
              return key.keys[0] === data?.Batch && key.keys[1] === data?.Property?.id;
            }
          );
          if (matchedKey) {
            return 'Reconciled';
          } else {
            return 'na';
          }
        },
        Cell: (row: any) => {
          if (row.value === 'Reconciled') {
            return row.value;
          } else {
            return (
              <NewButton
                variants="extraSmall"
                additionalClasses="w-[114px] ml-2"
                buttonLabel={'Reconcile'}
                onClick={async () => {
                  const { Batch: batch, Property: property } = row.row.original;
                  const res: FetchBaseQueryError | SerializedError | any = await mutation({
                    data: [
                      {
                        pay_ids: [batch],
                        paid_with: 'check',
                        property_id: property?.id,
                      },
                    ],
                  });
                  if (!res.data[0].success) {
                    alert('Error in Reconcilition. Reach out to Peeyush');
                  }
                }}
              />
            );
          }
        },
      },
    ],
    [groupedBatches]
  );
  const data = React.useMemo(() => entries, [entries]);
  const [defaultSortOrder] = useState({ id: 'number', desc: true });
  return (
    <Wrapper additionalClasses="flex-col items-start" pageTitle="Reconciliation">
      <Headers headingContent="Reconciliation" />
      <ToolBar
        navigationData={[
          {
            id: 1,
            urlPath: '/reconcile/checks',
            navTitle: 'Mercury',
            navClasses: 'active',
          },
          {
            id: 2,
            urlPath: '/reconcile/ach',
            navTitle: 'ACH',
            navClasses: 'inactive ',
          },
          {
            id: 3,
            urlPath: '/reconcile/manual',
            navTitle: 'Manual',
            navClasses: 'inactive ',
          },
        ]}
        isShowOptionEnabled={false}
        isCountVisible={false}
      />
      <Headers
        headingContent=""
        isFileUpload
        buttonLabel={'Choose file'}
        onChange={(event: { target: { files: any } }) => {
          const files = event?.target?.files;
          if (files) {
            const reader = new FileReader();
            reader.addEventListener('load', (event) => {
              const csvData = atob((event?.target?.result as string).split(',')[1]);
              const mercuryEntries = csvToJson(csvData);
              setEntries(
                (mercuryEntries as [])
                  .filter((value: any) => {
                    return /^\d/.test(value.Description) && value.Status === 'Sent';
                  })
                  .map((value: any) => {
                    const tokens = value.Description.split(' ');
                    value.Batch = tokens[0];
                    value.Property_name = value.Description.substring(tokens[0].length)
                      .replace('-', ' ')
                      .trim();
                    value.Property = propertyData?.properties?.nodes.find((p: any) => {
                      return p.name.toLowerCase().indexOf(value.Property_name.toLowerCase()) > -1;
                    });
                    if (!value.Property) {
                      value.Property_name = value.Property_name.split('(')[0].trim();
                      value.Property = propertyData?.properties?.nodes.find((p: any) => {
                        return p.name.toLowerCase().indexOf(value.Property_name.toLowerCase()) > -1;
                      });
                    }
                    return value;
                  }) as []
              );
            });
            reader.readAsDataURL(files[0]);
          }
        }}
      />
      <SuperTable
        columns={columns}
        hiddenColumns={[]}
        data={data}
        defaultSortBy={defaultSortOrder}
        paginationOptions={[50, 100, 200, 400, 1000, 2000, 4000, 6000]}
        tableWrapperType="grid-height-large"
        isPagination
      ></SuperTable>
    </Wrapper>
  );
};

export default ReconciliationContainer;
