import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useSortBy, useTable, useFilters, usePagination, useRowSelect } from 'react-table';

import Icon from '../Media/Icon/Icon';
import Pagination from './Pagination/Pagination';
import IndeterminateCheckbox from 'components/modules/Vendors/Account/ImportedInvoices/Table/IndeterminateCheckbox';

import {
  dateBetweenFilterFn,
  fuzzyTextFilterFn,
  DefaultColumnFilter,
} from './CellElements/Filters';
import { DataListListProps } from 'shared/models/components/base.model';
import { TimeLineModal } from '../Modals/TimeLineModal/TimeLineModal';

// Let the table remove the filter if the string is empty
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
fuzzyTextFilterFn.autoRemove = (val: any) => !val;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
function Table({
  columns,
  data,
  hiddenColumns,
  setFilteredRow,
  isPagination,
  paginationOptions,
  setVisibleColumns,
  enableSelect,
  setSelectedRows,
  tableWrapperType,
  handleInvoiceLinkClick,
  columnOrder,
  tableType,
  isServerSidePagination,
  previousPageClick,
  nextPageClick,
  pageSizeVal = 0,
  setPageSizeValue,
  totalPageCount = 0,
  pageIndexVal = 0,
}: DataListListProps) {
  const selectedValuesForTimeLineInitialState = {
    id: '',
    type: '',
  };
  const [timeLineModalOpen, setTimeLineModalOpen] = useState<boolean>(false);
  const [selectedValuesForTimeLine, setSelectedValuesForTimeLine] = useState<{
    id: string;
    type: string;
  }>(selectedValuesForTimeLineInitialState);

  const handleSorting = () => {
    switch (tableType) {
      case 'approve':
        return [{ id: 'invoiced_at', desc: true }];
      case 'vendor':
        return [{ id: 'VENDOR', desc: false }];
      case 'properties':
        return [{ id: 'name', desc: false }];
      case 'transactions':
        return [{ id: 'created_at', desc: true }];
      default:
        return [];
    }
  };

  const filterTypes = React.useMemo(
    () => ({
      fuzzy: fuzzyTextFilterFn,
      text: (rows: any, id: any, filterValue: any) => {
        return rows.filter((row: any) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
      multiSelect: (rows: any, id: any, filterValues: any) => {
        if (filterValues.length === 0) return rows;
        return rows.filter((r: any) => filterValues.includes(r.values[id]));
      },
      dateBetween: dateBetweenFilterFn,
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
      Header: '',
    }),
    []
  );

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    nextPage,
    previousPage,
    prepareRow,
    state,
    pageOptions,
    setPageSize,
    canNextPage,
    canPreviousPage,
    // filteredRows,
    visibleColumns,
    selectedFlatRows,
  } = useTable(
    {
      columns,
      data,
      autoResetFilters: false,
      initialState: {
        pageSize: paginationOptions[0],
        hiddenColumns,
        sortBy: handleSorting(),
        columnOrder: columnOrder,
      },
      defaultColumn: defaultColumn,
      filterTypes,
      manualPagination: isServerSidePagination,
    },
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      enableSelect &&
        hooks.visibleColumns.push((columns) => [
          // Let's make a column for selection
          {
            id: 'selection',
            disableSortBy: true,
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <div>
                <IndeterminateCheckbox
                  className="grid-checkbox"
                  name={'select-all'}
                  {...getToggleAllRowsSelectedProps()}
                />
              </div>
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }) => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              const status = row.original?.status;
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              const isTransferpaidAt = !row.original?.paid_at && tableType === 'transactions';
              const IsTransferVailable =
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                row.original?.transfer &&
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                row.original?.transfer?.id &&
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                !row.original?.transfer?.paid_at;
              const isPayoutEnabledinTransactionModalGrid =
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                row.original?.account && row.original?.account?.payouts_enabled;
              const isModalTranferPayoutEnabled =
                isPayoutEnabledinTransactionModalGrid &&
                tableType === 'transactionsModal' &&
                IsTransferVailable;
              return status === 'APPROVED' || isTransferpaidAt || isModalTranferPayoutEnabled ? (
                <div>
                  <IndeterminateCheckbox
                    className="grid-checkbox grid-checkbox-row"
                    name={'row-checkbox'}
                    {...row.getToggleRowSelectedProps()}
                  />
                </div>
              ) : (
                <input
                  className="invisible grid-checkbox"
                  type="checkbox"
                  disabled={true}
                  checked={false}
                />
              );
            },
          },
          ...columns,
        ]);
    }
  );

  const { pageIndex, pageSize } = state;

  useEffect(() => {
    if (setFilteredRow && rows) {
      setFilteredRow && setFilteredRow(rows);
    }
  }, [rows]);

  useEffect(() => {
    setVisibleColumns && setVisibleColumns(visibleColumns);
  }, [visibleColumns]);

  useEffect(() => {
    if (setSelectedRows) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setSelectedRows(
        selectedFlatRows.filter(
          (item) =>
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            item?.original?.status === 'APPROVED' ||
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            (!item.original?.paid_at && tableType === 'transactions') ||
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            (item.original?.account &&
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              item.original?.transfer &&
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              item.original?.transfer?.id &&
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              !item.original?.transfer?.paid_at &&
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              item.original?.account?.payouts_enabled &&
              tableType === 'transactionsModal')
        )
      );
    }
  }, [selectedFlatRows]);

  const onCloseEvent = () => {
    setTimeLineModalOpen(false);
    setSelectedValuesForTimeLine(selectedValuesForTimeLineInitialState);
  };

  const onTriggerTimeLine = (id: string, type: 'batch' | 'invoice') => {
    if (id) {
      setSelectedValuesForTimeLine({
        id: id,
        type: type,
      });
      setTimeLineModalOpen(true);
    } else {
      onCloseEvent();
    }
  };

  // Render the UI for your table
  return (
    <>
      <div
        className={`flex flex-col w-full pt-6 border-t border-solid border-grey-100 ${tableWrapperType}`}
      >
        <div
          className={`opacity-100 w-full min-h-[180px] ease-out duration-300 flex flex-col overflow-auto border-t border-solid border-white pb-2 table-grid`}
        >
          <table {...getTableProps()} className="w-auto pt-[60px]">
            <thead className="sticky top-0 z-10 bg-white">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any, index) => {
                    // Add the sorting props to control sorting. For this example
                    // we can add them into the header props
                    const borderClassforTh = index !== 0 ? '' : '';
                    const getWrapperclass = column.headerClasses ? column.headerClasses : '';
                    const getInnerWrapperclass = column.headerInnerClasses
                      ? column.headerInnerClasses
                      : '';

                    return (
                      <th className="border-b border-solid border-grey-100" key={index}>
                        <div
                          className={classNames([
                            'flex flex-row items-center h-[48px] pl-1 pr-1',
                            borderClassforTh,
                            getWrapperclass,
                          ])}
                        >
                          <div className={classNames(['flex flex-col', getInnerWrapperclass])}>
                            {column.filter ? (
                              <div className="block w-full text-12s table-filter">
                                {column.filter ? column.render('Filter') : null}
                              </div>
                            ) : (
                              <span className="font-normal text-left text-12s text-grey-600">
                                {column.render('Header')}
                              </span>
                            )}
                          </div>
                          <span
                            className="ml-2"
                            {...column.getHeaderProps(column.getSortByToggleProps())}
                          >
                            {!column.disableSortBy &&
                              (column.isSorted ? (
                                column.isSortedDesc ? (
                                  <span className="text-grey-900">
                                    <Icon
                                      iconColor="inherit"
                                      iconType={
                                        column.isReverseSortingIcon
                                          ? 'OrderIconDESC'
                                          : 'OrderIconASC'
                                      }
                                    />
                                  </span>
                                ) : (
                                  <span className="text-grey-900">
                                    <Icon
                                      iconColor="inherit"
                                      iconType={
                                        column.isReverseSortingIcon
                                          ? 'OrderIconASC'
                                          : 'OrderIconDESC'
                                      }
                                    />
                                  </span>
                                )
                              ) : (
                                <span className="text-grey-300">
                                  <Icon iconColor="inherit" iconType="Ordering" />
                                </span>
                              ))}
                          </span>
                        </div>
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()} className="overflow-y-auto">
              {(isPagination ? page : rows)?.map((row, i) => {
                // new
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} key={i} className="relative">
                    {row.cells.map((cell, index) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          className="border-b border-solid border-grey-100"
                          key={index}
                        >
                          <div className="min-h-[40px] flex flex-row items-center justify-start px-1">
                            {cell.render('Cell', {
                              handleInvoiceLinkClick: handleInvoiceLinkClick,
                              onTriggerTimeLine: onTriggerTimeLine,
                            })}
                          </div>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        {/* Pagination */}
        {isPagination && data?.length > paginationOptions[0] && !isServerSidePagination && (
          <Pagination
            options={paginationOptions}
            previousPage={() => previousPage()}
            pageIndex={pageIndex}
            pageOptions={pageOptions}
            pageSize={pageSize}
            nextPage={() => nextPage()}
            setPageSize={(e: any) => setPageSize(Number(e.target.value))}
            canNextPage={canNextPage}
            canPreviousPage={canPreviousPage}
          />
        )}
        {totalPageCount * pageSizeVal > paginationOptions[0] && isServerSidePagination && (
          <Pagination
            options={paginationOptions}
            previousPage={() => previousPageClick()}
            pageIndex={pageIndexVal}
            totalPageCount={totalPageCount}
            pageSize={pageSizeVal}
            nextPage={() => nextPageClick()}
            setPageSize={(e: any) => setPageSizeValue(Number(e.target.value))}
            canNextPage={pageIndexVal + 1 < totalPageCount}
            canPreviousPage={pageIndexVal > 0}
          />
        )}
      </div>

      {timeLineModalOpen &&
        selectedValuesForTimeLine.id !== '' &&
        selectedValuesForTimeLine.type !== '' && (
          <TimeLineModal
            onCloseEventModal={onCloseEvent}
            selectedId={selectedValuesForTimeLine.id}
            timeLineType={selectedValuesForTimeLine.type}
          />
        )}
    </>
  );
}

export default Table;
