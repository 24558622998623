import UpcomingContainer from 'components/modules/Balance/Days/Upcoming';
import UpcomingWeekContainer from 'components/modules/Balance/Weeks/Upcoming/Upcoming';

/**
 * Primary UI component for user interaction
 */
const Upcoming = () => {
  const getLocalStorageSettingItem = localStorage.getItem('balance_settings');
  return (
    <>
      {getLocalStorageSettingItem && getLocalStorageSettingItem === 'week' ? (
        <UpcomingWeekContainer />
      ) : (
        <UpcomingContainer />
      )}
    </>
  );
};

export default Upcoming;
