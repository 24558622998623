export interface PaymentSource {
  //common
  id: string;
  object: string;
  last4: string;

  //card
  brand: string;
  exp_month: string;
  exp_year: string;
  funding: string;
  cvc_check: string;

  //ach
  bank_name: string;
  routing_number: string;
  status: string;
  is_default: boolean;

  // ach credit transfer
  type: string;
  ach_credit_transfer: any;
}

export const paymentMesssages: any = {
  methodAddedSuccess: 'New payment method added successfully, please verify your payment method',
  verifyPaymentMethodMessage: 'Payment method is successfully verified',
  deletePaymentMethodMessage: 'Payment method is successfully deleted',
  pastBalnceValidationMessage: 'Past Balance value should be more than $0.00',
  negativePastBalnceValidationMessage: 'You should have a positive balance to pay',
};
