import { CheckboxProps } from 'shared/models/components/base.model';
import Icon from '../../Media/Icon/Icon';
import classNames from 'classnames';
import RadioButton from '../RadioButton/RadioButton';
import { useState } from 'react';

export const NotificationSwitch = ({
  register,
  name,
  id,
  label,
  defaultChecked,
  readonly,
  value,
  onChange,
  isDigest = false,
  isWeeklySelected = false,
  onRadioChange,
}: CheckboxProps) => {
  const [isActive, setActive] = useState(defaultChecked);
  const [isWeekly, setWeekly] = useState(isWeeklySelected);

  const handleDisableState = readonly ? 'opacity-50 pointer-events-none' : 'opacity-100';
  return (
    <>
      <div className={classNames(['relative w-full mb-4 form-switch-box', handleDisableState])}>
        <input
          type="checkbox"
          id={id}
          {...register(name)}
          className="absolute w-0 h-0 p-0 m-0 overflow-hidden -left-4"
          defaultChecked={defaultChecked}
          disabled={readonly}
          value={value}
          onChange={(e) => {
            onChange(e);
            setActive(e.target.checked);
          }}
        />
        {label && (
          <label htmlFor={id} className="font-normal bg-transparent text-grey-700 text-13s">
            {label}
          </label>
        )}
        <span className="tick-icon">
          <span className="tick-icon-mover">
            {readonly && <Icon iconType="LockedIcon" iconColor="inherit" />}
          </span>
        </span>
      </div>
      {isDigest && (
        <div className="flex flex-row w-full ">
          <RadioButton
            id={'weekly'}
            label="Weekly"
            name={'name'}
            checked={isActive && isWeekly}
            onChange={() => {
              if (isActive) {
                setWeekly(true);
                onRadioChange && onRadioChange(true);
              }
            }}
            variant="small"
            readOnly={readonly}
          />
          <RadioButton
            id={'daily'}
            label="Daily"
            name={'name'}
            checked={isActive && !isWeekly}
            onChange={() => {
              if (isActive) {
                setWeekly(false);
                onRadioChange && onRadioChange(false);
              }
            }}
            wrapperClasses={'ml-4'}
            variant="small"
            readOnly={readonly}
          />
        </div>
      )}
    </>
  );
};
