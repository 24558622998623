import moment from 'moment';

export function dateBetweenFilterFn(rows: any, id: any, filterValues: any) {
  const sd: any = filterValues[0] ? moment(filterValues[0]).startOf('day').toDate() : undefined;
  const ed: any = filterValues[1] ? moment(filterValues[1]).endOf('day').toDate() : undefined;

  if (ed || sd) {
    return rows.filter((r: any) => {
      const cellDate = new Date(r.values[id]);
      if (ed && sd) {
        return cellDate.getTime() >= sd.getTime() && cellDate.getTime() <= ed.getTime();
      } else if (sd) {
        return cellDate.getTime() >= sd.getTime();
      } else {
        return cellDate.getTime() <= ed.getTime();
      }
    });
  } else {
    return rows;
  }
}
