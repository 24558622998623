import VendorTransactionsContainer from 'components/modules/Vendors/Account/Transactions';

/**
 * Primary UI component for user interaction
 */
const VendorTransactions = () => {
  return <VendorTransactionsContainer />;
};

export default VendorTransactions;
