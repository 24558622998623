import VendorPropertiesContainer from 'components/modules/Vendors/Account/Properties';

/**
 * Primary UI component for user interaction
 */
const VendorProperties = () => {
  return <VendorPropertiesContainer />;
};

export default VendorProperties;
