import { useStripe } from '@stripe/react-stripe-js';
import { useParams } from 'react-router-dom';

import Icon from 'components/core/Media/Icon/Icon';
import { FooterNote } from 'components/core/Notes/FooterNote/FooterNote';
import { Typhography } from 'components/core/Typhography/Typhography';
import Message from 'components/core/Message/Message';

import { BankSetupProps } from 'shared/models/components/base.model';
import LoadingSpinner from 'components/core/LoadingSpinner/LoadingSpinner';
import { useCreateSetupIntentMutation } from 'services/paymentSourcesApi';
import { useFinancialConnectionSessionMutation } from 'services/customersApi';
import { CollectBankAccountTokenResult } from '@stripe/stripe-js/types/stripe-js/stripe';

export const PropertyBankSetup = ({ onClick, onClose, bankType }: BankSetupProps) => {
  const stripe = useStripe();
  const { id } = useParams();
  const [
    linkExternalAccount,
    {
      isError: isBankAccountError,
      error: linkBankAccountError,
      isSuccess: isBankAccountSuccess,
      isLoading: isBankAccountLoading,
    },
  ]: any = useCreateSetupIntentMutation();
  const [establishFinancialConnectionSession] = useFinancialConnectionSessionMutation();

  const createFinancialConnectionsSession = async () => {
    const connectionResponse: any = await establishFinancialConnectionSession('');

    try {
      const result: CollectBankAccountTokenResult = (await stripe?.collectBankAccountToken({
        clientSecret: connectionResponse?.data?.client_secret,
      })) as CollectBankAccountTokenResult;
      if (result?.error) {
        alert(`error in financial connections ${result?.error}`);
      } else {
        const accounts = result?.financialConnectionsSession?.accounts;
        if (accounts && accounts?.length > 0) {
          await linkExternalAccount({
            bank_data: {
              account_holder_name: 'test',
              financial_connections_account: accounts[0].id,
            },
            label: bankType,
            property_id: id,
          }).then((response: { error: { data: { message: string } } }) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (!response?.error) {
              setTimeout(() => {
                onClose();
              }, 3500);
            }
          });
        }
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const buttonClasses =
    'mb-4 flex w-full h-16 rounded-lg border border-solid border-frey-100 cursor-pointer items-center px-4 justify-between';

  return (
    <>
      {isBankAccountLoading && <LoadingSpinner />}

      <Typhography component="p" type="h2" color="text-grey-900 mb-6 uppercase">
        {'How do you want to Add Bank Details?'}
      </Typhography>

      {(isBankAccountError || isBankAccountSuccess) && (
        <Message
          title="Bank Setup"
          type={isBankAccountSuccess ? 'success' : 'error'}
          additionalClasses="mb-6"
        >
          {isBankAccountSuccess
            ? 'Bank Account Updated Successfully.'
            : linkBankAccountError?.data?.error}
        </Message>
      )}

      <div>
        <Typhography
          component="div"
          type="h2Bold"
          color="text-grey-900"
          additionalClasses={`${buttonClasses}`}
          onClick={createFinancialConnectionsSession}
        >
          <span className="flex items-center text-inherit">
            {'Connect via Bank Login'}{' '}
            <Icon
              iconColor="inherit"
              iconType="TermsIcon"
              iconContainerClasses="ml-2 flex items-center"
            />
          </span>
          <Icon iconColor="inherit" iconType="RightArrow" iconContainerClasses="text-grey-400" />
        </Typhography>
        <Typhography
          component="div"
          type="h2Bold"
          color="text-grey-900"
          additionalClasses={buttonClasses}
          onClick={() => onClick && onClick(true)}
        >
          <span className="text-inherit">{'Set Up Bank Manually'}</span>
          <Icon iconColor="inherit" iconType="RightArrow" iconContainerClasses="text-grey-400" />
        </Typhography>
      </div>
      <div className="absolute bottom-0 left-4 right-4">
        <FooterNote />
      </div>
    </>
  );
};
