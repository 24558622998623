import PaidContainer from 'components/modules/Approvals/Paid';
import React from 'react';

/**
 * Primary UI component for user interaction
 */
const PaidInvoices = () => {
  return <PaidContainer />;
};

export default PaidInvoices;
