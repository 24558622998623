import classNames from 'classnames';
import React from 'react';
import { Typhography } from '../Typhography/Typhography';
import { NavLink } from 'react-router-dom';

interface TabButtonProps {
  /**
   * Set the active tab
   */
  active?: boolean;
  /**
   * Tab label
   */
  label?: string;
  /**
   * onclick Event of Tab
   */
  onClick?: () => void;
  urlPath?: string;
}

/**
 * Primary UI component for user interaction
 */
const TabButton: React.FC<TabButtonProps> = ({
  active = false,
  label = active ? 'Active' : 'Inactive',
  urlPath = '#',
  onClick,
}) => {
  const handleActiveTabItemClasses = active
    ? 'bg-primary-75 cursor-default'
    : 'bg-white cursor-pointer';
  const tabCommonClasses =
    'h-6 w-auto inline-flex justify-center items-center ease-out duration-300 rounded-xl px-3 mr-2';

  return (
    <NavLink
      className={classNames([handleActiveTabItemClasses, tabCommonClasses])}
      to={urlPath}
      style={({ isActive }) => ({
        background: isActive ? '#F0F2FA' : '#FFF',
      })}
      replace={true}
    >
      <Typhography component="span" color="text-grey-900" type="h2">
        {label}
      </Typhography>
    </NavLink>
  );
};

export default TabButton;
