import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment-timezone';

import Wrapper from '../../../../layouts/Wrapper/Wrapper';
import ListEmpty from 'components/modules/Common/ListEmpty/ListEmpty';

import {
  ToolBar,
  LoadingSpinner,
  DataList,
  PastBalanceInnerListStatsBar,
  UpcomingBalanceInnerListStatsBar,
  Headers,
} from 'components/core';

import { approvedTabnavData } from 'constants/TabNavData';
import { CONFIG } from 'shared/configs/config';

import { helperApprove } from '../../../../../helpers/modules/helperInvoices';

import { useGetPayoutsQuery } from '../../../../../services/payoutsApi';
import { serverSidePaginationActive, useServerSidePagination } from 'hooks/useServerSidePagination';

export const BalanceDayListInnerContainer = () => {
  const navigate = useNavigate();
  const { timeRangeParams }: any = useParams();

  const [pageSizeOptions, paginationState, previousButtonClick, nextButtonClick, setPageSize]: any =
    useServerSidePagination();

  // get the time frame from local storage
  const getTargetTime = moment(localStorage.getItem('balance_date')).tz('America/Chicago');

  // render function to handle the column visbility button click event
  const [tableColumns, handleIconClick] = helperApprove('ALL');

  // set the states using useState react hooks
  const [toggleHiddenColumnChanged, setToggleHiddenColumnChanged] = useState(false);
  const [filteredRow, setFilteredRow] = useState([]);
  const [columnList, setColumnList]: any = useState([]);
  const [visibleColumns, setVisibleColumns] = useState([]);
  const [calenderDay, setCalenderDay] = useState(
    moment(getTargetTime).tz('America/Chicago').format('MM/DD/YYYY')
  );

  // set date range as a object
  const dateRangeQuery = {
    lessThan: moment(getTargetTime)
      .tz('America/Chicago')
      .add(1, 'day')
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .format(),
    greaterThan: moment(getTargetTime)
      .tz('America/Chicago')
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .format(),
  };

  // filter query to render all payouts in the given time range
  const fiterQueryParam = {
    first: serverSidePaginationActive ? paginationState.pageSize : CONFIG.DATA_OVERALL_LIMIT,
    offset: serverSidePaginationActive ? paginationState.pageIndex * paginationState.pageSize : 0,
    orderBy: 'INVOICED_AT_DESC',
    filter: {
      status: { in: ['INVOICED', 'APPROVED', 'PAID'] },
      customer_payment_due_at: dateRangeQuery,
    },
    groupBy: 'CUSTOMER_PAYMENT_DUE_AT_TRUNCATED_TO_DAY_CST',
  };

  // fetch all payouts date for given filter object
  const { data, isLoading, isSuccess, isError } = useGetPayoutsQuery(fiterQueryParam);
  const totalCount: number | undefined = data?.payouts.totalCount; // set total count

  const totalRecords: number = data?.payouts?.totalCount ?? 0;
  const totalPageCount = Math.ceil(totalRecords / paginationState.pageSize);

  // set the calenderDay state in the initial load
  useEffect(() => {
    if (timeRangeParams.includes('current')) {
      setCalenderDay('Today');
    }
  }, []);

  // update the column list according to the data array-object change
  useEffect(() => {
    getColumnsList();
  }, [data]);

  const getColumnsList = () => {
    setColumnList(tableColumns);
  };

  const hdColList = [
    ...columnList.filter((item: any) => item.isHidden).map((val: any) => val.accessor),
  ];

  return (
    <>
      <Wrapper additionalClasses="flex-col items-start" pageTitle="Approvals" isBackButtonVisible>
        <Headers
          headingContent="Balance"
          tagEnabled={timeRangeParams && timeRangeParams !== ''}
          tagLabel={timeRangeParams}
          isButtonVisibled={false}
          isDisabled={totalCount === 0}
          onClick={() => navigate('/balance/payment')}
          buttonLabel="Pay Now"
          variants="extraSmall"
          isIconEnabled={false}
          additionalClasses="w-[118px]"
        />
        {/**
         * Handle loader
         */}
        {isLoading && <LoadingSpinner />}
        {/**
         * Error handling
         */}
        {isError && <Navigate to={'/401'} />}
        {isSuccess && (
          <React.Fragment>
            <ToolBar
              navigationData={approvedTabnavData}
              isDownloadCsvButtonEnabled={false}
              isShowOptionEnabled={totalCount !== 0}
              columns={columnList}
              handleIconClick={(item: any) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                handleIconClick(
                  item,
                  columnList,
                  setColumnList,
                  setToggleHiddenColumnChanged,
                  toggleHiddenColumnChanged
                );
              }}
              filteredRow={filteredRow}
              visibleColumns={visibleColumns}
              title={`Invoices Due ${calenderDay}`}
              isNavigationEnabled={false}
            />

            {timeRangeParams === 'past' ? (
              <PastBalanceInnerListStatsBar
                dateRangeQuery={dateRangeQuery}
                dueDate={moment(getTargetTime).tz('America/Chicago').format('MM/DD/YYYY')}
              />
            ) : (
              <UpcomingBalanceInnerListStatsBar
                timeFrame={timeRangeParams}
                dateRangeQuery={dateRangeQuery}
                dueDate={moment(getTargetTime).tz('America/Chicago').format('MM/DD/YYYY')}
              />
            )}

            {totalCount !== 0 ? (
              <DataList
                data={data?.payouts?.nodes}
                setFilteredRow={setFilteredRow}
                editNetTerm={true}
                isLink={true}
                hiddenColumnList={hdColList}
                toggleHiddenColumnChanged={toggleHiddenColumnChanged}
                setVisibleColumns={setVisibleColumns}
                subTabType={'allAprove'}
                isServerSidePagination={serverSidePaginationActive}
                previousPageClick={previousButtonClick}
                nextPageClick={nextButtonClick}
                setPageSizeValue={setPageSize}
                options={pageSizeOptions}
                totalPageCount={totalPageCount}
                pageSizeVal={paginationState.pageSize}
                pageIndexVal={paginationState.pageIndex}
              />
            ) : (
              <ListEmpty />
            )}
          </React.Fragment>
        )}
      </Wrapper>
    </>
  );
};
