import { getTermName, getTermType } from 'helpers/arrayFormating';
import { handleTheStatusText, handleCheckStatus } from 'helpers/modules/helperInvoices';
import { useCurrencyFormat } from 'hooks/useCurrencyFormat';
// import { useTitleCase } from 'hooks/useTitleCase';
import moment from 'moment';
import { isPermitted } from 'services/userApi';
import { DataListListProps } from 'shared/models/components/base.model';
import {
  getApprovalsStatusSorting,
  // getNameFromAccount,
  getByUserNameSorting,
  getResmanProp,
  getResmanPropARMode,
  getVenderDueSorting,
  // getVendorNameSorting,
} from 'shared/utilities/utils';
import { NetXChips } from '../../Chips/NetXChips/NetXChips';
import { StatusTag } from '../../Chips/StatusTag/StatusTag';
import { CellContent } from '../CellElements/CellContent/CellContent';
import { DateRangeColumnFilter, MultiCheckBoxColumnFilter } from '../CellElements/Filters';
import { Approvals } from './ColumnWidths';
import { isTermEditable } from 'shared/configs/config';
import { PayModeSetModal } from 'components/core/Modals/PayModeSetModal/PayModeSetModal';
// import { RevererseInvoiceStatsDropDownCell } from '../CellElements/CellContent/RevererseInvoiceStatsDropDownCell';
import { useTitleCase } from 'hooks/useTitleCase';
import { CheckImageUrlModal } from 'components/core/Modals/CheckImageUrlModal/CheckImageUrlModal';

export const handleDataModalInvoicesColumns = ({ user }: DataListListProps) => {
  const ApprovalsTableColumns: any[] = [
    {
      Header: 'INVOICED',
      wrapperClasses: Approvals.ColomnWidth.invoiced_at,
      headerClasses: Approvals.HeaderCellWidth.invoiced_at,
      accessor: 'invoiced_at',
      Filter: DateRangeColumnFilter,
      filter: 'dateBetween',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention,react/destructuring-assignment
        const { invoiced_at, id } = row.row.original;
        return (
          <CellContent isLink wrapperClasses={Approvals.ColomnWidth.invoiced_at} isWordWrapped>
            {invoiced_at ? (
              <span
                onClick={row.handleInvoiceLinkClick}
                data-id={id}
                className="underline cursor-pointer"
              >
                {invoiced_at && moment(invoiced_at).format('MM/DD/YYYY')}
              </span>
            ) : null}
          </CellContent>
        );
      },
    },
    {
      Header: 'VENDOR',
      wrapperClasses: Approvals.ColomnWidth.vendor,
      headerClasses: Approvals.HeaderCellWidth.vendor,
      accessor: 'vendor_name',
      filter: 'matches',
      isReverseSortingIcon: true,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention,react/destructuring-assignment
        const { account_id, vendor_name } = row.row.original;

        const stringVendorName = useTitleCase(vendor_name);
        const getTextLength = vendor_name ? stringVendorName.length : 0;
        const maxLengthCount = 17;

        return (
          <CellContent
            wrapperClasses={Approvals.ColomnWidth.vendor}
            isTooltipEnabled={vendor_name && getTextLength > maxLengthCount}
            tooltipIcon={'DotsHorizontalMoreIcon'}
            tooltipIconClasses="text-grey-600 top-[2px] relative -left-[5px] cursor-pointer"
            tooltipContainerClass={'left-[12px] -top-[6px] rounded-tl-lg min-w-[100px]'}
            tooltipContent={vendor_name ? stringVendorName : null}
            isWordWrapped
            isLink={account_id ? true : false}
            linkTarget="_self"
            linkHref={`/vendors/account/${account_id}`}
          >
            {vendor_name ? stringVendorName.substring(0, maxLengthCount) : null}
          </CellContent>
        );
      },
    },
    {
      Header: 'PAYUP ID',
      accessor: 'number',
      wrapperClasses: Approvals.ColomnWidth.number,
      headerClasses: Approvals.HeaderCellWidth.number,
      filter: 'fuzzy',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        return (
          <CellContent wrapperClasses={Approvals.ColomnWidth.number}>
            {row.row.values.number ? `ID${row.row.values.number}` : null}
          </CellContent>
        );
      },
    },
    {
      Header: 'INVOICE',
      accessor: 'coupa_invoice_number',
      wrapperClasses: Approvals.ColomnWidth.description,
      headerClasses: Approvals.HeaderCellWidth.description,
      filter: 'fuzzy',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention,react/destructuring-assignment
        const { coupa_invoice_number, id } = row.row.original;
        const setInvoiceNumber: any = coupa_invoice_number;
        const getTextLength = coupa_invoice_number ? setInvoiceNumber.length : 0;
        const maxLengthCount = 18;

        return (
          <CellContent
            isLink
            linkHref={`/api/coupa/download-invoice/${id}`}
            wrapperClasses={`${Approvals.ColomnWidth.description} relative !z-[5]`}
            isWordWrapped
            isTooltipEnabled={coupa_invoice_number && getTextLength > maxLengthCount}
            tooltipIcon={'DotsHorizontalMoreIcon'}
            tooltipIconClasses="text-grey-600 top-[2px] relative -left-[5px] cursor-pointer"
            tooltipContainerClass={`left-[12px] -top-[6px] rounded-tl-lg whitespace-normal break-words min-w-[180px]`}
            tooltipContent={coupa_invoice_number ? setInvoiceNumber : null}
          >
            {coupa_invoice_number ? setInvoiceNumber.substring(0, maxLengthCount) : null}
          </CellContent>
        );
      },
    },
    {
      Header: 'DESCRIPTION',
      accessor: 'description',
      wrapperClasses: Approvals.ColomnWidth.description,
      headerClasses: Approvals.HeaderCellWidth.description,
      filter: 'fuzzy',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention,react/destructuring-assignment
        const { description, id } = row.row.original;
        const getTextLength = description ? description.length : 0;
        const maxLengthCount = 18;

        return (
          <CellContent
            isLink={false}
            linkHref={`/api/coupa/download-invoice/${id}`}
            wrapperClasses={`${Approvals.ColomnWidth.description} relative !z-[5]`}
            isWordWrapped
            isTooltipEnabled={description && getTextLength > maxLengthCount}
            tooltipIcon={'DotsHorizontalMoreIcon'}
            tooltipIconClasses="text-grey-600 top-[2px] relative -left-[5px] cursor-pointer"
            tooltipContainerClass={`left-[12px] -top-[6px] rounded-tl-lg whitespace-normal break-words min-w-[190px]`}
            tooltipContent={description ? description : null}
          >
            {description ? description.substring(0, maxLengthCount) : null}
          </CellContent>
        );
      },
    },
    {
      Header: 'STATUS',
      accessor: (row: any) =>
        getApprovalsStatusSorting(
          row.status,
          row.payout_scheduled_at,
          row.pay_mode,
          row.check_status
        ),
      Filter: MultiCheckBoxColumnFilter,
      headerClasses: Approvals.HeaderCellWidth.status,
      headerInnerClasses: Approvals.HeaderInnerDivWidth.status,
      filter: 'multiSelect',
      canFilter: false,
      gridType: 'invoices-modal',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention,react/destructuring-assignment
        const { payout_scheduled_at, pay_mode, check_status, check_number } = row.row.original;
        let { status } = row.row.original;
        if (pay_mode === 'check' && check_status !== null) {
          status = handleCheckStatus(check_status);
        }

        return (
          <CellContent
            wrapperClasses={`flex flex-row w-full !justify-start justify-items-start !items-start ${Approvals.ColomnWidth.status}`}
            isRemoveCellZindex
            isTooltipEnabled={pay_mode === 'check' && check_number}
            tooltipIconClasses="text-grey-600 relative -left-[5px] top-[5px] cursor-pointer"
            tooltipContainerClass={'left-[8px] -top-[2px] rounded-tl-lg !min-w-[0px] !w-[150px]'}
            tooltipContent={'Check number : ' + check_number}
          >
            {status ? (
              <StatusTag status={handleTheStatusText(status, payout_scheduled_at)} />
            ) : null}
          </CellContent>
        );
      },
    },
    {
      Header: 'APPROVAL DUE',
      accessor: 'estimated_approved_at',
      wrapperClasses: Approvals.ColomnWidth.estimated_approved_at,
      headerClasses: Approvals.HeaderCellWidth.estimated_approved_at,
      Filter: DateRangeColumnFilter,
      filter: 'dateBetween',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        // eslint-disable-next-line react/destructuring-assignment
        const dueDate = moment(row.row.values.estimated_approved_at).format('MM/DD/YYYY');
        // eslint-disable-next-line react/destructuring-assignment
        // check 'estimated_approved_at' is lessthan current date or not
        // if less than current date, make tooltip enabled
        const handleIsDue = () => {
          let setDue = false;

          if (moment(row.row.values.estimated_approved_at).format() < moment().format()) {
            setDue = true;
          }

          if (row.row.values.approved_at) {
            if (
              moment(row.row.values.estimated_approved_at).format() >
              moment(row.row.values.approved_at).format()
            ) {
              setDue = false;
            }

            if (
              moment(row.row.values.estimated_approved_at).format() <
              moment(row.row.values.approved_at).format()
            ) {
              setDue = true;
            }
          }

          return setDue;
        };

        // if 'approved_at' is greather than 'estimated_approved_at
        const isinvoicedDue =
          row.row.values.approved_at &&
          moment(row.row.values.estimated_approved_at).format() <
            moment(row.row.values.approved_at).format();

        return (
          <CellContent
            wrapperClasses={Approvals.ColomnWidth.estimated_approved_at}
            isTooltipEnabled={handleIsDue()}
            tooltipIconClasses={isinvoicedDue ? 'text-grey-400' : 'text-red-500'}
            tooltipContent={
              isinvoicedDue
                ? 'Invoice was approved after due date'
                : 'The approval date is past due'
            }
          >
            {dueDate}
          </CellContent>
        );
      },
    },
    {
      Header: 'APPROVED',
      accessor: 'approved_at',
      Filter: DateRangeColumnFilter,
      wrapperClasses: Approvals.ColomnWidth.approved_at,
      headerClasses: Approvals.HeaderCellWidth.approved_at,
      filter: 'dateBetween',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        return (
          <CellContent wrapperClasses={Approvals.ColomnWidth.approved_at}>
            {row.row.original.approved_at
              ? moment(row.row.original.approved_at).format('MM/DD/YYYY')
              : null}
          </CellContent>
        );
      },
    },
    {
      Header: 'NET',
      accessor: 'payout_term_id',
      Filter: MultiCheckBoxColumnFilter,
      headerClasses: Approvals.HeaderCellWidth.payout_term_id,
      filter: 'multiSelect',

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        // eslint-disable-next-line react/destructuring-assignment,@typescript-eslint/naming-convention
        const { payout_term_id, status } = row.row.original;

        const handleNetXEdit = () => {
          let isEditable = false;

          if (
            (status === 'INVOICED' || status === 'APPROVED') &&
            isPermitted(user, 'edit-payout')
          ) {
            isEditable = true;
          }

          if (status === 'PAID') {
            isEditable = false;
          }

          return isEditable;
        };

        // const getTermId = subTabType !== 'innerPropertiesEarnings' ? payout_term_id : payoutTerm.id;

        return (
          <CellContent wrapperClasses={Approvals.ColomnWidth.payout_term_id}>
            {payout_term_id ? (
              <NetXChips
                value={getTermName(payout_term_id)}
                isPercentageAvailable={getTermType(payout_term_id)}
                isDisabled={status === 'PAID'}
                isEditable={handleNetXEdit() && isTermEditable}
              />
            ) : null}
          </CellContent>
        );
      },
    },
    {
      Header: 'VENDOR DUE',
      accessor: (row: any) => getVenderDueSorting(row.payout_due_at, row.payout_scheduled_at),
      Filter: DateRangeColumnFilter,
      headerClasses: Approvals.HeaderCellWidth.payout_due_at,
      filter: 'dateBetween',
      wrapperClasses: Approvals.ColomnWidth.payout_due_at,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        return (
          <CellContent
            wrapperClasses={Approvals.ColomnWidth.payout_due_at}
            isTooltipEnabled={row.row.original.payout_scheduled_at ? true : false}
            tooltipIconClasses="text-green-400"
            tooltipContent={`Payout Manually Scheduled at ${moment(
              row.row.original.payout_scheduled_at
            ).format('MM/DD/YYYY')}`}
            tooltipIcon="TimerIconSmall"
          >
            {row.row.original.payout_scheduled_at
              ? moment(row.row.original.payout_scheduled_at).format('MM/DD/YYYY')
              : row.row.original.payout_due_at
              ? moment(row.row.original.payout_due_at).format('MM/DD/YYYY')
              : null}
          </CellContent>
        );
      },
    },
    {
      Header: 'RECEIVED ON',
      accessor: 'customer_paid_at',
      Filter: DateRangeColumnFilter,
      headerClasses: Approvals.HeaderCellWidth.customer_paid_at,
      filter: 'dateBetween',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { customer_paid_at } = row.row.original;
        return (
          <CellContent wrapperClasses={Approvals.ColomnWidth.customer_paid_at}>
            {customer_paid_at ? moment(customer_paid_at).format('MM/DD/YYYY') : null}
          </CellContent>
        );
      },
    },
    {
      Header: 'PAID ON',
      accessor: 'paid_at',
      Filter: DateRangeColumnFilter,
      filter: 'dateBetween',
      wrapperClasses: Approvals.ColomnWidth.paid_at,
      headerClasses: Approvals.HeaderCellWidth.paid_at,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { stripe_transfer_id, id, pay_mode, check_paid_on } = row.row.original;
        let paidAt = row.row.values.paid_at
          ? moment(row.row.values.paid_at).format('MM/DD/YYYY')
          : null;
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const check_paid_at = check_paid_on ? moment(check_paid_on).format('MM/DD/YYYY') : null;

        if (pay_mode === 'check') {
          if (check_paid_at) paidAt = check_paid_at;
          else paidAt = null;
        }

        return (
          <CellContent
            wrapperClasses={Approvals.ColomnWidth.paid_at}
            isWordWrapped
            isLink={stripe_transfer_id ? true : false}
            linkHref={`/api/payout/${id}/invoice`}
          >
            {paidAt}
          </CellContent>
        );
      },
    },
    {
      Header: 'PAID BY',
      accessor: (row: any) => getByUserNameSorting(row, false, false),
      filter: 'matches',
      sortType: 'string',
      wrapperClasses: Approvals.ColomnWidth.paid_by,
      headerClasses: Approvals.HeaderCellWidth.paid_by,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const setPaifByUserName = getByUserNameSorting(row.row.original, false, false);

        return (
          <CellContent wrapperClasses={Approvals.ColomnWidth.paid_by} isWordWrapped>
            {setPaifByUserName !== '' ? setPaifByUserName : null}
          </CellContent>
        );
      },
    },
    {
      Header: 'PAYUP DUE',
      accessor: 'customer_payment_due_at',
      wrapperClasses: Approvals.ColomnWidth.customer_payment_due_at,
      headerClasses: Approvals.HeaderCellWidth.customer_payment_due_at,
      Filter: DateRangeColumnFilter,
      filter: 'dateBetween',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { customer_payment_due_at } = row.row.original;
        return (
          <CellContent wrapperClasses={Approvals.ColomnWidth.customer_payment_due_at}>
            {customer_payment_due_at ? moment(customer_payment_due_at).format('MM/DD/YYYY') : null}
          </CellContent>
        );
      },
    },
    {
      Header: 'CHECK DATE',
      accessor: (row: any) => getResmanProp(row, 'check_date'),
      wrapperClasses: Approvals.ColomnWidth.check_date,
      headerClasses: Approvals.HeaderCellWidth.check_date,
      Filter: DateRangeColumnFilter,
      filter: 'dateBetween',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        const checkDate = getResmanProp(row.row.original, 'check_date');
        return (
          <CellContent wrapperClasses={Approvals.ColomnWidth.check_date}>
            {checkDate ? moment(checkDate).format('MM/DD/YYYY') : null}
          </CellContent>
        );
      },
    },
    {
      Header: 'AR MODE',
      // accessor: 'paid_by',
      accessor: (row: any) => getResmanPropARMode(row, 'paid_by'),
      wrapperClasses: Approvals.ColomnWidth.payUp_ar_mode,
      headerClasses: Approvals.HeaderCellWidth.payUp_ar_mode,
      Filter: MultiCheckBoxColumnFilter,
      filter: 'multiSelect',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        const paidBy = getResmanPropARMode(row.row.original, 'paid_by');
        return (
          <CellContent wrapperClasses={Approvals.ColomnWidth.payUp_ar_mode}>{paidBy}</CellContent>
        );
      },
    },
    {
      Header: 'PAY REF',
      accessor: (row: any) => getResmanProp(row, 'pay_id'),
      filter: 'matches',
      wrapperClasses: Approvals.ColomnWidth.pay_ref,
      headerClasses: Approvals.HeaderCellWidth.pay_ref,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        const paymentRef = getResmanProp(row.row.original, 'pay_id');
        return (
          <CellContent wrapperClasses={Approvals.ColomnWidth.pay_ref}>
            {paymentRef && paymentRef}
          </CellContent>
        );
      },
    },
    {
      Header: 'PAY MODE',
      // accessor: 'paid_by',
      accessor: 'pay_mode',
      wrapperClasses: Approvals.ColomnWidth.payUp_paid_mode,
      headerClasses: Approvals.HeaderCellWidth.payUp_paid_mode,
      Filter: MultiCheckBoxColumnFilter,
      filter: 'multiSelect',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { pay_mode, status, id } = row.row.original;
        const editAccessFalse = false;
        return (
          <CellContent wrapperClasses={Approvals.ColomnWidth.payUp_paid_mode}>
            {pay_mode ? (
              <PayModeSetModal
                status={status}
                pay_mode={pay_mode}
                id={id}
                isUserHaveEditAccess={isPermitted(user, 'edit-pay-mode') && editAccessFalse}
              />
            ) : null}
          </CellContent>
        );
      },
    },
    {
      Header: 'CHECK NUMBER',
      wrapperClasses: Approvals.ColomnWidth.check_image_url,
      headerClasses: Approvals.HeaderCellWidth.check_image_url,
      accessor: 'check_number',
      filter: 'fuzzy',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention,react/destructuring-assignment
        const { check_image_url, check_number } = row.row.original;

        return (
          <>
            <CheckImageUrlModal imageURL={check_image_url} checkNumer={check_number} user={user} />
          </>
        );
      },
    },
    {
      Header: 'INVOICE AMOUNT',
      accessor: 'amount',
      headerClasses: Approvals.HeaderCellWidth.amount,
      headerInnerClasses: Approvals.HeaderInnerDivWidth.amount,
      canFilter: false,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        // eslint-disable-next-line react/destructuring-assignment,@typescript-eslint/naming-convention
        const { amount } = row.row.original;
        // eslint-disable-next-line react/destructuring-assignment
        const amountMain = useCurrencyFormat(parseFloat(amount));
        // eslint-disable-next-line react/destructuring-assignment
        return (
          <CellContent wrapperClasses={Approvals.ColomnWidth.amount}>
            {amount ? amountMain : null}
          </CellContent>
        );
      },
    },
    {
      Header: 'PAYOUT AMOUNT',
      accessor: 'payout_amount',
      headerClasses: Approvals.HeaderCellWidth.payout_amount,
      headerInnerClasses: Approvals.HeaderInnerDivWidth.payout_amount,
      canFilter: false,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        // eslint-disable-next-line react/destructuring-assignment,@typescript-eslint/naming-convention
        const { payout_amount } = row.row.original;
        // eslint-disable-next-line react/destructuring-assignment
        const amountPayout = useCurrencyFormat(parseFloat(payout_amount));
        // eslint-disable-next-line react/destructuring-assignment
        return (
          <CellContent wrapperClasses={Approvals.ColomnWidth.payout_amount}>
            {payout_amount ? amountPayout : null}
          </CellContent>
        );
      },
    },
    {
      Header: 'PAID AMOUNT',
      accessor: 'paid_amount',
      headerClasses: Approvals.HeaderCellWidth.payout_amount,
      headerInnerClasses: Approvals.HeaderInnerDivWidth.payout_amount,
      canFilter: false,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        // eslint-disable-next-line react/destructuring-assignment,@typescript-eslint/naming-convention
        const { paid_amount } = row.row.original;
        // eslint-disable-next-line react/destructuring-assignment
        const amountPaid = useCurrencyFormat(parseFloat(paid_amount));
        // eslint-disable-next-line react/destructuring-assignment
        return (
          <CellContent wrapperClasses={Approvals.ColomnWidth.payout_amount}>
            {paid_amount ? amountPaid : null}
          </CellContent>
        );
      },
    },
    {
      Header: 'PROCESSING FEE',
      accessor: 'processing_fee_amount',
      headerClasses: Approvals.HeaderCellWidth.processing_fee_amount,
      headerInnerClasses: Approvals.HeaderInnerDivWidth.processing_fee_amount,
      canFilter: false,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        // eslint-disable-next-line react/destructuring-assignment,@typescript-eslint/naming-convention
        const { processing_fee_amount } = row.row.original;
        // eslint-disable-next-line react/destructuring-assignment
        const amountTerm = useCurrencyFormat(parseFloat(processing_fee_amount));
        // eslint-disable-next-line react/destructuring-assignment
        return (
          <CellContent wrapperClasses={Approvals.ColomnWidth.processing_fee_amount}>
            {processing_fee_amount ? amountTerm : null}
          </CellContent>
        );
      },
    },
    {
      Header: 'DISCOUNT FEE',
      accessor: 'payout_term_amount',
      headerClasses: Approvals.HeaderCellWidth.payout_term_amount,
      headerInnerClasses: Approvals.HeaderInnerDivWidth.payout_term_amount,
      canFilter: false,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        // eslint-disable-next-line react/destructuring-assignment,@typescript-eslint/naming-convention
        const { payout_term_amount } = row.row.original;
        // eslint-disable-next-line react/destructuring-assignment
        const amountTerm = useCurrencyFormat(parseFloat(payout_term_amount));
        // eslint-disable-next-line react/destructuring-assignment
        return (
          <CellContent wrapperClasses={Approvals.ColomnWidth.payout_term_amount}>
            {payout_term_amount ? amountTerm : null}
          </CellContent>
        );
      },
    },
    {
      Header: 'DRAW ACCOUNT',
      accessor: 'coupa_draw_account',
      Filter: MultiCheckBoxColumnFilter,
      headerClasses: Approvals.HeaderCellWidth.coupa_draw_account,
      filter: 'multiSelect',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        // eslint-disable-next-line react/destructuring-assignment,@typescript-eslint/naming-convention
        const { coupa_draw_account } = row.row.original;

        return (
          <CellContent wrapperClasses={Approvals.ColomnWidth.coupa_draw_account}>
            {coupa_draw_account}
          </CellContent>
        );
      },
    },
  ];

  return ApprovalsTableColumns;
};
