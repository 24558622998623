import React from 'react';
import { TyphographyProps } from 'shared/models/components/base.model';
import { handleFontTypes } from './Typhography.handler';

/**
 * Primary UI component for user interaction
 */
export const Typhography = ({
  type,
  component = 'span',
  color,
  additionalClasses,
  children,
  onClick,
  ...rest
}: TyphographyProps) => {
  const tyPhoClasses = handleFontTypes(type) + ' ' + additionalClasses + ' ' + color;

  return (
    <>
      {component === 'p' ? (
        <p className={tyPhoClasses} {...rest} onClick={onClick}>
          {children}
        </p>
      ) : component === 'span' ? (
        <span className={tyPhoClasses} {...rest} onClick={onClick}>
          {children}
        </span>
      ) : component === 'em' ? (
        <em className={tyPhoClasses} {...rest} onClick={onClick}>
          {children}
        </em>
      ) : component === 'div' ? (
        <div className={tyPhoClasses} {...rest} onClick={onClick}>
          {children}
        </div>
      ) : (
        <div className={additionalClasses} {...rest} onClick={onClick}>
          {children}
        </div>
      )}
    </>
  );
};
