import React from 'react';
import classNames from 'classnames';

export interface OverlayLayerProps {
  onOverlayClick?: () => void;
  additionalClasses?: string;
}

export const OverlayLayer = ({ onOverlayClick, additionalClasses }: OverlayLayerProps) => {
  return (
    <div
      className={classNames([
        'fixed top-0 bottom-0 left-0 right-0 z-10 bg-transparent',
        additionalClasses,
      ])}
      onClick={onOverlayClick}
    />
  );
};
