import { useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';

import Wrapper from '../../../../layouts/Wrapper/Wrapper';
import {
  BalanceStatsBar,
  LoadingSpinner,
  ToolBar,
  Typhography,
  Headers,
  SelectBox,
  EmptyList,
  AccordionItem,
} from 'components/core';

import { balanceDaysTabnavData } from 'constants/TabNavData';
import { CONFIG } from 'shared/configs/config';

import { useCurrencyFormat } from 'hooks/useCurrencyFormat';
import { handleBalanceAccordionClick } from 'helpers/modules/helperBalance';

import {
  useGetPendingBalanceApiQuery,
  useGetUpcomingBalanceHeroStatsQuery,
} from '../../../../../services/pastBalanceApi';

const BalanceContainer = () => {
  const navigate = useNavigate();

  const setLessThanDate = moment()
    .tz('America/Chicago')
    .add(1, 'day')
    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    .format();
  const setGreaterThan = moment()
    .tz('America/Chicago')
    .add(0, 'day')
    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    .format();

  /*
   * List the Current Invoices
   */
  const todaysInvoiceParams = {
    limit: CONFIG.DATA_OVERALL_LIMIT,
    offset: 0,
    filter: {
      status: { in: ['INVOICED', 'APPROVED', 'PAID'] },
      customer_payment_due_at: {
        lessThan: setLessThanDate,
        greaterThan: setGreaterThan,
      },
    },
    groupBy: 'CUSTOMER_PAYMENT_DUE_AT_TRUNCATED_TO_DAY_CST',
  };

  const { data, isLoading, isFetching, isSuccess } =
    useGetPendingBalanceApiQuery(todaysInvoiceParams);

  /*
   * Fetch the Current Invoices Hero Stats data
   */
  const currentHeroStatsParams = {
    filter: {
      status: { in: ['APPROVED', 'PAID', 'INVOICED'] },
      customer_payment_due_at: {
        lessThan: setLessThanDate,
        greaterThan: setGreaterThan,
      },
    },
    timeCycleDays: '' + 1 + '',
  };

  const { data: currentBalanceStatsBarData, isSuccess: isSuccessCurrentBalanceStatsBar }: any =
    useGetUpcomingBalanceHeroStatsQuery(currentHeroStatsParams);

  return (
    <>
      {(isLoading || isFetching) && <LoadingSpinner />}
      <Wrapper additionalClasses="flex-col items-start" pageTitle="Balance">
        <Headers
          headingContent="Balance"
          isButtonVisibled={false}
          isDisabled={isSuccess && data?.payouts?.groupedAggregates.length === 0}
          onClick={() => navigate('/balance/payment')}
          buttonLabel="Pay Balance"
          variants="extraSmall"
          isIconEnabled={false}
          additionalClasses="w-[118px]"
        />

        {!isLoading && (
          <>
            <ToolBar navigationData={balanceDaysTabnavData} isShowOptionEnabled={false}>
              <SelectBox defaultSelectBoxText={'Select time'} isDisabled itemSelected />
            </ToolBar>

            {isSuccessCurrentBalanceStatsBar && (
              <BalanceStatsBar data={currentBalanceStatsBarData} timeFrame="current" />
            )}

            {isSuccess && data?.payouts?.groupedAggregates.length > 0 ? (
              <div className="flex flex-col w-full">
                <Typhography
                  component="div"
                  type="h2"
                  color="text-grey-900"
                  additionalClasses="items-center uppercase pt-9 pb-3 border-t border-solid border-grey-100 px-4 !font-normal"
                >
                  {'Invoices List'}
                </Typhography>

                <div className="flex flex-col w-full invoices-list-wrapper">
                  {data?.payouts?.groupedAggregates.map((listItem: any) => {
                    const convertedPrice = parseFloat(listItem?.sum?.amount);

                    return (
                      <div className="flex flex-col w-full mb-0" key={listItem.keys[0]}>
                        <AccordionItem
                          mainLabel="Invoice"
                          subLabel={moment(listItem.keys[0])
                            .tz('America/Chicago')
                            .format('MM/DD/YYYY')}
                          rightColContent={useCurrencyFormat(convertedPrice)}
                          isInvoiceTag={false}
                          isRemoveTopBorder={data?.payouts?.groupedAggregates.length === 1}
                          clickAccordion={() =>
                            handleBalanceAccordionClick(
                              listItem.keys[0],
                              navigate,
                              false,
                              'current'
                            )
                          }
                          isLink={false}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              <EmptyList />
            )}
          </>
        )}
      </Wrapper>
    </>
  );
};

export default BalanceContainer;
