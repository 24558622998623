import { Grid } from '../../Grid/Grid';
import { StatusBlock } from '../../StatusBlock/StatusBlock';
import { useCurrencyFormat } from 'hooks/useCurrencyFormat';

export const PropertiesSummaryStatsBar = ({ data, sortingText }: any) => {
  const isTrendlineVisible =
    sortingText === 'All' || sortingText === '500' || sortingText === 'YTD';
  const getProperties = data.properties.nodes[0];

  const getAmountInTransition =
    getProperties?.amountInTransition?.aggregates?.sum.total_amount ?? 0;
  const getAmountReceived = getProperties?.amountReceived?.aggregates?.sum?.total_amount ?? 0;
  const geTotalPayoutsCompleted =
    getProperties?.totalPayoutsCompleted?.aggregates?.sum?.paid_amount ?? 0;
  const geTotalPayoutsCompletedCount = getProperties?.totalPayoutsCompleted?.totalCount ?? 0;
  const setTotalPayoutsCompletedCount =
    geTotalPayoutsCompletedCount === 0 ? `0` : geTotalPayoutsCompletedCount;

  const isAmountDigitsLengthGreaterThanThree =
    getAmountInTransition.length > 3 ||
    getAmountReceived.length > 3 ||
    geTotalPayoutsCompleted.length > 3;

  const handleFirstValueClasses = isAmountDigitsLengthGreaterThanThree ? '!text-20s' : '!text-24s';
  const handleSecondValueClasses = isAmountDigitsLengthGreaterThanThree ? '!text-12s' : '!text-13s';
  const handleThirdValueClasses = isAmountDigitsLengthGreaterThanThree ? '!text-12s' : '!text-13s';

  return (
    <Grid columnCount={3} additionalClasses={'w-full mb-6'}>
      <StatusBlock
        firstValue={useCurrencyFormat(getAmountInTransition)}
        // secondValue={
        //   !isTrendlineVisible ? getProperties?.customer_paid_payouts?.change_percentage : ''
        // }
        thirdValue={!isTrendlineVisible ? sortingText : ''}
        blockHeading={'Amount In Transition'}
        // iconType={'IncreaseMode'}
        // iconColor={'text-green-400'}
        secondValueColor={'text-green-400'}
        // isIconVisible={!isTrendlineVisible}
        isIconVisible={false}
        isRemoveBorderPadding
        titleClasses={
          'inline-flex px-2 h-[20px] rounded !text-white bg-primary-400 !text-12s items-center'
        }
        firstValueClasses={handleFirstValueClasses}
        secondValueClasses={handleSecondValueClasses}
        thirdValueClasses={handleThirdValueClasses}
      />

      <StatusBlock
        firstValue={useCurrencyFormat(getAmountReceived)}
        // secondValue={
        //   !isTrendlineVisible ? getProperties?.payouts_by_property_id?.change_percentage : ''
        // }
        thirdValue={!isTrendlineVisible ? sortingText : ''}
        blockHeading={'Amount Received'}
        iconType={'IncreaseMode'}
        iconColor={'text-green-400'}
        secondValueColor={'text-green-400'}
        isIconVisible={false}
        titleClasses={
          'inline-flex px-2 h-[20px] rounded !text-white bg-secondary-500 !text-12s items-center'
        }
        firstValueClasses={handleFirstValueClasses}
        secondValueClasses={handleSecondValueClasses}
        thirdValueClasses={handleThirdValueClasses}
      />

      <StatusBlock
        firstValue={`${setTotalPayoutsCompletedCount} | ${useCurrencyFormat(
          geTotalPayoutsCompleted
        )}`}
        // secondValue={
        //   !isTrendlineVisible ? getProperties?.customer_paid_payouts?.change_percentage : ''
        // }
        thirdValue={!isTrendlineVisible ? sortingText : ''}
        blockHeading={'Total Payouts Completed'}
        iconType={'IncreaseMode'}
        iconColor={'text-green-400'}
        // secondValueColor={'text-green-400'}
        isIconVisible={false}
        titleClasses={
          'inline-flex px-2 h-[20px] rounded !text-white bg-primary-200 !text-12s items-center'
        }
        firstValueClasses={handleFirstValueClasses}
        // secondValueClasses={handleSecondValueClasses}
        thirdValueClasses={handleThirdValueClasses}
      />
    </Grid>
  );
};
