import { ConfirmationModal } from 'components/core/Modals/ConfirmationModal/ConfirmationModal';
import { ErrorModal } from 'components/core/Modals/ErrorModal/ErrorModal';
import { StatusDropDown } from 'components/core/StatusDropDown/StatusDropDown';
import { useState } from 'react';
import { useReversePayoutsPaymentsMutation } from 'services/payoutsApi';
import { revereseInvoiceStatusDropDownData } from 'constants/statusDropDownData';
import { ReverseInvoiceModalParams } from 'shared/utilities/interface';

export const RevererseInvoiceStatsDropDownCell = ({
  id,
  status,
  invoiceNumber,
  amount,
}: ReverseInvoiceModalParams) => {
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [selectedVal, setSelectedVal] = useState('');
  const [message, setMessage] = useState('');
  const [updateStatus, { isLoading: updateLoading }]: any = useReversePayoutsPaymentsMutation();

  const handleOnStatusChange = (event: any) => {
    const selectedVal = event.target.getAttribute('data-val');
    setSelectedVal(selectedVal);
    setConfirmModalOpen(true);
  };

  const handleErrorModalClose = () => {
    setMessage('');
    setSelectedVal('');
  };

  const handleBackEvent = () => {
    setConfirmModalOpen(false);
    handleErrorModalClose();
  };

  const handleConfirmButtonClick = async () => {
    const response = await updateStatus({ id: id, status: selectedVal });

    if (response) {
      setConfirmModalOpen(false);

      if (response && response.error) {
        const geterrorResponse = response.error.data.message ?? response?.error?.data;
        setMessage(geterrorResponse);
      } else {
        handleErrorModalClose();
      }
    }
  };

  return (
    <>
      <StatusDropDown
        defautlOption={selectedVal !== '' ? selectedVal : status}
        dropDownData={revereseInvoiceStatusDropDownData}
        onOptionClick={handleOnStatusChange}
        additionalClasses={'!w-[102px] items-center inline-flex'}
        additionalDropDownContainerClasses={'w-[102px]'}
        iconContainerClasses={`w-2 ml-[50px] relative -top-[2px] ${
          selectedVal === 'reversed' ? 'hidden' : 'inline-flex'
        }`}
      />

      {confirmModalOpen && (
        <ConfirmationModal
          handleBackEvent={handleBackEvent}
          handleSubmitEvent={handleConfirmButtonClick}
          isIconLoad={updateLoading}
          mainContent={`Are you sure you want to reverse the ${amount} payment for Invoice #${invoiceNumber}?`}
          specialNote={'This action cannot be undone'}
        />
      )}

      {message !== '' && message !== undefined && (
        <ErrorModal
          message={message}
          type="error"
          title="Reverse the payment"
          onCloseModalEvent={handleErrorModalClose}
        />
      )}
    </>
  );
};
