import { handlePropertyStatus } from 'helpers/modules/helperProperties';
import { maskedInput } from './inputHandlers';
import { PropertyParams, ResultObject } from './interface';
import { IProcessSinglePayout } from 'shared/models/components/service.model';

// eslint-disable-next-line @typescript-eslint/ban-types
export default function dtoToFormValues(setValue: Function, data: ResultObject) {
  if (!data) {
    return;
  }
  setValue('first_name', data.individual?.first_name ?? '');
  setValue('last_name', data.individual?.last_name ?? '');
  setValue('business_type', data.business_type);
  setValue('status', data.status ?? '');
  setValue('id', data.id);
  setValue('email', data.individual?.email ?? '');
  setValue('phone_number', maskedInput(data.individual?.phone?.replace('+1', '')) ?? '');
  setValue('status', data.status ?? '');
  const bp = data.business_profile;
  setValue('inputDescription', bp.product_description ?? '');
  setValue('url', bp.url ?? '');
  setValue('inputBusinessName', bp.name ?? '');
  setValue('inputMCC', bp.mcc ?? '');
  const address = data.individual?.address;
  setValue('line1', address?.line1 ?? '');
  setValue('line2', address?.line2 ?? '');
  setValue('city', address?.city ?? '');
  setValue('postal_code', address?.postal_code ?? '');
  setValue('state', address?.state ?? '');
  setValue('code', data?.code ?? '');
  setValue('default_pay_mode', data?.default_pay_mode ?? 'ach');
  if (data.business_type === 'company') {
    setValue('inputCompanyName', data.company?.name ?? '');
    setValue('isRep', data.individual.relationShip.representative);
    setValue('ownership', data.individual.relationShip.owner ? 'owner' : 'executive');
    const companyAddress = data.company?.address;
    setValue('inputOwnerTitle', data.individual.relationShip.title ?? '');
    setValue('inputCompanyPhone', maskedInput(data.company?.phone?.replace('+1', '')) ?? '');
    setValue('inputComCity', companyAddress.city ?? '');
    setValue('inputComLine2', companyAddress.line2 ?? '');
    setValue('inputComLine1', companyAddress.line1 ?? '');
    setValue('inputComPostal', companyAddress.postal_code ?? '');
    setValue('inputComState', companyAddress.state ?? '');
    setValue('status', data.status ?? '');
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function dtoToPropertyFormValues(setValue: Function, data: PropertyParams) {
  if (!data) {
    return;
  }
  setValue('name', data.name ?? '');
  setValue('manager', data.manager ?? '');
  setValue('street_1', data.street_1 ?? '');
  setValue('city', data.city ?? '');
  setValue('state', data.state ?? '');
  setValue('code', data.code ?? '');
  setValue('company_id', data.company_id ?? '');
  setValue('company_name', data.company_name ?? '');
  setValue('phone', maskedInput(data.phone?.replace('+1', '')) ?? '');
  setValue('status', handlePropertyStatus(data.status, data.is_delinquent) ?? '');
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function dtoPaymentSourcesValues(setValue: Function, data: any) {
  if (!data) {
    return;
  }
  setValue('account_holder_name', data.account_holder_name);
  setValue('account_holder_type', data.account_holder_type);
  setValue('routing_number', data.routing_number);
  setValue('account_number', `• • • • • ${data.last4}`);
  setValue('label', data.metadata.label);
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function dtoSinglePayoutProcess(setValue: any, initPayoutProcess: IProcessSinglePayout) {
  if (!initPayoutProcess) {
    return;
  }
  setValue('mode', initPayoutProcess.mode);
  setValue('external_account_payout_id', initPayoutProcess.external_account_payout_id);
  setValue('external_pay_id', initPayoutProcess.external_pay_id);
  setValue('notes', initPayoutProcess.notes);
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function dtoToRecipentFormValues(setValue: Function, data: ResultObject) {
  if (!data) {
    return;
  }
  setValue('address', data?.stripe_account_metadata?.check_recipient_details_address ?? '');
  setValue('line2', data?.stripe_account_metadata?.check_recipient_details_address_2 ?? '');
  setValue('city', data?.stripe_account_metadata?.check_recipient_details_city ?? '');
  setValue('zip', data?.stripe_account_metadata?.check_recipient_details_zip ?? '');
  setValue('state', data?.stripe_account_metadata?.check_recipient_details_state ?? '');
  setValue('name', data?.stripe_account_metadata?.check_recipient_details_name ?? '');
}
