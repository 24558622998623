import { PropertySummaryBlock } from './SummaryBlock/SummaryBlock';
import { ConfirmationModal, DetailsDataRow, EditCard, Message } from 'components/core';
import Modal from 'components/core/Modals/Modal/Modal';
import { useState } from 'react';
import EditPropertyProfileForm from './Form/EditProfile';
import {
  handlePropertyStatus,
  handlePropertyUpdateQueryStates,
} from 'helpers/modules/helperProperties';
import { maskedInput } from 'shared/utilities/inputHandlers';
import { useUpdatePropertiesDetailsMutation } from 'services/properties';
import { useParams } from 'react-router-dom';
import { useTitleCase } from 'hooks/useTitleCase';
import { propertiesStatusDropDownData } from 'constants/statusDropDownData';
import { getByUserNameSorting } from 'shared/utilities/utils';
import moment from 'moment';
import { isPermitted } from 'services/userApi';

export const PropertyDetailsProfileContainer = ({ data, user }: any) => {
  const { id } = useParams();
  const [showEditModal, setShowEditModal] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [selectedVal, setSelectedVal] = useState('');

  const [
    updateProperties,
    { isError: updateError, error: updateErrorMessage, isLoading: updateLoading },
  ]: any = useUpdatePropertiesDetailsMutation();

  const handleStatusChange = (event: any) => {
    const selectedVal = event.target.getAttribute('data-val');
    setSelectedVal(selectedVal);
    setConfirmModalOpen(true);
  };

  const handleBackEvent = () => {
    setConfirmModalOpen(false);
    setSelectedVal('');
  };

  const handleConfirmButtonClick = async () => {
    const filterObj = handlePropertyUpdateQueryStates(selectedVal);
    await updateProperties({ id: id, ...filterObj });
    setConfirmModalOpen(false);
  };

  const setupdatedByUserName = getByUserNameSorting(data, true, false);
  const setLastEdit = data?.updated_at ? moment(data?.updated_at).format('MM/DD/YYYY') : null;

  return (
    <>
      {updateError && (
        <Message type={'error'} title="Property Summary" additionalClasses="mb-6">
          {updateErrorMessage?.data?.message}
        </Message>
      )}

      <div className="flex justify-between w-full">
        <div className="min-w-[442px]">
          <EditCard
            title={data.name}
            isEditIconEnabled={isPermitted(user, 'edit-property-profile')}
            onEditIconClick={() => {
              setShowEditModal(true);
            }}
          >
            <DetailsDataRow title="Property Name" value={data.name} />
            <DetailsDataRow title="Contact" value={data.manager} />
            <DetailsDataRow
              title="Phone"
              value={data.phone ? maskedInput(data.phone?.replace('+1', '')) : null}
            />
            <DetailsDataRow title="Address" value={data.street_1} />
            <DetailsDataRow title="City" value={data.city} />
            <DetailsDataRow title="State" value={data.state} />
            <DetailsDataRow title="Code" value={data.code} additionalClasses="capitalize" />
            <DetailsDataRow
              title="Company ID"
              value={data.company_id}
              additionalClasses="capitalize"
            />
            <DetailsDataRow
              title="Company Name"
              value={data.company_name ? useTitleCase(data.company_name) : null}
              additionalClasses="capitalize"
            />
            <DetailsDataRow title="Last Edit" value={setLastEdit} />
            <DetailsDataRow
              title="Modified By"
              value={setupdatedByUserName !== '' ? setupdatedByUserName : null}
              additionalClasses="capitalize"
            />
            <DetailsDataRow
              title="Status"
              isStatus
              value={
                selectedVal !== ''
                  ? selectedVal
                  : handlePropertyStatus(data.status, data.is_delinquent)
              }
              onStatusChange={handleStatusChange}
              dropDownData={propertiesStatusDropDownData}
              additionalClassesDropdwown={'!w-[135px] !ml-2'}
              isShowStatusWithDropDown={isPermitted(user, 'edit-property-status')}
              rowWrapperClasses="-mt-[3px] mb-4"
            />
          </EditCard>
        </div>
        <div className="w-full pl-10">
          <div className="max-w-[876px]">
            <PropertySummaryBlock />
          </div>
        </div>
      </div>

      {confirmModalOpen && (
        <ConfirmationModal
          handleBackEvent={handleBackEvent}
          handleSubmitEvent={handleConfirmButtonClick}
          isIconLoad={updateLoading}
          mainContent={'Are you sure you want to change the status?'}
        />
      )}

      {showEditModal && (
        <Modal
          isOpen={showEditModal}
          onClose={() => setShowEditModal(false)}
          modalAdditionalClasses={'!w-[500px] items-center justify-center px-4 py-8 flex-col'}
        >
          <EditPropertyProfileForm data={data} onCloseEvent={() => setShowEditModal(false)} />
        </Modal>
      )}
    </>
  );
};
