import LoadingSpinner from 'components/core/LoadingSpinner/LoadingSpinner';
import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Navigate, useParams } from 'react-router-dom';
import { useGetVendorsInfoQuery } from 'services/vendorsApi';
import Icon from '../../../../../core/Media/Icon/Icon';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import dtoToFormValues from '../../../../../../shared/utilities/dto';
import { AccountParams } from '../../../../../../shared/utilities/interface';
import { initAccount } from '../../../../../../shared/models/init/account.init';
import { useUpdateVendorsAccountMutation } from '../../../../../../services/vendorsApi';

import { ERROR_MESSAGES } from '../../../../../../constants/Validation';
import { useOutsideClickHandler } from '../../../../../../hooks/useOutsideComponent';
import Message from '../../../../../core/Message/Message';
import { onChangeHandler } from '../../../../../../helpers/profileHelper';
import { phoneNumberValidation } from '../../../../../../hooks/usePhoneNumberValidation';
import { BusinessProfileSchemaObj } from '../../../../../../helpers/validationSchemas';
import { useBusinessProfileState } from '../../../../../../hooks/useProfileState';
import { Typhography, Button, Input, Grid, StateDropDown } from 'components/core';

interface BusinessProfileProps {
  /**
   * Set the enable edit
   * depend on the parent component
   */
  enableEdit?: boolean;

  /**
   * edit vendor details
   */
  editVendor?: any;
}

const BusinessProfileForm: React.FC<BusinessProfileProps> = ({
  enableEdit = false,
  editVendor,
}) => {
  const { id } = useParams();

  /**
   * expand the default validation schema for phone and dob custom validations
   */
  const profileSchema = yup
    .object({
      ...BusinessProfileSchemaObj,
      inputCompanyPhone: yup
        .string()
        .required(ERROR_MESSAGES.REQUIRED)
        .test('len', ERROR_MESSAGES.PHONE_NUMBER_INVALID, (value) =>
          phoneNumberValidation(value, detailSubmitted)
        ),
    })
    .required();

  /**
   * react hook forms & yap init setup
   */
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useForm<AccountParams>({
    resolver: yupResolver(profileSchema),
    defaultValues: initAccount,
  });

  const [enableReadonlyForm, setEnableReadonlyForm] = useState(enableEdit);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { data, isLoadingVendor, isErrorVender, isSuccess } = useGetVendorsInfoQuery(id);
  const [
    updateVendorsAccount,
    {
      isError: updateError,
      isSuccess: updateSuccess,
      error: errorMessage,
      isLoading: updateLoading,
    },
  ]: any = useUpdateVendorsAccountMutation();

  /**
   * get all useStates form separate hook
   * @return allUseStates
   */
  const [setShowList, detailSubmitted, setDetailSubmitted, originalData, setOriginalData] =
    useBusinessProfileState();

  const wrapperRef = useRef(null);

  useOutsideClickHandler(wrapperRef, setShowList);

  useEffect(() => {
    /**
     * keep original data
     */
    setOriginalData(data);
    dtoToFormValues(setValue, data);
  }, [data]);

  useEffect(() => {
    // init edit potion
    showEditForm(!enableReadonlyForm);
  }, [enableEdit]);

  const showEditForm = (value: boolean) => {
    setEnableReadonlyForm(value);
  };

  const cancelAction = () => {
    showEditForm(false);
    dtoToFormValues(setValue, originalData);
    editVendor && editVendor();
  };

  /**
   * update business basic information of account
   */
  const appSubmit = handleSubmit(async (data: any) => {
    data.inputCompanyPhone = data?.inputCompanyPhone?.replace(/[^0-9]/g, '');
    const response = await updateVendorsAccount({
      obj: data,
      isCompany: true,
      isMappingRequired: true,
    });

    if (!response?.error) {
      setTimeout(() => {
        cancelAction();
      }, 2000);
    }
  });

  return (
    <div className="flex flex-col w-full">
      {/**
       * Handle loader
       */}
      {(updateLoading || isLoadingVendor) && <LoadingSpinner />}

      <Typhography component="p" type="h2" color="text-grey-900 mb-6 uppercase">
        {'Edit Business Details'}
      </Typhography>

      <div className="absolute cursor-pointer top-7 right-4 text-grey-600" onClick={cancelAction}>
        <Icon iconColor="inherit" iconType="CloseIcon" />
      </div>

      {(updateError || updateSuccess) && (
        <Message
          type={updateSuccess ? 'success' : 'error'}
          title="Business Details"
          additionalClasses="mb-6"
        >
          {updateSuccess
            ? 'Your business details are successfully updated.'
            : errorMessage?.data?.message}
        </Message>
      )}
      {/**
       * Error handling
       */}
      {isErrorVender && <Navigate to={'/401'} />}
      {isSuccess && (
        <div className="flex flex-col w-full">
          <form onSubmit={appSubmit} className="flex flex-col w-full">
            <Controller
              control={control}
              name="inputCompanyName"
              render={({ field: { value } }) => (
                <Input
                  id="inputCompanyName"
                  labelName={'inputCompanyName'}
                  type="text"
                  label="Company Name"
                  {...register('inputCompanyName')}
                  error={errors?.inputCompanyName?.message}
                  value={value}
                />
              )}
            />

            <Controller
              control={control}
              name="inputCompanyPhone"
              render={({ field: { value } }) => (
                <Input
                  id="inputCompanyPhone"
                  labelName={'Company Phone'}
                  type="text"
                  label="Company Phone"
                  {...register('inputCompanyPhone')}
                  error={errors?.inputCompanyPhone?.message}
                  maxLength={13}
                  onChange={(event: any) => onChangeHandler(event, getValues, setValue)}
                  value={value}
                />
              )}
            />

            <Controller
              control={control}
              name="inputComLine1"
              render={({ field: { value } }) => (
                <Input
                  id="inputComLine1"
                  type="text"
                  label={'Street'}
                  labelName={'Street'}
                  {...register('inputComLine1')}
                  error={errors?.inputComLine1?.message}
                  value={value}
                />
              )}
            />

            <Controller
              control={control}
              name="inputComLine2"
              render={({ field: { value } }) => (
                <Input
                  id="inputComLine2"
                  type="text"
                  {...register('inputComLine2')}
                  error={errors?.inputComCity?.message}
                  value={value}
                />
              )}
            />

            <Grid columnCount={3} columnGap={2}>
              <Controller
                control={control}
                name="inputComCity"
                render={({ field: { value } }) => (
                  <Input
                    id="inputComCity"
                    label={'City'}
                    labelName={'City'}
                    type="text"
                    {...register('inputComCity')}
                    error={errors?.inputComCity?.message}
                    value={value}
                  />
                )}
              />

              <StateDropDown register={register} uniqueID={'inputComState'} />

              <Controller
                control={control}
                name="inputComPostal"
                render={({ field: { value } }) => (
                  <Input
                    id="inputComPostal"
                    label={'Postal Code'}
                    labelName={'Postal Code'}
                    type="text"
                    {...register('inputComPostal')}
                    error={errors?.inputComPostal?.message}
                    value={value}
                  />
                )}
              />
            </Grid>

            <Grid columnCount={2} columnGap={2}>
              <Controller
                control={control}
                name="business_type"
                render={({ field: { value } }) => (
                  <Input
                    id="business_type"
                    labelName={'Business Type'}
                    type="text"
                    readOnly={true}
                    label="Business Type"
                    {...register('business_type')}
                    value={value}
                  />
                )}
              />
              <Controller
                control={control}
                name="inputDescription"
                render={({ field: { value } }) => (
                  <Input
                    id="inputDescription"
                    labelName={'Industry'}
                    type="text"
                    readOnly={true}
                    label="Industry"
                    {...register('inputDescription')}
                    value={value}
                  />
                )}
              />
            </Grid>

            <Controller
              control={control}
              name="url"
              render={({ field: { value } }) => (
                <Input
                  id="url"
                  labelName={'Website'}
                  type="text"
                  readOnly={true}
                  label="Website"
                  {...register('url')}
                  value={value}
                />
              )}
            />
            <div className="flex w-full pt-4">
              <Button
                type="submit"
                buttonLabel={`Update`}
                iconType="Spin"
                isIconEnabled={false}
                additionalClasses={'w-full'}
                onClick={() => {
                  setDetailSubmitted(true);
                }}
              />
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default BusinessProfileForm;
