import { DelinquentPropertiesContainer } from 'components/modules/Properties/Delinquent/Delinquent';

/**
 * Primary UI component for user interaction
 */
const DelinquentProperties = () => {
  return <DelinquentPropertiesContainer />;
};

export default DelinquentProperties;
