export const commonClasses =
  'h-6 capitalize text-12s font-medium inline-flex items-center justify-center rounded px-1';
export const commonDropdownClasses =
  'h-6 capitalize text-12s font-medium inline-flex items-center justify-between rounded px-2 cursor-pointer';
export const handleBeforeDotClasse =
  "before:content-[''] before:w-[6px] before:h-[6px] before:inline-flex before:rounded-full before:mr-1";
export const handleTextAlignMentForPayoutsTags = '!justify-start !px-2';

export const handleTagClasses = (status?: string | undefined) => {
  switch (status) {
    case 'PAID':
      return `text-primary-500 bg-grey-50 w-[108px] ${handleTextAlignMentForPayoutsTags}`;
    case 'APPROVED':
      return `text-green-400 bg-green-50 w-[108px] ${handleTextAlignMentForPayoutsTags}`;
    case 'paid':
      return `text-primary-500 bg-grey-50 w-[108px] ${handleTextAlignMentForPayoutsTags}`;
    case 'approved':
      return `text-green-400 bg-green-50 w-[108px] ${handleTextAlignMentForPayoutsTags}`;
    case 'INVOICED':
      return `text-yellow-500 bg-yellow-50 w-[108px] ${handleTextAlignMentForPayoutsTags}`;
    case 'DENIED':
      return `text-red-500 bg-red-50 w-[108px] ${handleTextAlignMentForPayoutsTags}`;
    case 'TERMS_ACCEPTED':
      return `text-yellow-500 bg-yellow-50 w-[110px] ${handleTextAlignMentForPayoutsTags}`;
    case 'REVERSED':
      return `text-red-500 bg-red-50 w-[108px] ${handleTextAlignMentForPayoutsTags}`;
    case 'reversed':
      return `text-red-500 bg-red-50 w-[108px] ${handleTextAlignMentForPayoutsTags}`;
    case 'ACTIVE':
      return `text-green-500 bg-white w-[64px] ${handleBeforeDotClasse} before:bg-green-500 px-2 border border-solid border-green-500 rounded-full`;
    case 'INACTIVE':
      return `text-red-500 bg-white w-[64px] ${handleBeforeDotClasse} before:bg-red-500 px-2 border border-solid border-red-500 rounded-full`;
    case 'active':
      return `text-green-500 bg-white w-[64px] ${handleBeforeDotClasse} before:bg-green-500 px-2 border border-solid border-green-500 rounded-full`;
    case 'inactive':
      return `text-red-500 bg-white w-[64px] ${handleBeforeDotClasse} before:bg-red-500 px-2 border border-solid border-red-500 rounded-full`;
    case 'Delinquent':
      return `text-red-500  w-[84px] border border-solid border-red-500`;
    case 'Non-Delinquent':
      return `text-green-400  w-[84px] border border-solid border-green-400`;
    case 'Inactive-Property':
      return `text-grey-400  w-[84px] border border-solid border-grey-400`;
    case 'Scheduled':
      return `text-green-400 bg-green-50 w-[108px] ${handleTextAlignMentForPayoutsTags}`;
    case 'Auto Scheduled':
      return `text-green-400 bg-green-50 w-[106px] ${handleTextAlignMentForPayoutsTags}`;
    case 'Default':
      return `h-[18px] border border-solid border-grey-100 bg-grey-50 text-grey-900`;
    case 'verified':
      return `text-green-400 bg-green-50 w-[78px]`;
    case 'verify_now':
      return `text-yellow-400 bg-yellow-50 w-[78px]`;
    case 'Refunded':
      return `text-yellow-500 bg-yellow-50 w-[108px]`;
    case 'Success':
      return `text-green-400 bg-green-50 w-[88px]`;
    case 'Failed':
      return `text-red-500 bg-red-50 w-[88px]`;
    case 'Pending':
      return `text-yellow-500 bg-yellow-50 w-[88px]`;
    case 'Enabled':
      return `text-green-400 bg-green-50 w-[88px]`;
    case 'Disabled':
      return `text-red-400 bg-red-50 w-[88px]`;
    case 'Inactive':
      return `text-grey-50 bg-grey-300 w-[88px]`;
    case 'Instant':
      return `h-[18px] border border-solid border-secondary-100 bg-secondary-50 text-secondary-300`;
    case 'UNPAID':
      return `text-yellow-500 bg-yellow-50 w-[108px] ${handleTextAlignMentForPayoutsTags}`;
    case 'IN_PROCESS':
      return `text-green-400 bg-green-50 w-[108px] ${handleTextAlignMentForPayoutsTags}`;
    case 'VOID':
      return `text-red-500 bg-red-50 w-[108px] ${handleTextAlignMentForPayoutsTags}`;
    case 'EXPIRED':
      return `text-red-500 bg-red-50 w-[108px] ${handleTextAlignMentForPayoutsTags}`;
    case 'PRINTED':
      return `text-green-400 bg-green-50 w-[108px] ${handleTextAlignMentForPayoutsTags}`;
    case 'MAILED':
      return `text-green-400 bg-green-50 w-[108px] ${handleTextAlignMentForPayoutsTags}`;
    case 'FAILED':
      return `text-red-500 bg-red-50 w-[108px]`;
    case 'REFUNDED':
      return `text-yellow-500 bg-yellow-50 w-[108px]`;
    case 'CHECK_PAID':
      return `text-primary-500 bg-grey-50 w-[108px] ${handleTextAlignMentForPayoutsTags}`;
    case 'in_transit':
      return `text-yellow-500 bg-yellow-50 w-[108px] ${handleTextAlignMentForPayoutsTags}`;
    default:
      return `text-yellow-500 bg-yellow-50 w-[74px]`;
  }
};

export const handleTagText = (status?: string | undefined) => {
  switch (status) {
    case 'PAID':
      return `Paid`;
    case 'APPROVED':
      return `Approved`;
    case 'INVOICED':
      return `Submitted`;
    case 'DENIED':
      return `Void`;
    case 'TERMS_ACCEPTED':
      return `Terms Accepted`;
    case 'REVERSED':
      return `Reversed`;
    case 'ACTIVE':
      return `Active`;
    case 'INACTIVE':
      return `Inactive`;
    case 'active':
      return `Active`;
    case 'inactive':
      return `Inactive`;
    case 'Delinquent':
      return `Delinquent`;
    case 'Non-Delinquent':
      return `Active`;
    case 'Inactive-Property':
      return `Inactive`;
    case 'Scheduled':
      return `Scheduled`;
    case 'Auto Scheduled':
      return 'Auto Scheduled';
    case 'Default':
      return 'Default';
    case 'verified':
      return 'Verified';
    case 'verify_now':
      return `Verify`;
    case 'Refunded':
      return `Refunded`;
    case 'Success':
      return `Success`;
    case 'Failed':
      return `Failed`;
    case 'Pending':
      return `Pending`;
    case 'Enabled':
      return `Enabled`;
    case 'Disabled':
      return `Disabled`;
    case 'reversed':
      return `Reverse`;
    case 'UNPAID':
      return `Unpaid`;
    case 'IN_PROCESS':
      return `Check Issued`;
    case 'VOID':
      return `Check Voided`;
    case 'EXPIRED':
      return `Check Expired`;
    case 'PRINTED':
      return `Check Printed`;
    case 'MAILED':
      return `Check In Transit`;
    case 'FAILED':
      return `Check Failed`;
    case 'REFUNDED':
      return `Check Refunded`;
    case 'CHECK_PAID':
      return `Check Cashed`;
    case 'in_transit':
      return `In Transit`;
    default:
      return status;
  }
};
