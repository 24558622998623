import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
// import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';

import {
  Typhography,
  Button,
  Input,
  Grid,
  // Message,
  // LoadingSpinner,
  Icon,
} from 'components/core';

import { phoneNumberValidation } from '../../../../../../hooks/usePhoneNumberValidation';

import { initAccount } from '../../../../../../shared/models/init/account.init';
// import { useUpdateVendorsAccountMutation } from '../../../../../../services/vendorsApi';

// import dtoToFormValues from '../../../../../../shared/utilities/dto';
import { PropertyParams } from '../../../../../../shared/utilities/interface';
import { ERROR_MESSAGES } from '../../../../../../constants/Validation';

import { onPhoneNumberChangeHandler } from '../../../../../../helpers/profileHelper';
import { PropertyProfileSchemaObj } from '../../../../../../helpers/validationSchemas';
import stateData from '../../../../../../constants/state.json';
import { EditPropertyProfileFormProps } from 'shared/models/components/base.model';

const AddPropertyForm = ({ onCloseEvent }: EditPropertyProfileFormProps) => {
  const [detailSubmitted, setDetailSubmitted] = useState(false);

  /**
   * expand the default validation schema for phone and dob custom validations
   */
  const profileSchema = yup
    .object({
      ...PropertyProfileSchemaObj,
      phone_number: yup
        .string()
        .required(ERROR_MESSAGES.REQUIRED)
        .test('len', ERROR_MESSAGES.PHONE_NUMBER_INVALID, (value) =>
          phoneNumberValidation(value, detailSubmitted)
        ),
    })
    .required();

  /**
   * react hook forms & yap init setup
   */
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useForm<PropertyParams>({
    resolver: yupResolver(profileSchema),
    defaultValues: initAccount,
  });

  /**
   * update basic information of account
   */
  const appSubmit = handleSubmit(async (data: any) => {
    if (moment(data?.dob).isValid()) {
      const formData = data;
      data.phone_number = data?.phone_number?.replace(/[^0-9]/g, '');

      console.log(formData);
    }
  });

  return (
    <div className="flex flex-col w-full">
      {/**
       * Handle loader
       */}

      <Typhography component="p" type="h2" color="text-grey-900 mb-6 uppercase">
        {'Add New Property'}
      </Typhography>

      <div className="absolute cursor-pointer top-7 right-4 text-grey-600" onClick={onCloseEvent}>
        <Icon iconColor="inherit" iconType="CloseIcon" />
      </div>

      <div className="flex flex-col w-full">
        <form onSubmit={appSubmit} className="flex flex-col w-full">
          <div className="flex flex-col w-full">
            <Grid columnCount={2} columnGap={2}>
              <Controller
                control={control}
                name="name"
                render={({ field: { value } }) => (
                  <Input
                    id="name"
                    labelName={'Property Name'}
                    type="text"
                    label="Property Name"
                    {...register('name')}
                    error={errors?.name?.message}
                    value={value}
                  />
                )}
              />

              <Controller
                control={control}
                name="manager"
                render={({ field: { value } }) => (
                  <Input
                    id="manager"
                    labelName={'manager'}
                    type="text"
                    label="Contact"
                    {...register('manager')}
                    error={errors?.manager?.message}
                    value={value}
                  />
                )}
              />
            </Grid>

            <Controller
              control={control}
              name="street_1"
              render={({ field: { value } }) => (
                <Input
                  id="street_1"
                  labelName="street_1"
                  label={'Address'}
                  type="text"
                  {...register('street_1')}
                  error={errors?.street_1?.message}
                  value={value}
                />
              )}
            />

            <Grid columnCount={2} columnGap={2}>
              <Controller
                control={control}
                name="city"
                render={({ field: { value } }) => (
                  <Input
                    id="city"
                    type="text"
                    label={'City'}
                    labelName="City"
                    {...register('city')}
                    error={errors?.city?.message}
                    value={value}
                  />
                )}
              />

              <div className="relative flex flex-col w-full mb-4 form-input-wrapper focused">
                <select
                  {...register('state')}
                  id="state"
                  className="form-select outline-none h-[42px] border border-solid border-grey-100 rounded-lg text-black placeholderColor-gray  text-14s text-grey-900 px-4 focus:!border-grey-100 focus:!shadow-none"
                  size={1}
                >
                  {stateData &&
                    stateData.map((item, index) => (
                      <option key={index} value={item.abbreviation}>
                        {item.abbreviation} - {item.name}
                      </option>
                    ))}
                </select>
                <label
                  htmlFor={'state'}
                  className={'text-14s font-normal text-grey-600 top-3 left-4 absolute'}
                >
                  {'State'}
                </label>
              </div>
            </Grid>

            <Controller
              control={control}
              name="code"
              render={({ field: { value } }) => (
                <Input
                  id="code"
                  type="text"
                  label={'Code'}
                  labelName="Code"
                  {...register('code')}
                  error={errors?.code?.message}
                  value={value}
                  additionalClasses={'capitalize'}
                />
              )}
            />

            <Controller
              control={control}
              name="phone"
              render={({ field: { value } }) => (
                <Input
                  id="phone"
                  labelName={'Phone'}
                  type="text"
                  label="Phone"
                  error={errors?.phone?.message}
                  {...register('phone')}
                  maxLength={14}
                  onChange={(event) => onPhoneNumberChangeHandler(event, setValue, getValues)}
                  value={value}
                />
              )}
            />
          </div>

          <div className="flex w-full pt-4">
            <Button
              type="submit"
              buttonLabel={`Update`}
              additionalClasses="w-full"
              onClick={() => {
                setDetailSubmitted(true);
              }}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddPropertyForm;
