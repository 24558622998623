import React from 'react';
import VendorAccountContainer from '../../../components/modules/Vendors/Account/Account';

/**
 * Primary UI component for user interaction
 */
const VendorAccount = () => {
  return <VendorAccountContainer />;
};

export default VendorAccount;
