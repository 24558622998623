import React from 'react';
import Typho from '../../OldComponents/Typho/Typho';

export interface SummaryDetailBlockProps {
  isSmall?: boolean;
  label?: string | number;
  subLabel?: string;
}

export const SummaryDetailBlock = ({
  isSmall = false,
  label,
  subLabel,
}: SummaryDetailBlockProps) => {
  return (
    <div
      className={`${
        isSmall ? 'min-h-[68px]' : 'min-h-[83px]'
      } rounded bg-lightBlue200 flex flex-col justify-center items-center px-2`}
    >
      <Typho
        weight="bold"
        type="p"
        size={isSmall ? '14s' : '18s'}
        additionalClasses="text-primaryBlue mb-[6px] mt-0"
      >
        {label ? label : ''}
      </Typho>
      <Typho
        weight="thin"
        additionalClasses={`${
          isSmall ? 'text-[8px]' : 'text-[9px]'
        } text-black-900 text-center uppercase`}
        type="p"
      >
        {subLabel ? subLabel : ''}
      </Typho>
    </div>
  );
};
