import { Grid } from 'components/core/Grid/Grid';
import { StatusBlock } from 'components/core/StatusBlock/StatusBlock';
import { useCurrencyFormat } from 'hooks/useCurrencyFormat';

interface ITransactionStatsBar {
  data?: any;
}

export const TransactionStatsBar = ({ data }: ITransactionStatsBar) => {
  const totalCount = data?.totalCount;
  const totalVolume = useCurrencyFormat(data?.aggregates?.sum?.amount);
  const processingFee = useCurrencyFormat(data?.aggregates?.sum?.processing_fee_amount);

  return (
    <Grid columnCount={3} additionalClasses={'w-full mb-2'}>
      <StatusBlock
        firstValue={`${totalCount}`}
        blockHeading={'Total Payments'}
        isIconVisible={false}
        isRemoveBorderPadding
      />
      <StatusBlock firstValue={totalVolume} blockHeading={'Total Volume'} isIconVisible={false} />
      <StatusBlock
        firstValue={processingFee}
        blockHeading={'Processing Fee'}
        isIconVisible={false}
      />
    </Grid>
  );
};
