import Wrapper from 'components/layouts/Wrapper/Wrapper';
import { DataList, Headers, LoadingSpinner, ToolBar, TransactionStatsBar } from 'components/core';
import { transactionTabnavData } from 'constants/TabNavData';
import { useGetAllTransactionsQuery } from 'services/payoutsApi';
import { CONFIG } from 'shared/configs/config';
import ListEmpty from '../Common/ListEmpty/ListEmpty';
import { useState } from 'react';

export const CurrentTransactionsContainer = () => {
  const filterParams = {
    first: CONFIG.DATA_OVERALL_LIMIT,
    offset: 0,
    details: false,
    as_user: true,
    filter: {
      paid_at: { isNull: false },
      status: { notEqualTo: '"cancelled"' },
    },
  };

  const { data, isSuccess, isLoading } = useGetAllTransactionsQuery(filterParams);

  const totalCount = isSuccess ? data?.payoutBatchTransfers?.totalCount : 0;

  const [visibleColumns, setVisibleColumns] = useState([]);
  const [filteredRow, setFilteredRow] = useState([]);

  return (
    <Wrapper additionalClasses="flex-col items-start" pageTitle="Payments">
      {isLoading && <LoadingSpinner />}
      <Headers headingContent="Payments" />
      <ToolBar
        navigationData={transactionTabnavData}
        isShowOptionEnabled={false}
        visibleColumns={visibleColumns}
        filteredRow={filteredRow}
        isDownloadCsvButtonEnabled={isSuccess && totalCount !== 0}
        isDownloadCompleteData={false}
      />
      {isSuccess && (
        <>
          <TransactionStatsBar data={data?.payoutBatchTransfers} />
          {totalCount !== 0 ? (
            <DataList
              data={data?.payoutBatchTransfers?.nodes}
              tableType="transactions"
              tableWrapperType="grid-height-normal"
              isPagination
              subTabType="currentTransactions"
              setVisibleColumns={setVisibleColumns}
              setFilteredRow={setFilteredRow}
              isServerSidePagination={false}
            />
          ) : (
            <ListEmpty />
          )}
        </>
      )}
    </Wrapper>
  );
};
