import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import Wrapper from 'components/layouts/Wrapper/Wrapper';
import Headers from 'components/core/Headers/Headers';
import { Button, Input, LoadingSpinner, Message, ToolBar, Typhography } from 'components/core';
import { Controller, useForm } from 'react-hook-form';
import { CONFIG } from 'shared/configs/config';
import {
  useGetPropertiesQuery,
  useReconcilationUpdateMutation,
  useUnReconcilationUpdateMutation,
} from 'services/properties';
import Label from 'components/core/FormElements/Label/Label';
import { useState } from 'react';

export interface IManualReconcileForm {
  pay_ids?: any;
  paid_with?: 'check';
  property_id?: string;
  type?: 'Reconcile' | 'Unreconcile';
}

export const defaultValues: IManualReconcileForm = {
  pay_ids: '',
  paid_with: 'check',
  type: 'Reconcile',
};

const ReconcileManualContainer = () => {
  const [successResponse, setSuccessResponse]: any = useState([]);
  const [requestType, setRequestType]: any = useState();
  const [messageType, setMessageType]: any = useState();
  const [message, setMessage]: any = useState('');
  const [isSuccessDetailsVisible, setIsSuccessDetailsVisible]: any = useState(false);

  // const handleViewMoreButtonHandler = () => setIsSuccessDetailsVisible(!isSuccessDetailsVisible);

  const formSchema = yup
    .object({
      pay_ids: yup.string().required('Please enter the Payout IDs'),
      property_id: yup.string().required('Please select the Property ID'),
    })
    .required();

  /**
   * react hook forms & yap init setup
   */
  const {
    register,
    handleSubmit,
    // setValue,
    control,
    formState: { errors },
  } = useForm<IManualReconcileForm>({
    resolver: yupResolver(formSchema),
    defaultValues: defaultValues,
  });

  const AllPropertiesFilterParam = {
    limit: CONFIG.DATA_OVERALL_LIMIT,
    offset: 0,
    orderBy: 'NAME_ASC',
    is_delinquent: { equalTo: false },
    status: { equalTo: 'ACTIVE' },
  };

  const { data: propertyData, isSuccess } = useGetPropertiesQuery(AllPropertiesFilterParam);
  const [reconcileMutation, { isLoading: reconcileLoading }] = useReconcilationUpdateMutation();
  const [unReconcileMutation] = useUnReconcilationUpdateMutation();

  const appSubmit = handleSubmit(async (data: any) => {
    const getPayoutIds = data.pay_ids?.split(',');
    const getInToStringArray = getPayoutIds.filter(String);
    const createUniqueArray = getInToStringArray.filter((c: any, index: any) => {
      return getInToStringArray.indexOf(c) === index;
    });

    let response: any;

    try {
      const objectParam = {
        pay_ids: createUniqueArray,
        paid_with: data.paid_with,
        property_id: data.property_id,
      };

      if (data.type === 'Reconcile') {
        response = await reconcileMutation(objectParam);
      }
      if (data.type === 'Unreconcile') {
        response = await unReconcileMutation(objectParam);
      }

      if (response) {
        if (!response.error) {
          setSuccessResponse(response.data);
          setMessageType('success');

          if (data.type === 'Reconcile') {
            setMessage(
              'Reconcile request processed successfully, click view more button to get more details'
            );
            setRequestType('Reconcile');
          }
          if (data.type === 'Unreconcile') {
            setMessage(
              'Unreconcile request processed successfully, click view more button to get more details'
            );
            setRequestType('Unreconcile');
          }
        } else {
          setSuccessResponse([]);
          setMessage(response.error.data.message ?? response?.error?.data ?? response?.error);
          setMessageType('error');
        }
      }
    } catch (e: any) {
      setSuccessResponse([]);
      setMessage(e.data ?? e.message);
      setMessageType('error');
    }
  });

  const propertyDropDownData = isSuccess ? propertyData.properties.nodes : [];

  const handleSuccessResponse = () => {
    setIsSuccessDetailsVisible(!isSuccessDetailsVisible);
  };

  return (
    <>
      {reconcileLoading && <LoadingSpinner />}

      <Wrapper additionalClasses="flex-col items-start" pageTitle="Reconciliation">
        <Headers headingContent="Reconciliation" />
        <ToolBar
          navigationData={[
            {
              id: 1,
              urlPath: '/reconcile/checks',
              navTitle: 'Mercury',
              navClasses: 'inactive',
            },
            {
              id: 2,
              urlPath: '/reconcile/ach',
              navTitle: 'ACH',
              navClasses: 'inactive ',
            },
            {
              id: 3,
              urlPath: '/reconcile/manual',
              navTitle: 'Manual',
              navClasses: 'active ',
            },
          ]}
          isShowOptionEnabled={false}
          isCountVisible={false}
        />
        <div className="flex flex-col w-full pt-6">
          <div className="px-6 pt-[18px] pb-6 border border-solid rounded-lg relative bg-white cursor-pointer border-grey-100 max-w-[800px]">
            <form onSubmit={appSubmit} className="flex flex-col w-full">
              <div className="flex flex-col w-full">
                <div className="flex flex-col w-full mb-6">
                  <div className="flex w-full pt-3">
                    <div className="relative mr-6 overflow-hidden form-check">
                      <Label
                        name={'field-reconcile'}
                        labelSpanClasses="radio-span justify-center items-center inline-flex"
                      >
                        <input
                          {...register('type')}
                          type="radio"
                          value="Reconcile"
                          id="field-reconcile"
                        />
                        <span className="mr-2 radio-mask" />
                        {'Reconcile'}
                      </Label>
                    </div>
                    <div className="relative mb-2 mr-6 overflow-hidden form-check">
                      <Label
                        name={'field-unreconcile'}
                        labelSpanClasses="radio-span justify-center items-center inline-flex"
                      >
                        <input
                          {...register('type')}
                          type="radio"
                          value="Unreconcile"
                          id="field-unreconcile"
                        />
                        <span className="mr-2 radio-mask" />
                        {'Unreconcile'}
                      </Label>
                    </div>
                  </div>
                </div>

                <Controller
                  control={control}
                  name="pay_ids"
                  render={({ field: { value } }) => (
                    <Input
                      id="pay_ids"
                      labelName={'pay_ids'}
                      type="text"
                      label="Comma Separated Payout Numbers"
                      {...register('pay_ids')}
                      error={errors?.pay_ids?.message}
                      value={value}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="paid_with"
                  render={() => (
                    <Input
                      id="paid_with"
                      type="text"
                      label={'Paid With'}
                      labelName="paid_with"
                      {...register('paid_with')}
                      value={'Check'}
                      readOnly
                    />
                  )}
                />

                <div className="relative flex flex-col w-full mb-4 form-input-wrapper focused">
                  <select
                    {...register('property_id')}
                    id="property_id"
                    className="form-select no-arrow outline-none bg-transparent border border-solid border-grey-100 rounded-lg text-black placeholderColor-gray  text-14s text-grey-900 px-4 focus:!border-grey-100 focus:!shadow-none max-h-[200px]"
                    size={10}
                  >
                    {propertyDropDownData &&
                      propertyDropDownData.map((item: any, index: any) => (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                  </select>
                  <label
                    htmlFor={'property_id'}
                    className={'text-14s font-normal text-grey-600 top-3 left-4 absolute'}
                  >
                    {'Property'}
                  </label>
                </div>

                <div className="flex w-full pt-4">
                  <Button
                    type="submit"
                    buttonLabel={`Submit`}
                    iconType="Spin"
                    isIconEnabled={false}
                    additionalClasses={'w-full'}
                  />
                </div>
              </div>
            </form>
          </div>

          {message !== '' && messageType === 'error' && (
            <Message
              type={'error'}
              title={`Manual ${
                requestType === 'Reconcile' ? 'Reconciliation' : 'Unreconciliation'
              }`}
              additionalClasses={`mb-6 mt-6 max-w-[600px]`}
            >
              <Typhography
                color="text-grey-600"
                additionalClasses={`!font-normal ${isSuccessDetailsVisible ? 'mb-3' : 'mb-0'}`}
                component="p"
                type="h2"
              >
                {message}
              </Typhography>
            </Message>
          )}

          {successResponse &&
            successResponse.length > 0 &&
            messageType === 'success' &&
            messageType !== 'error' && (
              <Message
                type={'success'}
                title={`Manual ${
                  requestType === 'Reconcile' ? 'Reconciliation' : 'Unreconciliation'
                }`}
                additionalClasses={`mb-6 !items-start mt-6 max-w-[800px]`}
                messageContentClasses={'!items-start'}
                buttonLabel={!isSuccessDetailsVisible ? 'View More' : 'View Less'}
                onClick={handleSuccessResponse}
                messageIconClasses={'mt-[10px]'}
                buttonClasses={'w-[100px] mt-2'}
                isButtonInside
              >
                <Typhography
                  color="text-grey-600"
                  additionalClasses={`!font-normal ${isSuccessDetailsVisible ? 'mb-3' : 'mb-0'}`}
                  component="p"
                  type="h2"
                >
                  {message}
                </Typhography>

                {isSuccessDetailsVisible && (
                  <div className="flex flex-col w-full">
                    {successResponse &&
                      successResponse.map((item: any, index: any) => {
                        return (
                          <Typhography
                            color="text-grey-600"
                            additionalClasses="mb-1 !font-normal"
                            component="p"
                            type="h1"
                            key={index}
                          >
                            {`• ${item.message}`}
                          </Typhography>
                        );
                      })}
                  </div>
                )}
              </Message>
            )}
        </div>
      </Wrapper>
    </>
  );
};

export default ReconcileManualContainer;
