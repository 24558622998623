import { useEffect } from 'react';
import { PropertiesSummaryStatsBar, SelectBox, Typhography } from 'components/core';
import { SummaryGraph } from 'components/modules/Common/Graph/Graph';
import { getURLParams, useDateRangeSorting } from 'hooks/useDateRangeSorting';
import moment from 'moment';
import { useParams, useNavigate, createSearchParams } from 'react-router-dom';
import { useGetPropertiesQuery, useGetPropertiesSummaryGraphDataQuery } from 'services/properties';
import { CONFIG } from 'shared/configs/config';

export const PropertySummaryBlock = () => {
  const { id } = useParams();
  const [
    rangeSelector,
    defaultSortingText,
    itemSlectedState,
    showDropdown,
    handlerSortingDropDownClick,
    handlerSortingOption,
  ]: any = useDateRangeSorting('All');
  const navigate = useNavigate();

  useEffect(() => {
    navigate(
      {
        pathname: `/properties/profile/${id}`,
        search: `?${createSearchParams(getURLParams(defaultSortingText))}`,
      },
      { replace: true }
    );
  }, [defaultSortingText]);

  const getGreaterThanDate =
    rangeSelector > 0
      ? moment()
          .subtract(rangeSelector, 'day')
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
          .format()
      : undefined;

  const getLessThanDate = moment()
    .add(1, 'day')
    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    .format();

  const graphPropertiesParams = {
    limit: CONFIG.DATA_OVERALL_LIMIT,
    offset: 0,
    filter: {
      id: { equalTo: JSON.stringify(id) },
      payoutsByPropertyId: {
        paid_at: { lessThan: getLessThanDate, greaterThan: getGreaterThanDate },
      },
    },
    groupBy: 'PAID_AT_TRUNCATED_TO_DAY',
  };

  const { data: graphData, isSuccess } =
    useGetPropertiesSummaryGraphDataQuery(graphPropertiesParams);
  const getNodes: any =
    isSuccess && graphData.properties.nodes[0].payoutsByPropertyId.groupedAggregates;

  const propertiesFilterParam = {
    limit: CONFIG.DATA_OVERALL_LIMIT,
    offset: 0,
    filter: {
      id: { equalTo: JSON.stringify(id) },
      payoutsByPropertyId: {
        created_at: { lessThan: getLessThanDate, greaterThan: getGreaterThanDate },
      },
    },
  };

  const { data: propertyData, isSuccess: propertyDataSuccess } =
    useGetPropertiesQuery(propertiesFilterParam);

  return (
    <div className="flex flex-col justify-start w-full">
      <div className="flex justify-between w-full mb-4">
        <Typhography component="p" type="h2" color="text-grey-900">
          {'PROPERTY SUMMARY'}
        </Typhography>
        <SelectBox
          itemClickHandler={handlerSortingOption}
          defaultSelectBoxText={defaultSortingText}
          itemSelected={itemSlectedState}
          dropDownState={showDropdown}
          dropDownClickHandler={handlerSortingDropDownClick}
        />
      </div>
      {propertyDataSuccess && (
        <PropertiesSummaryStatsBar data={propertyData} sortingText={defaultSortingText} />
      )}
      <SummaryGraph
        getNodes={getNodes}
        getLessThanDate={getLessThanDate}
        getGreaterThanDate={getGreaterThanDate}
        rangeSelector={rangeSelector}
        isGraphLoadingScuccess={isSuccess}
      />
    </div>
  );
};
