import React from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import Icon from '../Media/Icon/Icon';

const domNode: any = document.getElementById('loader-root');

export interface LoadingSpinnerprops {
  additionalClasses?: string;
  isLeftSpacingDisabled?: boolean;
}

const LoadingSpinner = ({
  additionalClasses,
  isLeftSpacingDisabled = true,
}: LoadingSpinnerprops) => {
  const defaultClasses =
    'fixed top-0 bottom-0 right-0 flex items-center justify-center duration-300 ease-in-out text-primary-500 bg-grey-900/60 z-[90]';

  const handleLoaderClasses = [
    defaultClasses,
    additionalClasses,
    isLeftSpacingDisabled ? 'left-0' : 'left-[92px]',
  ];

  return ReactDOM.createPortal(
    <div className={classNames(handleLoaderClasses)}>
      <Icon iconType="Spin" iconColor="inherit" iconContainerClasses="w-12 h-12 flex" />
    </div>,
    domNode
  );
};

export default LoadingSpinner;
