import React, { FC, forwardRef, TextareaHTMLAttributes } from 'react';

import classNames from 'classnames';
import { Classes } from '../../../../shared/configs/componentsClasses';
import InputValidation from '../InputValidation/InputValidation';

type InputType = 'text' | 'email' | 'password' | 'name' | 'date';

interface HookFormsInputProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  /**
   * Set Input type
   */
  type?: InputType;
  /**
   * Set new variant of the input
   */
  inputVariant?: 'default' | 'large';
  /**
   * Set Input name
   */
  id?: string;
  /**
   * Set Input name
   */
  labelName?: string;
  /**
   * Set the Label Type
   */
  labelType?: 'default' | 'black' | 'smallSelect' | 'blackSmall';
  /**
   * Set Input label name
   */
  label?: string | undefined;
  /**
   * Ser Error message
   */
  error?: string;
  /**
   * Ser Error message
   */
  isBorderedError?: boolean;
  /**
   * Set Wrapper classes, new classes will overide the existing default classes
   */
  wrapperClass?: string | undefined;
  /**
   * Optional for additional classes
   */
  additionalClasses?: string | undefined;
  /**
   * Enable read only
   */
  readonly?: boolean;
  /**
   * LabelClasses
   */
  labelClasses?: string;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  /**
   * Set label only
   */
  onlyLabel?: boolean;
  /**
   * inner span Classes
   */
  labelSpanClasses?: string;
  /**
   * add additional InputValidation Classes
   */
  additionalErrorClasses?: string;
  isAdditionalErrorInput?: boolean;
  isFullComponentDisabled?: boolean;
}

const handleInputElementClasses = (_inputVariant?: 'default' | 'large') => {
  switch (_inputVariant) {
    case 'default':
      return 'form-input h-[42px] border border-solid border-grey-100 rounded-lg text-black placeholderColor-gray  text-14s text-grey-900 px-4 focus:border-grey-100';
    case 'large':
      return 'form-input w-full h-14 border border-solid border-black-925 rounded-lg text-black placeholderColor-gray350 text-base shadow-none font-normal';
    default:
      break;
  }
};

/**
 * Primary UI component for user interaction
 */
const Textarea: FC<HookFormsInputProps> = forwardRef<HTMLTextAreaElement, HookFormsInputProps>(
  (
    {
      wrapperClass = Classes.input.wrapper,
      type = 'text | date',
      error,
      label,
      isBorderedError = false,
      readOnly = false,
      placeholder,
      onlyLabel = false,
      inputVariant = 'default',
      additionalClasses,
      isAdditionalErrorInput = false,
      additionalErrorClasses,
      labelSpanClasses,
      labelName,
      value,
      isFullComponentDisabled = false,
      ...props
    },
    ref
  ) => {
    const handleReadOnly = readOnly ? 'opacity-50' : 'opacity-100';

    const handleBorderError = error && isBorderedError && 'border-red-500';
    const inputClasses = [
      handleReadOnly,
      handleBorderError,
      additionalClasses,
      handleInputElementClasses(inputVariant),
      'resize-none !h-[100px]',
    ];

    return (
      <div className={wrapperClass}>
        <div
          className={`${
            isFullComponentDisabled ? 'opacity-60 pointer-events-none' : 'opacity-100'
          } relative flex flex-col w-full form-input-wrapper ${value ? 'focused' : ''}`}
        >
          <textarea
            rows={3}
            aria-invalid={error ? 'true' : 'false'}
            className={classNames(inputClasses)}
            readOnly={readOnly}
            ref={ref}
            placeholder={placeholder}
            {...props}
          />

          {label && (
            <label
              htmlFor={labelName}
              className={'text-14s font-normal text-grey-600 top-3 left-4 absolute'}
            >
              {label}
            </label>
          )}
        </div>

        {error && !isBorderedError && !isAdditionalErrorInput && (
          <InputValidation additionalClasses={`${Classes.input.error}`} message={error} />
        )}
        {isAdditionalErrorInput && error && <div className={additionalErrorClasses}>{error}</div>}
      </div>
    );
  }
);

export default Textarea;
