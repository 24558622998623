import React, { useEffect, useMemo, useState } from 'react';

import TableGrid from './List';
import { COLUMNS } from './Table/Columns';
import { useGetSupplierCoupaInvoicesQuery } from 'services/payoutsApi';
import { useGetPayoutsQuery } from 'services/payoutsApi';
import { getCombinedArrayInvoiceAndCoupaInvoice } from '../../../../../helpers/arrayFormating';
import LoadingSpinner from 'components/core/LoadingSpinner/LoadingSpinner';
import { ColumnSearchFilter } from './Table/columnSearchFilter';

export interface ImportIednvoiceProps {
  supplierID?: string;
}

const ImportIednvoice = ({ supplierID }: ImportIednvoiceProps) => {
  const id = '"' + supplierID + '"';

  const payoutsParams = {
    first: 500,
    offset: 0,
    filter: { account_id: { equalTo: id }, status: { in: ['TERMS_ACCEPTED'] } },
    orderBy: 'CREATED_AT_ASC',
  };

  //NEW
  /**
   * handle react_hooks
   */
  const [state] = useState(payoutsParams);
  const [defaultSortOrder, setDefaultSortOrder] = useState({ id: 'number', desc: true });
  const { data, isLoading, isFetching } = useGetSupplierCoupaInvoicesQuery(supplierID); // get coupa invoices
  const acceptedInvoices = useGetPayoutsQuery(state); // get term accepted invoices

  useEffect(() => {
    setDefaultSortOrder({ id: 'approved_at', desc: true });
  }, []);

  const columns = React.useMemo(() => COLUMNS, []);

  const defaultColumn = useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: ColumnSearchFilter,
    }),
    []
  );
  let result = data;
  if (acceptedInvoices?.data?.payouts?.nodes) {
    result = getCombinedArrayInvoiceAndCoupaInvoice(data, acceptedInvoices?.data?.payouts?.nodes);
  }

  return (
    <div className="flex flex-col w-full">
      {(isLoading || isFetching) && <LoadingSpinner />}

      {!isLoading && (
        <TableGrid
          columns={columns}
          hiddenColumns={[]}
          data={result}
          paginationOptions={null}
          isPagination={false}
          defaultSortBy={defaultSortOrder}
          handleLoadingState={isLoading || isFetching}
          defaultColumn={defaultColumn}
        />
      )}
    </div>
  );
};

export default ImportIednvoice;
