import { useGetAllPayModesQuery, useUpdateVendorsAccountMutation } from 'services/vendorsApi';
import Modal from '../Modals/Modal/Modal';
import { useState } from 'react';
import classNames from 'classnames';
import { InstantPaymentsIcon } from '../Media/Icons/Icons';
import { Button } from '../Button/Button';
import { ConfirmationModal } from '../Modals/ConfirmationModal/ConfirmationModal';
import Message from '../Message/Message';
import Icon from '../Media/Icon/Icon';
import { handleVendorDefaultPayMode } from 'helpers/modules/helperVendor';
import { AddRecipientDetailsModal } from '../Modals/AddRecipientDetailsModal/AddRecipientDetailsModal';
import { useFlags } from 'launchdarkly-react-client-sdk';

export interface EditPayModesDropDownProps {
  defaultValue?: string;
  vendorID?: string;
  isPayModeEditable?: boolean;
  vendorData?: any;
}
export const EditPayModesDropDown = ({
  defaultValue,
  vendorID,
  isPayModeEditable,
  vendorData,
}: EditPayModesDropDownProps) => {
  const { recipientDetailsFormEnabled } = useFlags();

  const [optionModalOpen, setOptionModalOpen] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [selectedPayMode, setSelectedPayMode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const { data: payModes } = useGetAllPayModesQuery(null);
  const [recipientDetailsModalOpen, setRecipientDetailsModalOpen] = useState(false);

  const [updateVendorsAccount, { isLoading: updateLoading }]: any =
    useUpdateVendorsAccountMutation();

  const onModalClose = () => {
    setOptionModalOpen(false);
    setSelectedPayMode('');
    setConfirmationModalOpen(false);
    setRecipientDetailsModalOpen(false);
  };

  const onModalOpen = () => {
    setOptionModalOpen(true);
  };

  const onPayModeSelection = (e: any) => {
    setSelectedPayMode(e.target.value);
  };

  const handleApplyButton = () => {
    if (selectedPayMode !== '') {
      if (selectedPayMode === 'check' && recipientDetailsFormEnabled) {
        setRecipientDetailsModalOpen(true);
      } else {
        setConfirmationModalOpen(true);
      }
    }
  };

  const onConfirmButtonClick = async () => {
    setRecipientDetailsModalOpen(false);
    const response = await updateVendorsAccount({
      obj: {
        default_pay_mode: selectedPayMode,
        id: vendorID,
      },
      isCompany: false,
      isMappingRequired: false,
    });

    if (response?.error) {
      setConfirmationModalOpen(false);
      setErrorMessage(response?.error?.error ?? response?.error?.message);
    } else {
      setConfirmationModalOpen(false);
      onModalClose();
      setSelectedPayMode('');
    }
  };

  const onBackEvent = () => {
    setRecipientDetailsModalOpen(false);
    if (!updateLoading) {
      setConfirmationModalOpen(false);
    }
  };

  const onRecipientDetailsModalClose = () => {
    setRecipientDetailsModalOpen(false);
    setOptionModalOpen(true);
  };

  return (
    <>
      {isPayModeEditable ? (
        <div
          onClick={onModalOpen}
          className="cursor-pointer inline-flex items-center justify-center h-6 px-[8px] font-medium capitalize bg-primary-75 rounded text-12s text-primary-500 min-w-[72px]"
        >
          {defaultValue ? handleVendorDefaultPayMode(defaultValue) : null}
        </div>
      ) : (
        <div className="inline-flex items-center justify-center h-6 px-[8px] font-medium capitalize bg-primary-75 rounded text-12s text-primary-500 min-w-[72px] opacity-70">
          {defaultValue ? handleVendorDefaultPayMode(defaultValue) : null}
        </div>
      )}

      <Modal
        isOpen={optionModalOpen && !confirmationModalOpen}
        onClose={onModalClose}
        modalAdditionalClasses="!w-[392px] flex flex-col bg-white justify-center px-2 py-6"
      >
        <div className="absolute cursor-pointer top-5 right-5 text-grey-600" onClick={onModalClose}>
          <Icon iconColor="inherit" iconType="CloseIcon" />
        </div>
        <div className="flex flex-col w-full px-4">
          <h2 className="mt-0 mb-3 font-medium text-18s text-primary-500">Choose payout mode</h2>
          <p className="mt-0 mb-6 font-light text-14s text-primary-500">
            Select your preferred mode for payouts.
          </p>
        </div>
        {errorMessage !== '' && (
          <div className="flex flex-col w-full px-4">
            <Message type="error">{errorMessage}</Message>
          </div>
        )}
        <div className="flex flex-col w-full px-4 h-[260px] overflow-y-auto">
          {payModes &&
            payModes.map((paymode: any) => {
              const handleLabelClasses = () => {
                let labelClasses = '';

                if (defaultValue === paymode.id) {
                  labelClasses = '!border-primary-500 pointer-events-none';
                }

                if (selectedPayMode === paymode.id) {
                  labelClasses = '!border-primary-500 pointer-events-none bg-grey-50';
                }

                return labelClasses;
              };

              return (
                <div className={classNames(['flex flex-col w-full mb-2'])} key={paymode.id}>
                  <input
                    type="radio"
                    name="payModes"
                    id={paymode.id}
                    className="absolute w-0 h-0 p-0 m-0 overflow-hidden opacity-0 -left-4 terms_selection"
                    onChange={onPayModeSelection}
                    defaultValue={paymode.id}
                  />
                  <label
                    htmlFor={paymode.id}
                    className={classNames([
                      'flex w-full py-1 border border-solid rounded-xl border-grey-100 min-h-[44px]',
                      'px-4 items-center justify-between cursor-pointer',
                      handleLabelClasses(),
                    ])}
                  >
                    {
                      <div className="flex items-center">
                        <div className="w-[100px] flex">
                          <span
                            className={classNames([
                              'inline-flex font-bold leading-3 text-primary-500 text-12s',
                            ])}
                          >
                            {paymode.modeName}
                          </span>
                        </div>
                      </div>
                    }

                    <div className="flex">
                      {paymode.id === 'debit_card' && <InstantPaymentsIcon />}
                      <span className="ml-1 mr-1 font-light text-12s text-grey-600 inline-flex w-[75px]">
                        {paymode.time}
                      </span>
                      <span className="font-medium text-12s text-primary-500 opacity-80 ml-[6px] w-[55px] text-left">
                        {paymode.feePercentage}
                      </span>
                    </div>
                  </label>
                </div>
              );
            })}
        </div>
        <div className="flex items-center justify-end w-full px-4 pt-4">
          <Button
            type={'button'}
            buttonLabel={'Apply'}
            onClick={handleApplyButton}
            variants="default"
            additionalClasses={`w-full ${
              selectedPayMode === '' ? 'pointer-events-none !opacity-30' : 'pointer-events-auto'
            }`}
            iconType="Spin"
            iconClass="inline-flex ml-1 w-3 h-3"
            isIconEnabled={false}
            iconAlignment={'right'}
          />
        </div>
      </Modal>

      {recipientDetailsModalOpen && (
        <AddRecipientDetailsModal
          onModalClose={onRecipientDetailsModalClose}
          vendorData={vendorData}
          onFormSubmitEvent={onModalClose}
          vendorID={vendorID}
          setErrorMessage={setErrorMessage}
        />
      )}

      {confirmationModalOpen && (
        <ConfirmationModal
          handleBackEvent={onBackEvent}
          handleSubmitEvent={onConfirmButtonClick}
          isIconLoad={updateLoading}
          mainContent={`Are you sure you want to change the pay mode?`}
          additionalClasses={'px-4'}
        />
      )}
    </>
  );
};
