// import React, { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
// import { useParams } from 'react-router-dom';

import { ERROR_MESSAGES } from '../../../../../../constants/Validation';
import { BankDetailsParams } from '../../../../../../shared/utilities/interface';
import { Classes } from '../../../../../../shared/configs/componentsClasses';
// import { useCreateBankAccountMutation } from '../../../../../../services/vendorsApi';

import Message from 'components/core/Message/Message';
import LoadingSpinner from 'components/core/LoadingSpinner/LoadingSpinner';
import { Typhography, Button, Input } from 'components/core';
import { useCreateSetupIntentMutation } from 'services/paymentSourcesApi';
import { useEffect, useState } from 'react';
import { dtoPaymentSourcesValues } from 'shared/utilities/dto';

interface BankDetailsProps {
  onClose?: any;
  propertyID?: string;
  isEdit?: boolean;
  paymentData?: any;
  labelValue?: string;
}

export const PropertyBankDetails = ({
  onClose,
  propertyID,
  isEdit,
  paymentData,
  labelValue,
}: BankDetailsProps) => {
  const inputDefaultClasses = Classes.input.default;
  const [mutation, { isLoading, isSuccess }]: any = useCreateSetupIntentMutation();

  const [strpeError, setStripeError] = useState('');

  const schemaAddNew = yup
    .object({
      account_holder_name: yup.string().required(ERROR_MESSAGES.REQUIRED),
      account_number: yup
        .string()
        .required(ERROR_MESSAGES.REQUIRED)
        .min(5, ERROR_MESSAGES.ACCOUNT_NUMBER_INVALID)
        .max(17, ERROR_MESSAGES.ACCOUNT_NUMBER_INVALID),
      account_number_conf: yup
        .string()
        .required(ERROR_MESSAGES.REQUIRED)
        .min(5, ERROR_MESSAGES.ACCOUNT_NUMBER_INVALID)
        .max(17, ERROR_MESSAGES.ACCOUNT_NUMBER_INVALID)
        .oneOf([yup.ref('account_number')], 'Account number does not match'),
      routing_number: yup
        .string()
        .required(ERROR_MESSAGES.REQUIRED)
        .min(9, ERROR_MESSAGES.SSN_INVALID_FULL)
        .max(9, ERROR_MESSAGES.SSN_INVALID_FULL),
    })
    .required();

  const schemaUpdate = yup
    .object({
      account_holder_name: yup.string().required(ERROR_MESSAGES.REQUIRED),
    })
    .required();

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<BankDetailsParams>({
    resolver: yupResolver(isEdit ? schemaUpdate : schemaAddNew),
    mode: 'onBlur', //used to check error in realtime
    shouldFocusError: true,
  });

  useEffect(() => {
    if (isEdit && paymentData) {
      dtoPaymentSourcesValues(setValue, paymentData);
    }
  }, [isEdit, paymentData]);

  const onSubmitNewPaymentMethod = async (data: any) => {
    const result: any = await mutation({
      bank_data: {
        account_holder_type: data.account_holder_type,
        account_number: data.account_number,
        account_type: data.account_type,
        routing_number: data.routing_number,
        account_holder_name: data.account_holder_name,
      },
      label: isEdit ? data.label : labelValue,
      property_id: propertyID,
    });

    if (result) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const getError = result?.error;

      if (getError) {
        setValue('account_holder_name', '');
        setValue('routing_number', '');
        setValue('account_number', '');
        setValue('account_number_conf', '');
        setStripeError(result?.error.data.message);
      } else {
        setStripeError('');
        setTimeout(() => {
          onClose();
        }, 2000);
      }
    }
  };

  return (
    <>
      {isLoading && <LoadingSpinner />}
      <Typhography component="p" type="h2" color="text-grey-900 mb-3 uppercase">
        {`${isEdit ? 'Update' : 'Add'} Your account Details`}
      </Typhography>

      <form
        onSubmit={handleSubmit(onSubmitNewPaymentMethod)}
        className={'w-full flex flex-col max-w-3xl'}
        noValidate
      >
        <div className="flex flex-col w-full min-h-[442px] mb-5 overflow-y-auto pt-3">
          {(strpeError !== '' || isSuccess) && (
            <Message
              title="Property Bank Setup"
              type={isSuccess ? 'success' : 'error'}
              additionalClasses={'mb-6'}
            >
              {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                isSuccess ? 'Thanks! your account has been successfully created.' : strpeError
              }
            </Message>
          )}

          <Controller
            control={control}
            name="account_holder_name"
            render={({ field: { value } }) => (
              <Input
                label="Account holder name"
                labelName={'account_holder_name'}
                type="text"
                {...register('account_holder_name')}
                className={inputDefaultClasses}
                error={errors?.account_holder_name?.message}
                required
                value={value}
              />
            )}
          />

          <Controller
            control={control}
            name="account_number"
            render={({ field: { value } }) => (
              <Input
                label={isEdit ? 'Account' : 'Account number'}
                labelName={'account_number'}
                type="text"
                {...register('account_number')}
                className={inputDefaultClasses}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                error={errors?.account_number?.message}
                maxLength={17}
                additionalClasses={`text-14s w-[334px]`}
                required
                value={value}
                isFullComponentDisabled={isEdit ? true : false}
              />
            )}
          />

          {!isEdit && (
            <Controller
              control={control}
              name="account_number_conf"
              render={({ field: { value } }) => (
                <Input
                  label={'Confirm account number'}
                  labelName={'account_number_conf'}
                  type="text"
                  {...register('account_number_conf')}
                  className={inputDefaultClasses}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  error={errors?.account_number_conf?.message}
                  maxLength={17}
                  required
                  value={value}
                />
              )}
            />
          )}

          <Controller
            control={control}
            name="routing_number"
            render={({ field: { value } }) => (
              <Input
                label="Routing number"
                labelName={'routing_number'}
                type="text"
                {...register('routing_number')}
                className={inputDefaultClasses}
                onKeyPress={(event: { key: string; preventDefault: () => void }) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                error={errors?.routing_number?.message}
                maxLength={9}
                required
                value={value}
                readOnly={isEdit ? true : false}
                isFullComponentDisabled={isEdit ? true : false}
              />
            )}
          />

          <Controller
            control={control}
            name="account_holder_type"
            render={({ field: { value } }) => (
              <div className="relative flex flex-col w-full mb-6 form-input-wrapper focused">
                <select
                  {...register('account_holder_type')}
                  required
                  className="form-select h-[42px] border border-solid border-grey-100 rounded-lg text-black placeholderColor-gray  text-14s text-grey-900 px-4"
                  value={value}
                >
                  <option value="individual">Individual</option>
                  <option value="company">Company</option>
                </select>
              </div>
            )}
          />
          {isEdit && (
            <Controller
              control={control}
              name="label"
              render={({ field: { value } }) => (
                <div className="relative flex flex-col w-full mb-6 form-input-wrapper focused">
                  <select
                    {...register('label')}
                    required
                    className="form-select h-[42px] border border-solid border-grey-100 rounded-lg text-black placeholderColor-gray  text-14s text-grey-900 px-4"
                    value={value}
                  >
                    <option value="operating">Operating</option>
                    <option value="capital">Capital</option>
                  </select>
                </div>
              )}
            />
          )}
        </div>

        <div className="flex items-center w-full">
          <Button
            type="submit"
            buttonLabel={isEdit ? 'Save Changes' : 'Add Account'}
            iconType="Spin"
            isIconEnabled={false}
            additionalClasses={'w-full'}
          />
        </div>
      </form>
    </>
  );
};
