import ReconcileManualContainer from 'components/modules/Reconciliation/Manual';
import React from 'react';
/**
 * Primary UI component for user interaction
 */
const ReconcileManual = () => {
  return <ReconcileManualContainer />;
};

export default ReconcileManual;
