import { AddAccountModalProps } from 'shared/models/components/base.model';
import Icon from '../../Media/Icon/Icon';
import Modal from '../Modal/Modal';
import { BankDetails } from './BankDetails/BankDetails';
import { BankSetup } from './BankSetup/BankSetup';
import { loadStripe } from '@stripe/stripe-js';
import { useGetConfigQuery } from '../../../../services/configApi';
import { Elements } from '@stripe/react-stripe-js';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import classNames from 'classnames';

export const AddAccountModal = ({
  isOpen = true,
  onClose,
  onBankSetupClick,
  enableSetupAccount,
  setUpdateAllList,
  updateAllList,
}: AddAccountModalProps) => {
  const { data, isLoading } = useGetConfigQuery('');

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isLeftSpaceEnabled={false}
      isContainerVerticalAligned={false}
      modalAdditionalClasses={classNames([
        'h-[610px] pt-12 pb-8 rounded-xl bg-white flex-col !w-[360px] px-6 mt-20',
      ])}
    >
      {isLoading && <LoadingSpinner />}
      <div className="absolute cursor-pointer top-5 right-5 text-grey-600" onClick={onClose}>
        <Icon iconColor="inherit" iconType="CloseIcon" />
      </div>
      {enableSetupAccount ? (
        <BankDetails
          setUpdateAllList={setUpdateAllList}
          updateAllList={updateAllList}
          onClose={onClose}
        />
      ) : (
        !isLoading && (
          <Elements stripe={loadStripe(data?.stripe_api_public_key)}>
            <BankSetup
              onClick={onBankSetupClick}
              setUpdateAllList={setUpdateAllList}
              updateAllList={updateAllList}
              onClose={onClose}
            />
          </Elements>
        )
      )}
    </Modal>
  );
};
