import Register, {
  Address,
  BussinessProfile,
  Company,
  DOB,
  Individual,
  IndividualExecutive,
  IndividualOwner,
  RegisterCompany,
  RelationshipExecutive,
  RelationshipOwner,
  Verification,
  Document,
  Metadata,
} from './account.register.model';

export const accountIndividual = (payload: any, fullSSN?: boolean): Register => {
  const date = payload?.dob;
  return new Register(
    payload.business_type,
    new Individual(
      new Address(payload.line1, payload.line2, payload.city, payload.state, payload.postal_code),
      date &&
        new DOB(
          date?.toString().substring(2, 4),
          date?.toString().substring(0, 2),
          date?.toString().substring(4, 8)
        ),
      payload.first_name,
      payload.last_name,
      payload.phone_number,
      payload.email,
      fullSSN && payload.idNumber,
      !fullSSN && payload.idNumber,
      new Verification(new Document(payload.front, payload.back)),
      new Metadata(payload.documentType)
    ),
    new BussinessProfile(
      payload.inputMCC,
      payload.inputDescription,
      payload.inputBusinessName,
      payload.url
    ),
    payload?.id,
    payload?.tos_shown_and_accepted,
    payload?.default_payment_term_id,
    payload?.accept_other_terms,
    payload?.is_email_notification_active,
    payload?.is_sms_notification_active
  );
};

export const accountCompany = (payload: any, fullSSN?: boolean): Register => {
  const date = payload?.dob;
  return new RegisterCompany(
    payload.business_type,
    payload.ownership === 'owner'
      ? new IndividualOwner(
          new Address(
            payload.line1,
            payload.line2,
            payload.city,
            payload.state,
            payload.postal_code
          ),
          date &&
            date !== '' &&
            new DOB(
              date?.toString().substring(2, 4),
              date?.toString().substring(0, 2),
              date?.toString().substring(4, 8)
            ),
          payload.first_name,
          payload.last_name,
          payload.phone_number,
          payload.email,

          new RelationshipOwner(payload.isRep, payload.inputOwnerTitle, true, 50),
          fullSSN && payload.idNumber,
          !fullSSN && payload.idNumber,
          new Verification(new Document(payload.front, payload.back)),
          payload.documentType &&
            payload.documentType !== 'Document Type' &&
            new Metadata(payload.documentType)
        )
      : new IndividualExecutive(
          new Address(
            payload.line1,
            payload.line2,
            payload.city,
            payload.state,
            payload.postal_code
          ),
          date &&
            date !== '' &&
            new DOB(
              date?.toString().substring(2, 4),
              date?.toString().substring(0, 2),
              date?.toString().substring(4, 8)
            ),
          payload.first_name,
          payload.last_name,
          payload.phone_number,
          payload.email,
          new RelationshipExecutive(payload.isRep, payload.inputOwnerTitle, true),
          fullSSN && payload.idNumber,
          !fullSSN && payload.idNumber,
          new Verification(new Document(payload.front, payload.back)),
          payload.documentType &&
            payload.documentType !== 'Document Type' &&
            new Metadata(payload.documentType)
        ),
    new BussinessProfile(
      payload.inputMCC,
      payload.inputDescription,
      payload.inputBusinessName,
      payload.url
    ),
    payload.id,
    payload.tos_shown_and_accepted,
    payload?.default_payment_term_id,
    payload.accept_other_terms,
    new Company(
      payload.inputCompanyName,
      payload.inputCompanyPhone,
      new Address(
        payload.inputComLine1,
        payload.inputComLine2,
        payload.inputComCity,
        payload.inputComState,
        payload.inputComPostal
      ),
      payload.inputTaxId
    )
  );
};
