import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { CSVLink } from 'react-csv';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { Button } from '../Button/Button';
import { ShowMoreButton } from '../ShowMoreButton/ShowMoreButton';
import { TabNavigation } from '../TabNavigation/TabNavigation';
import { Typhography } from '../Typhography/Typhography';
import { FilterComponent } from '../FilterComponent/FilterComponent';

import { useGetPayoutsQuery } from 'services/payoutsApi';
import { useGetVendorsQuery } from 'services/vendorsApi';
import { useGetPropertiesQuery } from 'services/properties';

import { ToolBarProps } from 'shared/models/components/base.model';
import { handleDownloadCSVDataMapping } from 'helpers/handleDownloadCSVDataMapping';

export const ToolBar = ({
  navigationData,
  children,
  isDownloadCsvButtonEnabled = true,
  isFilterButtonEnabled = false,
  isShowOptionEnabled = true,
  columns = [],
  handleIconClick,
  filteredRow,
  visibleColumns,
  isNavigationEnabled = true,
  title,
  subTitle,
  isCountVisible,
  onClickFilterApplyButton,
  isDownloadCompleteData,
  queryParam,
  tabType = 'approve',
  paymentMethodData,
  isShowOptionOnlyIconVisible = true,
  isDownloadOnlyIconVisible = true,
  isFilterIconOnlyVisible = true,
}: ToolBarProps) => {
  const { dashboardServerSideFilteration } = useFlags();
  const [customCsvData, setCustomCsvData] = useState<any>([]);
  const [customHeaderData, setCustomHeaderData] = useState<any>([]);

  // enable the filter button with feature falg __
  const isFilterFeatureEnabled = dashboardServerSideFilteration && isFilterButtonEnabled;
  const TabNavsClasses = ['inline-flex items-center'];
  const queryParamVal = queryParam;

  let responseData: any = [];

  if (queryParam) {
    if (tabType === 'vendor') {
      responseData = useGetVendorsQuery(queryParamVal);
    } else if (tabType === 'approve') {
      responseData = useGetPayoutsQuery(queryParamVal);
    } else if (tabType === 'properties') {
      responseData = useGetPropertiesQuery(queryParamVal);
    }
  }

  const { data: downloadData, isSuccess }: any = responseData;

  /**
   * Map the Payouts response to headersData column structure
   */
  useEffect(() => {
    if (isSuccess && downloadData) {
      const [result] = handleDownloadCSVDataMapping(tabType, downloadData, paymentMethodData);

      if (result) {
        setCustomCsvData(result);
      }
    } else {
      /**
       * Normal Download CSV Data for download
       */
      const CsvData = filteredRow?.map((row: any) => {
        const rtnValues = row.values;
        return rtnValues;
      });

      if (CsvData) {
        setCustomCsvData(CsvData);
      }
    }

    if (visibleColumns) {
      /**
       * Set header columns structure
       */
      const headersData = visibleColumns
        ?.filter((items: any) => items?.id !== 'selection')
        .map((row: any) => {
          if (typeof row?.Header === 'object') {
            return {
              label: `${row?.Header?.props?.firstLine} ${row?.Header?.props?.secondLine}`,
              key: row?.id,
            };
          } else {
            return { label: row?.Header, key: row?.id };
          }
        });

      setCustomHeaderData(headersData);
    }
  }, [downloadData, visibleColumns, filteredRow]);

  return (
    <div className={classNames(['flex justify-between w-full mb-3'])}>
      {(title || subTitle) && (
        <div className="flex flex-row items-center">
          {title && (
            <Typhography
              component="div"
              type="h2"
              color="text-grey-900"
              additionalClasses="items-center uppercase pb-3 !font-normal pt-1"
            >
              {title}
            </Typhography>
          )}
          {subTitle && (
            <>
              <span className="relative inline-flex w-1 h-1 mx-2 rounded bg-grey-900 -top-1"></span>
              <Typhography
                component="div"
                type="h2"
                color="text-grey-600"
                additionalClasses="items-center uppercase pb-3 !font-normal pt-1"
              >
                {subTitle}
              </Typhography>
            </>
          )}
        </div>
      )}
      {isNavigationEnabled && (
        <TabNavigation navigationData={navigationData} isCountVisible={isCountVisible} />
      )}

      <div className={classNames(TabNavsClasses)}>
        {isFilterFeatureEnabled && (
          <FilterComponent
            onClickFilterApplyButton={onClickFilterApplyButton}
            filterButtonLabel={isFilterIconOnlyVisible ? '' : 'Filter'}
            additionalData={customCsvData}
          />
        )}

        {children}

        {isShowOptionEnabled && (
          <div className={'ml-3'}>
            <ShowMoreButton
              columns={columns}
              handleIconClick={handleIconClick}
              isFilterEnabled={isShowOptionOnlyIconVisible}
            />
          </div>
        )}

        {isDownloadCsvButtonEnabled && (
          <div className={'ml-3'}>
            {customCsvData !== undefined && customHeaderData !== undefined && (
              <CSVLink data={customCsvData} headers={customHeaderData}>
                <Button
                  buttonLabel={isDownloadOnlyIconVisible ? '' : 'CSV'}
                  iconAlignment="left"
                  iconClass={isDownloadOnlyIconVisible ? 'mr-0' : 'mr-1'}
                  iconType="DownloadIcon"
                  isIconEnabled
                  variants="extraSmallOutlined"
                  additionalClasses="text-12s !border-grey-100 text-grey-900"
                />
              </CSVLink>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
