import React from 'react';
import Wrapper from '../../../../layouts/Wrapper/Wrapper';
import Headers from '../../../../core/Headers/Headers';
import SuccessDetails from './Details/Details';

const SuccessContainer = () => {
  return (
    <Wrapper additionalClasses="flex-col items-start" pageTitle="Payment Success">
      <Headers headingContent="Balance" />
      <div className="flex flex-row w-full">
        <div className="w-1/2">
          <SuccessDetails />
        </div>
      </div>
    </Wrapper>
  );
};

export default SuccessContainer;
