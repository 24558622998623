import React from 'react';
import VendorsContainer from '../../../components/modules/Vendors/Active/Index';

/**
 * Primary UI component for user interaction
 */
const Vendors = () => {
  return <VendorsContainer />;
};

export default Vendors;
