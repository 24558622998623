import VendorEarningsContainer from 'components/modules/Vendors/Account/Earnings';

/**
 * Primary UI component for user interaction
 */
const VendorEarnings = () => {
  return <VendorEarningsContainer />;
};

export default VendorEarnings;
