import { IErrorModal } from 'shared/models/components/base.model';
import Modal from '../Modal/Modal';
import { Icon, Message } from 'components/core';

export const ErrorModal = ({ onCloseModalEvent, type, message, title }: IErrorModal) => {
  return (
    <Modal
      isOpen={true}
      modalAdditionalClasses={
        'z-20 !w-[370px] flex flex-col bg-white justify-center rounded-lg shadow-importInvoicesModal absolute right-1 opacity-1 pt-6 pb-6 px-6'
      }
    >
      <div
        className={`absolute cursor-pointer top-3 right-3 text-grey-600`}
        onClick={onCloseModalEvent}
      >
        <Icon iconColor="inherit" iconType="CloseIcon" />
      </div>

      <Message type={type} title={title} additionalClasses={'mb-3 mt-3'}>
        {message}
      </Message>
    </Modal>
  );
};
