import { useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';

import Wrapper from '../../../../layouts/Wrapper/Wrapper';
import { WeeklyPayouts } from '../WeeklyPayouts/WeeklyPayouts';
import {
  BalanceStatsBar,
  LoadingSpinner,
  ToolBar,
  Typhography,
  EmptyList,
  Headers,
  SelectBox,
} from 'components/core';

import { balanceWeekTabnavData } from 'constants/TabNavData';
import { addDaysFunction, getCurrentWeekStartandEndDates } from 'shared/utilities/dataConversion';

import { useCurrencyFormat } from 'hooks/useCurrencyFormat';

import {
  useGetPendingBalanceApiQuery,
  useGetUpcomingBalanceHeroStatsQuery,
} from '../../../../../services/pastBalanceApi';

const CurrentWeekContainer = () => {
  const navigate = useNavigate();
  const [wkStart, wkEnd] = getCurrentWeekStartandEndDates();

  const todaysInvoiceParams = {
    limit: 1,
    offset: 0,
    filter: {
      status: { in: ['INVOICED', 'APPROVED', 'PAID'] },
      customer_payment_due_at: {
        lessThan: moment(wkEnd)
          .tz('America/Chicago')
          .set({ hour: 23, minute: 59, second: 59, millisecond: 999 })
          .format(),
        greaterThan: moment(wkStart)
          .tz('America/Chicago')
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
          .format(),
      },
    },
    groupBy: 'CUSTOMER_PAYMENT_DUE_AT_TRUNCATED_TO_WEEK_CST',
  };

  const { data, isLoading, isFetching, isSuccess } =
    useGetPendingBalanceApiQuery(todaysInvoiceParams);

  /*
   * Fetch the Current Invoices Hero Stats data
   */
  const currentHeroStatsParams = {
    filter: {
      status: { in: ['APPROVED', 'PAID', 'INVOICED'] },
      customer_payment_due_at: {
        lessThan: moment(wkEnd).tz('America/Chicago').format(),
        greaterThan: moment(wkStart).tz('America/Chicago').format(),
      },
    },
    timeCycleDays: '' + 7 + '',
  };

  const { data: currentBalanceStatsBarData, isSuccess: isSuccessCurrentBalanceStatsBar }: any =
    useGetUpcomingBalanceHeroStatsQuery(currentHeroStatsParams);

  return (
    <>
      {(isLoading || isFetching) && <LoadingSpinner />}
      <Wrapper additionalClasses="flex-col items-start" pageTitle="Balance">
        <Headers
          headingContent="Balance"
          isButtonVisibled={false}
          isDisabled={isSuccess && data?.payouts?.groupedAggregates.length === 0}
          onClick={() => navigate('/balance/payment')}
          buttonLabel="Pay Balance"
          variants="extraSmall"
          isIconEnabled={false}
          additionalClasses="w-[118px]"
        />
        {!isLoading && (
          <>
            <ToolBar navigationData={balanceWeekTabnavData} isShowOptionEnabled={false}>
              <SelectBox defaultSelectBoxText={'Select time'} isDisabled itemSelected />
            </ToolBar>

            {isSuccessCurrentBalanceStatsBar && (
              <BalanceStatsBar data={currentBalanceStatsBarData} timeFrame="current" />
            )}

            {isSuccess && data?.payouts?.groupedAggregates.length > 0 ? (
              <div className="flex flex-col w-full">
                <Typhography
                  component="div"
                  type="h2"
                  color="text-grey-900"
                  additionalClasses="items-center uppercase pt-9 pb-3 border-t border-solid border-grey-100 px-4 !font-normal"
                >
                  {'Invoices List'}
                </Typhography>

                <div className="flex flex-col w-full invoices-list-wrapper">
                  {data?.payouts?.groupedAggregates.map((listItem: any) => {
                    const convertedPrice = parseFloat(listItem?.sum?.amount);
                    const setDate = moment(listItem.keys[0]).tz('America/Chicago');
                    const endOfWeek = addDaysFunction(setDate, 6);

                    return (
                      <div className="flex flex-col w-full mb-0" key={listItem.keys[0]}>
                        <WeeklyPayouts
                          weekStartDate={listItem.keys[0]}
                          weekEndDate={endOfWeek}
                          mainLabel="Invoice for"
                          subLabel={`${moment(listItem.keys[0])
                            .tz('America/Chicago')
                            .format('MM/DD/YYYY')} - ${moment(endOfWeek)
                            .tz('America/Chicago')
                            .format('MM/DD/YYYY')}`}
                          rightColContent={useCurrencyFormat(convertedPrice)}
                          isRemoveTopBorder={data?.payouts?.groupedAggregates.length === 1}
                          timeFrame={'current'}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              <EmptyList />
            )}
          </>
        )}
      </Wrapper>
    </>
  );
};

export default CurrentWeekContainer;
