import { UserRole } from '../../../../core/OldComponents/UserRole/UserRole';
import moment from 'moment';
import {
  isPermitted,
  useGetUserQuery,
  useResendUserInviteMutation,
  useSetUserRoleMutation,
  useUpdateUserInviteMutation,
} from '../../../../../services/userApi';
import { ConfirmationModal, LoadingSpinner, Message } from 'components/core';
import { useState } from 'react';

export interface IUserList {
  data: any;
  isAllowToRemoveUser?: boolean;
}

export const UserList = ({ data, isAllowToRemoveUser }: IUserList) => {
  let messageAlert = '';
  const options = ['admin', 'view-only', 'super-admin'];
  const [updateRoles] = useSetUserRoleMutation();
  const { data: loggedInUser } = useGetUserQuery({});
  const [resendTriggerMutation, result] = useResendUserInviteMutation();
  const [updateUserInviteMutation, { isLoading: islaodingRemoveUser }] =
    useUpdateUserInviteMutation();
  const [selectedUserId, setSelectedUserId] = useState('');
  const [removeUserConfirmationModalOpen, setRemoveUserConfirmationModalOpen] = useState(false);

  const handleRoleChange = async (event: any) => {
    const value = String(event?.currentTarget?.value);
    const id = event?.currentTarget?.name;
    const response: any = await updateRoles({ id: id, role: value });
    if (response) {
      let updateMessage = {
        message: 'Account Updated Successfully.',
        messageType: 'success',
        visible: false,
      };
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (response?.error) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const newMessage = response?.error?.data?.error;
        updateMessage = {
          ...updateMessage,
          message: newMessage,
          messageType: 'error',
        };
      }
      messageAlert = updateMessage.message;
    }
    alert(messageAlert);
    window.location.reload();
  };

  const handleTrigger = async (id?: string) => {
    await resendTriggerMutation({ id: id });
  };

  const updateUserInvite = (userID: string) => {
    setSelectedUserId(userID);
    setRemoveUserConfirmationModalOpen(true);
  };

  const removeUserConfirmation = async () => {
    setSelectedUserId('');
    const updateParams = {
      id: selectedUserId,
      body: {
        status: 'inactive',
      },
    };

    const response = await updateUserInviteMutation(updateParams);
    if (response) {
      setRemoveUserConfirmationModalOpen(false);
    }
  };

  return (
    <>
      {result.isLoading && <LoadingSpinner />}
      {result.isSuccess && <Message type="success">Invitation sent successfully !</Message>}
      {data &&
        data?.userCustomerRoles?.nodes?.map((listItem: any, index: any) => {
          const { user } = listItem;
          const name =
            !user?.first_name && !user?.first_name
              ? ' '
              : `${user?.first_name} ${user?.last_name}` ?? ' ';
          const email: string = user?.email ?? '';
          const id: string = user?.id;
          const createdAtTime: string = user?.created_at;
          let joinAgoText = ``;
          if (user.status === 'INVITED') {
            joinAgoText = 'Invited';
          } else if (user.status === 'INACTIVE') {
            joinAgoText = 'Inactive';
          } else {
            let joinedAgo: number = moment(new Date()).diff(moment(createdAtTime), 'months', false);
            joinAgoText = `Joined ${joinedAgo} months ago`;
            if (joinedAgo <= 0) {
              joinedAgo = moment(new Date()).diff(moment(createdAtTime), 'days', false);
              joinAgoText = `Joined ${joinedAgo} day ago`;
              if (joinedAgo <= 0) {
                joinAgoText = `Joined Today`;
              }
            }
          }
          let role: string = listItem?.role;
          role = role === 'VIEW_ONLY' ? 'view-only' : role === 'ADMIN' ? 'admin' : 'super-admin';

          const isRemoveIconVisibleConstant =
            isAllowToRemoveUser && (role === 'view-only' || role === 'admin') ? true : false;

          if (joinAgoText !== 'Inactive') {
            return (
              <UserRole
                key={index}
                id={id}
                name={name}
                role={role}
                email={email}
                joinedPeriod={joinAgoText}
                options={options}
                handleOnChange={handleRoleChange}
                // eslint-disable-next-line
                readOnly={!isPermitted(loggedInUser, 'change-role')!}
                onClickButton={() => handleTrigger(id)}
                onClickUpdateUserStatus={() => updateUserInvite(id)}
                isRemoveIconVisible={isRemoveIconVisibleConstant}
              />
            );
          } else {
            return '';
          }
        })}

      {removeUserConfirmationModalOpen && (
        <ConfirmationModal
          mainContent="Are you sure you want to remove this user? "
          handleSubmitEvent={removeUserConfirmation}
          handleBackEvent={() => setRemoveUserConfirmationModalOpen(false)}
          isIconLoad={islaodingRemoveUser}
        />
      )}
    </>
  );
};
