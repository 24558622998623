export const ColumnNames = {
  invoices: {
    invoiced_at: { header: 'INVOICED', accessor: 'invoiced_at' },
    vendor: { header: 'VENDOR' },
    number: { header: 'PAYUP ID', accessor: 'number' },
    coupa_invoice_number: { header: 'INVOICE', accessor: 'coupa_invoice_number' },
    batch_number: { header: 'BATCH #' },
    description: { header: 'DESCRIPTION', accessor: 'description' },
    property_name: { header: 'PROPERTY', accessor: 'property.name' },
    status: { header: 'STATUS' },
    estimated_approved_at: { header: 'APPROVAL DUE', accessor: 'estimated_approved_at' },
    approved_at: { header: 'APPROVED', accessor: 'approved_at' },
    payout_term_id: { header: 'NET', accessor: 'payout_term_id' },
    payout_due_at: { header: 'VENDOR DUE' },
    paid_at: { header: 'PAID ON', accessor: 'paid_at' },
    paid_by: { header: 'PAID BY' },
    customer_payment_due_at: { header: 'PAYUP DUE', accessor: 'customer_payment_due_at' },
    check_date: { header: 'CHECK DATE' },
    estimated_arrived_on: { header: 'EXP. ARRIVAL', accessor: 'estimated_arrived_on' },
    customer_paid_at: { header: 'RECEIVED ON', accessor: 'customer_paid_at' },
    ar_mode: { header: 'AR MODE' },
    pay_ref: { header: 'PAY REF' },
    pay_mode: { header: 'PAY MODE', accessor: 'pay_mode' },
    check_image_url: { header: 'CHECK NUMBER' },
    amount: { header: 'INVOICE AMOUNT', accessor: 'amount' },
    payout_amount: { header: 'PAYOUT AMOUNT', accessor: 'payout_amount' },
    paid_amount: { header: 'PAID AMOUNT', accessor: 'paid_amount' },
    processing_fee_amount: { header: 'PROCESSING FEE', accessor: 'processing_fee_amount' },
    payout_term_amount: { header: 'DISCOUNT FEE', accessor: 'payout_term_amount' },
    coupa_draw_account: { header: 'DRAW ACCOUNT', accessor: 'coupa_draw_account' },
  },
};
