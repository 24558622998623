import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { Button, Card, Typho } from 'components/core';
import Form from '../../../core/FormElements/Form/Form';
import Input from '../../../core/OldComponents/FormElements/Input/Input';

import { AuthResponse } from '../../../../shared/models/services/login.model';
import { PhoneVerifyFormParams } from '../../../../shared/utilities/interfaceHookForms';

import { usePhoneValidation } from '../../../../hooks/usePhoneValidation';
import { usePrevious } from '../../../../hooks/usePrevious';

import { useCreateVerificationCodeMutation } from '../../../../services/authApi';

interface AccountVerifyPhoneProps {
  verificationData?: (data: AuthResponse) => void;
}

const AccountLoginSchema = yup.object().shape({
  phone: yup
    .string()
    // .length(13, 'Please enter a full 10 digit phone number')
    .required('Please enter a full 10 digit phone number'),
});

const AccountVerifyPhone: React.FC<AccountVerifyPhoneProps> = ({ verificationData }) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<PhoneVerifyFormParams>({ resolver: yupResolver(AccountLoginSchema) });

  const [isLoading, setIsLoading] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(true);
  const [erroState, setErroState] = useState(false);
  const [inputVal, setInputVal] = useState('');

  const prevCount = usePrevious(inputVal);

  const [addNewPost] = useCreateVerificationCodeMutation();

  const appLogin = handleSubmit(async (data) => {
    try {
      setIsLoading(true);
      const data1 = await addNewPost(data.phone).unwrap();
      setIsLoading(false);
      if (verificationData) {
        data1 && verificationData(data1);
      }
      localStorage.setItem('user_number', data.phone);
    } catch (error: any) {
      error.status !== null ? setIsLoading(false) : setIsLoading(true);
      error.status !== null ? setErroState(true) : setErroState(false);
      error.status !== null ? setButtonDisable(true) : setButtonDisable(false);
    }
  });

  const onChangeHandler = (event: any) => {
    setInputVal(usePhoneValidation(event.target.value, prevCount));
    const eventVal = event.target.value;
    setValue('phone', eventVal.replace(/\D/g, '')); // Set value
    event.target.value !== '' ? setButtonDisable(false) : setButtonDisable(true);
  };

  const handleInputBorderColor = erroState && 'border-red-500 bg-lightRed';

  return (
    <React.Fragment>
      <Card additionalClasses="flex-row items-center !w-[385px]" cardType="white">
        <Form
          register={register}
          handleSubmit={handleSubmit}
          onSubmit={appLogin}
          formClasses="w-full flex"
          formContainerClasses="flex-col"
        >
          <div>
            <Input
              name="phone"
              type="text"
              label="Phone Number"
              inputVariant="large"
              error={errors.phone?.message}
              isAdditionalErrorInput={true}
              additionalErrorClasses={
                'text-14s font-normal text-black-900 justify-center mt-6 flex w-full text-center px-4'
              }
              isBorderedError={true}
              additionalClasses={String(handleInputBorderColor)}
              labelClasses="relative bg-transparent -mb-2 px-1 w-auto flex align-self-start left-3 before:content-[''] before:absolute before:left-0 before:right-0 before:top-1 before:h-[7px] before:bg-white"
              labelType="blackSmall"
              labelSpanClasses="relative z-1"
              wrapperClass="flex w-full flex-col relative items-start"
              placeholder={'enter phone number'}
              onChange={onChangeHandler}
              value={inputVal}
              maxLength={14}
              onPaste={onChangeHandler}
              inputMode={'numeric'}
            />
          </div>
          <Typho
            type="p"
            size="14s"
            color="black"
            weight="normal"
            additionalClasses={!erroState ? '!hidden' : 'text-center mt-6 px-10'}
          >
            Looks like phone number is invalid. Please try again.
          </Typho>
          <Button
            type="submit"
            onClick={appLogin}
            buttonLabel={'SEND SECURE CODE'}
            additionalClasses="mt-6 relative"
            isDisabled={buttonDisable}
            iconType="Spin"
            iconClass="inline-flex ml-4 w-6 h-6"
            isIconEnabled={isLoading ? true : false}
            iconAlignment={'right'}
            variants="large"
          />
        </Form>
      </Card>
    </React.Fragment>
  );
};

export default AccountVerifyPhone;
