import { Typhography } from '../Typhography/Typhography';
import Icon from '../Media/Icon/Icon';
import { StatusBlockProps } from 'shared/models/components/base.model';
import classNames from 'classnames';

export const StatusBlock = ({
  firstValue,
  secondValue,
  secondValueColor = 'text-green-400',
  thirdValue,
  iconType = 'IncreaseMode',
  blockHeading,
  iconColor,
  isRemoveBorderPadding = false,
  isIconVisible = true,
  iconClasses,
  children,
  titleClasses = '',
  firstValueClasses = '',
  secondValueClasses = '',
  thirdValueClasses = '',
}: StatusBlockProps) => {
  return (
    <div
      className={`flex w-full py-[8px] justify-center items-center min-h-[72px] ${
        isRemoveBorderPadding ? 'pl-0' : 'pl-3 border-solid border-l-2 border-grey-100'
      }`}
    >
      <div className={`flex flex-col w-auto`}>
        <Typhography type="h2Bold" color="text-grey-600" component="div" additionalClasses="mb-2">
          <span className={classNames(['text-inherit', titleClasses])}>{blockHeading}</span>
        </Typhography>
        <div className="flex items-end h-8 whitespace-nowrap">
          {firstValue && (
            <Typhography
              type="h6Bold"
              color="text-primary-500"
              additionalClasses={firstValueClasses}
            >
              {firstValue}
            </Typhography>
          )}
          {isIconVisible && (
            <span
              className={`${
                iconColor ? iconColor : 'text-green-400'
              } ml-[10px] mb-2 ${iconClasses}`}
            >
              <Icon iconType={iconType} iconColor="inherit" />
            </span>
          )}
          {secondValue && (
            <Typhography
              type="h2"
              color={secondValueColor}
              additionalClasses={classNames(['ml-2 mb-[4px]', secondValueClasses])}
            >
              {secondValue}
            </Typhography>
          )}
          {thirdValue && (
            <Typhography
              type="h2Bold"
              color="text-grey-400"
              additionalClasses={classNames(['ml-1 mb-[4px]', thirdValueClasses])}
            >
              {thirdValue}
            </Typhography>
          )}
          {children}
        </div>
      </div>
    </div>
  );
};
