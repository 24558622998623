import classNames from 'classnames';

import { Icon, Message, Typhography } from 'components/core';
import Modal from 'components/core/Modals/Modal/Modal';

export interface DataModalProps {
  onCloseEvent?: () => void;
  errorMessage?: string;
}

export const ErrorModal = ({ onCloseEvent, errorMessage }: DataModalProps) => {
  return (
    <Modal
      isOpen={true}
      onClose={onCloseEvent}
      modalAdditionalClasses={classNames([
        'min-h-[100px] pt-7 pb-8 rounded-lg bg-white flex-col !w-[600px] px-6',
      ])}
    >
      <Typhography component="p" type="h2" color="text-grey-900 mb-6 uppercase">
        {`Payment Batches`}
      </Typhography>
      <div className="absolute cursor-pointer top-7 right-7 text-grey-600 " onClick={onCloseEvent}>
        <Icon iconColor="inherit" iconType="CloseIcon" />
      </div>
      {errorMessage && <Message type="error">{errorMessage}</Message>}
    </Modal>
  );
};
