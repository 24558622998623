import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { NetTermOptions, DateRangeOptions, SummaryDetailBlock, Typho } from '../../../../core';
import { AnalyticsChart } from '../AnalyticsChart/AnalyticsChart';

import { useCurrencyFormat } from '../../../../../hooks/useCurrencyFormat';

import {
  useGetAllPaymentTermsQuery,
  useGetPaymentSummaryQuery,
} from '../../../../../services/analyticsApi';
import { reOrderingTheTerms } from 'helpers/arrayFormating';

const initialState = {
  status: { in: ['APPROVED', 'INVOICED', 'PAID'] },
  payoutTerm: { active: { equalTo: true } },
};

export const FilteredPaymentSummaryBlock = () => {
  const [summary, setSummary]: any = useState(initialState);
  const [range, setSelectedRange]: any = useState('ALL');
  const [terms, setSelectedTerms]: any = useState([]);
  const [isAllTermChecked, setIsAllTermChecked]: any = useState(true);
  const { data } = useGetPaymentSummaryQuery(summary, {
    refetchOnMountOrArgChange: true,
  });
  //const [data, setData2]: any = useState(data);

  let sorted: any = null;

  const handeDateRangeChange = (event: any) => {
    const selectedValue = event.target.value;
    const targetVal = selectedValue.replace('payment-', '');

    setSelectedRange(targetVal);

    if (targetVal === 'ALL') {
      const stringConvertedTerms = '"' + terms.join('","') + '"';

      if (terms?.length === 0) {
        setSummary({
          status: { in: ['APPROVED', 'INVOICED', 'PAID'] },
          payoutTerm: { active: { equalTo: true } },
        });
      }

      if (terms?.length > 0) {
        setSummary({
          status: { in: ['APPROVED', 'INVOICED', 'PAID'] },
          payoutTerm: { active: { equalTo: true } },
          payout_term_id: {
            in: [`${stringConvertedTerms}`],
          },
        });
      }
    } else {
      const convertToInt = parseInt(targetVal);
      const greaterThan = moment()
        .subtract(convertToInt, 'day')
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .format();
      const lessThan = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).format();

      setSummary({
        ...summary,
        created_at: {
          lessThan: `${lessThan}`,
          greaterThan: `${greaterThan}`,
        },
      });
    }
  };

  const handleTermOnChange = (event: any) => {
    setIsAllTermChecked(false);
    const selectedtTerms: any = [...terms];

    if (event?.currentTarget?.checked !== undefined && event?.currentTarget?.checked) {
      selectedtTerms.push(event.target.value);
    } else {
      selectedtTerms.splice(selectedtTerms.indexOf(event.target.value), 1);
      // selectedtTerms.splice(selectedtTerms.indexOf(`old_${event.target.value}`), 1);
    }

    setSelectedTerms(selectedtTerms);

    const stringConvertedTerms = '"' + selectedtTerms.join('","') + '"';

    if (selectedtTerms?.length === 0) {
      // setSummary({
      //   status: { in: ['APPROVED', 'INVOICED', 'PAID'] },
      //   payoutTerm: { active: { equalTo: true } },
      // });
      setSummary({
        status: { in: [] },
        payoutTerm: { active: { equalTo: true } },
        payout_term_id: {
          in: [],
        },
      });
    }

    if (selectedtTerms?.length > 0) {
      setSummary((summary: any) => ({
        ...summary,
        payout_term_id: {
          in: [`${stringConvertedTerms}`],
        },
        status: { in: ['APPROVED', 'INVOICED', 'PAID'] },
      }));
    }
  };

  const handleSelectAll = (event: any, toSelectAll = false) => {
    let selectedtTerms: any = [];

    if (
      (event?.currentTarget?.checked !== undefined && event?.currentTarget?.checked) ||
      toSelectAll
    ) {
      for (const term of sorted) {
        selectedtTerms.push(term.id);
      }
      setIsAllTermChecked(true);
    } else {
      selectedtTerms = [];
      setIsAllTermChecked(false);
      // selectedtTerms.splice(selectedtTerms.indexOf(`old_${event.target.value}`), 1);
    }

    setSelectedTerms(selectedtTerms);

    const stringConvertedTerms = '"' + selectedtTerms.join('","') + '"';

    if (selectedtTerms?.length === 0) {
      setSummary({
        status: { in: [] },
        payoutTerm: { active: { equalTo: true } },
        payout_term_id: {
          in: [],
        },
      });
    }

    if (selectedtTerms?.length > 0) {
      setSummary((summary: any) => ({
        ...summary,
        payout_term_id: {
          in: [`${stringConvertedTerms}`],
        },
        status: { in: ['APPROVED', 'INVOICED', 'PAID'] },
      }));
    }
  };

  const { data: termDataObj, isSuccess: isTermDataLoadSuccess } = useGetAllPaymentTermsQuery(null);
  const [termsData, setTermsData]: any = useState();

  useEffect(() => {
    if (isTermDataLoadSuccess) {
      const getData = termDataObj;
      const dataArray = getData ? getData?.paymentTerms?.nodes : [];
      const sortedObj = reOrderingTheTerms(dataArray);
      sorted = sortedObj;
      setSelectedTerms(sortedObj);
      handleSelectAll(null, true);
      setTermsData(sortedObj);
    }
  }, [termDataObj]);

  return (
    <>
      <div className="flex items-start justify-between pb-6 mb-6 border-b border-solid border-gray-220">
        <div className="flex flex-col w-full max-w-[608px]">
          <div className="flex items-start mb-3">
            <Typho
              type="p"
              additionalClasses="m-0 whitespace-nowrap"
              size="12s"
              weight="bold"
              color="black"
            >
              Show Data For
            </Typho>
            <div className="flex flex-wrap justify-start ml-4">
              {terms && terms.length > 0 && (
                <NetTermOptions
                  onChange={handleTermOnChange}
                  onSelectAll={handleSelectAll}
                  terms={terms}
                  termsData={termsData}
                  // setAllTerms={setAllTerms}
                  isAllTermChecked={isAllTermChecked}
                />
              )}
            </div>
          </div>
          <div className="grid grid-cols-3 gap-2">
            <SummaryDetailBlock
              label={useCurrencyFormat(data?.invoices?.aggregates?.sum?.amount)}
              subLabel="Early TRANSACTIONS AMOUNT"
              isSmall
            />
            <SummaryDetailBlock
              label={data?.invoices?.totalCount === 0 ? '0' : data?.invoices?.totalCount}
              subLabel="NUMBER OF Early TRANSACTIONS"
              isSmall
            />
            <SummaryDetailBlock
              label={useCurrencyFormat(data?.invoices?.aggregates?.average?.amount)}
              subLabel="Avg Early Transaction"
              isSmall
            />
          </div>
        </div>
        <div className="flex items-center">
          <DateRangeOptions
            optionPrefix="payment"
            onChange={handeDateRangeChange}
            selectedOption={range}
          />
        </div>
      </div>

      <div className="flex flex-col w-full px-6 py-4 mb-6 rounded-lg bg-lightBlue200 min-h-[430px]">
        <AnalyticsChart
          response={data && data?.invoices?.totalCount > 0 ? data : []}
          summary={summary}
        />
      </div>
    </>
  );
};
