import React from 'react';

import Wrapper from '../../../layouts/Wrapper/Wrapper';
import { Logo, Heading } from 'components/core';
import { SignUpForm } from './SignUpForm/SignUpForm';

export const SignUpContainer = () => {
  return (
    <Wrapper
      additionalClasses="items-center justify-center"
      isNavigationEnabled={false}
      isBackgroundGray={true}
      isFullScreenHeight={true}
      backgroundClass={'!bg-black-900'}
      pageTitle="Login"
    >
      <div className="flex flex-col items-center justify-center">
        <Logo isInverted additionalClasses="mb-[40px]" />
        <Heading
          type="h2"
          label={'Sign Up'}
          additionalClasses="mb-4 text-center"
          colorType="white"
        />
        <SignUpForm />
      </div>
    </Wrapper>
  );
};
