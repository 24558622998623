import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useStripe } from '@stripe/react-stripe-js';

import Card from '../../../../core/OldComponents/Card/Card';
import Heading from '../../../../core/Heading/Heading';
import Form from '../../../../core/FormElements/Form/Form';
import RadioButton from '../../../../core/OldComponents/FormElements/RadioButton/RadioButton';
import Message from '../../../../core/Message/Message';

import CardList from '../CardList/CardList';
import NeMethod from '../ACH/NewMethod/NewMethod';
import {
  PaymentSource,
  paymentMesssages,
} from '../../../../../shared/models/services/paymentsources.model';
import {
  useCreateSourceMutation,
  useGetDefaultPaymentSourcesQuery,
} from '../../../../../services/paymentSourcesApi';
import { Button } from 'components/core';

interface PaymentTypeProps {
  buttonClickHandler?: any;
  btnValue?: string;
  paymentSources?: PaymentSource[] | undefined;
  payloaderButtonState?: boolean;
  verficationModalOpenEvent?: any;
  isPayButtonVisible?: boolean;
}

const PaymentType: React.FC<PaymentTypeProps> = ({
  buttonClickHandler,
  btnValue,
  paymentSources,
  payloaderButtonState,
  verficationModalOpenEvent,
  isPayButtonVisible = true,
}) => {
  const { register, handleSubmit } = useForm();
  const stripe = useStripe();

  const messageState: any = {
    meesage: '',
    messageType: '',
    isVisible: false,
  };

  /**
   * Define states
   */
  const [showACHForm, setShowACHForm] = useState(false);
  const [defaultPaymentSourceId, setDefaultPaymentSourceId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isPaynowDisabled, setIsPaynowDisabled] = useState(false);
  const [notifications, setNotifications] = useState(messageState);
  const [useCreateSourceMutationOp] = useCreateSourceMutation();

  /**
   * Handle add new payment method icon click
   */
  const handleNewPayment = () => setShowACHForm(!showACHForm);

  /**
   * Save new payment method
   */
  const handleNewPaymentMethod = async (data: any) => {
    setIsLoading(true);

    setNotifications({
      ...notifications,
      isVisible: false,
    });

    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const tokenResult = await stripe.createToken('bank_account', {
        ...data,
        country: 'US',
        currency: 'usd',
      });

      const result: any = await useCreateSourceMutationOp({
        type: tokenResult?.token?.type,
        stripe_token: tokenResult?.token?.id,
      });

      if (result?.error) {
        setNotifications({
          message: result?.error?.data?.message,
          isVisible: true,
          messageType: 'error',
        });
      } else {
        setShowACHForm(false);
        setNotifications({
          message: paymentMesssages.methodAddedSuccess,
          isVisible: true,
          messageType: 'success',
        });
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setNotifications({ message: error, isVisible: true, messageType: 'error' });
    }
  };

  /**
   * Handle Back button
   */
  const handleBackButton = () => setShowACHForm(false);

  /**
   * Get default payment source
   */
  const defaultPaymentsource = useGetDefaultPaymentSourcesQuery('');
  const defaultPaymentSourceData = defaultPaymentsource.data;

  useEffect(() => {
    defaultPaymentSourceData &&
      setDefaultPaymentSourceId(defaultPaymentSourceData['default_payment_source']);

    if (btnValue === '$0.00') {
      setNotifications({
        message: paymentMesssages.pastBalnceValidationMessage,
        isVisible: true,
        messageType: 'error',
      });
      setIsPaynowDisabled(true);
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (btnValue.includes('-')) {
      setNotifications({
        message: paymentMesssages.negativePastBalnceValidationMessage,
        isVisible: true,
        messageType: 'error',
      });
      setIsPaynowDisabled(true);
    }
  }, [defaultPaymentSourceData, btnValue]);

  return (
    <>
      {notifications.isVisible && (
        <Message type={notifications.messageType}>{notifications.message}</Message>
      )}

      <Card cardType="whiteBorder" additionalClasses="flex-col pt-4 pl-2 pr-2 pb-3">
        <Heading
          type="h2"
          colorType="dark"
          label="Make Payment"
          additionalClasses="mb-6 pl-2 pr-2"
        />
        <Form
          register={register}
          handleSubmit={handleSubmit}
          onSubmit={buttonClickHandler}
          formClasses="w-full flex"
          formContainerClasses="flex-col"
        >
          {/** Payment method selection */}
          <div className="pl-2 pr-2 mb-8">
            <RadioButton
              name="achPaymentType"
              label="ACH"
              value="ACH"
              checked
              readOnly
              id="achPaymentType"
            />
          </div>
          {/** Card list */}
          <CardList
            addNewMethodFunction={handleNewPayment}
            isVisible={!showACHForm}
            cardData={paymentSources}
            defaultSourceID={defaultPaymentSourceId}
            isFade={isLoading}
            verifyButtonClick={verficationModalOpenEvent}
          />
          {/** New method  from */}
          {isPayButtonVisible && (
            <div className={showACHForm ? 'hidden' : 'flex w-full pl-2 pr-2 mb-2 justify-end'}>
              <Button
                variants="default"
                buttonLabel={`Pay ${btnValue}`}
                iconType="Spin"
                iconClass="inline-flex ml-4 w-6 h-6"
                isIconEnabled={payloaderButtonState}
                iconAlignment={'right'}
                isDisabled={isPaynowDisabled}
                additionalClasses="w-auto px-4"
              />
            </div>
          )}
        </Form>
        <NeMethod
          isVisible={showACHForm}
          onSubmitNewPaymentMethod={handleNewPaymentMethod}
          backButtonEvent={handleBackButton}
          isIconLoading={isLoading}
        />
      </Card>
    </>
  );
};

export default PaymentType;
