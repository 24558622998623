import Wrapper from '../../layouts/Wrapper/Wrapper';
// import Teams from './Teams/Teams';
import { HeaderTabNavigation, Headers } from 'components/core';
import { handleSettingsTabNavData } from 'constants/TabNavData';
import { BalanceCards } from './Balance/Cards';

const SettingsBalanceContainer = () => {
  const [settingsTabNavData] = handleSettingsTabNavData();

  return (
    <Wrapper additionalClasses="flex-col items-start" pageTitle="Settings">
      <Headers headingContent="Settings" isSignOutButton headerWrapperClasses="border-none" />
      <HeaderTabNavigation tabNavData={settingsTabNavData} />
      <BalanceCards />
    </Wrapper>
  );
};

export default SettingsBalanceContainer;
