import { useEffect, useMemo, useState } from 'react';

export interface IuseShowOptionHandler {
  tableColumns?: any;
  data?: any;
  additionalColumns?: any;
  storageItemName?:
    | 'showOptionSubmittedList'
    | 'showOptionApprovedList'
    | 'showOptionPaidList'
    | 'showOptionAllList'
    | 'showOptionVendorList'
    | 'showOptionPropertiesList'
    | 'showOptionInnerPropertiesList';
}

export const useShowOptionHandler = ({
  tableColumns,
  data,
  additionalColumns,
  storageItemName = 'showOptionSubmittedList',
}: IuseShowOptionHandler) => {
  const [columnList, setColumnList]: any = useState([]);
  const [toggleHiddenColumnChanged, setToggleHiddenColumnChanged] = useState(false);
  const [payoutNodes, setPayoutNodes]: any = useState();

  // set the tableColumns into state, when each tme data change
  useEffect(() => {
    const getShowOptionList: any = localStorage.getItem(storageItemName);
    const parseTheShowOptionList = JSON.parse(getShowOptionList);

    if (!parseTheShowOptionList || parseTheShowOptionList === undefined) {
      setColumnList(tableColumns);
    } else {
      setColumnList(parseTheShowOptionList);
    }
  }, []);

  // handle the hidden columns
  const hiddenColumns = useMemo(() => {
    if (columnList && columnList.length > 0) {
      if (additionalColumns && additionalColumns.length > 0) {
        return [
          ...columnList.filter((item: any) => item.isHidden).map((val: any) => val.accessor),
          ...additionalColumns,
        ];
      } else {
        return [...columnList.filter((item: any) => item.isHidden).map((val: any) => val.accessor)];
      }
    }
  }, [toggleHiddenColumnChanged, data, tableColumns]);

  // update the column list into Local storage
  useEffect(() => {
    if (columnList && columnList.length > 0) {
      localStorage.setItem(storageItemName, JSON.stringify(columnList));
    }
  }, [columnList]);

  // set the data into state after on some milli seconds
  useEffect(() => {
    setTimeout(() => {
      setPayoutNodes(data);
    }, 50);
  }, [data]);

  return [
    hiddenColumns,
    toggleHiddenColumnChanged,
    setToggleHiddenColumnChanged,
    columnList,
    setColumnList,
    payoutNodes,
  ];
};
