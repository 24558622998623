export const disabledFilterationForInvoices = false;

export const initPayoutProcess = {
  mode: 'external',
  external_account_payout_id: '',
  external_pay_id: '',
  notes: '',
};

export const sendDigest = true;

export const invoiceStatusses = {
  Approved: 0,
  Scheduled: 0,
  Paid: 0,
  Reversed: 0,
  Submitted: 0,
  Void: 0,
  Unpaid: 0,
  'Check Issued': 0,
  'Check Voided': 0,
  'Check Expired': 0,
  'Check Printed': 0,
  'Check In Transit': 0,
  'Check Failed': 0,
  'Check Refunded': 0,
  'Check Cashed': 0,
};

export const invoiceStatussesModal = {
  Approved: 0,
  Scheduled: 0,
  paid: 0,
  reversed: 0,
  Submitted: 0,
  Void: 0,
  Unpaid: 0,
  'Check Issued': 0,
  'Check Voided': 0,
  'Check Expired': 0,
  'Check Printed': 0,
  'Check In Transit': 0,
  'Check Failed': 0,
  'Check Refunded': 0,
  'Check Cashed': 0,
};

export const propertyStatusses = {
  Active: 0,
  Inactive: 0,
  Delinquent: 0,
};

export const vendorsStatusses = {
  Active: 0,
  Inactive: 0,
};

export const toApproveHiddenColumns = [
  'approved_at',
  'paid_at',
  'stripe_transfer_id',
  'CHECK DATE',
  'PAY REF',
  'AR MODE',
  'PAID BY',
  'customer_paid_at',
  'paid_by',
  'VENDOR DUE',
  'customer_payment_due_at',
  'pay_id',
  'check_date',
  'payout_scheduled_at',
  'paid_amount',
  'processing_fee_amount',
  'pay_mode',
  'check_number',
];

export const approveHiddenColumns = [
  'estimated_approved_at',
  'pay_mode',
  'check_number',
  'customer_paid_at',
];

export const defaultInvoicesColumnOrder = [
  'invoiced_at',
  'VENDOR',
  'number',
  'coupa_invoice_number',
  'description',
  'property.name',
  'STATUS',
  'estimated_approved_at',
  'approved_at',
  'payout_term_id',
  'VENDOR DUE',
  'paid_at',
  'PAID BY',
  'customer_payment_due_at',
  'CHECK DATE',
  'customer_paid_at',
  'AR MODE',
  'PAY REF',
  'pay_mode',
  'amount',
  'payout_amount',
  'paid_amount',
  'processing_fee_amount',
  'payout_term_amount',
  'coupa_draw_account',
];

export const toApproveInvoicesColumnOrder = [
  'approved_at',
  'paid_at',
  'stripe_transfer_id',
  'CHECK DATE',
  'PAY REF',
  'customer_paid_at',
  'paid_by',
  'VENDOR DUE',
  'customer_payment_due_at',
  'pay_id',
  'check_date',
  'payout_scheduled_at',
];

export const timeline = {
  batch: {
    step1Title: 'Batch Submitted',
    step2Title: 'Batch Processing',
    step3Title: 'Batch Ready for Payout',
    step4Title: 'Batch Payout Triggered',
    step5Title: 'Batch Payout On The Way',
    step6Title: 'Batch Payout Complete',
    transcriptLink: 'View Transaction Receipt',
  },
  invoice: {
    invApproveText: 'Invoice Approved',
    invSubmittedText: 'Invoice Submitted',
    invDelinquentText: 'Invoice Submitted on Delinquent Property',
    notYetApproveText: 'Invoice Not Yet Approved',
    paymentTrigger: 'Payment Triggered',
    paymentOntheWay: 'Payment On the Way',
    paymentArrived: 'Payment Arrived',
    netUpdatedTo: 'Net Terms Updated to',
    pendingCustomerPay: 'Pending Customer Payment',
    estimated: 'Estimated',
    waitingForCustomerToApprove: 'Waiting for Customer to approve',
    viewTranReceipt: 'View Transaction Receipt',
    termsUpdatedTo: 'Terms Updated to',
    details: 'Details',
    less: 'Less',
    yourCustomer: 'your customer',
    approvalDeadlineMissed: 'Approval Deadline Missed',
    approvalDeadlineMissedDescription:
      'It looks like your customer has not approved this invoice yet. This has resulted in your terms being updated for this invoice. Please remind your customer to give this the thumbs up so that we can move forward with your payment.',
    delinquentTermChangeDescription:
      'We are sorry to inform you that the terms have changed on this invoice. The reason for this change is due to {{customer}} being over their lending limits with PayUp. Once {{customer}} reconciles their past due invoices with PayUp, we will immediately initiate your payment.',
    fundsRecivedFromCustomer: 'Funds received from Customer',
  },
};
