import { Typhography } from 'components/core';
// import { StatusTag } from 'components/base/core/Chips/StatusTag/StatusTag';
import Icon from 'components/core/Media/Icon/Icon';
import { useState } from 'react';
import { payoutsApi } from 'services/payoutsApi';
import { CONFIG } from 'shared/configs/config';

export const Search = () => {
  const [state, setState]: any = useState({
    first: 0,
  });
  const { data, isSuccess } = payoutsApi.useGetPayoutsQuery(state);

  const handleOnChange = async (e: any) => {
    const searchParam = {
      first: CONFIG.DATA_OVERALL_LIMIT,
      offset: 0,
      filter: {
        coupa_invoice_number: { likeInsensitive: `"%${e.target.value}%"` },
      },
    };

    const getValueLength = e.target.value.length;

    if (getValueLength >= 3) {
      setState(searchParam);
    } else {
      setState({
        first: 0,
      });
    }
  };

  const getDataNodes = isSuccess && data.payouts !== null ? data.payouts.nodes : [];

  return (
    <div>
      <form className="relative">
        <div className="flex items-center w-[332px] h-6 rounded-lg bg-white pl-2">
          <Icon iconType="SearchIcon" additionalClasses="mr-1" />
          <input
            type={'text'}
            placeholder="Search"
            className="h-6 w-[308px] bg-white placeholder:gray text-12s font-light rounded-lg outline-none"
            onChange={handleOnChange}
          />
        </div>
        {isSuccess && getDataNodes.length > 0 && (
          <div className="absolute left-0 right-0 py-2 mt-1 bg-white rounded-lg shadow-showMoreDropDown max-h-[250px] overflow-y-auto">
            {getDataNodes &&
              getDataNodes.map((payout: any) => {
                return (
                  <a
                    className="flex items-center justify-between w-full px-4 py-2 cursor-pointer hover:bg-grey-50"
                    key={payout.id}
                    href={`/api/coupa/download-invoice/${payout.id}`}
                    rel={'noreferrer'}
                    target="_blank"
                  >
                    <Typhography component="span" type="h1" color="text-grey-700">
                      {payout.description}
                    </Typhography>
                  </a>
                );
              })}
          </div>
        )}
      </form>
    </div>
  );
};
