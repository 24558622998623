import LoadingSpinner from 'components/core/LoadingSpinner/LoadingSpinner';
import Wrapper from 'components/layouts/Wrapper/Wrapper';
import ListEmpty from 'components/modules/Common/ListEmpty/ListEmpty';
import { useGetVendorsQuery } from '../../../../services/vendorsApi';
import Headers from '../../../core/Headers/Headers';
import { handleVndorsTabNavData } from 'constants/TabNavData';
import { Message, ToolBar, VendorsStatsBar } from 'components/core';
import DataList from 'components/core/SuperTable/DataList/DataList';
import moment from 'moment';
import { CONFIG } from 'shared/configs/config';
import {
  serverSidePaginationActiveForVendors,
  useServerSidePagination,
} from 'hooks/useServerSidePagination';
import { useState } from 'react';
import { helperVendorColumOrder } from 'helpers/modules/helperVendor';
import { useShowOptionHandler } from 'hooks/useShowOptionHandler';

/**
 * Primary UI component for user interaction
 */
const AllVendorsContainer = () => {
  const [visibleColumns, setVisibleColumns] = useState([]);
  const [filteredRow, setFilteredRow] = useState([]);
  const [tableColumns, handleIconClick] = helperVendorColumOrder();

  const [pageSizeOptions, paginationState, previousButtonClick, nextButtonClick, setPageSize]: any =
    useServerSidePagination();

  const vendorParams = {
    first: serverSidePaginationActiveForVendors
      ? paginationState.pageSize
      : CONFIG.DATA_OVERALL_LIMIT,
    offset: serverSidePaginationActiveForVendors
      ? paginationState.pageIndex * paginationState.pageSize
      : 0,
    filter: {
      created_at: {
        lessThan: moment()
          .add(1, 'day')
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
          .format(),
      },
    },
  };

  const paramsForDownloadAllData = {
    first: CONFIG.DATA_OVERALL_LIMIT,
    offset: 0,
    filter: {
      created_at: {
        lessThan: moment()
          .add(1, 'day')
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
          .format(),
      },
    },
  };

  /**
   * handle data request from redux_toolkit
   */
  const { data, isLoading, isSuccess, isError, error }: any = useGetVendorsQuery(vendorParams);
  const vendorsDataAccountNodes: any =
    isSuccess && data && data?.accounts !== null ? data?.accounts?.nodes : null; // Get vendors data Object
  const [vendorsTabnavData] = handleVndorsTabNavData(data);

  const totalRecords: number = data && data?.accounts !== null ? data?.accounts?.totalCount : 0;
  const totalPageCount = Math.ceil(totalRecords / paginationState.pageSize);

  const [
    hiddenColumns,
    toggleHiddenColumnChanged,
    setToggleHiddenColumnChanged,
    columnList,
    setColumnList,
    vendorNodes,
  ] = useShowOptionHandler({
    tableColumns: tableColumns,
    data: vendorsDataAccountNodes,
    additionalColumns: [],
    storageItemName: 'showOptionVendorList',
  });

  return (
    <Wrapper additionalClasses="flex-col items-start" pageTitle="Vendors">
      {/**
       * Title section
       */}
      <Headers headingContent="Vendors" />
      {/**
       * Tab navigation
       */}
      <ToolBar
        navigationData={vendorsTabnavData}
        isShowOptionEnabled={
          isSuccess && data && data?.accounts !== null && data?.accounts?.nodes.length > 0
        }
        isCountVisible
        visibleColumns={visibleColumns}
        filteredRow={filteredRow}
        isDownloadCsvButtonEnabled={
          isSuccess && data && data?.accounts !== null && data?.accounts?.nodes.length !== 0
        }
        columns={columnList}
        handleIconClick={(item: any) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          handleIconClick(
            item,
            columnList,
            setColumnList,
            setToggleHiddenColumnChanged,
            toggleHiddenColumnChanged
          );
        }}
        isDownloadCompleteData={true}
        queryParam={paramsForDownloadAllData}
        tabType="vendor"
      />
      {/**
       * Handle loader
       */}
      {isLoading && <LoadingSpinner />}
      {/**
       * Handle api request sucess
       */}
      {isError && (
        <Message type="error" title={`All Vendors`}>
          {error.error ?? error.data.message ?? error.data?.error}
        </Message>
      )}

      {isSuccess && (
        <>
          {/**
           * Use to display the inactive and active vendor counts
           */}

          {vendorNodes && vendorNodes.length > 0 ? (
            <>
              <VendorsStatsBar />
              <DataList
                data={vendorNodes}
                editNetTerm={true}
                isLink={true}
                tableType="vendor"
                isPagination
                subTabType="allVendors"
                isServerSidePagination={serverSidePaginationActiveForVendors}
                previousPageClick={previousButtonClick}
                nextPageClick={nextButtonClick}
                setPageSizeValue={setPageSize}
                options={pageSizeOptions}
                totalPageCount={totalPageCount}
                pageSizeVal={paginationState.pageSize}
                pageIndexVal={paginationState.pageIndex}
                setVisibleColumns={setVisibleColumns}
                setFilteredRow={setFilteredRow}
                hiddenColumnList={hiddenColumns}
                toggleHiddenColumnChanged={toggleHiddenColumnChanged}
              />
            </>
          ) : (
            <ListEmpty />
          )}
        </>
      )}
    </Wrapper>
  );
};

export default AllVendorsContainer;
