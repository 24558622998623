export const ColumnSearchFilter = ({ column }: any) => {
  const { filterValue, setFilter } = column;
  return (
    <span>
      <input
        value={filterValue || ''}
        onChange={(event: any) => setFilter(event?.target?.value)}
        placeholder={'Search...'}
        className="w-[120px] h-8 mt-2 border border-solid rounded-lg form-input border-gray-180 shadow-input text-10s text-black-900 ml-2"
      ></input>
    </span>
  );
};
