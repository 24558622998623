import AllApprovalsContainer from 'components/modules/Approvals/All';

/**
 * Primary UI component for user interaction
 */
const AllApprovals = () => {
  return <AllApprovalsContainer />;
};

export default AllApprovals;
