import Wrapper from '../../layouts/Wrapper/Wrapper';
import Headers from '../../core/Headers/Headers';
import { Button as NewButton, ToolBar } from '../../core';
import React, { useState } from 'react';
import SuperTable from '../../core/SuperTable/SuperTable';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';
import {
  useGetReconciliationDataQuery,
  useReconcileCheckBatchMutation,
} from '../../../services/resmanPayablesApi';

const ReconcileACHContainer = () => {
  const { data: achReconciliationData } = useGetReconciliationDataQuery('');
  const [mutation] = useReconcileCheckBatchMutation();
  const data = React.useMemo(
    () => achReconciliationData?.transactions?.confirmed ?? [],
    [achReconciliationData]
  );
  const [defaultSortOrder] = useState({ id: 'completed_at', desc: true });
  const columns = React.useMemo(
    () => [
      {
        Header: 'Status',
        accessor: `transaction_id`,
        Cell: (row: any) => {
          return (
            <NewButton
              variants="extraSmall"
              additionalClasses="w-[114px] ml-2"
              buttonLabel={'Reconcile'}
              onClick={async () => {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const { pay_id, transaction_id, property_id } = row.row.original;
                const res: FetchBaseQueryError | SerializedError | any = await mutation({
                  data: [
                    {
                      pay_ids: [pay_id],
                      paid_with: 'ach',
                      transaction_id: transaction_id,
                      property_id: property_id,
                    },
                  ],
                });
                if (!res.data[0].success) {
                  alert('Error in Reconcilition. Reach out to Peeyush');
                }
              }}
            />
          );
        },
      },

      {
        Header: 'Property',
        accessor: (row: any) => `${row?.property_name} ${row?.treasury_counter_party_name ?? ''}`,
      },
      {
        Header: 'CounterParty',
        accessor: 'counterparty',
      },
      {
        Header: 'Batch',
        accessor: 'pay_id',
      },
      {
        Header: 'Amount',
        accessor: 'amount',
      },
      {
        Header: 'Completed At',
        accessor: 'completed_at',
      },
      {
        Header: 'Check Date',
        accessor: 'check_date',
      },
    ],
    achReconciliationData
  );
  return (
    <Wrapper additionalClasses="flex-col items-start" pageTitle="Reconciliation">
      <Headers headingContent="Reconciliation" />
      <ToolBar
        navigationData={[
          {
            id: 1,
            urlPath: '/reconcile/checks',
            navTitle: 'Mercury',
            navClasses: 'active',
          },
          {
            id: 2,
            urlPath: '/reconcile/ach',
            navTitle: 'ACH',
            navClasses: 'inactive ',
          },
          {
            id: 3,
            urlPath: '/reconcile/manual',
            navTitle: 'Manual',
            navClasses: 'inactive ',
          },
        ]}
        isShowOptionEnabled={false}
        isCountVisible={false}
      />
      <SuperTable
        columns={columns}
        hiddenColumns={[]}
        data={data}
        defaultSortBy={defaultSortOrder}
        paginationOptions={[50, 100, 200, 400, 1000, 2000, 4000, 6000]}
        tableWrapperType="grid-height-large"
        isPagination={false}
      ></SuperTable>
    </Wrapper>
  );
};

export default ReconcileACHContainer;
