import React from 'react';
import classNames from 'classnames';

import Icon from '../../Media/Icon/Icon';

import { BlockStatusContentsProps } from 'shared/models/components/base.model';

export const BlockStatusContents = ({
  additionalClasses,
  additionalClassesWrapper,
  onCloseButtonClick,
  mainContent,
  subContent,
}: BlockStatusContentsProps) => {
  return (
    <div
      className={classNames([
        'w-[370px] flex flex-col bg-white justify-center rounded-lg z-30 shadow-paynowModal absolute right-1 opacity-0',
        additionalClassesWrapper,
      ])}
    >
      <div
        className={classNames([
          'flex flex-col items-center justify-center w-full',
          additionalClasses,
        ])}
      >
        <div className="absolute z-10 cursor-pointer top-4 right-4" onClick={onCloseButtonClick}>
          <Icon iconType={'CloseIcon'} />
        </div>

        <div className="relative flex flex-col items-center justify-center w-full">
          <Icon iconType="TickIconForSuccess" />
          <div className="flex flex-col w-full mt-4 text-center">
            <span className="flex justify-center w-full font-medium text-20s text-black-900">
              {mainContent}
            </span>
            {subContent && (
              <span className="flex justify-center w-full px-10 mt-6 font-light text-14s text-black-900">
                {subContent}
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
