import { Grid, SelectBox, StatusBlock, Typhography } from 'components/core';
import EmptyList from 'components/core/EmptyList/EmptyList';
import { GraphPayoutScheduled } from 'components/modules/Common/GraphPayoutScheduled/GraphPayoutScheduled';
import { useCurrencyFormat } from 'hooks/useCurrencyFormat';
import { updateURLParams, useDateRangeSorting } from 'hooks/useDateRangeSorting';
import moment from 'moment';
import { useEffect } from 'react';
import { createSearchParams, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useGetPayoutsQuery } from 'services/payoutsApi';
import { useGetVendorsSummaryGraphDataQuery } from 'services/vendorsApi';
import { CONFIG } from 'shared/configs/config';
import { nextThirtyDaysDropdownItems } from 'constants/DateRangeDropDownDatas';

export const ScheduledSummary = () => {
  const { id } = useParams();

  const urlParamName = 'payoutsScheduledFor';

  const [
    rangeSelector,
    defaultSortingText,
    itemSlectedState,
    showDropdown,
    handlerSortingDropDownClick,
    handlerSortingOption,
  ]: any = useDateRangeSorting('Next 30 Days', nextThirtyDaysDropdownItems, urlParamName);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    navigate(
      {
        pathname: location.pathname,
        search: `?${createSearchParams(
          updateURLParams(defaultSortingText, urlParamName, location.search)
        )}`,
      },
      { replace: true }
    );
  }, [defaultSortingText]);

  const getGreaterThanDate = moment()
    .add(0, 'day')
    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    .format();

  const getLessThanDate =
    rangeSelector > 0
      ? moment()
          .add(rangeSelector + 1, 'day')
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
          .format()
      : undefined;

  const payoutsQueryParam = {
    first: CONFIG.DATA_OVERALL_LIMIT,
    offset: 0,
    filter: {
      status: { in: ['APPROVED'] },
      account_id: { equalTo: JSON.stringify(id) },
      or: [
        {
          and: [
            {
              payout_due_at: {
                lessThan: getLessThanDate,
                greaterThan: getGreaterThanDate,
              },
            },
            {
              customer_paid_at: {
                isNull: false,
              },
            },
          ],
        },
        {
          payout_scheduled_at: {
            lessThan: getLessThanDate,
            greaterThan: getGreaterThanDate,
          },
        },
      ],
    },
  };

  // useGetPayoutsQuery RTKquery will used to fetch the vendor data
  const { data: vendorData, isSuccess: isVendorSuccess } = useGetPayoutsQuery(payoutsQueryParam);
  // set the payouts count
  const getPayoutsCount = isVendorSuccess ? vendorData?.payouts?.totalCount : '';
  // set the payouts amount value
  const getPayoutsAmount = isVendorSuccess ? vendorData?.payouts?.aggregates?.sum?.amount : '';
  // set the Change percentage
  const getPayoutsAmountChangePercentage = isVendorSuccess
    ? vendorData?.payouts?.change_percentage
    : '';

  const graphFilterParams = {
    filter: {
      id: { equalTo: JSON.stringify(id) },
      payoutsByAccountId: {
        status: {
          in: ['APPROVED'],
        },
        or: [
          {
            and: [
              {
                payout_due_at: {
                  lessThan: getLessThanDate,
                  greaterThan: getGreaterThanDate,
                },
              },
              {
                customer_paid_at: {
                  isNull: false,
                },
              },
            ],
          },
          {
            payout_scheduled_at: {
              lessThan: getLessThanDate,
              greaterThan: getGreaterThanDate,
            },
          },
        ],
      },
    },
    groupBy: 'PAYOUT_DUE_AT_TRUNCATED_TO_DAY_CST',
  };

  const { data: graphData, isSuccess: isGraphDataSuccess } =
    useGetVendorsSummaryGraphDataQuery(graphFilterParams);
  const getNodes =
    isGraphDataSuccess && graphData.accounts.nodes[0].payoutsByAccountId.groupedAggregates;

  const isPermitToVisbleAllRange =
    defaultSortingText === '500' ||
    defaultSortingText === 'All' ||
    getPayoutsCount === 0 ||
    defaultSortingText === 'YTD';

  return (
    <div className="flex flex-col justify-start w-full mb-12">
      <div className="flex justify-between w-full mb-4">
        <Typhography component="p" type="h2" color="text-grey-900">
          {'PAYOUTS SCHEDULED'}
        </Typhography>
        <SelectBox
          items={nextThirtyDaysDropdownItems}
          itemClickHandler={handlerSortingOption}
          defaultSelectBoxText={defaultSortingText}
          itemSelected={itemSlectedState}
          dropDownState={showDropdown}
          dropDownClickHandler={handlerSortingDropDownClick}
          additionalClasses={'-mt-[6px]'}
        />
      </div>
      <div className="flex w-full pb-3 mb-6 border-b border-solid bolder-grey-100">
        <Grid columnCount={2} additionalClasses={'w-full '}>
          <StatusBlock
            firstValue={getPayoutsCount === 0 ? '0' : getPayoutsCount}
            secondValue={''}
            thirdValue={!isPermitToVisbleAllRange ? defaultSortingText : ''}
            blockHeading={'Invoices'}
            iconColor={'text-grey-400'}
            secondValueColor={'text-grey-400'}
            isIconVisible={!isPermitToVisbleAllRange}
            isRemoveBorderPadding
          />
          <StatusBlock
            firstValue={useCurrencyFormat(getPayoutsAmount)}
            secondValue={!isPermitToVisbleAllRange ? `${getPayoutsAmountChangePercentage}%` : ''}
            thirdValue={!isPermitToVisbleAllRange ? defaultSortingText : ''}
            blockHeading={'Payouts Scheduled'}
            iconColor={'text-grey-400'}
            isIconVisible={!isPermitToVisbleAllRange}
            secondValueColor={'text-grey-400'}
          />
        </Grid>
      </div>
      {isGraphDataSuccess && (
        <>
          {getNodes.length > 0 ? (
            <GraphPayoutScheduled
              getNodes={getNodes}
              getLessThanDate={getLessThanDate}
              getGreaterThanDate={getGreaterThanDate}
              rangeSelector={rangeSelector}
            />
          ) : (
            <EmptyList />
          )}
        </>
      )}
    </div>
  );
};
