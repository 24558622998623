import React from 'react';
import { isPermitted, useGetUserQuery } from 'services/userApi';
import ErrorContainer from '../Error/Error';

/*
 * Primary UI component for user interaction
 */
const Error302Container = () => {
  const { data: user } = useGetUserQuery({});
  const isDashboardURLAccessible = isPermitted(user, 'analytics-menu');
  return (
    <ErrorContainer
      redirectURL={isDashboardURLAccessible ? '/dashboard' : '/invoices/submitted'}
      errorType={302}
      errorText="The target resource resides temporarily under a different URI."
      buttonLabel="Sign Out"
      isRedirectToLogin={true}
    />
  );
};

export default Error302Container;
