import React from 'react';
import { useGetCustomersQuery } from '../../../../services/customersApi';
import {
  isPermitted,
  useGetUserCustomerRolesQuery,
  useGetUserQuery,
} from '../../../../services/userApi';
import { camelCase } from '../../../../shared/utilities/utils';

export const Organizations = () => {
  const { data: customers } = useGetCustomersQuery({});
  const { data: user } = useGetUserQuery({});
  const { data: roles } = useGetUserCustomerRolesQuery({});
  const userRoles = roles?.userCustomerRoles?.nodes.filter((node: any) => {
    return node?.user_id === user?.id;
  });
  return (
    <div className="flex w-full">
      <div className="flex flex-row w-full">
        <div className="flex text-black-900 text-14s font-normal w-[85px]">Organization</div>
        <div className="pl-4">
          {customers?.customers?.nodes.map((node: any) => {
            if (isPermitted(user, 'switch-workspace')) {
              return (
                <div className="h-5 mb-2 text-black text-14s" key={node?.id}>
                  <span className="opacity-60 text-inherit font-inherit">
                    {node?.business_name}
                  </span>
                  <span className="ml-2 opacity-40 text-inherit font-inherit">[Super Admin]</span>
                </div>
              );
            } else {
              return userRoles?.map((role: any) => {
                return (
                  <div className="h-5 mb-2 text-black text-14s" key={node?.id}>
                    <span className="opacity-60 text-inherit font-inherit">
                      {node?.business_name}
                    </span>
                    <span className="ml-2 opacity-40 text-inherit font-inherit">
                      [
                      {role?.role
                        ?.split('_')
                        .map((str: string) => camelCase(str))
                        .join(' ')}
                      ]
                    </span>
                  </div>
                );
              });
            }
          })}
        </div>
      </div>
    </div>
  );
};
