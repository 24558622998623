import React from 'react';
import Typho from '../../../../core/OldComponents/Typho/Typho';
import { SummaryDetailBlock } from '../../../../core/FilterSummaryBlock/SummaryDetailBlock/SummaryDetailBlock';
import { useGetPaymentSummaryQuery } from '../../../../../services/analyticsApi';
import { useCurrencyFormat } from '../../../../../hooks/useCurrencyFormat';

export const PaymentSummaryBlock = () => {
  const { data } = useGetPaymentSummaryQuery({
    payout_term_id: {
      in: ['"net30", "old_net30"'],
    },
    status: { in: ['APPROVED', 'INVOICED', 'PAID'] },
    payoutTerm: { active: { equalTo: true } },
  });

  return (
    <div className="flex flex-col flex-wrap pb-6 mb-6 border-b border-solid border-gray-220 lg:pr-[217px]">
      <Typho type="p" additionalClasses="mt-0 mb-3" size="12s" weight="medium" color="black">
        Payments Data for Net 30+ (No Early Payouts option)
      </Typho>
      <div className="grid grid-cols-3 gap-2 max-h-16">
        <SummaryDetailBlock
          label={useCurrencyFormat(data?.invoices?.aggregates?.sum?.amount)}
          subLabel="TRANSACTIONS AMOUNT"
          isSmall
        />
        <SummaryDetailBlock
          label={data?.invoices?.totalCount === 0 ? '0' : data?.invoices?.totalCount}
          subLabel="NUMBER OF TRANSACTIONS"
          isSmall
        />
        <SummaryDetailBlock
          label={useCurrencyFormat(data?.invoices?.aggregates?.average?.amount)}
          subLabel="Avg Transaction"
          isSmall
        />
      </div>
    </div>
  );
};
