import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import Wrapper from '../../layouts/Wrapper/Wrapper';
import Payment from './Payment/Payment';

import { useGetConfigQuery } from '../../../services/configApi';
import { HeaderTabNavigation, Headers } from 'components/core';
import { handleSettingsTabNavData } from 'constants/TabNavData';
import { isPermitted, useGetUserQuery } from 'services/userApi';

const SettingsPaymentsContainer = () => {
  const [settingsTabNavData] = handleSettingsTabNavData();
  const configResponse = useGetConfigQuery('');
  const { data: user } = useGetUserQuery({});

  return (
    <Wrapper additionalClasses="flex-col items-start" pageTitle="Settings">
      <Headers headingContent="Settings" isSignOutButton headerWrapperClasses="border-none" />

      <HeaderTabNavigation tabNavData={settingsTabNavData} />

      {configResponse?.data?.stripe_api_public_key && (
        <Elements stripe={loadStripe(configResponse?.data?.stripe_api_public_key)}>
          {isPermitted(user, 'payment-tab') && <Payment />}
        </Elements>
      )}
    </Wrapper>
  );
};

export default SettingsPaymentsContainer;
