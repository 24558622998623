import { useState } from 'react';
/**
 * set All Vendor Profile use states
 */
export const useVendorProfileState = () => {
  const [enableReadonlyForm, setEnableReadonlyForm] = useState(true);
  const [selectedDate, setSelectedDate]: any = useState();
  const [dobError, setDOBError] = useState('');
  const [enableTermUpdate, setEnableTermUpdate] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [payoutId, setPayoutId]: any = useState();
  const [currentNetTerm, setCurrentNetTerm]: any = useState();
  const [currentDesc, setCurrentDesc] = useState('');
  const [selectedNetTerm, setSelectedNetTerm]: any = useState();
  const [newEarning, setNewEarning]: any = useState();
  const [errorMessage, setErrorMessage]: any = useState();
  const [isError, setIsError] = useState(false);
  const [showList, setShowList] = useState(false);
  const [showDate, setShowDate] = useState('');
  const [originalData, setOriginalData]: any = useState();
  const [detailSubmitted, setDetailSubmitted] = useState(false);

  return [
    enableReadonlyForm,
    setEnableReadonlyForm,
    selectedDate,
    setSelectedDate,
    dobError,
    setDOBError,
    isChecked,
    setIsChecked,
    currentNetTerm,
    selectedNetTerm,
    setSelectedNetTerm,
    newEarning,
    errorMessage,
    isError,
    setShowList,
    setShowDate,
    originalData,
    setOriginalData,
    detailSubmitted,
    setDetailSubmitted,
    showDate,
    showList,
    setCurrentDesc,
    setCurrentNetTerm,
    enableTermUpdate,
    setEnableTermUpdate,
    isModalOpen,
    setPayoutId,
    setIsModalOpen,
    payoutId,
    currentDesc,
    setNewEarning,
    setErrorMessage,
    setIsError,
  ];
};

/**
 * set All Vendor Profile use states
 */
export const useBusinessProfileState = () => {
  const [showList, setShowList] = useState(false);
  const [detailSubmitted, setDetailSubmitted] = useState(false);
  const [originalData, setOriginalData]: any = useState();

  return [
    setShowList,
    detailSubmitted,
    setDetailSubmitted,
    originalData,
    setOriginalData,
    showList,
  ];
};
