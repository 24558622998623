import React from 'react';
import ProfileContainer from '../../components/modules/Profile/Index';

/**
 * Primary UI component for user interaction
 */
const Profile = () => {
  return <ProfileContainer />;
};

export default Profile;
