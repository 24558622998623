import InActiveVendorsContainer from 'components/modules/Vendors/InActive';

/**
 * Primary UI component for user interaction
 */
const InActiveVendors = () => {
  return <InActiveVendorsContainer />;
};

export default InActiveVendors;
