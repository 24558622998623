import { Button } from '../Button/Button';
import { Typhography } from '../Typhography/Typhography';
import { useComponentVisible } from 'hooks/useComponentVisible';
import Icon from '../Media/Icon/Icon';

export const ShowMoreButton = ({ columns, handleIconClick, isFilterEnabled }: any) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const rowItemClass = 'flex w-full py-2 pl-2 pr-6 relative cursor-pointer hover:bg-grey-50';
  const handleShomoreActiveStatusClasses = isComponentVisible
    ? 'border-grey-100 bg-grey-50'
    : 'bg-white';

  return (
    <div className="relative" ref={ref}>
      <Button
        buttonLabel={isFilterEnabled ? '' : 'Show'}
        iconAlignment="left"
        iconClass={isFilterEnabled ? 'mr-0' : 'mr-1'}
        iconType="EyeIcon"
        isIconEnabled
        variants="extraSmallOutlined"
        additionalClasses={`text-12s text-grey-900 ${handleShomoreActiveStatusClasses} !border-grey-100`}
        onClick={() => setIsComponentVisible(!isComponentVisible)}
      />

      {isComponentVisible && (
        <div className="absolute right-0 z-50 flex flex-col px-2 py-2 bg-white rounded-lg top-full shadow-dropDown min-w-[175px] overflow-y-auto max-h-[358px]">
          <Typhography type="h1Bold" component="p" additionalClasses="mb-2" color="text-grey-900">
            Show Columns
          </Typhography>
          <Typhography
            type="h1"
            component="p"
            additionalClasses="px-2 py-2 bg-white hover:bg-grey-50 cursor-pointer"
            color="text-grey-900"
            onClick={() => handleIconClick('All')}
          >
            <span>Show all</span>
          </Typhography>
          <div className="flex flex-col w-full min-h-full">
            {columns &&
              columns?.map((item: any, index: any) => (
                <div
                  className={rowItemClass}
                  key={`${item?.accessor}-${index}`}
                  onClick={() => handleIconClick(item)}
                >
                  <Typhography type="h1" component="p" color="text-inherit">
                    {item?.Header}
                  </Typhography>
                  <Icon
                    iconType={item?.isHidden ? 'EyeIconClose' : 'EyeIconLarge'}
                    iconColor="inherit"
                    additionalClasses="absolute right-2 top-[8px]"
                  />
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};
