import React from 'react';
import EmptyList from '../../../core/EmptyList/EmptyList';

/**
 * Primary UI component for user interaction
 */
const ListEmpty = () => {
  return (
    <EmptyList
      topContent={`No data found`}
      subContent={'There are no vendors data found at this time.'}
    />
  );
};

export default ListEmpty;
