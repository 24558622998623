/* eslint-disable @typescript-eslint/naming-convention */
import { getTermName, getTermType } from 'helpers/arrayFormating';
import { handleTheStatusText, handleCheckStatus } from 'helpers/modules/helperInvoices';
import { useCurrencyFormat } from 'hooks/useCurrencyFormat';
import { useTitleCase } from 'hooks/useTitleCase';
import moment from 'moment';
import { isPermitted } from 'services/userApi';
import { DataListListProps } from 'shared/models/components/base.model';
import {
  getApprovalsStatusSorting,
  getNameFromAccount,
  getByUserNameSorting,
  getResmanProp,
  getResmanPropARMode,
  getVenderDueSorting,
  getVendorNameSorting,
} from 'shared/utilities/utils';
import { NetXChips } from '../../Chips/NetXChips/NetXChips';
import { StatusTag } from '../../Chips/StatusTag/StatusTag';
import { CellContent } from '../CellElements/CellContent/CellContent';
import { MultiCheckBoxColumnFilter } from '../CellElements/Filters';
import { Approvals } from './ColumnWidths';
import { isTermEditable } from 'shared/configs/config';
import { PayModeSetModal } from 'components/core/Modals/PayModeSetModal/PayModeSetModal';
import { RevererseInvoiceStatsDropDownCell } from '../CellElements/CellContent/RevererseInvoiceStatsDropDownCell';
import { getPaidAtDateForInvoices } from 'helpers/modules/helperVendor';
import { CheckImageUrlModal } from 'components/core/Modals/CheckImageUrlModal/CheckImageUrlModal';
import Tag from 'components/core/Tag/Tag';
import { ColumnNames } from './ColumnNames';

export const handleApprovalsTableColumn = ({
  isLink,
  editNetTerm,
  handleIsModalOpen,
  user,
  subTabType,
  onPayModeModalDisplayModalEvent,
}: DataListListProps) => {
  const ApprovalsTableColumns: any[] = [
    {
      Header: ColumnNames.invoices.invoiced_at.header,
      accessor: ColumnNames.invoices.invoiced_at.accessor,
      wrapperClasses: Approvals.ColomnWidth.invoiced_at,
      headerClasses: Approvals.HeaderCellWidth.invoiced_at,
      // Filter: DateRangeColumnFilter,
      // filter: 'dateBetween',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention,react/destructuring-assignment
        const { invoiced_at, coupa_invoice_date, id } = row.row.original;
        return (
          <CellContent isLink wrapperClasses={Approvals.ColomnWidth.invoiced_at} isWordWrapped>
            {coupa_invoice_date || invoiced_at ? (
              <span
                onClick={row.handleInvoiceLinkClick}
                data-id={id}
                className="underline cursor-pointer"
              >
                {moment(coupa_invoice_date ?? invoiced_at).format('MM/DD/YYYY')}
              </span>
            ) : null}
          </CellContent>
        );
      },
    },
    {
      Header: ColumnNames.invoices.vendor.header,
      wrapperClasses: Approvals.ColomnWidth.vendor,
      headerClasses: Approvals.HeaderCellWidth.vendor,
      accessor: (row: any) => getVendorNameSorting(row.account),
      // filter: 'matches',
      sortType: 'string',
      isReverseSortingIcon: true,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention,react/destructuring-assignment
        const { account } = row.row.original;

        return (
          <CellContent
            isLink={true}
            linkTarget="_self"
            linkHref={`/vendors/account/${account.id}`}
            wrapperClasses={Approvals.ColomnWidth.vendor}
            additionalClasses="capitalize"
            isLinkWrapped
          >
            {useTitleCase(getNameFromAccount(account))}
          </CellContent>
        );
      },
    },
    {
      Header: ColumnNames.invoices.number.header,
      accessor: ColumnNames.invoices.number.accessor,
      wrapperClasses: Approvals.ColomnWidth.number,
      headerClasses: Approvals.HeaderCellWidth.number,
      // filter: 'fuzzy',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        const { id } = row.row.original;
        const { onTriggerTimeLine } = row;

        return (
          <CellContent wrapperClasses={Approvals.ColomnWidth.number}>
            {row.row.values.number ? (
              <Tag
                additionalClasses="min-w-[70px] !rounded-lg items-center cursor-pointer"
                onTagClickEvent={() => onTriggerTimeLine(id, 'invoice')}
                isClickable
              >
                {`ID${row.row.values.number}`}
              </Tag>
            ) : null}
          </CellContent>
        );
      },
    },
    {
      Header: ColumnNames.invoices.coupa_invoice_number.header,
      accessor: ColumnNames.invoices.coupa_invoice_number.accessor,
      wrapperClasses: Approvals.ColomnWidth.invoice_numer,
      headerClasses: Approvals.HeaderCellWidth.invoice_numer,
      // filter: 'fuzzy',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention,react/destructuring-assignment
        const { coupa_invoice_number, id } = row.row.original;
        const setInvoiceNumber: any = coupa_invoice_number;
        const getTextLength = coupa_invoice_number ? setInvoiceNumber.length : 0;
        const maxLengthCount = 18;

        return (
          <CellContent
            isLink
            linkHref={`/api/coupa/download-invoice/${id}`}
            wrapperClasses={`${Approvals.ColomnWidth.invoice_numer} relative !z-[5]`}
            isWordWrapped
            isTooltipEnabled={coupa_invoice_number && getTextLength > maxLengthCount}
            tooltipIcon={'DotsHorizontalMoreIcon'}
            tooltipIconClasses="text-grey-600 top-[2px] relative -left-[5px] cursor-pointer"
            tooltipContainerClass={`left-[12px] -top-[6px] rounded-tl-lg whitespace-normal break-words min-w-[180px]`}
            tooltipContent={coupa_invoice_number ? setInvoiceNumber : null}
          >
            {coupa_invoice_number ? setInvoiceNumber.substring(0, maxLengthCount) : null}
          </CellContent>
        );
      },
    },
    {
      Header: ColumnNames.invoices.batch_number.header,
      accessor: (row: any) => row?.payoutBatchTransfer?.batch_number ?? '',
      wrapperClasses: Approvals.ColomnWidth.batch_number,
      headerClasses: Approvals.HeaderCellWidth.batch_number,
      filter: 'fuzzy',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention,react/destructuring-assignment
        const { payoutBatchTransfer } = row.row.original;
        const { onTriggerTimeLine } = row;

        return (
          <CellContent wrapperClasses={Approvals.ColomnWidth.batch_number}>
            {payoutBatchTransfer && payoutBatchTransfer?.batch_number ? (
              <Tag
                additionalClasses="min-w-[60px] !rounded-lg items-center cursor-pointer"
                onTagClickEvent={() => onTriggerTimeLine(payoutBatchTransfer?.id, 'batch')}
                isClickable
              >
                {payoutBatchTransfer?.batch_number}
              </Tag>
            ) : null}
          </CellContent>
        );
      },
    },
    {
      Header: ColumnNames.invoices.description.header,
      accessor: ColumnNames.invoices.description.accessor,
      wrapperClasses: Approvals.ColomnWidth.description,
      headerClasses: Approvals.HeaderCellWidth.description,
      // filter: 'fuzzy',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention,react/destructuring-assignment
        const { description, id } = row.row.original;
        const getTextLength = description ? description.length : 0;
        const maxLengthCount = 18;

        return (
          <CellContent
            isLink={false}
            linkHref={`/api/coupa/download-invoice/${id}`}
            wrapperClasses={`${Approvals.ColomnWidth.description} relative !z-[5]`}
            isWordWrapped
            isTooltipEnabled={description && getTextLength > maxLengthCount}
            tooltipIcon={'DotsHorizontalMoreIcon'}
            tooltipIconClasses="text-grey-600 top-[2px] relative -left-[5px] cursor-pointer"
            tooltipContainerClass={`left-[12px] -top-[6px] rounded-tl-lg whitespace-normal break-words min-w-[190px]`}
            tooltipContent={description ? description : null}
          >
            {description ? description.substring(0, maxLengthCount) : null}
          </CellContent>
        );
      },
    },
    {
      Header: ColumnNames.invoices.property_name.header,
      accessor: ColumnNames.invoices.property_name.accessor,
      wrapperClasses: Approvals.ColomnWidth.property_name,
      headerClasses: Approvals.HeaderCellWidth.property_name,
      sortType: 'string',
      isReverseSortingIcon: true,
      headerInnerClasses: Approvals.HeaderCellWidth.property_name,
      // Filter: MultiCheckBoxColumnFilter,
      // filter: 'multiSelect',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        const { property } = row.row.original;

        return (
          <CellContent
            isLink
            linkTarget="_self"
            linkHref={`/properties/profile/${property && property.id}`}
            wrapperClasses={`${Approvals.ColomnWidth.property_name} z-30`}
            isTooltipEnabled={property && property.is_delinquent}
            tooltipIconClasses="text-red-500"
            tooltipIcon="DelinquentIcon"
            tooltipContent={`Property is delinquent`}
          >
            {property?.name ? useTitleCase(property?.name) : null}
          </CellContent>
        );
      },
    },
    {
      Header: ColumnNames.invoices.status.header,
      accessor: (row: any) =>
        getApprovalsStatusSorting(
          row.status,
          row.payout_scheduled_at,
          row.pay_mode,
          row.check_status
        ),
      Filter: MultiCheckBoxColumnFilter,
      headerClasses: Approvals.HeaderCellWidth.status,
      headerInnerClasses: Approvals.HeaderInnerDivWidth.status,
      filter: 'multiSelect',
      canFilter: false,
      gridType: 'invoices',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        const {
          payout_scheduled_at,
          id,
          description,
          payout_amount,
          pay_mode,
          check_status,
          check_number,
          payoutBatchTransfer,
        } = row.row.original;
        let { status } = row.row.original;
        if (pay_mode === 'CHECK' && check_status !== null && payoutBatchTransfer !== null) {
          status = handleCheckStatus(check_status);
        }
        const getFloatNumber = description.replace(/[^\d.]/g, '');
        const amountPayout = useCurrencyFormat(parseFloat(payout_amount));
        const paidAt = row.row.values.paid_at && moment(row.row.values.paid_at, 'YYYY-MM-DD');
        const presentDate: any = moment().startOf('day');
        const getTheDifference =
          row.row.values.paid_at && status === 'PAID'
            ? moment.duration(presentDate.diff(paidAt)).asDays()
            : 0;
        const isDateDifferenceGreaterThan90Days = Number(getTheDifference) > 90 ? true : false;

        return (
          <CellContent
            wrapperClasses={`flex flex-row w-full !justify-start justify-items-start !items-start ${Approvals.ColomnWidth.status}`}
            isRemoveCellZindex
            isTooltipEnabled={pay_mode === 'CHECK' && check_number}
            tooltipIconClasses="text-grey-600 relative -left-[5px] top-[5px] cursor-pointer"
            tooltipContainerClass={'left-[8px] -top-[2px] rounded-tl-lg !min-w-[0px] !w-[150px]'}
            tooltipContent={'Check number : ' + check_number}
          >
            {status !== 'PAID' && status !== 'paid' ? (
              <StatusTag status={handleTheStatusText(status, payout_scheduled_at)} />
            ) : !isDateDifferenceGreaterThan90Days &&
              isPermitted(user, 'edit-invoice-status') &&
              pay_mode !== 'CHECK' ? (
              <RevererseInvoiceStatsDropDownCell
                status={status}
                id={id}
                invoiceNumber={getFloatNumber}
                amount={amountPayout}
              />
            ) : (
              <StatusTag status={handleTheStatusText(status, payout_scheduled_at)} />
            )}
          </CellContent>
        );
      },
    },
    {
      Header: ColumnNames.invoices.estimated_approved_at.header,
      accessor: ColumnNames.invoices.estimated_approved_at.accessor,
      wrapperClasses: Approvals.ColomnWidth.estimated_approved_at,
      headerClasses: Approvals.HeaderCellWidth.estimated_approved_at,
      // Filter: DateRangeColumnFilter,
      // filter: 'dateBetween',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        // eslint-disable-next-line react/destructuring-assignment
        const dueDate = moment(row.row.values.estimated_approved_at).format('MM/DD/YYYY');
        // eslint-disable-next-line react/destructuring-assignment
        // check 'estimated_approved_at' is lessthan current date or not
        // if less than current date, make tooltip enabled
        const handleIsDue = () => {
          let setDue = false;

          if (moment(row.row.values.estimated_approved_at).format() < moment().format()) {
            setDue = true;
          }

          if (row.row.values.approved_at) {
            if (
              moment(row.row.values.estimated_approved_at).format() >
              moment(row.row.values.approved_at).format()
            ) {
              setDue = false;
            }

            if (
              moment(row.row.values.estimated_approved_at).format() <
              moment(row.row.values.approved_at).format()
            ) {
              setDue = true;
            }
          }

          return setDue;
        };

        // if 'approved_at' is greather than 'estimated_approved_at
        const isinvoicedDue =
          row.row.values.approved_at &&
          moment(row.row.values.estimated_approved_at).format() <
            moment(row.row.values.approved_at).format();

        return (
          <CellContent
            wrapperClasses={Approvals.ColomnWidth.estimated_approved_at}
            isTooltipEnabled={handleIsDue()}
            tooltipIconClasses={isinvoicedDue ? 'text-grey-400' : 'text-red-500'}
            tooltipContent={
              isinvoicedDue
                ? 'Invoice was approved after due date'
                : 'The approval date is past due'
            }
          >
            {dueDate}
          </CellContent>
        );
      },
    },
    {
      Header: ColumnNames.invoices.approved_at.header,
      accessor: ColumnNames.invoices.approved_at.accessor,
      wrapperClasses: Approvals.ColomnWidth.approved_at,
      headerClasses: Approvals.HeaderCellWidth.approved_at,
      // Filter: DateRangeColumnFilter,
      // filter: 'dateBetween',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        return (
          <CellContent wrapperClasses={Approvals.ColomnWidth.approved_at}>
            {row.row.original.approved_at
              ? moment(row.row.original.approved_at).format('MM/DD/YYYY')
              : null}
          </CellContent>
        );
      },
    },
    {
      Header: ColumnNames.invoices.payout_term_id.header,
      accessor: ColumnNames.invoices.payout_term_id.accessor,
      headerClasses: Approvals.HeaderCellWidth.payout_term_id,
      // Filter: MultiCheckBoxColumnFilter,
      // filter: 'multiSelect',

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        // eslint-disable-next-line react/destructuring-assignment,@typescript-eslint/naming-convention
        const { id, amount, description, payout_term_id, status } = row.row.original;

        const handleNetXEdit = () => {
          let isEditable = false;

          if (
            (status === 'INVOICED' || status === 'APPROVED') &&
            editNetTerm &&
            isPermitted(user, 'edit-payout')
          ) {
            isEditable = true;
          }

          if (status === 'PAID') {
            isEditable = false;
          }

          return isEditable;
        };

        return (
          <CellContent wrapperClasses={Approvals.ColomnWidth.payout_term_id}>
            {payout_term_id ? (
              <NetXChips
                value={getTermName(payout_term_id)}
                isPercentageAvailable={getTermType(payout_term_id)}
                onClick={() => handleIsModalOpen(id, payout_term_id, amount, description)}
                isDisabled={status === 'PAID'}
                isEditable={handleNetXEdit() && isTermEditable}
              />
            ) : null}
          </CellContent>
        );
      },
    },
    {
      Header: ColumnNames.invoices.payout_due_at.header,
      accessor: (row: any) => getVenderDueSorting(row.payout_due_at, row.payout_scheduled_at),
      headerClasses: Approvals.HeaderCellWidth.payout_due_at,
      wrapperClasses: Approvals.ColomnWidth.payout_due_at,
      // Filter: DateRangeColumnFilter,
      // filter: 'dateBetween',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        return (
          <CellContent
            wrapperClasses={Approvals.ColomnWidth.payout_due_at}
            isTooltipEnabled={row.row.original.payout_scheduled_at ? true : false}
            tooltipIconClasses="text-green-400"
            tooltipContent={`Payout Manually Scheduled at ${moment(
              row.row.original.payout_scheduled_at
            ).format('MM/DD/YYYY')}`}
            tooltipIcon="TimerIconSmall"
          >
            {row.row.original.payout_scheduled_at
              ? moment(row.row.original.payout_scheduled_at).format('MM/DD/YYYY')
              : row.row.original.payout_due_at
              ? moment(row.row.original.payout_due_at).format('MM/DD/YYYY')
              : null}
          </CellContent>
        );
      },
    },
    {
      Header: ColumnNames.invoices.paid_at.header,
      accessor: ColumnNames.invoices.paid_at.accessor,
      // Filter: DateRangeColumnFilter,
      // filter: 'dateBetween',
      wrapperClasses: Approvals.ColomnWidth.paid_at,
      headerClasses: Approvals.HeaderCellWidth.paid_at,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { stripe_transfer_id, id } = row.row.original;
        const paidAtDate = getPaidAtDateForInvoices(row.row.original);

        return (
          <CellContent
            wrapperClasses={Approvals.ColomnWidth.paid_at}
            isWordWrapped
            isLink={stripe_transfer_id ? true : false}
            linkHref={`/api/payout/${id}/invoice`}
          >
            {paidAtDate}
          </CellContent>
        );
      },
    },
    {
      Header: ColumnNames.invoices.paid_by.header,
      accessor: (row: any) => getByUserNameSorting(row, false, false),
      // filter: 'matches',
      sortType: 'string',
      wrapperClasses: Approvals.ColomnWidth.paid_by,
      headerClasses: Approvals.HeaderCellWidth.paid_by,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const setPaifByUserName = getByUserNameSorting(row.row.original, false, false);

        return (
          <CellContent wrapperClasses={Approvals.ColomnWidth.paid_by} isWordWrapped>
            {setPaifByUserName !== '' ? setPaifByUserName : null}
          </CellContent>
        );
      },
    },
    {
      Header: ColumnNames.invoices.customer_payment_due_at.header,
      accessor: ColumnNames.invoices.customer_payment_due_at.accessor,
      wrapperClasses: Approvals.ColomnWidth.customer_payment_due_at,
      headerClasses: Approvals.HeaderCellWidth.customer_payment_due_at,
      // Filter: DateRangeColumnFilter,
      // filter: 'dateBetween',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { customer_payment_due_at } = row.row.original;
        return (
          <CellContent wrapperClasses={Approvals.ColomnWidth.customer_payment_due_at}>
            {customer_payment_due_at ? moment(customer_payment_due_at).format('MM/DD/YYYY') : null}
          </CellContent>
        );
      },
    },
    {
      Header: ColumnNames.invoices.check_date.header,
      accessor: (row: any) => getResmanProp(row, 'check_date'),
      wrapperClasses: Approvals.ColomnWidth.check_date,
      headerClasses: Approvals.HeaderCellWidth.check_date,
      // Filter: DateRangeColumnFilter,
      // filter: 'dateBetween',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        const checkDate = getResmanProp(row.row.original, 'check_date');
        return (
          <CellContent wrapperClasses={Approvals.ColomnWidth.check_date}>
            {checkDate ? moment(checkDate).format('MM/DD/YYYY') : null}
          </CellContent>
        );
      },
    },
    {
      Header: ColumnNames.invoices.estimated_arrived_on.header,
      accessor: ColumnNames.invoices.estimated_arrived_on.accessor,
      // Filter: DateRangeColumnFilter,
      headerClasses: Approvals.HeaderCellWidth.estimated_arrived_on,
      // filter: 'dateBetween',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { estimated_arrived_on } = row.row.original;
        return (
          <CellContent wrapperClasses={Approvals.ColomnWidth.estimated_arrived_on}>
            {estimated_arrived_on ? moment(estimated_arrived_on).format('MM/DD/YYYY') : null}
          </CellContent>
        );
      },
    },
    {
      Header: ColumnNames.invoices.customer_paid_at.header,
      accessor: ColumnNames.invoices.customer_paid_at.accessor,
      // Filter: DateRangeColumnFilter,
      headerClasses: Approvals.HeaderCellWidth.customer_paid_at,
      // filter: 'dateBetween',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { customer_paid_at } = row.row.original;
        return (
          <CellContent wrapperClasses={Approvals.ColomnWidth.customer_paid_at}>
            {customer_paid_at ? moment(customer_paid_at).format('MM/DD/YYYY') : null}
          </CellContent>
        );
      },
    },
    {
      Header: ColumnNames.invoices.ar_mode.header,
      // accessor: 'paid_by',
      accessor: (row: any) => getResmanPropARMode(row, 'paid_by'),
      wrapperClasses: Approvals.ColomnWidth.payUp_ar_mode,
      headerClasses: Approvals.HeaderCellWidth.payUp_ar_mode,
      // Filter: MultiCheckBoxColumnFilter,
      // filter: 'multiSelect',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        const paidBy = getResmanPropARMode(row.row.original, 'paid_by');
        return (
          <CellContent wrapperClasses={Approvals.ColomnWidth.payUp_ar_mode}>{paidBy}</CellContent>
        );
      },
    },
    {
      Header: ColumnNames.invoices.pay_ref.header,
      accessor: (row: any) => getResmanProp(row, 'pay_id'),
      // filter: 'matches',
      wrapperClasses: Approvals.ColomnWidth.pay_ref,
      headerClasses: Approvals.HeaderCellWidth.pay_ref,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        const paymentRef = getResmanProp(row.row.original, 'pay_id');
        return (
          <CellContent wrapperClasses={Approvals.ColomnWidth.pay_ref}>
            {paymentRef && paymentRef}
          </CellContent>
        );
      },
    },
    {
      Header: ColumnNames.invoices.pay_mode.header,
      accessor: ColumnNames.invoices.pay_mode.accessor,
      wrapperClasses: Approvals.ColomnWidth.payUp_paid_mode,
      headerClasses: Approvals.HeaderCellWidth.payUp_paid_mode,
      // Filter: MultiCheckBoxColumnFilter,
      // filter: 'multiSelect',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { pay_mode, status, id } = row.row.original;
        const isPaymodeIsEmpty =
          status === 'INVOICED' &&
          (subTabType === 'allAprove' ||
            subTabType === 'innerVendorEarnings' ||
            subTabType === 'innerPropertiesEarnings');
        return (
          <CellContent wrapperClasses={Approvals.ColomnWidth.payUp_paid_mode} isRemoveCellZindex>
            {pay_mode && !isPaymodeIsEmpty ? (
              <PayModeSetModal
                status={status}
                pay_mode={pay_mode}
                invoiceNumber={row?.row?.original?.coupa_invoice_number}
                id={id}
                isUserHaveEditAccess={isPermitted(user, 'edit-pay-mode')}
                onDisplayModalEvent={onPayModeModalDisplayModalEvent}
              />
            ) : null}
          </CellContent>
        );
      },
    },
    {
      Header: ColumnNames.invoices.check_image_url.header,
      wrapperClasses: Approvals.ColomnWidth.check_image_url,
      headerClasses: Approvals.HeaderCellWidth.check_image_url,
      // accessor: 'check_number',
      // filter: 'fuzzy',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention,react/destructuring-assignment
        const { check_image_url, check_number } = row.row.original;

        return (
          <>
            <CheckImageUrlModal imageURL={check_image_url} checkNumer={check_number} user={user} />
          </>
        );
      },
    },
    {
      Header: ColumnNames.invoices.amount.header,
      accessor: ColumnNames.invoices.amount.accessor,
      headerClasses: Approvals.HeaderCellWidth.amount,
      headerInnerClasses: Approvals.HeaderInnerDivWidth.amount,
      canFilter: false,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        // eslint-disable-next-line react/destructuring-assignment,@typescript-eslint/naming-convention
        const { amount } = row.row.original;
        // eslint-disable-next-line react/destructuring-assignment
        const amountMain = useCurrencyFormat(parseFloat(amount));
        // eslint-disable-next-line react/destructuring-assignment
        return (
          <CellContent wrapperClasses={Approvals.ColomnWidth.amount}>
            {amount ? amountMain : null}
          </CellContent>
        );
      },
    },
    {
      Header: ColumnNames.invoices.payout_amount.header,
      accessor: ColumnNames.invoices.payout_amount.accessor,
      headerClasses: Approvals.HeaderCellWidth.payout_amount,
      headerInnerClasses: Approvals.HeaderInnerDivWidth.payout_amount,
      canFilter: false,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        // eslint-disable-next-line react/destructuring-assignment,@typescript-eslint/naming-convention
        const { payout_amount } = row.row.original;
        // eslint-disable-next-line react/destructuring-assignment
        const amountPayout = useCurrencyFormat(parseFloat(payout_amount));
        // eslint-disable-next-line react/destructuring-assignment
        return (
          <CellContent wrapperClasses={Approvals.ColomnWidth.payout_amount}>
            {payout_amount ? amountPayout : null}
          </CellContent>
        );
      },
    },
    {
      Header: ColumnNames.invoices.paid_amount.header,
      accessor: ColumnNames.invoices.paid_amount.accessor,
      headerClasses: Approvals.HeaderCellWidth.payout_amount,
      headerInnerClasses: Approvals.HeaderInnerDivWidth.payout_amount,
      canFilter: false,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        // eslint-disable-next-line react/destructuring-assignment,@typescript-eslint/naming-convention
        const { paid_amount } = row.row.original;
        // eslint-disable-next-line react/destructuring-assignment
        const amountPaid = useCurrencyFormat(parseFloat(paid_amount));
        // eslint-disable-next-line react/destructuring-assignment
        return (
          <CellContent wrapperClasses={Approvals.ColomnWidth.payout_amount}>
            {paid_amount ? amountPaid : null}
          </CellContent>
        );
      },
    },
    {
      Header: ColumnNames.invoices.processing_fee_amount.header,
      accessor: ColumnNames.invoices.processing_fee_amount.accessor,
      headerClasses: Approvals.HeaderCellWidth.processing_fee_amount,
      headerInnerClasses: Approvals.HeaderInnerDivWidth.processing_fee_amount,
      canFilter: false,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        // eslint-disable-next-line react/destructuring-assignment,@typescript-eslint/naming-convention
        const { processing_fee_amount } = row.row.original;
        // eslint-disable-next-line react/destructuring-assignment
        const amountTerm = useCurrencyFormat(parseFloat(processing_fee_amount));
        // eslint-disable-next-line react/destructuring-assignment
        return (
          <CellContent wrapperClasses={Approvals.ColomnWidth.processing_fee_amount}>
            {processing_fee_amount ? amountTerm : null}
          </CellContent>
        );
      },
    },
    {
      Header: ColumnNames.invoices.payout_term_amount.header,
      accessor: ColumnNames.invoices.payout_term_amount.accessor,
      headerClasses: Approvals.HeaderCellWidth.payout_term_amount,
      headerInnerClasses: Approvals.HeaderInnerDivWidth.payout_term_amount,
      canFilter: false,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        // eslint-disable-next-line react/destructuring-assignment,@typescript-eslint/naming-convention
        const { payout_term_amount } = row.row.original;
        // eslint-disable-next-line react/destructuring-assignment
        const amountTerm = useCurrencyFormat(parseFloat(payout_term_amount));
        // eslint-disable-next-line react/destructuring-assignment
        return (
          <CellContent wrapperClasses={Approvals.ColomnWidth.payout_term_amount}>
            {payout_term_amount ? amountTerm : null}
          </CellContent>
        );
      },
    },
    {
      Header: ColumnNames.invoices.coupa_draw_account.header,
      accessor: ColumnNames.invoices.coupa_draw_account.accessor,
      Filter: MultiCheckBoxColumnFilter,
      headerClasses: Approvals.HeaderCellWidth.coupa_draw_account,
      filter: 'multiSelect',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        // eslint-disable-next-line react/destructuring-assignment,@typescript-eslint/naming-convention
        const { coupa_draw_account } = row.row.original;

        return (
          <CellContent wrapperClasses={Approvals.ColomnWidth.coupa_draw_account}>
            {coupa_draw_account}
          </CellContent>
        );
      },
    },
  ];

  return ApprovalsTableColumns;
};
