import { createApi } from '@reduxjs/toolkit/query/react';
import { prepareBaseQuery } from '../shared/configs/config';

export const configApi = createApi({
  reducerPath: 'configApi',
  baseQuery: prepareBaseQuery(),
  endpoints: (builder) => ({
    getConfig: builder.query({
      query: (state) => ({
        url: `/config`,
        method: 'GET',
      }),
    }),
    getLogin: builder.mutation({
      query: () => ({
        url: '/login/isloggedin',
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetConfigQuery, useGetLoginMutation } = configApi;
