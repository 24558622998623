import { InvoiceSummary } from './InvoiceSummary/InvoiceSummary';
import { VendorProfileBlock } from './ProfileBlock/ProfileBlock';
import { ScheduledSummary } from './ScheduledSummary/ScheduledSummary';
// import { VendorSummaryBlock } from './SummaryBlock/SummaryBlock';

export const VendorProfileContainer = ({ data, user }: any) => {
  return (
    <div className="flex justify-between w-full">
      <div className="min-w-[442px]">
        <VendorProfileBlock vendorData={data} user={user} />
      </div>
      <div className="w-full pl-10">
        <div className="max-w-[876px]">
          {/* <VendorSummaryBlock /> */}
          <ScheduledSummary />
          <InvoiceSummary />
        </div>
      </div>
    </div>
  );
};
