import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import { TabNavigationProps } from 'shared/models/components/base.model';
import { TabNavItemProps } from 'shared/models/components/common.model';
import { handleAdditionalClasses } from 'shared/utilities/dataConversion';

export const TabNavigation = ({
  navigationData,
  additionalClasses,
  isCountVisible,
}: TabNavigationProps) => {
  const navItemClasses =
    'text-14s font-bold px-2 h-[30px] inline-flex border-solid border-grey-100 items-center justify-center min-w-[98px] tab-navigation';
  const navItemsWrapperClasses =
    'border border-solid border-grey-100 flex rounded-lg overflow-hidden items-center justify-center h-[30px]';
  const handlenavigationVisibility = navigationData ? 'visible' : 'invisible';

  return (
    <div
      className={classNames([
        handleAdditionalClasses(additionalClasses),
        navItemsWrapperClasses,
        handlenavigationVisibility,
      ])}
    >
      {navigationData &&
        navigationData.map((item: TabNavItemProps, index: any) => {
          const handleLeftBorder = index === 0 ? 'border-l-0' : 'border-l';
          return (
            <NavLink
              key={item.id}
              to={item.urlPath ? item.urlPath : '#'}
              style={({ isActive }) => ({
                color: isActive ? '#FFFFFF' : '#606266',
                background: isActive ? '#0B1848' : '#FFF',
              })}
              className={`${navItemClasses} ${item.navClasses} ${handleLeftBorder}`}
            >
              {item.navTitle}{' '}
              {isCountVisible && (
                <span
                  className={classNames([
                    'inline-flex justify-center px-1 ml-2 h-[18px] text-white rounded w-[21px] font-medium text-11s items-center',
                  ])}
                >
                  {item.count ?? `0`}
                </span>
              )}
            </NavLink>
          );
        })}
    </div>
  );
};
