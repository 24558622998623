import Wrapper from '../../layouts/Wrapper/Wrapper';
import Teams from './Teams/Teams';
import { HeaderTabNavigation, Headers } from 'components/core';
import { handleSettingsTabNavData } from 'constants/TabNavData';

const SettingsTeamsContainer = () => {
  const [settingsTabNavData] = handleSettingsTabNavData();

  return (
    <Wrapper additionalClasses="flex-col items-start" pageTitle="Settings">
      <Headers headingContent="Settings" isSignOutButton headerWrapperClasses="border-none" />
      <HeaderTabNavigation tabNavData={settingsTabNavData} />
      <Teams />
    </Wrapper>
  );
};

export default SettingsTeamsContainer;
