import DatePicker from 'react-datepicker';
import classNames from 'classnames';
import moment from 'moment';
import { Button } from '../Button/Button';

export interface CustomDateFilterProps {
  selected?: any;
  startDate?: any;
  endDate?: any;
  onChange?: any;
  onCloseEvent?: () => void;
  onSubmitEvent?: () => void;
  onClearEvent?: () => void;
  onEndDateResetEvent?: () => void;
  isConfirmButtonDisabled?: boolean;
  changeStartDate?: any;
  changeEndDate?: any;
  startInputDate?: any;
  endInputDate?: any;
  validateDate?: (isStart: boolean) => void;
  errorStart?: string;
  errorEnd?: string;
  isRightAligned?: boolean;
}

export const CustomDateFilter = ({
  selected,
  startDate,
  endDate,
  onChange,
  onCloseEvent,
  onSubmitEvent,
  onClearEvent,
  onEndDateResetEvent,
  isConfirmButtonDisabled,
  changeStartDate,
  changeEndDate,
  startInputDate,
  endInputDate,
  validateDate,
  errorStart,
  errorEnd,
  isRightAligned,
}: CustomDateFilterProps) => {
  const startInputDateDefault = !startInputDate
    ? moment(startDate).format('MM-DD-YYYY')
    : startInputDate;
  const handleEndInputActiveState = endDate || endInputDate ? 'border-grey-100' : 'border-grey-100';
  const startLabelClassNames = `mb-1 font-medium text-left text-grey-600 text-12s`;
  const endLabelClassNames = `mb-1 font-medium text-left text-grey-600 text-12s`;

  return (
    <div
      className={classNames([
        'absolute top-[30px] z-10 flex w-[242px] shadow-dropDown rounded',
        isRightAligned ? 'right-[2px]' : 'left-[2px]',
      ])}
    >
      <div className="z-20 w-[242px] flex flex-col bg-white justify-center rounded-lg">
        <div className="flex items-center justify-between w-full px-2 pt-3">
          <div className="flex flex-col justify-start">
            <label className={startLabelClassNames}>Start Date</label>
            <div>
              <input
                type="text"
                value={startInputDateDefault ? startInputDateDefault.toString() : ''}
                placeholder="Start Date"
                className={classNames([
                  'h-6 border border-solid rounded px-2 font-normal',
                  'text-grey-600 placeholderColor-gray text-12s w-[105px] !focus:outline-none',
                  `${handleEndInputActiveState}`,
                  `${errorEnd && 'border-red-400'}`,
                ])}
                onChange={changeStartDate}
                onBlur={() => validateDate && validateDate(true)}
              />
            </div>
          </div>
          <div className="flex flex-col justify-start">
            <label className={endLabelClassNames}>End Date</label>
            <div className="relative">
              <input
                type="text"
                value={endInputDate ? endInputDate.toString() : ''}
                placeholder="End Date"
                className={classNames([
                  'h-6 border border-solid rounded px-2 font-normal',
                  'text-grey-600 placeholderColor-gray text-12s w-[105px] !focus:outline-none',
                  `${handleEndInputActiveState}`,
                  `${errorEnd && 'border-red-400'}`,
                ])}
                pattern="20[0-9][0-9]-((0[1-9])|(1[0-2]))-(([1-2][0-9])|(3[0-1])|(0[1-9]))"
                onChange={changeEndDate}
                onBlur={() => validateDate && validateDate(false)}
                disabled={!startInputDateDefault || errorStart ? true : false}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col w-full px-2 pt-3 pb-0">
          <div className="block w-full px-1 pt-3 border border-solid rounded-md border-grey-100">
            <DatePicker
              selected={selected}
              onChange={onChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              inline
              calendarClassName="custom-picker-filter"
              showMonthDropdown
              showYearDropdown
            />
          </div>

          <div className="flex items-center justify-end w-full py-3">
            <span
              className={classNames(['text-grey-900', 'mr-8 font-normal cursor-pointer text-12s'])}
              onClick={onClearEvent}
            >
              Clear
            </span>
            <Button
              type={'button'}
              buttonLabel="Apply"
              variants="extraSmall"
              onClick={onSubmitEvent}
              additionalClasses={classNames([
                'w-[94px] !h-8 !text-12s !font-normal',
                // handleButtonClass,
              ])}
              isDisabled={isConfirmButtonDisabled}
            />
          </div>
        </div>
      </div>
      {/* <ReactTooltip
        id="custom-event"
        place="bottom"
        type="dark"
        effect="float"
        delayHide={10000}
        className="font-sans text-xs font-normal leading-4 text-center rounded-md text-slate-50"
      /> */}
    </div>
  );
};
