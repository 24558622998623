import { format, parse } from 'date-fns';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ValueEditor, ValueEditorProps } from 'react-querybuilder';
import { PropertySelector } from './Selectors/PropertySelector';
import { PayModeSelector } from './Selectors/PayModeSelector';
import { CheckStatusSelector, StatusSelector } from './Selectors/StatusSelector';

const dateFormat = 'yyyy-MM-dd';

export const CustomDatePicker = (props: ValueEditorProps) => {
  if (props.fieldData.datatype === 'date') {
    return (
      <div>
        <DatePicker
          dateFormat={dateFormat}
          selected={!props.value ? null : parse(props.value, dateFormat, new Date())}
          onChange={(d: Date) => props.handleOnChange(d ? format(d, dateFormat) : null)}
        />
      </div>
    );
  } else if (props.fieldData.datatype === 'dateRange') {
    const [startDate, endDate] = props.value.split(',');
    return (
      <div className="filter-picker-wrapper">
        <DatePicker
          startDate={!startDate ? null : parse(startDate, dateFormat, new Date())}
          endDate={!endDate ? null : parse(endDate, dateFormat, new Date())}
          onChange={(update: [Date, Date]) => {
            const [s, e] = update;

            props.handleOnChange(
              [!s ? '' : format(s, dateFormat), !e ? '' : format(e, dateFormat)].join(',')
            );
          }}
          selectsRange
          // inline
          calendarClassName="custom-picker-filter filter-picker"
          showMonthDropdown
          showYearDropdown
          dateFormat={dateFormat}
        />
      </div>
    );
  } else if (props.fieldData.datatype === 'multi-select') {
    if (props.fieldData.name === 'property') {
      return <PropertySelector {...props} />;
    } else if (props.fieldData.name === 'pay_mode') {
      return <PayModeSelector {...props} />;
    } else if (props.fieldData.name === 'status') {
      return <StatusSelector {...props} />;
    } else if (props.fieldData.name === 'check_status') {
      return <CheckStatusSelector {...props} />;
    }
  }
  return <ValueEditor {...props} />;
};
