import { DOB } from '../shared/utilities/interface';
import moment from 'moment';
import dtoToFormValues from '../shared/utilities/dto';

export const messageState: any = {
  meesage: '',
  messageType: '',
  isVisible: false,
};

/**
 * make company phone with mask input
 * @param event
 * @param setValue
 * @param getValues
 */
export const onPhoneNumberChangeHandler = (event: any, setValue: any, getValues: any) => {
  let val = event.target.value;
  if (val.length > (getValues('phone_number') as string).length) {
    val = val.replace(/\D/g, '');
    let num = '';
    if (val.length < 3) {
      num = `${val.substring(0, 3)}`;
    } else {
      num = `${val.substring(0, 3)}-${val.substring(3, 6)}-${val.substring(6, 10)}`;
    }

    num = num.trim();
    setValue('phone_number', num, { shouldValidate: true }); // Set value
  } else {
    setValue('phone_number', val, { shouldValidate: true }); // Set value
  }
};

export const onPhoneNumberChangeHandlerNew = (event: any, setValue: any, getValues: any) => {
  let val = event.target.value;
  if (val.length > (getValues('phone') as string).length) {
    val = val.replace(/\D/g, '');
    let num = '';
    if (val.length < 3) {
      num = `${val.substring(0, 3)}`;
    } else {
      num = `${val.substring(0, 3)}-${val.substring(3, 6)}-${val.substring(6, 10)}`;
    }

    num = num.trim();
    setValue('phone', num, { shouldValidate: true }); // Set value
  } else {
    setValue('phone', val, { shouldValidate: true }); // Set value
  }
};

/**
 * make full address for display
 * @param data
 * @return fullAddress
 */
export const getProfileData = (data: any) => {
  const line1 = `${
    data?.individual?.address?.line1 !== null ? data?.individual?.address?.line1 : ''
  }`;
  const line2 = `${
    data?.individual?.address?.line2 !== null ? data?.individual?.address?.line2 : ''
  }`;

  const city = `${data?.individual?.address?.city !== null ? data?.individual?.address?.city : ''}`;

  const state = `${
    data?.individual?.address?.state !== null ? data?.individual?.address?.state : ''
  }`;
  const country = `${
    data?.individual?.address?.country !== null ? data?.individual?.address?.country : ''
  }`;
  const postalCode = `${
    data?.individual?.address?.postal_code !== null ? data?.individual?.address?.postal_code : ''
  }`;
  const vendorAddress = `${line1} ${line2} ${city} ${state} ${country} ${postalCode}`;
  return [vendorAddress];
};

/**
 * get Secondary address for display
 * @param data
 * @return fullAddress
 */
const getSecondaryAddressData = (data: any, isRecipient?: boolean) => {
  let city = `${data?.address?.city !== null ? data?.address?.city : ''}`;
  let state = `${data?.address?.state !== null ? data?.address?.state : ''}`;

  if (isRecipient && data?.stripe_account_metadata) {
    city = `${
      data?.stripe_account_metadata?.check_recipient_details_city !== undefined
        ? data?.stripe_account_metadata?.check_recipient_details_city
        : ''
    }`;
    state = `${
      data?.stripe_account_metadata?.check_recipient_details_state !== undefined
        ? data?.stripe_account_metadata?.check_recipient_details_state
        : ''
    }`;
  }

  return `${city}, ${state}`;
};

/**
 * get primary address for display
 * @param data
 * @return fullAddress
 */
export const getPrimaryAddressData = (data: any, isRecipient?: boolean) => {
  let line1 = `${data?.address?.line1 !== null ? data?.address?.line1 : ''}`;
  let line2 = `${data?.address?.line2 !== null ? data?.address?.line2 : ''}`;
  let postalCode = '';
  let country = `${data?.address?.country !== null ? data?.address?.country : ''}`;

  if (isRecipient && data?.stripe_account_metadata) {
    line1 = `${
      data?.stripe_account_metadata?.check_recipient_details_address !== undefined
        ? data?.stripe_account_metadata?.check_recipient_details_address
        : ''
    }`;
    line2 = `${
      data?.stripe_account_metadata?.check_recipient_details_address_2 !== undefined
        ? data?.stripe_account_metadata?.check_recipient_details_address_2
        : ''
    }`;
    postalCode = `${
      data?.stripe_account_metadata?.check_recipient_details_zip !== undefined
        ? data?.stripe_account_metadata?.check_recipient_details_zip
        : ''
    }`;
    country = 'US';
  }

  const vendorPrimaryAddress = `${line1} ${line2}, ${country}`;
  const secondaryAddress = getSecondaryAddressData(data, isRecipient);
  return [vendorPrimaryAddress, secondaryAddress, postalCode];
};

/**
 * check dob
 * @param dob
 */
export const isNullDOB = (dob: DOB) => {
  return !dob.day || !dob.month || !dob.year;
};

/**
 * terms section handle
 * @param val
 * @param setSelectedNetTerm
 * @param netTermData
 * @param setNewEarning
 */
export const handleSelection = (
  val: any,
  setSelectedNetTerm: any,
  netTermData: any,
  setNewEarning: any
) => {
  setSelectedNetTerm(val.target.value);
  if (netTermData?.data?.terms) {
    const newEarnings = netTermData?.data?.terms?.find((data: any) => {
      return data.id === val.target.value;
    });
    newEarnings && setNewEarning(newEarnings.payout_amount);
  }
};

/**
 * handle change DOB
 * @param date
 * @param setSelectedDate
 * @param setDOBError
 * @param setValue
 */
export const onDOBChanged = (date: any, setSelectedDate: any, setDOBError: any, setValue: any) => {
  setSelectedDate(date);
  const dateWithFormat = moment(date).format('yyyy-MM-DD');
  setDOBError('');
  setValue('dob', dateWithFormat, { shouldValidate: true });
};

/**
 * show term modal
 * @param setEnableTermUpdate
 * @param id
 * @param handleIsModalOpen
 * @param selectedNetTerm
 */
export const showTermModal = (
  setEnableTermUpdate: any,
  id: any,
  handleIsModalOpen: any,
  selectedNetTerm: any
) => {
  setEnableTermUpdate(true);
  handleIsModalOpen(id, selectedNetTerm, '0', '');
};

export const showEditForm = (value: boolean, setEnableReadonlyForm: any) => {
  setEnableReadonlyForm(false);
};

export const selectItem = (val: any, setValue: any, setShowList: any) => {
  setValue('state', val.target.innerText);
  setShowList(false);
};

/**
 * cancel edit
 * @param setEnableReadonlyForm
 * @param editVendor
 * @param setValue
 * @param originalData
 */
export const cancelAction = (
  setEnableReadonlyForm: any,
  editVendor: any,
  setValue: any,
  originalData: any
) => {
  editVendor && editVendor(false);
  dtoToFormValues(setValue, originalData);
};

/**
 * make company phone with mask input
 * @param event
 * @param getValues
 * @param setValue
 */
export const onChangeHandler = (event: any, getValues: any, setValue: any) => {
  let val = event.target.value;
  if (val.length > (getValues('inputCompanyPhone') as string).length) {
    val = val.replace(/\D/g, '');
    let num = '';
    if (val.length < 3) {
      num = `${val.substring(0, 3)}`;
    } else {
      num = `${val.substring(0, 3)}-${val.substring(3, 6)}-${val.substring(6, 10)}`;
    }

    num = num.trim();
    setValue('inputCompanyPhone', num, { shouldValidate: true }); // Set value
  } else {
    setValue('inputCompanyPhone', val, { shouldValidate: true }); // Set value
  }
};
