import { useEffect, useState } from 'react';
import {
  createSearchParams,
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

import {
  EmptyList,
  HeaderTabNavigation,
  SelectBox,
  ToolBar,
  TransactionStatsBar,
} from 'components/core';
import DataList from 'components/core/SuperTable/DataList/DataList';
import LoadingSpinner from 'components/core/LoadingSpinner/LoadingSpinner';
import { CONFIG } from 'shared/configs/config';
import TopPaymentBar from 'components/core/TopPaymentBar/TopPaymentBar';
import { handlePropertiesDetailTabNavData } from 'constants/TabNavData';
import { useGetAllTransactionsQuery } from 'services/payoutsApi';
import { helperTransactions } from 'helpers/modules/helperTransactions';
import { getURLParams, useDateRangeSorting } from 'hooks/useDateRangeSorting';
import moment from 'moment';
import { isPermitted, useGetUserQuery } from 'services/userApi';

const PropertyTransactionsList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const [detailTabNavData] = handlePropertiesDetailTabNavData(id);
  const { data: user } = useGetUserQuery({});

  const [
    rangeSelector,
    defaultSortingText,
    itemSlectedState,
    showDropdown,
    handlerSortingDropDownClick,
    handlerSortingOption,
  ]: any = useDateRangeSorting();

  const pid = '"' + id + '"';

  const filterParams = {
    first: CONFIG.DATA_OVERALL_LIMIT,
    offset: 0,
    filter: {
      created_at: {
        lessThan: moment()
          .add(1, 'day')
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
          .format(),
        greaterThan:
          rangeSelector > 0
            ? moment()
                .subtract(rangeSelector, 'day')
                .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                .format()
            : undefined,
      },
      property: { id: { equalTo: pid } },
      status: { notEqualTo: '"cancelled"' },
    },
  };

  const { data, isSuccess, isLoading, isError } = useGetAllTransactionsQuery(filterParams);

  useEffect(() => {
    navigate(
      {
        pathname: location.pathname,
        search: `?${createSearchParams(getURLParams(defaultSortingText))}`,
      },
      { replace: true }
    );
  }, [defaultSortingText]);

  const totalCount = isSuccess ? data?.payoutBatchTransfers?.totalCount : 0;

  const [getTransferAmount] = helperTransactions();
  const [enableSelect, setEnableSelect] = useState(false);
  // eslint-disable-next-line
  const [selectedRows, setSelectedRows] = useState([]);
  const [tlAmount, setTlAmount]: any = useState();
  const [selectedIds, setSelectedIds]: any = useState();

  const [visibleColumns, setVisibleColumns] = useState([]);
  const [filteredRow, setFilteredRow] = useState([]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    getTransferAmount(selectedRows, setTlAmount, setSelectedIds);
  }, [selectedRows]);

  const handlePaynowOption = () => {
    setEnableSelect(true);
    setSelectedIds(null);
    setSelectedRows([]);
  };

  return (
    <>
      {isLoading && <LoadingSpinner />}
      {isError && <Navigate to={'/401'} />}

      {enableSelect && (
        <TopPaymentBar
          selectedRowsLength={selectedRows?.length}
          setEnableSelect={setEnableSelect}
          totalAmount={tlAmount}
          ids={selectedIds}
          isTransfer
        />
      )}

      <HeaderTabNavigation
        tabNavData={detailTabNavData}
        additionalWrapperClasses={'mb-3'}
        isAdditionalButtonVisibled={isPermitted(user, 'paynow-option')}
        onButtonClick={handlePaynowOption}
        additionalButtonLabel={'Pay Now'}
        isAdditionalButtonDisabled={enableSelect || totalCount === 0}
      />

      <ToolBar
        isShowOptionEnabled={false}
        visibleColumns={visibleColumns}
        filteredRow={filteredRow}
        isDownloadCsvButtonEnabled={isSuccess && totalCount !== 0}
      >
        <SelectBox
          itemClickHandler={handlerSortingOption}
          defaultSelectBoxText={defaultSortingText}
          itemSelected={itemSlectedState}
          dropDownState={showDropdown}
          dropDownClickHandler={handlerSortingDropDownClick}
        />
      </ToolBar>

      {isSuccess &&
        (totalCount !== 0 ? (
          <>
            <TransactionStatsBar data={data?.payoutBatchTransfers} />
            <DataList
              data={data?.payoutBatchTransfers?.nodes}
              tableType="transactions"
              tableWrapperType="grid-height-small"
              isPagination
              subTabType="allTransactions"
              enableSelect={enableSelect}
              setSelectedRows={setSelectedRows}
              setVisibleColumns={setVisibleColumns}
              setFilteredRow={setFilteredRow}
            />
          </>
        ) : (
          <EmptyList
            topContent={`No data found`}
            subContent={'There are no transactions data found at this time.'}
          />
        ))}
    </>
  );
};

export default PropertyTransactionsList;
