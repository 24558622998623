import React, { useState } from 'react';
import Typho from '../../../../core/OldComponents/Typho/Typho';
import { DateRangeOptions } from '../../../../core/FilterSummaryBlock/DateRangeOptions/DateRangeOptions';
import { SummaryDetailBlock } from '../../../../core/FilterSummaryBlock/SummaryDetailBlock/SummaryDetailBlock';
import { useGetFinancialSummaryQuery } from '../../../../../services/analyticsApi';
import { useCurrencyFormat } from '../../../../../hooks/useCurrencyFormat';
import moment from 'moment';

const initialState = {
  status: { in: ['PAID', 'APPROVED', 'INVOICED'] },
};

export const FinancialSummaryBlock = () => {
  const [summary, setSummary]: any = useState(initialState);
  const [range, setSelectedRange]: any = useState('ALL');
  const { data } = useGetFinancialSummaryQuery(summary);
  const handeDateRangeChange = (event: any) => {
    const selectedValue = event.target.value;
    const targetVal = selectedValue.replace('financial-', '');

    setSelectedRange(targetVal);

    if (targetVal === 'ALL') {
      setSummary(initialState);
    } else {
      const convertToInt = parseInt(targetVal);
      const greaterThan = moment()
        .subtract(convertToInt, 'day')
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .format();
      const lessThan = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).format();

      setSummary({
        ...summary,
        created_at: {
          lessThan: `${lessThan}`,
          greaterThan: `${greaterThan}`,
        },
      });
    }
  };

  return (
    <div className="flex flex-col w-full px-6 pt-6 pb-8 mb-6 rounded-lg shadow-analyticsCard">
      <Typho weight="bold" size="18s" additionalClasses="mb-4 mt-0" type="p">
        {'Financial Analysis'}
      </Typho>

      <DateRangeOptions
        optionPrefix={'financial'}
        onChange={handeDateRangeChange}
        selectedOption={range}
      />
      <div className={`grid grid-cols-2 gap-2`}>
        <SummaryDetailBlock
          label={useCurrencyFormat(data?.totalRevenue)}
          subLabel="Estimated Total Revenue"
        />
        <SummaryDetailBlock
          label={data?.estimatedBlendedRate?.toFixed(2)}
          subLabel="Estimated Blended rate"
        />
        <SummaryDetailBlock
          label={data?.annualizedRate?.toFixed(2)}
          subLabel="Estimated Annualized rate"
        />
        <SummaryDetailBlock
          label={useCurrencyFormat(data?.totalRevenueSharing)}
          subLabel="Estimated Total Revenue Sharing"
        />
      </div>
    </div>
  );
};
