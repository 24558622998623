import { createApi } from '@reduxjs/toolkit/query/react';
import { prepareBaseQuery } from '../shared/configs/config';

export const customersApi = createApi({
  reducerPath: 'customersApi',
  tagTypes: ['Customers'],
  baseQuery: prepareBaseQuery(),
  endpoints: (builder) => ({
    getCustomers: builder.query({
      query: (state) => ({
        url: `/query/customers`,
        body: state,
        method: 'POST',
      }),
      providesTags: ['Customers'],
    }),
    updateCustomerPreference: builder.mutation({
      query: (state) => ({
        url: `/customer-preference`,
        body: state,
        method: 'POST',
      }),
      invalidatesTags: ['Customers'],
    }),
    getCustomerPreference: builder.query({
      query: (key) => ({
        url: `/customer-preference?key=${key}`,
        method: 'GET',
      }),
      providesTags: ['Customers'],
    }),
    financialConnectionSession: builder.mutation({
      query: (state) => ({
        url: `/financial-connection/customer-session`,
        body: {},
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useGetCustomersQuery,
  useUpdateCustomerPreferenceMutation,
  useGetCustomerPreferenceQuery,
  useFinancialConnectionSessionMutation,
} = customersApi;
