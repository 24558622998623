/**
 * hook for validate phone number with length
 * @param value: input value
 * @param isSubmit: wether click submit btn
 * @return boolean: is valid number
 */
export const phoneNumberValidation = (value?: any, isSubmit?: boolean) => {
  const pNumber = value?.replace(/[^0-9]/g, '');
  if (isSubmit) {
    if (pNumber === '') return false;
    if (pNumber.length !== 10) return false;
  }
  return true;
};
