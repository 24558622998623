export interface Vendors<T> {
  accounts: {
    nodes: T[];
    totalCount: number | undefined;
  };
}

export interface NestedResponse {
  default_payment_term_id: string;
  id: string;
  first_name: string;
  last_name: string;
  business_name?: string;
  business_type?: string;
  charges_enabled?: boolean;
  details_submitted?: boolean;
  treasury_account_id: string;
  card_id?: string;
  email?: string;
  phone?: string;
  limit?: number;
  offset?: number;
  status?: 'ACTIVE' | 'INACTIVE' | 'INVITED' | string;
  payoutsByAccountId: {
    groupedAggregates: [
      {
        sum: {
          amount: string;
          payout_amount: string;
          payout_term_amount: string;
        };
      }
    ];
  };
}

export interface VendorsListResponse {
  limit?: number;
  offset?: number;
  status?: string;
}

export const InitialState = {
  first: 20,
  offset: 0,
  filter: { status: { equalTo: 'ACTIVE' } },
};
