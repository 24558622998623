import { useState } from 'react';
import { Button, Typhography } from 'components/core';
import Label from 'components/core/FormElements/Label/Label';
import { useForm } from 'react-hook-form';
import { BankDetailsParams } from 'shared/utilities/interface';

export interface IPropertyBankTypeSelection {
  setStepsVisibility?: any;
  setSelectedLabelValue?: any;
}

export const PropertyBankTypeSelection = ({
  setStepsVisibility,
  setSelectedLabelValue,
}: IPropertyBankTypeSelection) => {
  const { handleSubmit, register } = useForm<BankDetailsParams>({
    mode: 'onBlur', //used to check error in realtime
    shouldFocusError: true,
  });
  const [isLabelSet, setLabel] = useState(false);
  const onSubmitNewPaymentMethod = async (data: any) => {
    setStepsVisibility({ step1: false, step2: true, step3: false });
    setSelectedLabelValue(data.label);
  };

  return (
    <>
      <Typhography component="p" type="h2" color="text-grey-900 mb-3 uppercase">
        {`Select Your Bank Type`}
      </Typhography>
      <form
        onSubmit={handleSubmit(onSubmitNewPaymentMethod)}
        className={'w-full flex flex-col max-w-3xl'}
        noValidate
      >
        <div className="flex flex-col w-full min-h-[442px] mb-5 overflow-y-auto pt-3">
          <div className="relative mb-2 overflow-hidden form-check">
            <Label
              name={'field-operating'}
              labelSpanClasses="radio-span justify-center items-center inline-flex"
            >
              <input
                {...register('label')}
                type="radio"
                value="operating"
                id="field-operating"
                onClick={() => {
                  setLabel(true);
                }}
              />
              <span className="mr-3 radio-mask" />
              {'Operating'}
            </Label>
          </div>

          <div className="relative overflow-hidden form-check">
            <Label
              name={'field-capital'}
              labelSpanClasses="radio-span justify-center items-center inline-flex"
            >
              <input
                {...register('label')}
                type="radio"
                value="capital"
                id="field-capital"
                onClick={() => {
                  setLabel(true);
                }}
              />
              <span className="mr-3 radio-mask" />
              {'Capital'}
            </Label>
          </div>
        </div>
        <div className="flex items-center w-full">
          <Button
            type="submit"
            buttonLabel={'Next'}
            iconType="Spin"
            isIconEnabled={false}
            additionalClasses={'w-full'}
            isDisabled={!isLabelSet}
          />
        </div>
      </form>
    </>
  );
};
