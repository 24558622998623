import { useEffect, useState } from 'react';

import { Grid } from '../../Grid/Grid';
import { StatusBlock } from '../../StatusBlock/StatusBlock';

import { useCurrencyFormat } from 'hooks/useCurrencyFormat';
import { BalanceInnerListStatsBarProps, IdataObj } from 'shared/models/components/base.model';

import { useGetPastBalanceHeroStatsQuery } from 'services/pastBalanceApi';

const initialDataObj: IdataObj = {
  gridColumnCount: 3,
  totalInvoices: '0',
  amount: useCurrencyFormat(0),
  dueDate: '',
};

export const PastBalanceInnerListStatsBar = ({
  dateRangeQuery,
  dueDate,
}: BalanceInnerListStatsBarProps) => {
  const [dataObj, setDataObj] = useState(initialDataObj);

  // set filer object to get hero stats
  const heroStatsParams = {
    filter: {
      customer_payment_due_at: dateRangeQuery,
    },
    timeCycleDays: '' + 1 + '',
  };

  const { data, isSuccess }: any = useGetPastBalanceHeroStatsQuery(heroStatsParams);

  useEffect(() => {
    if (isSuccess) {
      setDataObj({
        ...dataObj,
        gridColumnCount: 3,
        totalInvoices: `${data.pending_balance.totalCount}`,
        amount: useCurrencyFormat(data.amount_received.aggregates.sum.amount),
        dueDate: dueDate,
      });
    }
  }, [data]);

  return (
    <>
      <Grid columnCount={dataObj.gridColumnCount} additionalClasses={'w-full mb-3'}>
        <StatusBlock
          firstValue={dataObj.totalInvoices}
          blockHeading={'Invoices'}
          isIconVisible={true}
          iconType="InvoiceIcon"
          iconClasses={'relative top-1'}
          isRemoveBorderPadding
        />

        <StatusBlock
          blockHeading={'Amount'}
          firstValue={dataObj.amount}
          iconType={'DollarCircled'}
          iconColor={'text-green-400'}
          iconClasses={'relative top-[6px] -left-[2px]'}
          isIconVisible={true}
        />

        <StatusBlock
          blockHeading={'Due Date'}
          firstValue={dataObj.dueDate}
          iconType={'TimerIcon'}
          iconClasses={'relative top-1'}
          isIconVisible
        />
      </Grid>
    </>
  );
};
