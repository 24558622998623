import React, { FC, forwardRef } from 'react';
import classNames from 'classnames';

import InputValidation from '../InputValidation/InputValidation';
import Label from '../Label/Label';

import { Classes } from 'shared/configs/componentsClasses';
import { HookFormsInputProps } from 'shared/models/components/base.model';

const handleInputElementClasses = (_inputVariant?: 'default' | 'large') => {
  switch (_inputVariant) {
    case 'default':
      return Classes.input.default;
    case 'large':
      return Classes.input.large;
    default:
      break;
  }
};

/**
 * Primary UI component for user interaction
 */
const Input: FC<HookFormsInputProps> = forwardRef<HTMLInputElement, HookFormsInputProps>(
  (
    {
      labelType = 'black',
      wrapperClass = Classes.input.wrapper,
      type = 'text | date',
      error,
      label,
      isBorderedError = false,
      readOnly = false,
      placeholder,
      onlyLabel = false,
      inputVariant = 'default',
      additionalClasses,
      isAdditionalErrorInput = false,
      additionalErrorClasses,
      labelClasses,
      labelSpanClasses,
      labelName,
      fullError = true,
      ...props
    },
    ref
  ) => {
    const handleReadOnly = readOnly
      ? Classes.input.readonly
      : handleInputElementClasses(inputVariant);
    const handleBorderError = error && isBorderedError && 'border-red-500 bg-lightRed';
    const fullErrorClass = fullError && 'ml-[85px]';
    const inputClasses = [handleReadOnly, handleBorderError, additionalClasses];

    return (
      <div className={wrapperClass}>
        {label && (
          <Label
            name={labelName}
            labelType={labelType}
            label={label}
            additionalClasses={labelClasses}
            labelSpanClasses={labelSpanClasses}
          />
        )}
        <input
          ref={ref}
          aria-invalid={error ? 'true' : 'false'}
          type={type}
          className={classNames(inputClasses)}
          placeholder={placeholder}
          readOnly={readOnly}
          {...props}
        />
        {error && !isBorderedError && !isAdditionalErrorInput && (
          <InputValidation
            additionalClasses={`${Classes.input.error} ${fullErrorClass}`}
            message={error}
          />
        )}
        {isAdditionalErrorInput && error && <div className={additionalErrorClasses}>{error}</div>}
      </div>
    );
  }
);

export default Input;
