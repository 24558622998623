import { useEffect } from 'react';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';

import { Grid } from '../../Grid/Grid';
import SelectBox from '../../SelectBox/SelectBox';
import { StatusBlock } from '../../StatusBlock/StatusBlock';

import { useCurrencyFormat } from 'hooks/useCurrencyFormat';
import { getURLParams, useDateRangeSorting } from 'hooks/useDateRangeSorting';

import { CONFIG } from 'shared/configs/config';
import { nextThirtyDaysDropdownItems } from 'constants/DateRangeDropDownDatas';

import { useGetPayoutsQuery } from 'services/payoutsApi';
import { useGetVendorsQuery } from 'services/vendorsApi';
import { disableVendorPayoutSceduledHeroStats } from 'constants/disableFeatures';

export const VendorsStatsBar = () => {
  // set filter query to get last 30 days vendors count
  const vendorParams = {
    first: CONFIG.DATA_OVERALL_LIMIT,
    offset: 0,
    filter: {
      created_at: {
        lessThan: moment()
          .add(1, 'day')
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
          .format(),
        greaterThan: moment()
          .subtract(30, 'day')
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
          .format(),
      },
    },
  };

  // useGetVendorsQuery RTKquery will used to fetch the vendor data
  // filter query as send as body prameter
  const { data, isLoading, isSuccess }: any = useGetVendorsQuery(vendorParams);
  // get total vendor counts of last 30 days
  const getTotalCount: number = isSuccess && data?.accounts?.totalCount;

  // call useDateRangeSorting hook to return the time range related parameters
  const [
    rangeSelector,
    defaultSortingText,
    itemSlectedState,
    showDropdown,
    handlerSortingDropDownClick,
    handlerSortingOption,
  ]: any = useDateRangeSorting('next 7 days', nextThirtyDaysDropdownItems);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    navigate(
      {
        pathname: location.pathname,
        search: `?${createSearchParams(getURLParams(defaultSortingText))}`,
      },
      { replace: true }
    );
  }, [defaultSortingText]);

  // set filter query to get Sceduled payouts count and amount
  // it's according to the date-range selection
  const payoutsQueryParam = {
    first: CONFIG.DATA_OVERALL_LIMIT,
    offset: 0,
    filter: {
      status: { in: ['APPROVED'] },
      or: [
        {
          payout_due_at: {
            lessThan:
              rangeSelector > 0
                ? moment()
                    .add(rangeSelector + 1, 'day')
                    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                    .format()
                : undefined,
            greaterThan: moment()
              .add(0, 'day')
              .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
              .format(),
          },
        },
        {
          payout_scheduled_at: {
            lessThan:
              rangeSelector > 0
                ? moment()
                    .add(rangeSelector + 1, 'day')
                    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                    .format()
                : undefined,
            greaterThan: moment()
              .add(0, 'day')
              .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
              .format(),
          },
        },
      ],
    },
  };

  // useGetPayoutsQuery RTKquery will used to fetch the vendor data
  const {
    data: vendorData,
    isSuccess: isVendorSuccess,
    isLoading: isVendorLoading,
  } = useGetPayoutsQuery(payoutsQueryParam);
  // set the payouts count
  const getPayoutsCount = isVendorSuccess ? vendorData?.payouts?.totalCount : '';
  // set the payouts amount value
  const getPayoutsAmount = isVendorSuccess ? vendorData?.payouts?.aggregates?.sum?.amount : '';

  return (
    <>
      {(!isLoading || !isVendorLoading) && (
        <Grid columnCount={2} additionalClasses={'w-full mb-2'}>
          <StatusBlock
            firstValue={getTotalCount === 0 ? '0' : getTotalCount}
            thirdValue={'last 30 days'}
            blockHeading={'New Vendors'}
            iconType={'IncreaseMode'}
            iconColor={'text-green-400'}
            isIconVisible={getTotalCount !== 0}
            isRemoveBorderPadding
          />

          {!disableVendorPayoutSceduledHeroStats ? (
            <StatusBlock
              blockHeading={'Payouts Scheduled'}
              firstValue={`${getPayoutsCount} | ${useCurrencyFormat(getPayoutsAmount)}`}
              isIconVisible={false}
            >
              <SelectBox
                items={nextThirtyDaysDropdownItems}
                itemClickHandler={handlerSortingOption}
                defaultSelectBoxText={defaultSortingText}
                itemSelected={itemSlectedState}
                dropDownState={showDropdown}
                dropDownClickHandler={handlerSortingDropDownClick}
                dropDownType="small"
                textSpace={'py-0'}
                wrapperClasses={'!ml-2'}
              />
            </StatusBlock>
          ) : (
            <StatusBlock
              blockHeading={'Payouts Scheduled'}
              firstValue={`---`}
              isIconVisible={false}
            />
          )}
        </Grid>
      )}
    </>
  );
};
