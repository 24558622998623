import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const CONFIG = {
  BASE_URL: process.env.REACT_APP_BASE_URL ?? '/api', // DEV
  API_TOKEN: localStorage.getItem('id_token'),
  BASE_CURRENCY: '$',
  METHOD_GET: 'GET',
  METHOD_POST: 'POST',
  CONTENT_TYPE: 'application/json',
  COUNTRY_PREFIX: '1',
  TABLE_ROW_HEIGHT: 83,
  DATA_OVERALL_LIMIT: 5000,
  FULLSTORY_ORG_ID: 'o-1CZ4ZR-na1',
};

export const getCurrentCustomerId = () => {
  return localStorage.getItem('pay_current_customer_id');
};

export const setCurrentCustomerId = (value: string) => {
  return localStorage.setItem('pay_current_customer_id', value);
};

export const setLSKey = (key: string, value: string) => {
  return localStorage.setItem(`pay_${key}`, value);
};

export const getLSKey = (key: string) => {
  return localStorage.getItem(`pay_${key}`);
};

export const prepareHeaders = (headers: any) => {
  headers.set('authorization', `Bearer ${CONFIG.API_TOKEN}`);
  headers.set('Content-Type', 'application/json');
  if (getCurrentCustomerId()) {
    headers.set('x-customer-id', getCurrentCustomerId());
  }
  return headers;
};

export const prepareHeadersForFileUpload = (headers: any) => {
  headers.set('authorization', `Bearer ${CONFIG.API_TOKEN}`);
  if (getCurrentCustomerId()) {
    headers.set('x-customer-id', getCurrentCustomerId());
  }
  return headers;
};

export const prepareBaseQuery = (isFileUpload = false) => {
  return fetchBaseQuery({
    baseUrl: CONFIG.BASE_URL,
    prepareHeaders: isFileUpload ? prepareHeadersForFileUpload : prepareHeaders,
  });
};

export const isTermEditable = false;
