import React from 'react';
import Icon from '../../core/Media/Icon/Icon';
import Typho from '../../core/OldComponents/Typho/Typho';
import { useComponentVisible } from '../../../hooks/useComponentVisible';
import { useGetCustomersQuery } from '../../../services/customersApi';
import {
  getCurrentCustomerId,
  setCurrentCustomerId,
  setLSKey,
} from '../../../shared/configs/config';
import { useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';

export const OrganizationDropDown = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const customerFIlterParam = {
    limit: 1000,
    offset: 0,
    filter: {
      status: {
        equalTo: 'ACTIVE',
      },
    },
  };

  const { data: customersResponse } = useGetCustomersQuery(customerFIlterParam);
  let currentCustomer: any;
  if (customersResponse?.customers?.nodes) {
    currentCustomer = customersResponse?.customers?.nodes.find(
      (node: any) => getCurrentCustomerId() === node?.id
    );
  }

  const changeCurrentCustomer = (customer: any) => {
    setIsComponentVisible(!isComponentVisible);
    setCurrentCustomerId(customer?.id);
    setLSKey('current_customer_name', customer?.business_name);

    const isVendorsTabs =
      !location.pathname.includes('vendors/active') &&
      !location.pathname.includes('vendors/inactive') &&
      !location.pathname.includes('vendors/all');
    const isPropertiesTabs =
      !location.pathname.includes('/properties/all') &&
      !location.pathname.includes('/properties/delinquent') &&
      !location.pathname.includes('/properties/active');
    const isBalanceInnerScreens =
      location.pathname.includes('/balance/invoice/day') ||
      location.pathname.includes('/balance/invoice/week/');

    if (location.pathname.includes('vendors') && isVendorsTabs) {
      navigate('/vendors/active', { replace: true });
    } else if (location.pathname.includes('properties') && isPropertiesTabs) {
      navigate('/properties/active', { replace: true });
    } else if (isBalanceInnerScreens) {
      navigate('/balance/current', { replace: true });
    } else {
      window.location.reload();
    }
  };

  return (
    <div className="relative z-10 w-full" ref={ref}>
      <div
        className="flex items-center justify-between w-full h-8 px-2 cursor-pointer text-grey-100 bg-primary-75/12"
        onClick={() => setIsComponentVisible(!isComponentVisible)}
      >
        <Typho type="span" color="gray" weight="bold" size="10s" additionalClasses="m-0 capitalize">
          {currentCustomer?.business_name ? currentCustomer?.business_name : 'Select'}
        </Typho>
        <Icon iconType="DropDown" iconColor="gray-220" additionalClasses="ml-2" />
      </div>
      {isComponentVisible && (
        <div className="absolute flex flex-col pb-4 bg-white rounded-md shadow-dropDown top-full w-60 left-1">
          <div className="px-2 py-4 font-bold text-gray-900 border-b border-solid border-grey-100 text-14s">
            Change Organization
          </div>
          {customersResponse?.customers?.nodes.map((node: any) => {
            return (
              <div
                className={classNames([
                  'flex flex-col',
                  currentCustomer?.id === node?.id ? 'pointer-events-none' : 'cursor-pointer',
                ])}
                key={node?.id}
                onClick={() => changeCurrentCustomer(node)}
              >
                <div className="flex w-full cursor-pointer py-[10px] px-3 bg-white justify-between items-center">
                  <Typho type="span" color="black" weight="medium" size="12s" additionalClasses="">
                    {node?.business_name}
                  </Typho>
                  {currentCustomer?.id === node?.id && <Icon iconType="TickIconGray" />}
                </div>
              </div>
            );
          })}
          <div className="flex w-full text-grey-700 pt-[5px] pb-[2px] px-3 items-center cursor-pointer">
            <Icon iconType="AddIcon" />
            <span className="ml-2 font-medium text-inherit text-12s">New Organization</span>
          </div>
        </div>
      )}
    </div>
  );
};
