import { Grid } from '../../Grid/Grid';
import { StatusBlock } from '../../StatusBlock/StatusBlock';
import { helperPropertiesStats } from '../../../../helpers/modules/helperProperties';
import { PropertiesStatsBarProps } from 'shared/models/components/base.model';

export const PropertiesStatsBar = ({ dateRange }: PropertiesStatsBarProps) => {
  const [
    delinquentTrending,
    activeTrending,
    inactiveTrending,
    isTrendlineLoading,
    delinquentData,
    activePropertyData,
    inActivePropertyData,
  ]: any = helperPropertiesStats(dateRange);

  const isTrendlineVisible = dateRange === 'All' || dateRange === '500' || dateRange === 'YTD';
  return (
    <>
      {!isTrendlineLoading && (
        <Grid columnCount={4} additionalClasses={'w-full mb-2'}>
          {/**
           * Active Properties
           */}
          <StatusBlock
            firstValue={
              activePropertyData?.properties?.totalCount === 0
                ? `0`
                : activePropertyData?.properties?.totalCount
            }
            secondValue={
              !isTrendlineVisible
                ? `${activeTrending > 0 ? '+' : ''}${activeTrending?.toString()}`
                : ''
            }
            thirdValue={!isTrendlineVisible ? dateRange : null}
            blockHeading={'Active Property'}
            iconType={activeTrending <= 0 ? 'DecreaseMode' : 'IncreaseMode'}
            iconColor={
              activeTrending <= 0
                ? activeTrending === 0
                  ? 'text-grey-400'
                  : 'text-green-400'
                : 'text-red-400'
            }
            secondValueColor={
              activeTrending <= 0
                ? activeTrending === 0
                  ? 'text-grey-400'
                  : 'text-green-400'
                : 'text-red-400'
            }
            isRemoveBorderPadding
            isIconVisible={!isTrendlineVisible}
          />
          {/**
           * Delinquent Properties
           */}
          <StatusBlock
            firstValue={
              delinquentData?.properties?.totalCount === 0
                ? `0`
                : delinquentData?.properties?.totalCount
            }
            secondValue={
              !isTrendlineVisible
                ? `${delinquentTrending > 0 ? '+' : ''}${delinquentTrending?.toString()}`
                : ''
            }
            thirdValue={!isTrendlineVisible ? dateRange : null}
            blockHeading={'Delinquent Property'}
            iconType={delinquentTrending <= 0 ? 'DecreaseMode' : 'IncreaseMode'}
            iconColor={
              delinquentTrending <= 0
                ? delinquentTrending === 0
                  ? 'text-grey-400'
                  : 'text-green-400'
                : 'text-red-400'
            }
            secondValueColor={
              delinquentTrending <= 0
                ? delinquentTrending === 0
                  ? 'text-grey-400'
                  : 'text-green-400'
                : 'text-red-400'
            }
            isIconVisible={!isTrendlineVisible}
          />
          {/**
           * Inactive Properties
           */}
          <StatusBlock
            firstValue={
              inActivePropertyData?.properties?.totalCount === 0
                ? `0`
                : inActivePropertyData?.properties?.totalCount
            }
            secondValue={
              !isTrendlineVisible
                ? `${inactiveTrending > 0 ? '+' : ''}${inactiveTrending?.toString()}`
                : ''
            }
            thirdValue={!isTrendlineVisible ? dateRange : null}
            blockHeading={'Inactive Property'}
            iconType={inactiveTrending <= 0 ? 'DecreaseMode' : 'IncreaseMode'}
            iconColor={
              inactiveTrending <= 0
                ? inactiveTrending === 0
                  ? 'text-grey-400'
                  : 'text-green-400'
                : 'text-red-400'
            }
            secondValueColor={
              inactiveTrending <= 0
                ? inactiveTrending === 0
                  ? 'text-grey-400'
                  : 'text-green-400'
                : 'text-red-400'
            }
            isIconVisible={!isTrendlineVisible}
          />
          {/**
           * Total Properties
           */}
          <StatusBlock
            firstValue={
              delinquentData?.all_properties?.totalCount === 0
                ? `0`
                : delinquentData?.all_properties?.totalCount
            }
            blockHeading={'Total Properties'}
            iconType={'Properties'}
            iconColor="text-grey-500 relative top-1"
          />
        </Grid>
      )}
    </>
  );
};
