import React from 'react';
import moment from 'moment';

export interface DateRangeOptionsProps {
  choiceType?: 'radio' | 'checkbox';
  optionPrefix?: string;
  onChange?: any;
  selectedOption?: string;
}
const getYTD = () => {
  const start = moment().startOf('year');
  const end = moment(Date.now());
  return parseInt(moment.duration(end.diff(start)).asDays().toString());
};

const rangeData = [
  {
    label: '1D',
    value: '1',
    active: false,
  },
  {
    label: '1W',
    value: '7',
    active: false,
  },
  {
    label: '1M',
    value: '30',
    active: false,
  },
  {
    label: 'YTD',
    value: getYTD(),
    active: false,
  },
  {
    label: 'ALL',
    value: 'ALL',
    active: true,
  },
];

export const DateRangeOptions = ({
  choiceType = 'radio',
  optionPrefix,
  onChange,
  selectedOption,
}: DateRangeOptionsProps) => {
  return (
    <div className="flex flex-row mb-4">
      {rangeData &&
        rangeData.map((option: any, index: any) => {
          return (
            <label
              className={`${index === 0 ? 'ml-0' : 'ml-1'} rangeOptionTag`}
              htmlFor={`${optionPrefix}-${option.value}`}
              key={index}
            >
              <input
                type={choiceType}
                id={`${optionPrefix}-${option.value}`}
                value={`${optionPrefix}-${option.value}`}
                name={optionPrefix ? optionPrefix : option.name}
                defaultChecked={option.value === selectedOption}
                onChange={onChange}
              />
              <span>{option.label}</span>
            </label>
          );
        })}
    </div>
  );
};
