import classNames from 'classnames';
import { getTermName, getTermType } from 'helpers/arrayFormating';
import { useCurrencyFormat } from 'hooks/useCurrencyFormat';
import { usePhoneValidation } from 'hooks/usePhoneValidation';
import { useTitleCase } from 'hooks/useTitleCase';
import moment from 'moment';
import {
  getOverllaEarnongs,
  getNameFromAccount,
  handleLanguage,
  getVendorNameSorting,
  getVendorsStatusSorting,
  getPayoutSceduledSorting,
  getByUserNameSorting,
} from 'shared/utilities/utils';
import { NetXChips } from '../../Chips/NetXChips/NetXChips';
// import { StatusTag } from '../../Chips/StatusTag/StatusTag';
import { CellContent } from '../CellElements/CellContent/CellContent';
import { VendorsStatsDropDownCell } from '../CellElements/CellContent/VendorsStatsDropDownCell';
import { DateRangeColumnFilter, MultiCheckBoxColumnFilter } from '../CellElements/Filters';
import { VendorsColomnWidth } from './ColumnWidths';
import { isPermitted } from 'services/userApi';
import { StatusTag } from 'components/core/Chips/StatusTag/StatusTag';
import {
  handleVendorDefaultPayMode,
  handleVendorDefaultPayModeValues,
} from 'helpers/modules/helperVendor';
import { EditPayModesDropDown } from 'components/core/EditPayModesDropDown/EditPayModesDropDown';

export const handleVendorsTableColumn = ({ subTabType, user }: any) => {
  const vendorsTableColumns: any[] = [
    {
      Header: 'VENDOR',
      accessor: (row: any) => getVendorNameSorting(row),
      filter: 'matches',
      sortType: 'string',
      isReverseSortingIcon: true,
      wrapperClasses: VendorsColomnWidth.ColomnWidth.business_name,
      headerClasses: VendorsColomnWidth.HeaderCellWidth.business_name,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention,react/destructuring-assignment
        const originalArrayObject: any = row.cell.row.original;

        const isVendorNameAvailable =
          originalArrayObject.business_name ||
          originalArrayObject.first_name ||
          originalArrayObject.last_name;

        const setVendorName = useTitleCase(getNameFromAccount(originalArrayObject));
        const getTextLength = isVendorNameAvailable ? setVendorName.length : 0;
        const settooltipContainerwidth = getTextLength > 38 ? 'min-w-[300px]' : 'min-w-[250px]';

        return (
          <CellContent
            isLink
            linkHref={`/vendors/account/${originalArrayObject.id}`}
            wrapperClasses={VendorsColomnWidth.ColomnWidth.business_name}
            additionalClasses={'capitalize'}
            linkTarget="_self"
            isWordWrapped
            isTooltipEnabled={isVendorNameAvailable && getTextLength > 32}
            tooltipIcon={'DotsHorizontalMoreIcon'}
            tooltipIconClasses="text-grey-600 top-[2px] relative -left-[5px] cursor-pointer"
            tooltipContainerClass={classNames([
              'left-[12px] -top-[6px] rounded-tl-lg  h-auto',
              settooltipContainerwidth,
            ])}
            tooltipContent={isVendorNameAvailable ? setVendorName : null}
          >
            {isVendorNameAvailable ? setVendorName.substring(0, 32) : null}
          </CellContent>
        );
      },
    },
    {
      Header: 'STATUS',
      accessor: (row: any) => getVendorsStatusSorting(row.status),
      Filter: MultiCheckBoxColumnFilter,
      filter: subTabType === 'allVendors' ? 'multiSelect' : null,
      wrapperClasses: VendorsColomnWidth.ColomnWidth.status,
      headerClasses: VendorsColomnWidth.HeaderCellWidth.status,
      gridType: 'vendors',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention,react/destructuring-assignment
        const { status, id } = row.cell.row.original;

        return (
          <CellContent wrapperClasses={VendorsColomnWidth.ColomnWidth.status} isRemoveCellZindex>
            {isPermitted(user, 'edit-invoice-status') ? (
              <VendorsStatsDropDownCell status={status} id={id} />
            ) : (
              <StatusTag status={status} />
            )}
          </CellContent>
        );
      },
    },
    {
      Header: 'MODIFIED BY',
      accessor: (row: any) => getByUserNameSorting(row, true, false),
      filter: 'matches',
      sortType: 'string',
      wrapperClasses: VendorsColomnWidth.ColomnWidth.modified_by,
      headerClasses: VendorsColomnWidth.HeaderCellWidth.modified_by,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        const setModifiedBy = getByUserNameSorting(row.row.original, true, false);
        return (
          <CellContent wrapperClasses={VendorsColomnWidth.ColomnWidth.modified_by}>
            {setModifiedBy}
          </CellContent>
        );
      },
    },
    {
      Header: 'CODE',
      accessor: (row: any) => row?.coupaSuppliersByAccountId.nodes[0]?.code,
      filter: 'matches',
      wrapperClasses: VendorsColomnWidth.ColomnWidth.code,
      headerClasses: VendorsColomnWidth.HeaderCellWidth.code,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention,react/destructuring-assignment
        const { coupaSuppliersByAccountId } = row.cell.row.original;

        const setCodeValue =
          coupaSuppliersByAccountId.nodes && coupaSuppliersByAccountId.nodes.length > 0 ? (
            <span className="!normal-case !text-inherit">
              {coupaSuppliersByAccountId.nodes[0]?.code}
            </span>
          ) : null;

        return (
          <CellContent wrapperClasses={VendorsColomnWidth.ColomnWidth.code}>
            {setCodeValue}
          </CellContent>
        );
      },
    },
    {
      Header: 'PAY MODE',
      accessor: (row: any) => handleVendorDefaultPayMode(row.default_pay_mode),
      Filter: MultiCheckBoxColumnFilter,
      filter: 'multiSelect',
      wrapperClasses: VendorsColomnWidth.ColomnWidth.code,
      headerClasses: VendorsColomnWidth.HeaderCellWidth.code,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention,react/destructuring-assignment
        const { default_pay_mode, id } = row.cell.row.original;

        return (
          <CellContent wrapperClasses={VendorsColomnWidth.ColomnWidth.code}>
            {default_pay_mode ? (
              <EditPayModesDropDown
                defaultValue={handleVendorDefaultPayModeValues(default_pay_mode)}
                vendorID={id}
                isPayModeEditable={isPermitted(user, 'edit-pay-mode')}
              />
            ) : (
              ''
            )}
          </CellContent>
        );
      },
    },
    {
      Header: 'DEFAULT NET',
      accessor: 'default_payment_term_id',
      Filter: MultiCheckBoxColumnFilter,
      filter: 'multiSelect',
      wrapperClasses: VendorsColomnWidth.ColomnWidth.default_payment_term_id,
      headerClasses: VendorsColomnWidth.HeaderCellWidth.default_payment_term_id,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention,react/destructuring-assignment
        const { default_payment_term_id } = row.cell.row.original;

        return (
          <CellContent wrapperClasses={VendorsColomnWidth.ColomnWidth.default_payment_term_id}>
            {default_payment_term_id ? (
              <NetXChips
                value={getTermName(default_payment_term_id)}
                isPercentageAvailable={getTermType(default_payment_term_id)}
                isEditable={false}
              />
            ) : null}
          </CellContent>
        );
      },
    },
    {
      Header: 'PHONE',
      accessor: 'phone',
      canFilter: false,
      disableSortBy: true,
      wrapperClasses: VendorsColomnWidth.ColomnWidth.phone,
      headerClasses: VendorsColomnWidth.HeaderCellWidth.phone,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention,react/destructuring-assignment
        const { phone } = row.cell.row.original;

        const getPhoneNumber = String(phone).replace(/\D/g, '');
        const getRestOfTheFirstNumber =
          getPhoneNumber.substring(0, 1) === '1' ? getPhoneNumber.slice(1) : getPhoneNumber;

        return (
          <CellContent wrapperClasses={VendorsColomnWidth.ColomnWidth.phone}>
            {phone ? usePhoneValidation(getRestOfTheFirstNumber) : null}
          </CellContent>
        );
      },
    },
    {
      Header: 'EMAIL',
      accessor: 'email',
      canFilter: false,
      disableSortBy: true,
      wrapperClasses: VendorsColomnWidth.ColomnWidth.email,
      headerClasses: VendorsColomnWidth.HeaderCellWidth.email,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention,react/destructuring-assignment
        const { email } = row.cell.row.original;

        return (
          <CellContent
            wrapperClasses={VendorsColomnWidth.ColomnWidth.email}
            additionalClasses="normal-case"
            isLinkWrapped
          >
            {email}
          </CellContent>
        );
      },
    },
    {
      Header: 'LANG.',
      accessor: 'language',
      canFilter: false,
      wrapperClasses: VendorsColomnWidth.ColomnWidth.language,
      headerClasses: VendorsColomnWidth.HeaderCellWidth.language,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention,react/destructuring-assignment
        const { language } = row.cell.row.original;

        return (
          <CellContent wrapperClasses={VendorsColomnWidth.ColomnWidth.language}>
            {handleLanguage(language)}
          </CellContent>
        );
      },
    },
    {
      Header: 'OVERALL EARNINGS',
      accessor: (row: any) => getOverllaEarnongs(row.payoutsByAccountId.groupedAggregates),
      canFilter: false,
      wrapperClasses: VendorsColomnWidth.ColomnWidth.overall_earning,
      headerClasses: VendorsColomnWidth.HeaderCellWidth.overall_earning,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention,react/destructuring-assignment
        const { payoutsByAccountId } = row.cell.row.original;
        const overallSumArray = payoutsByAccountId.groupedAggregates; // Get overall earning array

        return (
          <CellContent wrapperClasses={VendorsColomnWidth.ColomnWidth.overall_earning}>
            {overallSumArray.length > 0
              ? overallSumArray?.map((item: any) => useCurrencyFormat(item.sum.payout_amount))
              : null}
          </CellContent>
        );
      },
    },
    {
      Header: 'PAYOUTS SCHEDULED',
      accessor: (row: any) =>
        getPayoutSceduledSorting(row.payoutsScheduledByAccountId.groupedAggregates),
      canFilter: false,
      wrapperClasses: VendorsColomnWidth.ColomnWidth.payout_sceduled,
      headerClasses: VendorsColomnWidth.HeaderCellWidth.payout_sceduled,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention,react/destructuring-assignment
        const { payoutsScheduledByAccountId } = row.cell.row.original;
        const overallSumArray = payoutsScheduledByAccountId.groupedAggregates; // Get overall earning array

        return (
          <CellContent wrapperClasses={VendorsColomnWidth.ColomnWidth.payout_sceduled}>
            {overallSumArray.length > 0
              ? overallSumArray?.map((item: any) => useCurrencyFormat(item.sum.amount))
              : null}
          </CellContent>
        );
      },
    },
    {
      Header: 'TOTAL PROCESSING FEE',
      accessor: (row: any) =>
        row?.paidPayoutsByAccountId?.groupedAggregates[0]?.sum?.processing_fee_amount,
      canFilter: false,
      wrapperClasses: VendorsColomnWidth.ColomnWidth.processing_fee_amount,
      headerClasses: VendorsColomnWidth.HeaderCellWidth.processing_fee_amount,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention,react/destructuring-assignment
        const { paidPayoutsByAccountId } = row.cell.row.original;
        const totalProcessingFee =
          paidPayoutsByAccountId?.groupedAggregates[0]?.sum?.processing_fee_amount; // Get total processing fee attay

        return (
          <CellContent wrapperClasses={VendorsColomnWidth.ColomnWidth.processing_fee_amount}>
            {totalProcessingFee ? useCurrencyFormat(totalProcessingFee) : null}
          </CellContent>
        );
      },
    },

    {
      Header: 'DATE CREATED',
      accessor: 'created_at',
      Filter: DateRangeColumnFilter,
      filter: 'dateBetween',
      wrapperClasses: VendorsColomnWidth.ColomnWidth.created_at,
      headerClasses: VendorsColomnWidth.HeaderCellWidth.created_at,
      isRightAligned: true,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention,react/destructuring-assignment
        const { created_at } = row.cell.row.original;

        return (
          <CellContent wrapperClasses={VendorsColomnWidth.ColomnWidth.overall_earning}>
            {created_at ? moment(created_at).format('MM/DD/YYYY') : null}
          </CellContent>
        );
      },
    },
  ];

  return vendorsTableColumns;
};
