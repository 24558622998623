import React from 'react';
import Error401Container from '../../../components/modules/Error/Error401/Error401';

/*
 * Primary UI component for user interaction
 */
const Error401 = () => {
  return <Error401Container />;
};

export default Error401;
