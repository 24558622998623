import { StatusTag } from 'components/core/Chips/StatusTag/StatusTag';
import { useCurrencyFormat } from 'hooks/useCurrencyFormat';
import { useTitleCase } from 'hooks/useTitleCase';
import moment from 'moment-timezone';
import { DataListListProps } from 'shared/models/components/base.model';
import {
  getApprovalsStatusSorting,
  getNameFromAccount,
  getVendorNameSorting,
} from 'shared/utilities/utils';
import { CellContent } from '../CellElements/CellContent/CellContent';
import { DateRangeColumnFilter, MultiCheckBoxColumnFilter } from '../CellElements/Filters';
import { Transactions } from './ColumnWidths';
import { handleCheckStatus } from 'helpers/modules/helperInvoices';
import { CheckImageUrlModal } from 'components/core/Modals/CheckImageUrlModal/CheckImageUrlModal';

export const handleTransactionTableColumn = ({ subTabType, user }: DataListListProps) => {
  const transactionTableColumn: any[] = [
    {
      Header: 'ACCOUNT HOLDER',
      wrapperClasses: Transactions.ColomnWidth.vendor,
      headerClasses: Transactions.HeaderCellWidth.vendor,
      accessor: (row: any) => getVendorNameSorting(row.account),
      filter: 'matches',
      sortType: 'string',
      isReverseSortingIcon: true,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention,react/destructuring-assignment
        const { account } = row.row.original;

        return (
          <CellContent
            isLink={true}
            linkTarget="_self"
            linkHref={`/vendors/account/${account.id}`}
            isLinkWrapped
            wrapperClasses={Transactions.ColomnWidth.vendor}
            additionalClasses="capitalize"
          >
            {useTitleCase(getNameFromAccount(account))}
          </CellContent>
        );
      },
    },
    {
      Header: 'PROPERTY',
      accessor: 'property.name',
      wrapperClasses: Transactions.ColomnWidth.property_name,
      headerClasses: Transactions.HeaderCellWidth.property_name,
      filter: 'fuzzy',
      sortType: 'string',
      isReverseSortingIcon: true,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        const { property } = row.row.original;

        return (
          <CellContent
            isLink
            linkTarget="_self"
            linkHref={`/properties/profile/${property && property.id}`}
            wrapperClasses={Transactions.ColomnWidth.property_name}
            isTooltipEnabled={property && property.is_delinquent}
            tooltipIconClasses="text-red-500"
            tooltipIcon="DelinquentIcon"
            tooltipContent={`Property is delinquent`}
          >
            {property?.name ? useTitleCase(property?.name) : null}
          </CellContent>
        );
      },
    },
    {
      Header: 'STATUS',
      accessor: (row: any) =>
        getApprovalsStatusSorting(
          row.status,
          row.payout_scheduled_at,
          row.pay_mode,
          row.check_status
        ),
      filter: 'multiSelect',
      Filter: MultiCheckBoxColumnFilter,
      wrapperClasses: Transactions.ColomnWidth.status,
      headerClasses: Transactions.HeaderCellWidth.status,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention,react/destructuring-assignment
        const { pay_mode, check_status, check_number } = row.row.original;
        let { status } = row.row.original;
        if (pay_mode === 'CHECK' && check_status !== null) {
          status = handleCheckStatus(check_status);
        }
        // eslint-disable-next-line @typescript-eslint/naming-convention
        return (
          <CellContent
            wrapperClasses={`flex flex-row w-full !justify-start justify-items-start !items-start ${Transactions.ColomnWidth.status}`}
            isWordWrapped
            isTooltipEnabled={pay_mode === 'CHECK' && check_number}
            tooltipIconClasses="text-grey-600 relative -left-[5px] top-[5px] cursor-pointer"
            tooltipContainerClass={'left-[8px] -top-[2px] rounded-tl-lg !min-w-[0px] !w-[150px]'}
            tooltipContent={'Check number : ' + check_number}
          >
            {status ? <StatusTag status={status} /> : null}
          </CellContent>
        );
      },
    },
    {
      Header: 'CREATED ON',
      accessor: 'created_at',
      Filter: DateRangeColumnFilter,
      filter: 'dateBetween',
      wrapperClasses: Transactions.ColomnWidth.created_at,
      headerClasses: Transactions.HeaderCellWidth.created_at,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const createdAt =
          row.row.values.created_at && moment(row.row.values.created_at).format('MM/DD/YYYY');

        return (
          <CellContent wrapperClasses={Transactions.ColomnWidth.created_at} isWordWrapped>
            {createdAt}
          </CellContent>
        );
      },
    },
    {
      Header: 'PAID ON',
      accessor: 'paid_at',
      Filter: DateRangeColumnFilter,
      filter: 'dateBetween',
      wrapperClasses: Transactions.ColomnWidth.paid_at,
      headerClasses: Transactions.HeaderCellWidth.paid_at,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const paidAt = row.row.values.paid_at
          ? moment(row.row.values.paid_at).format('MM/DD/YYYY')
          : null;

        return (
          <CellContent wrapperClasses={Transactions.ColomnWidth.paid_at} isWordWrapped>
            {paidAt}
          </CellContent>
        );
      },
    },
    {
      Header: 'SETTLED ON',
      accessor: 'expected_settlement_date',
      Filter: DateRangeColumnFilter,
      filter: 'dateBetween',
      wrapperClasses: Transactions.ColomnWidth.expected_settlement_date,
      headerClasses: Transactions.HeaderCellWidth.expected_settlement_date,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        let settlementVal = row.row.values.expected_settlement_date;
        // const date = new Date(row.row.values.expected_settlement_date);
        let TimeHours = moment(row.row.values.expected_settlement_date).utc().format('HH');

        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { pay_mode, check_paid_on } = row.row.original;
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const check_paid_at = check_paid_on ? check_paid_on : null;

        if (pay_mode === 'CHECK') {
          if (check_paid_at) {
            settlementVal = check_paid_at;
            TimeHours = moment(check_paid_at).utc().format('HH');
          }
        }

        const handleDate = () => {
          let settlementDate;

          if (settlementVal) {
            settlementDate = moment(settlementVal).format('MM/DD/YYYY');

            if (TimeHours >= '18') {
              // Check if it's after or equal 6:00pm
              settlementDate = moment(settlementVal)
                .add(1, 'day')
                .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                .format('MM/DD/YYYY');
            }
          }

          return settlementDate;
        };

        return (
          <CellContent
            wrapperClasses={Transactions.ColomnWidth.expected_settlement_date}
            isWordWrapped
          >
            {handleDate()}
          </CellContent>
        );
      },
    },
    {
      Header: 'BATCH NUMBER',
      accessor: 'batch_number',
      filter: 'multiSelect',
      wrapperClasses: Transactions.ColomnWidth.batch_number,
      headerClasses: Transactions.HeaderCellWidth.batch_number,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const handleBatchNumber = () => {
          let batchNumber;

          if (row.row.values.batch_number === 0) {
            batchNumber = '0';
          } else if (row.row.values.batch_number !== 0 && row.row.values.batch_number) {
            batchNumber = row.row.values.batch_number;
          }

          return batchNumber;
        };

        return (
          <CellContent wrapperClasses={Transactions.ColomnWidth.batch_number} isWordWrapped>
            {handleBatchNumber()}
          </CellContent>
        );
      },
    },
    {
      Header: 'PAY MODE',
      accessor: 'pay_mode',
      wrapperClasses: Transactions.ColomnWidth.pay_mode,
      headerClasses: Transactions.HeaderCellWidth.pay_mode,
      Filter: MultiCheckBoxColumnFilter,
      filter: 'multiSelect',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention,react/destructuring-assignment
        const { pay_mode } = row.row.original;

        return <CellContent wrapperClasses={'w-100px'}>{pay_mode}</CellContent>;
      },
    },
    {
      Header: 'CHECK NUMBER',
      wrapperClasses: Transactions.ColomnWidth.check_image_url,
      headerClasses: Transactions.HeaderCellWidth.check_image_url,
      accessor: 'check_number',
      filter: 'fuzzy',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention,react/destructuring-assignment
        const { check_image_url, check_number } = row.row.original;

        return (
          <CheckImageUrlModal imageURL={check_image_url} checkNumer={check_number} user={user} />
        );
      },
    },
    {
      Header: 'ESTIMATED PROCESSING FEE',
      accessor: 'estimated_processing_fee_amount',
      headerClasses: Transactions.HeaderCellWidth.estimated_processing_fee_amount,
      headerInnerClasses: Transactions.HeaderInnerDivWidth.estimated_processing_fee_amount,
      canFilter: false,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        // eslint-disable-next-line react/destructuring-assignment,@typescript-eslint/naming-convention
        const { estimated_processing_fee_amount } = row.row.original;
        // eslint-disable-next-line react/destructuring-assignment
        const amountTerm = estimated_processing_fee_amount
          ? useCurrencyFormat(parseFloat(estimated_processing_fee_amount))
          : null;
        // eslint-disable-next-line react/destructuring-assignment
        return (
          <CellContent wrapperClasses={Transactions.ColomnWidth.estimated_processing_fee_amount}>
            {amountTerm}
          </CellContent>
        );
      },
    },
    {
      Header: 'INVOICE AMOUNT',
      accessor: 'amount',
      headerClasses: Transactions.HeaderCellWidth.amount,
      headerInnerClasses: Transactions.HeaderInnerDivWidth.amount,
      canFilter: false,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        // eslint-disable-next-line react/destructuring-assignment,@typescript-eslint/naming-convention
        const { amount } = row.row.original;
        // eslint-disable-next-line react/destructuring-assignment
        const amountMain = amount ? useCurrencyFormat(parseFloat(amount)) : null;
        // eslint-disable-next-line react/destructuring-assignment
        return (
          <CellContent wrapperClasses={Transactions.ColomnWidth.amount}>{amountMain}</CellContent>
        );
      },
    },
    {
      Header: 'PAID AMOUNT',
      accessor: 'paid_amount',
      headerClasses: Transactions.HeaderCellWidth.paid_amount,
      headerInnerClasses: Transactions.HeaderInnerDivWidth.paid_amount,
      canFilter: false,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        // eslint-disable-next-line react/destructuring-assignment,@typescript-eslint/naming-convention
        const { paid_amount } = row.row.original;
        // eslint-disable-next-line react/destructuring-assignment
        const amountPayout = paid_amount ? useCurrencyFormat(parseFloat(paid_amount)) : null;
        // eslint-disable-next-line react/destructuring-assignment
        return (
          <CellContent wrapperClasses={Transactions.ColomnWidth.paid_amount}>
            {amountPayout}
          </CellContent>
        );
      },
    },
    {
      Header: 'PROCESSING FEE',
      accessor: 'processing_fee_amount',
      headerClasses: Transactions.HeaderCellWidth.processing_fee_amount,
      headerInnerClasses: Transactions.HeaderInnerDivWidth.processing_fee_amount,
      canFilter: false,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        // eslint-disable-next-line react/destructuring-assignment,@typescript-eslint/naming-convention
        const { processing_fee_amount } = row.row.original;
        // eslint-disable-next-line react/destructuring-assignment
        const amountTerm = processing_fee_amount
          ? useCurrencyFormat(parseFloat(processing_fee_amount))
          : null;
        // eslint-disable-next-line react/destructuring-assignment
        return (
          <CellContent wrapperClasses={Transactions.ColomnWidth.processing_fee_amount}>
            {amountTerm}
          </CellContent>
        );
      },
    },
  ];

  return transactionTableColumn;
};
