import { useEffect, useState } from 'react';
import { ValueEditorProps } from 'react-querybuilder';
import Select from 'react-select';
import { useGetPropertiesQuery } from 'services/properties';
import { handleListItemsArray } from '../data';

export const PropertySelector = (props: ValueEditorProps) => {
  const { data: propertyData, isSuccess }: any = useGetPropertiesQuery({
    limit: 1000,
    offset: 0,
    orderBy: 'NAME_ASC',
  });

  const [propertyDataObj, setPropertyDataObj] = useState([]);
  const [selectedValue, setSelectedValue]: any = useState([]);

  useEffect(() => {
    const getStorageSelectedPropertyValues = localStorage.getItem('filterPropertyRouteItems') ?? '';
    if (getStorageSelectedPropertyValues !== undefined && getStorageSelectedPropertyValues !== '') {
      const parseStringedObj: any = JSON.parse(getStorageSelectedPropertyValues);
      setSelectedValue(parseStringedObj);
      const [listOfSelectedItemsArray] = handleListItemsArray(parseStringedObj, true);
      props.handleOnChange(listOfSelectedItemsArray);
    }

    if (isSuccess && propertyData) {
      const getPropertyNodesFromData = propertyData?.properties?.nodes;

      const propertyNodes =
        getPropertyNodesFromData.length > 0 &&
        getPropertyNodesFromData.map((property: any) => ({
          value: property.id,
          label: property.name,
        }));

      if (propertyNodes && propertyNodes.length > 0) {
        setPropertyDataObj(propertyNodes);
      }
    }
  }, [isSuccess]);

  return (
    <div className="filter-custom-select">
      <Select
        options={isSuccess && propertyDataObj && propertyDataObj.length > 0 ? propertyDataObj : []}
        isMulti
        className="react-multi-select-container"
        classNamePrefix="react-multi-select"
        value={selectedValue}
        onChange={(e) => {
          const selectedValues: any = e;
          const [listOfSelectedItemsArray] = handleListItemsArray(selectedValues, true);
          props.handleOnChange(listOfSelectedItemsArray);
          setSelectedValue(selectedValues);
          localStorage.setItem('filterPropertyRouteItems', JSON.stringify(selectedValues));
        }}
      />
    </div>
  );
};
