import classNames from 'classnames';
import moment from 'moment';
import React from 'react';

import { CustomDateFilter } from 'components/core/CustomDatePIcker/CustomDatePIcker';
import Icon from 'components/core/Media/Icon/Icon';
import { getFormatDate } from 'helpers/datePickerHelper';
import { useComponentVisible } from 'hooks/useComponentVisible';

export function DateRangeColumnFilter({
  column: {
    filterValue = [],
    preFilteredRows,
    setFilter,
    id,
    Header,
    wrapperClasses,
    isRightAligned,
  },
}: any) {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  // eslint-disable-next-line
  const [min, max]: any = React.useMemo(() => {
    let min = preFilteredRows.length ? new Date(preFilteredRows[0].values[id]) : new Date(0);
    let max = preFilteredRows.length ? new Date(preFilteredRows[0].values[id]) : new Date(0);

    preFilteredRows.forEach((row: any) => {
      const rowDate = new Date(row.values[id]);
      min = rowDate <= min ? rowDate : min;
      max = rowDate >= max ? rowDate : max;
    });

    return [min, max];
  }, [id, preFilteredRows]);

  const [startDate, setStartDate] = React.useState(new Date());
  const [endDate, setEndDate]: any = React.useState(null);
  const [startInputDate, setStartInputDate] = React.useState('');
  const [endInputDate, setEndInputDate]: any = React.useState('');
  const [errorStart, setErrorStart] = React.useState('');
  const [errorEnd, setErrorEnd] = React.useState('');

  const onChange = (dates: any) => {
    // eslint-disable-next-line
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    changeStartDate(moment(start).format('MM-DD-YYYY'), true);
    changeEndDate(moment(end).format('MM-DD-YYYY'), true);
    start && setErrorStart('');
    end && setErrorEnd('');
  };

  const validateDate = (isStart: boolean) => {
    const date = isStart ? startInputDate : endInputDate;
    let isError = false;
    if (!moment(date, 'MM - DD - YYYY', true).isValid()) {
      isError = true;
    }
    if (isError) {
      isStart ? setErrorStart('invalid date') : setErrorEnd('invalid date');
    } else {
      if (isStart) {
        setErrorStart('');
        setStartDate(new Date(moment(date.replaceAll(' ', '')).format('MM-DD-YYYY')));
      } else {
        setErrorEnd('');
        setEndDate(new Date(moment(date.replaceAll(' ', '')).format('MM-DD-YYYY')));
      }
    }
  };

  const changeStartDate = (dateValue: any, isPicker = false) => {
    setStartInputDate(
      getFormatDate(startInputDate, isPicker ? dateValue : dateValue?.target?.value)
    );
  };

  const changeEndDate = (dateValue: any, isPicker = false) => {
    setEndInputDate(getFormatDate(endInputDate, isPicker ? dateValue : dateValue?.target?.value));
  };

  const handlePickerOpen = () => {
    setIsComponentVisible(!isComponentVisible);
  };

  const handlePickerClose = () => {
    setIsComponentVisible(false);
    setStartDate(new Date());
    setEndDate(moment(new Date()).format('MM-DD-YYYY'));
    setEndInputDate(null);
    setStartInputDate('');
    setEndDate(null);
    setFilter((old = []) => ['', old[1]]);
    setFilter((old = []) => [old[0], '']);
  };

  const handleClearFunction = () => {
    setStartDate(new Date());
    setEndDate(null);
    setEndInputDate(null);
    setStartInputDate('');
    setFilter((old = []) => ['', old[1]]);
    setFilter((old = []) => [old[0], '']);
    setIsComponentVisible(false);
  };

  const handleApplyButton = () => {
    setIsComponentVisible(false);
    setFilter((old = []) => [startDate ? startDate : undefined, old[1]]);
    setFilter((old = []) => [old[0], endDate ? endDate : undefined]);
  };

  const handleEndDateReset = () => {
    setEndDate(null);
    setEndInputDate(null);
  };

  const handleActiveState = endDate ? 'text-primary-500 text-10s' : 'text-grey-600 text-12s';
  const inputFieldText = `${moment(startDate).format('MM/DD/YY')}-${moment(endDate).format(
    'MM/DD/YY'
  )}`;

  return (
    <div className="relative" ref={ref}>
      <div
        onClick={handlePickerOpen}
        className={classNames([
          'justify-between items-center flex whitespace-nowrap h-6 border border-solid border-grey-100 rounded-md form-input px-[6px] py-0 min-w-[115px]',
          'cursor-pointer font-medium',
          handleActiveState,
          wrapperClasses,
          isComponentVisible ? 'bg-grey-100' : 'bg-white',
        ])}
      >
        <span className="text-inherit">{`${endDate ? inputFieldText : Header}`}</span>
        <Icon iconType="DatePicker" additionalClasses="ml-1" iconColor="inherit" />
      </div>
      {isComponentVisible && (
        <CustomDateFilter
          onCloseEvent={handlePickerClose}
          onSubmitEvent={handleApplyButton}
          onClearEvent={handleClearFunction}
          onChange={onChange}
          selected={startDate}
          startDate={startDate}
          endDate={endDate}
          isConfirmButtonDisabled={endDate ? false : true}
          onEndDateResetEvent={handleEndDateReset}
          changeEndDate={changeEndDate}
          changeStartDate={changeStartDate}
          startInputDate={startInputDate}
          endInputDate={endInputDate}
          errorStart={errorStart}
          errorEnd={errorEnd}
          validateDate={validateDate}
          isRightAligned={isRightAligned}
        />
      )}
    </div>
  );
}
