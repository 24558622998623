import React, { useEffect, useState } from 'react';
import moment from 'moment';

import {
  Typhography,
  Button,
  BankAccountCard,
  Grid,
  AddAccountModal,
  LoadingSpinner,
  ConfirmationModal,
} from 'components/core';

import {
  useDeleteBankAccountMutation,
  useGetAllBankDetailsMutation,
  useUpdateDefaultBankAccountMutation,
} from '../../../../services/vendorsApi';
import { useParams } from 'react-router-dom';
import EmptyList from 'components/core/EmptyList/EmptyList';
import { getByUserNameSorting } from 'shared/utilities/utils';

const PaymentProfile = ({ isPaymentEditAccess = true }) => {
  const { id: vendorId } = useParams();
  const [accountModalOpen, setAccountModalOpen] = useState(false);
  const [allBankDetails, setAllBankDetails]: any = useState(null);
  const [enableSetupAccount, setEnableSetupAccount] = useState(false);
  const [updateAllList, setUpdateAllList] = useState(false);
  const [methodId, setMethodId] = useState('');
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const [getAllBankDetails, { isLoading: isLoadingAll }] = useGetAllBankDetailsMutation();
  const [deleteBankAccount, { isLoading: isDeleting }] = useDeleteBankAccountMutation();
  const [updateDefaultBankAccount, { isLoading: isMakingAsDefault }] =
    useUpdateDefaultBankAccountMutation();

  useEffect(() => {
    getBankDetails();
  }, [updateAllList]);

  const onClickModalOpen = () => {
    setAccountModalOpen(true);
    setEnableSetupAccount(false);
  };

  const onClickModalClose = () => {
    setAccountModalOpen(false);
    setEnableSetupAccount(false);
  };

  const getBankDetails = async () => {
    const bankData = await getAllBankDetails({ id: vendorId });
    setAllBankDetails(bankData);
  };

  const deleteAccount = (id: any) => {
    setMethodId(id);
    setConfirmationModalOpen(true);
  };

  const deletePaymentSourceCancel = () => {
    setMethodId('');
    setConfirmationModalOpen(false);
  };

  const deleteAccountConfirmation = async () => {
    await deleteBankAccount({ id: methodId, vendor_id: vendorId });
    setUpdateAllList(!updateAllList);
    deletePaymentSourceCancel();
  };

  const updateDefaultAccount = async (id: any) => {
    await updateDefaultBankAccount({ id: id, vendor_id: vendorId });
    setUpdateAllList(!updateAllList);
  };

  return (
    <>
      <div className="flex flex-col w-full">
        {/**
         * Handle loader
         */}
        {(isMakingAsDefault || isDeleting || isLoadingAll) && <LoadingSpinner />}

        <div className="flex items-center justify-between w-full mb-4">
          <Typhography component="p" type="h2" color="text-grey-900">
            {'PAYMENT DETAILS'}
          </Typhography>
          {isPaymentEditAccess && (
            <Button
              variants={'extraSmallOutlined'}
              buttonLabel={'Add Account'}
              iconType="AddIcon"
              isIconEnabled
              additionalClasses="!border-grey-200"
              onClick={onClickModalOpen}
            />
          )}
        </div>
        {allBankDetails && allBankDetails?.data.length > 0 ? (
          <Grid columnCount={3} columnGap={4} additionalClasses={'mb-6'}>
            {allBankDetails?.data?.map((option: any, index: any) => {
              return (
                <BankAccountCard
                  key={index}
                  title={option?.metadata?.nickname ?? option?.bank_name}
                  // lastFourDigits={option?.last4}
                  isDefault={option?.default_for_currency}
                  valueAccountType="Bank account"
                  valueConnectedOn={
                    option?.metadata?.created_at
                      ? moment(option?.metadata?.created_at).format('MM/DD/YYYY')
                      : null
                  }
                  valueAccountHolder={option?.account_holder_name}
                  valueRouting={option?.routing_number}
                  valueCountry={option?.country}
                  removeAccount={() => deleteAccount(option?.id)}
                  setAsDefault={() => updateDefaultAccount(option?.id)}
                  valueLastEdit={
                    option?.metadata?.updated_at
                      ? moment(option?.metadata?.updated_at).format('MM/DD/YYYY')
                      : null
                  }
                  valueModifiedBy={getByUserNameSorting(option?.metadata, true, false)}
                  isPaymentEditAccess={isPaymentEditAccess}
                  valueNickname={option?.metadata?.nickname ?? null}
                  valueBankName={option?.bank_name}
                  valueAccountNumber={option?.metadata?.account_number ?? null}
                />
              );
            })}
          </Grid>
        ) : (
          <EmptyList />
        )}

        {confirmationModalOpen && (
          <ConfirmationModal
            handleSubmitEvent={deleteAccountConfirmation}
            handleBackEvent={deletePaymentSourceCancel}
            mainContent={`Are you sure you want to delete this payment method?`}
            isIconLoad={isDeleting}
          />
        )}

        {accountModalOpen && (
          <AddAccountModal
            isOpen={true}
            onClose={onClickModalClose}
            onBankSetupClick={() => {
              setEnableSetupAccount(true);
            }}
            enableSetupAccount={enableSetupAccount}
            setUpdateAllList={setUpdateAllList}
            updateAllList={updateAllList}
          />
        )}
      </div>
    </>
  );
};

export default PaymentProfile;
