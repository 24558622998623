import { ConfirmationModal } from 'components/core/Modals/ConfirmationModal/ConfirmationModal';
import { StatusDropDown } from 'components/core/StatusDropDown/StatusDropDown';
import { useState } from 'react';
import { useUpdateVendorStatusMutation } from 'services/vendorsApi';
import { vendorsStatusDropDownData } from 'constants/statusDropDownData';
import { PropertyParams } from 'shared/utilities/interface';

export const VendorsStatsDropDownCell = ({ id, status }: PropertyParams) => {
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [selectedVal, setSelectedVal] = useState('');
  const [updateVendors, { isLoading: updateLoading }]: any = useUpdateVendorStatusMutation();

  const handleOnStatusChange = (event: any) => {
    const selectedVal = event.target.getAttribute('data-val');
    setSelectedVal(selectedVal);
    setConfirmModalOpen(true);
  };

  const handleBackEvent = () => {
    setConfirmModalOpen(false);
    setSelectedVal('');
  };

  const handleConfirmButtonClick = async () => {
    await updateVendors({ id: id, status: selectedVal === 'ACTIVE' ? 'active' : 'inactive' });
    setConfirmModalOpen(false);
  };

  return (
    <>
      <StatusDropDown
        defautlOption={selectedVal !== '' ? selectedVal : status}
        dropDownData={vendorsStatusDropDownData}
        onOptionClick={handleOnStatusChange}
        additionalClasses={'!w-[85px] !ml-2'}
        additionalDropDownContainerClasses={'w-[101px]'}
        additionalSpaceSpanClasses={
          status === 'ACTIVE' || selectedVal === 'ACTIVE' ? 'inline-flex w-2' : 'inline-flex w-4'
        }
        iconContainerClasses={
          status === 'ACTIVE' || selectedVal === 'ACTIVE' ? 'w-4 inline-flex pl-1' : 'inline-flex'
        }
      />

      {confirmModalOpen && (
        <ConfirmationModal
          handleBackEvent={handleBackEvent}
          handleSubmitEvent={handleConfirmButtonClick}
          isIconLoad={updateLoading}
          mainContent={'Are you sure you want to change the status?'}
        />
      )}
    </>
  );
};
