import { createApi } from '@reduxjs/toolkit/query/react';
import { prepareBaseQuery } from '../shared/configs/config';

export const invoicesApi = createApi({
  reducerPath: 'invoicesApi',
  baseQuery: prepareBaseQuery(),
  tagTypes: ['Payouts'],
  endpoints: (builder) => ({
    getInvoices: builder.query({
      query: (state) => ({
        url: `/query/invoices`,
        body: state,
        method: 'POST',
      }),
    }),
  }),
});

export const { useGetInvoicesQuery } = invoicesApi;
