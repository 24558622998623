import classNames from 'classnames';
import { StatusTagProps } from 'shared/models/components/base.model';
import { handleAdditionalClasses } from 'shared/utilities/dataConversion';
import { commonClasses, handleTagClasses, handleTagText } from './StatusTag.handler';

export const StatusTag = ({ status, onClick, additionalClasses }: StatusTagProps) => {
  return (
    <div
      className={classNames([
        commonClasses,
        handleTagClasses(status),
        handleAdditionalClasses(additionalClasses),
      ])}
      onClick={onClick}
    >
      {handleTagText(status)}
    </div>
  );
};
