import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';

import { Typhography } from 'components/core';

import { VendorSummaryGraphProps } from 'shared/models/components/base.model';
import { useCurrencyFormatWithoutCents } from 'hooks/useCurrencyFormat';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

export const GraphPayoutScheduled = ({
  getNodes,
  getGreaterThanDate,
  getLessThanDate,
  rangeSelector = 30,
}: VendorSummaryGraphProps) => {
  const [finalChartDataSet, setFinalChartDataSet]: any = useState(null);
  const [chartlabels, setChartLabels]: any = useState(null);
  const allOptionValue = 500;

  const options = {
    elements: {
      point: {
        radius: 1,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        displayColors: true,
        intersect: true,
        backgroundColor: 'rgba(255,255,255,1)',
        titleColor: 'rgba(20,21,24,1)',
        bodyColor: 'rgba(96, 98, 102, 1)',
        borderColor: 'rgba(232, 234, 237, 1)',
        borderWidth: 1,
        boxPadding: 0,
        padding: 8,
        cornerRadius: 4,
        boxWidth: 10,
        boxHeight: 10,
        callbacks: {
          label: (context: any) => {
            let label = ` $0`;

            if (context.parsed.y) {
              label = ` ${useCurrencyFormatWithoutCents(context.parsed.y)}`;
            }
            return label;
          },
        },
      },
    },
    scales: {
      x: {
        stacked: true,
        beginAtZero: true,
        grid: {
          display: true,
          drawBorder: true,
        },
        ticks: {
          display: false,
        },
      },
      y: {
        stacked: true,
        beginAtZero: true,
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          callback: (v: any) => useCurrencyFormatWithoutCents(v),
          color: 'rgba(159, 161, 166, 1)',
          font: {
            size: 12,
          },
        },
      },
    },
  };

  useEffect(() => {
    let graphDataSet: any[] = [];
    let labels: any = [];
    let geNextCountDays: any = [];

    const getNextCountDaysArray = () => {
      const days: any[] = [];
      const dateStart = moment();
      const dateEnd = moment().add(rangeSelector, 'days');

      while (dateEnd.diff(dateStart, 'days') >= 0) {
        days.push(dateStart.format('MMM DD'));
        dateStart.add(1, 'days');
      }

      return days;
    };

    if (rangeSelector !== allOptionValue) {
      geNextCountDays = getNextCountDaysArray();
      geNextCountDays = geNextCountDays.map(String);
      graphDataSet = new Array(rangeSelector).fill('0');
    }

    getNodes.forEach((row?: any) => {
      if (rangeSelector !== allOptionValue) {
        const getDate = moment(row.keys[0]).format('MMM DD');
        const getIndex = geNextCountDays.indexOf(getDate);
        if (getIndex !== -1) {
          graphDataSet[getIndex] = row.sum.amount;
        }
      } else {
        if (getNodes.length === 1) {
          geNextCountDays = [
            ...geNextCountDays,
            moment(row.keys[0]).subtract(2, 'days').format('MMM DD'), // add before 2 day
            moment(row.keys[0]).subtract(1, 'days').format('MMM DD'), // add before 1 day
            moment(row.keys[0]).format('MMM DD'),
            moment(row.keys[0]).add(1, 'days').format('MMM DD'), // add after 1 day
            moment(row.keys[0]).add(2, 'days').format('MMM DD'), // add after 2 day
          ];
          graphDataSet = [...graphDataSet, 0, 0, row.sum.amount, 0, 0];
        } else {
          geNextCountDays = [...geNextCountDays, moment(row.keys[0]).format('MMM DD')];
          graphDataSet = [...graphDataSet, row.sum.amount];
        }
      }
    });

    graphDataSet = graphDataSet.map(Number);
    labels = [...geNextCountDays];

    setChartLabels(labels);

    const finalChartData = {
      labels: labels,
      datasets: [
        {
          fill: true,
          data: graphDataSet,
          backgroundColor: 'rgba(189, 186, 255, 0.5)',
          borderColor: 'rgba(174, 170, 255, 1)',
          borderWidth: 1,
        },
      ],
    };

    setFinalChartDataSet(finalChartData);
  }, [getNodes, getGreaterThanDate, getLessThanDate, setFinalChartDataSet]);

  return (
    <div className="flex flex-col w-full max-w-[900px]">
      <div className="flex w-full">
        {chartlabels && <Line data={finalChartDataSet} options={options} height={'90%'} />}
      </div>
      <div className="flex justify-between pt-4 pl-[50px]">
        <Typhography
          component="span"
          type="h1"
          color="text-grey-400"
          additionalClasses="!font-normal"
        >
          {moment(getGreaterThanDate).format('DD MMM YYYY')}
        </Typhography>
        <Typhography
          component="span"
          type="h1"
          color="text-grey-400"
          additionalClasses="!font-normal"
        >
          {moment(getLessThanDate).format('DD MMM YYYY')}
        </Typhography>
      </div>
    </div>
  );
};
