import React, { useEffect, useState } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Tooltip } from 'chart.js';
import numeral from 'numeral';
import { Bar } from 'react-chartjs-2';
import { useGetAllPaymentTermsQuery } from 'services/analyticsApi';
import { reOrderingTheTerms } from 'helpers/arrayFormating';
import { getTermName } from 'helpers/arrayFormating';

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip);

const options = {
  plugins: {
    title: {
      display: false,
    },
  },
  Legend: {
    display: false,
  },
  responsive: true,
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
        drawBorder: false,
      },
      ticks: {
        color: '#141415',
        font: {
          size: 12,
        },
      },
    },
    y: {
      stacked: true,
      grid: {
        display: false,
        drawBorder: false,
      },
      ticks: {
        callback: (v: any) => numeral(v).format('($0a)'),
        color: '#141415',
        font: {
          size: 12,
        },
      },
    },
  },
};

export interface AnalyticsChartProps {
  response?: any;
  summary?: any;
}

export const AnalyticsChart = ({ response, summary }: AnalyticsChartProps) => {
  const [finalChartDataSet, setFinalChartDataSet]: any = useState(null);
  const { data: chartData, isSuccess } = useGetAllPaymentTermsQuery(null);
  let labels: any = [];
  let labelsListed: any = [];
  const invoiceData: any[] = isSuccess ? chartData?.paymentTerms?.nodes : [];
  const sorted = reOrderingTheTerms(invoiceData);

  sorted.forEach(
    (invoice?: any) =>
      invoice.active &&
      invoice.id !== 'net30' &&
      invoice.id !== 'old_net30' &&
      (labels = [...labels, getTermName(invoice.id)])
  );

  sorted.forEach(
    (invoice?: any, index?: any) =>
      invoice.active &&
      invoice.id !== 'net30' &&
      invoice.id !== 'old_net30' &&
      (labelsListed = [...labelsListed, { name: getTermName(invoice.id), index: index }])
  );

  useEffect(() => {
    const responseNode = response?.invoices?.groupedAggregates;
    const responseLength = responseNode?.length;
    let approvedDataSet: any = [];
    let invoicedDataSet: any = [];
    let paidDataSet: any = [];
    // when we will get only one netTerm from API
    if (responseLength > 0) {
      const termsMap = new Map();
      const invoiceData: any[] = response?.invoices?.groupedAggregates;

      invoiceData.forEach((invoice) =>
        termsMap.set(invoice.keys[1], {
          name: getTermName(invoice.keys[1]),
          invoiced: 0,
          approved: 0,
          paid: 0,
        })
      );

      invoiceData.forEach((invoice) => {
        const lineItem = termsMap.get(invoice.keys[1]);

        if (invoice.keys[0] === 'invoiced') {
          lineItem.invoiced = invoice.sum.amount;
        } else if (invoice.keys[0] === 'approved') {
          lineItem.approved = invoice.sum.amount;
        } else if (invoice.keys[0] === 'paid') {
          lineItem.paid = invoice.sum.amount;
        }

        termsMap.set(invoice.keys[1], lineItem);
      });

      const data: any[] = [];

      termsMap.forEach((value: any, key: string) => data.push(value));

      const bindedObj = labelsListed.map((item: any) => {
        const dataObj = data.find((elem: any) => elem.name === item.name);
        return {
          ...item, // will consist all the items from object2
          // get index from object1 where the code matches
          ...dataObj,
        };
      });

      bindedObj.forEach(
        (invoice?: any) => (approvedDataSet = [...approvedDataSet, invoice.approved])
      );

      bindedObj.forEach(
        (invoice?: any) => (invoicedDataSet = [...invoicedDataSet, invoice.invoiced])
      );

      bindedObj.forEach((invoice?: any) => (paidDataSet = [...paidDataSet, invoice.paid]));

      approvedDataSet = approvedDataSet.map(Number);
      invoicedDataSet = invoicedDataSet.map(Number);
      paidDataSet = paidDataSet.map(Number);

      const finalChartData: any = {
        labels,
        datasets: [
          {
            label: 'Approved',
            data: approvedDataSet,
            backgroundColor: '#A8C7FA',
            hoverBackgroundColor: '#A8C7FA',
            borderWidth: 1,
            hoverBorderWidth: 1,
            borderColor: '#A8C7FA',
            hoverBorderColor: '#fff',
            borderRadius: 4,
            hoverBorderRadius: 4,
            barThickness: 60,
          },
          {
            label: 'Invoiced',
            data: invoicedDataSet,
            backgroundColor: '#0B1848',
            hoverBackgroundColor: '#0B1848',
            borderWidth: 1,
            hoverBorderWidth: 1,
            borderColor: '#0B1848',
            hoverBorderColor: '#fff',
            borderRadius: 4,
            hoverBorderRadius: 4,
            barThickness: 60,
          },
          {
            label: 'Paid',
            data: paidDataSet,
            backgroundColor: '#DDDDDD',
            hoverBackgroundColor: '#DDDDDD',
            borderWidth: 1,
            hoverBorderWidth: 1,
            borderColor: '#DDDDDD',
            hoverBorderColor: '#fff',
            borderRadius: 4,
            hoverBorderRadius: 4,
            barThickness: 60,
          },
        ],
      };

      setFinalChartDataSet(finalChartData);
    }
  }, [response, summary]);

  return (
    finalChartDataSet && (
      <>
        <div className="flex justify-end">
          <div className="inline-flex items-center mx-4">
            <span className="inline-flex w-6 h-6 mr-1 rounded-full bg-black-450" />
            <span className="font-medium text-12s text-black-900">Submitted</span>
          </div>
          <div className="inline-flex items-center mx-4">
            <span className="inline-flex w-6 h-6 mr-1 rounded-full bg-lightBlue100" />
            <span className="font-medium text-12s text-black-900">Approved</span>
          </div>
          <div className="inline-flex items-center mx-4">
            <span className="inline-flex w-6 h-6 mr-1 rounded-full bg-gray-320" />
            <span className="font-medium text-12s text-black-900">Paid</span>
          </div>
        </div>

        <Bar options={options} data={finalChartDataSet} />
      </>
    )
  );
};
