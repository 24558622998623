import React from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import Card from '../../OldComponents/Card/Card';
import { ModalProps } from 'shared/models/components/common.model';

const domNode: any = document.getElementById('app-modal');

const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  children,
  isLeftSpaceEnabled = true,
  isContainerVerticalAligned = true,
  modalAdditionalClasses = 'w-[600px] items-center justify-center min-h-[138px] px-4 py-4 flex-col ',
}) => {
  const defaultClasses = `fixed top-0 bottom-0 right-0 flex ${
    isContainerVerticalAligned ? 'items-center' : ''
  } justify-center duration-300 ease-in-out text-black-900 bg-grey-900/60 z-[60]`;
  const handleLoaderClasses = [isLeftSpaceEnabled ? 'left-[92px]' : 'left-0', defaultClasses];
  const modalContainerDefaultClasses = 'relative z-20';

  if (!isOpen) return null;
  return ReactDOM.createPortal(
    <div className={classNames(handleLoaderClasses)}>
      <div
        className="absolute top-0 bottom-0 left-0 right-0 z-10 bg-transparent"
        onClick={onClose}
      />
      <Card
        additionalClasses={modalContainerDefaultClasses + ' ' + modalAdditionalClasses}
        cardType={'whiteBorder'}
      >
        {children}
      </Card>
    </div>,
    domNode
  );
};

export default Modal;
