import moment from 'moment';

export const getNameFromAccount = (account: any) => {
  return account.business_name ?? account.first_name + ' ' + account.last_name;
};

export const getAccountNameFromAccount = (account?: any) => {
  let accountName;
  if (account) {
    if (account.business_type === 'company' && account.business_name) {
      accountName = account.business_name;
    } else if (account.business_type === 'company' && !account.business_name) {
      accountName = account.first_name;
    } else if (account.business_type === 'individual') {
      accountName = account.first_name + ' ' + account.last_name;
    }
  }
  return accountName;
};

export const getVendorNameSorting = (account?: any) => {
  let accountName = 'z';

  if (account) {
    if (account.business_name) {
      accountName = account.business_name;
    } else if (!account.business_name && account.first_name) {
      accountName = account.first_name;
    } else if (!account.business_name && !account.first_name && account.last_name) {
      accountName = account.last_name;
    }
  }
  return accountName;
};

export const getAccountNameSorting = (account?: any) => {
  let accountName;
  if (account) {
    if (account.business_type === 'company' && account.business_name) {
      accountName = account.business_name;
    } else if (account.business_type === 'company' && !account.business_name) {
      accountName = account.first_name;
    } else if (account.business_type === 'individual') {
      accountName = account.first_name;
    }
  }
  return accountName;
};

export const getPayoutsEnabledSorting = (account?: any) => {
  let status;

  if (account) {
    if (account.payouts_enabled) {
      status = 'Enabled';
    } else {
      status = 'Disabled';
    }
  } else {
    status = 'Inactive';
  }

  return status;
};

export const getByUserNameSorting = (
  data?: any,
  isPropertyOrVendor?: boolean,
  isPayment?: boolean
) => {
  let name = 'Test';

  const getDataNode = isPropertyOrVendor
    ? data?.userByUpdatedBy
    : isPayment && !isPropertyOrVendor
    ? data?.created_by
    : data?.userByPaidBy;

  if (getDataNode && getDataNode?.first_name && getDataNode?.last_name) {
    name = `${getDataNode?.first_name} ${getDataNode?.last_name}`;
  }

  if (getDataNode && getDataNode?.first_name && !getDataNode?.last_name) {
    name = getDataNode?.first_name;
  }

  if (getDataNode && !getDataNode?.first_name && getDataNode?.last_name) {
    name = getDataNode?.last_name;
  }

  if (!getDataNode) {
    name = '';
  }

  const finalValue = name.includes('self') ? 'Vendor' : name;

  return finalValue;
};

export const getPropertyDefautBankAccount = (paymentMethodData?: any, getPropertyID?: string) => {
  let bankAccountLastFour;

  if (paymentMethodData) {
    const getTargetItems = paymentMethodData.find(
      (methodItem: any) => methodItem?.metadata?.property_id === getPropertyID
    );

    bankAccountLastFour =
      getTargetItems && getTargetItems !== undefined
        ? `xxxxx${getTargetItems?.us_bank_account?.last4}`
        : null;
  }

  return bankAccountLastFour;
};

export const getPaymentBatchesDataModalMessages = (row?: any) => {
  let message;

  if (row.msgIfAny) {
    message = row.msgIfAny;
  } else if (row.errorIfAny) {
    message = row.errorIfAny;
  }

  return message;
};

export const getApprovalsStatusSorting = (
  payoutStatus?: string,
  payoutScheduledAt?: string,
  paymode?: string,
  checkStatus?: string
) => {
  let buttonText: any = payoutStatus;

  if (paymode === 'CHECK' || paymode === 'check') {
    if (checkStatus === 'UNPAID') {
      buttonText = 'Unpaid';
    } else if (checkStatus === 'IN_PROCESS') {
      buttonText = 'Check Issued';
    } else if (checkStatus === 'VOID') {
      buttonText = 'Check Voided';
    } else if (checkStatus === 'EXPIRED') {
      buttonText = 'Check Expired';
    } else if (checkStatus === 'PRINTED') {
      buttonText = 'Check Printed';
    } else if (checkStatus === 'MAILED') {
      buttonText = 'Check In Transit';
    } else if (checkStatus === 'FAILED') {
      buttonText = 'Check Failed';
    } else if (checkStatus === 'REFUNDED') {
      buttonText = 'Check Refunded';
    } else if (checkStatus === 'CHECK_PAID') {
      buttonText = 'Check Cashed';
    }
  } else {
    if (payoutStatus === 'APPROVED') {
      buttonText = 'Approved';
      if (payoutScheduledAt) {
        buttonText = 'Scheduled';
      }
    } else if (payoutStatus === 'PAID') {
      buttonText = 'Paid';
    } else if (payoutStatus === 'INVOICED') {
      buttonText = 'Submitted';
    } else if (payoutStatus === 'DENIED') {
      buttonText = 'Void';
    } else if (payoutStatus === 'REVERSED') {
      buttonText = 'Reversed';
    } else if (payoutStatus === 'in_transit') {
      buttonText = 'In Transit';
    } else if (payoutStatus === 'TERMS_ACCEPTED') {
      buttonText = null;
    }
  }

  return buttonText;
};

export const getVenderDueSorting = (payoutDueAt?: string, payoutScheduledAt?: string) => {
  let vendorDue = payoutDueAt;
  if (payoutScheduledAt) {
    vendorDue = payoutScheduledAt;
  }
  return vendorDue;
};

export const getVendorsStatusSorting = (status?: string) => {
  switch (status) {
    case 'ACTIVE':
      return `Active`;
    case 'INACTIVE':
      return `Inactive`;
    default:
      break;
  }
};

export const getOverllaEarnongs = (data: any) => {
  const datareturn = data?.map((item: any) => item.sum.payout_amount);
  return datareturn.shift();
};

export const getPayoutSceduledSorting = (data: any) => {
  const datareturn = data?.map((item: any) => item.sum.amount);
  return datareturn.shift();
};

export const getPropertyPayments = (
  data?: any,
  column?: 'paid_payouts' | 'customer_paid_payouts' | 'unpaid_payouts' | 'amount_in_transition'
) => {
  switch (column) {
    case 'paid_payouts':
      return data.paid_payouts.aggregates.sum.amount;
    case 'unpaid_payouts':
      return data.unpaid_payouts.aggregates.sum.amount;
    case 'customer_paid_payouts':
      return data.customer_paid_payouts.aggregates.sum.amount;
    case 'amount_in_transition':
      return data.amountInTransition.aggregates.sum.total_amount;
    default:
      break;
  }
};

export const handleThePaymentBatchsesStatus = (status?: any) => {
  let statusVal = status;

  if (status === 'PROCESSING' || status === 'Processing') {
    statusVal = 'Pending';
  }

  if (status === 'SUCCEEDED' || status === 'succeeded' || status === 'Succeeded') {
    statusVal = 'Success';
  }

  if (status === 'FAILED' || status === 'failed') {
    statusVal = 'Failed';
  }

  if (status === 'REFUNDEDED' || status === 'refunded' || status === 'Refunded') {
    statusVal = 'Refunded';
  }

  return statusVal;
};

export const handlePaymentBatchesLastFourNumber = (data?: any, batches?: any) => {
  let lastFourNumber;

  if (data?.source && data?.source?.last4) {
    lastFourNumber = data?.source?.last4;
  } else {
    const source =
      batches?.sources?.find((source: any) => source.id === data?.bank_source_id) ??
      batches?.payment_methods.find((source: any) => source.id === data?.bank_source_id);

    lastFourNumber = source?.us_bank_account?.last4 ?? source?.last4;
  }

  return lastFourNumber;
};

export const camelCase = (str: string) => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toUpperCase() : word.toLowerCase();
    })
    .replace(/\s+/g, '');
};

export const getResmanProp = (row: any, prop: string) => {
  const resmanPayableDataNodes = row.resmanAccountsPayableDataByPayoutId?.nodes;
  if (resmanPayableDataNodes?.length > 0) {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    return resmanPayableDataNodes[0][prop];
  }
};

export const getResmanPropDownloadData = (row: any, prop: string) => {
  const resmanPayableDataNodes = row.resmanAccountsPayableDataByPayoutId?.nodes;

  if (resmanPayableDataNodes?.length > 0) {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    if (prop === 'check_date') {
      const checkDate = resmanPayableDataNodes[0][prop];
      return checkDate ? moment(checkDate).format('MM/DD/YYYY') : null;
    } else {
      return resmanPayableDataNodes[0][prop];
    }
  } else {
    return null;
  }
};

export const getResmanPropARMode = (row: any, prop: string) => {
  const resmanPayableDataNodes = row.resmanAccountsPayableDataByPayoutId?.nodes;
  let armodeValue;
  if (resmanPayableDataNodes?.length > 0) {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    armodeValue = resmanPayableDataNodes[0][prop];
    if (resmanPayableDataNodes[0][prop] === 'ACH_PULL') {
      armodeValue = 'Batch';
    }
  }

  return armodeValue;
};

/**
 * @param language to handle the language code
 * @returns language code
 */
export const handleLanguage = (language: string) => {
  let langCode = 'EN';

  if (language === 'English' || language === 'en') {
    langCode = 'EN';
  }

  if (language === 'Spanish' || language === 'es') {
    langCode = 'ES';
  }

  return langCode;
};

export const handletraceID = (transfer?: any, globalTraceNumber?: any) => {
  let traceID;

  if (transfer && transfer.charge_trace_id) {
    const setTraceID = transfer.charge_trace_id;
    if (setTraceID.startsWith('TT')) {
      traceID = setTraceID.slice(-8);
    } else {
      traceID = setTraceID.slice(-7);
    }
  }

  if ((transfer && !transfer.charge_trace_id) || !transfer) {
    if (globalTraceNumber) {
      const setTraceID = globalTraceNumber;
      if (setTraceID.startsWith('TT')) {
        traceID = setTraceID.slice(-8);
      } else {
        traceID = setTraceID.slice(-7);
      }
    }
  }

  return traceID;
};

export const handleShowIconClick = (
  item: any,
  columnList: any,
  setColumnList: any,
  setToggleHiddenColumnChanged: any,
  toggleHiddenColumnChanged: any
) => {
  let newColumnList;
  if (item === 'All') {
    newColumnList = columnList.map((p: any) => {
      return { ...p, isHidden: false };
    });
  } else {
    newColumnList = columnList.map((p: any) =>
      p?.accessor === item.accessor ? { ...p, isHidden: !item.isHidden } : p
    );
  }

  setColumnList(newColumnList);
  setToggleHiddenColumnChanged(!toggleHiddenColumnChanged);
};

/**
 * used to remove space from the string
 * @param dataString : target string value
 * @returns val: string
 */
export const getSpaceRemovedString = (dataString?: string) => {
  const val = dataString ? dataString.replace(/\s/g, '') : null;

  return val;
};

export const isBillingAndRecipentAddressAreTheSame = (
  companyAddress: any,
  recipentAddress: any
) => {
  const isLine1Equal =
    getSpaceRemovedString(companyAddress?.line1) ===
    getSpaceRemovedString(recipentAddress?.check_recipient_details_address);
  const isLine2Equal =
    getSpaceRemovedString(companyAddress?.line2) ===
    getSpaceRemovedString(recipentAddress?.check_recipient_details_address_2);
  const isCityEqual =
    getSpaceRemovedString(companyAddress?.city) ===
    getSpaceRemovedString(recipentAddress?.check_recipient_details_city);
  const isCodeEqual =
    getSpaceRemovedString(companyAddress?.postal_code) ===
    getSpaceRemovedString(recipentAddress?.check_recipient_details_zip);
  const isStateEqual =
    getSpaceRemovedString(companyAddress?.state) ===
    getSpaceRemovedString(recipentAddress?.check_recipient_details_state);

  const isValuesAreNotNull =
    companyAddress?.line1 !== null &&
    recipentAddress?.check_recipient_details_address !== null &&
    companyAddress?.city !== null &&
    recipentAddress?.check_recipient_details_city !== null &&
    companyAddress?.postal_code !== null &&
    recipentAddress?.check_recipient_details_zip !== null &&
    companyAddress?.state !== null &&
    recipentAddress?.check_recipient_details_state !== null;

  const isBillingAndRecipentAddressAreTheSame =
    isLine1Equal &&
    isCityEqual &&
    isCodeEqual &&
    isStateEqual &&
    isValuesAreNotNull &&
    isLine2Equal;

  return isBillingAndRecipentAddressAreTheSame;
};

/**
 * Check recipent details are added or not
 */

export const checkIsAnyOfTheRecipentDetailsAreAddedOrNot = (data: any) => {
  let isAddressAvailable = false;
  let isRecipientNameAvailable = false;

  if (data) {
    const getStripeAccountMetadata = data?.stripe_account_metadata;
    const checkAddressDetails =
      getStripeAccountMetadata?.check_recipient_details_address &&
      getStripeAccountMetadata?.check_recipient_details_city &&
      getStripeAccountMetadata?.check_recipient_details_state &&
      getStripeAccountMetadata?.check_recipient_details_zip;

    if (checkAddressDetails) {
      isAddressAvailable = true;
    }

    if (getStripeAccountMetadata?.check_recipient_details_name) {
      isRecipientNameAvailable = true;
    }
  }

  return [isAddressAvailable, isRecipientNameAvailable];
};
