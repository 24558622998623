import Typho from '../../../core/OldComponents/Typho/Typho';
import {
  isPermitted,
  useGetUserCustomerRolesQuery,
  useGetUserQuery,
} from '../../../../services/userApi';

import { UserList } from './UserList/UserList';
import { InviteUser } from './InviteUser/InviteUser';

const Teams = () => {
  const { data, isSuccess } = useGetUserCustomerRolesQuery({});
  const { data: user } = useGetUserQuery({});
  return (
    <div className="flex flex-col w-full mb-8 max-w-[660px]">
      <Typho size="14s" type="p" color="black" weight="light" additionalClasses="mb-6">
        {`This workspace has ${data?.userCustomerRoles?.nodes?.length} collaborators. 
        Adding a workspace as an admin/view-only will give them write/readonly access to all sections within this workspace.`}
      </Typho>

      {isPermitted(user, 'invite-users') && (
        <>
          <InviteUser />
          <div className="flex flex-col w-full pt-8">
            {isSuccess && (
              <UserList
                data={data}
                isAllowToRemoveUser={isPermitted(user, 'update_users') ? true : false}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Teams;
