import { useState } from 'react';

import { AccordionItem } from 'components/core';
import { DailyPayouts } from '../DailyPayouts/DailyPayouts';

import { DailyPayoutsProps } from 'shared/models/components/base.model';

export const WeeklyPayouts = ({
  weekStartDate,
  weekEndDate,
  subLabel,
  rightColContent,
  isRemoveTopBorder,
  timeFrame,
}: DailyPayoutsProps) => {
  const [openAccordion, setOpenAccordion] = useState(false);

  const handleAccordionClick = () => {
    setOpenAccordion(!openAccordion);
  };

  return (
    <>
      <AccordionItem
        mainLabel="Invoice for"
        subLabel={subLabel}
        rightColContent={rightColContent}
        isInvoiceTag={false}
        isRemoveTopBorder={isRemoveTopBorder}
        isArrowIcon
        isLink={false}
        isActive={openAccordion}
        clickAccordion={handleAccordionClick}
      />
      {openAccordion && (
        <DailyPayouts
          weekEndDate={weekEndDate}
          weekStartDate={weekStartDate}
          timeFrame={timeFrame}
        />
      )}
    </>
  );
};
