import React from 'react';
import classNames from 'classnames';

import { Classes } from '../../../shared/configs/componentsClasses';

import { HeadingProps } from 'shared/models/components/base.model';

const handleColor = (_colorType: string | undefined) => {
  switch (_colorType) {
    case 'white':
      return Classes.heading.white;
    case 'dark':
      return Classes.heading.dark;
    case 'gray':
      return Classes.heading.gray;
    default:
      break;
  }
};

/**
 * Primary UI component for user interaction
 */
const Heading: React.FC<HeadingProps> = ({
  type = 'h4',
  additionalClasses,
  colorType = 'dark',
  label = 'Heading',
  ...rest
}) => {
  const headingClasses = additionalClasses + ' ' + handleColor(colorType);

  return (
    <>
      {type === 'h1' ? (
        <h1 className={classNames([headingClasses, Classes.heading.h1])} {...rest}>
          {label}
        </h1>
      ) : type === 'h2' ? (
        <h2 className={classNames([headingClasses, Classes.heading.h2])} {...rest}>
          {label}
        </h2>
      ) : type === 'h3' ? (
        <h3 className={classNames([headingClasses, Classes.heading.h3])} {...rest}>
          {label}
        </h3>
      ) : type === 'h4' ? (
        <h4 className={classNames([headingClasses, Classes.heading.h4])} {...rest}>
          {label}
        </h4>
      ) : type === 'h5' ? (
        <h5 className={classNames([headingClasses, Classes.heading.h5])} {...rest}>
          {label}
        </h5>
      ) : (
        <h6 className={classNames([headingClasses, Classes.heading.h6])} {...rest}>
          {label}
        </h6>
      )}
    </>
  );
};

export default Heading;
