import classNames from 'classnames';
import Icon from '../../Media/Icon/Icon';
import { Typhography } from '../../Typhography/Typhography';

export interface SettingBalanceCardsProps {
  inputName?: string;
  inputID?: string;
  inputValue?: string;
  cardTitle?: string;
  cardIcon?: string;
  cardContent?: string;
  cardImageSrc?: string;
  onChange?: any;
  selectedValue?: string;
}

export const SettingBalanceCards = ({
  inputName,
  inputID,
  inputValue,
  cardTitle,
  cardIcon,
  cardContent,
  cardImageSrc,
  onChange,
  selectedValue,
}: SettingBalanceCardsProps) => {
  return (
    <div className="flex flex-col w-full">
      <input
        type="radio"
        id={inputID}
        className="w-0 h-0 p-0 m-0 overflow-hidden balance-card-input"
        name={inputName}
        defaultValue={inputValue}
        onChange={onChange}
        checked={selectedValue === inputID}
      />
      <label
        htmlFor={inputID}
        className={classNames([
          'px-6 pt-[18px] pb-6 border border-solid rounded-lg relative',
          selectedValue === inputID
            ? 'bg-grey-50 border-grey-50'
            : 'bg-white cursor-pointer border-grey-100 ',
        ])}
      >
        <div className="flex flex-col">
          <div className="flex items-center w-full text-grey-900 mb-[10px]">
            <Icon iconColor="inherit" iconType={cardIcon} additionalClasses="mr-[10px]" />
            <Typhography type="h2Bold" component="p" color="text-grey-900" additionalClasses="mb-0">
              {cardTitle}
            </Typhography>
          </div>
          <Typhography
            component="p"
            type="h2"
            additionalClasses="mb-4 max-w-[470px] !leading-6 !font-normal"
            color="text-grey-600"
          >
            {cardContent}
          </Typhography>
        </div>
        <div className="flex w-full max-w-[560px] rounded bg-white">
          <img src={cardImageSrc} alt="Calculate by Day" className="w-full h-auto " />
        </div>
      </label>
    </div>
  );
};
