import SettingsPaymentsContainer from 'components/modules/Settings/Payments';

/**
 * Primary UI component for user interaction
 */
const SettingsPayments = () => {
  return <SettingsPaymentsContainer />;
};

export default SettingsPayments;
