import React from 'react';
import { Typhography } from '..';
import { Button } from '../Button/Button';
import { useCurrencyFormat } from '../../../hooks/useCurrencyFormat';
import { PayNowModal } from '../Modals/PayNowModal/PayNowModal';
import { PayTransactionModal } from '../Modals/PayTransactionModal/PayTransactionModal';

interface TopPaymentBarProps {
  selectedRowsLength?: number;
  setEnableSelect?: any;
  totalAmount?: any;
  ids?: any;
  isTransfer?: boolean;
  isFixed?: boolean;
  isFromTransactionModal?: boolean;
  setModalOpen?: any;
  onReset?: any;
  additionalClasses?: string;
}

const TopPaymentBar: React.FC<TopPaymentBarProps> = ({
  selectedRowsLength,
  setEnableSelect,
  totalAmount = 0,
  ids,
  isTransfer,
  isFixed = true,
  isFromTransactionModal,
  setModalOpen,
  onReset,
  additionalClasses = 'top-0 left-0 right-0',
}) => {
  return (
    <div
      className={`${
        isFixed ? 'fixed rounded-b' : 'absolute rounded-t'
      } w-full px-4 py-3 border-t-4  shadow-md bg-primary-75 z-[51] ${additionalClasses}`}
      role="alert"
    >
      <div className="flex ml-60">
        <div className={`flex w-full justify-end items-end ${isTransfer ? 'pr-4' : 'pr-1'}`}>
          <div className={`flex flex-col`}>
            <Typhography
              type="h10"
              color="text-grey-600"
              component="div"
              additionalClasses="mb-2 uppercase"
            >
              {'Selected'}
            </Typhography>
            <div className="flex items-end whitespace-nowrap min-w-[80px]">
              <Typhography type="h2Bold" color="text-primary-500">
                {`${selectedRowsLength} ${isTransfer ? 'Transactions' : 'Invoices'}`}
              </Typhography>
            </div>
          </div>
        </div>
        <div className={`flex pl-4 border-solid border-l-[1px] border-grey-200`}>
          <div className={`flex flex-col justify-center items-start`}>
            <Typhography
              type="h10"
              color="text-grey-600"
              component="div"
              additionalClasses="mb-2 uppercase"
            >
              {'Amount'}
            </Typhography>
            <div className="flex whitespace-nowrap min-w-[74px]">
              <Typhography type="h2" color="text-primary-500">
                {useCurrencyFormat(totalAmount)}
              </Typhography>
            </div>
          </div>
          <div className={`flex flex-col pr-4 justify-center items-end ml-[10px]`}>
            {isTransfer && (
              <PayTransactionModal
                payoutNumber={selectedRowsLength?.toString()}
                ids={ids}
                btnDisabled={selectedRowsLength === 0}
                isFromTransactionModal={isFromTransactionModal}
                setEnableSelect={setEnableSelect}
                setMainModalOpen={setModalOpen}
              />
            )}
            {!isTransfer && (
              <PayNowModal
                payoutStatus={'APPROVED'}
                id={ids}
                dueAmount={useCurrencyFormat(totalAmount)}
                buttonVariants={'extraSmall'}
                btnDisabled={selectedRowsLength === 0}
                offsetFromBottom={200}
                isLeft={true}
                isEnableScheduled={false}
                isEnableScheduledToggle={false}
                payoutNumber={selectedRowsLength?.toString()}
                setEnableSelect={setEnableSelect}
              />
            )}
          </div>
        </div>
        {!isTransfer && (
          <div
            className={`flex w-full justify-start items-start pt-1 pl-4 border-solid border-l-[1px] border-grey-200`}
          >
            <div className={`flex flex-col`}>
              <PayNowModal
                payoutStatus={'APPROVED'}
                id={ids}
                dueAmount={useCurrencyFormat(totalAmount)}
                buttonVariants={'extraSmall'}
                btnDisabled={selectedRowsLength === 0}
                offsetFromBottom={200}
                isLeft={true}
                isEnableScheduled={true}
                isEnableScheduledToggle={false}
                payoutNumber={selectedRowsLength?.toString()}
                setEnableSelect={setEnableSelect}
              />
            </div>
          </div>
        )}

        <div className={`flex w-full justify-end items-center pl-3`}>
          <div className={`flex flex-col w-auto`}>
            <Button
              buttonLabel="Cancel"
              iconAlignment="right"
              iconClass="ml-2"
              iconType="CloseIcon"
              isIconEnabled
              variants="extraSmallOutlined"
              additionalClasses={'border-none bg-inherit'}
              onClick={() => setEnableSelect(false)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopPaymentBar;
