import { Grid, PaymentInfoCard, Typhography } from 'components/core';

export interface TypeSelectionProps {
  onPaymentClick?: () => void;
  onWireTransferClick?: () => void;
}

export const TypeSelection = ({ onPaymentClick, onWireTransferClick }: TypeSelectionProps) => {
  return (
    <>
      <Typhography component="p" type="h2" color="text-grey-900 mb-6 uppercase">
        {'Select PayMent Method'}
      </Typhography>
      <Grid columnCount={1} columnGap={4}>
        <PaymentInfoCard
          iconType="PropertyIcon"
          title="ACH Payment"
          content="Pay bills online with your bank account withing 3-4 days."
          onClick={onPaymentClick}
        />
        <PaymentInfoCard
          iconType="WireTransferIcon"
          title="Wire Transfer"
          content="Get the wire details. You can easely transfer your money directly to PayUp with our bank details. "
          onClick={onWireTransferClick}
        />
      </Grid>
    </>
  );
};
