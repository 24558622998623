import { createApi } from '@reduxjs/toolkit/query/react';
import { prepareBaseQuery } from '../shared/configs/config';
export const ledgersApi = createApi({
  reducerPath: 'ledgersApi',
  tagTypes: ['ledgers'],
  baseQuery: prepareBaseQuery(),
  endpoints: (builder) => ({
    getLedgers: builder.query({
      query: (state) => ({
        url: `/query/ledgers`,
        body: state,
        method: 'POST',
      }),
    }),
  }),
});

export const { useGetLedgersQuery } = ledgersApi;
