import React, { useState } from 'react';
import { ConfirmationModal, Grid, Message } from 'components/core';
import NewMethodVerificationForm from 'components/modules/Balance/Payment/Verification/Verification';
import { useCancelSetupIntentMutation, useVerifySourceMutation } from 'services/paymentSourcesApi';
import { useForm } from 'react-hook-form';
import { IntentCard } from '../../../../core/Cards/BankAccountCard/IntentCard';
import moment from 'moment';
import { getByUserNameSorting } from 'shared/utilities/utils';
import { useUpdatePropertyDefaultPaymentMethodMutation } from '../../../../../services/properties';

interface IntentListProps {
  cardData?: any[];
  defaultSourceID?: any;
  editPaymentSource?: any;
  isPaymentEditable?: boolean;
  isPaymentEditAccess?: boolean;
  propertyId?: string;
}

const IntentList: React.FC<IntentListProps> = ({
  cardData,
  defaultSourceID,
  editPaymentSource,
  isPaymentEditable,
  isPaymentEditAccess,
  propertyId,
}) => {
  const [showVerificationModal, setShowVerificationModal] = useState(false);
  const [methodId, setMethodId] = useState('');
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const [useVerifySourceMutationOp, { error, isError }]: any = useVerifySourceMutation();
  const [useUpdtePropertyDefaultPMMutation] = useUpdatePropertyDefaultPaymentMethodMutation();
  const [
    useDeleteMutationOp,
    { error: deleteError, isError: isDeleteError, isLoading: isDeleteLoading },
  ]: any = useCancelSetupIntentMutation();

  const { register, handleSubmit, control, setValue } = useForm();

  /**
   * handle verification popup modal
   */
  const handleStatusVerification = (card: any) => {
    if (card.next_action.type === 'verify_with_microdeposits') {
      window.open(card.next_action.verify_with_microdeposits.hosted_verification_url);
    }
  };

  /**
   * Verify the payment method
   */
  const handlePaymentMethodVerification = async (data: any) => {
    const setAmount1: any = parseFloat(data.descriptor_code_1) * 100;
    const setAmount2: any = parseFloat(data.descriptor_code_2) * 100;

    await useVerifySourceMutationOp({
      id: methodId,
      amount1: parseInt(setAmount1),
      amount2: parseInt(setAmount2),
    });

    setValue('descriptor_code_1', null);
    setValue('descriptor_code_2', null);

    setShowVerificationModal(false);
  };

  /**
   * Handle VerificationModalClose
   */
  const handleVerificationPopupClose = () => {
    setValue('descriptor_code_1', null);
    setValue('descriptor_code_2', null);
    setShowVerificationModal(!showVerificationModal);
  };

  /**
   * Set default payment source
   */
  const updateDefaultAccount = async (pmId: string, propertyId?: string) => {
    await useUpdtePropertyDefaultPMMutation({ id: propertyId, pmId: pmId });
  };

  /**
   * Remove the payment method
   */
  const deletePaymentSource = (id: string) => {
    setMethodId(id);
    setConfirmationModalOpen(true);
  };

  const deletePaymentSourceCancel = () => {
    setMethodId('');
    setConfirmationModalOpen(false);
  };

  const deletePaymentSourceConfirm = async () => {
    await useDeleteMutationOp(methodId);
    deletePaymentSourceCancel();
  };

  return (
    <>
      {((isError && error.status === 500) || (isDeleteError && deleteError.status === 500)) && (
        <Message type={'error'} title={'Payment method verification'}>
          {isDeleteError
            ? deleteError?.data?.error ?? deleteError?.data?.message
            : error?.data?.message}
        </Message>
      )}

      <Grid columnCount={3} columnGap={4} additionalClasses={'mb-6'}>
        {cardData?.map((card: any, index) => {
          // eslint-disable-next-line @typescript-eslint/naming-convention,react/destructuring-assignment,max-len
          const getUsBankAccount = card.payment_method;
          if (getUsBankAccount) {
            // eslint-disable-next-line @typescript-eslint/naming-convention,react/destructuring-assignment,max-len
            const { routing_number, bank_name, last4, account_holder_type }: any =
              card.payment_method.us_bank_account;
            // eslint-disable-next-line @typescript-eslint/naming-convention
            const { billing_details } = card.payment_method;
            // eslint-disable-next-line @typescript-eslint/naming-convention
            const setup_intent_id = card.id;
            // eslint-disable-next-line @typescript-eslint/naming-convention
            const mandate_status = card?.mandate?.status;

            const setValueCreatedAt = card.created
              ? moment.unix(card.created).format('MM/DD/YYYY')
              : null;

            const setValueModifiedBy = getByUserNameSorting(card, false, true);

            return (
              <React.Fragment key={card.id}>
                <IntentCard
                  title={'Bank Account'}
                  isDefault={card.payment_method.id === defaultSourceID ? true : false}
                  valueBankName={bank_name}
                  valueAccountHolder={billing_details.name}
                  valueRouting={routing_number}
                  isPaymentCard
                  valueStatus={card.status === 'succeeded' ? 'verified' : card.status}
                  handleStatusVerification={() => handleStatusVerification(card)}
                  removeAccount={() => deletePaymentSource(setup_intent_id)}
                  setAsDefault={() => updateDefaultAccount(card.payment_method.id, propertyId)}
                  editAccount={() => editPaymentSource(card)}
                  lastFourDigits={last4}
                  isPaymentEditable={isPaymentEditable}
                  valueLabel={card.metadata.label}
                  valueAccountHolderType={account_holder_type}
                  isDefaultOptionAvailable={true}
                  nextAction={card.next_action}
                  valueCreatedAt={setValueCreatedAt}
                  valueModifiedBy={setValueModifiedBy !== '' ? setValueModifiedBy : null}
                  isPaymentEditAccess={isPaymentEditAccess}
                  mandateStatus={mandate_status}
                />
              </React.Fragment>
            );
          }
          return false;
        })}
      </Grid>

      {confirmationModalOpen && (
        <ConfirmationModal
          handleSubmitEvent={deletePaymentSourceConfirm}
          handleBackEvent={deletePaymentSourceCancel}
          mainContent={`Are you sure you want to delete this payment method?`}
          isIconLoad={isDeleteLoading}
        />
      )}

      <NewMethodVerificationForm
        verificationPopUpClose={handleVerificationPopupClose}
        onSubmitVerifyPaymentMethod={handlePaymentMethodVerification}
        modalState={showVerificationModal}
        control={control}
        handleSubmit={handleSubmit}
        register={register}
      />
    </>
  );
};

export default IntentList;
