import React from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { store } from './store/store';
import { Provider } from 'react-redux';

import App from './App';
import reportWebVitals from './shared/configs/reportWebVitals';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';

import './shared/styles/tailwind.css';
import moment from 'moment-timezone';

moment.updateLocale('en', {
  week: {
    dow: 1, // Monday is the first day of the week.
  },
});

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LD_KEY ?? '61c35a35d8fdf20ec09592ac',
    user: { key: 'anon-user', anonymous: true },
  });

  ReactDOM.render(
    <React.StrictMode>
      <HelmetProvider>
        <LDProvider>
          <Provider store={store}>
            <App />
          </Provider>
        </LDProvider>
      </HelmetProvider>
    </React.StrictMode>,
    document.getElementById('root')
  );
})();

reportWebVitals();
