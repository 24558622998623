import classNames from 'classnames';

export interface NetXChipsProps {
  isEditable?: boolean;
  isPercentageAvailable?: boolean;
  isDisabled?: boolean;
  value?: string;
  onClick?: () => void;
  isChipInFadeMode?: boolean;
}

export const NetXChips = ({
  isEditable = false,
  isPercentageAvailable = false,
  isDisabled = false,
  value,
  onClick,
  isChipInFadeMode,
}: NetXChipsProps) => {
  const commonTagClasses =
    'rounded bg-primary-75 w-[72px] inline-flex items-center justify-center h-6 text-12s font-medium text-primary-500 relative overflow-hidden';
  const handleDisabledStateClasses = isDisabled ? 'opacity-50' : 'opacity-100';
  const diabledEditOption =
    isDisabled || !isEditable ? 'pointer-events-none' : 'pointer-events-auto';
  const handleCursorClasses = isEditable ? 'cursor-pointer' : 'cursor-auto';
  const handleChipFadeMode = !isChipInFadeMode ? '' : '!opacity-70';

  return (
    <div
      className={classNames([
        commonTagClasses,
        handleDisabledStateClasses,
        diabledEditOption,
        handleCursorClasses,
        handleChipFadeMode,
      ])}
      onClick={onClick}
    >
      <span className={`ml-0 text-inherit`}>{value}</span>
      {isPercentageAvailable && value !== 'Net 30+' && (
        <>
          <span className="absolute flex w-10 h-8 rotate-[60deg] bg-primary-500/80 -top-3 -right-[25px]" />
          <span className="absolute top-0 font-medium text-white text-10s right-[1px]">%</span>
        </>
      )}
    </div>
  );
};
