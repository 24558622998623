import { Typhography } from '../../Typhography/Typhography';
import Icon from '../../Media/Icon/Icon';
import { PaymentInfoCardProps } from 'shared/models/components/base.model';

export const PaymentInfoCard = ({ iconType, content, title, onClick }: PaymentInfoCardProps) => {
  return (
    <div
      className="flex flex-col border border-solid rounded-lg cursor-pointer border-grey-100 min-h-[124px] p-4 hover:bg-grey-50"
      onClick={onClick}
    >
      <div className="flex items-center w-full mb-3">
        <div className="mr-2 text-grey-900">
          <Icon iconType={iconType} iconColor="inherit" />
        </div>
        {title && (
          <Typhography component="p" type="h2Bold" color="text-primary-500">
            {title}
          </Typhography>
        )}
      </div>
      {content && (
        <Typhography
          component="p"
          type="h2Bold"
          color="text-grey-700"
          additionalClasses="!font-normal"
        >
          {content}
        </Typhography>
      )}
    </div>
  );
};
