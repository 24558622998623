import DataList from 'components/core/SuperTable/DataList/DataList';
import EmptyList from 'components/core/EmptyList/EmptyList';

import { useGetVendorsQuery } from 'services/vendorsApi';
import { serverSidePaginationActive, useServerSidePagination } from 'hooks/useServerSidePagination';
import { HeaderTabNavigation, Message } from 'components/core';
import { handlePropertiesDetailTabNavData } from 'constants/TabNavData';
import { useState } from 'react';

export const PropertyDetailsVendorsContainer = ({ data, id, propertyName }: any) => {
  const [detailTabNavData] = handlePropertiesDetailTabNavData(id);
  const [visibleColumns, setVisibleColumns] = useState([]);
  const [filteredRow, setFilteredRow] = useState([]);

  const [pageSizeOptions, paginationState, previousButtonClick, nextButtonClick, setPageSize]: any =
    useServerSidePagination();

  const vendorsParam = {
    first: serverSidePaginationActive ? paginationState.pageSize : 3000,
    offset: serverSidePaginationActive ? paginationState.pageIndex * paginationState.pageSize : 0,
    filter: {
      id: {
        in: data?.map((item: any) => JSON.stringify(item)),
      },
    },
  };

  const { data: vendordata, isSuccess, error, isError }: any = useGetVendorsQuery(vendorsParam);
  const vendorsDataAccountNodes: any = vendordata?.accounts.nodes; // Get vendors data Object

  const totalRecords: number = vendordata?.accounts?.totalCount ?? 0;
  const totalPageCount = Math.ceil(totalRecords / paginationState.pageSize);

  return (
    <>
      <HeaderTabNavigation
        tabNavData={detailTabNavData}
        visibleColumns={visibleColumns}
        filteredRow={filteredRow}
        isDownloadCsvButtonEnabled={isSuccess && vendorsDataAccountNodes.length !== 0}
      />

      {isError && (
        <Message type="error" title={`${propertyName}'s Vendors`}>
          {error.error ?? error.data.message ?? error.data?.error}
        </Message>
      )}

      {isSuccess && (
        <div>
          {vendorsDataAccountNodes && vendorsDataAccountNodes.length > 0 ? (
            <DataList
              subTabType="allVendors"
              tableType="vendor"
              data={vendorsDataAccountNodes}
              tableWrapperType="grid-height-medium"
              isPagination={true}
              isServerSidePagination={serverSidePaginationActive}
              previousPageClick={previousButtonClick}
              nextPageClick={nextButtonClick}
              setPageSizeValue={setPageSize}
              options={pageSizeOptions}
              totalPageCount={totalPageCount}
              pageSizeVal={paginationState.pageSize}
              pageIndexVal={paginationState.pageIndex}
              setVisibleColumns={setVisibleColumns}
              setFilteredRow={setFilteredRow}
            />
          ) : (
            <EmptyList />
          )}
        </div>
      )}
    </>
  );
};
