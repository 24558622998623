import React from 'react';
import { Controller } from 'react-hook-form';

import Modal from '../../../../core/Modals/Modal/Modal';
import Heading from '../../../../core/Heading/Heading';
import Typho from '../../../../core/OldComponents/Typho/Typho';
import Label from '../../../../core/OldComponents/FormElements/Label/Label';
import { Classes } from '../../../../../shared/configs/componentsClasses';
import { Button } from 'components/core';

interface NeMethodProps {
  onSubmitVerifyPaymentMethod?: any;
  verificationPopUpClose?: () => void;
  modalState?: boolean;
  onInputChange?: any;
  values?: any;
  control?: any;
  handleSubmit?: any;
  register?: any;
}

const NewMethodVerificationForm: React.FC<NeMethodProps> = ({
  onSubmitVerifyPaymentMethod,
  verificationPopUpClose,
  modalState,
  values,
  control,
  handleSubmit,
  register,
}) => {
  const inputDefaultClasses = Classes.input.default;

  return (
    <>
      <Modal
        isOpen={modalState}
        onClose={verificationPopUpClose}
        modalAdditionalClasses={
          '!w-[600px] items-center justify-center min-h-[138px] px-8 py-8 flex-col'
        }
      >
        <div className="flex flex-col w-full pl-2 pr-2">
          <Heading
            type="h2"
            colorType="dark"
            additionalClasses="mb-2 text-center"
            label="Verify Bank Account"
          />
          <Typho
            size="14s"
            color="black"
            weight="medium"
            additionalClasses="mb-6 text-center"
            type="p"
          >
            PayUp sent two small deposits to this bank account. To verify this account, please
            confirm the amounts of these deposits.
          </Typho>
        </div>
        <form onSubmit={handleSubmit(onSubmitVerifyPaymentMethod)} className="flex flex-col w-full">
          <div className="pl-2 pr-2">
            <div className={'mb-8 flex w-full flex-col'}>
              <Label
                name={'descriptor_code_1'}
                labelType={'blackSmall'}
                label={'First deposit'}
                additionalClasses={'mb-2'}
              />

              <Controller
                control={control}
                name="descriptor_code_1"
                render={({ field: { value } }) => (
                  <input
                    type="text"
                    {...register('descriptor_code_1')}
                    className={inputDefaultClasses}
                    placeholder={'0.32'}
                    maxLength={4}
                    onKeyPress={(event) => {
                      if (!/^[-+]?\d*\.?\d*$/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    required
                    value={value}
                  />
                )}
              />
            </div>
            <div className={'mb-8 flex w-full flex-col'}>
              <Label
                name={'descriptor_code_2'}
                labelType={'blackSmall'}
                label={'Second deposit'}
                additionalClasses={'mb-2'}
              />
              <Controller
                control={control}
                name="descriptor_code_2"
                render={({ field: { value } }) => (
                  <input
                    type="text"
                    {...register('descriptor_code_2')}
                    className={inputDefaultClasses}
                    placeholder={'0.45'}
                    maxLength={4}
                    onKeyPress={(event) => {
                      if (!/^[-+]?\d*\.?\d*$/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    required
                    value={value}
                  />
                )}
              />
            </div>
          </div>
          <div className="flex items-center justify-end w-full pl-2 pr-2 mb-2">
            <span
              className="inline-flex mr-12 font-medium underline cursor-pointer text-black-900 text-16s"
              onClick={verificationPopUpClose}
            >
              Cancel
            </span>
            <Button
              variants="default"
              type={'submit'}
              buttonLabel={`Verify`}
              additionalClasses="w-20"
            />
          </div>
        </form>
      </Modal>
    </>
  );
};

export default NewMethodVerificationForm;
