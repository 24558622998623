import classNames from 'classnames';
import { ButtonProps } from 'shared/models/components/base.model';
import Icon from '../Media/Icon/Icon';
import { handleButtonClasses } from './Button.handler';

export const Button = ({
  type = 'button',
  isDisabled = false,
  buttonLabel,
  iconType,
  iconColor,
  iconAlignment = 'left',
  isIconEnabled,
  variants = 'default',
  additionalClasses,
  iconClass,
  onClick,
  ...props
}: ButtonProps) => {
  const commonStyles = 'flex rounded-lg justify-center items-center px-2';
  const handleDisabledState = isDisabled ? 'opacity-[0.38] pointer-events-none' : 'opacity-100';
  const buttonClasses = [
    commonStyles,
    handleButtonClasses(variants),
    additionalClasses,
    handleDisabledState,
  ];

  return (
    <button
      type={type}
      disabled={isDisabled}
      className={classNames(buttonClasses)}
      onClick={onClick}
      {...props}
    >
      {iconAlignment === 'left' && isIconEnabled && (
        <span
          className={`${iconColor ? iconColor : 'text-inherit'} ${iconClass ? iconClass : 'mr-2'}`}
        >
          <Icon iconType={iconType} iconColor="inherit" />
        </span>
      )}
      {buttonLabel}
      {iconAlignment === 'right' && isIconEnabled && (
        <span
          className={`${iconColor ? iconColor : 'text-inherit'} ${iconClass ? iconClass : 'ml-2 '}`}
        >
          <Icon iconType={iconType} iconColor="inherit" />
        </span>
      )}
    </button>
  );
};
