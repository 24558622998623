export const Classes = {
  wrapper: {
    default: 'flex w-full min-h-screen bg-white',
  },
  typho: {
    white: 'text-white',
    black: 'text-black-900',
    blue: 'text-blue',
    gray: 'text-gray-220',
    gray720: 'text-gray-720',
    black650: 'text-black-650',
    inherit: 'text-inherit',
  },
  heading: {
    white: 'text-white',
    gray: 'text-black-850',
    dark: 'text-black-900',
    h1: 'text-42s font-bold',
    h2: 'text-24s font-light',
    h3: 'text-20s font-bold',
    h4: 'text-18s font-bold',
    h5: 'text-16s font-bold',
    h6: 'text-16s font-bold',
  },
  message: {
    common:
      'py-4 pl-6 px-6 w-full mb-4 rounded-lg shadow-messages flex items-center justify-between',
    error: 'bg-red-75 text-red-500',
    success: 'bg-green-75 text-green-500',
    warning: 'bg-yellow-50 text-yellow-500',
    neutral: 'bg-grey-50 text-grey-500',
    highlight: 'bg-lightBlue',
    headsUp: 'bg-yellow-75 !text-black-900 py-3 pl-5 pr-6 rounded-none shadow-none',
  },
  card: {
    common: 'flex w-full rounded-lg',
    lightGray: 'bg-gray-110 px-6 py-5',
    lightGrayHalf: 'bg-gray-115 px-6 py-5',
    gray: 'bg-gray-140 px-5 py-4',
    white: 'bg-white border-black-925 px-[35px] py-[35px]',
    black: 'bg-black-900 px-2 py-2 border border-solid border-black-900',
    whiteBorder: 'bg-white border border-gray-190 border-solid',
  },
  button: {
    default: 'text-white text-xl bg-black-900',
    common: 'flex items-center justify-center font-normal w-full ease-out duration-300',
    disabled: 'disabled:text-black-950 disabled:cursor-not-allowed disabled:pointer-events-none',
    blackLarge: 'h-60px text-white bg-black-900 lg:hover:!bg-black-900 text-17s rounded uppercase',
    blackMedium: 'h-48 text-white bg-black-900 lg:hover:!bg-black-900 text-18s rounded-md',
    blackMediumH36: 'h-36 text-white bg-black-900 lg:hover:!bg-black-900 text-18s rounded-md',
    blackSmall: 'h-[36px] text-white bg-black-900 lg:hover:!bg-black-900 text-12s rounded-md',
    blackExtraSmall: 'h-[28px] text-white bg-black-900 lg:hover:!bg-black-900 text-14s rounded-md',
    sentButton: 'h-[40px] text-white bg-black-900 lg:hover:!bg-blue text-19s rounded-md',
    smallButtonGray:
      'h-[36px] text-14s bg-gray-110 text-black-900 font-bold lg:hover:!bg-gray-110 hover:text-black-900 rounded-md',
  },
  label: {
    default: 'text-black-600 text-14s font-normal',
    black: 'text-black-900 text-14s font-normal',
    blackSmall: 'text-black-960 text-12s font-normal',
    smallSelect: 'text-14s font-normal text-black-900',
  },
  input: {
    default:
      'form-input h-[42px] border border-solid border-grey-100 rounded-lg text-black placeholderColor-gray  text-14s text-grey-900 px-4',
    readonly: 'form-input text-black opacity-60 !outline-0 !border-none',
    wrapper: 'flex w-full flex-col relative mb-4',
    error: 'relative pt-1',
    large:
      'form-input w-full h-14 border border-solid border-black-925 rounded-lg text-black placeholderColor-gray350 text-base shadow-none font-normal',
  },
  inputValidation: {
    error: 'flex w-full pt-[1px] text-12s text-red-500 font-normal items-center',
  },
  select: {
    default:
      'form-select h-[42px] border border-solid border-grey-100 rounded-lg text-black placeholderColor-gray text-14s text-grey-900 px-4',
    small:
      'form-select h-8 border border-gray-160 border-solid text-13s text-black-760 rounded-[3px] appearence-none placeholderColor-gray shadow-none',
    wrapper: 'flex w-full flex-col relative',
    error: 'absolute top-[100%]',
  },
  image: {
    default: 'flex',
  },
  icon: {
    white: 'text-white',
    black: 'text-black-900',
  },
};
