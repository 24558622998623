import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import Icon from '../../Media/Icon/Icon';
import Message from '../../Message/Message';
import { OverlayLayer } from '../../OldComponents/OverlayLayer/OverlayLayer';
import { BlockConfirmationContent } from '../../OldComponents/BlockContents/BlockConfirmationContent';
import { BlockStatusContents } from '../../OldComponents/BlockContents/BlockStatusContents';

import { useElementPosition } from '../../../../hooks/useElementPosition';
import { useGetPayoutTermInfoMutation } from 'services/vendorsApi';
import { useUpdateNetTermMutation, useUpdateNetTermsBulkMutation } from 'services/payoutsApi';

import { reOrderingTheTerms, getTermName, getTermDueDays } from 'helpers/arrayFormating';
import { Button } from '../../Button/Button';

export interface EditTermModalProps {
  isBulkEdit?: boolean;
  defaultTermID?: any;
  offsetFromBottom?: number;
  payoutId?: string;
  amount?: string;
  isCoupa?: boolean;
  selectedPayoutIDs?: any;
  dependencyVariable?: any;
}

const EditTermModal = ({
  isBulkEdit = true,
  defaultTermID,
  offsetFromBottom = 0,
  payoutId,
  amount,
  isCoupa,
  selectedPayoutIDs,
  dependencyVariable,
}: EditTermModalProps) => {
  const [modalConfirmationOpen, setModalConfirmationOpen] = useState(false);
  const [modalStatusOpen, setModalStatusOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedTerm, setSelectedTerm] = useState(null);
  const [error, setError] = useState(null);

  const [height, boxRef]: any = useElementPosition([modalOpen]);

  const [useGetPayouts, netTermData] = useGetPayoutTermInfoMutation();
  const payoutTerms = netTermData?.data?.terms ? netTermData?.data?.terms : [];
  const [updateNetTermMutation, { isLoading: isUpdateNetTermLoading }] = useUpdateNetTermMutation();
  const [updateNetTermBulkMutation, { isLoading: isUpdateNetTermBulkLoading }] =
    useUpdateNetTermsBulkMutation();

  useEffect(() => {
    setTimeout(() => {
      setModalStatusOpen(false);
      setSelectedTerm(null);
    }, 2000);
  }, [dependencyVariable]);

  /**
   * Re ordering the terms data __
   */
  const sorted = reOrderingTheTerms(payoutTerms);

  /**
   * Handl model open __
   */
  const handleModalOpen = () => {
    setModalOpen(true);
    setModalConfirmationOpen(false);
    setModalStatusOpen(false);

    if (!isBulkEdit && payoutId) {
      useGetPayouts({ amount: amount, payoutId: payoutId });
    }

    if (isBulkEdit && selectedPayoutIDs.length > 0) {
      useGetPayouts({ amount: null, payoutId: selectedPayoutIDs[0] });
    }
  };

  /**
   * Handle the modal close __
   */
  const handlePickerClose = () => {
    setModalOpen(false);
    setModalConfirmationOpen(false);
    setModalStatusOpen(false);
    setSelectedTerm(null);
  };

  /**
   * handle the term selection from the modal __
   */
  const handleTermSelection = (e: any) => {
    setSelectedTerm(e.target.value);
  };

  /**
   * Handle the apply button click after the term selection __
   */
  const handleApplyButton = () => {
    setModalOpen(true);
    setModalConfirmationOpen(true);
    setModalStatusOpen(false);
  };

  /**
   * Handle the Cancel link click event __
   */
  const handleBackEvent = () => {
    setModalOpen(true);
    setModalConfirmationOpen(false);
    setModalStatusOpen(false);
    setSelectedTerm(null);
  };

  /**
   * Handle the api request to get the call __
   */
  const handleEditTermSubmit = async () => {
    const editParamsIndividual: any = { payoutId: payoutId, net_term: selectedTerm };
    const editParamsBulk: any = { payoutIds: selectedPayoutIDs, net_term: selectedTerm };

    try {
      let response: any = null;

      if (isBulkEdit) {
        response = await updateNetTermBulkMutation(editParamsBulk);
      }

      if (!isBulkEdit) {
        response = await updateNetTermMutation(editParamsIndividual);
      }

      if (response && !response?.error?.data?.message) {
        setModalOpen(false);
        setModalConfirmationOpen(false);
        setModalStatusOpen(true);
      }

      if (response && response?.error?.data?.message) {
        setModalOpen(true);
        setModalConfirmationOpen(false);
        setError(response?.error?.data?.message);
      }
    } catch (e: any) {
      console.log(e);
    }
  };

  /**
   * Handle the classes for rest of the edit term modal container __
   */
  const handleTheOffsetClasses = () => {
    let offSetClasses = 'top-10 !opacity-100';

    if (!isBulkEdit && height > offsetFromBottom) {
      offSetClasses = 'top-auto bottom-10 !opacity-100';
    }

    if (height < offsetFromBottom) {
      offSetClasses = 'top-10 !opacity-100';
    }

    return offSetClasses;
  };

  /**
   * Handle the classes for edit term modal container __
   */
  const handleTheConfirmationModalOffsetClasses = () => {
    let offSetClasses = 'top-10 !opacity-100';
    const modalHeight = 450;

    if (!isBulkEdit && modalHeight > offsetFromBottom) {
      offSetClasses = 'top-auto bottom-10 !opacity-100';
    }

    if (modalHeight < offsetFromBottom) {
      offSetClasses = 'top-10 !opacity-100';
    }

    return offSetClasses;
  };

  return (
    <div className="relative flex flex-col items-center">
      {isBulkEdit && (
        <Button
          variants="extraSmall"
          additionalClasses={`w-[228px] ${
            selectedPayoutIDs && selectedPayoutIDs.length === 0
              ? 'pointer-events-none opacity-30'
              : 'pointer-events-auto'
          }`}
          buttonLabel="Apply NET Terms"
          onClick={handleModalOpen}
        />
      )}

      {!isBulkEdit && (
        <div
          className="border border-solid rounded border-grey-300 h-9 w-[168px] flex items-center justify-between px-3 cursor-pointer"
          onClick={handleModalOpen}
        >
          <span
            className={`text-14s capitalize ${
              defaultTermID ? 'text-primary-500' : 'text-grey-700'
            }`}
          >
            {defaultTermID
              ? getTermName(defaultTermID)
              : selectedTerm
              ? getTermName(selectedTerm)
              : 'Undefined'}
          </span>
          <Icon iconType="DropDown" additionalClasses="text-grey-700 h-1" />
        </div>
      )}

      {modalOpen && (
        <>
          <OverlayLayer
            onOverlayClick={handlePickerClose}
            additionalClasses={
              modalConfirmationOpen ? 'pointer-events-none' : 'pointer-events-auto'
            }
          />
          {isCoupa && (
            <div className="z-20 w-[210px] flex flex-col bg-white justify-center rounded-lg shadow-paynowModal absolute right-1 px-3 py-3 top-10 min-h-[50px] text-12s normal-case">
              Please import the invoice to edit the term.
            </div>
          )}

          {!isCoupa && !modalConfirmationOpen && !modalStatusOpen && (
            <div
              className={classNames([
                'z-20 w-[392px] flex flex-col bg-white justify-center rounded-lg shadow-paynowModal absolute right-1  px-2 opacity-0 py-6',
                handleTheOffsetClasses(),
              ])}
              ref={boxRef}
            >
              <div className="flex flex-col w-full px-4">
                <h2 className="mt-0 mb-6 font-medium text-20s text-primary-500">
                  Please select your payout terms
                </h2>
                <p className="mt-0 mb-6 font-light text-14s text-primary-500">
                  For existing invoices, we offer a 50% discount on payout fees!
                </p>
              </div>
              {error && (
                <div className="flex flex-col w-full px-4">
                  <Message type="error">{error}</Message>
                </div>
              )}
              <div className="flex flex-col w-full px-4 h-[260px] overflow-y-auto">
                {payoutTerms &&
                  sorted.map((term: any) => {
                    const handleActiveState =
                      term.active && (term.id === 'old_net30' || term.id === 'net30')
                        ? 'pointer-events-auto order-0'
                        : 'pointer-events-none opacity-60 order-1';

                    const handleLabelClasses = () => {
                      let labelClasses = '';

                      if (defaultTermID === term.id) {
                        labelClasses = '!border-primary-500 pointer-events-none';
                      }

                      if (selectedTerm === term.id) {
                        labelClasses = '!border-primary-500 pointer-events-none bg-grey-50';
                      }

                      return labelClasses;
                    };

                    return (
                      <div
                        className={classNames(['flex flex-col w-full mb-2', handleActiveState])}
                        key={term.id}
                      >
                        <input
                          type="radio"
                          name="netTerms"
                          id={term.id}
                          className="absolute w-0 h-0 p-0 m-0 overflow-hidden opacity-0 -left-4 terms_selection"
                          onChange={handleTermSelection}
                          defaultValue={term.id}
                          data-days={term.due_days}
                        />
                        <label
                          htmlFor={term.id}
                          className={classNames([
                            'flex w-full py-1 border border-solid rounded-xl border-grey-100 min-h-[44px]',
                            'px-4 items-center justify-between cursor-pointer',
                            handleLabelClasses(),
                          ])}
                        >
                          {
                            <div className="flex items-center">
                              <div className="w-[100px] flex">
                                <span
                                  className={classNames([
                                    'inline-flex font-bold leading-3 text-primary-500',
                                    term.id === 'old_net30' || term.id === 'net30'
                                      ? 'text-10s'
                                      : 'text-12s',
                                  ])}
                                >
                                  {term.id === 'old_net30' || term.id === 'net30'
                                    ? `When property pays (Est. 30+ days)`
                                    : `${term.due_days} Days Payout`}
                                </span>
                              </div>
                              {term.isAccountDefault && (
                                <span
                                  className={classNames([
                                    'inline-flex items-center justify-center px-2 font-medium uppercase whitespace-nowrap text-8s h-4',
                                    'text-primary-500 rounded-2xl bg-grey-100 ml-1',
                                  ])}
                                >
                                  Default
                                </span>
                              )}
                            </div>
                          }

                          <div>
                            {term.category === 'old' && term.id !== 'old_net30' && (
                              <span className="font-light line-through text-12s text-grey-600">
                                {term.processing_fee !== 0 && term.processing_fee * 2 + '%'}
                              </span>
                            )}
                            <span className="font-medium text-12s text-primary-500 opacity-80 ml-[6px]">
                              {`${
                                term.processing_fee !== 0
                                  ? term.processing_fee.toFixed(1)
                                  : term.processing_fee
                              }% Fee`}
                            </span>
                          </div>
                        </label>
                      </div>
                    );
                  })}
              </div>
              <div className="flex items-center justify-end w-full px-4 pt-4">
                <span
                  className={classNames([
                    'inline-flex font-bold cursor-pointer text-14s text-primary-500 mr-7',
                    !selectedTerm ? 'pointer-events-none opacity-30' : 'pointer-events-auto',
                  ])}
                  onClick={handlePickerClose}
                >
                  Cancel
                </span>

                <Button
                  type={'button'}
                  buttonLabel={'Apply'}
                  onClick={handleApplyButton}
                  variants="default"
                  additionalClasses={`w-[128px] ${
                    !selectedTerm ? 'pointer-events-none opacity-30' : 'pointer-events-auto'
                  }`}
                  iconType="Spin"
                  iconClass="inline-flex ml-1 w-3 h-3"
                  isIconEnabled={false}
                  iconAlignment={'right'}
                />
              </div>
            </div>
          )}

          {modalConfirmationOpen && (
            <BlockConfirmationContent
              handleBackEvent={handleBackEvent}
              handleSubmitEvent={handleEditTermSubmit}
              mainContent={
                isBulkEdit
                  ? `Are you sure you want to apply ${getTermName(selectedTerm)} Term to ${
                      selectedPayoutIDs && selectedPayoutIDs.length
                    } Invoices ?`
                  : `Are you sure you want to apply ${getTermName(selectedTerm)} Term ?`
              }
              additionalClasses={`px-6 py-9 min-h-[408px]`}
              additionalClassesWrapper={handleTheConfirmationModalOffsetClasses()}
              isIconLoad={isUpdateNetTermLoading || isUpdateNetTermBulkLoading}
            />
          )}
        </>
      )}

      {modalStatusOpen && (
        <>
          <OverlayLayer
            onOverlayClick={handlePickerClose}
            additionalClasses={
              modalConfirmationOpen ? 'pointer-events-none' : 'pointer-events-auto'
            }
          />
          <BlockStatusContents
            additionalClasses={`px-6 pt-[20px] pb-[15px] min-h-[408px]`}
            additionalClassesWrapper={handleTheConfirmationModalOffsetClasses()}
            onCloseButtonClick={handlePickerClose}
            mainContent={isBulkEdit ? 'Net Terms Updated!' : 'Net Term Updated!'}
            subContent={`Your payment for the selected invoice will be made in ${getTermDueDays(
              selectedTerm
            )} days.`}
          />
        </>
      )}
    </div>
  );
};

export default EditTermModal;
