import React from 'react';
import Card from '../../../../core/OldComponents/Card/Card';
import Heading from '../../../../core/Heading/Heading';
import Typho from '../../../../core/OldComponents/Typho/Typho';
import { useCurrencyFormat } from '../../../../../hooks/useCurrencyFormat';

interface PaymentSummaryProps {
  pastBalance?: number;
  todaysInvoice?: any;
  dueAmount?: number;
}

const PaymentSummary: React.FC<PaymentSummaryProps> = ({
  todaysInvoice,
  pastBalance,
  dueAmount,
}) => {
  return (
    <Card cardType="whiteBorder" additionalClasses="flex-col pt-4 pl-4 pr-4 pb-3">
      <Heading type="h2" colorType="dark" additionalClasses="mb-2" label="Payment Summary" />
      <Typho size="14s" color="black" weight="medium" additionalClasses="mb-6" type="p">
        Payments are secure and powered by Stripe.
      </Typho>

      {/** Summary  */}
      <div className="flex flex-col w-full">
        {todaysInvoice && (
          <div className="flex flex-row justify-between w-full mb-2">
            <div className="w-1/2 font-normal text-left text-14s text-black-900">
              Invoice for {new Date(todaysInvoice?.due_date).toLocaleDateString()}
            </div>
            <div className="w-1/2 font-normal text-right text-14s text-black-900">
              {useCurrencyFormat(parseFloat(todaysInvoice?.amount))}
            </div>
          </div>
        )}
        <div className="flex flex-row justify-between w-full mb-2">
          <div className="w-1/2 font-normal text-left text-14s text-black-900">Past Balance</div>
          <div className="w-1/2 font-normal text-right text-14s text-black-900">
            {useCurrencyFormat(pastBalance)}
          </div>
        </div>
      </div>
      {/** Toltal Row */}
      <div className="flex flex-row items-center justify-between w-full pt-6 mb-1 border-t border-solid border-gray-155">
        <div className="w-1/2 font-bold text-left text-20s text-black-900">Total</div>
        <div className="w-1/2 font-bold text-right text-15s text-black-900">
          {useCurrencyFormat(dueAmount)}
        </div>
      </div>
    </Card>
  );
};

export default PaymentSummary;
