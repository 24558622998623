import React from 'react';
import classNames from 'classnames';

import Icon from '../Media/Icon/Icon';
import { TooltipProps } from 'shared/models/components/base.model';
import { Typhography } from 'components/core';

/**
 * Primary UI component for user interaction
 */
const Tooltip: React.FC<TooltipProps> = ({
  tooltipHeading,
  tooltipContent,
  tooltipContaineClasses = `z-10 left-0`,
  additionalClasses,
  tooltipIcon,
  tooltipIconClasses,
  tooltipSubContent,
  tooltipContentHighlighted = false,
}) => {
  const tooltipDefautClasses =
    'block h-auto invisible px-2 py-1 rounded-lg rounded-tl-none shadow-tooltip absolute top-full w-[160px] ease-out duration-300 opacity-0 tooltip !bg-primary-500 !text-white';
  const handleTooltipContainerClasses = [tooltipContaineClasses, tooltipDefautClasses];
  return (
    <div className={classNames([additionalClasses, 'relative'])}>
      <Icon
        iconColor="inherit"
        iconType={tooltipIcon}
        additionalClasses={classNames([tooltipIconClasses, 'tooltip-icon'])}
      />
      <div className={classNames(handleTooltipContainerClasses)}>
        {tooltipHeading && (
          <Typhography type="h1Bold" color="text-white" component="p" additionalClasses="mb-[2px]">
            {tooltipHeading}
          </Typhography>
        )}
        <Typhography
          type="h1"
          color={tooltipContentHighlighted ? 'text-red-500' : 'text-white'}
          component="p"
          additionalClasses="mb-[2px] !font-normal"
        >
          {tooltipContent}
        </Typhography>
        {tooltipSubContent && (
          <Typhography
            type="h1"
            color={tooltipContentHighlighted ? 'text-red-500' : 'text-white'}
            component="p"
            additionalClasses=" mb-[2px] !font-normal"
          >
            {tooltipSubContent}
          </Typhography>
        )}
      </div>
    </div>
  );
};

export default Tooltip;
