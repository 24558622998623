import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import Wrapper from '../../../layouts/Wrapper/Wrapper';
import Headers from '../../../core/Headers/Headers';
import PaymentSummary from './PaymentSummary/PaymentSummary';
import PaymentType from './PaymentType/PaymentType';
import Icon from '../../../core/Media/Icon/Icon';
import NewMethodVerificationForm from './Verification/Verification';
import Message from '../../../core/Message/Message';

import { useGetPastBalanceApiQuery } from '../../../../services/pastBalanceApi';
import { useGetInvoicesQuery } from '../../../../services/invoicesApi';
import { useCurrencyFormat } from '../../../../hooks/useCurrencyFormat';
import { useCreateChargeMutation } from '../../../../services/paymentsApi';
import {
  useGetPaymentSourcesQuery,
  useVerifySourceMutation,
} from '../../../../services/paymentSourcesApi';
import { useGetConfigQuery } from '../../../../services/configApi';
import { useForm } from 'react-hook-form';

const messageState: any = {
  meesage: '',
  messageType: '',
  isVisible: false,
};

const PaymentContainer = () => {
  const configResponse = useGetConfigQuery('');
  const navigate = useNavigate();
  const [payButtonLoader, setPayButtonLoader] = useState(false);
  const [useVerifySourceMutationOp] = useVerifySourceMutation();
  const [methodId, setMethodId] = useState('');
  const [notifications, setNotifications] = useState(messageState);
  const [showVerificationModal, setShowVerificationModal] = useState(false);
  const { register, handleSubmit, control, setValue } = useForm();

  const todaysInvoiceParams = {
    limit: 1,
    offset: 0,
    filter: {
      due_date: {
        greaterThan: moment().startOf('day').format(),
        lessThan: moment().add(1, 'day').startOf('day').format(),
      },
    },
    orderBy: 'DUE_DATE_DESC',
  };

  const [createCharge] = useCreateChargeMutation();
  const paymentButtonEvent = async () => {
    setPayButtonLoader(true);
    try {
      const payPayment = await createCharge(dueAmount);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const paymentData = payPayment?.data;
      const paymentMethod = paymentData?.payment_method_details?.type === 'ach_debit' && 'ACH';

      const paymentDetails: any = {
        paymentDate: paymentData.created
          ? moment.unix(paymentData.created).format('MM/DD/YYYY')
          : '',
        paymentMethod: paymentMethod ? paymentMethod : '',
        paymentPasbalance: paymentData.amount ? paymentData.amount : '',
        paymentTotal: paymentData.amount ? paymentData.amount : '',
        paymentFee: 0,
      };

      localStorage.setItem('paymentSummary', JSON.stringify(paymentDetails));
      setPayButtonLoader(false);
      paymentData && navigate('/blance/payment/success');
    } catch (error) {
      console.log(error);
      setPayButtonLoader(false);
    }
  };

  const todaysInvoicesResponse = useGetInvoicesQuery(todaysInvoiceParams);
  const todaysInvoice =
    todaysInvoicesResponse?.data?.invoices?.nodes?.length > 0
      ? todaysInvoicesResponse?.data?.invoices?.nodes[0]
      : '';

  const { data } = useGetPastBalanceApiQuery({});
  const dueAmount = parseFloat(todaysInvoice?.amount ?? 0) + parseFloat(data?.past_balance ?? 0);

  const paymentSourcesResponse = useGetPaymentSourcesQuery('');
  const paymentSources = paymentSourcesResponse?.data;
  const paymentSourcesLoadingFetching = paymentSourcesResponse?.isFetching;

  /**
   * Handle Payment method verification
   */
  const handleVerifyButtonClick = (event: any) => {
    setShowVerificationModal(!showVerificationModal);
    setMethodId(event.target.getAttribute('data-val'));
  };

  const handlePaymentMethodVerification = async (data: any) => {
    setNotifications({
      ...notifications,
      isVisible: false,
    });
    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const setAmount1: any = parseFloat(data.descriptor_code_1) * 100;
      const setAmount2: any = parseFloat(data.descriptor_code_2) * 100;

      const sourceVerficationQuery = {
        id: methodId,
        amount1: parseInt(setAmount1),
        amount2: parseInt(setAmount2),
      };

      const verifyData = await useVerifySourceMutationOp(sourceVerficationQuery);

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const verifyDataError = verifyData?.error?.data;

      if (verifyDataError !== 'OK') {
        setNotifications({
          message: verifyDataError.message,
          isVisible: true,
          messageType: 'error',
        });
      } else {
        setNotifications({
          ...notifications,
          isVisible: false,
        });
      }
    } catch (error: any) {
      setNotifications({ message: error, isVisible: true, messageType: 'error' });
    }
    setValue('descriptor_code_1', null);
    setValue('descriptor_code_2', null);
    setShowVerificationModal(false);
  };

  /**
   * Handle VerificationModalClose
   */
  const handleVerificationPopupClose = () => {
    setValue('descriptor_code_1', null);
    setValue('descriptor_code_2', null);
    setShowVerificationModal(!showVerificationModal);
  };

  return (
    <>
      <Wrapper additionalClasses="flex-col items-start" pageTitle="Payup Payment">
        <Headers headingContent="Balance" />
        {notifications.isVisible && (
          <Message type={notifications.messageType}>{notifications.message}</Message>
        )}
        <div className="flex flex-row w-full">
          <div className="w-1/2 pr-4">
            <PaymentSummary
              todaysInvoice={todaysInvoice}
              pastBalance={data?.past_balance}
              dueAmount={dueAmount}
            />
          </div>
          {!paymentSourcesLoadingFetching && configResponse?.data?.stripe_api_public_key ? (
            <div className="w-1/2 pl-4">
              <Elements stripe={loadStripe(configResponse?.data?.stripe_api_public_key)}>
                <PaymentType
                  buttonClickHandler={paymentButtonEvent}
                  btnValue={useCurrencyFormat(dueAmount)}
                  paymentSources={paymentSources}
                  payloaderButtonState={payButtonLoader}
                  verficationModalOpenEvent={handleVerifyButtonClick}
                />
              </Elements>
            </div>
          ) : (
            <div className="w-1/2 pl-4 h-[300px] flex justify-center items-center">
              <Icon
                iconColor="black-900"
                iconHoverColor="black-900"
                iconType="Spin"
                additionalClasses="w-12 h-12 opacit-10"
              />
            </div>
          )}
        </div>

        <NewMethodVerificationForm
          verificationPopUpClose={handleVerificationPopupClose}
          onSubmitVerifyPaymentMethod={handlePaymentMethodVerification}
          modalState={showVerificationModal}
          control={control}
          handleSubmit={handleSubmit}
          register={register}
        />
      </Wrapper>
    </>
  );
};

export default PaymentContainer;
