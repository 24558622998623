export const Images = {
  APP_LOGO: require('../../assets/images/media/payup-logo.png'),
  SLIDER_IMAGE: require('../../assets/images/media/card-intro.png'),
  APP_LOGIN: require('../../assets/images/media/payup-login.png'),
  PLACE_HOLDER_IMAGE: require('../../assets/images/media/placeholder.png'),
  SAMPLE_USER_PROFILE_IMAGE: require('../../assets/images/media/samples/user-profile.png'),
  PAYUP_TEXT: require('../../assets/images/media/paup-text.png'),
  PAYUP_LOGO_WHITE: require('../../assets/images/media/payup-logo-white.png'),
  CALCULATION_BY_DAY: require('../../assets/images/media/settings/calculation_by_day.jpg'),
  CALCULATION_BY_WEEK: require('../../assets/images/media/settings/calculation_by_week.jpg'),
};

export const Flags = {
  USA: require('../../assets/images/flags/us.png'),
};

export const LargeIcons = {
  MORE: require('../../assets/images/icons/icon_more.png'),
  BANK_ACCOUNT: require('../../assets/images/icons/bank-account-icon.png'),
};
