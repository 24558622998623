import React from 'react';
import { Navigate } from 'react-router-dom';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Form from '../../../core/FormElements/Form/Form';
import Input from '../../../core/OldComponents/FormElements/Input/Input';
import { Organizations } from '../Organizations/Organizations';

import { useGetUserQuery } from '../../../../services/userApi';
import { usePhoneValidation } from '../../../../hooks/usePhoneValidation';
import { ProfileFormParams } from '../../../../shared/utilities/interfaceHookForms';

const ProfileSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  email: yup.string().email('Enter a valid email').required('Email is required'),
  phone: yup.string().max(32, 'Max password length is 32').required('phone is required'),
});

const ProfileForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ProfileFormParams>({ resolver: yupResolver(ProfileSchema) });
  const { data, isError, isSuccess } = useGetUserQuery({});
  const name = `${data?.first_name} ${data?.last_name}` ?? ' ';
  const getPhoneNumber = String(data?.phone).replace(/\D/g, '');
  const getRestOfTheFirstNumber =
    getPhoneNumber.substring(0, 1) === '1' ? getPhoneNumber.slice(1) : getPhoneNumber;

  return (
    <>
      {/**
       * Error handling
       */}
      {isError && <Navigate to={'/401'} />}
      {/**
       *Load data if data is valid
       */}
      {isSuccess && (
        <div className="flex w-full h-">
          <Form
            buttonLabel="Update Profile"
            register={register}
            handleSubmit={handleSubmit}
            formClasses="w-full flex"
            formContainerClasses="flex-col"
          >
            <Input
              name="name"
              type="text"
              value={name}
              error={errors.name?.message}
              additionalClasses="text-14s h-5"
              label="Name"
              readOnly
              labelClasses="w-[85px]"
              wrapperClass="flex w-full flex-row relative items-center mb-8"
            />
            <Input
              name="email"
              type="email"
              value={data?.email ?? ' '}
              error={errors.email?.message}
              additionalClasses="text-14s h-5"
              label="Email"
              readOnly
              labelClasses="w-[85px]"
              wrapperClass="flex w-full flex-row relative items-center mb-8"
            />
            <Input
              name="phone"
              type="text"
              label="Phone"
              value={usePhoneValidation(getRestOfTheFirstNumber) ?? ' '}
              additionalClasses="text-14s h-5"
              error={errors.phone?.message}
              readOnly
              labelClasses="w-[85px]"
              wrapperClass="flex w-full flex-row relative items-center mb-8"
            />
            <Organizations />
          </Form>
        </div>
      )}
    </>
  );
};

export default ProfileForm;
