import React, { useState, useEffect } from 'react';

import Wrapper from '../../../layouts/Wrapper/Wrapper';
import { Logo, Heading, Icon, AnchorLink } from 'components/core';

import AccountVerifyPhone from '../VerifyPhone/Index';
import AccountVerifyCode from '../VerifyCode/Index';

import { AuthResponse, InitAuthResponse } from '../../../../shared/models/services/login.model';
import { isPermitted, useGetUserQuery } from 'services/userApi';
import { useFlags } from 'launchdarkly-react-client-sdk';
import AccountVerifyEmail from '../VerifyEmail/Index';

interface LoginContainerProps {
  headerText?: string;
  setVerificationData?: (data: AuthResponse) => void;
}

const Index: React.FC<LoginContainerProps> = ({ headerText = 'Log In', setVerificationData }) => {
  const [isSendCode, setIsSendCode] = useState(false);
  const [verifiedResponse, setVerifiedResponse] = useState<AuthResponse>(InitAuthResponse);
  const [auth, setAuth] = useState();
  const { data: user } = useGetUserQuery({});
  const getLogedInRoute = isPermitted(user, 'logedin-route');
  const { enableEmailLogin } = useFlags();

  const getVerificationData = (data: AuthResponse) => {
    if (data?.id_token !== '') {
      setIsSendCode(true);
      setVerifiedResponse(data);
    }
  };

  useEffect(() => {
    const idToken: any = localStorage.getItem('id_token');
    if (auth) {
      if (getLogedInRoute) {
        window.location.href = '/payments/current';
      } else {
        window.location.href = '/invoices/submitted';
      }
    } else {
      setAuth(idToken);
    }
  }, [auth]);

  return (
    <>
      {!auth && (
        <Wrapper
          additionalClasses="items-center justify-center"
          isNavigationEnabled={false}
          isBackgroundGray={true}
          isFullScreenHeight={true}
          backgroundClass={'!bg-black-900'}
          pageTitle="Login"
        >
          <div className="flex flex-col items-center justify-center">
            <Logo isInverted additionalClasses="mb-[60px]" />
            <Heading
              type="h2"
              label={headerText}
              additionalClasses="mb-4 text-center"
              colorType="white"
            />
            {!isSendCode ? (
              enableEmailLogin ? (
                <AccountVerifyEmail verificationData={getVerificationData} />
              ) : (
                <AccountVerifyPhone verificationData={getVerificationData} />
              )
            ) : (
              <>
                <AccountVerifyCode verifiedResponse={verifiedResponse} />
                <AnchorLink
                  additionalClasses="flex fixed top-[48px] left-[64px] w-[13px] h-[26px] cursor-pointer"
                  isUnderLine={false}
                  urlPath="/"
                >
                  <Icon iconType="WhiteArrow" />
                </AnchorLink>
              </>
            )}
          </div>
        </Wrapper>
      )}
    </>
  );
};

export default Index;
