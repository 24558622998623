import React, { useState } from 'react';
import moment from 'moment';
import Typho from '../../../../core/OldComponents/Typho/Typho';
import { DateRangeOptions } from '../../../../core/FilterSummaryBlock/DateRangeOptions/DateRangeOptions';
import { SummaryDetailBlock } from '../../../../core/FilterSummaryBlock/SummaryDetailBlock/SummaryDetailBlock';
import { useGetPaymentSummaryQuery } from '../../../../../services/analyticsApi';
import { useCurrencyFormat } from '../../../../../hooks/useCurrencyFormat';

const initialState = {
  status: { in: ['APPROVED', 'INVOICED', 'PAID'] },
  payoutTerm: { active: { equalTo: true } },
};

export const TotalSummaryBlock = () => {
  const [summary, setSummary]: any = useState(initialState);
  const [range, setSelectedRange]: any = useState('ALL');
  const { data } = useGetPaymentSummaryQuery(summary);

  const handeDateRangeChange = (event: any) => {
    const selectedValue = event.target.value;
    const targetVal = selectedValue.replace('total-', '');

    setSelectedRange(targetVal);

    if (targetVal === 'ALL') {
      setSummary(initialState);
    } else {
      const convertToInt = parseInt(targetVal);
      const greaterThan = moment()
        .subtract(convertToInt, 'day')
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .format();
      const lessThan = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).format();

      setSummary({
        ...summary,
        created_at: {
          lessThan: `${lessThan}`,
          greaterThan: `${greaterThan}`,
        },
      });
    }
  };

  return (
    <div className="flex flex-col w-full px-6 pt-6 pb-8 mb-6 rounded-lg shadow-analyticsCard">
      <Typho weight="bold" size="18s" additionalClasses="mb-4 mt-0" type="p">
        {'Total Summary'}
      </Typho>

      <DateRangeOptions
        optionPrefix={'total'}
        onChange={handeDateRangeChange}
        selectedOption={range}
      />

      <div className={`grid grid-cols-2 gap-2`}>
        <SummaryDetailBlock
          label={useCurrencyFormat(data?.invoices?.aggregates?.sum?.amount)}
          subLabel="Total Volume"
        />
        <SummaryDetailBlock
          label={data?.invoices?.totalCount === 0 ? '0' : data?.invoices?.totalCount}
          subLabel="Total Transactions"
        />
        <SummaryDetailBlock
          label={useCurrencyFormat(data?.invoices?.aggregates?.average?.amount)}
          subLabel="Average Transaction Cost"
        />
        <SummaryDetailBlock
          label={data?.invoices?.aggregates?.distinctCount?.account_id}
          subLabel="Total Vendors"
        />
      </div>
    </div>
  );
};
