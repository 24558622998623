import { Grid } from '../Grid/Grid';
import Icon from '../Media/Icon/Icon';

export interface FileHandlerButtonsProps {
  onClickDownload?: () => void;
  onClickPrint?: () => void;
  onClickMail?: () => void;
  isPrintLink?: boolean;
  isDownloadLink?: boolean;
  printURL?: string;
  isPrintLinkVisible?: boolean;
}

export const FileHandlerButtons = ({
  onClickDownload,
  onClickPrint,
  onClickMail,
  isPrintLink,
  isDownloadLink,
  printURL,
  isPrintLinkVisible = true,
}: FileHandlerButtonsProps) => {
  const buttonClasses =
    'flex items-center justify-center w-6 h-6 border-none rounded-lg shadow-none bg-grey-50 text-grey-600';

  return (
    <Grid columnCount={2} columnGap={1} additionalClasses="w-[52px]">
      {isPrintLinkVisible && (
        <>
          {!isPrintLink && (
            <button type="button" onClick={onClickPrint} className={buttonClasses}>
              <Icon iconColor="inherit" iconType="PrintIcon" />
            </button>
          )}
          {isPrintLink && (
            <a href={printURL} className={buttonClasses} target="_blank" rel="noreferrer">
              <Icon iconColor="inherit" iconType="PrintIcon" />
            </a>
          )}
        </>
      )}
      {!isDownloadLink && (
        <button type="button" onClick={onClickDownload} className={buttonClasses}>
          <Icon iconColor="inherit" iconType="DownloadIcon" />
        </button>
      )}

      {isDownloadLink && (
        <a href={printURL} className={buttonClasses} download>
          <Icon iconColor="inherit" iconType="DownloadIcon" />
        </a>
      )}
      <button type="button" onClick={onClickMail} className={'hidden'}>
        <Icon iconColor="inherit" iconType="Sent" />
      </button>
    </Grid>
  );
};
