import { useState, useRef, useEffect } from 'react';

export const useElementPosition = (dependency: any) => {
  const boxRef = useRef<HTMLDivElement>(null);

  // getheight
  const [height, setClientHeight] = useState<number | undefined>();

  // This function calculate X and Y
  const getPosition = () => {
    const modalHeight = boxRef.current?.clientHeight;
    setClientHeight(modalHeight);
  };

  // Get the position of the red box in the beginning
  useEffect(() => {
    getPosition();
  }, dependency);

  // Re-calculate X and Y of the red box when the window gets resized by the user
  useEffect(() => {
    window.addEventListener('resize', getPosition);
  }, []);

  return [height, boxRef];
};
