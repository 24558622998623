import classNames from 'classnames';
import Tooltip from 'components/core/Tooltip/Tooltip';
import { CellContentProps } from 'shared/models/components/base.model';
import { EmptyCellContent } from '../EmptyCellContent/EmptyCellContent';

export const CellContent = ({
  id,
  children,
  additionalClasses,
  isLink,
  linkTarget = '_blank',
  linkHref,
  linkRel = 'noreferrer',
  wrapperClasses,
  emptyCellContent,
  isTooltipEnabled = false,
  tooltipIcon = 'Info',
  tooltipHeading,
  tooltipContent,
  tooltipIconClasses,
  tooltipSubContent,
  isWordWrapped = false,
  isLinkWrapped = false,
  isRemoveCellZindex = false,
  tooltipContainerClass = 'left-[16px] -top-4 rounded-tl-lg',
}: CellContentProps) => {
  const handleLinkClasses = isLinkWrapped ? `truncate ${wrapperClasses}` : 'w-auto';
  const handleContentClasses = [
    'font-normal capitalize text-14s text-grey-900',
    additionalClasses,
    isLink ? 'hover:text-grey-900 underline' : '',
    handleLinkClasses,
  ];

  const commonWrapperClasses = 'inline-flex items-center relative';
  const handleWrapperClasses = [
    isWordWrapped ? 'whitespace-nowrap' : '',
    wrapperClasses,
    isTooltipEnabled ? '!z-[3]' : '',
  ];

  return (
    <div
      className={classNames([
        handleWrapperClasses,
        commonWrapperClasses,
        isRemoveCellZindex ? '' : 'grid-cell-wrapper',
      ])}
    >
      {!isLink && children && <span className={classNames(handleContentClasses)}>{children}</span>}
      {isLink && children && (
        <a
          target={linkTarget}
          href={linkHref}
          rel={linkRel}
          className={classNames(handleContentClasses)}
        >
          {children}
        </a>
      )}
      {isTooltipEnabled && (
        <Tooltip
          tooltipIcon={tooltipIcon}
          tooltipHeading={tooltipHeading}
          additionalClasses="ml-2"
          tooltipIconClasses={tooltipIconClasses}
          tooltipContent={tooltipContent}
          tooltipSubContent={tooltipSubContent}
          tooltipContaineClasses={tooltipContainerClass}
        />
      )}
      {!children && <EmptyCellContent>{emptyCellContent}</EmptyCellContent>}
    </div>
  );
};
