import moment from 'moment';
import { useEffect, useState } from 'react';
import {
  createSearchParams,
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

import { HeaderTabNavigation, SelectBox, ToolBar, VedorsInvoicesBar } from 'components/core';
import DataList from 'components/core/SuperTable/DataList/DataList';
import LoadingSpinner from 'components/core/LoadingSpinner/LoadingSpinner';
import EmptyList from '../../../../../core/EmptyList/EmptyList';
import { CONFIG } from 'shared/configs/config';
import TopPaymentBar from 'components/core/TopPaymentBar/TopPaymentBar';
import { helperApprove } from 'helpers/modules/helperInvoices';
import { handleVendorsInnerTabNavData } from 'constants/TabNavData';
import { getURLParams, useDateRangeSorting } from 'hooks/useDateRangeSorting';
import {
  serverSidePaginationActiveForInvoices,
  useServerSidePagination,
} from 'hooks/useServerSidePagination';
import { isPermitted, useGetUserQuery } from 'services/userApi';
import { useGetPayoutsQuery } from 'services/payoutsApi';
import { useFlags } from 'launchdarkly-react-client-sdk';

const EarningsList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const [vendorsInnerTabNavData] = handleVendorsInnerTabNavData(id);
  const { data: user } = useGetUserQuery({});
  const { dashboardServerSideFilteration } = useFlags();

  const serverSidePaginationActive =
    dashboardServerSideFilteration && serverSidePaginationActiveForInvoices;

  const [tableColumns, handleIconClick, getInvoiceAmount] = helperApprove('VENDOR_INVOICES');
  const [pageSizeOptions, paginationState, previousButtonClick, nextButtonClick, setPageSize]: any =
    useServerSidePagination();
  const [
    rangeSelector,
    defaultSortingText,
    itemSlectedState,
    showDropdown,
    handlerSortingDropDownClick,
    handlerSortingOption,
  ]: any = useDateRangeSorting();

  const [toggleHiddenColumnChanged, setToggleHiddenColumnChanged] = useState(false);
  const [columnList, setColumnList]: any = useState([]);
  const [visibleColumns, setVisibleColumns] = useState([]);
  const [filteredRow, setFilteredRow] = useState([]);
  const [enableSelect, setEnableSelect] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [tlAmount, setTlAmount]: any = useState();
  const [selectedIds, setSelectedIds]: any = useState();
  const [finalQueryParam, setFinalQueryParam]: any = useState();

  const pid = '"' + id + '"';

  const intialQuery = {
    invoiced_at: {
      lessThan: moment()
        .add(1, 'day')
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .format(),
      greaterThan:
        rangeSelector > 0
          ? moment()
              .subtract(rangeSelector, 'day')
              .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
              .format()
          : undefined,
    },
    account_id: { equalTo: pid },
  };

  const invoicesParams = {
    first: serverSidePaginationActive ? paginationState.pageSize : CONFIG.DATA_OVERALL_LIMIT,
    offset: serverSidePaginationActive ? paginationState.pageIndex * paginationState.pageSize : 0,
    orderBy: 'INVOICED_AT_DESC',
    filter: !dashboardServerSideFilteration ? intialQuery : finalQueryParam,
  };

  useEffect(() => {
    setFinalQueryParam(intialQuery);
  }, [defaultSortingText]);

  const onClickFilterApplyButton = (finalQuery: any) => {
    if (finalQuery !== false) {
      const arrangeQuery = { ...intialQuery, ...finalQuery };
      setFinalQueryParam(arrangeQuery);
    } else {
      setFinalQueryParam(intialQuery);
    }
  };

  const paramsForDownloadAllData = {
    first: CONFIG.DATA_OVERALL_LIMIT,
    offset: 0,
    orderBy: 'INVOICED_AT_DESC',
    filter: !dashboardServerSideFilteration ? intialQuery : finalQueryParam,
  };

  useEffect(() => {
    navigate(
      {
        pathname: location.pathname,
        search: `?${createSearchParams(getURLParams(defaultSortingText))}`,
      },
      { replace: true }
    );
  }, [defaultSortingText]);

  /**
   * handle react_hooks
   */
  const { data, isLoading, isError, isSuccess } = useGetPayoutsQuery(invoicesParams);
  const totalCount: number | undefined = data?.payouts.totalCount; // Get total count

  const totalRecords: number = data?.payouts?.totalCount ?? 0;
  const totalPageCount = Math.ceil(totalRecords / paginationState.pageSize);

  useEffect(() => {
    getColumnsList();
  }, [data]);

  const getColumnsList = () => {
    setColumnList(tableColumns);
  };

  const hdColList = [
    ...columnList.filter((item: any) => item.isHidden).map((val: any) => val.accessor),
  ];

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    getInvoiceAmount(selectedRows, setTlAmount, setSelectedIds);
  }, [selectedRows]);

  const handlePaynowOption = () => {
    setEnableSelect(true);
    setSelectedIds(null);
    setSelectedRows([]);
  };

  return (
    <>
      {enableSelect && (
        <TopPaymentBar
          selectedRowsLength={selectedRows?.length}
          setEnableSelect={setEnableSelect}
          totalAmount={tlAmount}
          ids={selectedIds}
        />
      )}

      <HeaderTabNavigation
        tabNavData={vendorsInnerTabNavData}
        additionalWrapperClasses={'mb-3'}
        isAdditionalButtonVisibled={isPermitted(user, 'paynow-option')}
        onButtonClick={handlePaynowOption}
        additionalButtonLabel={'Pay Invoices Now'}
        isAdditionalButtonDisabled={enableSelect || totalCount === 0}
      />

      {isLoading && <LoadingSpinner />}
      {isError && <Navigate to={'/401'} />}

      <ToolBar
        columns={columnList}
        handleIconClick={(item: any) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          handleIconClick(
            item,
            columnList,
            setColumnList,
            setToggleHiddenColumnChanged,
            toggleHiddenColumnChanged
          );
        }}
        visibleColumns={visibleColumns}
        isShowOptionEnabled={isSuccess && totalCount !== 0}
        filteredRow={filteredRow}
        isDownloadCsvButtonEnabled={isSuccess && totalCount !== 0}
        isFilterButtonEnabled
        onClickFilterApplyButton={onClickFilterApplyButton}
        isDownloadCompleteData={true}
        queryParam={paramsForDownloadAllData}
      >
        <SelectBox
          itemClickHandler={handlerSortingOption}
          defaultSelectBoxText={defaultSortingText}
          itemSelected={itemSlectedState}
          dropDownState={showDropdown}
          dropDownClickHandler={handlerSortingDropDownClick}
        />
      </ToolBar>

      {isSuccess &&
        (totalCount !== 0 ? (
          <>
            <VedorsInvoicesBar defaultSortingText={defaultSortingText} data={data} />
            <DataList
              data={data?.payouts?.nodes}
              editNetTerm={true}
              isPagination={true}
              tableType="approve"
              subTabType="innerVendorEarnings"
              tableWrapperType="grid-height-small"
              hiddenColumnList={hdColList}
              toggleHiddenColumnChanged={toggleHiddenColumnChanged}
              setVisibleColumns={setVisibleColumns}
              setSelectedRows={setSelectedRows}
              enableSelect={enableSelect}
              setFilteredRow={setFilteredRow}
              isServerSidePagination={serverSidePaginationActive}
              previousPageClick={previousButtonClick}
              nextPageClick={nextButtonClick}
              setPageSizeValue={setPageSize}
              options={pageSizeOptions}
              totalPageCount={totalPageCount}
              pageSizeVal={paginationState.pageSize}
              pageIndexVal={paginationState.pageIndex}
            />
          </>
        ) : (
          <EmptyList
            topContent={`No data found`}
            subContent={'There are no earnings data found at this time.'}
          />
        ))}
    </>
  );
};

export default EarningsList;
