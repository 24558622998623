import React from 'react';
import classNames from 'classnames';
import { useSortBy, useTable, useFilters, usePagination, useRowSelect } from 'react-table';

import Heading from 'components/core/Heading/Heading';
import EditTermModal from 'components/core/Modals/EditTermModal/EditTermModal';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
function TableGrid({
  columns,
  data,
  hiddenColumns,
  defaultSortBy,
  isPagination,
  onSelectedRowsChange,
  handleLoadingState,
  defaultColumn,
}: any) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    prepareRow,
    selectedFlatRows,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      autoResetFilters: false,
      autoResetSelectedRows: false,
      initialState: {
        pageSize: 10,
        hiddenColumns,
        sortBy: [defaultSortBy],
      },
    },
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect
  );

  let selectedPayoutIds: any[] = [];
  const selectedData: any[] = selectedFlatRows;

  selectedData.forEach(
    (rowVal?: any) =>
      !rowVal.original.isCoupa && (selectedPayoutIds = [...selectedPayoutIds, rowVal.original.id])
  );

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return (
    <>
      <div className="flex w-full h-[64px] mb-4 justify-between items-center">
        <div className="flex flex-row items-center text-black-900">
          <Heading
            label={'Imported Invoices'}
            type="h3"
            colorType="gray"
            additionalClasses="text-2xl !font-bold"
          />
        </div>
        <EditTermModal
          offsetFromBottom={0}
          selectedPayoutIDs={selectedPayoutIds}
          dependencyVariable={rows}
        />
      </div>

      <div className="flex flex-col w-full table-classes">
        <div className="flex flex-row justify-end w-full"></div>
        <div className="opacity-100 w-full mb-8 min-h-[180px] ease-out duration-300">
          <table {...getTableProps()} className="w-[1024px] xl:w-full">
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column, index, header) => {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    const showSearch = column?.showSearch;
                    // Add the sorting props to control sorting. For this example
                    // we can add them into the header props
                    return (
                      <th className="pr-2 border-b border-solid border-black-365" key={index}>
                        <div className={classNames(['flex flex-row items-center h-[50px]'])}>
                          <div className="font-normal text-left text-gray-500 text-16s">
                            {column.render('Header')}
                            {showSearch ? column.render('Filter') : null}
                          </div>
                        </div>
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>

            <tbody {...getTableBodyProps()}>
              {(isPagination ? page : rows)?.map((row: any, i) => {
                // new
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} key={i}>
                    {row.cells.map((cell: any, index: any) => {
                      const tdClasses = cell?.column?.additionalClasses;

                      return (
                        <td
                          {...cell.getCellProps()}
                          className={`border-b border-solid border-gray-365 min-w-[120px] pr-2 ${tdClasses}`}
                          key={index}
                        >
                          <div className="min-h-[74px] flex flex-row items-center justify-start">
                            {cell.render('Cell', { handleLoadingState })}
                          </div>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default TableGrid;
