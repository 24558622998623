import React, { useState } from 'react';
import { PaymentSource } from 'shared/models/services/paymentsources.model';
import { BankAccountCard, ConfirmationModal, Grid, Message } from 'components/core';
import NewMethodVerificationForm from 'components/modules/Balance/Payment/Verification/Verification';
import {
  useDeleteMutation,
  useSetDefaultSourceMutation,
  useVerifySourceMutation,
} from 'services/paymentSourcesApi';
import { useForm } from 'react-hook-form';

interface MethodListProps {
  cardData?: PaymentSource[];
  defaultSourceID?: any;
  editPaymentSource?: any;
  isPaymentEditable?: boolean;
}

const MethodList: React.FC<MethodListProps> = ({
  cardData,
  defaultSourceID,
  editPaymentSource,
  isPaymentEditable,
}) => {
  const messageState: any = {
    meesageContent: '',
    messageHeading: '',
    isVisible: false,
  };

  const [showVerificationModal, setShowVerificationModal] = useState(false);
  const [notifications, setNotifications] = useState(messageState);
  const [methodId, setMethodId] = useState('');
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const [useVerifySourceMutationOp, { error, isError }]: any = useVerifySourceMutation();
  const [useSetDefaultSourceMutationOp] = useSetDefaultSourceMutation();
  const [
    useDeleteMutationOp,
    { error: deleteError, isError: isDeleteError, isLoading: isDeleteLoading },
  ]: any = useDeleteMutation();

  const { register, handleSubmit, control, setValue } = useForm();

  /**
   * handle verification popup modal
   */
  const handleStatusVerification = (id: string) => {
    setNotifications({
      ...notifications,
    });

    setShowVerificationModal(!showVerificationModal);
    setMethodId(id);
  };

  /**
   * Verify the payment method
   */
  const handlePaymentMethodVerification = async (data: any) => {
    const setAmount1: any = parseFloat(data.descriptor_code_1) * 100;
    const setAmount2: any = parseFloat(data.descriptor_code_2) * 100;

    await useVerifySourceMutationOp({
      id: methodId,
      amount1: parseInt(setAmount1),
      amount2: parseInt(setAmount2),
    });

    setValue('descriptor_code_1', null);
    setValue('descriptor_code_2', null);

    setShowVerificationModal(false);
  };

  /**
   * Handle VerificationModalClose
   */
  const handleVerificationPopupClose = () => {
    setValue('descriptor_code_1', null);
    setValue('descriptor_code_2', null);
    setShowVerificationModal(!showVerificationModal);
  };

  /**
   * Set default payment source
   */
  const updateDefaultAccount = async (id: string) => {
    await useSetDefaultSourceMutationOp({ id: id });
  };

  /**
   * Remove the payment method
   */
  const deletePaymentSource = (id: string) => {
    setMethodId(id);
    setConfirmationModalOpen(true);
  };

  const deletePaymentSourceCancel = () => {
    setMethodId('');
    setConfirmationModalOpen(false);
  };

  const deletePaymentSourceConfirm = async () => {
    await useDeleteMutationOp({ id: methodId });
    deletePaymentSourceCancel();
  };

  return (
    <>
      {((isError && error.status === 500) || (isDeleteError && deleteError.status === 500)) && (
        <Message type={'error'} title={'Payment method verification'}>
          {isDeleteError ? deleteError?.data?.error : error?.data?.message}
        </Message>
      )}

      <Grid columnCount={3} columnGap={4} additionalClasses={'mb-6'}>
        {cardData
          ?.filter((value) => value.object === 'bank_account')
          .map((card: any, index) => {
            // eslint-disable-next-line @typescript-eslint/naming-convention,react/destructuring-assignment
            const { routing_number, account_holder_name, bank_name, id, status }: any = card;

            return (
              <React.Fragment key={card.id}>
                <BankAccountCard
                  title={'ACH Payment'}
                  isDefault={card.id === defaultSourceID ? true : false}
                  valueBankName={bank_name}
                  valueAccountHolder={account_holder_name}
                  valueRouting={routing_number}
                  isPaymentCard
                  valueStatus={status}
                  handleStatusVerification={() => handleStatusVerification(id)}
                  removeAccount={() => deletePaymentSource(id)}
                  setAsDefault={() => updateDefaultAccount(id)}
                  editAccount={() => editPaymentSource(card)}
                  lastFourDigits={card.last4}
                  isPaymentEditable={isPaymentEditable}
                  valueLabel={card.metadata.label}
                  valueAccountHolderType={card.account_holder_type}
                  isDefaultOptionAvailable={false}
                />
              </React.Fragment>
            );
          })}
      </Grid>

      {confirmationModalOpen && (
        <ConfirmationModal
          handleSubmitEvent={deletePaymentSourceConfirm}
          handleBackEvent={deletePaymentSourceCancel}
          mainContent={`Are you sure you want to delete this payment method?`}
          isIconLoad={isDeleteLoading}
        />
      )}

      <NewMethodVerificationForm
        verificationPopUpClose={handleVerificationPopupClose}
        onSubmitVerifyPaymentMethod={handlePaymentMethodVerification}
        modalState={showVerificationModal}
        control={control}
        handleSubmit={handleSubmit}
        register={register}
      />
    </>
  );
};

export default MethodList;
