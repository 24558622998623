import React, { useEffect, useState } from 'react';
import chroma from 'chroma-js';

import Select, { StylesConfig } from 'react-select';
import {
  ColourOption,
  handleListItemsArray,
  setAvailableCheckStatusOptions,
  setAvailableStatusOptions,
} from '../data';
import { ValueEditorProps } from 'react-querybuilder';

const colourStyles: StylesConfig<ColourOption, true> = {
  control: (styles) => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? data.color
        : isFocused
        ? color.alpha(0.1).css()
        : undefined,
      color: isDisabled
        ? '#ccc'
        : isSelected
        ? chroma.contrast(color, 'white') > 2
          ? 'white'
          : 'black'
        : data.color,
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled
          ? isSelected
            ? data.color
            : color.alpha(0.3).css()
          : undefined,
      },
    };
  },
  multiValue: (styles, { data }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: color.alpha(0.1).css(),
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data.color,
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    ':hover': {
      backgroundColor: data.color,
      color: 'white',
    },
  }),
};

export const StatusSelector = (props: ValueEditorProps) => {
  const [selectedValue, setSelectedValue]: any = useState([]);

  useEffect(() => {
    const getStorageSelectedPropertyValues = localStorage.getItem('filterStatusRouteItems') ?? '';
    if (getStorageSelectedPropertyValues !== undefined && getStorageSelectedPropertyValues !== '') {
      const parseStringedObj: any = JSON.parse(getStorageSelectedPropertyValues);
      setSelectedValue(parseStringedObj);
      const [listOfSelectedItemsArray] = handleListItemsArray(parseStringedObj, false);
      props.handleOnChange(listOfSelectedItemsArray);
    }
  }, []);

  return (
    <div className="filter-custom-select">
      <Select
        closeMenuOnSelect={false}
        isMulti
        options={setAvailableStatusOptions}
        styles={colourStyles}
        className="react-multi-select-container"
        classNamePrefix="react-multi-select"
        value={selectedValue}
        onChange={(e) => {
          const selectedValues: any = e;
          const [listOfSelectedItemsArray] = handleListItemsArray(selectedValues, false);
          props.handleOnChange(listOfSelectedItemsArray);
          setSelectedValue(selectedValues);
          localStorage.setItem('filterStatusRouteItems', JSON.stringify(selectedValues));
        }}
      />
    </div>
  );
};

export const CheckStatusSelector = (props: ValueEditorProps) => {
  const [selectedValue, setSelectedValue]: any = useState([]);

  useEffect(() => {
    const getStorageSelectedPropertyValues =
      localStorage.getItem('filterCheckStatusRouteItems') ?? '';
    if (getStorageSelectedPropertyValues !== undefined && getStorageSelectedPropertyValues !== '') {
      const parseStringedObj: any = JSON.parse(getStorageSelectedPropertyValues);
      setSelectedValue(parseStringedObj);
      const [listOfSelectedItemsArray] = handleListItemsArray(parseStringedObj, false);
      props.handleOnChange(listOfSelectedItemsArray);
    }
  }, []);

  return (
    <div className="filter-custom-select">
      <Select
        closeMenuOnSelect={false}
        isMulti
        options={setAvailableCheckStatusOptions}
        styles={colourStyles}
        className="react-multi-select-container"
        classNamePrefix="react-multi-select"
        value={selectedValue}
        onChange={(e) => {
          const selectedValues: any = e;
          const [listOfSelectedItemsArray] = handleListItemsArray(selectedValues, false);
          props.handleOnChange(listOfSelectedItemsArray);
          setSelectedValue(selectedValues);
          localStorage.setItem('filterCheckStatusRouteItems', JSON.stringify(selectedValues));
        }}
      />
    </div>
  );
};
