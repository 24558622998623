import React from 'react';
import Card from '../OldComponents/Card/Card';
import Typho from '../OldComponents/Typho/Typho';

interface EmptyListProps {
  /**
   * Set top content
   */
  topContent?: string;
  /**
   * Set subcontent, display after the top content
   */
  subContent?: string;
  /**
   * Set the additional classes
   */
  emptyListAdditionalClasses?: string;
}

/**
 * Primary UI component for user interaction
 */
const EmptyList: React.FC<EmptyListProps> = ({
  topContent = 'No Data found',
  subContent = 'There are no data to render at this time.',
  emptyListAdditionalClasses,
}) => {
  const cardContainerClasses =
    'border-gray-110 flex-col items-center justify-center mb-8 mt-3 h-160 ease-out duration-300'; // Set container classes
  return (
    <Card
      cardType="lightGrayHalf"
      additionalClasses={cardContainerClasses + ' ' + emptyListAdditionalClasses}
    >
      <Typho size="14s" type="p" color="gray720" weight="bold" additionalClasses="mb-1">
        {topContent}
      </Typho>
      <Typho
        size="14s"
        type="p"
        color="gray720"
        weight="normal"
        additionalClasses="max-w-[234px] text-center"
      >
        {subContent}
      </Typho>
    </Card>
  );
};

export default EmptyList;
