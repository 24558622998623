import { Button } from 'components/core/Button/Button';
import Logo from 'components/core/Logo/Logo';
import Icon from 'components/core/Media/Icon/Icon';

export interface WireTransferProps {
  onClose?: () => void;
}

export const WireTransfer = ({ onClose }: WireTransferProps) => {
  const tbodyDataTitleClasses = 'text-grey-400 font-semibold text-12s min-w-[338px] pb-3 align-top';
  const tbodyDataContentClasses =
    'text-grey-700 font-medium text-12s max-w-[130px] min-w-[130px] pb-3 align-top';

  return (
    <div className="w-full px-[72px] flex flex-col">
      <table cellPadding={0} className="w-full border-collapse border-none" id="divToPrint">
        <thead>
          <tr>
            <th colSpan={2}>
              <Logo isDarkSamll />
            </th>
          </tr>
          <tr>
            <th
              colSpan={2}
              className="font-semibold text-24s text-grey-900 pt-[14px] pb-2 text-left"
            >
              PayUp Wire Transfer Details
            </th>
          </tr>
          <tr>
            <th
              colSpan={2}
              className="pb-4 font-normal text-left border-b border-solid text-12s text-grey-600 border-grey-100"
            >
              Please use this details to send wires transfers to PayUp account
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={2} className="pt-4 pb-3 font-semibold text-12s text-primary-500">
              Beneficiary
            </td>
          </tr>
          <tr>
            <td className={tbodyDataTitleClasses}>Beneficiary Name</td>
            <td className={tbodyDataContentClasses}>PayUp Inc</td>
          </tr>
          <tr>
            <td className={tbodyDataTitleClasses}>Account Number</td>
            <td className={tbodyDataContentClasses}>0000000</td>
          </tr>
          <tr>
            <td className={tbodyDataTitleClasses}>Account Type</td>
            <td className={tbodyDataContentClasses}>Cheking</td>
          </tr>
          <tr>
            <td className={`${tbodyDataTitleClasses} !pb-4`}>Beneficiary Address</td>
            <td className={`${tbodyDataContentClasses} !pb-4`}>
              6300 Bridge Point Parkway, building 2, St 500, Austin, TX 78730
            </td>
          </tr>
          {/***/}
          <tr>
            <td
              colSpan={2}
              className="pt-4 pb-3 font-semibold border-t border-solid border-grey-100 text-12s text-primary-500"
            >
              Receiving Bank Details
            </td>
          </tr>
          <tr>
            <td className={tbodyDataTitleClasses}>ABA routing Number</td>
            <td className={tbodyDataContentClasses}>00000000</td>
          </tr>
          <tr>
            <td className={tbodyDataTitleClasses}>Bank Name</td>
            <td className={tbodyDataContentClasses}>Name</td>
          </tr>
          <tr>
            <td className={tbodyDataTitleClasses}>Bank Address</td>
            <td className={tbodyDataContentClasses}>
              6300 Bridge Point Parkway, Building 2, St 500, Austin, TX 78730
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={2} className="pb-4 pt-[21vh] max-h-60">
              <div className="flex w-full pl-[18px] relative">
                <Icon
                  iconColor="inherit"
                  iconType="QuestionIcon"
                  additionalClasses="absolute top-[2px] left-0"
                />
                <p
                  className="m-0 font-normal text-12s text-grey-600"
                  style={{ lineHeight: '16px' }}
                >
                  {'If you have any questions, contact us for support at '}
                  <a
                    href="mailto:support@payup.com"
                    className="font-medium no-underline text-primary-500"
                  >
                    support@payup.com
                  </a>
                </p>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
      <div className="flex justify-center w-full pt-4 border-t border-solid border-grey-100 ">
        <Button
          type="button"
          variants="small"
          buttonLabel={'Done'}
          additionalClasses="w-[140px] ml-auto mr-auto"
          onClick={onClose}
        />
      </div>
    </div>
  );
};
