/**
 * Invoices grid column and header cell widths
 */
export const Approvals = {
  ColomnWidth: {
    invoiced_at: 'w-[70px]',
    estimated_approved_at: 'w-[120px]',
    status: 'w-[131px]',
    check_image_url: 'w-[120px]',
    approved_at: 'w-[80px]',
    number: 'w-[70px]',
    batch_number: 'w-[100px]',
    vendor: 'w-[130px]',
    description: 'w-[140px]',
    invoice_numer: 'w-[100px]',
    property_name: 'w-[185px]',
    payout_term_id: 'w-[80px]',
    payout_due_at: 'w-[110px]',
    paid_at: 'w-[80px]',
    paid_by: 'w-[95px]',
    check_date: 'w-[100px]',
    customer_payment_due_at: 'w-[100px]',
    customer_paid_at: 'w-[100px]',
    estimated_arrived_on: 'w-[100px]',
    payUp_ar_mode: 'w-[80px]',
    payUp_paid_mode: 'w-[105px]',
    pay_ref: 'w-[80px]',
    amount: 'w-[80px]',
    payout_amount: 'w-[80px]',
    processing_fee_amount: 'w-[100px]',
    coupa_draw_account: 'w-[120px]',
    payout_term_amount: 'w-[80px]',
    check_status: 'w-[135px]',
  },
  HeaderCellWidth: {
    invoiced_at: 'w-[100px]',
    estimated_approved_at: 'w-[125px]',
    status: 'w-[141px]',
    check_image_url: 'w-[135px]',
    approved_at: 'w-[105px]',
    number: 'w-[95px]',
    batch_number: 'w-[135px]',
    vendor: 'w-[150px]',
    description: 'w-[170px]',
    invoice_numer: 'w-[130px]',
    property_name: 'w-[100px]',
    payout_term_id: 'w-[95px]',
    payout_due_at: 'w-[110px]',
    paid_at: 'w-[95px]',
    paid_by: 'w-[95px]',
    check_date: 'w-[115px]',
    customer_payment_due_at: 'w-[100px]',
    customer_paid_at: 'w-[115px]',
    estimated_arrived_on: 'w-[115px]',
    payUp_ar_mode: 'w-[95px]',
    payUp_paid_mode: 'w-[105px]',
    pay_ref: 'w-[80px]',
    amount: 'w-[100px]',
    payout_amount: 'w-[100px]',
    processing_fee_amount: 'w-[120px]',
    coupa_draw_account: 'w-[120px]',
    payout_term_amount: 'w-[100px]',
    check_status: 'w-[155px]',
  },
  HeaderInnerDivWidth: {
    status: 'w-[106px]',
    amount: 'w-[52px]',
    payout_amount: 'w-[54px]',
    processing_fee_amount: 'w-[82px]',
    payout_term_amount: 'w-[62px]',
    check_image_url: 'w-[180px]',
  },
};

/**
 * Vendors grid column and header cell widths
 */
export const VendorsColomnWidth = {
  ColomnWidth: {
    business_name: 'w-[220px]',
    status: 'w-[80px]',
    default_payment_term_id: 'w-[150px]',
    phone: 'w-[130px]',
    email: 'w-[210px]',
    language: 'w-[80px]',
    overall_earning: 'w-[155px]',
    processing_fee_amount: 'w-[175px]',
    payout_sceduled: 'w-[165px]',
    created_at: 'w-[105px]',
    code: 'w-[100px]',
    modified_by: 'w-[180px]',
  },
  HeaderCellWidth: {
    business_name: 'w-[260px]',
    status: 'w-[135px] pl-[14px]',
    default_payment_term_id: 'w-[160px]',
    phone: 'w-[140px]',
    email: 'w-[220px]',
    language: 'w-[90px]',
    overall_earning: 'w-[170px]',
    processing_fee_amount: 'w-[190px]',
    payout_sceduled: 'w-[180px]',
    created_at: 'w-[150px]',
    code: 'w-[150px]',
    modified_by: 'w-[230px]',
  },
};

/**
 * Properties grid column and header cell widths
 */
export const Properties = {
  ColomnWidth: {
    name: 'min-w-[200px]',
    nameFilter: 'w-[200px]',
    innerName: 'min-w-[200px]',
    is_delinquent: 'w-[100px]',
    payouts_by_property_id: 'w-[60px]',
    paid_payouts: 'w-[103px]',
    customer_paid_payouts: 'w-[75px]',
    unpaid_payouts: 'w-[80px]',
    code: 'w-[70px]',
    payment_term_id: 'w-[120px]',
    fee: 'w-[248px]',
    by_property: 'w-[200px]',
    updated_by: 'w-[130px]',
    default_bank: 'w-[140px]',
    amount_in_transition: 'w-[90px]',
  },
  HeaderCellWidth: {
    nameFilter: 'w-[220px]',
    innerName: 'min-w-[200px]',
    is_delinquent: 'w-[130px]',
    payouts_by_property_id: 'w-[110px]',
    paid_payouts: 'w-[123px]',
    customer_paid_payouts: 'w-[110px]',
    unpaid_payouts: 'w-[110px]',
    code: 'w-[50px]',
    updated_by: 'w-[160px]',
    default_bank: 'w-[170px]',
    amount_in_transition: 'w-[110px]',
  },
  HeaderInnerDivWidth: {
    nameFilter: 'w-[300px]',
    paid_payouts: 'w-[71px]',
    customer_paid_payouts: 'w-[60px]',
    customer_paid_payouts_v2: 'w-[84px]',
    unpaid_payouts: 'w-[83px]',
    updated_by: 'w-[160px]',
    default_bank: 'w-[130px]',
    amount_in_transition: 'w-[70px]',
  },
};

/**
 * Transactions grid column and header cell widths
 */
export const Transactions = {
  ColomnWidth: {
    vendor: 'w-[160px]',
    check_image_url: 'w-[150px]',
    property_name: 'w-[230px]',
    amount: 'w-[80px]',
    paid_amount: 'w-[80px]',
    processing_fee_amount: 'w-[100px]',
    estimated_processing_fee_amount: 'w-[110px]',
    paid_at: 'w-[115px]',
    batch_number: 'w-[155px]',
    created_at: 'w-[115px]',
    updated_at: 'w-[115px]',
    expected_settlement_date: 'w-[125px]',
    pay_mode: 'w-[100px]',
    status: 'w-[105px]',
  },
  HeaderCellWidth: {
    vendor: 'w-[190px]',
    check_image_url: 'w-[190px]',
    property_name: 'w-[260px]',
    amount: 'w-[100px]',
    paid_amount: 'w-[100px]',
    processing_fee_amount: 'w-[120px]',
    estimated_processing_fee_amount: 'w-[145px]',
    paid_at: 'w-[155px]',
    batch_number: 'w-[195px]',
    created_at: 'w-[155px]',
    updated_at: 'w-[155px]',
    expected_settlement_date: 'w-[165px]',
    pay_mode: 'w-[130px]',
    status: 'w-[135px]',
  },
  HeaderInnerDivWidth: {
    amount: 'w-[52px]',
    paid_amount: 'w-[54px]',
    processing_fee_amount: 'w-[80px]',
    estimated_processing_fee_amount: 'w-[105px]',
  },
};
