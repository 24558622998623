import React from 'react';

import Wrapper from '../../layouts/Wrapper/Wrapper';

import { TotalSummaryBlock } from './SummaryBlock/TotalSummaryBlock/TotalSummaryBlock';
import { FinancialSummaryBlock } from './SummaryBlock/FinancialSummaryBlock/FinancialSummaryBlock';
import { PaymentSummaryBlock } from './SummaryBlock/PaymentSummaryBlock/PaymentSummaryBlock';
import { FilteredPaymentSummaryBlock } from './SummaryBlock/FilteredPaymentSummaryBlock/FilteredPaymentSummaryBlock';
import Typho from '../../core/OldComponents/Typho/Typho';

const HomeContainer = () => {
  return (
    <Wrapper pageTitle="Dashboard">
      <div className="container">
        <div className="flex flex-wrap w-full pt-8 pr-8 lg:pr-0 xl:flex-nowrap">
          <div className="w-full xl:w-1/2 lg:pr-6 lg:max-w-[870px] xl:max-w-[400px] lg:min-w-[400px]">
            <TotalSummaryBlock />
            <FinancialSummaryBlock />
          </div>
          <div className="w-full lg:max-w-[870px] lg:pr-6">
            <div className="flex flex-col w-full px-6 pt-6 mb-8 rounded-lg shadow-analyticsCard pb-7">
              <Typho
                weight="bold"
                size="18s"
                additionalClasses="mb-4 mt-0 pb-4 border-b border-solid border-gray-220"
                type="p"
              >
                {'Payments Distribution'}
              </Typho>
              <PaymentSummaryBlock />
              <FilteredPaymentSummaryBlock />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default HomeContainer;
