export const handleFontTypes = (_type: string | undefined) => {
  switch (_type) {
    // h1 font types __
    case 'h1':
      return 'text-12s font-medium';
    case 'h1Bold':
      return 'text-12s font-semibold';
    case 'h1ExtraBold':
      return 'text-12s font-extrabold';
    // h2 font types __
    case 'h2':
      return 'text-14s font-medium';
    case 'h2Bold':
      return 'text-14s font-semibold';
    case 'h2ExtraBold':
      return 'text-14s font-extrabold';
    // h6 font types __
    case 'h6':
      return 'text-28s font-medium';
    case 'h6Bold':
      return 'text-28s font-semibold';
    case 'h6ExtraBold':
      return 'text-28s font-extrabold';
    // h10 font types __
    case 'h10':
      return 'text-10s font-medium';
    case 'h10Bold':
      return 'text-10s font-semibold';
    case 'h10ExtraBold':
      return 'text-10s font-extrabold';
    default:
      break;
  }
};
