import React, { useState } from 'react';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';

import {
  useGetPropertyPaymentSorcesQuery,
  useGetSetupIntentsQuery,
} from 'services/paymentSourcesApi';

import { Button, Icon, LoadingSpinner, Typhography } from 'components/core';
import Modal from 'components/core/Modals/Modal/Modal';
import { PropertyBankDetails } from 'components/modules/Properties/Details/Payment/BankDetails/BankDetails';
import IntentList from '../../../Common/Payment/MethodList/IntentList';
import { PropertyBankSetup } from './BankSetup/BankSetup';
import { PropertyBankTypeSelection } from './BankType/BankType';
import { useGetPropertyQuery } from '../../../../../services/properties';

export interface IModalVisibilityStates {
  step1?: boolean; //Bank type selection screen
  step2?: boolean; //Bank Details or Fin-connection selection step
  step3?: boolean; //Bank Details manual adding step
}

export const initialModalVisibilityStates: IModalVisibilityStates = {
  step1: true, //Bank type selection screen
  step2: false, //Bank Details or Fin-connection selection step
  step3: false, //Bank Details manual adding step
};

const Payment = ({ isPaymentEditAccess = true }) => {
  const { id } = useParams();
  const [showACHForm, setShowACHForm] = useState(false);
  const [editPaymentmethod, setEditPaymentmethod] = useState(false);
  const [methodDetails, setMethodDetails] = useState('');

  const [stepsVisibility, setStepsVisibility] = useState<IModalVisibilityStates>(
    initialModalVisibilityStates
  );
  const [selectedLabelValue, setSelectedLabelValue] = useState('');

  const handleNewPaymentToExistingList = async () => {
    setShowACHForm(true);
    setStepsVisibility(initialModalVisibilityStates);
  };

  /**
   * Handle VerificationModalClose
   */
  const {
    data: paymentData,
    isLoading: paymentLoading,
    isSuccess: paymentSuccess,
    isFetching: paymentFetching,
  } = useGetPropertyPaymentSorcesQuery(id);

  const {
    data: intents,
    isLoading: intentsLoading,
    isSuccess: intentsSuccess,
  } = useGetSetupIntentsQuery(id, { pollingInterval: 3000 });

  /**
   * Handle Back button
   */
  const handleCloseEvent = () => {
    setShowACHForm(false);
    setEditPaymentmethod(false);
    setStepsVisibility(initialModalVisibilityStates);
  };

  /**
   * Get default payment source
   */
  const { data } = useGetPropertyQuery({ id: id });
  /**
   * Edit the payment method
   */
  const editPaymentSource = async (card: string) => {
    setMethodDetails(card);
    setEditPaymentmethod(true);
  };

  /**
   * Step 2 related changes
   */
  const manualBankSetupEvent = () => {
    setStepsVisibility({
      step1: false,
      step2: false,
      step3: true,
    });
  };

  /**
   * Handle Back Event
   */
  const handleBackClickEvent = () => {
    if (stepsVisibility.step2) {
      setStepsVisibility(initialModalVisibilityStates);
    } else if (stepsVisibility.step3) {
      setStepsVisibility({
        step1: false,
        step2: true,
        step3: false,
      });
    }
  };

  return (
    <>
      {(paymentLoading || paymentFetching || intentsLoading) && <LoadingSpinner />}

      {paymentSuccess && intentsSuccess && (
        <>
          <div className="flex flex-col w-full mb-4 mt-6 pt-6 max-w-[1360px] border-t border-solid border-grey-100">
            <div className="flex flex-col w-full mb-4">
              <div className="flex items-center justify-between">
                <Typhography
                  type="h2"
                  color="text-grey-900"
                  component="p"
                  additionalClasses="uppercase"
                >
                  Your Payment Methods
                </Typhography>
                {isPaymentEditAccess && (
                  <Button
                    variants={'extraSmallOutlined'}
                    onClick={handleNewPaymentToExistingList}
                    additionalClasses={`ml-[6px]`}
                    buttonLabel={'Link Account'}
                    isIconEnabled={true}
                    iconColor={'inherit'}
                    iconType={'AddIcon'}
                  />
                )}
              </div>
              {paymentData.length === 0 && intents.length === 0 && (
                <Typhography type="h2" color="text-grey-700" component="p">
                  You don't have any payment methods yet.
                </Typhography>
              )}
            </div>
            <IntentList
              propertyId={id}
              cardData={intents}
              defaultSourceID={data?.default_payment_method_id}
              editPaymentSource={editPaymentSource}
              isPaymentEditable={false}
              isPaymentEditAccess={isPaymentEditAccess}
            />
          </div>

          {(showACHForm || editPaymentmethod) && (
            <>
              <Modal
                isOpen={true}
                onClose={handleCloseEvent}
                isLeftSpaceEnabled={false}
                isContainerVerticalAligned={false}
                modalAdditionalClasses={classNames([
                  'h-[610px] pt-12 pb-8 rounded-xl bg-white flex-col !w-[360px] px-6 mt-20',
                ])}
              >
                {!stepsVisibility.step1 && !editPaymentmethod && (
                  <div
                    className="absolute cursor-pointer top-5 left-5 text-grey-600 "
                    onClick={handleBackClickEvent}
                  >
                    <Icon iconColor="inherit" iconType="Prev" />
                  </div>
                )}

                <div
                  className="absolute cursor-pointer top-5 right-5 text-grey-600 "
                  onClick={handleCloseEvent}
                >
                  <Icon iconColor="inherit" iconType="CloseIcon" />
                </div>

                {stepsVisibility.step1 && !editPaymentmethod && (
                  <PropertyBankTypeSelection
                    setStepsVisibility={setStepsVisibility}
                    setSelectedLabelValue={setSelectedLabelValue}
                  />
                )}

                {stepsVisibility.step2 && !editPaymentmethod && (
                  <>
                    <PropertyBankSetup
                      onClick={manualBankSetupEvent}
                      onClose={handleCloseEvent}
                      bankType={selectedLabelValue}
                    />
                  </>
                )}

                {(stepsVisibility.step3 || editPaymentmethod) && (
                  <PropertyBankDetails
                    onClose={handleCloseEvent}
                    propertyID={id}
                    isEdit={editPaymentmethod}
                    paymentData={methodDetails}
                    labelValue={selectedLabelValue}
                  />
                )}
              </Modal>
            </>
          )}
        </>
      )}
    </>
  );
};
export default Payment;
