// import React, { useEffect, useState } from 'react';
import Icon from '../../Media/Icon/Icon';
// import { useGetAllPaymentTermsQuery } from '../../../../services/analyticsApi';
// import { reOrderingTheTerms } from '../../../../helpers/arrayFormating';
import { getTermName } from '../../../../helpers/arrayFormating';

export const NetTermOptions = ({
  onChange,
  terms,
  onSelectAll,
  // setAllTerms,
  isAllTermChecked,
  termsData,
}: any) => {
  // const { data, isSuccess } = useGetAllPaymentTermsQuery(null);
  // const [termsData, setTermsData]: any = useState();

  // useEffect(() => {
  //   if (isSuccess) {
  //     const getData = data;
  //     const dataArray = getData ? getData?.paymentTerms?.nodes : [];
  //     const sorted = reOrderingTheTerms(dataArray);
  //     setAllTerms(sorted);
  //     setTermsData(sorted);
  //   }
  // }, [data]);

  return (
    <>
      {termsData &&
        termsData?.map((term: any) => {
          return (
            term.active &&
            term.id !== 'net30' &&
            term.category === 'NEW' && (
              <label
                htmlFor={term.id}
                className="w-1/4 pr-4 mb-2 uppercase termOptionTag whitespace-nowrap min-w-[127px]"
                key={term.id}
              >
                <input
                  type="checkbox"
                  id={term.id}
                  name={term.id}
                  value={term.id}
                  onChange={onChange}
                  //defaultChecked={terms.includes(term.id)}
                  checked={terms.includes(term.id)}
                />
                <span className="text-wrapper">
                  <span className="icon-wrapper">
                    <Icon iconType="OkIcon" iconColor="inherit" />
                  </span>
                  {getTermName(term.id)}
                </span>
              </label>
            )
          );
        })}

      {termsData &&
        termsData?.map((term: any) => {
          return (
            term.active &&
            term.id !== 'old_net30' &&
            term.category === 'OLD' && (
              <label
                htmlFor={term.id}
                className="w-1/4 pr-4 mb-2 uppercase termOptionTag whitespace-nowrap min-w-[127px]"
                key={term.id}
              >
                <input
                  type="checkbox"
                  id={term.id}
                  name={term.id}
                  value={term.id}
                  onChange={onChange}
                  //defaultChecked={terms.includes(term.id)}
                  checked={terms.includes(term.id)}
                />
                <span className="text-wrapper">
                  <span className="icon-wrapper">
                    <Icon iconType="OkIcon" iconColor="inherit" />
                  </span>
                  {getTermName(term.id)}
                </span>
              </label>
            )
          );
        })}
      {/* {termsData && (
        <label
          htmlFor="ALLTERMS"
          className="w-1/4 pr-4 mb-2 uppercase termOptionTag whitespace-nowrap min-w-[127px]"
          key="ALLTERMS"
        >
          <input
            type="checkbox"
            id="ALLTERMS"
            name="ALLTERMS"
            value="ALLTERMS"
            onChange={onSelectAll}
            //defaultChecked={true}
            checked={isAllTermChecked}
          />
          <span className="text-wrapper">
            <span className="icon-wrapper">
              <Icon iconType="OkIcon" iconColor="inherit" />
            </span>
            ALL TERMS
          </span>
        </label>
      )} */}
    </>
  );
};
