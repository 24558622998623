import React from 'react';
import BalanceContainer from '../../../components/modules/Balance/Days/Current/Index';
import CurrentWeekContainer from 'components/modules/Balance/Weeks/Current/Index';

/**
 * Primary UI component for user interaction
 */
const Balance = () => {
  const getLocalStorageSettingItem = localStorage.getItem('balance_settings');
  return (
    <>
      {getLocalStorageSettingItem && getLocalStorageSettingItem === 'week' ? (
        <CurrentWeekContainer />
      ) : (
        <BalanceContainer />
      )}
    </>
  );
};

export default Balance;
