import React from 'react';
import Card from '../../../../../core/OldComponents/Card/Card';
import Heading from '../../../../../core/Heading/Heading';
import Typho from '../../../../../core/OldComponents/Typho/Typho';
import AnchorLink from '../../../../../core/OldComponents/AnchorLink/AnchorLink';
import { useCurrencyFormat } from '../../../../../../hooks/useCurrencyFormat';

const SuccessDetails = () => {
  const paymentSummary = localStorage.getItem('paymentSummary');
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const paymentSummaryArray = JSON.parse(paymentSummary);
  const paymentPastBalance = paymentSummaryArray.paymentPasbalance / 100;
  const paymentTotal = paymentSummaryArray.paymentTotal / 100;
  const paymentStatus = paymentSummaryArray.paymentStatus;

  return (
    <Card cardType="whiteBorder" additionalClasses="flex-col pt-4 pl-4 pr-4 pb-3">
      <Heading
        type="h2"
        colorType="dark"
        additionalClasses="mb-2"
        label={paymentStatus === 'pending' ? 'Payment Status!' : 'Success!'}
      />
      <Typho size="14s" color="black" weight="medium" additionalClasses="mb-6" type="p">
        {paymentStatus === 'pending'
          ? 'Your payment is being processed.'
          : 'Your payment was successful.'}
      </Typho>
      {/** Summary  */}
      <div className="flex flex-col w-full">
        <div className="flex flex-row justify-between w-full mb-2">
          <div className="w-1/2 font-normal text-left text-14s text-black-900">Date</div>
          <div className="w-1/2 font-normal text-right text-14s text-black-900">
            {paymentSummaryArray.paymentDate}
          </div>
        </div>
        <div className="flex flex-row justify-between w-full mb-2">
          <div className="w-1/2 font-normal text-left text-14s text-black-900">Method</div>
          <div className="w-1/2 font-normal text-right text-14s text-black-900">
            {paymentSummaryArray.paymentMethod}
          </div>
        </div>
        <div className="flex flex-row justify-between w-full mb-2">
          <div className="w-1/2 font-normal text-left text-14s text-black-900">Subtotal</div>
          <div className="w-1/2 font-normal text-right text-14s text-black-900">
            {`${useCurrencyFormat(paymentPastBalance)}`}
          </div>
        </div>
        <div className="flex flex-row justify-between w-full mb-2">
          <div className="w-1/2 font-normal text-left text-14s text-black-900">Fees</div>
          <div className="w-1/2 font-normal text-right text-14s text-black-900">
            {paymentSummaryArray.paymentFee}
          </div>
        </div>
        <div className="flex flex-row justify-between w-full mb-2">
          <div className="w-1/2 font-bold text-left text-16s text-black-900">Total</div>
          <div className="w-1/2 font-bold text-right text-16s text-black-900">
            {`${useCurrencyFormat(paymentTotal)}`}
          </div>
        </div>
      </div>
      {/** Back button */}
      <AnchorLink
        linkColor="black-900"
        isUnderLine={true}
        linkFontSize="14s"
        urlPath="/balance/current"
        additionalClasses="mb-1"
      >
        Back to Balance
      </AnchorLink>
    </Card>
  );
};

export default SuccessDetails;
