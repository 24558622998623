import { DetailsDataRowProps } from 'shared/models/components/base.model';
import { NetXChips } from '../../Chips/NetXChips/NetXChips';
import { StatusDropDown } from '../../StatusDropDown/StatusDropDown';
import { EmptyCellContent } from '../../SuperTable/CellElements/EmptyCellContent/EmptyCellContent';
import { Typhography } from '../../Typhography/Typhography';
import { StatusTag } from 'components/core/Chips/StatusTag/StatusTag';
import classNames from 'classnames';
import { EditPayModesDropDown } from 'components/core/EditPayModesDropDown/EditPayModesDropDown';

export const DetailsDataRow = ({
  title,
  value,
  isTerm = false,
  isStatus = false,
  isShowStatusWithDropDown = true,
  additionalClasses = '',
  onStatusChange,
  dropDownData,
  additionalClassesDropdwown = '!w-[85px] !ml-2',
  additionalDropDownContainerClasses,
  additionalDropDownItemClasses,
  additionalSpaceSpanClasses,
  iconContainerClasses,
  rowWrapperClasses = 'mb-4',
  isPayMode,
  vendorId,
  isPayModeEditable,
  vendorData,
}: DetailsDataRowProps) => {
  return (
    <div
      className={classNames([
        'flex flex-row items-center justify-between w-full ',
        rowWrapperClasses,
      ])}
    >
      <Typhography
        component="div"
        type="h2"
        color="text-grey-700 w-[147px] min-w-[147px] !font-normal"
      >
        {title}
      </Typhography>
      <div>
        {value ? (
          <div>
            {isPayMode && (
              <EditPayModesDropDown
                defaultValue={value}
                vendorID={vendorId}
                isPayModeEditable={isPayModeEditable}
                vendorData={vendorData}
              />
            )}
            {isTerm && <NetXChips value={value} isChipInFadeMode />}{' '}
            {!isTerm && !isStatus && !isPayMode && (
              <Typhography
                component="div"
                type="h2"
                color="text-grey-500 !font-normal text-right"
                additionalClasses={additionalClasses}
              >
                {value}
              </Typhography>
            )}
            {isStatus && isShowStatusWithDropDown && (
              <StatusDropDown
                defautlOption={value}
                dropDownData={dropDownData}
                onOptionClick={onStatusChange}
                additionalClasses={additionalClassesDropdwown}
                additionalDropDownContainerClasses={additionalDropDownContainerClasses}
                additionalSpaceSpanClasses={additionalSpaceSpanClasses}
                iconContainerClasses={iconContainerClasses}
              />
            )}
            {isStatus && !isShowStatusWithDropDown && <StatusTag status={value} />}
          </div>
        ) : (
          <EmptyCellContent />
        )}
      </div>
    </div>
  );
};
