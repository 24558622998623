export const propertiesStatusDropDownData = [
  { status: 'Active', statusVal: 'Non-Delinquent' },
  { status: 'Inactive', statusVal: 'Inactive-Property' },
  { status: 'Delinquent', statusVal: 'Delinquent' },
];

export const vendorsStatusDropDownData = [
  { status: 'Active', statusVal: 'ACTIVE' },
  { status: 'Inactive', statusVal: 'INACTIVE' },
];

export const revereseInvoiceStatusDropDownData = [{ status: 'Reverse', statusVal: 'reversed' }];
