import React from 'react';
import Modal from '../../Modals/Modal/Modal';
import Typho from '../Typho/Typho';
import Heading from '../../Heading/Heading';
import { Button } from '../../Button/Button';

interface DialogBoxProps {
  title?: string;
  content?: string;
  isConfirmButtonEnabled?: boolean;
  isCancelButtonEnabled?: boolean;
  handleConfirmButton?: any;
  handleCancelButton?: any;
  handleOuterClick?: any;
  textConfirmButton?: string;
  textCancelButton?: string;
  dialogBoxState?: boolean;
  dialogBoxAdditionalClasses?: string;
}

const DialogBox: React.FC<DialogBoxProps> = ({
  title,
  content,
  isConfirmButtonEnabled,
  isCancelButtonEnabled,
  handleConfirmButton,
  handleCancelButton,
  handleOuterClick,
  textConfirmButton,
  textCancelButton,
  dialogBoxState,
  dialogBoxAdditionalClasses = '!w-[400px] items-center justify-center px-4 py-8 flex-col',
}) => {
  return (
    <Modal
      isOpen={dialogBoxState}
      onClose={handleOuterClick}
      modalAdditionalClasses={dialogBoxAdditionalClasses}
    >
      {title && <Heading type="h2" colorType="dark" label={title} additionalClasses="mb-4" />}

      {content && (
        <Typho size="16s" color={'black'} weight="medium" additionalClasses="mb-6" type="p">
          {content}
        </Typho>
      )}
      <div className="flex">
        {isConfirmButtonEnabled && (
          <Button
            variants="extraSmall"
            additionalClasses="mx-2 px-10"
            buttonLabel={textConfirmButton}
            onClick={handleConfirmButton}
          />
        )}

        {isCancelButtonEnabled && (
          <Button
            variants="extraSmallOutlined"
            additionalClasses="mx-2 px-10"
            buttonLabel={textCancelButton}
            onClick={handleCancelButton}
          />
        )}
      </div>
    </Modal>
  );
};

export default DialogBox;
