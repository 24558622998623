import React from 'react';
import classNames from 'classnames';
import { IconProps } from 'shared/models/components/base.model';
import { handleIconColor, handleIconHoverColor, handleIconTYpes } from './Icon.handler';

/**
 * Primary UI component for user interaction
 *
 * Used SVG for Icons, All icons are exported in the following file path
 *
 * File Path : "src/components/core/Media/Icons/Icons.tsx"
 */
const Icon: React.FC<IconProps> = ({
  iconType = 'Home',
  iconText,
  iconColor = 'black-900',
  iconHoverColor,
  additionalClasses,
  iconContainerClasses,
  iconTextClasses,
  onClick,
}) => {
  const iconClasses = [
    additionalClasses,
    handleIconColor(iconColor),
    iconHoverColor && handleIconHoverColor(iconHoverColor),
  ];
  return (
    <div className={classNames(iconClasses)} onClick={onClick}>
      <span className={iconContainerClasses}>{handleIconTYpes(iconType)}</span>
      {iconText && <span className={iconTextClasses}>{iconText}</span>}
    </div>
  );
};

export default Icon;
