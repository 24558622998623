import React from 'react';
import SettingsTeamsContainer from '../../components/modules/Settings/Teams';

/**
 * Primary UI component for user interaction
 */
const SettingTeams = () => {
  return <SettingsTeamsContainer />;
};

export default SettingTeams;
