import {
  Middleware,
  Reducer,
  combineReducers,
  configureStore,
  createAction,
  isRejectedWithValue,
} from '@reduxjs/toolkit';
import { vendorsApi } from '../services/vendorsApi';
import { payoutsApi } from '../services/payoutsApi';
import { paymentsApi } from '../services/paymentsApi';
import { invoicesApi } from '../services/invoicesApi';
import { ledgersApi } from '../services/ledgersApi';
import { pastBalanceApi } from '../services/pastBalanceApi';
import { usersApi } from '../services/userApi';
import { paymentSourcesApi } from '../services/paymentSourcesApi';
import { configApi } from '../services/configApi';
import { authApi, cookieApi, logoutApi } from '../services/authApi';
import { customersApi } from '../services/customersApi';
import { analyticsApi } from '../services/analyticsApi';
import { propertiesApi } from 'services/properties';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { resmanPayablesApi } from '../services/resmanPayablesApi';

export const RESET_STATE_ACTION_TYPE = 'resetState';

export const reducers = {
  // Add the generated reducer as a specific top-level slice
  [vendorsApi.reducerPath]: vendorsApi.reducer,
  [payoutsApi.reducerPath]: payoutsApi.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [paymentsApi.reducerPath]: paymentsApi.reducer,
  [invoicesApi.reducerPath]: invoicesApi.reducer,
  [ledgersApi.reducerPath]: ledgersApi.reducer,
  [pastBalanceApi.reducerPath]: pastBalanceApi.reducer,
  [usersApi.reducerPath]: usersApi.reducer,
  [paymentSourcesApi.reducerPath]: paymentSourcesApi.reducer,
  [configApi.reducerPath]: configApi.reducer,
  [cookieApi.reducerPath]: cookieApi.reducer,
  [logoutApi.reducerPath]: logoutApi.reducer,
  [customersApi.reducerPath]: customersApi.reducer,
  [analyticsApi.reducerPath]: analyticsApi.reducer,
  [propertiesApi.reducerPath]: propertiesApi.reducer,
  [resmanPayablesApi.reducerPath]: resmanPayablesApi.reducer,
};

export const resetStateAction = createAction(RESET_STATE_ACTION_TYPE, () => {
  // Error checking is unavailable for following routes
  const errorNoNeedToCheck =
    window.location.pathname !== '/' &&
    window.location.pathname !== '/verify-code' &&
    window.location.pathname !== '/verify-login' &&
    window.location.pathname !== '/search-supplier' &&
    window.location.pathname !== '/401' &&
    window.location.pathname !== '/404error';

  if (typeof window !== 'undefined' && errorNoNeedToCheck) {
    window.location.href = '/401';
  }

  return { payload: null };
});

// set middleware for error handling
export const unauthenticatedMiddleware: Middleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    if (isRejectedWithValue(action) && action.payload.status === 401) {
      dispatch(resetStateAction());
    }

    return next(action);
  };

const combinedReducer = combineReducers<typeof reducers>(reducers);

export const rootReducer: Reducer<RootState> = (state, action) => {
  if (action.type === RESET_STATE_ACTION_TYPE) {
    state = {} as RootState;
  }

  return combinedReducer(state, action);
};

export const store = configureStore({
  reducer: reducers,
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      usersApi.middleware,
      vendorsApi.middleware,
      payoutsApi.middleware,
      paymentSourcesApi.middleware,
      customersApi.middleware,
      analyticsApi.middleware,
      propertiesApi.middleware,
      resmanPayablesApi.middleware,
      usersApi.middleware,
      cookieApi.middleware,
      configApi.middleware,
      authApi.middleware,
      paymentsApi.middleware,
      invoicesApi.middleware,
      ledgersApi.middleware,
      pastBalanceApi.middleware,
      paymentSourcesApi.middleware,
      logoutApi.middleware,
      unauthenticatedMiddleware
    ),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export const useTypedDispatch = () => useDispatch<AppDispatch>();
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
