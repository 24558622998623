import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';

import { Button, Message, Card } from 'components/core';
import HookFormsInput from '../../../../core/OldComponents/FormElements/Input/HookFormsInput';

import { SignupFormParams } from '../../../../../shared/utilities/interfaceHookForms';

import { AccountSignUpSchemaOObj } from '../../../../../helpers/validationSchemas';
import { onPhoneChangeHandler } from '../../../../../helpers/signupHelper';

import { ERROR_MESSAGES } from '../../../../../constants/Validation';
import { phoneNumberValidation } from '../../../../../hooks/usePhoneNumberValidation';

import { useAcceptInviteMutation, useGetInviteQuery } from '../../../../../services/userApi';

export const SignUpForm = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data: invite, isError } = useGetInviteQuery({ id });
  const [isLoading, setIsLoading] = useState(false);
  const [detailSubmitted, setDetailSubmitted] = useState(false);
  const [trigger, result] = useAcceptInviteMutation();

  /**
   * default validation schema
   */
  const AccountSignUpSchema = yup
    .object({
      ...AccountSignUpSchemaOObj,
      phone: yup
        .string()
        .required(ERROR_MESSAGES.REQUIRED)
        .test('len', ERROR_MESSAGES.PHONE_NUMBER_INVALID, (value) =>
          phoneNumberValidation(value, detailSubmitted)
        ),
    })
    .required();
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<SignupFormParams>({ resolver: yupResolver(AccountSignUpSchema) });

  const onSubmit = handleSubmit(async (data) => {
    try {
      setIsLoading(true);
      const names = data.name.split(' ');
      const triggerResult: any = await trigger({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        id: id!,
        email: data.email,
        first_name: names[0],
        last_name: names[1],
        phone_number: data.phone?.replace(/[^0-9]/g, ''),
      });
      setIsLoading(false);
      if (!triggerResult.error) {
        setTimeout(() => {
          navigate('/');
        }, 2000);
      }
    } catch (error: any) {
      console.log(error);
    }
  });

  /**
   * set prefill values
   */
  useEffect(() => {
    setValue('email', invite?.email);
  }, [invite]);

  return (
    <React.Fragment>
      <Card additionalClasses="flex-col items-center !w-[385px]" cardType="white">
        {result?.isSuccess && <div>Sign Up was a success, now redirecting to login</div>}
        {result?.isError && (
          <Message type={'error'} additionalClasses={'text-center left-[92px] right-0 z-40'}>
            {(result.error as any)?.data?.message ?? 'An internal Server Error occurred'}
          </Message>
        )}
        {isError && (
          <Message type={'error'}>
            There was an error loading this invitation,Kindly recheck the link!
          </Message>
        )}
        {(invite?.status === 'accepted' || moment(invite?.valid_upto).isBefore(moment())) && (
          <Message type={'error'} additionalClasses="text-center">
            This invite has been already accepted or expired!
          </Message>
        )}
        {!result?.isSuccess &&
          invite?.status === 'pending' &&
          moment(invite?.valid_upto).isAfter(moment()) && (
            <form onSubmit={onSubmit} className={'w-full flex flex-col'}>
              <div>
                <HookFormsInput
                  id="name"
                  placeholder={'enter your full name'}
                  labelName={'name'}
                  type="text"
                  label="Name"
                  {...register('name')}
                  additionalClasses={errors.name?.message && 'border-red-500 bg-lightRed'}
                  additionalErrorClasses={
                    'text-14s font-normal text-red-500 justify-center mt-6 flex w-full text-center px-4'
                  }
                  labelClasses="relative bg-transparent -mb-2 px-1 w-auto flex align-self-start left-3 before:content-[''] before:absolute before:left-0 before:right-0 before:top-0 before:h-[7px] before:bg-white"
                  error={errors?.name?.message}
                  labelSpanClasses="relative z-1"
                  wrapperClass="flex w-full flex-col relative items-start mb-4"
                  inputVariant="large"
                  isAdditionalErrorInput={true}
                  isBorderedError={true}
                  labelType="blackSmall"
                />
                <HookFormsInput
                  id="email"
                  placeholder={'enter email address'}
                  label="Email"
                  type="text"
                  {...register('email')}
                  additionalClasses={errors.email?.message && 'border-red bg-lightRed'}
                  additionalErrorClasses={
                    'text-14s font-normal text-red-500 justify-center mt-6 flex w-full text-center px-4'
                  }
                  labelClasses="relative bg-transparent -mb-2 px-1 w-auto flex align-self-start left-3 before:content-[''] before:absolute before:left-0 before:right-0 before:top-0 before:h-[7px] before:bg-white"
                  error={errors?.email?.message}
                  labelSpanClasses="relative z-1"
                  wrapperClass="flex w-full flex-col relative items-start mb-4"
                  inputVariant="large"
                  isAdditionalErrorInput={true}
                  isBorderedError={true}
                  labelType="blackSmall"
                />
                <HookFormsInput
                  id="phone"
                  placeholder={'enter phone number'}
                  label="Phone Number"
                  type="text"
                  {...register('phone')}
                  additionalClasses={errors.phone?.message && 'border-red bg-lightRed'}
                  additionalErrorClasses={
                    'text-14s font-normal text-red-500 justify-center mt-6 flex w-full text-center px-4'
                  }
                  labelClasses="relative bg-transparent -mb-2 px-1 w-auto flex align-self-start left-3 before:content-[''] before:absolute before:left-0 before:right-0 before:top-0 before:h-[7px] before:bg-white"
                  error={errors?.phone?.message}
                  labelSpanClasses="relative z-1"
                  wrapperClass="flex w-full flex-col relative items-start mb-4"
                  inputVariant="large"
                  isAdditionalErrorInput={true}
                  isBorderedError={true}
                  labelType="blackSmall"
                  maxLength={14}
                  onChange={(event) => onPhoneChangeHandler(event, setValue, getValues)}
                />
              </div>
              <Button
                type="submit"
                buttonLabel={'Sign Up'}
                additionalClasses="mt-6 relative"
                iconType="Spin"
                onClick={() => {
                  setDetailSubmitted(true);
                }}
                iconClass="inline-flex ml-4 w-6 h-6"
                iconAlignment={'right'}
                isIconEnabled={isLoading ? true : false}
              />
            </form>
          )}
      </Card>
    </React.Fragment>
  );
};
