import React, { useState, useEffect } from 'react';

import {
  useGetPaymentSourcesQuery,
  useGetDefaultPaymentSourcesQuery,
} from '../../../../services/paymentSourcesApi';

import {
  AddPaymentSourceModal,
  Button,
  Grid,
  LoadingSpinner,
  PaymentInfoCard,
  Typhography,
} from 'components/core';
import MethodList from 'components/modules/Common/Payment/MethodList/MethodList';

const Payment = () => {
  const [showACHForm, setShowACHForm] = useState(false);
  const [showACHFormWithoutFirstStep, setShowACHFormWithoutFirstStep] = useState(false);
  const [showWireTransferStep, setShowWireTransferStep] = useState(false);
  const [defaultPaymentSourceId, setDefaultPaymentSourceId] = useState('');

  const handleNewPaymentToExistingList = () => {
    setShowACHForm(!showACHForm);
    setShowACHFormWithoutFirstStep(false);
  };

  const handleNewPayment = () => {
    setShowACHForm(!showACHForm);
    setShowACHFormWithoutFirstStep(true);
  };

  const handleWireTransferPayment = () => {
    setShowACHForm(!showACHForm);
    setShowACHFormWithoutFirstStep(false);
    setShowWireTransferStep(true);
  };

  /**
   * Handle VerificationModalClose
   */
  const {
    data: paymentData,
    isLoading: paymentLoading,
    isSuccess: paymentSuccess,
    isFetching: paymentFetching,
  } = useGetPaymentSourcesQuery('');

  /**
   * Handle Back button
   */
  const handleCloseEvent = () => {
    setShowACHForm(false);
    setShowACHFormWithoutFirstStep(false);
    setShowWireTransferStep(false);
  };

  /**
   * Get default payment source
   */
  const defaultPaymentsource = useGetDefaultPaymentSourcesQuery('');
  const defaultPaymentSourceData = defaultPaymentsource.data;

  useEffect(() => {
    defaultPaymentSourceData &&
      setDefaultPaymentSourceId(defaultPaymentSourceData['default_payment_source']);
  }, [defaultPaymentSourceData]);

  return (
    <>
      {(paymentLoading || paymentFetching) && <LoadingSpinner />}

      {paymentSuccess && (
        <>
          <div className="flex flex-col w-full mb-4">
            <div className="flex items-center justify-between">
              <Typhography
                type="h2"
                color="text-grey-900"
                component="p"
                additionalClasses="mb-4 uppercase"
              >
                Your Payment Method
              </Typhography>
              {paymentData.length !== 0 && (
                <Button
                  variants={'extraSmall'}
                  onClick={handleNewPaymentToExistingList}
                  additionalClasses={`ml-[6px]`}
                  buttonLabel={'New Payment Method'}
                  isIconEnabled={true}
                  iconColor={'inherit'}
                  iconType={'AddIcon'}
                />
              )}
            </div>
            {paymentData.length === 0 && (
              <Typhography type="h2" color="text-grey-700" component="p">
                You don't have any payment methods yet. Please select appropriate one.
              </Typhography>
            )}
          </div>

          {paymentData.length > 0 ? (
            <MethodList cardData={paymentData} defaultSourceID={defaultPaymentSourceId} />
          ) : (
            <Grid columnCount={3} columnGap={4} additionalClasses={'mb-6'}>
              <PaymentInfoCard
                iconType="PropertyIcon"
                title="ACH Payment"
                content="Pay bills online with your bank account withing 3-4 days."
                onClick={handleNewPayment}
              />
              <PaymentInfoCard
                iconType="WireTransferIcon"
                title="Wire Transfer"
                content="Get the wire details. You can easely transfer your money directly to PayUp with our bank details. "
                onClick={handleWireTransferPayment}
              />
            </Grid>
          )}

          {showACHForm && (
            <AddPaymentSourceModal
              onClose={handleCloseEvent}
              isIgnoreFirstStep={showACHFormWithoutFirstStep}
              isLastStepVisible={showWireTransferStep}
            />
          )}
        </>
      )}
    </>
  );
};
export default Payment;
