import React from 'react';
import AccountLoginContainer from '../../../components/modules/Account/LoginContainer/Index';

/**
 * Primary UI component for user interaction
 */
const AccountLogin = () => {
  return <AccountLoginContainer />;
};

export default AccountLogin;
