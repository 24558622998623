import { usePhoneValidation } from 'hooks/usePhoneValidation';

/**
 * Used to reorder the array using due_days
 * @param dataString: pass the terms option, wich return from the api
 * @returns sorted array
 */
export const reOrderingTheTerms = (options?: any) => {
  const sorted: any = [...options].sort(
    (a: any, b: any) => parseFloat(a.due_days) - parseFloat(b.due_days)
  );

  return sorted;
};

/**
 * used to retrive integer value from the string
 * @param dataString : target string value
 * @returns val: integervalue
 */
export const getIntVal = (dataString?: string) => {
  const val = dataString && dataString.replace(/[^0-9]/g, '');

  return val;
};

/**
 * Used to get term name using term id or other string values
 * @param dataString: pass the string value to genarate the term name
 * @returns termName
 */
export const getTermName = (dataString?: any) => {
  const termId = getIntVal(dataString); // get the integer value
  return termId === '30' ? `Net 30+` : `Net ${termId}`;
};

/**
 * Used to confirm the , if it's old term or not
 * @param dataString: pass the string value to genarate the boolean
 * @returns termName
 */
export const getTermType = (dataString?: any) => {
  let isOldTerm = false;

  if (dataString?.includes('old')) {
    isOldTerm = true;
  }
  // set the name

  return isOldTerm;
};

/**
 * Used to get term due_days using term id or other string values
 * @param dataString: pass the string value to genarate the term due_days
 * @returns termName
 */
export const getTermDueDays = (dataString?: any) => {
  const termId = getIntVal(dataString); // get the integer value
  const termDueDays = termId !== '30' ? termId : '30+'; // set the due days

  return termDueDays;
};

/**
 *
 * @param data
 * @param acceptedInvoices
 */
export const getCombinedArrayInvoiceAndCoupaInvoice = (data: any, acceptedInvoices: any) => {
  if (data) {
    const invoiceSet = new Set([
      // eslint-disable-next-line no-unsafe-optional-chaining
      ...acceptedInvoices,
      ...data,
    ]);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore

    const combinedInvoiceData = [...invoiceSet];

    const result = combinedInvoiceData.reduce(
      (
        acc,
        {
          id,
          account_id,
          amount,
          approved_at,
          coupa_invoice_date,
          coupa_invoice_id,
          coupa_invoice_number,
          customerId,
          description,
          estimated_approved_at,
          estimated_invoiced_at,
          invoiced_at,
          payout_term_id,
          title,
        }
      ) => {
        const isCoupa = account_id !== undefined ? true : false;
        id = id === undefined ? null : id;
        //account_id = account_id === undefined ? account?.id : account_id;
        acc.push({
          id,
          account_id,
          amount,
          approved_at,
          coupa_invoice_date,
          coupa_invoice_id,
          coupa_invoice_number,
          customerId,
          description,
          estimated_approved_at,
          estimated_invoiced_at,
          invoiced_at,
          payout_term_id,
          title,
          isCoupa,
        });
        return acc;
      },
      []
    );

    return result;
  } else {
    return acceptedInvoices;
  }
};

/**
 * Handle phone number formting to download data
 */

export const getPhoneNumberForCSV = (phone: any) => {
  const getPhoneNumber = String(phone).replace(/\D/g, '');
  const getRestOfTheFirstNumber =
    getPhoneNumber.substring(0, 1) === '1' ? getPhoneNumber.slice(1) : getPhoneNumber;

  return phone ? usePhoneValidation(getRestOfTheFirstNumber) : null;
};
