import React from 'react';
import classNames from 'classnames';
import { Classes } from '../../../shared/configs/componentsClasses';
import { Button, Icon, Typhography } from 'components/core';
import { MessageProps } from 'shared/models/components/base.model';
import { handleClasses, handleIcons } from './Message.handler';

/**
 * Primary UI component for user interaction
 */
const Message = ({
  type = 'success',
  additionalClasses,
  title,
  children = 'This is a message from Payup',
  buttonLabel,
  onClick,
  messageContentClasses = '',
  messageIconClasses = '',
  buttonClasses = '',
  isButtonInside = false,
  ...props
}: MessageProps) => {
  const messageClasses = [Classes.message.common, handleClasses(type), additionalClasses];

  return (
    <div className={classNames(messageClasses)} {...props}>
      <div className={classNames(['flex items-center', messageContentClasses])}>
        <div className={`w-6 ${type === 'headsUp' ? 'mr-3' : 'mr-4'} ${messageIconClasses}`}>
          <Icon iconType={handleIcons(type)} iconColor="inherit" />
        </div>
        <div className="flex flex-col pr-4">
          {title && (
            <Typhography color="text-grey-900" additionalClasses="mb-1" component="p" type="h2Bold">
              {title}
            </Typhography>
          )}

          {children &&
            (type !== 'headsUp' ? (
              <Typhography
                color="text-grey-600"
                additionalClasses="mb-0 !font-normal"
                component="p"
                type="h2"
              >
                {children}
              </Typhography>
            ) : (
              <>{children}</>
            ))}
          {isButtonInside && buttonLabel && (
            <div className="flex pt-2">
              <Button
                buttonLabel={buttonLabel}
                variants="extraSmallOutlined"
                additionalClasses={classNames([
                  'h-6 !border-grey-100 shadow-messages',
                  buttonClasses,
                ])}
                onClick={onClick}
              />
            </div>
          )}
        </div>
      </div>
      {!isButtonInside && buttonLabel && (
        <div>
          <Button
            buttonLabel={buttonLabel}
            variants="extraSmallOutlined"
            additionalClasses={classNames(['h-6 !border-grey-100 shadow-messages', buttonClasses])}
            onClick={onClick}
          />
        </div>
      )}
    </div>
  );
};

export default Message;
