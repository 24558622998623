import React from 'react';
import Error302Container from '../../../components/modules/Error/Error302/Error302';

/*
 * Primary UI component for user interaction
 */
const Error302 = () => {
  return <Error302Container />;
};

export default Error302;
