import React from 'react';
import Wrapper from '../../layouts/Wrapper/Wrapper';
import Headers from '../../core/Headers/Headers';
import ProfileForm from './Form/Form';

const ProfileContainer = () => {
  return (
    <Wrapper additionalClasses="flex-col items-start" pageTitle="Your Profile">
      <Headers headingContent="Your Profile" />
      <ProfileForm />
    </Wrapper>
  );
};

export default ProfileContainer;
