import React from 'react';
import classNames from 'classnames';

import AnchorLink from '../OldComponents/AnchorLink/AnchorLink';
import CountTag from '../CountTag/CountTag';
import Icon from '../Media/Icon/Icon';
import Tooltip from '../Tooltip/Tooltip';
import Tag from '../Tag/Tag';
import { Typhography } from '../Typhography/Typhography';
import { AccordionItemProps } from 'shared/models/components/base.model';

const AccordionItem: React.FC<AccordionItemProps> = ({
  mainLabel,
  subLabel,
  isActive = false,
  urlPath = '/#',
  additionalClasses,
  tagValue,
  isArrowIcon = false,
  rightColContent,
  isTooltip = false,
  isLink = true,
  clickHandlerLabel,
  clickHandlerIcon,
  tooltipHeading,
  tooltipContent,
  tagUrl,
  isInvoiceTag = false,
  tagLabel = 'INV01234',
  isHighlighted = false,
  isHover = false,
  isRemoveTopBorder = false,
  clickAccordion,
  isDisabled = false,
}) => {
  const defaultAccordionClasses =
    'flex items-center justify-between h-10 w-full bg-white ease-out duration-300 border-solid border-grey-100 border-t-0 border-b-1';
  const handleHoverState = 'hover:bg-secondary-75 hover:text-white hover:border-secondary-75';
  const handleActiveState = isActive ? 'bg-grey-100' : 'bg-white';
  const handleIconClassesInActiveState = isActive ? 'rotate-90' : 'rotate-0';
  const handleFirstAccordionClasses = 'first:border-t';
  const handleAccordionItemWithoutDropDownIcon = isArrowIcon ? 'pl-4 pr-4' : 'pl-4 pr-4';
  const handleHighlightedClasses = isHighlighted && '!text-green-400';

  const handleAccordionClasses = [
    additionalClasses,
    defaultAccordionClasses,
    isHover && handleHoverState,
    handleFirstAccordionClasses,
    handleAccordionItemWithoutDropDownIcon,
    handleHighlightedClasses,
    handleActiveState,
    isRemoveTopBorder ? 'border-b-0' : 'border-b-1',
    isArrowIcon ? 'cursor-pointer' : 'cursor-pointer',
  ];

  const handleDisableState = isDisabled ? 'opacity-30' : 'opacity-100';

  return (
    <div className={classNames(handleAccordionClasses)} onClick={clickAccordion}>
      <div className={classNames(['flex items-center cursor-pointer', handleDisableState])}>
        {isArrowIcon && (
          <span
            title="icon"
            className={classNames([handleIconClassesInActiveState, 'inline-flex'])}
            onClick={clickHandlerIcon}
          >
            <Icon iconColor="inherit" iconType="RightArrow" />
          </span>
        )}
        {isLink ? (
          <AnchorLink
            urlPath={urlPath}
            linkFontSize={`14s`}
            linkFontWeight={`semibold`}
            linkHoverColor={isHover ? 'grey-900' : 'grey-900'}
            linkColor={isHover ? 'grey-900' : 'grey-900'}
            additionalClasses={`inline-flex items-center min-w-[186px] ${
              isArrowIcon ? 'ml-3' : 'ml-0'
            }`}
            isUnderLine={false}
          >
            <span className="text-inherit">{mainLabel}</span>
            {subLabel && <span className="ml-[10px] font-normal text-inherit">{subLabel}</span>}
          </AnchorLink>
        ) : (
          <div
            className={`inline-flex ${isArrowIcon ? 'ml-3' : 'ml-0'}`}
            onClick={clickHandlerLabel}
          >
            <Typhography
              component="p"
              type="h2Bold"
              color="text-grey-900"
              additionalClasses="inline-flex items-center min-w-[186px]"
            >
              <span>{mainLabel}</span>
              {subLabel && <span className="ml-[10px] font-normal text-inherit">{subLabel}</span>}
            </Typhography>
          </div>
        )}

        {isTooltip && (
          <div className={`inline-flex ml-[18px]`}>
            <Tooltip
              tooltipIcon="Info"
              tooltipIconClasses="opacity-[0.55] cursor-pointer"
              tooltipHeading={tooltipHeading}
              tooltipContent={tooltipContent}
            />
          </div>
        )}

        {tagValue && <CountTag additionalClasses="ml-3">{tagValue}</CountTag>}

        {isInvoiceTag && (
          <Tag
            isLink={false}
            isUnderLine={false}
            urlPath={tagUrl}
            additionalClasses="ml-4 border border-solid border-white"
          >
            {tagLabel}
          </Tag>
        )}
      </div>
      {rightColContent && (
        <div className={classNames(['flex items-center ', handleDisableState])}>
          <Typhography
            component="p"
            type="h2Bold"
            color="text-grey-900"
            additionalClasses="inline-flex items-center"
          >
            {rightColContent}
          </Typhography>

          {!isArrowIcon && (
            <span className="ml-2 inline-flexitems-center text-grey-900">
              <Icon iconColor="inherit" iconType="RightArrow" />
            </span>
          )}
        </div>
      )}
    </div>
  );
};

export default AccordionItem;
