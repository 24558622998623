import { useEffect } from 'react';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';

import Wrapper from 'components/layouts/Wrapper/Wrapper';
import ListEmpty from 'components/modules/Common/ListEmpty/ListEmpty';

import {
  BalanceStatsBar,
  LoadingSpinner,
  ToolBar,
  Typhography,
  Headers,
  SelectBox,
  AccordionItem,
} from 'components/core';

import { balanceDaysTabnavData } from 'constants/TabNavData';
import { upcomingDropdownItems } from 'constants/DateRangeDropDownDatas';
import { CONFIG } from 'shared/configs/config';
import { handleBalanceAccordionClick } from 'helpers/modules/helperBalance';

import { useCurrencyFormat } from 'hooks/useCurrencyFormat';
import { getURLParams, useDateRangeSorting } from 'hooks/useDateRangeSorting';

import {
  useGetPendingBalanceApiQuery,
  useGetUpcomingBalanceHeroStatsQuery,
} from '../../../../../services/pastBalanceApi';

const UpcomingContainer = () => {
  const [
    rangeSelector,
    defaultSortingText,
    itemSlectedState,
    showDropdown,
    handlerSortingDropDownClick,
    handlerSortingOption,
  ]: any = useDateRangeSorting('All', upcomingDropdownItems);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    navigate(
      {
        pathname: location.pathname,
        search: `?${createSearchParams(getURLParams(defaultSortingText))}`,
      },
      { replace: true }
    );
  }, [defaultSortingText]);

  const setLessThanDate = moment()
    .tz('America/Chicago')
    .add(rangeSelector, 'day')
    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    .format();
  const setGreaterThan = moment()
    .tz('America/Chicago')
    .add(0, 'day')
    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    .format();

  /*
   * List the Upcoming Invoices
   */
  const upcomingInvoiceParams = {
    first: CONFIG.DATA_OVERALL_LIMIT,
    offset: 0,
    filter: {
      status: { in: ['INVOICED', 'APPROVED', 'PAID'] },
      customer_payment_due_at: {
        greaterThan: setGreaterThan,
        lessThan: setLessThanDate,
      },
    },
    groupBy: 'CUSTOMER_PAYMENT_DUE_AT_TRUNCATED_TO_DAY_CST',
  };

  const { data, isLoading, isFetching, isSuccess } =
    useGetPendingBalanceApiQuery(upcomingInvoiceParams);
  const upcomingListData = isSuccess && data?.payouts?.groupedAggregates;

  /*
   * Fetch the Upcoming Invoices Hero Stats data
   */
  const upcomingHeroStatsParams = {
    filter: {
      status: { in: ['APPROVED', 'PAID', 'INVOICED'] },
      customer_payment_due_at: {
        greaterThan: setGreaterThan,
        lessThan: setLessThanDate,
      },
    },
    timeCycleDays: '' + rangeSelector + '',
  };

  const { data: upcomingBalanceStatsBarData, isSuccess: isSuccessUpcomingBalanceStatsBar }: any =
    useGetUpcomingBalanceHeroStatsQuery(upcomingHeroStatsParams);

  return (
    <>
      {(isLoading || isFetching) && <LoadingSpinner />}
      <Wrapper additionalClasses="flex-col items-start" pageTitle="Balance">
        <Headers headingContent="Balance" />
        {!isLoading && (
          <>
            <ToolBar navigationData={balanceDaysTabnavData} isShowOptionEnabled={false}>
              <SelectBox
                items={upcomingDropdownItems}
                itemClickHandler={handlerSortingOption}
                defaultSelectBoxText={defaultSortingText}
                itemSelected={itemSlectedState}
                dropDownState={showDropdown}
                dropDownClickHandler={handlerSortingDropDownClick}
              />
            </ToolBar>

            {isSuccessUpcomingBalanceStatsBar && (
              <BalanceStatsBar
                defaultSortingText={defaultSortingText}
                data={upcomingBalanceStatsBarData}
                timeFrame="upcoming"
              />
            )}

            {upcomingListData?.length > 0 && (
              <div className="flex flex-col w-full">
                <Typhography
                  component="div"
                  type="h2"
                  color="text-grey-900"
                  additionalClasses="items-center uppercase pt-9 pb-3 border-t border-solid border-grey-100 px-4 !font-normal"
                >
                  {'Invoices List'}
                </Typhography>
                <div className="flex flex-col w-full invoices-list-wrapper">
                  {upcomingListData.map((listItem: any) => {
                    const convertedPrice = parseFloat(listItem?.sum?.amount);

                    return (
                      <div className="flex flex-col w-full mb-0" key={listItem.keys[0]}>
                        <AccordionItem
                          mainLabel="Invoice"
                          subLabel={moment(listItem.keys[0])
                            .tz('America/Chicago')
                            .format('MM/DD/YYYY')}
                          rightColContent={useCurrencyFormat(convertedPrice)}
                          isInvoiceTag={false}
                          clickAccordion={() =>
                            handleBalanceAccordionClick(
                              listItem.keys[0],
                              navigate,
                              false,
                              'upcoming'
                            )
                          }
                          isLink={false}
                          isRemoveTopBorder={upcomingListData?.length === 1}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {upcomingListData?.length === 0 && <ListEmpty />}
          </>
        )}
      </Wrapper>
    </>
  );
};

export default UpcomingContainer;
