import LoadingSpinner from 'components/core/LoadingSpinner/LoadingSpinner';
import { ActivePropertiesStatsBar, SelectBox, ToolBar } from 'components/core';
import Wrapper from 'components/layouts/Wrapper/Wrapper';
// import React from 'react';
// import { propertiesTabnavData } from 'shared/datas/TabNavData';
import { Navigate, useNavigate, createSearchParams } from 'react-router-dom';
import Headers from '../../../core/Headers/Headers';
import { useGetPropertiesQuery } from 'services/properties';
import DataList from 'components/core/SuperTable/DataList/DataList';
import { propertiesTabnavData } from 'constants/TabNavData';
import ListEmpty from '../../Common/ListEmpty/ListEmpty';
import { CONFIG } from 'shared/configs/config';
import moment from 'moment';
import { useEffect, useState } from 'react';
import Modal from 'components/core/Modals/Modal/Modal';
import AddPropertyForm from '../Details/Profile/Form/AddPropertyForm';
import { getURLParams, useDateRangeSorting } from 'hooks/useDateRangeSorting';
import {
  serverSidePaginationActive,
  serverSidePaginationActiveForProperties,
  useServerSidePagination,
} from 'hooks/useServerSidePagination';
import { useGetPaymentMethodsQuery } from 'services/payoutsApi';
import { useShowOptionHandler } from 'hooks/useShowOptionHandler';
import { helperPropertiesColumOrder } from 'helpers/modules/helperProperties';

export const ActivePropertiesContainer = () => {
  const [visibleColumns, setVisibleColumns] = useState([]);
  const [filteredRow, setFilteredRow] = useState([]);

  const [tableColumns, handleIconClick] = helperPropertiesColumOrder('allProperties');
  const [pageSizeOptions, paginationState, previousButtonClick, nextButtonClick, setPageSize]: any =
    useServerSidePagination();
  const [
    rangeSelector,
    defaultSortingText,
    itemSlectedState,
    showDropdown,
    handlerSortingDropDownClick,
    handlerSortingOption,
  ]: any = useDateRangeSorting('All');
  const navigate = useNavigate();

  const propertiesFilterParam = {
    limit: serverSidePaginationActiveForProperties
      ? paginationState.pageSize
      : CONFIG.DATA_OVERALL_LIMIT,
    offset: serverSidePaginationActiveForProperties
      ? paginationState.pageIndex * paginationState.pageSize
      : 0,
    orderBy: 'NAME_ASC',
    filter: {
      payoutsByPropertyId: {
        created_at: {
          lessThan: moment()
            .add(1, 'day')
            .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
            .format(),
          greaterThan:
            rangeSelector > 0
              ? moment()
                  .subtract(rangeSelector, 'day')
                  .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                  .format()
              : undefined,
        },
      },
      is_delinquent: { equalTo: false },
      status: { equalTo: 'ACTIVE' },
    },
  };

  const paramsForDownloadAllData = {
    limit: serverSidePaginationActive ? paginationState.pageSize : CONFIG.DATA_OVERALL_LIMIT,
    offset: serverSidePaginationActive ? paginationState.pageIndex * paginationState.pageSize : 0,
    orderBy: 'NAME_ASC',
    filter: {
      created_at: {
        lessThan: moment()
          .add(1, 'day')
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
          .format(),
        greaterThan:
          rangeSelector > 0
            ? moment()
                .subtract(rangeSelector, 'day')
                .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                .format()
            : undefined,
      },
      is_delinquent: { equalTo: false },
      status: { equalTo: 'ACTIVE' },
    },
  };

  const [showEditModal, setShowEditModal] = useState(false);

  useEffect(() => {
    navigate(
      {
        pathname: '/properties/active',
        search: `?${createSearchParams(getURLParams(defaultSortingText))}`,
      },
      { replace: true }
    );
  }, [defaultSortingText]);

  const { data, isLoading, isError, isSuccess }: any = useGetPropertiesQuery(propertiesFilterParam);
  const propertyDataNodes: any = isSuccess ? data.properties.nodes : [];

  const totalRecords: number = data?.properties?.totalCount ?? 0;
  const totalPageCount = Math.ceil(totalRecords / paginationState.pageSize);

  const {
    data: paymentMethodsData,
    isSuccess: isPaymentMethodsDataSuccess,
    isError: isPaymentMethodsDataError,
  } = useGetPaymentMethodsQuery('');

  const [
    hiddenColumns,
    toggleHiddenColumnChanged,
    setToggleHiddenColumnChanged,
    columnList,
    setColumnList,
    propertyNodes,
  ] = useShowOptionHandler({
    tableColumns: tableColumns,
    data: propertyDataNodes,
    additionalColumns: [],
    storageItemName: 'showOptionPropertiesList',
  });

  return (
    <Wrapper additionalClasses="flex-col items-start" pageTitle="Properties">
      <Headers
        headingContent="Properties"
        isDisabled
        onClick={() => setShowEditModal(true)}
        buttonLabel={'Add Property'}
        isButtonVisibled={true}
        isIconEnabled={true}
        variants={'extraSmall'}
        additionalClasses={'border-none w-[140px]'}
        iconColor={'inherit'}
        iconType={'AddIcon'}
      />
      {/**
       * Tab navigation
       */}
      <ToolBar
        navigationData={propertiesTabnavData}
        visibleColumns={visibleColumns}
        filteredRow={filteredRow}
        isDownloadCsvButtonEnabled={isSuccess && data.properties.nodes.length !== 0}
        isDownloadCompleteData={true}
        queryParam={paramsForDownloadAllData}
        tabType="properties"
        paymentMethodData={
          isPaymentMethodsDataSuccess ? paymentMethodsData : isPaymentMethodsDataError ? [] : []
        }
        isShowOptionEnabled={propertyDataNodes && propertyDataNodes.length > 0}
        columns={columnList}
        handleIconClick={(item: any) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          handleIconClick(
            item,
            columnList,
            setColumnList,
            setToggleHiddenColumnChanged,
            toggleHiddenColumnChanged
          );
        }}
      >
        <SelectBox
          itemClickHandler={handlerSortingOption}
          defaultSelectBoxText={defaultSortingText}
          itemSelected={itemSlectedState}
          dropDownState={showDropdown}
          dropDownClickHandler={handlerSortingDropDownClick}
        />
      </ToolBar>
      {/**
       * Handle loader
       */}
      {isLoading && <LoadingSpinner />}
      {/**
       * Error handling
       */}
      {isError && <Navigate to={'/401'} />}

      {isSuccess &&
        (isPaymentMethodsDataSuccess || isPaymentMethodsDataError) &&
        (propertyNodes && propertyNodes.length > 0 ? (
          <>
            <ActivePropertiesStatsBar dateRange={defaultSortingText} propertiesData={data} />
            <DataList
              data={propertyNodes}
              tableType="properties"
              isPagination
              subTabType="delinquentProperties"
              isServerSidePagination={serverSidePaginationActiveForProperties}
              previousPageClick={previousButtonClick}
              nextPageClick={nextButtonClick}
              setPageSizeValue={setPageSize}
              options={pageSizeOptions}
              totalPageCount={totalPageCount}
              pageSizeVal={paginationState.pageSize}
              pageIndexVal={paginationState.pageIndex}
              setVisibleColumns={setVisibleColumns}
              setFilteredRow={setFilteredRow}
              paymentMethodData={
                isPaymentMethodsDataSuccess
                  ? paymentMethodsData
                  : isPaymentMethodsDataError
                  ? []
                  : []
              }
              hiddenColumnList={hiddenColumns}
              toggleHiddenColumnChanged={toggleHiddenColumnChanged}
            />
          </>
        ) : (
          <ListEmpty />
        ))}

      {showEditModal && (
        <Modal
          isOpen={showEditModal}
          onClose={() => setShowEditModal(false)}
          modalAdditionalClasses={'!w-[500px] items-center justify-center px-4 py-8 flex-col'}
        >
          <AddPropertyForm onCloseEvent={() => setShowEditModal(false)} />
        </Modal>
      )}
    </Wrapper>
  );
};
