import moment from 'moment';
import { ERROR_MESSAGES } from '../constants/Validation';

/**
 * validate DOB with yap
 * @param value
 * @param setDOBError
 * @return boolean,
 *
 */
export const validateDOB = (value: any, setDOBError: any) => {
  if (moment(value).isValid()) {
    return true;
  } else {
    //set error message
    value == null ? setDOBError(ERROR_MESSAGES.REQUIRED) : setDOBError('Invalid Date of Birth');
    return false;
  }
};
