import classNames from 'classnames';
import {
  commonDropdownClasses,
  handleTagClasses,
  handleTagText,
} from '../Chips/StatusTag/StatusTag.handler';
import { useComponentVisible } from 'hooks/useComponentVisible';
import Icon from '../Media/Icon/Icon';
import { StatusDropDownProps } from 'shared/models/components/base.model';

export const StatusDropDown = ({
  dropDownData,
  defautlOption,
  onOptionClick,
  additionalClasses = '!w-[148px]',
  additionalDropDownContainerClasses = 'w-[148px]',
  additionalDropDownItemClasses = '',
  additionalSpaceSpanClasses = '',
  iconContainerClasses = '',
}: StatusDropDownProps) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  const handleOptionClick = (item: any) => {
    onOptionClick && onOptionClick(item);
    setIsComponentVisible(false);
  };

  return (
    <div ref={ref} className={'relative'}>
      <div
        className={classNames([
          commonDropdownClasses,
          handleTagClasses(defautlOption),
          additionalClasses,
        ])}
        onClick={() => setIsComponentVisible(!isComponentVisible)}
      >
        {handleTagText(defautlOption)}
        <Icon iconType="DropDown" iconColor="inherit" iconContainerClasses={iconContainerClasses} />
      </div>

      {isComponentVisible && (
        <div
          className={classNames([
            'absolute shadow-dropDown pt-2 px-2 pb-1 bg-white status-dropdown',
            additionalDropDownContainerClasses,
          ])}
        >
          {dropDownData &&
            dropDownData.map((item: any, index: any) => {
              return (
                item.statusVal !== defautlOption && (
                  <div key={index} className="mb-2">
                    <div
                      data-val={item.statusVal}
                      onClick={(item) => handleOptionClick(item)}
                      className={classNames([
                        commonDropdownClasses,
                        handleTagClasses(item.statusVal),
                        additionalDropDownItemClasses,
                        '!w-full',
                      ])}
                    >
                      {handleTagText(item.statusVal)}
                      <span className={additionalSpaceSpanClasses} />
                    </div>
                  </div>
                )
              );
            })}
        </div>
      )}
    </div>
  );
};
