import { Typhography } from '../../Typhography/Typhography';
import { EditCardProps } from 'shared/models/components/base.model';
import classNames from 'classnames';
import Icon from '../../Media/Icon/Icon';
import { useComponentVisible } from 'hooks/useComponentVisible';
import { handleAdditionalClasses } from 'shared/utilities/dataConversion';

export const EditCard = ({
  title,
  onEditIconClick,
  additionalClasses,
  children,
  isEditIconEnabled = true,
  iconType = 'Properties',
  iconClasses,
}: EditCardProps) => {
  const wrapperClass = 'w-full flex flex-col';
  const innerWrapperClass =
    'pt-2 pl-4 pr-4 pb-1 relative rounded-lg border border-solid border-grey-100 border-1';
  const showMoreDropdownItemClasses =
    'h-[34px] px-4 hover:bg-grey-50 flex items-center cursor-pointer text-12s font-medium';

  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  const handleOnEdit = () => {
    onEditIconClick && onEditIconClick();
    setIsComponentVisible(!isComponentVisible);
  };

  return (
    <div className={classNames([additionalClasses, wrapperClass, 'mb-8'])}>
      <div className={classNames([wrapperClass, innerWrapperClass])}>
        {title && (
          <div className="flex items-center justify-between w-full h-[54px] px-0 mb-2">
            <div className="flex flex-col">
              <div className="flex items-center" style={{ strokeWidth: 1.5 }}>
                <Icon
                  iconColor="inherit"
                  iconType={iconType}
                  additionalClasses={classNames([
                    'mr-2 -top-[2px] relative text-primary-500',
                    handleAdditionalClasses(iconClasses),
                  ])}
                />
                <Typhography component="p" type="h2Bold" color="text-grey-900">
                  {title}
                </Typhography>
              </div>
            </div>
            {isEditIconEnabled && (
              <div>
                <div className="relative flex" ref={ref}>
                  <div
                    onClick={() => setIsComponentVisible(!isComponentVisible)}
                    className="flex items-center justify-center w-5 cursor-pointer text-grey-500"
                  >
                    <Icon iconColor="inherit" iconType="DotsMoreIcon" />
                  </div>
                  {isComponentVisible && (
                    <div className="absolute w-[140px] rounded-lg rounded-tr-none bg-white py-2 shadow-showMoreDropDown top-0 right-[20px]">
                      <div
                        className={`${showMoreDropdownItemClasses} text-grey-900`}
                        onClick={handleOnEdit}
                      >
                        <Icon iconColor="inherit" iconType="EditIcon" additionalClasses="mr-2" />
                        Edit
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
        {children}
      </div>
    </div>
  );
};
