import { createApi } from '@reduxjs/toolkit/query/react';
import { prepareBaseQuery } from '../shared/configs/config';
import { IProcessSinglePayout } from 'shared/models/components/service.model';
import { PaymentSource } from 'shared/models/services/paymentsources.model';

export const payoutsApi = createApi({
  reducerPath: 'payoutsApi',
  tagTypes: ['Payouts'],
  baseQuery: prepareBaseQuery(true),
  endpoints: (builder) => ({
    updatePayoutStatus: builder.mutation({
      query: ({ id, status }) => ({
        url: `/payout/${id}/status`,
        body: { status: status },
        method: 'POST',
      }),
      invalidatesTags: ['Payouts'],
    }),
    setPayoutTermInfo: builder.mutation({
      query: (obj: any) => ({
        url: `/payout/${obj.id}/updateTerms`,
        method: 'POST',
        body: {
          payout_term_id: obj.selectedNetTerm,
        },
      }),
      invalidatesTags: ['Payouts'],
    }),
    getPayouts: builder.query({
      query: (state) => ({
        url: `/query/payouts`,
        body: state,
        method: 'POST',
      }),
      providesTags: ['Payouts'],
    }),
    getPayoutsData: builder.mutation({
      query: (state) => ({
        url: `/query/payouts`,
        body: state,
        method: 'POST',
      }),
      invalidatesTags: ['Payouts'],
    }),
    payoutNow: builder.mutation({
      query: ({ id }) => ({
        url: `/payout/process-payout-bulk`,
        method: 'POST',
        body: {
          payoutIds: id,
        },
      }),
      invalidatesTags: ['Payouts'],
    }),
    importInvoices: builder.mutation({
      query: ({ invoiceNumbers, accountId, status }) => ({
        url: `/coupa/sync-invoices-manually`,
        method: 'POST',
        body: {
          invoice_numbers: invoiceNumbers,
          desired_status: status,
          account_id: accountId,
        },
      }),
      invalidatesTags: ['Payouts'],
    }),
    secheduleThePayment: builder.mutation({
      query: (obj: any) => ({
        url: `/payout/${obj.id}/schedule-payout`,
        method: 'POST',
        body: {
          scheduleDate: {
            day: obj.day,
            month: obj.month,
            year: obj.year,
          },
        },
      }),
      invalidatesTags: ['Payouts'],
    }),
    getSupplierCoupaInvoices: builder.query({
      query: (id) => ({
        url: `/coupa/invoices`,
        method: 'GET',
        params: {
          account_id: id,
        },
      }),
      providesTags: ['Payouts'],
    }),
    createImportInvoice: builder.mutation({
      query: (invoiceIds) => ({
        url: `/coupa/sync-invoices-manually`,
        body: { invoiceids: invoiceIds },
        method: 'POST',
      }),
      invalidatesTags: ['Payouts'],
    }),
    updateNetTerm: builder.mutation({
      query: (obj: any) => ({
        url: `/payout/${obj.payoutId}/updateTerms`,
        method: 'POST',
        body: {
          payout_term_id: `${obj.net_term}`,
        },
      }),
      invalidatesTags: ['Payouts'],
    }),
    updateNetTermsBulk: builder.mutation({
      query: (obj: any) => ({
        url: `/payout/update-terms-bulk`,
        method: 'POST',
        body: {
          payout_term_id: `${obj.net_term}`,
          payoutIds: obj.payoutIds,
        },
      }),
      invalidatesTags: ['Payouts'],
    }),
    processSinglePayout: builder.mutation({
      query: (obj: IProcessSinglePayout) => ({
        url: `/payout/${obj.id}/process-payout`,
        method: 'POST',
        body: {
          mode: obj.mode,
          external_account_payout_id: obj.external_account_payout_id,
          external_pay_id: obj.external_pay_id,
          notes: obj.notes,
        },
      }),
      invalidatesTags: ['Payouts'],
    }),
    unprocessSinglePayout: builder.mutation({
      query: (id?: string) => ({
        url: `/payout/${id}/undo-process-payout`,
        method: 'POST',
      }),
      invalidatesTags: ['Payouts'],
    }),
    achFileUpload: builder.mutation({
      query: (data) => ({
        url: `/testdata/ach-file`,
        body: data,
        method: 'POST',
      }),
      invalidatesTags: ['Payouts'],
    }),
    createACHCharge: builder.mutation({
      query: (data) => ({
        url: `/customer/charge-from-file`,
        body: data,
        method: 'POST',
      }),
      invalidatesTags: ['Payouts'],
    }),
    parseAchFileFromUrl: builder.mutation({
      query: (data) => ({
        url: `/customer/data-from-fileurl`,
        body: data,
        method: 'POST',
      }),
      invalidatesTags: ['Payouts'],
    }),
    createCustomerInvoiceByChargeId: builder.mutation({
      query: (state) => ({
        url: `/customer/create-invoice-from-chargeid`,
        body: state,
        method: 'POST',
      }),
      invalidatesTags: ['Payouts'],
    }),
    createTransferFromChargeId: builder.mutation({
      query: (state) => ({
        url: `/customer/create-transfer-from-ach-file-charge`,
        body: state,
        method: 'POST',
      }),
      invalidatesTags: ['Payouts'],
    }),
    createRefunFromChargeId: builder.mutation({
      query: (params) => ({
        url: `/customer/charge/refund/${params.id}`,
        body: params.body,
        method: 'POST',
      }),
      invalidatesTags: ['Payouts'],
    }),
    getCustomerSourceCharges: builder.query({
      query: (source_id) => ({
        url: `/customer/charges?property_id=${source_id}`,
        method: 'GET',
      }),
      providesTags: ['Payouts'],
    }),
    getPayoutsByIDs: builder.query({
      query: (state) => ({
        url: `/query/payouts`,
        body: state,
        method: 'POST',
      }),
      providesTags: ['Payouts'],
    }),
    getAllTransactions: builder.query({
      query: (state) => ({
        url: `/query/transfers`,
        method: 'POST',
        body: { ...state, as_user: true },
      }),
      providesTags: ['Payouts'],
    }),
    transferNow: builder.mutation({
      query: (ids) => ({
        url: `/transfer/process-transfer-bulk`,
        method: 'POST',
        body: {
          transferIds: ids,
        },
      }),
      invalidatesTags: ['Payouts'],
    }),
    getPaymentMethods: builder.query<PaymentSource[], string>({
      query: (state) => ({
        url: `/customer/payment-methods`,
        method: 'GET',
      }),
      providesTags: ['Payouts'],
    }),
    reversePayoutsPayments: builder.mutation({
      query: (obj: { id: string; status: string }) => ({
        url: `/payout/${obj.id}/status`,
        method: 'POST',
        body: {
          status: obj.status,
        },
      }),
      invalidatesTags: ['Payouts'],
    }),
    getPropertiesNames: builder.query({
      query: () => ({
        url: `/property/names`,
        method: 'GET',
      }),
    }),
    getTransferDetails: builder.query({
      query: (id: string) => {
        return {
          url: `/payout/transfer-detail/${id}`,
          method: 'GET',
        };
      },
      providesTags: ['Payouts'],
    }),
    getInvoiceDetails: builder.query({
      query: (id: string) => {
        return {
          url: `/payout/payoutdetail/${id}`,
          method: 'GET',
        };
      },
      providesTags: ['Payouts'],
    }),
  }),
});

export const {
  useGetPayoutsQuery,
  useGetPayoutsDataMutation,
  useImportInvoicesMutation,
  useSetPayoutTermInfoMutation,
  usePayoutNowMutation,
  useSecheduleThePaymentMutation,
  useGetSupplierCoupaInvoicesQuery,
  useCreateImportInvoiceMutation,
  useUpdateNetTermMutation,
  useUpdateNetTermsBulkMutation,
  useProcessSinglePayoutMutation,
  useUnprocessSinglePayoutMutation,
  useAchFileUploadMutation,
  useCreateACHChargeMutation,
  useParseAchFileFromUrlMutation,
  useGetCustomerSourceChargesQuery,
  useCreateCustomerInvoiceByChargeIdMutation,
  useCreateTransferFromChargeIdMutation,
  useCreateRefunFromChargeIdMutation,
  useTransferNowMutation,
  useGetAllTransactionsQuery,
  useGetPayoutsByIDsQuery,
  useGetPaymentMethodsQuery,
  useReversePayoutsPaymentsMutation,
  useGetPropertiesNamesQuery,
  useGetTransferDetailsQuery,
  useGetInvoiceDetailsQuery,
} = payoutsApi;
