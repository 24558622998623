import Icon from 'components/core/Media/Icon/Icon';

// Define a default UI for filtering
export function DefaultColumnFilter({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  column: { filterValue, preFilteredRows, setFilter, Header, wrapperClasses = 'w-[140px]' },
}) {
  // const count = preFilteredRows.length;
  const inputSearchClasses =
    'w-full h-6 pl-2 pr-10  border border-solid rounded-md  border-grey-100 text-12s text-grey-600 placeholder-gray600 font-medium ';
  return (
    <div className={`relative ${wrapperClasses}`}>
      <input
        value={filterValue || ''}
        className={inputSearchClasses}
        onChange={(e) => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
        placeholder={Header}
      />
      <div className="absolute text-grey-400 right-2 top-[6px]">
        <Icon iconType="SearchIcon" additionalClasses="mr-1" iconColor="inherit" />
      </div>
    </div>
  );
}
