import Error404 from 'pages/Error/Error404/Error404';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AccountLogin from '../../pages/Account/Login/Login';
import Approvals from '../../pages/Approvals/Approve';
import Balance from '../../pages/Balance/Current/Current';
import Error302 from '../../pages/Error/Error302/Error302';
import Error401 from '../../pages/Error/Error401/Error401';
import Error500 from '../../pages/Error/Error500/Error500';
import Home from '../../pages/Home/Home';
import Profile from '../../pages/Profile/Profile';
import SettingTeams from '../../pages/Settings/Teams';
import VendorAccount from '../../pages/Vendors/Account/Account';
import Vendors from '../../pages/Vendors/Active';
import PrivateRoute from './PrivateRoute';
import Payment from '../../pages/Balance/Payment/Payment';
import PaymentSuccess from '../../pages/Balance/Payment/Success/Success';
import ToApprove from '../../pages/Approvals/ToApprove';
import Error403 from '../modules/Error/Error403/Error403';
import { SignUp } from '../../pages/Account/SignUp/SignUp';
import InActiveVendors from '../../pages/Vendors/InActive/Index';
import Past from '../../pages/Balance/Past/Past';
import Upcoming from 'pages/Balance/Upcoming/Upcoming';
import AllApprovals from 'pages/Approvals/All';
import AllProperties from 'pages/Properties/All';
import AllVendors from 'pages/Vendors/All';
import DelinquentProperties from 'pages/Properties/Delinquent';
import { PropertyDetails } from 'pages/Properties/Details/Profile';
import VendorEarnings from 'pages/Vendors/Account/Earnings';
import VendorProperties from 'pages/Vendors/Account/Properties';
import VendorPayments from 'pages/Vendors/Account/Payments';
import VendorImportIednvoices from 'pages/Vendors/Account/ImportedInvoices';
import { PropertyVendors } from 'pages/Properties/Details/Vendors';
import { PropertyInvoices } from 'pages/Properties/Details/AllInvoices';
import ActiveProperties from 'pages/Properties/Active';
import SettingsPayments from 'pages/Settings/Payment';
import { BalanceDayListInner } from 'pages/Balance/ListInner/Day/ListInner';
import { BalanceWeekListInner } from 'pages/Balance/ListInner/Week/ListInner';
import SettingsBalance from 'pages/Settings/Balance';
import { isPermitted, useGetUserQuery } from 'services/userApi';
import { PropertyPayment } from 'pages/Properties/Details/Payment';
import { PropertyPaymentBatches } from '../../pages/Properties/Details/PaymentBatches';
import CurrentTransactions from 'pages/Transactions/CurrentTransactions';
import UpcomingTransactions from 'pages/Transactions/UpcomingTransactions';
import VendorTransactions from 'pages/Vendors/Account/Transactions';
import { PropertyTransactions } from 'pages/Properties/Details/Transactions';
import Reconciliation from '../modules/Reconciliation';
import ReconcileACHContainer from '../modules/Reconciliation/ach';
import ReconcileManual from 'pages/Reconciliation/Manual';
import PaidInvoices from 'pages/Approvals/Paid';

const AppRoutes = () => {
  const { data: user } = useGetUserQuery({});

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<AccountLogin />} />
        {isPermitted(user, 'balance-menu') && (
          <>
            <Route path="/" element={<PrivateRoute />}>
              <Route path="/balance/current" element={<Balance />} />
            </Route>
            <Route path="/" element={<PrivateRoute />}>
              <Route path="/balance/past" element={<Past />} />
            </Route>
            <Route path="/" element={<PrivateRoute />}>
              <Route path="/balance/upcoming" element={<Upcoming />} />
            </Route>
            <Route path="/" element={<PrivateRoute />}>
              <Route
                path="/balance/invoice/day/:timeRangeParams"
                element={<BalanceDayListInner />}
              />
            </Route>
            <Route path="/" element={<PrivateRoute />}>
              <Route
                path="/balance/invoice/week/:timeRangeParams"
                element={<BalanceWeekListInner />}
              />
            </Route>
            <Route path="/" element={<PrivateRoute />}>
              <Route path="/balance/payment" element={<Payment />} />
            </Route>
            <Route path="/" element={<PrivateRoute />}>
              <Route path="/blance/payment/success" element={<PaymentSuccess />} />
            </Route>
          </>
        )}

        {isPermitted(user, 'reconciliation-menu') && (
          <>
            <Route path="/" element={<PrivateRoute />}>
              <Route path="/reconcile/checks" element={<Reconciliation />} />
            </Route>
            <Route path="/" element={<PrivateRoute />}>
              <Route path="/reconcile/ach" element={<ReconcileACHContainer />} />
            </Route>
            <Route path="/" element={<PrivateRoute />}>
              <Route path="/reconcile/manual" element={<ReconcileManual />} />
            </Route>
          </>
        )}

        {isPermitted(user, 'analytics-menu') && (
          <Route path="/" element={<PrivateRoute />}>
            <Route path="/dashboard" element={<Home />} />
          </Route>
        )}

        {isPermitted(user, 'transctions-menu') && (
          <>
            <Route path="/" element={<PrivateRoute />}>
              <Route path="/payments/current" element={<CurrentTransactions />} />
            </Route>
            <Route path="/" element={<PrivateRoute />}>
              <Route path="/payments/upcoming" element={<UpcomingTransactions />} />
            </Route>
          </>
        )}

        <Route path="/" element={<PrivateRoute />}>
          <Route path="setting/team" element={<SettingTeams />} />
        </Route>
        <Route path="/" element={<PrivateRoute />}>
          <Route path="setting/payment" element={<SettingsPayments />} />
        </Route>
        <Route path="/" element={<PrivateRoute />}>
          <Route path="setting/balance" element={<SettingsBalance />} />
        </Route>

        <Route path="/" element={<PrivateRoute />}>
          <Route path="/invoices/approved" element={<Approvals />} />
        </Route>
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/invoices/submitted" element={<ToApprove />} />
        </Route>
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/invoices/paid" element={<PaidInvoices />} />
        </Route>
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/invoices/all" element={<AllApprovals />} />
        </Route>
        <Route path="/" element={<PrivateRoute />}>
          <Route path="vendors/active" element={<Vendors />} />
        </Route>
        <Route path="/" element={<PrivateRoute />}>
          <Route path="vendors/inactive" element={<InActiveVendors />} />
        </Route>
        <Route path="/" element={<PrivateRoute />}>
          <Route path="vendors/all" element={<AllVendors />} />
        </Route>
        <Route path="/" element={<PrivateRoute />}>
          <Route path="profile" element={<Profile />} />
        </Route>
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/vendors/account/:id" element={<VendorAccount />} />
        </Route>
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/vendors/earnings/:id" element={<VendorEarnings />} />
        </Route>
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/vendors/properties/:id" element={<VendorProperties />} />
        </Route>
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/vendors/payments/:id" element={<VendorPayments />} />
        </Route>
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/vendors/imported-invoices/:id" element={<VendorImportIednvoices />} />
        </Route>
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/vendors/payouts/:id" element={<VendorTransactions />} />
        </Route>

        <Route path="/" element={<PrivateRoute />}>
          <Route path="*" element={<Error404 />} />
        </Route>
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/properties/all" element={<AllProperties />} />
        </Route>
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/properties/delinquent" element={<DelinquentProperties />} />
        </Route>
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/properties/active" element={<ActiveProperties />} />
        </Route>
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/properties/profile/:id" element={<PropertyDetails />} />
        </Route>
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/properties/vendors/:id" element={<PropertyVendors />} />
        </Route>
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/properties/invoices/:id" element={<PropertyInvoices />} />
        </Route>
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/properties/payments/:id" element={<PropertyTransactions />} />
        </Route>
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/properties/payment/:id" element={<PropertyPayment />} />
        </Route>
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/properties/payment-batches/:id" element={<PropertyPaymentBatches />} />
        </Route>

        <Route path="/500" element={<Error500 />} />
        <Route path="/401" element={<Error401 />} />
        <Route path="/403" element={<Error403 />} />
        <Route path="/302" element={<Error302 />} />
        <Route path="/accept-invite/:id" element={<SignUp />} />
      </Routes>
    </div>
  );
};

export default AppRoutes;
