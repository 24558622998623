import { CellContent } from '../CellElements/CellContent/CellContent';
import { Properties } from './ColumnWidths';
import { useCurrencyFormat } from 'hooks/useCurrencyFormat';
import { MultiCheckBoxColumnFilter } from '../CellElements/Filters';
import { DataListListProps } from 'shared/models/components/base.model';
import {
  getByUserNameSorting,
  getPropertyDefautBankAccount,
  getPropertyPayments,
} from 'shared/utilities/utils';
import { PropertiesStatusDropDownCell } from '../CellElements/CellContent/PropertiesStatusDropDownCell';
import { useTitleCase } from 'hooks/useTitleCase';
import { isPermitted } from 'services/userApi';
import { StatusTag } from 'components/core/Chips/StatusTag/StatusTag';
import { handlePropertyStatus } from 'helpers/modules/helperProperties';

export const handlePropertiesTableColumn = ({
  subTabType,
  paymentMethodData,
  user,
}: DataListListProps) => {
  const propertiesTableColumns: any[] = [
    {
      Header: 'PROPERTY',
      accessor: 'name',
      wrapperClasses: Properties.ColomnWidth.nameFilter,
      headerClasses: Properties.HeaderCellWidth.nameFilter,
      headerInnerClasses: Properties.HeaderInnerDivWidth.nameFilter,
      filter: 'fuzzy',
      sortType: 'string',
      isReverseSortingIcon: true,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention,react/destructuring-assignment
        const { name } = row.row.values;

        return (
          <CellContent
            wrapperClasses={
              subTabType === 'innerVendorProperties'
                ? Properties.ColomnWidth.innerName
                : Properties.ColomnWidth.name
            }
            additionalClasses={'capitalize'}
            isWordWrapped
            isLink
            linkTarget="_self"
            linkHref={`/properties/profile/${row.row.original.id}`}
          >
            {name ? useTitleCase(name) : null}
          </CellContent>
        );
      },
    },
    {
      Header: 'STATUS',
      accessor: (row: any) => {
        return row.is_delinquent && row.status === 'ACTIVE'
          ? 'Delinquent'
          : row.status === 'INACTIVE'
          ? 'Inactive'
          : 'Active';
      },
      Filter: MultiCheckBoxColumnFilter,
      filter: 'multiSelect',
      wrapperClasses: Properties.ColomnWidth.is_delinquent,
      headerClasses: Properties.HeaderCellWidth.is_delinquent,
      gridType: 'properties',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention,react/destructuring-assignment
        const { is_delinquent, status, id } = row.row.original;

        return (
          <CellContent wrapperClasses={Properties.ColomnWidth.is_delinquent} isRemoveCellZindex>
            {isPermitted(user, 'edit-property-status') ? (
              <PropertiesStatusDropDownCell is_delinquent={is_delinquent} status={status} id={id} />
            ) : (
              <StatusTag status={handlePropertyStatus(status, is_delinquent)} />
            )}
          </CellContent>
        );
      },
    },
    {
      Header: 'UPDATED BY',
      accessor: (row: any) => getByUserNameSorting(row, true, false),
      filter: 'matches',
      sortType: 'string',
      wrapperClasses: Properties.ColomnWidth.updated_by,
      headerClasses: Properties.HeaderCellWidth.updated_by,
      headerInnerClasses: Properties.HeaderInnerDivWidth.updated_by,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const setUpdatedByUserName = getByUserNameSorting(row.row.original, true, false);

        return (
          <CellContent wrapperClasses={Properties.ColomnWidth.updated_by} isWordWrapped>
            {setUpdatedByUserName !== '' ? setUpdatedByUserName : null}
          </CellContent>
        );
      },
    },
    {
      Header: 'VENDORS',
      accessor: (row: any) => row?.payouts_by_property_id?.aggregates?.distinctCount?.account_id,
      canFilter: false,
      wrapperClasses: Properties.ColomnWidth.payouts_by_property_id,
      headerClasses: Properties.HeaderCellWidth.payouts_by_property_id,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        return (
          <CellContent wrapperClasses={Properties.ColomnWidth.payouts_by_property_id}>
            {row?.row?.original?.payouts_by_property_id?.aggregates?.distinctCount?.account_id}
          </CellContent>
        );
      },
    },
    {
      Header: 'DEFAULT BANK',
      accessor: (row: any) => getPropertyDefautBankAccount(paymentMethodData, row?.id),
      filter: 'fuzzy',
      wrapperClasses: Properties.ColomnWidth.default_bank,
      headerClasses: Properties.HeaderCellWidth.default_bank,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        const setDefaultBankAccount =
          paymentMethodData && paymentMethodData.length > 0
            ? getPropertyDefautBankAccount(paymentMethodData, row.row.original.id)
            : null;

        return (
          <CellContent wrapperClasses={`${Properties.ColomnWidth.default_bank} `}>
            {setDefaultBankAccount ? (
              <span className="normal-case text-inherit">{setDefaultBankAccount}</span>
            ) : null}
          </CellContent>
        );
      },
    },
    {
      Header: 'CODE',
      accessor: 'code',
      canFilter: false,
      disableSortBy: true,
      wrapperClasses: Properties.ColomnWidth.code,
      headerClasses: Properties.HeaderCellWidth.code,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention,react/destructuring-assignment
        // const { unpaid_payouts } = row.row.original;

        return (
          <CellContent wrapperClasses={Properties.ColomnWidth.code}>
            {row?.row?.original?.code}
          </CellContent>
        );
      },
    },
    {
      Header: 'AMOUNT IN TRANSITION',
      accessor: (row: any) => getPropertyPayments(row, 'amount_in_transition'),
      headerInnerClasses: Properties.HeaderInnerDivWidth.amount_in_transition,
      headerClasses: Properties.HeaderCellWidth.amount_in_transition,
      wrapperClasses: Properties.ColomnWidth.amount_in_transition,
      canFilter: false,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention,react/destructuring-assignment
        const { amountInTransition } = row.row.original;

        return (
          <CellContent wrapperClasses={Properties.ColomnWidth.amount_in_transition}>
            {amountInTransition?.aggregates?.sum?.total_amount
              ? useCurrencyFormat(amountInTransition?.aggregates?.sum?.total_amount)
              : null}
          </CellContent>
        );
      },
    },
    {
      Header:
        subTabType === 'innerVendorProperties' ? 'AMOUNT PAID BY PROPERTY' : 'AMOUNT RECEIVED',
      accessor: (row: any) => getPropertyPayments(row, 'customer_paid_payouts'),
      headerInnerClasses:
        subTabType === 'innerVendorProperties'
          ? Properties.HeaderInnerDivWidth.customer_paid_payouts_v2
          : Properties.HeaderInnerDivWidth.customer_paid_payouts,
      headerClasses: Properties.HeaderCellWidth.customer_paid_payouts,
      wrapperClasses: Properties.ColomnWidth.customer_paid_payouts,
      canFilter: false,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention,react/destructuring-assignment
        const { accountDebitsCompleted } = row.row.original;

        return (
          <CellContent wrapperClasses={Properties.ColomnWidth.customer_paid_payouts}>
            {accountDebitsCompleted?.aggregates?.sum?.total_amount
              ? useCurrencyFormat(accountDebitsCompleted?.aggregates?.sum?.total_amount)
              : null}
          </CellContent>
        );
      },
    },
    {
      Header: 'PAYOUTS COMPLETED',
      accessor: (row: any) => getPropertyPayments(row, 'paid_payouts'),
      headerInnerClasses: Properties.HeaderInnerDivWidth.paid_payouts,
      headerClasses: Properties.HeaderCellWidth.paid_payouts,
      wrapperClasses: Properties.ColomnWidth.paid_payouts,
      canFilter: false,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention,react/destructuring-assignment
        const { totalPayoutsCompleted } = row.row.original;

        return (
          <CellContent wrapperClasses={Properties.ColomnWidth.paid_payouts}>
            {totalPayoutsCompleted?.aggregates?.sum?.paid_amount
              ? useCurrencyFormat(totalPayoutsCompleted?.aggregates?.sum?.paid_amount)
              : null}
          </CellContent>
        );
      },
    },
  ];

  return propertiesTableColumns;
};
