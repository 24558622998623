import React, { InputHTMLAttributes } from 'react';
import classNames from 'classnames';

import Label from '../Label/Label';
import Icon from '../../../Media/Icon/Icon';

interface RadioProps extends InputHTMLAttributes<HTMLInputElement> {
  /**
   * Set Input name
   */
  name?: string;
  /**
   * Set Input label name
   */
  label?: string | undefined;
  /**
   * Optional for additional classes
   */
  additionalClasses?: string | undefined;
  id?: any;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isDeafult?: boolean;
}

/**
 * Primary UI component for user interaction
 */
const RadioButton: React.FC<RadioProps> = ({
  name,
  label,
  type = 'text',
  additionalClasses,
  onChange,
  id,
  isDeafult = true,
  ...rest
}) => {
  const defaultClasses = 'opacity-0 absolute -left-4 radio-input';
  const checkboxClasses = [defaultClasses, additionalClasses];

  return (
    <div className="relative overflow-hidden form-check">
      {isDeafult ? (
        <Label name={id} labelSpanClasses="radio-span justify-center items-center inline-flex">
          <input
            type="radio"
            name={name}
            className={classNames(checkboxClasses)}
            {...rest}
            id={id}
            onChange={onChange}
          />
          <span className="mr-3 radio-mask" />
          {label}
        </Label>
      ) : (
        <Label name={id} labelSpanClasses="radio-span-box justify-center items-center inline-flex">
          <input
            type="radio"
            name={name}
            className={classNames(checkboxClasses)}
            {...rest}
            id={id}
            onChange={onChange}
          />
          <span className="w-5 h-5 border border-t-0 border-r-0 radio-mask-box rounded-tr-md">
            <Icon iconType="OkIcon" iconColor="inherit" />
          </span>
          {label}
        </Label>
      )}
    </div>
  );
};

export default RadioButton;
