import React from 'react';
import HomeContainer from '../../components/modules/Home/Index';

/**
 * Primary UI component for user interaction
 */
const Home = () => {
  return <HomeContainer />;
};

export default Home;
