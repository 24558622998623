import moment from 'moment-timezone';

/**
 * get overAllSummary amount
 */
export const getOverAllSummaryAmount = (overallSumArray: any) => {
  let amount: string;
  if (overallSumArray[0]?.sum) {
    amount = overallSumArray[0]?.sum?.amount;
  } else {
    amount = '0';
  }
  return amount;
};

/**
 * handle additionalClasses
 * @param additionalClasses string value
 * @returns classes string or empty string
 */
export const handleAdditionalClasses = (additionalClasses?: any) => {
  return additionalClasses ? additionalClasses : '';
};

/**
 * add days for 'America/Chicago' timezone
 */
export const addDaysFunction = (date: any, days: any) => {
  const getCSTTimeZoneDate = moment(date).tz('America/Chicago');
  const result = getCSTTimeZoneDate.clone().add(days, 'day');
  return result;
};

/**
 * get current week start and end dates
 */
export const getCurrentWeekStartandEndDates = (date?: any) => {
  const dt = date ? moment(date).tz('America/Chicago') : moment().tz('America/Chicago'); //current date of week
  const wkStart = dt.clone().startOf('week');
  const wkEnd = dt.clone().endOf('week');

  return [wkStart, wkEnd];
};

/**
 * get day counts from year staterd to today
 */
export const getYTD = () => {
  const now: any = new Date();
  const start: any = new Date(now.getFullYear(), 0, 0);
  const diff: any = now - start;
  const oneDay = 1000 * 60 * 60 * 24;
  const day = Math.floor(diff / oneDay);

  return day;
};

/**
 * Check is batch paymode is instant or not
 * @param pay_mode send the paymode
 * @returns boolean value true or false
 */
export const checkIsInstandPaymode = (pay_mode: string) => {
  const isInstantPayMode =
    pay_mode === 'DEBIT_CARD' || pay_mode === 'Debit_Card' || pay_mode === 'debit_card'
      ? true
      : false;

  return isInstantPayMode;
};

/**
 * convert date in the Month Date format
 * @param date date value
 * @returns Month Date format : June 25
 */
export const dateFormatMMMSpaceDD = (date: any) => {
  return moment(date).format('MMM DD');
};

/**
 * get he time from date timestamp
 * @param date date with timestamp
 * @returns hour:minutes am/pm : 13:50 pm
 */
export const timeFormatWithoutTimeZoneForEarning = (date: any) => {
  return moment(date).format('hh:mm A');
};

/**
 * add 1 hour from given date and get the time
 * @param date date value givent time 12:50pm + adding 1 hour
 * @returns hour:minutes am/pm : 13:50 pm
 */
export const getArrivalTimeforInstantPayment = (date: any) => {
  return moment(date).add(1, 'hour').format('hh:mm A');
};

/**
 * convert date in the Month Date, Year format
 * @param date date value
 * @returns Month Date, Year format : June 25, 2023
 */
export const dateFormatWithTimeZoneForEarning = (date: any) => {
  return moment(date).format('MMM DD, YYYY ');
};
