import React, { useState } from 'react';

import DatePicker from 'react-datepicker';
import moment from 'moment';

import classNames from 'classnames';
import Icon from '../../Media/Icon/Icon';
import Typho from '../../OldComponents/Typho/Typho';
import Message from '../../Message/Message';
import { Button } from '../../Button/Button';
import {
  usePayoutNowMutation,
  useSecheduleThePaymentMutation,
} from '../../../../services/payoutsApi';
import { useElementPosition } from '../../../../hooks/useElementPosition';

export interface PayNowModalProps {
  dueAmount?: string;
  payoutNumber?: string;
  id?: string;
  scheduleDate?: any;
  isAlreadyScheduled?: boolean;
  isAutoScheduled?: boolean;
  payoutStatus?: string;
  payoutScheduledAt?: any;
  coupaInvoiceID?: string;
  offsetFromBottom?: number;
  buttonVariants?: any;
  btnDisabled?: any;
  isLeft?: any;
  isEnableScheduled?: boolean;
  isEnableScheduledToggle?: boolean;
  setEnableSelect?: any;
}

export const PayNowModal = ({
  dueAmount,
  payoutNumber,
  id,
  scheduleDate,
  isAlreadyScheduled,
  isAutoScheduled,
  payoutStatus,
  payoutScheduledAt,
  coupaInvoiceID,
  offsetFromBottom = 0,
  buttonVariants,
  btnDisabled = false,
  isLeft,
  isEnableScheduled = true,
  isEnableScheduledToggle = true,
  setEnableSelect,
}: PayNowModalProps) => {
  const [payoutNowMutation, { isLoading: isPayoutNowLoading }] = usePayoutNowMutation();

  const [payoutScheduleMutation, { isLoading: isPayoutScheduleLoading }] =
    useSecheduleThePaymentMutation();

  const [selectedDate, setSelectedDate]: any = useState(scheduleDate);
  const [modalConfirmationOpen, setModalConfirmationOpen] = useState(false);
  const [modalStatusOpen, setModalStatusOpen] = useState(false);
  const [datpickerVisible, setDatpickerVisible] = useState(
    isAlreadyScheduled || isAutoScheduled || (isEnableScheduled && isLeft)
  );
  const [errorPayNow, setErrorPayNow]: any = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const [height, boxRef]: any = useElementPosition([modalOpen, datpickerVisible]);

  // Handle the button text in the grid __
  const handleTheButtonText = () => {
    let buttonText = payoutStatus;

    if (payoutStatus === 'PAID') {
      buttonText = 'Paid';
    }

    if (payoutStatus === 'INVOICED') {
      buttonText = 'Invoiced';
    }

    if (payoutStatus === 'DENIED') {
      buttonText = 'Denied';
    }

    if (payoutStatus === 'APPROVED' && !payoutScheduledAt && !isAutoScheduled) {
      buttonText = 'Pay Invoices';
    }

    if (payoutStatus === 'APPROVED' && payoutScheduledAt) {
      buttonText = 'Scheduled';
    }

    // if (isAutoScheduled && payoutStatus === 'APPROVED' && !payoutScheduledAt) {
    //   buttonText = 'Auto Scheduled';
    // }

    return buttonText;
  };

  // Used to handle the Pay Now modal open ___
  const handlePickerOpen = (isOpen: any = false) => {
    if (payoutStatus === 'APPROVED') {
      setModalOpen(!modalOpen);
      setErrorPayNow(null);
      setSelectedDate(scheduleDate);
      if (isAlreadyScheduled) {
        setDatpickerVisible(true);
      } else {
        !isOpen && setDatpickerVisible(false);
      }
    }
  };

  // Used to handle the Pay Now modal close ___
  const handlePickerClose = () => {
    setModalOpen(false);
    setModalConfirmationOpen(false);
    setModalStatusOpen(false);

    if (!isAlreadyScheduled) {
      setSelectedDate(new Date());
      setDatpickerVisible(false);
    }
  };

  // Used to handle the clear function___
  const handleClearFunction = () => {
    setModalOpen(false);

    if (!isAlreadyScheduled) {
      setSelectedDate(new Date());
      setDatpickerVisible(false);
    }

    if (isAlreadyScheduled) {
      setSelectedDate(scheduleDate);
    }
  };

  // Used to handle the Paynow Modal Apply button___
  const handleApplyButton = () => {
    setModalConfirmationOpen(true);
  };

  // Used to handle the datepicker oonchange__
  const handleOnChange = (date: any) => {
    setSelectedDate(date);
  };

  // Used to handle the datepickervisibility also confirmthe schedule event enabled___
  const handleDatePickerVisibility = () => {
    if (!isAlreadyScheduled) {
      setSelectedDate(new Date());
    }
    setDatpickerVisible(!datpickerVisible);
  };

  // Used to handle the Ye, confirm button for paynow event___
  const handlePayNowConfirmationEvent = async () => {
    try {
      const response: any = await payoutNowMutation({ id: id });

      if (response) {
        if (!response?.error?.data?.message && !response?.error?.data?.error) {
          setModalOpen(false);
          setModalConfirmationOpen(false);
          setModalStatusOpen(true);
          setEnableSelect(false);
        } else if (response?.error?.data?.message && !response?.error?.data?.error) {
          setModalOpen(true);
          setModalConfirmationOpen(false);
          setErrorPayNow(response?.error?.data?.message);
        } else if (response?.error?.data?.error && !response?.error?.data?.message) {
          setModalOpen(true);
          setModalConfirmationOpen(false);
          setErrorPayNow(response?.error?.data?.error);
        }
      }
    } catch (e: any) {
      console.log(e);
    }
  };

  // Used to handle the Ye, confirm button for scedule event___
  const handleSceduleConfirmationEvent = async () => {
    const selectedDay = moment(selectedDate).format('DD');
    const selectedMonth = moment(selectedDate).format('MM');
    const selectedYear = moment(selectedDate).format('YYYY');

    try {
      const response: any = await payoutScheduleMutation({
        id: id,
        day: parseInt(selectedDay),
        month: parseInt(selectedMonth),
        year: parseInt(selectedYear),
      });

      if (response && !response?.error?.data?.message) {
        setModalOpen(false);
        setModalConfirmationOpen(false);
        setModalStatusOpen(true);
        setEnableSelect(false);
      }

      if (response && response?.error?.data?.message) {
        setModalOpen(true);
        setModalConfirmationOpen(false);
        setErrorPayNow(response?.error?.data?.message);
        setDatpickerVisible(true);
      }
    } catch (e: any) {
      console.log(e);
    }
  };

  // Used to ignore the confirmation___
  const handleCancelEvent = () => {
    setModalConfirmationOpen(false);
    setModalStatusOpen(false);
  };

  // Used to handle the reschedule event __
  const handleReScheduleEvent = () => {
    setModalConfirmationOpen(false);
    setModalStatusOpen(false);
    setModalOpen(true);
  };

  const handleApplyButtonText = () => {
    let buttonText = 'Pay';

    if (datpickerVisible) {
      buttonText = 'Schedule';
    }

    if (isAlreadyScheduled && datpickerVisible) {
      buttonText = 'Re-Schedule';
    }

    return buttonText;
  };

  const handleTheOffsetClasses = () => {
    let offSetClasses = '';
    const halfOfHeight = height / 2;
    if (!datpickerVisible && height > offsetFromBottom) {
      offSetClasses = 'top-auto bottom-10 !opacity-100';
    }

    if (!datpickerVisible && halfOfHeight < offsetFromBottom) {
      offSetClasses = `top-10 !opacity-100`;
    }

    if (datpickerVisible && halfOfHeight > offsetFromBottom) {
      offSetClasses = 'top-auto bottom-10 !opacity-100';
    }

    if (datpickerVisible && halfOfHeight < offsetFromBottom) {
      offSetClasses = `-top-80 !opacity-100`;
    }
    if (isLeft) {
      offSetClasses = `top-8 !opacity-100 left-0`;
    }
    return offSetClasses;
  };

  const handleTheConfirmationModalOffsetClasses = () => {
    let offSetClasses = '';
    const modalHeight = 250;

    if (modalHeight > offsetFromBottom) {
      offSetClasses = 'top-auto bottom-10 !opacity-100';
    }

    if (modalHeight < offsetFromBottom) {
      offSetClasses = 'top-10 !opacity-100';
    }

    if (isLeft) {
      offSetClasses = `top-8 !opacity-100 left-0`;
    }
    return offSetClasses;
  };

  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth();
  const day = today.getDate();
  const setMaxDate = new Date(year + 3, month, day);

  return (
    <div className="relative z-50">
      {payoutStatus !== 'PAID' && (
        <div className="flex w-full">
          {isLeft ? (
            isEnableScheduled ? (
              <Button
                buttonLabel="Schedule"
                iconAlignment="left"
                iconClass="mr-1 gray-600 !font-medium"
                iconType="CardClockIcon"
                isIconEnabled
                variants="extraSmallOutlined"
                additionalClasses={'border-none'}
                isDisabled={btnDisabled}
                onClick={() => {
                  handlePickerOpen(true);
                  setDatpickerVisible(true);
                }}
              />
            ) : (
              <Button
                variants="extraSmall"
                onClick={handlePickerOpen}
                additionalClasses="w-[114px] ml-2"
                buttonLabel={handleTheButtonText()}
                isDisabled={btnDisabled}
              />
            )
          ) : (
            <Button
              buttonLabel={handleTheButtonText()}
              additionalClasses={'min-w-[110px]'}
              onClick={handlePickerOpen}
              variants={'extraSmall'}
              isDisabled={btnDisabled}
            />
          )}
        </div>
      )}

      {payoutStatus === 'PAID' && (
        <a
          target="_blank"
          href={`/api/coupa/download-invoice/${id}`}
          rel="noreferrer"
          className={classNames([
            'flex items-center justify-center font-normal w-full ease-out duration-300 text-white bg-black-900',
            'lg:hover:!bg-black-900 text-17s rounded-lg whitespace-nowrap h-9 px-3 capitalize min-w-[110px]',
          ])}
        >
          {'Paid'}
        </a>
      )}

      {modalOpen && (
        <>
          <div
            className={classNames([
              modalConfirmationOpen ? 'pointer-events-none' : 'pointer-events-auto',
              'fixed top-0 bottom-0 left-0 right-0 z-10 bg-transparent',
            ])}
            onClick={handlePickerClose}
          />

          {!modalConfirmationOpen && !modalStatusOpen && (
            <div
              className={classNames([
                'z-20 w-[392px] bg-white rounded-lg shadow-paynowModal absolute right-1 opacity-0',
                handleTheOffsetClasses(),
              ])}
              ref={boxRef}
            >
              <div className="flex items-center justify-between w-full px-6 py-4 border-b border-solid border-gray-150">
                <Typho type="p" weight="bold" size="14s">
                  {!isAutoScheduled && !isEnableScheduled && 'Pay Invoices Now'}
                  {isAutoScheduled && !isEnableScheduled && 'Edit Auto Schedule'}
                  {isEnableScheduled && 'Schedule Payment'}
                </Typho>
                <div className="cursor-pointer" onClick={handlePickerClose}>
                  <Icon iconType={'CloseIcon'} />
                </div>
              </div>
              {errorPayNow !== null && (
                <div className="flex items-center justify-between w-full px-6 pt-4">
                  <Message type="error">{errorPayNow}</Message>
                </div>
              )}
              <div className="flex items-center justify-between w-full px-6 pt-4">
                <span className="font-bold text-14s text-black-900">Amount Due for Payment</span>
                <input
                  type="text"
                  defaultValue={dueAmount}
                  className={classNames([
                    'text-black-900 form-input text-18s font-bold  h-[48px] border border-solid rounded-md text-center',
                    'text-black placeholderColor-gray shadow-input text-14s w-[151px] !focus:outline-none pointer-events-none',
                  ])}
                  readOnly
                />
              </div>
              <div className="flex flex-col w-full px-6 pt-4 pb-6">
                {isEnableScheduledToggle && (
                  <div className="relative flex flex-row items-center justify-between w-full pt-3">
                    <div className="absolute bg-gray-150 -left-6 -right-6 h-[1px] flex top-0" />
                    <span className="font-light text-12s text-black-270">Schedule Payment</span>
                    <div
                      onClick={handleDatePickerVisibility}
                      className={classNames([
                        datpickerVisible
                          ? 'bg-black-900 border-black-900 justify-end'
                          : 'bg-gray-125 border-gray-165 justify-start',
                        'inline-flex items-center h-5 px-1 border border-solid rounded-full',
                        'cursor-pointer w-9',
                      ])}
                    >
                      <span className="flex w-3 h-3 bg-white rounded-full shadow-swithOption" />
                    </div>
                  </div>
                )}

                {datpickerVisible && (
                  <div className="block w-full px-6 py-6 mt-3 border border-solid rounded-lg border-gray-330">
                    <DatePicker
                      selected={selectedDate}
                      onChange={handleOnChange}
                      inline
                      calendarClassName="custom-picker"
                      showMonthDropdown
                      showYearDropdown
                      minDate={today}
                      maxDate={setMaxDate}
                    />
                  </div>
                )}

                <div
                  className={classNames([
                    'mt-3 relative',
                    'flex items-center justify-end w-full pt-6 mt-3 relative',
                  ])}
                >
                  <div className="absolute bg-gray-150 -left-6 -right-6 h-[1px] flex top-0" />
                  <span
                    className={classNames([
                      'text-primary-500',
                      'mr-6 font-medium cursor-pointer text-16s',
                    ])}
                    onClick={handleClearFunction}
                  >
                    Cancel
                  </span>
                  <Button
                    type={'button'}
                    buttonLabel={handleApplyButtonText()}
                    onClick={handleApplyButton}
                    variants="default"
                    additionalClasses={'w-[130px] font-medium '}
                  />
                </div>
              </div>
            </div>
          )}

          {modalConfirmationOpen && (
            <div
              className={classNames([
                'z-20 w-[370px] flex flex-col bg-white justify-center rounded-lg shadow-paynowModal absolute right-1 opacity-0',
                handleTheConfirmationModalOffsetClasses(),
              ])}
            >
              <div className="flex flex-col items-center justify-center w-full py-14">
                <div className="flex flex-col w-full mb-8 font-medium text-center text-20s text-primary-500">
                  <span>{`Are you sure to ${datpickerVisible ? 'schedule ' : 'pay'}`}</span>
                  <span>{` ${payoutNumber} Invoices ?`}</span>
                </div>
                <div className="flex flex-row justify-between w-full px-12">
                  <Button
                    type={'button'}
                    buttonLabel={'Cancel'}
                    variants="defaultOutlined"
                    onClick={handleCancelEvent}
                    additionalClasses={'w-[132px] font-medium'}
                  />
                  <Button
                    type={'button'}
                    buttonLabel={'Yes, Confirm'}
                    variants="default"
                    // eslint-disable-next-line
                    onClick={
                      datpickerVisible
                        ? handleSceduleConfirmationEvent
                        : handlePayNowConfirmationEvent
                    }
                    additionalClasses={'w-[132px] font-medium'}
                    iconType="Spin"
                    iconClass="inline-flex ml-1 w-3 h-3"
                    isIconEnabled={isPayoutNowLoading || isPayoutScheduleLoading}
                    iconAlignment={'right'}
                  />
                </div>
              </div>
            </div>
          )}
        </>
      )}

      {modalStatusOpen && (
        <>
          <div
            className={classNames([
              modalConfirmationOpen ? 'pointer-events-none' : 'pointer-events-auto',
              'fixed top-0 bottom-0 left-0 right-0 z-10 bg-transparent',
            ])}
            onClick={handlePickerClose}
          />
          <div
            className={classNames([
              'w-[370px] flex flex-col bg-white justify-center rounded-lg z-30 shadow-paynowModal absolute right-1 opacity-0',
              handleTheConfirmationModalOffsetClasses(),
            ])}
          >
            <div className="absolute z-10 cursor-pointer top-4 right-4" onClick={handlePickerClose}>
              <Icon iconType={'CloseIcon'} />
            </div>
            <div className="relative flex flex-col items-center justify-center w-full py-14">
              <Icon iconType="TickIconForSuccess" />
              <div className="flex flex-col w-full mt-4 text-center">
                <span className="flex justify-center w-full mb-1 font-medium text-20s">
                  {datpickerVisible ? 'Payment Scheduled ' : 'Paid'}
                </span>
                {datpickerVisible && (
                  <span className="font-light text-12s">{`for ${moment(selectedDate).format(
                    'MM / DD / YYYY'
                  )} at 8:00am`}</span>
                )}
              </div>
              {datpickerVisible && (
                <div className="absolute left-0 right-0 flex justify-center w-full mt-4 text-center bottom-4">
                  <span
                    className="font-medium underline cursor-pointer text-10s text-black-900"
                    onClick={handleReScheduleEvent}
                  >
                    {'re-schedule'}
                  </span>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
