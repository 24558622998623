import Icon from '../../Media/Icon/Icon';
import { Typhography } from '../../Typhography/Typhography';

export const FooterNote = () => {
  return (
    <div className="flex items-center justify-start w-full pt-3 pb-8 border-t border-solid border-grey-100">
      <Icon iconColor="inherit" iconType="InfoRoundedIcon" additionalClasses="mr-2" />
      <Typhography component="p" type="h1" color="text-grey-800 !font-normal">
        If your bank is not on the list, contact us for support: <br />
        email{' '}
        <a href="mailto:support@payup.com" className="underline text-inherit">
          support@payup.com
        </a>{' '}
        or call us at{' '}
        <a href="tel:5126674081" className="underline text-inherit">
          (512) 667 4081
        </a>
      </Typhography>
    </div>
  );
};
