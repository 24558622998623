import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { Button, Card, Typho } from 'components/core';
import HookFormsInput from '../../../core/OldComponents/FormElements/Input/HookFormsInput';
import ResendCode from '../ResendCode/ResendCode';

import { AuthResponse } from '../../../../shared/models/services/login.model';
import { CodeVerifyFormParams } from '../../../../shared/utilities/interfaceHookForms';

import { usePhoneValidation } from '../../../../hooks/usePhoneValidation';

import {
  cookieApi,
  useCreateConfirmVerificationCodeMutation,
  useCreateEmailConfirmVerificationCodeMutation,
} from '../../../../services/authApi';
import { isPermitted, useGetUserQuery } from 'services/userApi';
import { useFlags } from 'launchdarkly-react-client-sdk';

const AccountLoginCodeSchema = yup.object().shape({
  code: yup
    .string()
    .length(6, 'Looks like that code did not match. Please try again.')
    .required('Looks like that code did not match. Please try again.'),
});

interface AccountVerifyCodeProps {
  verifiedResponse: AuthResponse;
}

const AccountVerifyCode: React.FC<AccountVerifyCodeProps> = ({ verifiedResponse }) => {
  const { enableEmailLogin } = useFlags();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<CodeVerifyFormParams>({ resolver: yupResolver(AccountLoginCodeSchema) });
  const [isLoading, setIsLoading] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(true);
  const [erroState, setErroState] = useState(false);

  const [addNewPost] = useCreateConfirmVerificationCodeMutation();
  const [addNewPostEmail] = useCreateEmailConfirmVerificationCodeMutation();
  const [addCookie] = cookieApi.useAddNewPostMutation();

  const { data: user } = useGetUserQuery({});
  const getLogedInRoute = isPermitted(user, 'logedin-route');

  const appLogin = handleSubmit(async (data) => {
    try {
      setIsLoading(true);
      let response;

      if (enableEmailLogin) {
        response = await addNewPostEmail({
          code: data.code,
          email: verifiedResponse?.email,
        }).unwrap();
      } else {
        response = await addNewPost({
          code: data.code,
          phone_number: verifiedResponse?.phone_number,
        }).unwrap();
      }

      if (response?.id_token) {
        await addCookie(response?.id_token).unwrap();
        //for local use
        if (window.location.hostname === 'localhost') {
          localStorage.setItem('id_token', response.id_token);
          localStorage.setItem('user_authentication', JSON.stringify(response));
        }
        navigateToDashBoard(response);
      } else {
        setErroState(true);
      }
      setIsLoading(false);
    } catch (error: any) {
      error.status !== null ? setIsLoading(false) : setIsLoading(true);
      error.status !== null ? setErroState(true) : setErroState(false);
      error.status !== null ? setButtonDisable(true) : setButtonDisable(false);
    }
  });

  const navigateToDashBoard = (response: any) => {
    if (getLogedInRoute) {
      window.location.href = '/payments/current';
    } else {
      window.location.href = '/invoices/submitted';
    }
  };

  const onChangeHandler = (event: any) => {
    setValue('code', event.target.value.replace(/\D/g, ''));
    event.target.value.length === 6 ? setButtonDisable(false) : setButtonDisable(true);
  };

  const handleInputBorderColor = erroState && 'border-red-500 bg-lightRed';

  const verifiedResponseNumber = String(verifiedResponse?.phone_number).replace(/\D/g, '');
  const getVerifiedResponseNumber =
    verifiedResponseNumber.substring(0, 1) === '1'
      ? verifiedResponseNumber.slice(1)
      : verifiedResponseNumber;

  return (
    <React.Fragment>
      <Card additionalClasses="flex-row items-center !w-[385px]" cardType="white">
        <form onSubmit={appLogin} className="flex flex-col w-full">
          <div>
            <HookFormsInput
              {...register('code')}
              type="text"
              label="Authentication Code"
              inputVariant="large"
              error={errors?.code?.message}
              additionalClasses={String(handleInputBorderColor)}
              additionalErrorClasses="text-14s font-normal text-black-900 justify-center mt-6 flex w-full "
              isBorderedError={true}
              labelClasses="relative bg-transparent -mb-2 px-1 w-auto flex align-self-start left-3 before:content-[''] before:absolute before:left-0 before:right-0 before:top-1 before:h-[7px] before:bg-white"
              labelType="blackSmall"
              labelSpanClasses="relative z-1"
              wrapperClass="flex w-full flex-col relative items-start"
              placeholder={'enter 6 digit code'}
              onChange={onChangeHandler}
              onPaste={onChangeHandler}
              maxLength={6}
            />
          </div>
          <Typho
            type="p"
            size="14s"
            color="black"
            weight="normal"
            additionalClasses={!erroState ? 'text-center mt-6' : 'text-center mt-6 px-10'}
          >
            {!erroState ? (
              <>
                Please enter the six digit code we just sent to the number
                <span className={`ml-2 font-bold`}>
                  {usePhoneValidation(getVerifiedResponseNumber)}
                </span>
              </>
            ) : (
              <>Looks like that code did not match. Please try again.</>
            )}
          </Typho>
          <Button
            type="submit"
            buttonLabel={'SUBMIT'}
            additionalClasses="mt-6"
            isDisabled={buttonDisable}
            iconType="Spin"
            iconClass="inline-flex ml-4 w-6 h-6"
            isIconEnabled={isLoading ? true : false}
            iconAlignment={'right'}
            variants="large"
          />
        </form>
      </Card>
      <ResendCode />
    </React.Fragment>
  );
};

export default AccountVerifyCode;
