import React from 'react';
import classNames from 'classnames';
import Card from '../Card/Card';
import Typho from '../Typho/Typho';
import Icon from '../../Media/Icon/Icon';

interface AddItemCardProps {
  methodAddHandler?: any;
  cardAdditionalClasses?: string;
}

const AddItemCard: React.FC<AddItemCardProps> = ({ methodAddHandler, cardAdditionalClasses }) => {
  return (
    <div
      className={classNames(['flex w-1/3 pl-2 pr-2 mb-2', cardAdditionalClasses])}
      onClick={methodAddHandler}
    >
      <Card
        additionalClasses={`px-4 py-4 flex-col items-center justify-center w-full min-h-[138px] cursor-pointer`}
        cardType={'whiteBorder'}
      >
        <div className="inline-flex mb-2">
          <Icon iconType="AddNewitemIcon" iconColor="black-900" />
        </div>
        <Typho
          size="10s"
          color={'black'}
          weight="medium"
          additionalClasses="mb-2 text-center"
          type="p"
        >
          {'Add new method'}
        </Typho>
      </Card>
    </div>
  );
};

export default AddItemCard;
