import { createApi } from '@reduxjs/toolkit/query/react';
import { prepareBaseQuery } from '../shared/configs/config';
import { InitialState } from '../shared/models/services/vendors.model';

export const propertiesApi = createApi({
  reducerPath: 'propertiesApi',
  tagTypes: ['Properties'],
  baseQuery: prepareBaseQuery(),
  endpoints: (builder) => ({
    // Update Vendor Term
    getProperties: builder.query({
      query: (obj: any) => ({
        url: `/query/properties`,
        body: obj,
        method: 'POST',
      }),
      providesTags: ['Properties'],
    }),
    getProperty: builder.query({
      query: (obj: any) => ({
        url: `/property/${obj.id}`,
        body: obj,
        method: 'POST',
      }),
      providesTags: ['Properties'],
    }),
    getDelinquentTrendingProperties: builder.query({
      query: (state = InitialState) => ({
        url: `/reports/event-trail`,
        body: state,
        method: 'POST',
      }),
      providesTags: ['Properties'],
    }),
    getPropertiesSummaryGraphData: builder.query({
      query: (state) => ({
        url: `/query/properties/summary/graph`,
        body: state,
        method: 'POST',
      }),
      providesTags: ['Properties'],
    }),
    updatePropertiesDetails: builder.mutation({
      query: (obj) => {
        return {
          url: `/property/${obj.id}`,
          body: {
            phone: obj?.phone,
            status: obj?.status,
            is_delinquent: obj?.is_delinquent,
          },
          method: 'POST',
        };
      },
      invalidatesTags: ['Properties'],
    }),
    updatePropertyDefaultPaymentMethod: builder.mutation({
      query: (obj) => {
        return {
          url: `/property/${obj.id}`,
          body: {
            default_payment_method_id: obj?.pmId,
          },
          method: 'POST',
        };
      },
      invalidatesTags: ['Properties'],
    }),
    reconcilationUpdate: builder.mutation({
      query: (obj) => {
        return {
          url: `/payment-detail`,
          body: {
            data: [
              {
                pay_ids: obj.pay_ids,
                paid_with: obj.paid_with,
                property_id: obj.property_id,
              },
            ],
          },
          method: 'POST',
        };
      },
      invalidatesTags: ['Properties'],
    }),
    unReconcilationUpdate: builder.mutation({
      query: (obj) => {
        return {
          url: `/payment-detail/unreconcile`,
          body: {
            data: [
              {
                pay_ids: obj.pay_ids,
                paid_with: obj.paid_with,
                property_id: obj.property_id,
              },
            ],
          },
          method: 'POST',
        };
      },
      invalidatesTags: ['Properties'],
    }),
  }),
});

export const {
  useGetPropertiesQuery,
  useGetDelinquentTrendingPropertiesQuery,
  useGetPropertiesSummaryGraphDataQuery,
  useUpdatePropertiesDetailsMutation,
  useReconcilationUpdateMutation,
  useUnReconcilationUpdateMutation,
  useUpdatePropertyDefaultPaymentMethodMutation,
  useGetPropertyQuery,
} = propertiesApi;
