import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import Label from '../../../../core/OldComponents/FormElements/Label/Label';
import { Classes } from '../../../../../shared/configs/componentsClasses';
import { useImportInvoicesMutation } from '../../../../../services/payoutsApi';
import { Button, LoadingSpinner, Message } from 'components/core';

const ImportVendorProfile = ({ accountId = '' }) => {
  const { register, handleSubmit, setValue } = useForm();
  const inputDefaultClasses = Classes.input.default;
  const [trigger, { isSuccess, error, isLoading }]: any = useImportInvoicesMutation();
  const [isMessageVisible, setIsMessageVisible] = useState(false);

  const onSubmitNewPaymentMethod = async (data: any) => {
    const invoiceNumbers = data?.comma_separated_invoice_numbers?.split(',');
    const { status } = data;
    const response = await trigger({ invoiceNumbers, accountId, status });

    if (response) {
      setIsMessageVisible(true);
      setValue('comma_separated_invoice_numbers', '');
      setTimeout(() => {
        setIsMessageVisible(false);
      }, 6000);
    }
  };

  return (
    <>
      {isLoading && <LoadingSpinner />}
      <div className="flex flex-col w-full">
        {isMessageVisible && (
          <Message type={isSuccess ? 'success' : 'error'}>
            {isSuccess ? 'Invoices are successfully imported' : error?.data?.error}
          </Message>
        )}

        <div className="flex flex-col w-full px-3 pt-4 pb-5 mb-3 border border-solid rounded-lg border-grey-100 max-w-[800px]">
          <form onSubmit={handleSubmit(onSubmitNewPaymentMethod)} className={'w-full flex'}>
            <div className="pl-2 pr-2 max-w-[calc(800px - 350px)] w-full">
              <div className={'flex w-full flex-col'}>
                <Label
                  name={'comma_separated_invoice_numbers'}
                  labelType={'blackSmall'}
                  label="Comma Separated Invoice numbers"
                  additionalClasses={'mb-2'}
                />
                <input
                  type="text"
                  {...register('comma_separated_invoice_numbers')}
                  className={inputDefaultClasses}
                  required
                />
              </div>
            </div>
            <div className="pl-2 pr-2 w-[250px]">
              <div className={'flex w-full flex-col'}>
                <Label
                  name={'status'}
                  labelType={'blackSmall'}
                  label="status in Coupa"
                  additionalClasses={'mb-2'}
                />
                <select
                  {...register('status')}
                  className={`form-select outline-none h-[42px] border border-solid border-grey-100 rounded-lg text-black placeholderColor-gray  text-14s text-grey-900 px-4 focus:!border-grey-100 focus:!shadow-none w-[200px]`}
                  required
                >
                  <option value="approved">Approved</option>
                  <option value="pending_approval">Pending Approval</option>
                </select>
              </div>
            </div>
            <div className="flex items-center pl-2 pr-2 w-[150px] pt-[22px]">
              <Button
                variants="default"
                type="submit"
                buttonLabel={`Import`}
                additionalClasses="w-full"
              />
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
export default ImportVendorProfile;
