import { createApi } from '@reduxjs/toolkit/query/react';
import { prepareBaseQuery } from '../shared/configs/config';

export const pastBalanceApi = createApi({
  reducerPath: 'pastBalanceApi',
  baseQuery: prepareBaseQuery(),
  endpoints: (builder) => ({
    getPastBalanceApi: builder.query({
      query: () => ({
        url: `/customer/past-balance`,
        method: 'GET',
      }),
    }),
    getPendingBalanceApi: builder.query({
      query: (state) => ({
        url: `/balances`,
        body: state,
        method: 'POST',
      }),
    }),
    getPastBalanceHeroStats: builder.query({
      query: (state) => ({
        url: `/balances/past`,
        body: state,
        method: 'POST',
      }),
    }),
    getUpcomingBalanceHeroStats: builder.query({
      query: (state) => ({
        url: `/balances/upcoming`,
        body: state,
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useGetPastBalanceApiQuery,
  useGetPendingBalanceApiQuery,
  useGetPastBalanceHeroStatsQuery,
  useGetUpcomingBalanceHeroStatsQuery,
} = pastBalanceApi;
