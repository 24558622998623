import classNames from 'classnames';
import { getTermDueDays, getTermName } from 'helpers/arrayFormating';
import { useState } from 'react';
import { useUpdateNetTermMutation } from 'services/payoutsApi';
import { Button } from '../../Button/Button';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import Icon from '../../Media/Icon/Icon';
import Message from '../../Message/Message';
import { Typhography } from '../../Typhography/Typhography';
import Modal from '../Modal/Modal';

export interface SingleTermEditModalProps {
  defaultTermID?: string;
  onClose?: () => void;
  payoutTerms?: any;
  payoutId?: string;
  isNetTermsLoading?: boolean;
}

export const SingleTermEditModal = ({
  onClose,
  defaultTermID,
  payoutTerms,
  payoutId,
  isNetTermsLoading,
}: SingleTermEditModalProps) => {
  const [selectedTerm, setSelectedTerm] = useState(null);
  const [modalConfirmationOpen, setModalConfirmationOpen] = useState(false);
  const [modalStatusOpen, setModalStatusOpen] = useState(false);
  const [error, setError] = useState(null);

  const [updateNetTermMutation, { isLoading: isUpdateNetTermLoading }] = useUpdateNetTermMutation();

  const handlePickerClose = () => {
    onClose && onClose();
    setSelectedTerm(null);
    setModalConfirmationOpen(false);
    setModalStatusOpen(false);
  };

  /**
   * handle the term selection from the modal __
   */
  const handleTermSelection = (e: any) => {
    setSelectedTerm(e.target.value);
  };

  /**
   * Handle the apply button click after the term selection __
   */
  const handleApplyButton = () => {
    setModalConfirmationOpen(true);
    setModalStatusOpen(false);
  };

  /**
   * Handle the Cancel link click event __
   */
  const handleBackEvent = () => {
    setModalConfirmationOpen(false);
    setModalStatusOpen(false);
    setSelectedTerm(null);
  };

  /**
   * Handle the api request to get the call __
   */
  const handleEditTermSubmit = async () => {
    const editParamsIndividual: any = { payoutId: payoutId, net_term: selectedTerm };

    try {
      let response: any = null;
      response = await updateNetTermMutation(editParamsIndividual);

      if (response && !response?.error?.data?.message) {
        setTimeout(() => {
          onClose && onClose();
        }, 3000);
        setModalConfirmationOpen(false);
        setModalStatusOpen(true);
      }

      if (response && response?.error?.data?.message) {
        setModalConfirmationOpen(false);
        setError(response?.error?.data?.message);
      }
    } catch (e: any) {
      console.log(e);
    }
  };

  const handleModalWidth = !modalConfirmationOpen && !modalStatusOpen ? '!w-[392px]' : '!w-[280px]';

  return (
    <Modal
      onClose={handlePickerClose}
      isOpen={true}
      modalAdditionalClasses={classNames([
        handleModalWidth,
        'flex flex-col bg-white justify-center rounded-lg shadow-paynowModal px-2 py-6',
      ])}
    >
      <>
        {(isNetTermsLoading || isUpdateNetTermLoading) && <LoadingSpinner />}
        {!modalConfirmationOpen && !modalStatusOpen && (
          <>
            <div
              className="absolute z-10 cursor-pointer top-6 right-6 text-grey-600"
              onClick={onClose}
            >
              <Icon iconColor="inherit" iconType="CloseIcon" />
            </div>

            <div>
              <div className="flex flex-col w-full px-4">
                <Typhography component="p" type="h2" color="text-grey-900 mb-3 uppercase">
                  {'Please select your payout terms'}
                </Typhography>
                <p className="mt-0 mb-6 font-light text-14s text-grey-900">
                  For existing invoices, we offer a 50% discount on payout fees!
                </p>
              </div>
              {error && (
                <div className="flex flex-col w-full px-4">
                  <Message type="error">{error}</Message>
                </div>
              )}
              <div className="flex flex-col w-full px-4 h-[260px] overflow-y-auto">
                {payoutTerms &&
                  payoutTerms.map((term: any) => {
                    const handleActiveState = term.active
                      ? 'pointer-events-auto'
                      : 'pointer-events-none opacity-60';

                    const handleLabelClasses = () => {
                      let labelClasses = '';

                      if (defaultTermID === term.id) {
                        labelClasses = '!border-primary-500 pointer-events-none';
                      }

                      if (selectedTerm === term.id) {
                        labelClasses = '!border-primary-500 pointer-events-none bg-grey-50';
                      }

                      return labelClasses;
                    };

                    return (
                      <div
                        className={classNames(['flex flex-col w-full mb-2', handleActiveState])}
                        key={term.id}
                      >
                        <input
                          type="radio"
                          name="netTerms"
                          id={term.id}
                          className="absolute w-0 h-0 p-0 m-0 overflow-hidden opacity-0 -left-4 terms_selection"
                          onChange={handleTermSelection}
                          defaultValue={term.id}
                          data-days={term.due_days}
                        />
                        <label
                          htmlFor={term.id}
                          className={classNames([
                            'flex w-full py-1 border border-solid rounded-xl border-grey-100 min-h-[44px]',
                            'px-4 items-center justify-between cursor-pointer',
                            handleLabelClasses(),
                          ])}
                        >
                          {
                            <div className="flex items-center">
                              <div className="w-[100px] flex">
                                <span
                                  className={classNames([
                                    'inline-flex font-bold leading-3 text-primary-500',
                                    term.id === 'old_net30' || term.id === 'net30'
                                      ? 'text-10s'
                                      : 'text-12s',
                                  ])}
                                >
                                  {term.id === 'net30'
                                    ? `When property pays (Est. 30+ days)`
                                    : term.id === 'old_net30'
                                    ? term.name
                                    : `${term.due_days} Days Payout`}
                                </span>
                              </div>
                              {term.isAccountDefault && (
                                <span
                                  className={classNames([
                                    'inline-flex items-center justify-center px-2 font-medium uppercase whitespace-nowrap text-8s h-4',
                                    'text-primary-500 rounded-2xl bg-grey-100 ml-1',
                                  ])}
                                >
                                  Default
                                </span>
                              )}
                            </div>
                          }

                          <div>
                            {term.category === 'old' && term.id !== 'old_net30' && (
                              <span className="mr-1 font-light line-through text-12s text-grey-600">
                                {term.processing_fee !== 0 && term.processing_fee * 2 + '%'}
                              </span>
                            )}
                            <span className="font-medium text-12s text-primary-500 opacity-80 ml-[6px]">
                              {`${
                                term.processing_fee !== 0
                                  ? term.processing_fee.toFixed(1)
                                  : term.processing_fee
                              }% Fee`}
                            </span>
                          </div>
                        </label>
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className="flex items-center w-full px-4 pt-4">
              <Button
                type={'button'}
                buttonLabel={'Apply'}
                variants="default"
                onClick={handleApplyButton}
                isDisabled={!selectedTerm}
                additionalClasses={` w-full ${
                  !selectedTerm ? 'pointer-events-none' : 'pointer-events-auto'
                }`}
              />
            </div>
          </>
        )}

        {modalConfirmationOpen && (
          <div className={classNames(['flex flex-col items-center justify-center w-full'])}>
            <div className="flex flex-col w-full px-6 mb-6 font-medium text-center text-18s text-primary-500">
              <span>{`Are you sure you want to apply ${getTermName(selectedTerm)} Term ?`}</span>
            </div>
            <div className="flex flex-row items-center justify-center w-full px-6">
              <span
                className="inline-flex mr-4 font-bold cursor-pointer text-14s text-primary-500"
                onClick={handleBackEvent}
              >
                Back
              </span>

              <Button
                type={'button'}
                buttonLabel={'Yes'}
                variants={'default'}
                onClick={handleEditTermSubmit}
                additionalClasses={'max-w-[100px] w-full'}
              />
            </div>
          </div>
        )}

        {modalStatusOpen && (
          <div className={classNames(['flex flex-col items-center justify-center w-full'])}>
            <div className="relative flex flex-col items-center justify-center w-full text-primary-500">
              <Icon iconType="TickIconForSuccess" iconColor="inherit" />
              <div className="flex flex-col w-full mt-3 text-center">
                <span className="flex justify-center w-full font-medium text-18s text-primary-500">
                  {'Net Term Updated!'}
                </span>
                <span className="flex justify-center w-full px-6 mt-3 font-meduim text-14s text-primary-500">
                  {`Your payment for the selected invoice will be made in ${getTermDueDays(
                    selectedTerm
                  )} days.`}
                </span>
              </div>
            </div>
          </div>
        )}
      </>
    </Modal>
  );
};
