import { useParams } from 'react-router-dom';

import Wrapper from '../../../layouts/Wrapper/Wrapper';
import Headers from '../../../core/Headers/Headers';

import { useGetPropertiesQuery } from 'services/properties';

import { CONFIG } from 'shared/configs/config';
import PropertyTransactionsList from './Transactions/List';

export const PropertyTransactionsContainer = () => {
  const { id } = useParams();
  const filterParams = {
    limit: CONFIG.DATA_OVERALL_LIMIT,
    offset: 0,
    filter: {
      id: {
        equalTo: '"' + id + '"',
      },
    },
  };

  const { data, isSuccess } = useGetPropertiesQuery(filterParams);

  return (
    <Wrapper additionalClasses="flex-col" pageTitle={'Properties Details'} isBackButtonVisible>
      {isSuccess && (
        <>
          <Headers
            headingContent={data.properties.nodes[0].name}
            headerWrapperClasses="border-none"
          />

          <PropertyTransactionsList />
        </>
      )}
    </Wrapper>
  );
};
