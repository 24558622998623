import { createApi } from '@reduxjs/toolkit/query/react';
import {
  InitialState,
  NestedResponse,
  Vendors,
  VendorsListResponse,
} from '../shared/models/services/vendors.model';
import { prepareBaseQuery } from '../shared/configs/config';
import {
  accountCompany,
  accountIndividual,
} from '../shared/models/services/account.service.mapper';
import { PropertyParams } from 'shared/utilities/interface';

export const vendorsApi = createApi({
  reducerPath: 'vendorsApi',
  tagTypes: ['Vendors'],
  baseQuery: prepareBaseQuery(),
  endpoints: (builder) => ({
    getVendors: builder.query<Vendors<NestedResponse>, VendorsListResponse | void>({
      query: (state = InitialState) => ({
        url: `/query/accounts`,
        body: state,
        method: 'POST',
      }),
      providesTags: ['Vendors'],
    }),
    // Update Vendor Term
    updateVendorsNetTerm: builder.mutation({
      query: (obj: any) => ({
        url: `/account/${obj.id}`,
        method: 'POST',
        body: {
          default_payment_term_id: obj.selectedNetTerm,
        },
      }),
      invalidatesTags: ['Vendors'],
    }),
    getVendorsInfo: builder.query({
      query: (id) => ({
        url: `/account/${id}`,
        method: 'GET',
      }),
      providesTags: ['Vendors'],
    }),
    getVendorsAccountInfo: builder.query({
      query: (id) => ({
        url: `/account/external-account/${id}`,
        method: 'GET',
      }),
      providesTags: ['Vendors'],
    }),
    getPayoutTermInfo: builder.mutation({
      query: ({ amount, payoutId }) => ({
        url: `/account/payout-terms`,
        method: 'GET',
        params: {
          amount: amount,
          payoutId: payoutId,
        },
      }),
    }),
    getCurrentPayoutTermInfo: builder.mutation({
      query: ({ amount, payoutId }) => ({
        url: `/account/payout-terms-current-invoices`,
        method: 'GET',
        params: {
          amount: amount,
          payoutId: payoutId,
        },
      }),
    }),
    deleteVendor: builder.mutation({
      query: (id) => ({
        url: `/account/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Vendors'],
    }),
    updateVendorsAccount: builder.mutation({
      query: (data) => {
        const getdata = data.obj;
        const payloadData: any = data.isCompany
          ? accountCompany(getdata)
          : accountIndividual(getdata);
        const isMappingRequired = data.isMappingRequired;
        const convertedDataJson = JSON.stringify(payloadData);
        const convertedArrayData = JSON.parse(convertedDataJson);

        return {
          url: `/account/${data.obj.id}`,
          method: 'POST',
          body: isMappingRequired ? convertedArrayData : data.obj,
        };
      },
      invalidatesTags: ['Vendors'],
    }),
    updateVendorBankAccount: builder.mutation({
      query: (data) => {
        const getdata = data.obj;
        return {
          url: `/account/add-external-account/${data.id}`,
          method: 'POST',
          body: getdata,
        };
      },
      invalidatesTags: ['Vendors'],
    }),
    getVendorsTrendingActive: builder.query<Vendors<NestedResponse>, VendorsListResponse | void>({
      query: (state = InitialState) => ({
        url: `/reports/event-trail`,
        body: state,
        method: 'POST',
      }),
      providesTags: ['Vendors'],
    }),
    getVendorsTrendingInactive: builder.query<Vendors<NestedResponse>, VendorsListResponse | void>({
      query: (state = InitialState) => ({
        url: `/reports/event-trail`,
        body: state,
        method: 'POST',
      }),
      providesTags: ['Vendors'],
    }),
    getVendorsTrendingTotal: builder.query<Vendors<NestedResponse>, VendorsListResponse | void>({
      query: (state = InitialState) => ({
        url: `/query/accounts`,
        body: state,
        method: 'POST',
      }),
      providesTags: ['Vendors'],
    }),
    getAllBankDetails: builder.mutation({
      query: (state) => ({
        url: `/account/bank-accounts?account_id=${state.id}`,
        method: 'GET',
      }),
      invalidatesTags: ['Vendors'],
    }),
    createBankAccount: builder.mutation({
      query: (state) => ({
        url: `/account/add-external-account/${state.id}`,
        method: 'POST',
        body: JSON.stringify({
          account_holder_name: state.account_holder_name,
          routing_number: state.routing_number,
          account_number: state.account_number,
          default_for_currency: state.default_for_currency,
          nickname: state.nickname,
          force: false,
        }),
        headers: { api_version: 'v2' },
      }),
      invalidatesTags: ['Vendors'],
    }),
    deleteBankAccount: builder.mutation({
      query: (state) => ({
        url: `/account/bank-accounts/${state.id}`,
        body: { account_id: state.vendor_id },
        method: 'DELETE',
      }),
      invalidatesTags: ['Vendors'],
    }),
    updateDefaultBankAccount: builder.mutation({
      query: (state) => ({
        url: `/account/bank-accounts/default/${state.id}`,
        body: { account_id: state.vendor_id },
        method: 'POST',
      }),
      invalidatesTags: ['Vendors'],
    }),
    establishFinancialConnectionSession: builder.mutation({
      query: (state) => ({
        url: `financial-connection/session`,
        method: 'POST',
        body: { account_id: state.vendor_id },
      }),
      invalidatesTags: ['Vendors'],
    }),
    linkExternalAccount: builder.mutation({
      query: (data) => ({
        url: `account/external-account/link`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Vendors'],
    }),
    getVendorsProperties: builder.mutation({
      query: (state) => ({
        url: `/query/properties`,
        body: state,
        method: 'POST',
      }),
      invalidatesTags: ['Vendors'],
    }),
    getVendorsSummaryGraphData: builder.query({
      query: (state) => ({
        url: `/query/accounts/summary/graph`,
        body: state,
        method: 'POST',
      }),
      providesTags: ['Vendors'],
    }),
    updateVendorStatus: builder.mutation({
      query: ({ id, status }: PropertyParams) => ({
        url: `/account/${id}/status`,
        body: {
          status: status,
        },
        method: 'POST',
      }),
      invalidatesTags: ['Vendors'],
    }),
    getNotificationPreferences: builder.query({
      query: () => ({
        url: `/query/notification-preferences`,
        method: 'POST',
        body: {
          limit: 1000,
          offset: 0,
          filter: {},
        },
      }),

      providesTags: ['Vendors'],
    }),
    updateNotificationPreferences: builder.mutation({
      query: ({ id, is_active }) => ({
        url: `/notification-preference/${id}`,
        method: 'POST',
        body: {
          is_active: is_active,
        },
      }),
      invalidatesTags: ['Vendors'],
    }),
    updateBankAccountFields: builder.mutation({
      query: (state) => ({
        url: `/account/bank-accounts/${state.field}/${state.id}`,
        method: 'POST',
        body: state.body,
      }),
      invalidatesTags: ['Vendors'],
    }),
    getAllPayModes: builder.query({
      query: () => ({
        url: `/account/payment-modes/all`,
        method: 'GET',
      }),
      providesTags: ['Vendors'],
    }),
  }),
});

export const {
  useGetVendorsQuery,
  useGetVendorsInfoQuery,
  useDeleteVendorMutation,
  useGetPayoutTermInfoMutation,
  useUpdateVendorsNetTermMutation,
  useUpdateVendorsAccountMutation,
  useUpdateVendorBankAccountMutation,
  useGetVendorsAccountInfoQuery,
  useGetCurrentPayoutTermInfoMutation,
  useGetVendorsTrendingActiveQuery,
  useGetVendorsTrendingInactiveQuery,
  useGetVendorsTrendingTotalQuery,
  useGetAllBankDetailsMutation,
  useCreateBankAccountMutation,
  useDeleteBankAccountMutation,
  useUpdateDefaultBankAccountMutation,
  useEstablishFinancialConnectionSessionMutation,
  useLinkExternalAccountMutation,
  useGetVendorsPropertiesMutation,
  useGetVendorsSummaryGraphDataQuery,
  useUpdateVendorStatusMutation,
  useGetNotificationPreferencesQuery,
  useUpdateNotificationPreferencesMutation,
  useUpdateBankAccountFieldsMutation,
  useGetAllPayModesQuery,
} = vendorsApi;
