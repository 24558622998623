/**
 * make company phone with mask input
 * @param event
 * @param setValue
 * @param getValues
 */
export const onPhoneChangeHandler = (event: any, setValue: any, getValues: any) => {
  let val = event.target.value;
  if (val.length > (getValues('phone') as string).length) {
    val = val.replace(/\D/g, '');
    let num = '';
    if (val.length < 3) {
      num = `${val.substring(0, 3)}`;
    } else {
      num = `(${val.substring(0, 3)}) ${val.substring(3, 6)}-${val.substring(6, 10)}`;
    }

    num = num.trim();
    setValue('phone', num, { shouldValidate: true }); // Set value
  } else {
    setValue('phone', val, { shouldValidate: true }); // Set value
  }
};
