import React, { FC, forwardRef, InputHTMLAttributes } from 'react';
import Label from '../Label/Label';
import InputValidation from '../InputValidation/InputValidation';

import classNames from 'classnames';
import { Classes } from '../../../../../shared/configs/componentsClasses';

type InputType = 'text' | 'email' | 'password' | 'name' | 'date' | 'number';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  /**
   * Set Input type
   */
  type?: InputType;
  /**
   * Set new variant of the input
   */
  inputVariant?: 'default' | 'large';
  /**
   * Set Input name
   */
  id?: string;
  /**
   * Set Input name
   */
  name: string;
  /**
   * Set the Label Type
   */
  labelType?: 'default' | 'black' | 'smallSelect' | 'blackSmall';
  /**
   * Set Input label name
   */
  label?: string | undefined;
  /**
   * Ser Error message
   */
  error?: string | undefined;
  /**
   * Ser Error message
   */
  isBorderedError?: boolean;
  /**
   * Set Wrapper classes, new classes will overide the existing default classes
   */
  wrapperClass?: string | undefined;
  /**
   * Optional for additional classes
   */
  additionalClasses?: string | undefined;
  /**
   * Enable read only
   */
  readonly?: boolean;
  /**
   * LabelClasses
   */
  labelClasses?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  /**
   * Set label only
   */
  onlyLabel?: boolean;
  /**
   * inner span Classes
   */
  labelSpanClasses?: string;
  /**
   * add additional InputValidation Classes
   */
  additionalErrorClasses?: string;
  isAdditionalErrorInput?: boolean;
}

const handleInputElementClasses = (_inputVariant?: 'default' | 'large') => {
  switch (_inputVariant) {
    case 'default':
      return Classes.input.default;
    case 'large':
      return Classes.input.large;
    default:
      break;
  }
};

/**
 * Primary UI component for user interaction
 */
const Input: FC<InputProps> = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      id,
      name,
      error,
      isBorderedError = false,
      label,
      labelType = 'black',
      wrapperClass = Classes.input.wrapper,
      type = 'text | date',
      additionalClasses,
      labelClasses,
      onChange,
      readOnly = false,
      placeholder,
      onlyLabel = false,
      inputVariant = 'default',
      labelSpanClasses,
      additionalErrorClasses,
      isAdditionalErrorInput = false,
      ...props
    },
    ref
  ) => {
    const handleReadOnly = readOnly
      ? Classes.input.readonly
      : handleInputElementClasses(inputVariant);
    const handleBorderError = error && isBorderedError && 'border-red-500 bg-lightRed';

    const inputClasses = [handleReadOnly, handleBorderError, additionalClasses];
    return (
      <div className={wrapperClass}>
        {label && (
          <Label
            name={name}
            labelType={labelType}
            label={label}
            additionalClasses={labelClasses}
            labelSpanClasses={labelSpanClasses}
          />
        )}
        <input
          ref={ref}
          aria-invalid={error ? 'true' : 'false'}
          type={type}
          className={classNames(inputClasses)}
          placeholder={placeholder}
          id={id}
          readOnly={readOnly}
          onChange={onChange}
          {...props}
        />
        {error && error !== '' && !isBorderedError && !isAdditionalErrorInput && (
          <InputValidation additionalClasses={Classes.input.error} message={error} />
        )}
        {isAdditionalErrorInput && error && <div className={additionalErrorClasses}>{error}</div>}
      </div>
    );
  }
);

export default Input;
