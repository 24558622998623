import React from 'react';
import EmptyList from '../../../core/EmptyList/EmptyList';

interface ListEmptyProps {
  additionalClasses?: string;
  subText?: string;
}

const ListEmpty: React.FC<ListEmptyProps> = ({
  additionalClasses,
  subText = 'There are no records of transactions for the selected time period.',
}) => {
  return (
    <EmptyList
      topContent="No Activity"
      subContent={subText}
      emptyListAdditionalClasses={additionalClasses}
    />
  );
};

export default ListEmpty;
