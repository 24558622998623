import { createApi } from '@reduxjs/toolkit/query/react';
import { CONFIG, prepareBaseQuery } from '../shared/configs/config';
import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

export const authApi = createApi({
  reducerPath: 'authApi',
  tagTypes: ['Auth'],
  baseQuery: prepareBaseQuery(),
  endpoints: (builder) => ({
    createVerificationCode: builder.mutation({
      query: (phone: string) => ({
        url: '/verify/code',
        method: 'POST',
        body: {
          phone_number:
            phone.indexOf('+') > -1
              ? phone
              : `+${CONFIG.COUNTRY_PREFIX}${phone.replace(/\D/g, '')}`,
        },
      }),
    }),
    createEmailVerificationCode: builder.mutation({
      query: (email: string) => ({
        url: '/verify/code',
        method: 'POST',
        body: {
          email: email,
        },
      }),
    }),
    createConfirmVerificationCode: builder.mutation({
      query: (data: any) => ({
        url: '/verify/confirm',
        method: 'POST',
        body: {
          phone_number: data.phone_number,
          code: data.code,
        },
      }),
    }),
    createEmailConfirmVerificationCode: builder.mutation({
      query: (data: any) => ({
        url: '/verify/confirm',
        method: 'POST',
        body: {
          email: data.email,
          code: data.code,
        },
      }),
    }),
  }),
});
export const {
  useCreateVerificationCodeMutation,
  useCreateConfirmVerificationCodeMutation,
  useCreateEmailVerificationCodeMutation,
  useCreateEmailConfirmVerificationCodeMutation,
} = authApi;

export const authTokenApi = createApi({
  reducerPath: 'authTokenApi',
  tagTypes: ['AuthToken'],
  baseQuery: prepareBaseQuery(),
  endpoints: (builder) => ({
    createAuthLogin: builder.mutation({
      query: (token: string) => ({
        url: `/auth`,
        method: 'POST',
        body: {},
      }),
    }),
  }),
});

const cookieApi = createApi({
  reducerPath: 'cookieApi',
  baseQuery: fetchBaseQuery({ baseUrl: CONFIG.BASE_URL }),
  endpoints: (builder) => ({
    addNewPost: builder.mutation({
      query: (id_token) => ({
        url: '/auth',
        method: 'POST',
        headers: {
          Authorization: `Bearer ${id_token}`,
          'Content-Type': 'application/json',
        },
      }),
    }),
  }),
});

const logoutApi = createApi({
  reducerPath: 'logoutApi',
  baseQuery: prepareBaseQuery(),
  endpoints: (builder) => ({
    addNewPost: builder.mutation({
      query: (id_token) => ({
        url: '/logout',
        method: 'POST',
      }),
    }),
  }),
});

export { cookieApi, logoutApi };
