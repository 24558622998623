import { AllPropertiesContainer } from 'components/modules/Properties/All/All';
import React from 'react';

/**
 * Primary UI component for user interaction
 */
const AllProperties = () => {
  return <AllPropertiesContainer />;
};

export default AllProperties;
