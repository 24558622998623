import React from 'react';
import Typho from '../Typho/Typho';
import { Button } from '../../Button/Button';
import { RemoveTrashIcon } from 'components/core/Media/Icons/Icons';

//import Select from '../FormElements/Select/Select';

export interface UserRoleProps {
  id: string;
  name?: string;
  email?: string;
  joinedPeriod?: string;
  role?: string;
  handleOnChange?: any;
  options: any;
  readOnly: boolean;
  onClickButton?: any;
  onClickUpdateUserStatus?: any;
  isRemoveIconVisible?: boolean;
}

export const UserRole = ({
  id,
  name,
  email,
  joinedPeriod,
  role,
  options,
  handleOnChange,
  readOnly = true,
  onClickButton,
  onClickUpdateUserStatus,
  isRemoveIconVisible,
}: UserRoleProps) => {
  return (
    <div className="flex flex-row items-center justify-between w-full mb-5">
      <div className="flex flex-row items-center justify-start w-1/3">
        <div className="flex flex-col">
          <Typho type="span" color="black" weight="medium" size="14s" additionalClasses="mb-1">
            {name}
          </Typho>
          <Typho type="span" color="gray720" weight="light" size="12s" additionalClasses="m-0">
            {email}
          </Typho>
        </div>
      </div>
      {joinedPeriod === 'Invited' && (
        <div className="flex flex-row items-center justify-start mr-10">
          <div className="flex flex-col">
            <Button
              buttonLabel={'Resend'}
              variants="extraSmall"
              isIconEnabled={false}
              additionalClasses="h-8 !text-14s min-w-[115px]"
              onClick={onClickButton}
            />
          </div>
        </div>
      )}
      <div className="flex flex-row items-center">
        <Typho
          type="span"
          color="black"
          weight="medium"
          size="12s"
          additionalClasses="mr-7 whitespace-nowrap"
        >
          {joinedPeriod}
        </Typho>
        <select
          value={role}
          name={id}
          disabled={readOnly}
          onChange={handleOnChange}
          className="form-select h-8 text-13s text-black-760 appearence-none placeholderColor-gray shadow-none bg-gray-110 font-bold border-none rounded-md min-w-[120px]"
        >
          {options.map((option: any, index: any) =>
            option === role ? (
              <option selected value={option} key={index}>
                {option}
              </option>
            ) : (
              <option value={option} key={index}>
                {option}
              </option>
            )
          )}
        </select>
        {isRemoveIconVisible ? (
          <div className="flex flex-col ml-2 cursor-pointer" onClick={onClickUpdateUserStatus}>
            <RemoveTrashIcon />
          </div>
        ) : (
          <div className="flex flex-col ml-2 cursor-auto pointer-events-none opacity-20">
            <RemoveTrashIcon />
          </div>
        )}
      </div>
    </div>
  );
};
