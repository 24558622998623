import React from 'react';
import { useNavigate } from 'react-router-dom';

import Card from '../../../core/OldComponents/Card/Card';
import Wrapper from '../../../layouts/Wrapper/Wrapper';
import Typho from '../../../core/OldComponents/Typho/Typho';
import { Button } from 'components/core';

interface ErrorContainerProps {
  /**
   * Set redirection
   */
  redirectURL?: string;
  /**
   * Error Type
   */
  errorType?: 403 | 404 | 500 | 401 | 302 | string;
  /**
   * Error Text
   */
  errorText?: string;
  /**
   * Set the button label
   */
  buttonLabel?: string;
  /**
   * Set redirection to Login
   */
  isRedirectToLogin?: boolean;
}

const ErrorContainer: React.FC<ErrorContainerProps> = ({
  redirectURL = '/balance/current',
  errorType,
  errorText,
  buttonLabel,
  isRedirectToLogin = false,
}) => {
  const navigate = useNavigate();
  const buttonClickHandler = () => {
    navigate(redirectURL);
  };

  const signOutClickHandler = () => {
    localStorage.removeItem('id_token');
    localStorage.removeItem('balance_settings');
    localStorage.removeItem('user_authentication');
    return !localStorage.getItem('id_token') && navigate('/');
  };

  return (
    <Wrapper additionalClasses="items-center justify-center">
      <Card
        cardType="lightGrayHalf"
        additionalClasses="max-w-[545px] px-12 py-12 flex-col items-center jutify-center"
      >
        <Typho
          type="p"
          color="gray720"
          additionalClasses="text-[80px] mb-2 leading-[88px] text-center"
          weight="bold"
        >
          {errorType}
        </Typho>
        <Typho
          type="p"
          color="gray720"
          size="20s"
          weight="light"
          additionalClasses="mb-6 text-center px-6"
        >
          {errorText}
        </Typho>
        <Button
          buttonLabel={buttonLabel}
          additionalClasses="max-w-[415px] px-4"
          onClick={isRedirectToLogin ? signOutClickHandler : buttonClickHandler}
        />
      </Card>
    </Wrapper>
  );
};

export default ErrorContainer;
