import { useState } from 'react';

export const serverSidePaginationActive = false;
export const serverSidePaginationActiveForInvoices = true;
export const serverSidePaginationActiveForProperties = false;
export const serverSidePaginationActiveForVendors = false;

export const useServerSidePagination = (pageSizeOptions = [25, 50, 75, 100, 125, 150, 200]) => {
  const intitalPaginationState = {
    pageIndex: 0,
    pageSize: pageSizeOptions[0],
    prevButtonVisible: false,
    nextButtonVisible: false,
    isPaginationVisible: false,
  };

  const [paginationState, setPaginationState] = useState(intitalPaginationState);

  const previousButtonClick = () => {
    setPaginationState((paginationState: any) => ({
      ...paginationState,
      pageIndex:
        paginationState.pageIndex > 0 ? paginationState.pageIndex - 1 : paginationState.pageIndex,
    }));
  };

  const nextButtonClick = () => {
    setPaginationState((paginationState: any) => ({
      ...paginationState,
      pageIndex: paginationState.pageIndex + 1,
    }));
  };

  const setPageSize = (value: number) => {
    setPaginationState((paginationState: any) => ({
      ...paginationState,
      pageIndex: 0,
      pageSize: value,
    }));
  };

  return [pageSizeOptions, paginationState, previousButtonClick, nextButtonClick, setPageSize];
};
