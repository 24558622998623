import Modal from '../Modal/Modal';
import { usePdfViewer } from '../../../../hooks/usePdfViewer';
import * as pdfjs from 'pdfjs-dist';
import { FileHandlerButtons } from '../../FileHandlerButtons/FileHandlerButtons';
import Icon from '../../Media/Icon/Icon';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import Message from '../../Message/Message';
import classNames from 'classnames';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export interface InvoicePDFModalProps {
  onCloseEvent?: () => void;
  payoutId?: string;
}

export const InvoicePDFModal = ({ onCloseEvent, payoutId }: InvoicePDFModalProps) => {
  const url = `/api/coupa/download-invoice/${payoutId}`;

  const { loading, pdfDoc, canvasContainerRef, error } = usePdfViewer({
    pdfUrl: url,
  });

  return (
    <>
      {/** Loader */}
      {loading && !error && <LoadingSpinner />}

      {/** Load Error Message into Modal */}
      {error && !pdfDoc && (
        <Modal
          isOpen
          onClose={onCloseEvent}
          modalAdditionalClasses={classNames([
            '!w-[612px] max-h-[95vh] overflow-y-auto overflow-x-hidden pt-12 pb-10 rounded-lg bg-white flex-col justify-center px-8 min-h-[170px]',
          ])}
        >
          <div
            className="absolute cursor-pointer top-7 right-7 text-grey-600 "
            onClick={onCloseEvent}
          >
            <Icon iconColor="inherit" iconType="CloseIcon" />
          </div>
          <Message title="Preview Invoice" type="error" additionalClasses="mb-0">
            Preview currently not available.
          </Message>
        </Modal>
      )}

      {/** Load PDF into Modal */}
      {!loading && pdfDoc && (
        <Modal
          isOpen
          onClose={onCloseEvent}
          modalAdditionalClasses={classNames([
            '!w-[612px] max-h-[95vh] overflow-y-auto overflow-x-hidden pt-7 pb-8 rounded-lg bg-white flex-col justify-center min-h-[600px]',
          ])}
        >
          <div
            className="absolute cursor-pointer top-7 right-7 text-grey-600 "
            onClick={onCloseEvent}
          >
            <Icon iconColor="inherit" iconType="CloseIcon" />
          </div>

          <div className="w-auto top-6 right-[72px] absolute">
            <FileHandlerButtons isPrintLink printURL={url} isDownloadLink />
          </div>

          <div
            id="pdf-canvas-container"
            ref={canvasContainerRef}
            className="max-h-[95vh] overflow-y-auto"
          />
        </Modal>
      )}
    </>
  );
};
