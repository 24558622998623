import { UpcomingTransactionsContainer } from 'components/modules/Transactions/UpcomingTransactions';

/**
 * Primary UI component for user interaction
 */
const UpcomingTransactions = () => {
  return <UpcomingTransactionsContainer />;
};

export default UpcomingTransactions;
