import VendorPaymentsContainer from 'components/modules/Vendors/Account/Payments';

/**
 * Primary UI component for user interaction
 */
const VendorPayments = () => {
  return <VendorPaymentsContainer />;
};

export default VendorPayments;
