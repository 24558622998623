import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { Typhography, Button, Input, Grid, Icon, Message, LoadingSpinner } from 'components/core';

import { phoneNumberValidation } from '../../../../../../hooks/usePhoneNumberValidation';
import { PropertyParams } from '../../../../../../shared/utilities/interface';
import { ERROR_MESSAGES } from '../../../../../../constants/Validation';

import { onPhoneNumberChangeHandlerNew } from '../../../../../../helpers/profileHelper';
import stateData from '../../../../../../constants/state.json';
import { EditPropertyProfileFormProps } from 'shared/models/components/base.model';
import { dtoToPropertyFormValues } from 'shared/utilities/dto';
import { useUpdatePropertiesDetailsMutation } from 'services/properties';
import { propertiesStatusDropDownData } from 'constants/statusDropDownData';
import { handlePropertyUpdateQueryStates } from 'helpers/modules/helperProperties';

const EditPropertyProfileForm = ({ data, onCloseEvent }: EditPropertyProfileFormProps) => {
  const { id } = useParams();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const [
    updateProperties,
    {
      isError: updateError,
      isSuccess: updateSuccess,
      error: updateErrorMessage,
      isLoading: updateLoading,
    },
  ]: any = useUpdatePropertiesDetailsMutation();

  const [detailSubmitted, setDetailSubmitted] = useState(false);
  const [originalData, setOriginalData]: any = useState();

  /**
   * expand the default validation schema for phone and dob custom validations
   */
  const profileSchema = yup
    .object({
      // ...PropertyProfileSchemaObj,
      phone: yup
        .string()
        .required(ERROR_MESSAGES.REQUIRED)
        .test('len', ERROR_MESSAGES.PHONE_NUMBER_INVALID, (value) =>
          phoneNumberValidation(value, detailSubmitted)
        ),
    })
    .required();

  /**
   * react hook forms & yap init setup
   */
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useForm<PropertyParams>({
    resolver: yupResolver(profileSchema),
    defaultValues: { phone: '' },
  });

  React.useEffect(() => {
    dtoToPropertyFormValues(setValue, data);
    setOriginalData(data);
  }, [data]);

  /**
   * update basic information of account
   */
  const appSubmit = handleSubmit(async (data: any) => {
    const filterObj = handlePropertyUpdateQueryStates(data?.status);
    const response = await updateProperties({
      ...filterObj,
      phone: data?.phone?.replace(/[^0-9]/g, ''),
      id: id,
    });

    if (!response?.error) {
      setTimeout(() => {
        onCloseEvent && onCloseEvent();
        dtoToPropertyFormValues(setValue, originalData);
      }, 2500);
    }
  });

  return (
    <div className="flex flex-col w-full">
      {/**
       * Handle loader
       */}
      {updateLoading && <LoadingSpinner />}

      <Typhography component="p" type="h2" color="text-grey-900 mb-6 uppercase">
        {'Edit Property Details'}
      </Typhography>

      {(updateError || updateSuccess) && (
        <Message
          type={updateSuccess ? 'success' : 'error'}
          title="Property Details"
          additionalClasses="mb-6"
        >
          {updateSuccess
            ? 'Your property details are successfully updated.'
            : updateErrorMessage?.data?.message}
        </Message>
      )}

      <div className="absolute cursor-pointer top-7 right-4 text-grey-600" onClick={onCloseEvent}>
        <Icon iconColor="inherit" iconType="CloseIcon" />
      </div>

      <div className="flex flex-col w-full">
        <form onSubmit={appSubmit} className="flex flex-col w-full">
          <div className="flex flex-col w-full">
            <Grid columnCount={2} columnGap={2}>
              <Controller
                control={control}
                name="name"
                render={({ field: { value } }) => (
                  <Input
                    id="name"
                    labelName={'Property Name'}
                    type="text"
                    label="Property Name"
                    {...register('name')}
                    value={value}
                    readOnly
                  />
                )}
              />

              <Controller
                control={control}
                name="manager"
                render={({ field: { value } }) => (
                  <Input
                    id="manager"
                    labelName={'manager'}
                    type="text"
                    label="Contact"
                    {...register('manager')}
                    value={value}
                    readOnly
                  />
                )}
              />
            </Grid>

            <Controller
              control={control}
              name="street_1"
              render={({ field: { value } }) => (
                <Input
                  id="street_1"
                  labelName="street_1"
                  label={'Address'}
                  type="text"
                  {...register('street_1')}
                  value={value}
                  readOnly
                />
              )}
            />

            <Grid columnCount={2} columnGap={2}>
              <Controller
                control={control}
                name="city"
                render={({ field: { value } }) => (
                  <Input
                    id="city"
                    type="text"
                    label={'City'}
                    labelName="City"
                    {...register('city')}
                    value={value}
                    readOnly
                  />
                )}
              />

              <div className="relative flex flex-col w-full mb-4 form-input-wrapper focused">
                <select
                  {...register('state')}
                  id="state"
                  className="form-select outline-none h-[42px] border border-solid border-grey-100 opacity-50 pointer-events-none rounded-lg text-black placeholderColor-gray  text-14s text-grey-900 px-4 focus:!border-grey-100 focus:!shadow-none"
                  size={1}
                >
                  {stateData &&
                    stateData.map((item, index) => (
                      <option key={index} value={item.abbreviation}>
                        {item.abbreviation} - {item.name}
                      </option>
                    ))}
                </select>
                <label
                  htmlFor={'state'}
                  className={'text-14s font-normal text-grey-600 top-3 left-4 absolute'}
                >
                  {'State'}
                </label>
              </div>
            </Grid>

            <Grid columnCount={2} columnGap={2}>
              <Controller
                control={control}
                name="code"
                render={({ field: { value } }) => (
                  <Input
                    id="code"
                    type="text"
                    label={'Code'}
                    labelName="Code"
                    {...register('code')}
                    value={value}
                    readOnly
                    additionalClasses={'capitalize'}
                  />
                )}
              />
              <Controller
                control={control}
                name="company_id"
                render={({ field: { value } }) => (
                  <Input
                    id="company_id"
                    type="text"
                    label={'Company Id'}
                    labelName="company_id"
                    {...register('company_id')}
                    value={value}
                    readOnly
                    additionalClasses={'capitalize'}
                  />
                )}
              />
            </Grid>

            <Controller
              control={control}
              name="company_name"
              render={({ field: { value } }) => (
                <Input
                  id="company_name"
                  type="text"
                  label={'Company Name'}
                  labelName="company_name"
                  {...register('company_name')}
                  value={value}
                  readOnly
                  additionalClasses={'capitalize'}
                />
              )}
            />

            <Grid columnCount={2} columnGap={2}>
              <Controller
                control={control}
                name="phone"
                render={({ field: { value } }) => (
                  <Input
                    id="phone"
                    labelName={'Phone'}
                    type="text"
                    label="Phone"
                    {...register('phone')}
                    error={errors?.phone?.message}
                    maxLength={14}
                    onChange={(event) => onPhoneNumberChangeHandlerNew(event, setValue, getValues)}
                    value={value}
                  />
                )}
              />

              <div className="relative flex flex-col w-full mb-4 form-input-wrapper focused">
                <select
                  {...register('status')}
                  id="status"
                  className="form-select outline-none h-[42px] border border-solid border-grey-100 rounded-lg text-black placeholderColor-gray  text-14s text-grey-900 px-4 focus:!border-grey-100 focus:!shadow-none"
                  size={1}
                >
                  {propertiesStatusDropDownData &&
                    propertiesStatusDropDownData.map((item, index) => (
                      <option key={index} value={item.statusVal}>
                        {item.status}
                      </option>
                    ))}
                </select>
                <label
                  htmlFor={'status'}
                  className={'text-14s font-normal text-grey-600 top-3 left-4 absolute'}
                >
                  {'Status'}
                </label>
              </div>
            </Grid>
          </div>

          <div className="flex w-full pt-4">
            <Button
              type="submit"
              buttonLabel={`Update`}
              additionalClasses="w-full"
              onClick={() => {
                setDetailSubmitted(true);
              }}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditPropertyProfileForm;
