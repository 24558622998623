import { useCurrencyFormat } from 'hooks/useCurrencyFormat';
import { Grid } from '../../Grid/Grid';
import { StatusBlock } from '../../StatusBlock/StatusBlock';

export const VedorsInvoicesBar = ({ data, defaultSortingText }: any) => {
  const isPermitToVisbleAllRange =
    defaultSortingText === '500' || defaultSortingText === 'All' || defaultSortingText === 'YTD';
  return (
    <Grid columnCount={2} additionalClasses={'w-full mb-2'}>
      <StatusBlock
        firstValue={data.all_payouts?.totalCount}
        secondValue={!isPermitToVisbleAllRange ? '+' + data.payouts?.totalCount + ' new' : ''}
        thirdValue={!isPermitToVisbleAllRange ? defaultSortingText : ''}
        blockHeading={'Invoices'}
        isRemoveBorderPadding
        isIconVisible={false}
      />
      <StatusBlock
        firstValue={useCurrencyFormat(data.payouts?.aggregates?.sum.amount)}
        secondValue={!isPermitToVisbleAllRange ? '+' + data.payouts?.change_percentage + '%' : ''}
        thirdValue={!isPermitToVisbleAllRange ? defaultSortingText : ''}
        blockHeading={'Amount'}
        isIconVisible={!isPermitToVisbleAllRange}
      />
    </Grid>
  );
};
