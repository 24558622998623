import { useNavigate } from 'react-router-dom';
import { useGetUserQuery } from 'services/userApi';

import { Typhography } from 'components/core';
import Icon from 'components/core/Media/Icon/Icon';

export const TopNavigation = () => {
  const navigate = useNavigate();
  const { data, isSuccess } = useGetUserQuery({});

  return (
    <div className="inline-flex items-center">
      <div className="inline-flex mr-4 cursor-pointer" onClick={() => navigate('/setting/team')}>
        <Icon iconColor="inherit" iconType="Setting" />
      </div>

      {isSuccess && (data?.first_name || data?.last_name) && (
        <div
          className="relative inline-flex items-center justify-center bg-white rounded-full cursor-pointer w-7 h-7"
          onClick={() => navigate('/profile')}
        >
          <Typhography
            type="h10Bold"
            component="span"
            color="text-grey-900"
          >{`${data?.first_name?.substring(0, 1)}${data?.last_name?.substring(0, 1)}`}</Typhography>

          <div className="absolute top-0 right-0 w-2 h-2 bg-green-400 border-2 border-solid rounded-full border-primary-75" />
        </div>
      )}
    </div>
  );
};
