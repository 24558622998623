export const handlerSelectBoxClasses = (type?: 'small' | 'medium', dropDownState?: boolean) => {
  switch (type) {
    case 'small':
      return {
        defaultDropDownWrapperClasses: 'top-8',
        defaultDropDownIconColor: 'text-additional-black-450 relative top-[2px]',
        defaultPreviewerClasses: `pb-[2px] rounded-xl h-6`,
        defaultTextColor: 'text-additional-black-450',
        optionClasses: 'font-medium text-additional-black-450 text-14s px-1 py-[6px]',
        showDropDownStateClasses: dropDownState
          ? 'border-primary-75 bg-primary-75'
          : ' border-primary-75 bg-white',
      };
    case 'medium':
      return {
        defaultDropDownWrapperClasses: 'top-full',
        defaultDropDownIconColor: 'text-grey-600',
        defaultPreviewerClasses: `rounded-lg h-7`,
        defaultTextColor: 'text-primary-500 capitalize',
        optionClasses: 'font-normal text-grey-900 text-12s px-2 py-2 capitalize',
        showDropDownStateClasses: dropDownState
          ? 'border-grey-100 bg-grey-50'
          : ' border-grey-100 bg-white',
      };
    default:
      break;
  }
};
