import moment from 'moment';
import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './DatePicker.css';
import { Classes } from '../../../shared/configs/componentsClasses';
import InputValidation from '../FormElements/InputValidation/InputValidation';

interface NewDatePickerProps {
  selectedDate: any;
  setSelectedDate: any;
  error: string;
  isValid?: boolean;
  isMaxDate?: boolean;
  labelName?: string;
  label?: string;
  maxDate?: any;
}

export const CustomDatePicker = ({
  selectedDate,
  setSelectedDate,
  error,
  isValid,
  isMaxDate,
  labelName,
  maxDate,
  label,
}: NewDatePickerProps) => {
  const date: any = moment().subtract(13, 'years').format();
  return (
    <div className="relative flex flex-col items-center w-full mb-4 text-14s">
      <div className="flex flex-col w-full form-date-picker ">
        <DatePicker
          selected={selectedDate}
          onChange={setSelectedDate}
          dateFormat="MM/dd/yyyy"
          showYearDropdown
          scrollableYearDropdown
          showMonthDropdown
          yearDropdownItemNumber={100}
          placeholderText="mm/dd/yyyy"
          maxDate={isMaxDate ? (maxDate ? maxDate : new Date(date)) : null}
          className=""
        />
        {label && (
          <label
            htmlFor={labelName}
            className={'font-normal text-grey-600 left-4 absolute form-label'}
          >
            {label}
          </label>
        )}
      </div>
      {error && <InputValidation additionalClasses={`${Classes.input.error}`} message={error} />}
    </div>
  );
};
