export const maskedInput = (val: any): string => {
  let num = '';
  if (val) {
    val = val?.replace(/\D/g, '');

    if (val.length < 3) {
      num = `(${val.substring(0, 3)}`;
    } else {
      num = `${val.substring(0, 3)}-${val.substring(3, 6)}-${val.substring(6, 10)}`;
    }
    num = num.trim();
  }

  return num;
};
