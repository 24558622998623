import React from 'react';
import classNames from 'classnames';

interface CountTagProps {
  /**
   * Set the child nodes and components
   */
  children?: React.ReactNode;
  /**
   * Optional | Set the additional classes
   */
  additionalClasses?: string;
  /**
   * Optional | enable the underline
   */
  isUnderLine?: boolean;
}

/**
 * Primary UI component for user interaction
 */
const CountTag: React.FC<CountTagProps> = ({
  children = '13',
  additionalClasses,
  isUnderLine = false,
}) => {
  const handleUnderline = isUnderLine && 'underline';
  const handleDefaultClasses =
    'text-14s text-black-900 font-bold px-2 py-1 rounded-[10px] bg-gray-110 inline-flex';
  const tagClasses = [additionalClasses, handleUnderline, handleDefaultClasses];
  return (
    <>
      <span className={classNames(tagClasses)}>{children}</span>
    </>
  );
};

export default CountTag;
