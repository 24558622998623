import classNames from 'classnames';
import LoadingSpinner from 'components/core/LoadingSpinner/LoadingSpinner';
import {
  ApprovedIcon,
  PaymentArrivedIcon,
  PaymentInTransitIcon,
  PaymentOnTheWayIcon,
  PaymentTriggeredIcon,
  SubmittedIcon,
} from 'components/core/Media/Icons/Icons';
import Modal from 'components/core/Modals/Modal/Modal';
import { timeline } from 'constants/inits';
import { ReactNode } from 'react';
import { useGetInvoiceDetailsQuery, useGetTransferDetailsQuery } from 'services/payoutsApi';
import {
  TimeLineDetailsProps,
  TimeLineItemProps,
  TimeLineModalProps,
} from 'shared/models/components/base.model';
import {
  checkIsInstandPaymode,
  dateFormatWithTimeZoneForEarning,
  dateFormatMMMSpaceDD,
  getArrivalTimeforInstantPayment,
  timeFormatWithoutTimeZoneForEarning,
} from 'shared/utilities/dataConversion';

/**
 * Time line item  component
 * @returns component UI
 */
const TimeLineItem = ({
  itemName,
  dateText,
  timeText,
  position,
  isActive = false,
  hideConnector = false,
}: TimeLineItemProps) => {
  const getIcon = (): ReactNode => {
    switch (position) {
      case 1:
        return <SubmittedIcon isActive={isActive} />;
      case 2:
        return <PaymentInTransitIcon isActive={isActive} />;
      case 3:
        return <ApprovedIcon isActive={isActive} />;
      case 4:
        return <PaymentTriggeredIcon isActive={isActive} />;
      case 5:
        return <PaymentOnTheWayIcon isActive={isActive} />;
      case 6:
        return <PaymentArrivedIcon isActive={isActive} />;
      default:
        return <SubmittedIcon isActive={isActive} />;
    }
  };

  return (
    <div className="flex flex-col ml-1">
      {position === 1 || hideConnector ? null : (
        <div
          className={`w-[1px] h-6 ml-3 ${isActive ? ' bg-primary-100' : 'bg-grey-300 opacity-60'}`}
        />
      )}
      <div className="flex flex-row items-center flex-1">
        {getIcon()}
        <div className="flex flex-col ml-4">
          <p className={`mb-1 text-grey-700 text-14s font-medium ${isActive ? '' : 'opacity-60'}`}>
            {itemName}
          </p>
          <div className="flex flex-row flex-1">
            <p className={`mb-1 text-grey-500 text-12s font-thin ${isActive ? '' : 'opacity-60'}`}>
              {dateText}
            </p>
            {timeText ? (
              <p
                className={`mb-1 text-grey-500 text-12s font-thin ml-[3px] ${
                  isActive ? '' : 'opacity-60'
                }`}
              >
                <span>• </span>
                {isActive ? timeText : 'Estimated'}
              </p>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * Render the batch timeline
 */
const BatchTimeLine = ({ timeLineDetails }: { timeLineDetails: TimeLineDetailsProps }) => {
  const getBatchStatus = timeLineDetails?.status;
  const isInstantPayMode = checkIsInstandPaymode(timeLineDetails?.method ?? '');

  const isSubmittedPayout = getBatchStatus === 'pending';
  const isApprovedPayout = getBatchStatus === 'approved';
  const isPaidPayout = getBatchStatus === 'paid';
  const isInTransitPayout = timeLineDetails?.estimated_approved_date ?? false;

  const dateFirstStep = timeLineDetails?.invoice_submitted_date;
  const dateSecondStep = timeLineDetails?.estimated_approved_date;
  const dateThirdStep =
    timeLineDetails?.approved_at_date ?? timeLineDetails?.estimated_approved_date;
  const dateFourthStep = timeLineDetails?.paid_at_date ?? timeLineDetails?.estimated_payout_date;
  const isPaymentEstArrivalDateisHigher =
    timeLineDetails?.payment_arrival_estimated_date &&
    timeLineDetails?.estimated_approved_date &&
    timeLineDetails?.payment_arrival_estimated_date >= timeLineDetails?.estimated_approved_date
      ? true
      : false;

  const dateFifthStepForInstantPayment = `${dateFormatMMMSpaceDD(
    timeLineDetails?.payment_arrival_estimated_date
  )}, ${timeFormatWithoutTimeZoneForEarning(
    timeLineDetails?.payment_arrival_estimated_date
  )} CST - ${getArrivalTimeforInstantPayment(timeLineDetails?.payment_arrival_estimated_date)} CST`;
  const dateFifthStepForNonInstantPayment = `${dateFormatMMMSpaceDD(
    isPaymentEstArrivalDateisHigher
      ? timeLineDetails?.estimated_approved_date
      : timeLineDetails?.payment_arrival_estimated_date
  )} - ${dateFormatMMMSpaceDD(
    isPaymentEstArrivalDateisHigher
      ? timeLineDetails?.payment_arrival_estimated_date
      : timeLineDetails?.estimated_approved_date
  )}`;
  const dateFifthStep = isInstantPayMode
    ? dateFifthStepForInstantPayment
    : dateFifthStepForNonInstantPayment;

  const dateSixthStep =
    timeLineDetails?.actual_arrival_date ?? timeLineDetails?.payment_arrival_estimated_date;

  return (
    <>
      <TimeLineItem
        itemName={timeline.batch.step1Title}
        dateText={dateFormatWithTimeZoneForEarning(dateFirstStep)}
        timeText={timeFormatWithoutTimeZoneForEarning(dateFirstStep)}
        position={1}
        isActive={
          isSubmittedPayout || isApprovedPayout || isPaidPayout || isInTransitPayout ? true : false
        }
      />

      <TimeLineItem
        itemName={timeline.batch.step2Title}
        dateText={`${dateFormatMMMSpaceDD(
          timeLineDetails?.invoice_submitted_date
        )} - ${dateFormatWithTimeZoneForEarning(dateSecondStep)}`}
        timeText={timeFormatWithoutTimeZoneForEarning(dateSecondStep)}
        position={2}
        isActive={isInTransitPayout || isApprovedPayout ? true : false}
      />

      <TimeLineItem
        itemName={timeline.batch.step3Title}
        dateText={dateFormatWithTimeZoneForEarning(dateThirdStep)}
        timeText={timeFormatWithoutTimeZoneForEarning(dateThirdStep)}
        position={3}
        isActive={isApprovedPayout || isPaidPayout ? true : false}
      />

      <TimeLineItem
        itemName={timeline.batch.step4Title}
        dateText={dateFormatWithTimeZoneForEarning(dateFourthStep)}
        timeText={timeFormatWithoutTimeZoneForEarning(dateFourthStep)}
        position={4}
        isActive={isPaidPayout}
      />

      <TimeLineItem
        itemName={timeline.batch.step5Title}
        dateText={dateFifthStep}
        timeText={
          isInstantPayMode
            ? undefined
            : timeFormatWithoutTimeZoneForEarning(timeLineDetails?.payment_arrival_estimated_date)
        }
        position={5}
        isActive={isPaidPayout}
      />

      <TimeLineItem
        itemName={timeline.batch.step6Title}
        dateText={
          !isPaidPayout && isInstantPayMode
            ? `${dateFormatMMMSpaceDD(
                timeLineDetails?.payment_arrival_estimated_date
              )}, ${getArrivalTimeforInstantPayment(
                timeLineDetails?.payment_arrival_estimated_date
              )} CST`
            : dateFormatWithTimeZoneForEarning(dateSixthStep)
        }
        timeText={timeFormatWithoutTimeZoneForEarning(dateSixthStep)}
        position={6}
        isActive={isPaidPayout}
      />
    </>
  );
};

/**
 * Render the invoice timeline
 */
export const InvoiceTimeline = ({
  invoiceDetails,
  isDatesPendingInvoice = false,
}: {
  invoiceDetails: any;
  isDatesPendingInvoice?: boolean;
}) => {
  const isApproved = invoiceDetails?.approved_at_date ?? false;
  const dateForApproved =
    invoiceDetails?.approved_at_date ?? invoiceDetails?.estimated_approved_date;
  const dateForTriggeredAndOnTheWay =
    invoiceDetails?.paid_at_date ?? invoiceDetails?.estimated_payout_date;
  const dateForArrived =
    invoiceDetails?.payment_arrival_estimated_date ?? dateForTriggeredAndOnTheWay;
  const dateForFundsRecived = invoiceDetails?.received_from_customer ?? null;
  const dateForArrivedForInstantPayment = invoiceDetails?.actual_arrival_date ?? null;

  const isPayModeCheck = invoiceDetails?.method === 'check' ? true : false;
  const dateForCheckMailed = invoiceDetails?.check_mailed_on ?? invoiceDetails?.paid_at_date;
  const dateForCheckPaid =
    invoiceDetails?.check_paid_on ?? invoiceDetails?.payment_arrival_estimated_date;

  const termArr = invoiceDetails?.netTermChangeTimeline;
  const isInstantPayment = invoiceDetails?.method === 'debit_card';

  return (
    <>
      <TimeLineItem
        itemName={timeline.invoice.invSubmittedText}
        dateText={dateFormatWithTimeZoneForEarning(invoiceDetails?.invoice_submitted_date)}
        timeText={timeFormatWithoutTimeZoneForEarning(invoiceDetails?.invoice_submitted_date)}
        position={1}
        isActive={invoiceDetails?.invoice_submitted_date ?? false}
      />
      {/* {invoiceTermsTimeline()} */}
      <TimeLineItem
        itemName={timeline.invoice.invApproveText}
        dateText={
          isDatesPendingInvoice && !isApproved
            ? timeline.invoice.waitingForCustomerToApprove
            : dateFormatWithTimeZoneForEarning(dateForApproved)
        }
        timeText={
          isDatesPendingInvoice && !isApproved
            ? undefined
            : timeFormatWithoutTimeZoneForEarning(dateForApproved)
        }
        position={3}
        isActive={isApproved}
        hideConnector={termArr?.length > 0}
      />
      {/**
       * Timeline for Funds Recived for Instant payment
       */}
      {isInstantPayment && invoiceDetails?.received_from_customer && (
        <TimeLineItem
          itemName={timeline.invoice.fundsRecivedFromCustomer}
          dateText={
            isDatesPendingInvoice
              ? timeline.invoice.pendingCustomerPay
              : dateFormatWithTimeZoneForEarning(dateForFundsRecived)
          }
          timeText={
            isDatesPendingInvoice
              ? undefined
              : timeFormatWithoutTimeZoneForEarning(dateForFundsRecived)
          }
          position={4}
          isActive={invoiceDetails?.received_from_customer ?? false}
        />
      )}

      <TimeLineItem
        itemName={timeline.invoice.paymentTrigger}
        dateText={
          isDatesPendingInvoice
            ? timeline.invoice.pendingCustomerPay
            : dateFormatWithTimeZoneForEarning(dateForTriggeredAndOnTheWay)
        }
        timeText={
          isDatesPendingInvoice
            ? undefined
            : timeFormatWithoutTimeZoneForEarning(dateForTriggeredAndOnTheWay)
        }
        position={isInstantPayment ? 5 : 4}
        isActive={invoiceDetails?.paid_at_date ?? false}
      />

      {/**
       * Timeline for rest of the Instant payment
       */}
      {!isInstantPayment && !isPayModeCheck && (
        <>
          <TimeLineItem
            itemName={timeline.invoice.paymentOntheWay}
            dateText={
              isDatesPendingInvoice
                ? timeline.invoice.pendingCustomerPay
                : `${dateFormatMMMSpaceDD(
                    dateForTriggeredAndOnTheWay
                  )} - ${dateFormatWithTimeZoneForEarning(dateForArrived)}`
            }
            timeText={isDatesPendingInvoice ? undefined : timeline.invoice.estimated}
            position={5}
            isActive={invoiceDetails?.paid_at_date ?? false}
          />
          <TimeLineItem
            itemName={timeline.invoice.paymentArrived}
            dateText={
              isDatesPendingInvoice
                ? timeline.invoice.pendingCustomerPay
                : dateFormatWithTimeZoneForEarning(dateForArrived)
            }
            timeText={
              isDatesPendingInvoice
                ? undefined
                : timeFormatWithoutTimeZoneForEarning(dateForArrived)
            }
            position={6}
            isActive={invoiceDetails?.status === 'paid'}
          />
        </>
      )}

      {isPayModeCheck && (
        <>
          <TimeLineItem
            itemName={timeline.invoice.paymentOntheWay}
            dateText={
              !dateForCheckMailed
                ? timeline.invoice.pendingCustomerPay
                : `${dateFormatMMMSpaceDD(dateForCheckMailed)} - ${dateFormatWithTimeZoneForEarning(
                    dateForArrived
                  )}`
            }
            timeText={dateForCheckMailed ? undefined : timeline.invoice.estimated}
            position={5}
            isActive={dateForCheckMailed}
          />
          <TimeLineItem
            itemName={timeline.invoice.paymentArrived}
            dateText={
              !dateForCheckPaid
                ? timeline.invoice.pendingCustomerPay
                : dateFormatWithTimeZoneForEarning(dateForCheckPaid)
            }
            timeText={
              !dateForCheckPaid ? undefined : timeFormatWithoutTimeZoneForEarning(dateForCheckPaid)
            }
            position={6}
            isActive={invoiceDetails?.status === 'paid'}
          />
        </>
      )}

      {/**
       * Timeline for Payment Arrived for Instant payment
       */}
      {isInstantPayment && invoiceDetails?.actual_arrival_date && (
        <TimeLineItem
          itemName={timeline.invoice.paymentArrived}
          dateText={
            isDatesPendingInvoice
              ? timeline.invoice.pendingCustomerPay
              : dateFormatWithTimeZoneForEarning(dateForArrivedForInstantPayment)
          }
          timeText={
            isDatesPendingInvoice
              ? undefined
              : timeFormatWithoutTimeZoneForEarning(dateForArrivedForInstantPayment)
          }
          position={6}
          isActive={invoiceDetails?.status === 'paid'}
        />
      )}
    </>
  );
};

/**
 * Timeline modal component
 * @param param0 tag value need to pass
 * @returns component UI
 */
export const TimeLineModal = ({
  onCloseEventModal,
  selectedId,
  timeLineType,
}: TimeLineModalProps) => {
  const modalDefaultClasses =
    'z-20 !w-[370px] flex flex-col bg-white rounded-lg shadow-importInvoicesModal absolute right-1 opacity-1 pt-8 pb-8 px-6 relative';
  const handlaeTheHeightOfTheModal = timeLineType === 'batch' ? 'min-h-[474px]' : 'min-h-[360px]';

  let response: any = null;

  if (timeLineType === 'batch') {
    response = useGetTransferDetailsQuery(selectedId);
  } else if (timeLineType === 'invoice') {
    response = useGetInvoiceDetailsQuery(selectedId);
  }

  const onCloseEvent = () => {
    onCloseEventModal && onCloseEventModal();
  };

  return (
    <>
      {response && response?.isLoading && <LoadingSpinner additionalClasses="absolute" />}
      {response && response?.isSuccess && (
        <Modal
          isOpen={true}
          onClose={onCloseEvent}
          modalAdditionalClasses={`${modalDefaultClasses} ${handlaeTheHeightOfTheModal}`}
        >
          {response?.data && (
            <div className={classNames(['flex flex-col justify-start w-full'])}>
              <div className="flex mb-8">
                <p className={`text-grey-500 text-12s font-bold m-0`}>{`${
                  timeLineType === 'batch' ? 'PAYOUT' : 'INVOICE'
                } TIMELINE`}</p>
              </div>
              {timeLineType === 'batch' && <BatchTimeLine timeLineDetails={response?.data} />}
              {timeLineType === 'invoice' && <InvoiceTimeline invoiceDetails={response?.data} />}
            </div>
          )}
        </Modal>
      )}
    </>
  );
};
