import { ConfirmationModal } from 'components/core/Modals/ConfirmationModal/ConfirmationModal';
import { StatusDropDown } from 'components/core/StatusDropDown/StatusDropDown';
import {
  handlePropertyStatus,
  handlePropertyUpdateQueryStates,
} from 'helpers/modules/helperProperties';
import { useState } from 'react';
import { useUpdatePropertiesDetailsMutation } from 'services/properties';
import { propertiesStatusDropDownData } from 'constants/statusDropDownData';
import { PropertyParams } from 'shared/utilities/interface';

export const PropertiesStatusDropDownCell = ({ id, status, is_delinquent }: PropertyParams) => {
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [selectedVal, setSelectedVal] = useState('');

  const [updateProperties, { isLoading: updateLoading }]: any =
    useUpdatePropertiesDetailsMutation();

  const handleOnStatusChange = (event: any) => {
    const selectedVal = event.target.getAttribute('data-val');
    setSelectedVal(selectedVal);
    setConfirmModalOpen(true);
  };

  const handleBackEvent = () => {
    setConfirmModalOpen(false);
    setSelectedVal('');
  };

  const handleConfirmButtonClick = async () => {
    const filterObj = handlePropertyUpdateQueryStates(selectedVal);
    await updateProperties({ id: id, ...filterObj });
    setConfirmModalOpen(false);
  };

  return (
    <>
      <StatusDropDown
        defautlOption={
          selectedVal !== '' ? selectedVal : handlePropertyStatus(status, is_delinquent)
        }
        dropDownData={propertiesStatusDropDownData}
        onOptionClick={handleOnStatusChange}
        additionalClasses={'!w-[100px]'}
        additionalDropDownContainerClasses={'w-[100px]'}
      />

      {confirmModalOpen && (
        <ConfirmationModal
          handleBackEvent={handleBackEvent}
          handleSubmitEvent={handleConfirmButtonClick}
          isIconLoad={updateLoading}
          mainContent={'Are you sure you want to change the status?'}
        />
      )}
    </>
  );
};
