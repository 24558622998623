import { useComponentVisible } from 'hooks/useComponentVisible';
import { Button } from '../Button/Button';
import { formatQuery, QueryBuilder, RuleGroupType, RuleProcessor, Field } from 'react-querybuilder';
import { useEffect, useState } from 'react';
import 'react-querybuilder/dist/query-builder.css';
import { CustomDatePicker } from './CustomDatePicker';
import './overide.css';
import moment from 'moment';
import {
  commonHandlerCustomRuleArray,
  handlePropertyCustomRuleArray,
  multiSelectOperatorTypes,
  stringOperatorTypes,
  valueOperatorTypes,
} from './data';
// import { musicalInstruments } from './apiData';

const initialQuery: RuleGroupType = {
  combinator: 'and',
  rules: [],
};

export interface IFilterComponent {
  onClickFilterApplyButton?: any;
  filterButtonLabel?: string;
  additionalData?: any;
}

export const FilterComponent = ({
  onClickFilterApplyButton,
  filterButtonLabel,
  additionalData,
}: IFilterComponent) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const [query, setQuery] = useState(initialQuery);
  const [finalQuery, setFinalQuery]: any = useState();
  const [isApplyButtonClicked, setIsApplyButtonClicked] = useState(false);

  // const [ propertiesDataValues, vendorsDataValues ] = handleAPIData(additionalData);

  const fields: Field[] = [
    { label: 'Invoice', name: 'coupa_invoice_number', operators: stringOperatorTypes },
    { label: 'Check Number', name: 'check_number', operators: stringOperatorTypes },
    { label: 'Description', name: 'description', operators: stringOperatorTypes },
    { label: 'Payup Id', name: 'number', operators: valueOperatorTypes },
    {
      label: 'Approved',
      name: 'approved_at',
      operators: [{ name: 'between', label: 'is' }],
      datatype: 'dateRange',
    },
    {
      label: 'Paid On',
      name: 'paid_at',
      operators: [{ name: 'between', label: 'is' }],
      datatype: 'dateRange',
    },
    {
      label: 'Payup Due',
      name: 'customer_payment_due_at',
      operators: [{ name: 'between', label: 'is' }],
      datatype: 'dateRange',
    },
    {
      label: 'Exp. Arrival',
      name: 'estimated_arrived_on',
      operators: [{ name: 'between', label: 'is' }],
      datatype: 'dateRange',
    },
    {
      label: 'Recived On',
      name: 'customer_paid_at',
      operators: [{ name: 'between', label: 'is' }],
      datatype: 'dateRange',
    },
    { label: 'Invoice Amount', name: 'amount', operators: valueOperatorTypes },
    { label: 'Payout Amount', name: 'payout_amount', operators: stringOperatorTypes },
    { label: 'Paid Amount', name: 'paid_amount', operators: stringOperatorTypes },
    { label: 'Processing Fee', name: 'processing_fee_amount', operators: stringOperatorTypes },
    { label: 'Discount Fee', name: 'payout_term_amount', operators: stringOperatorTypes },
    {
      label: 'Property',
      name: 'property',
      operators: multiSelectOperatorTypes,
      datatype: 'multi-select',
    },
    {
      label: 'Pay Mode',
      name: 'pay_mode',
      operators: multiSelectOperatorTypes,
      datatype: 'multi-select',
    },
    {
      label: 'Status',
      name: 'status',
      operators: multiSelectOperatorTypes,
      datatype: 'multi-select',
    },
    // {
    //   label: 'Check Status',
    //   name: 'check_status',
    //   operators: multiSelectOperatorTypes,
    //   datatype: 'multi-select',
    // },
    {
      label: 'Vendor',
      name: 'vendor',
      operators: stringOperatorTypes,
    },
  ];

  const customRuleProcessor: RuleProcessor = (rule) => {
    // The "has" operator is not handled by the default processor
    // eslint-disable-next-line no-new-object
    const parentArray: any = new Object();
    // eslint-disable-next-line no-new-object
    const childArray: any = new Object();

    if (rule.operator === '=') {
      childArray['equalTo'] = '"' + rule.value + '"';
      parentArray[rule['field']] = childArray;
      return parentArray;
    } else if (rule.operator === '!=') {
      childArray['notEqualTo'] = '"' + rule.value + '"';
      parentArray[rule['field']] = childArray;
      return parentArray;
    } else if (rule.operator === 'contains') {
      if (rule.field === 'vendor') {
        const accountArray = {
          or: [
            {
              business_name: {
                likeInsensitive: '"%' + rule.value + '%"',
              },
            },
            {
              first_name: {
                likeInsensitive: '"%' + rule.value + '%"',
              },
            },
            {
              last_name: {
                likeInsensitive: '"%' + rule.value + '%"',
              },
            },
          ],
        };
        parentArray['account'] = accountArray;
      } else {
        childArray['likeInsensitive'] = '"%' + rule.value + '%"';
        parentArray[rule['field']] = childArray;
      }
      return parentArray;
    } else if (
      rule.field === 'approved_at' ||
      rule.field === 'paid_at' ||
      rule.field === 'customer_payment_due_at' ||
      rule.field === 'customer_paid_at'
    ) {
      const finalfalue = rule.value;
      const spitValue = finalfalue.split(',');
      const finalFormatedValue = spitValue;

      const array = {
        greaterThan: moment(finalFormatedValue[0])
          .add(1, 'day')
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
          .format(),
        lessThan: moment(finalFormatedValue[1])
          .add(1, 'day')
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
          .format(),
      };

      parentArray[rule['field']] = array;
      return parentArray;
    } else if (rule.field === 'property') {
      const finalValue = rule.value;
      const array = handlePropertyCustomRuleArray(rule?.operator, finalValue);
      parentArray[rule['field']] = array;
      return parentArray;
    } else if (rule.field === 'pay_mode') {
      const finalValue = rule.value;
      const array = commonHandlerCustomRuleArray(rule?.operator, finalValue);
      parentArray[rule['field']] = array;
      return parentArray;
    } else if (rule.field === 'status') {
      const finalValue = rule.value;
      const array = commonHandlerCustomRuleArray(rule?.operator, finalValue);
      parentArray[rule['field']] = array;
      return parentArray;
    } else if (rule.field === 'check_status') {
      const finalValue = rule.value;
      const array = commonHandlerCustomRuleArray(rule?.operator, finalValue);
      parentArray[rule['field']] = array;
      return parentArray;
    }
  };

  const handleAplyButtonClick = () => {
    onClickFilterApplyButton && onClickFilterApplyButton(finalQuery);
    setIsComponentVisible(false);
    setIsApplyButtonClicked(true);
  };

  useEffect(() => {
    const setFormatedQueryFromQB: any = formatQuery(query, {
      format: 'jsonlogic',
      ruleProcessor: customRuleProcessor,
    });
    setFinalQuery(setFormatedQueryFromQB);
  }, [query]);

  const handleCancelButtonClick = () => {
    setIsComponentVisible(false);
  };

  const handleClearAllButtonClick = () => {
    onClickFilterApplyButton && onClickFilterApplyButton(false);
    setFinalQuery();
    setQuery(initialQuery);
    setIsComponentVisible(false);
  };

  return (
    <div className="relative flex flex-col ml-3" ref={ref}>
      <Button
        buttonLabel={filterButtonLabel}
        iconAlignment="left"
        iconClass={filterButtonLabel ? 'mr-1' : 'mx-[2px]'}
        iconType="FilterIcon"
        isIconEnabled
        variants="extraSmallOutlined"
        additionalClasses={`text-12s !border-grey-100 text-grey-900 ${
          isComponentVisible || (finalQuery !== false && isApplyButtonClicked) ? 'bg-grey-100' : ''
        }`}
        onClick={() => setIsComponentVisible(!isComponentVisible)}
      />
      {isComponentVisible && (
        <div className="absolute left-auto right-0 z-50 flex flex-col top-full bg-white rounded-lg shadow-dropDown w-[620px] px-6 py-6 border border-solid border-grey-50">
          <QueryBuilder
            fields={fields}
            query={query}
            onQueryChange={(q) => setQuery(q)}
            controlElements={{
              valueEditor: CustomDatePicker,
            }}
            translations={{
              addRule: {
                label: '+ Add',
              },
              removeRule: {
                label: '',
              },
            }}
            showLockButtons
          />

          <div className="flex justify-between w-full pt-6">
            <Button
              buttonLabel={'Cancel'}
              variants="extraSmallOutlined"
              additionalClasses="!text-12s w-auto !border-none px-0 font-medium"
              onClick={handleCancelButtonClick}
            />

            <div className="flex">
              <Button
                buttonLabel={'Clear All'}
                variants="extraSmallOutlined"
                additionalClasses="!text-12s w-[100px] !font-medium mr-4"
                onClick={handleClearAllButtonClick}
                isDisabled={finalQuery === false}
              />

              <Button
                buttonLabel={'Apply'}
                variants="extraSmall"
                additionalClasses="!text-12s w-[100px] !font-medium"
                onClick={handleAplyButtonClick}
                // isDisabled={finalQuery === false}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
