import React from 'react';
import classNames from 'classnames';

import { BlockConfirmationContentProps } from 'shared/models/components/base.model';
import { Button } from '../../Button/Button';

export const BlockConfirmationContent = ({
  handleBackEvent,
  handleSubmitEvent,
  isIconLoad,
  mainContent,
  additionalClasses,
  additionalClassesWrapper,
}: BlockConfirmationContentProps) => {
  return (
    <div
      className={classNames([
        'z-20 w-[370px] flex flex-col bg-white justify-center rounded-lg shadow-importInvoicesModal absolute right-1 opacity-0',
        additionalClassesWrapper,
      ])}
    >
      <div
        className={classNames([
          'flex flex-col items-center justify-center w-full',
          additionalClasses,
        ])}
      >
        <div className="flex flex-col w-full mb-6 font-medium text-center text-20s text-black-900 ">
          <span>{mainContent}</span>
        </div>
        <div className="flex flex-row items-center justify-end w-full px-12">
          <span
            className="inline-flex font-bold cursor-pointer text-14s text-black-900 mr-7"
            onClick={handleBackEvent}
          >
            Back
          </span>

          <Button
            type={'button'}
            buttonLabel={'Yes'}
            variants="default"
            onClick={handleSubmitEvent}
            additionalClasses={'w-[128px]'}
            iconType="Spin"
            iconClass="inline-flex ml-1 w-3 h-3"
            isIconEnabled={isIconLoad}
            iconAlignment={'right'}
          />
        </div>
      </div>
    </div>
  );
};
