import React from 'react';
import classNames from 'classnames';
import Typho from '../Typho/Typho';

interface AnchorLinkProps {
  /**
   * Set label
   */
  children?: React.ReactNode;
  /**
   * Set UrlPath
   */
  urlPath?: string;
  /**
   * Optional | Set additional classes
   */
  additionalClasses?: string;
  /**
   * fontSize
   */
  linkFontSize?: '14s' | '18s';
  /**
   * FontWeight
   */
  linkFontWeight?: 'bold' | 'normal' | 'medium' | 'semibold';
  /**
   * Set link color
   */
  linkColor?: 'black-900' | 'white' | 'blue' | 'inherit' | 'grey-900';
  /**
   * Set link hover color
   */
  linkHoverColor?: 'white' | 'blue' | 'inherit' | 'gray-820' | 'grey-900';
  /**
   * Optional | enable the underline
   */
  isUnderLine?: boolean;
}

const AnchorLink: React.FC<AnchorLinkProps> = ({
  children,
  urlPath,
  additionalClasses,
  linkFontWeight = 'normal',
  linkFontSize = '14s',
  isUnderLine = true,
  linkColor = 'black-900',
  linkHoverColor = 'blue',
}) => {
  const handleUnderline = isUnderLine && 'underline';
  const defaultAnchorLinkClasses = `text-${linkColor} hover:!text-${linkHoverColor} ease-out duration-300`;
  const handleAnchorLinkClasses = [additionalClasses, defaultAnchorLinkClasses, handleUnderline];
  return (
    <>
      <a href={urlPath} className={classNames(handleAnchorLinkClasses)}>
        <Typho
          type="span"
          size={linkFontSize}
          weight={linkFontWeight}
          color="inherit"
          additionalClasses="hover:text-inherit"
        >
          {children}
        </Typho>
      </a>
    </>
  );
};

export default AnchorLink;
