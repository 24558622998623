import { CurrentTransactionsContainer } from 'components/modules/Transactions/CurrentTransactions';

/**
 * Primary UI component for user interaction
 */
const CurrentTransactions = () => {
  return <CurrentTransactionsContainer />;
};

export default CurrentTransactions;
