import SettingsBalanceContainer from 'components/modules/Settings/Balance';

/**
 * Primary UI component for user interaction
 */
const SettingsBalance = () => {
  return <SettingsBalanceContainer />;
};

export default SettingsBalance;
