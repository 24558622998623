import { createApi } from '@reduxjs/toolkit/query/react';
import { prepareBaseQuery } from '../shared/configs/config';

export const paymentsApi = createApi({
  reducerPath: 'paymentsApi',
  tagTypes: ['Payments'],
  baseQuery: prepareBaseQuery(),
  endpoints: (builder) => ({
    getPayments: builder.query({
      query: (state) => ({
        url: `/query/payments`,
        body: state,
        method: 'POST',
      }),
      providesTags: ['Payments'],
    }),
    createCharge: builder.mutation({
      query: (amount) => ({
        url: `/customer/charge`,
        body: { amount: amount },
        method: 'POST',
      }),
    }),
  }),
});

export const { useGetPaymentsQuery, useCreateChargeMutation } = paymentsApi;
