import DataList from 'components/core/SuperTable/DataList/DataList';
import EmptyList from 'components/core/EmptyList/EmptyList';
import { useParams } from 'react-router-dom';
import { CONFIG } from 'shared/configs/config';
import { useEffect, useState } from 'react';
import { handlePropertiesDetailTabNavData } from 'constants/TabNavData';
import { helperApprove } from 'helpers/modules/helperInvoices';
import TopPaymentBar from 'components/core/TopPaymentBar/TopPaymentBar';
import { HeaderTabNavigation } from 'components/core';
import { serverSidePaginationActive, useServerSidePagination } from 'hooks/useServerSidePagination';
import { isPermitted, useGetUserQuery } from 'services/userApi';
import { useGetPayoutsQuery } from 'services/payoutsApi';

export const PropertyDetailsAllInvoicesContainer = () => {
  const [pageSizeOptions, paginationState, previousButtonClick, nextButtonClick, setPageSize]: any =
    useServerSidePagination();
  const { id } = useParams();
  const { data: user } = useGetUserQuery({});

  const filterParams = {
    first: serverSidePaginationActive ? paginationState.pageSize : CONFIG.DATA_OVERALL_LIMIT,
    offset: serverSidePaginationActive ? paginationState.pageIndex * paginationState.pageSize : 0,
    orderBy: 'INVOICED_AT_DESC',
    filter: {
      property_id: { equalTo: '"' + id + '"' },
      status: { in: ['APPROVED', 'INVOICED', 'PAID', 'DENIED'] },
    },
  };

  const { data, isSuccess } = useGetPayoutsQuery(filterParams);
  const dataNodes = data?.payouts?.nodes ?? [];

  const getStatusArray = dataNodes?.map((item: any) => item.status);
  const setStatusArray = [...getStatusArray];

  const totalRecords: number = data?.payouts?.totalCount ?? 0;
  const totalPageCount = Math.ceil(totalRecords / paginationState.pageSize);

  const [detailTabNavData] = handlePropertiesDetailTabNavData(id);
  const [tableColumns, handleIconClick, getInvoiceAmount] = helperApprove('PROPERTIES_INVOICES');
  const [enableSelect, setEnableSelect] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [tlAmount, setTlAmount]: any = useState();
  const [selectedIds, setSelectedIds]: any = useState();

  const [columnList, setColumnList]: any = useState([]);
  const [toggleHiddenColumnChanged, setToggleHiddenColumnChanged] = useState(false);

  const [visibleColumns, setVisibleColumns] = useState([]);
  const [filteredRow, setFilteredRow] = useState([]);

  useEffect(() => {
    setColumnList(tableColumns);
  }, [data]);

  const hdColList = [
    ...columnList.filter((item: any) => item.isHidden).map((val: any) => val.accessor),
  ];

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    getInvoiceAmount(selectedRows, setTlAmount, setSelectedIds);
  }, [selectedRows]);

  const handlePaynowOption = () => {
    setEnableSelect(true);
    setSelectedIds(null);
    setSelectedRows([]);
  };

  return (
    <>
      {enableSelect && (
        <TopPaymentBar
          selectedRowsLength={selectedRows?.length}
          setEnableSelect={setEnableSelect}
          totalAmount={tlAmount}
          ids={selectedIds}
        />
      )}
      <HeaderTabNavigation
        tabNavData={detailTabNavData}
        isShowOptionEnabled
        isShowMoreOptionDisabled={false}
        columns={columnList}
        handleIconClick={(item: any) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          handleIconClick(
            item,
            columnList,
            setColumnList,
            setToggleHiddenColumnChanged,
            toggleHiddenColumnChanged
          );
        }}
        isAdditionalButtonVisibled={isPermitted(user, 'paynow-option')}
        onButtonClick={handlePaynowOption}
        additionalButtonLabel={'Pay Invoices Now'}
        isAdditionalButtonDisabled={
          enableSelect || dataNodes.length === 0 || !setStatusArray.includes('APPROVED')
        }
        additionalWrapperClasses={'mb-4'}
        visibleColumns={visibleColumns}
        filteredRow={filteredRow}
        isDownloadCsvButtonEnabled={isSuccess && dataNodes && dataNodes.length !== 0}
      />
      {isSuccess && (
        <div>
          {dataNodes && dataNodes.length > 0 ? (
            <DataList
              tableType="approve"
              subTabType="innerPropertiesEarnings"
              data={dataNodes}
              tableWrapperType="grid-height-medium"
              isPagination={true}
              editNetTerm={true}
              hiddenColumnList={hdColList}
              toggleHiddenColumnChanged={toggleHiddenColumnChanged}
              setSelectedRows={setSelectedRows}
              enableSelect={enableSelect}
              isServerSidePagination={serverSidePaginationActive}
              previousPageClick={previousButtonClick}
              nextPageClick={nextButtonClick}
              setPageSizeValue={setPageSize}
              options={pageSizeOptions}
              totalPageCount={totalPageCount}
              pageSizeVal={paginationState.pageSize}
              pageIndexVal={paginationState.pageIndex}
              setVisibleColumns={setVisibleColumns}
              setFilteredRow={setFilteredRow}
            />
          ) : (
            <EmptyList />
          )}
        </div>
      )}
    </>
  );
};
