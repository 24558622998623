import Icon from 'components/core/Media/Icon/Icon';
import { Typhography } from '../Typhography/Typhography';

export const BackLink = () => {
  return (
    <Typhography
      type="h1"
      component="span"
      color="text-primary-500"
      additionalClasses="cursor-pointer inline-flex items-center"
    >
      <Icon
        iconType="RightArrow"
        iconColor="inherit"
        additionalClasses="rotate-180 mr-4 text-primary-500"
        iconContainerClasses="text-primary-500"
      />
      Back
    </Typhography>
  );
};
