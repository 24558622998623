import { ChangeEvent, useState } from 'react';
import { InitialState } from '../../shared/models/services/vendors.model';
import { handleShowIconClick } from 'shared/utilities/utils';
import moment from 'moment';

export const helperVendor = (initialActiveTabText: any) => {
  /**
   * tabnav data for top vendors navigation
   * TODO need to moved to separate file
   */
  const tabNavData: any = [
    { name: 'Active', value: 'ACTIVE' },
    { name: 'Inactive', value: 'INACTIVE' },
  ];

  /**
   * handle react_hooks
   */
  const [state, setState] = useState(InitialState);
  const [recordsPerPage, setRecordsPerPage] = useState(state.first);
  const [activeTabName, setActiveTabName] = useState(initialActiveTabText);
  const [offset, setOffset] = useState(1);

  /**
   * pagerdropdown onchange event
   * TODO need to moved to separate hooks
   */
  const handlePagerChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const value = Number(event?.currentTarget?.value);
    setRecordsPerPage(value);
    setState({
      ...state,
      first: value,
    });
  };

  return [
    tabNavData,
    handlePagerChange,
    state,
    setState,
    recordsPerPage,
    activeTabName,
    setActiveTabName,
    offset,
    setOffset,
  ];
};

export const handleVendorStatus = (status?: string) => {
  let statusVal;

  if (status === 'INACTIVE' || status === 'Inactive' || status === 'inactive') {
    statusVal = 'INACTIVE';
  }

  if (status === 'ACTIVE' || status === 'Active' || status === 'active') {
    statusVal = 'ACTIVE';
  }

  return statusVal;
};

export const helperVendorColumOrder = () => {
  // set inital empty array
  let tableColumns = [];

  // set default tableColumns array
  tableColumns = [
    {
      Header: 'Vendor',
      accessor: 'VENDOR',
      isHidden: false,
      order: 1,
    },
    {
      Header: 'Status',
      accessor: 'STATUS',
      isHidden: false,
      order: 2,
    },
    {
      Header: 'Modified By',
      accessor: 'MODIFIED BY',
      isHidden: false,
      order: 3,
    },
    {
      Header: 'Code',
      accessor: 'code',
      isHidden: false,
      order: 4,
    },
    {
      Header: 'Default Net',
      accessor: 'default_payment_term_id',
      isHidden: true,
      order: 5,
    },
    {
      Header: 'Phone',
      accessor: 'phone',
      isHidden: false,
      order: 6,
    },
    {
      Header: 'Email',
      accessor: 'email',
      isHidden: true,
      order: 7,
    },
    {
      Header: 'Lang.',
      accessor: 'language',
      isHidden: false,
      order: 8,
    },
    {
      Header: 'Overall Earnings',
      accessor: 'OVERALL EARNINGS',
      isHidden: false,
      order: 9,
    },
    {
      Header: 'Payouts Scheduled',
      accessor: 'PAYOUTS SCHEDULED',
      isHidden: true,
      order: 10,
    },
    {
      Header: 'Total Processing Fee',
      accessor: 'TOTAL PROCESSING FEE',
      isHidden: false,
      order: 11,
    },
    {
      Header: 'Date Created',
      accessor: 'created_at',
      isHidden: false,
      order: 12,
    },
  ];

  // reorder the table colomns according to the order property value
  tableColumns = [...tableColumns].sort(
    (a: any, b: any) => parseFloat(a.order) - parseFloat(b.order)
  );

  const handleIconClick = (
    item: any,
    columnList: any,
    setColumnList: any,
    setToggleHiddenColumnChanged: any,
    toggleHiddenColumnChanged: any
  ) => {
    handleShowIconClick(
      item,
      columnList,
      setColumnList,
      setToggleHiddenColumnChanged,
      toggleHiddenColumnChanged
    );
  };

  return [tableColumns, handleIconClick];
};

/**
 * Handle default pay mod
 */

export const handleVendorDefaultPayMode = (pay_mode: string) => {
  let payMode = pay_mode;

  if (pay_mode === 'debit_card' || pay_mode === 'DEBIT_CARD' || pay_mode === 'Debit_Card') {
    payMode = 'Instant';
  }

  if (pay_mode === 'CHECK' || pay_mode === 'Check' || pay_mode === 'check') {
    payMode = 'Checks';
  }

  if (pay_mode === 'ach' || pay_mode === 'ACH' || pay_mode === 'Ach') {
    payMode = 'ACH';
  }

  return payMode;
};

export const handleVendorDefaultPayModeValues = (pay_mode: string) => {
  let payMode = pay_mode;

  if (pay_mode === 'debit_card' || pay_mode === 'DEBIT_CARD' || pay_mode === 'Debit_Card') {
    payMode = 'debit_card';
  }

  if (pay_mode === 'CHECK' || pay_mode === 'Check' || pay_mode === 'check') {
    payMode = 'check';
  }

  if (pay_mode === 'ach' || pay_mode === 'ACH' || pay_mode === 'Ach') {
    payMode = 'ach';
  }

  return payMode;
};

export const getPaidAtDateForInvoices = (node: any) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { pay_mode, check_paid_on, paid_at }: any = node;

  let paidAt = paid_at ? moment(paid_at).format('MM/DD/YYYY') : null;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const check_paid_at = check_paid_on ? moment(check_paid_on).format('MM/DD/YYYY') : null;
  if (pay_mode === 'CHECK') {
    if (check_paid_at) paidAt = check_paid_at;
    else paidAt = null;
  }

  return paidAt;
};
