import { ActivePropertiesContainer } from 'components/modules/Properties/Active/Active';

/**
 * Primary UI component for user interaction
 */
const ActiveProperties = () => {
  return <ActivePropertiesContainer />;
};

export default ActiveProperties;
