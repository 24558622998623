import React, { useState } from 'react';

import classNames from 'classnames';
import Icon from '../../Media/Icon/Icon';
import Message from '../../Message/Message';
import { Button } from '../../Button/Button';
import { useTransferNowMutation } from 'services/payoutsApi';

export interface PayTransactionModalProps {
  payoutNumber?: string;
  ids?: string;
  btnDisabled?: any;
  isFromTransactionModal?: boolean;
  setEnableSelect?: any;
  setMainModalOpen?: any;
}

export const PayTransactionModal = ({
  payoutNumber,
  ids,
  btnDisabled = false,
  isFromTransactionModal,
  setEnableSelect,
  setMainModalOpen,
}: PayTransactionModalProps) => {
  const [transferNowMutation, { isLoading: isTransferNowLoading }] = useTransferNowMutation();
  const [modalConfirmationOpen, setModalConfirmationOpen] = useState(false);
  const [modalStatusOpen, setModalStatusOpen] = useState(false);
  const [errorPayNow, setErrorPayNow]: any = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  // Used to handle the Pay Now modal open ___
  const handlePickerOpen = () => {
    setModalOpen(!modalOpen);
    setModalConfirmationOpen(true);
    setErrorPayNow(null);
  };

  // Used to handle the Pay Now modal close ___
  const handlePickerClose = () => {
    setModalOpen(false);
    setModalConfirmationOpen(false);
    setModalStatusOpen(false);
    setEnableSelect(false);
    setMainModalOpen(false);
  };

  const handleErrorInModal = () => {
    setModalOpen(true);
    setModalConfirmationOpen(true);
    setModalStatusOpen(false);
    setTimeout(() => {
      handlePickerClose();
    }, 3500);
  };

  // Used to handle the Ye, confirm button for paynow event___
  const handlePayNowConfirmationEvent = async () => {
    try {
      const response: any = await transferNowMutation(ids);

      if (response) {
        const isTransferError = response?.data[0]?.errorIfAny && !response?.data[0]?.transfer;
        if (!response?.error?.data?.message && !isTransferError) {
          setModalOpen(false);
          setModalConfirmationOpen(false);
          setModalStatusOpen(true);
          setTimeout(() => {
            handlePickerClose();
          }, 2000);
        } else if (response && response?.error?.data?.message && !isTransferError) {
          handleErrorInModal();
          setErrorPayNow(response?.error?.data?.message);
        } else if (isTransferError) {
          handleErrorInModal();
          if (response?.data[0]?.errorIfAny.statusCode) {
            setErrorPayNow(response?.data[0]?.errorIfAny?.raw?.message);
          } else {
            setErrorPayNow(response?.data[0]?.errorIfAny);
          }
        }
      }
    } catch (e: any) {
      console.log(e);
    }
  };

  // Used to ignore the confirmation___
  const handleCancelEvent = () => {
    setModalConfirmationOpen(false);
    setModalStatusOpen(false);
  };

  return (
    <div className="relative z-50">
      <div className="flex w-full">
        <Button
          variants="extraSmall"
          onClick={handlePickerOpen}
          additionalClasses={`${isFromTransactionModal ? 'w-[100px]' : 'w-[134px]'} ml-2`}
          buttonLabel={isFromTransactionModal ? 'Pay Payouts' : 'Pay Transactions'}
          isDisabled={btnDisabled}
        />
      </div>

      {modalOpen && (
        <>
          <div
            className={classNames([
              modalConfirmationOpen ? 'pointer-events-none' : 'pointer-events-auto',
              'fixed top-0 bottom-0 left-0 right-0 z-10 bg-transparent',
            ])}
            onClick={handlePickerClose}
          />

          {modalConfirmationOpen && (
            <div
              className={classNames([
                'z-20 w-[370px] flex flex-col bg-white justify-center rounded-lg shadow-paynowModal absolute right-1 top-10 opacity-100',
              ])}
            >
              <div
                className={`flex flex-col items-center justify-center w-full ${
                  errorPayNow !== null ? 'py-6 pb-10' : 'py-10'
                }`}
              >
                {errorPayNow !== null && (
                  <div className="flex items-center justify-between w-full px-6 pt-4">
                    <Message type="error">{errorPayNow}</Message>
                  </div>
                )}
                <div className="flex flex-col w-full mb-8 font-medium text-center text-20s text-primary-500">
                  <span>{`Are you sure to pay`}</span>
                  <span>{` ${payoutNumber} ${
                    isFromTransactionModal ? 'Payouts' : 'Transactions'
                  } ?`}</span>
                </div>
                <div className="flex flex-row justify-between w-full px-12">
                  <Button
                    type={'button'}
                    buttonLabel={'Cancel'}
                    variants="defaultOutlined"
                    onClick={handleCancelEvent}
                    additionalClasses={'w-[132px] font-medium'}
                  />
                  <Button
                    type={'button'}
                    buttonLabel={'Yes, Confirm'}
                    variants="default"
                    // eslint-disable-next-line
                    onClick={handlePayNowConfirmationEvent}
                    additionalClasses={'w-[132px] font-medium'}
                    iconType="Spin"
                    iconClass="inline-flex ml-1 w-3 h-3"
                    isIconEnabled={isTransferNowLoading}
                    iconAlignment={'right'}
                  />
                </div>
              </div>
            </div>
          )}
        </>
      )}

      {modalStatusOpen && (
        <>
          <div
            className={classNames([
              modalConfirmationOpen ? 'pointer-events-none' : 'pointer-events-auto',
              'fixed top-0 bottom-0 left-0 right-0 z-10 bg-transparent',
            ])}
            onClick={handlePickerClose}
          />
          <div
            className={classNames([
              'w-[370px] flex flex-col bg-white justify-center rounded-lg z-30 shadow-paynowModal absolute right-1 top-10 opacity-100',
            ])}
          >
            <div className="absolute z-10 cursor-pointer top-4 right-4" onClick={handlePickerClose}>
              <Icon iconType={'CloseIcon'} />
            </div>
            <div className="relative flex flex-col items-center justify-center w-full py-10">
              <Icon iconType="TickIconForSuccess" />
              <div className="flex flex-col w-full mt-4 text-center">
                <span className="flex justify-center w-full mb-1 font-medium text-20s">
                  {'Paid'}
                </span>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
