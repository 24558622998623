import React from 'react';
import { useNavigate } from 'react-router-dom';

import { BackLink } from 'components/core';
import { Search } from '../Search/Search';
import { TopNavigation } from '../TopNavigation/TopNavigation';
import { TopNavigationBarProps } from 'shared/models/components/layouts.model';

export const TopNavigationBar = ({ isBackButtonVisible = false }: TopNavigationBarProps) => {
  const navigate = useNavigate();

  return (
    <div className="h-10 left-[92px] right-0 fixed top-0 bg-primary-75 flex items-center justify-between pr-6 pl-4 z-50">
      <div
        className={`inline-flex ${!isBackButtonVisible ? 'invisible' : 'visible'}`}
        onClick={() => navigate(-1)}
      >
        <BackLink />
      </div>
      <Search />
      <TopNavigation />
    </div>
  );
};
