import { useStripe } from '@stripe/react-stripe-js';
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

import Icon from 'components/core/Media/Icon/Icon';
import { FooterNote } from 'components/core/Notes/FooterNote/FooterNote';
import Message from 'components/core/Message/Message';
import { Typhography, Input, Button } from 'components/core';

import {
  useEstablishFinancialConnectionSessionMutation,
  useLinkExternalAccountMutation,
  useUpdateBankAccountFieldsMutation,
} from '../../../../../services/vendorsApi';

import { BankSetupProps } from 'shared/models/components/base.model';
import LoadingSpinner from 'components/core/LoadingSpinner/LoadingSpinner';
import { BankDetailsParams } from 'shared/utilities/interface';
import { ERROR_MESSAGES } from 'constants/Validation';
import { Classes } from 'shared/configs/componentsClasses';
import { useState } from 'react';

export const BankSetup = ({
  onClick,
  setUpdateAllList,
  updateAllList,
  onClose,
}: BankSetupProps) => {
  const inputDefaultClasses = Classes.input.default;
  const buttonClasses =
    'mb-4 flex w-full h-16 rounded-lg border border-solid border-frey-100 cursor-pointer items-center px-4 justify-between';

  const stripe = useStripe();
  const { id: vendorId } = useParams();

  const [isFinAccountAdded, setIsFinAccountAdded] = useState(false);
  const [finAccountID, setFinAccountID] = useState('');

  const [establishFinancialConnectionSession] = useEstablishFinancialConnectionSessionMutation();
  const [linkExternalAccount, { isLoading: isBankAccountLoading }]: any =
    useLinkExternalAccountMutation();
  const [updateBankAccount, { isLoading: isLoadingUpdateFA }] =
    useUpdateBankAccountFieldsMutation();
  const [errorMessage, setErrorMessage] = useState('');

  const createFinancialConnectionsSession = async () => {
    const connectionResponse: any = await establishFinancialConnectionSession({
      vendor_id: vendorId,
    });
    try {
      const result: any = await stripe?.collectBankAccountToken({
        clientSecret: connectionResponse?.data?.client_secret,
      });

      if (result.token?.id) {
        const response = await linkExternalAccount({
          token: result.token?.id,
          account_id: vendorId,
        });

        if (response) {
          if (!response?.error) {
            setIsFinAccountAdded(true);
            setFinAccountID(response?.data?.id);
            setErrorMessage('');
          } else {
            setIsFinAccountAdded(false);
            setErrorMessage(
              response?.error?.data?.error ??
                response?.error?.data?.message ??
                response?.error?.error
            );
          }
        }
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const schema = yup
    .object({
      nickname: yup.string().required(ERROR_MESSAGES.REQUIRED),
    })
    .required();

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<BankDetailsParams>({
    resolver: yupResolver(schema),
    mode: 'onBlur', //used to check error in realtime
    shouldFocusError: true,
  });

  const onSubmitNewPaymentMethod = async (data: any) => {
    const setUpdateParams = {
      id: finAccountID,
      field: 'nickname',
      body: {
        nickname: data.nickname,
        account_id: vendorId,
      },
    };

    const resp: any = await updateBankAccount(setUpdateParams);

    if (resp) {
      setValue('nickname', '');
      setUpdateAllList(!updateAllList);
      if (resp?.error) {
        setErrorMessage(
          resp?.error?.data?.error ?? resp?.error?.data?.message ?? resp?.error?.error
        );
      } else {
        setErrorMessage('');
        setTimeout(() => {
          onClose();
        }, 1000);
      }
    }
  };

  if (isFinAccountAdded) {
    return (
      <>
        {isLoadingUpdateFA && <LoadingSpinner />}
        <Typhography component="p" type="h2" color="text-grey-900 mb-2 uppercase">
          {'Success!'}
        </Typhography>
        <Typhography component="p" type="h2" color="text-grey-900 mb-4 !font-light !leading-5">
          {
            'Congratulation, your bank account was successfully set up. Set a nickname below for easier identification!'
          }
        </Typhography>
        <form
          onSubmit={handleSubmit(onSubmitNewPaymentMethod)}
          className={'w-full flex flex-col max-w-3xl'}
          noValidate
        >
          <Controller
            control={control}
            name="nickname"
            render={({ field: { value } }) => (
              <Input
                label="Nickname"
                labelName={'nickname'}
                type="text"
                {...register('nickname')}
                className={inputDefaultClasses}
                error={errors?.nickname?.message}
                required
                value={value}
              />
            )}
          />
          <div className="absolute flex items-center bottom-6 left-6 right-6">
            <Button
              type="submit"
              buttonLabel={`Done`}
              iconType="Spin"
              isIconEnabled={false}
              additionalClasses={'w-full'}
            />
          </div>
        </form>
      </>
    );
  }

  return (
    <>
      {isBankAccountLoading && <LoadingSpinner />}

      <Typhography component="p" type="h2" color="text-grey-900 mb-3 uppercase">
        {'How do you want to Add Bank Details?'}
      </Typhography>

      {errorMessage !== '' && (
        <Message title="Bank Setup" type={'error'} additionalClasses="mb-6">
          {errorMessage}
        </Message>
      )}

      <div>
        <Typhography
          component="div"
          type="h2Bold"
          color="text-grey-900"
          additionalClasses={`${buttonClasses}`}
          onClick={createFinancialConnectionsSession}
        >
          <span className="flex items-center text-inherit">
            {'Connect via Bank Login'}{' '}
            <Icon
              iconColor="inherit"
              iconType="TermsIcon"
              iconContainerClasses="ml-2 flex items-center"
            />
          </span>
          <Icon iconColor="inherit" iconType="RightArrow" iconContainerClasses="text-grey-400" />
        </Typhography>
        <Typhography
          component="div"
          type="h2Bold"
          color="text-grey-900"
          additionalClasses={buttonClasses}
          onClick={() => onClick && onClick(true)}
        >
          <span className="text-inherit">{'Set Up Bank Manually'}</span>
          <Icon iconColor="inherit" iconType="RightArrow" iconContainerClasses="text-grey-400" />
        </Typhography>
      </div>
      <div className="absolute bottom-0 left-4 right-4">
        <FooterNote />
      </div>
    </>
  );
};
