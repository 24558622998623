import moment from 'moment';
import { CONFIG } from 'shared/configs/config';
import { PropertiesStatsBarProps } from 'shared/models/components/base.model';
import {
  useGetDelinquentTrendingPropertiesQuery,
  useGetPropertiesQuery,
} from '../../services/properties';
import { handleShowIconClick } from 'shared/utilities/utils';

export const helperPropertiesStats = ({ dateRange }: PropertiesStatsBarProps) => {
  const PROPERTY_STATUS_CHANGED_DELINQUENT = '10';
  const PROPERTY_STATUS_CHANGED_ACTIVE = '12';
  const PROPERTY_STATUS_CHANGED_INACTIVE = '13';

  const handleTrendlineQuery = (equalValue: string) => {
    const TrendlineDelinquentTrendlines = {
      first: 3000,
      offset: 0,
      filter: {
        event_type: { equalTo: equalValue },
        created_at: {
          lessThan: moment()
            .add(1, 'day')
            .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
            .format(),
          greaterThan:
            dateRange > 0
              ? moment()
                  .subtract(dateRange, 'day')
                  .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                  .format()
              : undefined,
        },
      },
    };

    return TrendlineDelinquentTrendlines;
  };

  const DelinquentPropertiesFilterParam = {
    limit: CONFIG.DATA_OVERALL_LIMIT,
    offset: 0,
    orderBy: 'CREATED_AT_ASC',
    filter: {
      created_at: {
        lessThan: moment()
          .add(1, 'day')
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
          .format(),
        greaterThan:
          dateRange > 0
            ? moment()
                .subtract(dateRange, 'day')
                .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                .format()
            : undefined,
      },
      is_delinquent: { equalTo: true },
      status: { equalTo: 'ACTIVE' },
    },
  };

  const ActivePropertiesFilterParam = {
    limit: CONFIG.DATA_OVERALL_LIMIT,
    offset: 0,
    orderBy: 'CREATED_AT_ASC',
    filter: {
      created_at: {
        lessThan: moment()
          .add(1, 'day')
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
          .format(),
        greaterThan:
          dateRange > 0
            ? moment()
                .subtract(dateRange, 'day')
                .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                .format()
            : undefined,
      },
      is_delinquent: { equalTo: false },
      status: { equalTo: 'ACTIVE' },
    },
  };

  const InActivePropertiesFilterParam = {
    limit: CONFIG.DATA_OVERALL_LIMIT,
    offset: 0,
    orderBy: 'CREATED_AT_ASC',
    filter: {
      created_at: {
        lessThan: moment()
          .add(1, 'day')
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
          .format(),
        greaterThan:
          dateRange > 0
            ? moment()
                .subtract(dateRange, 'day')
                .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                .format()
            : undefined,
      },
      status: { equalTo: 'INACTIVE' },
    },
  };

  const { data: delinquentData } = useGetPropertiesQuery(DelinquentPropertiesFilterParam);
  const { data: activePropertyData } = useGetPropertiesQuery(ActivePropertiesFilterParam);
  const { data: inActivePropertyData } = useGetPropertiesQuery(InActivePropertiesFilterParam);

  const { data: trendlineDelinquentData, isLoading: delinquentTrendlineLoading } =
    useGetDelinquentTrendingPropertiesQuery(
      handleTrendlineQuery(PROPERTY_STATUS_CHANGED_DELINQUENT)
    );
  const { data: trendlineActiveData, isLoading: activeTrendlineLoading } =
    useGetDelinquentTrendingPropertiesQuery(handleTrendlineQuery(PROPERTY_STATUS_CHANGED_ACTIVE));
  const { data: trendlineInactiveData, isLoading: inactiveTrendlineLoading } =
    useGetDelinquentTrendingPropertiesQuery(handleTrendlineQuery(PROPERTY_STATUS_CHANGED_INACTIVE));

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const delinquentTrending: any = trendlineDelinquentData?.eventAuditTrails?.totalCount;
  const activeTrending: any = trendlineActiveData?.eventAuditTrails?.totalCount;
  const inactiveTrending: any = trendlineInactiveData?.eventAuditTrails?.totalCount;
  const isTrendlineLoading =
    delinquentTrendlineLoading || activeTrendlineLoading || inactiveTrendlineLoading;

  return [
    delinquentTrending,
    activeTrending,
    inactiveTrending,
    isTrendlineLoading,
    delinquentData,
    activePropertyData,
    inActivePropertyData,
  ];
};

export const handlePropertyStatus = (status?: string, is_delinquent?: boolean) => {
  let statusVal = null;

  if (status === 'INACTIVE') {
    statusVal = 'Inactive-Property';
  }

  if (is_delinquent && status !== 'INACTIVE') {
    statusVal = 'Delinquent';
  }

  if (!is_delinquent && status === 'ACTIVE') {
    statusVal = 'Non-Delinquent';
  }

  return statusVal;
};

export const handlePropertyUpdateQueryStates = (status: any) => {
  let obj;

  if (status === 'Inactive-Property') {
    obj = {
      status: 'inactive',
    };
  }

  if (status === 'Non-Delinquent') {
    obj = {
      status: 'active',
      is_delinquent: false,
    };
  }

  if (status === 'Delinquent') {
    obj = {
      status: 'active',
      is_delinquent: true,
    };
  }

  return obj;
};

/**
 * get properties column and show icon click handler
 * @param subTabType
 * @returns
 */
export const helperPropertiesColumOrder = (subTabType: any) => {
  // set inital empty array
  let tableColumns = [];

  // set default tableColumns array
  tableColumns = [
    {
      Header: 'Property',
      accessor: 'name',
      isHidden: false,
      order: 1,
    },
    {
      Header: 'Status',
      accessor: 'STATUS',
      isHidden: false,
      order: 2,
    },
    {
      Header: 'Updated By',
      accessor: 'UPDATED BY',
      isHidden: false,
      order: 3,
    },
    {
      Header: 'Vendors',
      accessor: 'VENDORS',
      isHidden: false,
      order: 4,
    },
    {
      Header: 'Default Bank',
      accessor: 'DEFAULT BANK',
      isHidden: false,
      order: 5,
    },
    {
      Header: 'Code',
      accessor: 'code',
      isHidden: subTabType !== 'innerVendorProperties',
      order: 6,
    },
    {
      Header: 'Paid To Vendors',
      accessor: 'PAID TO VENDORS',
      isHidden: false,
      order: 7,
    },
    {
      Header:
        subTabType === 'innerVendorProperties'
          ? 'Amount Paid By Property'
          : 'Received From Property',
      accessor:
        subTabType === 'innerVendorProperties'
          ? 'AMOUNT PAID BY PROPERTY'
          : 'RECEIVED FROM PROPERTY',
      isHidden: false,
      order: 8,
    },
    {
      Header: 'Amount In Transition',
      accessor: 'AMOUNT IN TRANSITION',
      isHidden: false,
      order: 9,
    },
  ];

  // reorder the table colomns according to the order property value
  tableColumns = [...tableColumns].sort(
    (a: any, b: any) => parseFloat(a.order) - parseFloat(b.order)
  );

  const handleIconClick = (
    item: any,
    columnList: any,
    setColumnList: any,
    setToggleHiddenColumnChanged: any,
    toggleHiddenColumnChanged: any
  ) => {
    handleShowIconClick(
      item,
      columnList,
      setColumnList,
      setToggleHiddenColumnChanged,
      toggleHiddenColumnChanged
    );
  };

  return [tableColumns, handleIconClick];
};
