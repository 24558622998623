import classNames from 'classnames';
import { CSVLink } from 'react-csv';
import { Button } from '../Button/Button';
import { ShowMoreButton } from '../ShowMoreButton/ShowMoreButton';
import TabButton from '../TabButton/TabButton';

export interface HeaderTabNavigationProps {
  tabNavData?: any;
  isLink?: boolean;
  isShowOptionEnabled?: boolean;
  isShowMoreOptionDisabled?: boolean;
  columns?: any;
  handleIconClick?: any;
  additionalWrapperClasses?: string;
  variants?: 'extraSmall';
  onButtonClick?: () => void;
  isAdditionalButtonVisibled?: boolean;
  additionalButtonLabel?: string;
  isAdditionalButtonDisabled?: boolean;
  isDownloadCsvButtonEnabled?: boolean;
  filteredRow?: any;
  visibleColumns?: any;
  isShowOptionOnlyIconVisible?: boolean;
  isDownloadOnlyIconVisible?: boolean;
}

export const HeaderTabNavigation = ({
  tabNavData,
  isShowOptionEnabled = false,
  isShowMoreOptionDisabled = false,
  columns = [],
  handleIconClick,
  additionalWrapperClasses = 'mb-8',
  variants = 'extraSmall',
  onButtonClick,
  isAdditionalButtonVisibled,
  additionalButtonLabel,
  isAdditionalButtonDisabled,
  filteredRow,
  visibleColumns,
  isDownloadCsvButtonEnabled = false,
  isShowOptionOnlyIconVisible = true,
  isDownloadOnlyIconVisible = true,
}: HeaderTabNavigationProps) => {
  const showMoreOptionStates = isShowMoreOptionDisabled
    ? 'opacity-40 pointer-events-none'
    : 'opacity-100';

  return (
    <div
      className={classNames([
        'flex flex-row items-center justify-between w-full pb-3 border-b border-solid border-grey-100 min-h-[41px]',
        additionalWrapperClasses,
      ])}
    >
      <div className="flex items-center fex-row">
        {tabNavData?.length > 0 &&
          tabNavData?.map((item: any, index: any) => (
            <TabButton key={index} label={item.name} urlPath={item.urlPath} />
          ))}
      </div>

      <div className="flex items-center fex-row">
        {isShowOptionEnabled && (
          <div className={classNames([showMoreOptionStates, 'ml-3'])}>
            <ShowMoreButton
              columns={columns}
              handleIconClick={handleIconClick}
              isFilterEnabled={isShowOptionOnlyIconVisible}
            />
          </div>
        )}
        {isAdditionalButtonVisibled && (
          <Button
            variants={variants}
            onClick={onButtonClick}
            additionalClasses={`ml-3`}
            buttonLabel={additionalButtonLabel}
            isDisabled={isAdditionalButtonDisabled}
          />
        )}
        {isDownloadCsvButtonEnabled && (
          <div className={'ml-3'}>
            <CSVLink
              data={filteredRow?.map((row: any) => {
                const rtnValues = row.values;
                // if (row.values['vendor'] === undefined) {
                //   rtnValues[
                //     'vendor'
                //   ] = `${row?.original?.account?.first_name} ${row?.original?.account?.last_name}`;
                //   return rtnValues;
                // } else {
                //   return rtnValues;
                // }
                return rtnValues;
              })}
              headers={visibleColumns
                ?.filter((items: any) => items?.id !== 'selection')
                .map((row: any) => {
                  if (typeof row?.Header === 'object') {
                    return {
                      label: `${row?.Header?.props?.firstLine} ${row?.Header?.props?.secondLine}`,
                      key: row?.id,
                    };
                  } else {
                    return { label: row?.Header, key: row?.id };
                  }
                })}
            >
              <Button
                buttonLabel={isDownloadOnlyIconVisible ? '' : 'CSV'}
                iconAlignment="left"
                iconClass={isDownloadOnlyIconVisible ? 'mr-0' : 'mr-1'}
                iconType="DownloadIcon"
                isIconEnabled
                variants="extraSmallOutlined"
                additionalClasses="text-12s !border-grey-100 text-grey-900"
              />
            </CSVLink>
          </div>
        )}
      </div>
    </div>
  );
};
