// import React, { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
// import { useParams } from 'react-router-dom';
import { useStripe } from '@stripe/react-stripe-js';

import { ERROR_MESSAGES } from '../../../../../constants/Validation';
import { BankDetailsParams } from '../../../../../shared/utilities/interface';
import { Classes } from '../../../../../shared/configs/componentsClasses';
// import { useCreateBankAccountMutation } from '../../../../../../services/vendorsApi';

import Message from 'components/core/Message/Message';
import LoadingSpinner from 'components/core/LoadingSpinner/LoadingSpinner';
import { Typhography, Button, Input } from 'components/core';
import { useCreateSourceMutation } from 'services/paymentSourcesApi';

interface BankDetailsProps {
  onClose?: any;
}

export const BankDetails = ({ onClose }: BankDetailsProps) => {
  const inputDefaultClasses = Classes.input.default;
  const [useCreateSourceMutationOp, { isLoading, isError, isSuccess, error }] =
    useCreateSourceMutation();
  const stripe: any = useStripe();

  const schema = yup
    .object({
      account_holder_name: yup.string().required(ERROR_MESSAGES.REQUIRED),
      account_number: yup
        .string()
        .required(ERROR_MESSAGES.REQUIRED)
        .min(5, ERROR_MESSAGES.ACCOUNT_NUMBER_INVALID)
        .max(17, ERROR_MESSAGES.ACCOUNT_NUMBER_INVALID),
      account_number_conf: yup
        .string()
        .required(ERROR_MESSAGES.REQUIRED)
        .min(5, ERROR_MESSAGES.ACCOUNT_NUMBER_INVALID)
        .max(17, ERROR_MESSAGES.ACCOUNT_NUMBER_INVALID)
        .oneOf([yup.ref('account_number')], 'Account number does not match'),
      routing_number: yup
        .string()
        .required(ERROR_MESSAGES.REQUIRED)
        .min(9, ERROR_MESSAGES.SSN_INVALID_FULL)
        .max(9, ERROR_MESSAGES.SSN_INVALID_FULL),
    })
    .required();

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<BankDetailsParams>({
    resolver: yupResolver(schema),
    mode: 'onBlur', //used to check error in realtime
    shouldFocusError: true,
  });

  const onSubmitNewPaymentMethod = async (data: any) => {
    const tokenResult = await stripe.createToken('bank_account', {
      ...data,
      country: 'US',
      currency: 'usd',
    });

    const result: any = await useCreateSourceMutationOp({
      type: tokenResult?.token?.type,
      stripe_token: tokenResult?.token?.id,
    });

    if (result) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const getError = result?.error;

      if (getError) {
        setValue('account_holder_name', '');
        setValue('routing_number', '');
        setValue('account_number', '');
        setValue('account_number_conf', '');
      } else {
        setTimeout(() => {
          onClose();
        }, 2000);
      }
    }
  };

  return (
    <>
      {isLoading && <LoadingSpinner />}
      <Typhography component="p" type="h2" color="text-grey-900 mb-6 uppercase">
        {'Add Your account Details'}
      </Typhography>

      {(isError || isSuccess) && (
        <Message
          title="Manual Bank Setup"
          type={isSuccess ? 'success' : 'error'}
          additionalClasses={'mb-6'}
        >
          {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            isSuccess ? 'Thanks! your account has been successfully created.' : error?.data?.message
          }
        </Message>
      )}

      <form
        onSubmit={handleSubmit(onSubmitNewPaymentMethod)}
        className={'w-full flex flex-col max-w-3xl'}
        noValidate
      >
        <div className="flex flex-col w-full min-h-[346px]">
          <Controller
            control={control}
            name="account_holder_name"
            render={({ field: { value } }) => (
              <Input
                label="Account holder name"
                labelName={'account_holder_name'}
                type="text"
                {...register('account_holder_name')}
                className={inputDefaultClasses}
                error={errors?.account_holder_name?.message}
                required
                value={value}
              />
            )}
          />

          <Controller
            control={control}
            name="account_number"
            render={({ field: { value } }) => (
              <Input
                label={'Account number'}
                labelName={'account_number'}
                type="text"
                {...register('account_number')}
                className={inputDefaultClasses}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                error={errors?.account_number?.message}
                maxLength={17}
                additionalClasses={`text-14s w-[334px]`}
                required
                value={value}
              />
            )}
          />
          <Controller
            control={control}
            name="account_number_conf"
            render={({ field: { value } }) => (
              <Input
                label={'Confirm account number'}
                labelName={'account_number_conf'}
                type="text"
                {...register('account_number_conf')}
                className={inputDefaultClasses}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                error={errors?.account_number_conf?.message}
                maxLength={17}
                required
                value={value}
              />
            )}
          />
          <Controller
            control={control}
            name="routing_number"
            render={({ field: { value } }) => (
              <Input
                label="Routing number"
                labelName={'routing_number'}
                type="text"
                {...register('routing_number')}
                className={inputDefaultClasses}
                onKeyPress={(event: { key: string; preventDefault: () => void }) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                error={errors?.routing_number?.message}
                maxLength={9}
                required
                value={value}
              />
            )}
          />
          <Controller
            control={control}
            name="account_holder_type"
            render={({ field: { value } }) => (
              <div className="relative flex flex-col w-full mb-6 form-input-wrapper focused">
                <select
                  {...register('account_holder_type')}
                  required
                  className="form-select h-[42px] border border-solid border-grey-100 rounded-lg text-black placeholderColor-gray  text-14s text-grey-900 px-4"
                >
                  <option value="individual">Individual</option>
                  <option value="company">Company</option>
                </select>
              </div>
            )}
          />
        </div>

        <div className="flex items-center w-full">
          <Button
            type="submit"
            buttonLabel={`Add Account`}
            iconType="Spin"
            isIconEnabled={false}
            additionalClasses={'w-full'}
          />
        </div>
      </form>
    </>
  );
};
