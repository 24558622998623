import React from 'react';
import ApproveContainer from '../../components/modules/Approvals/Approve/Index';

/**
 * Primary UI component for user interaction
 */
const Approve = () => {
  return <ApproveContainer />;
};

export default Approve;
