import {
  ApprovalsIcon,
  BalanceIcon,
  HomeIcon,
  VendorsIcon,
  SettingIcon,
  ProfileIcon,
  SentIcon,
  TickIcon,
  OrderIcon,
  PrevIcon,
  NextIcon,
  SpinIcon,
  InfoIcon,
  RightArrow,
  DropDownIcon,
  DropDownArrow,
  WhiteArrowIcon,
  TrashIcon,
  AddNewitemIcon,
  OkIcon,
  EditIcon,
  AddIcon,
  TickIconGray,
  CloseIcon,
  DatePicker,
  OrderIconASC,
  OrderIconDESC,
  TickIconForSuccess,
  BlackLeftArrow,
  TickIconWhite,
  PropertiesIcon,
  SearchIcon,
  IncreaseModeIcon,
  DecreaseModeIcon,
  DownloadIcon,
  EyeIcon,
  FilterIcon,
  EyeIconClose,
  EyeIconLarge,
  CardClockIcon,
  EditLineIcon,
  DotsMoreIcon,
  InfoRoundedIcon,
  TermsIcon,
  RemoveTrashIcon,
  RoundTickIcon,
  ValidationWarningIcon,
  WarningIcon,
  SuccessIcon,
  NeturalIcon,
  DelinquentIcon,
  UploadIcon,
  PropertyIcon,
  WireTransferIcon,
  InfoLinedIcon,
  QuestionIcon,
  PrintIcon,
  InvoiceIcon,
  TimerIcon,
  DollarCircled,
  TimeCalculation,
  CalendarIcon,
  TimerIconSmall,
  DotsHorizontalMoreIcon,
  Payments,
  Reconcile,
  Dollaricon,
  UserIcon,
  LockedIcon,
} from '../Icons/Icons';

export const handleIconColor = (_iconColor: string) => `text-${_iconColor}`;
export const handleIconHoverColor = (_iconHoverColor: string | undefined) =>
  `hover:text-${_iconHoverColor} cursor-pointer`;

export const handleIconTYpes = (_iconType: string | undefined) => {
  switch (_iconType) {
    case 'Home':
      return <HomeIcon />;
    case 'Approvals':
      return <ApprovalsIcon />;
    case 'Vendors':
      return <VendorsIcon />;
    case 'Balance':
      return <BalanceIcon />;
    case 'Setting':
      return <SettingIcon />;
    case 'Profile':
      return <ProfileIcon />;
    case 'Sent':
      return <SentIcon />;
    case 'Tick':
      return <TickIcon />;
    case 'Ordering':
      return <OrderIcon />;
    case 'Next':
      return <NextIcon />;
    case 'Prev':
      return <PrevIcon />;
    case 'Spin':
      return <SpinIcon />;
    case 'Info':
      return <InfoIcon />;
    case 'RightArrow':
      return <RightArrow />;
    case 'DropDown':
      return <DropDownIcon />;
    case 'DropDownArrow':
      return <DropDownArrow />;
    case 'WhiteArrow':
      return <WhiteArrowIcon />;
    case 'TrashIcon':
      return <TrashIcon />;
    case 'AddNewitemIcon':
      return <AddNewitemIcon />;
    case 'OkIcon':
      return <OkIcon />;
    case 'EditIcon':
      return <EditIcon />;
    case 'AddIcon':
      return <AddIcon />;
    case 'TickIconGray':
      return <TickIconGray />;
    case 'CloseIcon':
      return <CloseIcon />;
    case 'DatePicker':
      return <DatePicker />;
    case 'OrderIconASC':
      return <OrderIconASC />;
    case 'OrderIconDESC':
      return <OrderIconDESC />;
    case 'TickIconForSuccess':
      return <TickIconForSuccess />;
    case 'BlackLeftArrow':
      return <BlackLeftArrow />;
    case 'TickIconWhite':
      return <TickIconWhite />;
    case 'Properties':
      return <PropertiesIcon />;
    case 'SearchIcon':
      return <SearchIcon />;
    case 'IncreaseMode':
      return <IncreaseModeIcon />;
    case 'DecreaseMode':
      return <DecreaseModeIcon />;
    case 'DownloadIcon':
      return <DownloadIcon />;
    case 'EyeIcon':
      return <EyeIcon />;
    case 'FilterIcon':
      return <FilterIcon />;
    case 'EyeIconClose':
      return <EyeIconClose />;
    case 'EyeIconLarge':
      return <EyeIconLarge />;
    case 'CardClockIcon':
      return <CardClockIcon />;
    case 'EditLineIcon':
      return <EditLineIcon />;
    case 'DotsMoreIcon':
      return <DotsMoreIcon />;
    case 'InfoRoundedIcon':
      return <InfoRoundedIcon />;
    case 'TermsIcon':
      return <TermsIcon />;
    case 'RemoveTrashIcon':
      return <RemoveTrashIcon />;
    case 'RoundTickIcon':
      return <RoundTickIcon />;
    case 'ValidationWarningIcon':
      return <ValidationWarningIcon />;
    case 'WarningIcon':
      return <WarningIcon />;
    case 'SuccessIcon':
      return <SuccessIcon />;
    case 'NeturalIcon':
      return <NeturalIcon />;
    case 'DelinquentIcon':
      return <DelinquentIcon />;
    case 'UploadIcon':
      return <UploadIcon />;
    case 'PropertyIcon':
      return <PropertyIcon />;
    case 'WireTransferIcon':
      return <WireTransferIcon />;
    case 'InfoLinedIcon':
      return <InfoLinedIcon />;
    case 'QuestionIcon':
      return <QuestionIcon />;
    case 'PrintIcon':
      return <PrintIcon />;
    case 'InvoiceIcon':
      return <InvoiceIcon />;
    case 'TimerIcon':
      return <TimerIcon />;
    case 'DollarCircled':
      return <DollarCircled />;
    case 'TimeCalculation':
      return <TimeCalculation />;
    case 'CalendarIcon':
      return <CalendarIcon />;
    case 'TimerIconSmall':
      return <TimerIconSmall />;
    case 'DotsHorizontalMoreIcon':
      return <DotsHorizontalMoreIcon />;
    case 'Payments':
      return <Payments />;
    case 'Reconcile':
      return <Reconcile />;
    case 'Dollaricon':
      return <Dollaricon />;
    case 'UserIcon':
      return <UserIcon />;
    case 'LockedIcon':
      return <LockedIcon />;
    default:
      break;
  }
};
