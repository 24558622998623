import * as yup from 'yup';
import { ERROR_MESSAGES } from '../constants/Validation';
/**
 * vender profile validation schemas
 */
export const VendorProfileSchemaObj = {
  first_name: yup.string().required(ERROR_MESSAGES.REQUIRED),
  last_name: yup.string().required(ERROR_MESSAGES.REQUIRED),
  email: yup.string().email(ERROR_MESSAGES.EMAIL_INVALID).required(ERROR_MESSAGES.REQUIRED),
  line1: yup.string().typeError(ERROR_MESSAGES.REQUIRED).required(ERROR_MESSAGES.REQUIRED),
  city: yup.string().typeError(ERROR_MESSAGES.REQUIRED).required(ERROR_MESSAGES.REQUIRED),
  postal_code: yup
    .string()
    .typeError(ERROR_MESSAGES.REQUIRED)
    .required(ERROR_MESSAGES.REQUIRED)
    .max(5, ERROR_MESSAGES.ZIPCODE_INVALID)
    .min(5, ERROR_MESSAGES.ZIPCODE_INVALID),
  state: yup.string().typeError(ERROR_MESSAGES.REQUIRED).required(ERROR_MESSAGES.REQUIRED),
};

export const BusinessProfileSchemaObj = {
  inputCompanyName: yup
    .string()
    .typeError(ERROR_MESSAGES.REQUIRED)
    .required(ERROR_MESSAGES.REQUIRED),
  inputComLine1: yup.string().typeError(ERROR_MESSAGES.REQUIRED).required(ERROR_MESSAGES.REQUIRED),
  inputComCity: yup.string().typeError(ERROR_MESSAGES.REQUIRED).required(ERROR_MESSAGES.REQUIRED),
  inputComPostal: yup
    .string()
    .typeError(ERROR_MESSAGES.REQUIRED)
    .required(ERROR_MESSAGES.REQUIRED)
    .max(5, ERROR_MESSAGES.ZIPCODE_INVALID)
    .min(5, ERROR_MESSAGES.ZIPCODE_INVALID),
};

export const AccountSignUpSchemaOObj = {
  name: yup.string().required(ERROR_MESSAGES.REQUIRED),
  email: yup.string().email(ERROR_MESSAGES.EMAIL_INVALID).required(ERROR_MESSAGES.REQUIRED),
};

export const PropertyProfileSchemaObj = {
  name: yup.string().required(ERROR_MESSAGES.REQUIRED),
  manager: yup.string().required(ERROR_MESSAGES.REQUIRED),
  email: yup.string().email(ERROR_MESSAGES.EMAIL_INVALID).required(ERROR_MESSAGES.REQUIRED),
  street_1: yup.string().typeError(ERROR_MESSAGES.REQUIRED).required(ERROR_MESSAGES.REQUIRED),
  city: yup.string().typeError(ERROR_MESSAGES.REQUIRED).required(ERROR_MESSAGES.REQUIRED),
  state: yup.string().typeError(ERROR_MESSAGES.REQUIRED).required(ERROR_MESSAGES.REQUIRED),
  code: yup.string().typeError(ERROR_MESSAGES.REQUIRED).required(ERROR_MESSAGES.REQUIRED),
};
