import classNames from 'classnames';
import { PaginationProps } from 'shared/models/components/base.model';
import Icon from '../../Media/Icon/Icon';
import { Typhography } from '../../Typhography/Typhography';

const Pagination = ({
  previousPage,
  pageOptions,
  totalPageCount,
  pageIndex,
  nextPage,
  pageSize,
  setPageSize,
  options = [25, 50, 75, 100, 125, 150, 200],
  canNextPage,
  canPreviousPage,
}: PaginationProps) => {
  const linkClasses =
    'flex items-center justify-center  h-6 bg-white border border-solid  border-grey-100 text-grey-900 rounded mr-1';
  const pagerClasses = ['w-6 ease-out duration-300 cursor-pointer', linkClasses];
  const currentPagerClasses = ['w-28 text-12s font-normal', linkClasses];
  const separatorClasses = 'text-grey-9700 text-12s font-normal mr-1 ml-1';
  const totalPageCounterClasses = 'text-grey-700 font-normal text-12s mr-[10px]';
  return (
    <div className="flex align-center my-[15px] mx-[0px]">
      <div className="flex-1">
        <div className="flex flex-row items-center">
          {canPreviousPage && (
            <div className={classNames(pagerClasses)} onClick={() => previousPage()}>
              <Icon
                iconColor="inherit"
                iconHoverColor="inherit"
                iconType="Prev"
                iconContainerClasses="ease-out duration-300"
              />
            </div>
          )}
          <div className="flex flex-row items-center">
            <span className={classNames(currentPagerClasses)}>{pageIndex + 1}</span>
            <span className={separatorClasses}>{`/`}</span>
            <span className={totalPageCounterClasses}>
              {totalPageCount ? totalPageCount : pageOptions.length}
            </span>
          </div>
          {canNextPage && (
            <div className={classNames(pagerClasses)} onClick={() => nextPage()}>
              <Icon
                iconColor="inherit"
                iconHoverColor="inherit"
                iconType="Next"
                iconContainerClasses="ease-out duration-300"
              />
            </div>
          )}
        </div>
      </div>
      <div className="inline-flex items-center">
        <Typhography component="span" type="h1" additionalClasses="mr-2" color="text-grey-700">
          Rows per page
        </Typhography>
        <select
          className="form-select h-6 border border-grey-100 border-solid text-12s text-grey-900 
          rounded appearence-none placeholderColor-gray shadow-none w-14 pr-1 cursor-pointer
          bg-[center_right_0.5rem] form-control-small bg-[length:auto_4px] pt-0 pb-0 !outline-none"
          value={pageSize}
          onChange={setPageSize}
        >
          {options?.map((pageSize: any) => {
            return (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
};

export default Pagination;
