import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import classNames from 'classnames';

import Icon from '../Media/Icon/Icon';

import { NavItemProps } from 'shared/models/components/base.model';

/**
 * Primary UI component for user interaction
 *
 * Ignore the storyboard parent div styles, because it's used only for visibility purpose
 */
const NavItem: React.FC<NavItemProps> = ({
  iconType = 'Home',
  name = 'Nav Link',
  urlPath = '/#',
  additionalClasses,
  listItemClasses,
  indexVal,
  ...rest
}) => {
  const location = useLocation();
  const navLinkClasses = ['flex flex-col ease-out duration-300', additionalClasses];
  const liClasses = ['flex self-center h-78', listItemClasses];
  const handleActiveMenu = indexVal && (location.pathname.indexOf(indexVal) === 1 ? true : false);

  return (
    <li className={classNames(liClasses)} {...rest}>
      <NavLink
        to={urlPath}
        style={({ isActive }) => ({
          color: isActive || handleActiveMenu ? '#fff' : '#E8EAED',
          strokeWidth: isActive || handleActiveMenu ? 2 : 1,
        })}
        className={classNames(navLinkClasses)}
      >
        <Icon
          iconType={iconType}
          iconText={name}
          iconTextClasses="text-12s font-normal pt-3 ease-out duration-300"
          iconColor="inherit"
          iconHoverColor="white"
          iconContainerClasses="ease-out duration-300"
          additionalClasses="flex flex-col items-center"
        />
      </NavLink>
    </li>
  );
};

export default NavItem;
