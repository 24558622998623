import React from 'react';
import classNames from 'classnames';

import { PageTitle } from '../../core';
import SideDrawyer from '../SideDrawyer/SideDrawyer';
import { TopNavigationBar } from '../TopNavigationBar/TopNavigationBar';

import { WrapperProps } from 'shared/models/components/layouts.model';
import { Classes } from '../../../shared/configs/componentsClasses';

/**
 * Primary UI component for user interaction
 */
const Wrapper: React.FC<WrapperProps> = ({
  additionalClasses = '',
  defaultClasses = Classes.wrapper.default,
  isNavigationEnabled = true,
  isBackgroundGray = false,
  isFullScreenHeight = false,
  pageTitle,
  metaDescription,
  children,
  backgroundClass = '',
  isBackButtonVisible,
  ...rest
}) => {
  const handleBgColor =
    backgroundClass !== '' ? backgroundClass : isBackgroundGray ? 'bg-gray-100' : 'bg-white';
  const handleNavigationEnabledClasses = isNavigationEnabled && 'pl-[108px] pt-10 pr-4';
  const handleFullScreenHeight = isFullScreenHeight ? 'h-screen' : 'h-auto';
  const wrapperClasses = [
    defaultClasses,
    handleFullScreenHeight,
    handleNavigationEnabledClasses,
    handleBgColor,
    additionalClasses,
  ];

  return (
    <div data-testid="wrapper">
      <PageTitle pageTitle={pageTitle} metaDescription={metaDescription} />
      {isNavigationEnabled && <SideDrawyer />}
      {isNavigationEnabled && <TopNavigationBar isBackButtonVisible={isBackButtonVisible} />}
      <div className={classNames(wrapperClasses)} {...rest}>
        {children}
      </div>
    </div>
  );
};

export default Wrapper;
