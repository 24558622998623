import { StatusTag } from '../../Chips/StatusTag/StatusTag';
import { BankDetailRow } from '../../DataRows/BankDetailRow/BankDetailRow';
import Icon from '../../Media/Icon/Icon';
import { Typhography } from '../../Typhography/Typhography';
import { useComponentVisible } from 'hooks/useComponentVisible';
import { useTitleCase } from 'hooks/useTitleCase';
import { IntentRow } from '../../DataRows/BankDetailRow/IntentRow';

export interface IntentCardProps {
  title?: string;
  isDefault?: boolean;
  valueAccountType?: string;
  valueConnectedOn?: string;
  valueAccountHolder?: string;
  valueRouting?: string;
  valueCountry?: string;
  lastFourDigits?: string;
  setEditAccount?: any;
  setAsDefault?: any;
  removeAccount?: any;
  valueBankName?: string;
  isPaymentCard?: boolean;
  valueStatus?: string;
  handleStatusVerification?: () => void;
  methodId?: string;
  editAccount?: any;
  isPaymentEditable?: boolean;
  valueLabel?: string;
  valueAccountHolderType?: string;
  isDefaultOptionAvailable?: boolean;
  nextAction?: any;
  valueCreatedAt?: any;
  valueLastEdit?: any;
  valueModifiedBy?: any;
  isPaymentEditAccess?: boolean;
  mandateStatus?: string;
}

export const IntentCard = ({
  title,
  isDefault = false,
  valueAccountType,
  valueConnectedOn,
  valueAccountHolder,
  valueRouting,
  valueCountry,
  lastFourDigits,
  setEditAccount,
  setAsDefault,
  removeAccount,
  valueBankName,
  isPaymentCard = false,
  valueStatus,
  methodId,
  handleStatusVerification,
  editAccount,
  isPaymentEditable = false,
  valueLabel,
  valueAccountHolderType,
  isDefaultOptionAvailable = true,
  nextAction,
  valueCreatedAt,
  valueModifiedBy,
  isPaymentEditAccess,
  mandateStatus,
  ...props
}: IntentCardProps) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const showMoreDropdownItemClasses =
    'h-[34px] px-4 hover:bg-grey-50 flex items-center cursor-pointer text-12s font-medium';

  const handleOnsetDetault = () => {
    setAsDefault && setAsDefault();
    setIsComponentVisible(!isComponentVisible);
  };

  const handleOnRemove = () => {
    removeAccount && removeAccount();
    setIsComponentVisible(!isComponentVisible);
  };

  const handleOnEdit = () => {
    editAccount && editAccount();
    setIsComponentVisible(!isComponentVisible);
  };

  return (
    <div className={`w-full border border-solid rounded-lg border-grey-100 pt-3`} {...props}>
      <div className="flex items-center justify-between w-full h-[54px] px-4">
        <div className="flex flex-col">
          <div className="flex items-center">
            {isPaymentCard && (
              <Icon
                iconColor="inherit"
                iconType="PropertyIcon"
                additionalClasses="mr-2 -top-[2px] relative text-primary-500"
              />
            )}
            <Typhography
              component="p"
              type="h2Bold"
              color={!isPaymentCard ? 'text-grey-900 mb-[3px]' : 'text-primary-500'}
            >
              {title}
            </Typhography>
          </div>
          {lastFourDigits && (
            <div className="flex items-center">
              <Typhography component="span" type="h1" color="text-grey-600 !font-normal mr-3">
                {'Account'}
              </Typhography>
              <Typhography
                component="span"
                type="h2"
                color="text-grey-600 !font-normal mr-1 relative top-[1px]"
              >
                {'• • • • •'}
              </Typhography>
              <Typhography component="span" type="h1" color="text-grey-600 !font-normal">
                {lastFourDigits}
              </Typhography>
            </div>
          )}
        </div>
        <div>
          {isDefault && isDefaultOptionAvailable && <StatusTag status="Default" />}
          {(!isDefault || isPaymentCard) && isPaymentEditAccess && (
            <div className="relative flex" ref={ref}>
              <div
                onClick={() => setIsComponentVisible(!isComponentVisible)}
                className="flex items-center justify-center w-5 cursor-pointer text-grey-500"
              >
                <Icon iconColor="inherit" iconType="DotsMoreIcon" />
              </div>
              {isComponentVisible && (
                <div className="absolute w-[140px] rounded-lg rounded-tr-none bg-white py-2 shadow-showMoreDropDown top-0 right-[20px]">
                  {isDefaultOptionAvailable && (
                    <div
                      className={`${showMoreDropdownItemClasses} text-grey-900`}
                      onClick={handleOnsetDetault}
                    >
                      <Icon iconColor="inherit" iconType="RoundTickIcon" additionalClasses="mr-1" />
                      Make Default
                    </div>
                  )}

                  {isPaymentEditable && (
                    <div
                      className={`${showMoreDropdownItemClasses} text-grey-900`}
                      onClick={handleOnEdit}
                    >
                      <Icon iconColor="inherit" iconType="EditIcon" additionalClasses="mr-2" />
                      Edit
                    </div>
                  )}

                  <div
                    className={`${showMoreDropdownItemClasses} text-red-500`}
                    onClick={handleOnRemove}
                  >
                    <Icon iconColor="inherit" iconType="RemoveTrashIcon" additionalClasses="mr-1" />
                    Remove
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="w-full px-4 pt-4 pb-3">
        {valueAccountType && <BankDetailRow title="Type" value={useTitleCase(valueAccountType)} />}
        <BankDetailRow
          title="Bank name"
          value={valueBankName ? useTitleCase(valueBankName) : null}
        />
        {valueConnectedOn && <BankDetailRow title="Connected on" value={valueConnectedOn} />}
        <BankDetailRow title="Account holder" value={valueAccountHolder} />
        <BankDetailRow title="Routing" value={valueRouting} />
        <BankDetailRow
          title="Account holder type"
          value={valueAccountHolderType ? useTitleCase(valueAccountHolderType) : null}
        />
        <BankDetailRow title="Label" value={valueLabel ? useTitleCase(valueLabel) : null} />
        {valueCountry && <BankDetailRow title="Country" value={valueCountry} isCountry />}
        <BankDetailRow title="Connected On" value={valueCreatedAt} />
        <BankDetailRow title="Created By" value={valueModifiedBy} />
        <IntentRow title="Mandate Status" statusType={mandateStatus} isPaymentEditAccess={false} />
        {valueStatus && (
          <IntentRow
            title="Status"
            statusType={valueStatus === 'verified' ? 'verified' : 'verify_now'}
            handleStatusVerificationClick={handleStatusVerification}
            isPaymentEditAccess={isPaymentEditAccess}
          />
        )}
      </div>
    </div>
  );
};
