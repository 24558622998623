import { isPermitted, useGetUserQuery } from 'services/userApi';

export const approvedTabnavData = [
  { id: 1, urlPath: '/invoices/submitted', navTitle: 'Submitted', navClasses: 'submitted' },
  { id: 2, urlPath: '/invoices/approved', navTitle: 'Approved', navClasses: 'approved' },
  { id: 3, urlPath: '/invoices/paid', navTitle: 'Paid', navClasses: 'paid' },
  { id: 4, urlPath: '/invoices/all', navTitle: 'All', navClasses: 'all' },
];

export const propertiesTabnavData = [
  { id: 2, urlPath: '/properties/active', navTitle: 'Active ', navClasses: 'active ' },
  { id: 1, urlPath: '/properties/delinquent', navTitle: 'Delinquent ', navClasses: 'delinquent ' },
  { id: 3, urlPath: '/properties/all', navTitle: 'All', navClasses: 'all' },
];

export const handleVndorsTabNavData = (data: any) => {
  const totalVendorsCount = data?.allAccounts?.totalCount;
  const totalActiveVendorsCount = data?.allActiveAccounts?.totalCount;
  const totalInactiveVendorsCount = data?.allInactiveAccounts?.totalCount;

  const vendorsTabnavData = [
    {
      id: 1,
      urlPath: '/vendors/active',
      navTitle: 'Active',
      navClasses: 'active',
      count: totalActiveVendorsCount,
    },
    {
      id: 2,
      urlPath: '/vendors/inactive',
      navTitle: 'Inactive ',
      navClasses: 'inactive ',
      count: totalInactiveVendorsCount,
    },
    {
      id: 3,
      urlPath: '/vendors/all',
      navTitle: 'All',
      navClasses: 'all',
      count: totalVendorsCount,
    },
  ];

  return [vendorsTabnavData];
};

export const balanceDaysTabnavData = [
  { id: 1, urlPath: '/balance/past', navTitle: 'Past', navClasses: 'past' },
  { id: 2, urlPath: '/balance/current', navTitle: 'Today ', navClasses: 'today ' },
  { id: 3, urlPath: '/balance/upcoming', navTitle: 'Upcoming', navClasses: 'upcoming' },
];

export const balanceWeekTabnavData = [
  { id: 1, urlPath: '/balance/past', navTitle: 'Past', navClasses: 'past' },
  { id: 2, urlPath: '/balance/current', navTitle: 'Current ', navClasses: 'today ' },
  { id: 3, urlPath: '/balance/upcoming', navTitle: 'Upcoming', navClasses: 'upcoming' },
];

export const tabNavData = [
  { name: 'Past', value: 'Past' },
  { name: 'Today', value: 'Today' },
  { name: 'Upcoming', value: 'Upcoming' },
];

export const handleVendorsInnerTabNavData = (id: any) => {
  const { data: user } = useGetUserQuery({});

  let vendorsInnerTabNavData = [
    {
      id: 1,
      name: 'All Invoices',
      urlPath: `/vendors/earnings/${id}`,
      value: 'Earnings',
      navClasses: 'earnings',
    },
  ];

  if (isPermitted(user, 'transctions-menu')) {
    const importedInvoicesTab = [
      {
        id: 2,
        name: 'All Payments',
        urlPath: `/vendors/payouts/${id}`,
        value: 'Payments',
        navClasses: 'payments',
      },
    ];

    vendorsInnerTabNavData = [...vendorsInnerTabNavData, ...importedInvoicesTab];
  }

  if (isPermitted(user, 'view-dashboard')) {
    const importedInvoicesTab = [
      {
        id: 3,
        name: 'Vendor Profile',
        urlPath: `/vendors/account/${id}`,
        value: 'Vprofile',
        navClasses: 'vprofile',
      },
      {
        id: 5,
        name: 'Properties',
        urlPath: `/vendors/properties/${id}`,
        value: 'Properties',
        navClasses: 'properties',
      },
    ];

    vendorsInnerTabNavData = [...vendorsInnerTabNavData, ...importedInvoicesTab];
  }

  if (isPermitted(user, 'import-invoices')) {
    const importedInvoicesTab = [
      {
        id: 5,
        name: 'Imported Invoices',
        urlPath: `/vendors/imported-invoices/${id}`,
        value: 'ImportedInvoices',
        navClasses: 'imported_invoices',
      },
    ];

    vendorsInnerTabNavData = [...vendorsInnerTabNavData, ...importedInvoicesTab];
  }

  return [vendorsInnerTabNavData];
};

export const handlePropertiesDetailTabNavData = (id: any) => {
  const { data: user } = useGetUserQuery({});

  let detailTabNavData = [
    { name: 'All Invoices', urlPath: `/properties/invoices/${id}`, value: 'Invoices' },
  ];

  if (isPermitted(user, 'setting-balance')) {
    const paymentsTab = [
      { name: 'All Payments', urlPath: `/properties/payments/${id}`, value: 'Payments' },
    ];

    detailTabNavData = [...detailTabNavData, ...paymentsTab];
  }

  const otherDetailTabNavData = [
    { name: 'Property Profile', urlPath: `/properties/profile/${id}`, value: 'Pprofile' },
    { name: 'Vendors', urlPath: `/properties/vendors/${id}`, value: 'Vendors' },
    // { name: 'Linked Account', urlPath: `/properties/payment/${id}`, value: 'Payment' },
    {
      name: 'Payouts',
      urlPath: `/properties/payment-batches/${id}`,
      value: 'payment-batches',
    },
  ];

  detailTabNavData = [...detailTabNavData, ...otherDetailTabNavData];

  return [detailTabNavData];
};

export const handleSettingsTabNavData = () => {
  const { data: user } = useGetUserQuery({});

  let settingsTabNavData = [
    { name: 'Team', value: 'Team', urlPath: `/setting/team` },
    // { name: 'Payment', value: 'Payment', urlPath: `/setting/payment` },
  ];

  if (isPermitted(user, 'setting-balance')) {
    const balanceTab = [{ name: 'Balance', value: 'Balance', urlPath: `/setting/balance` }];

    settingsTabNavData = [...settingsTabNavData, ...balanceTab];
  }

  return [settingsTabNavData];
};

export const transactionTabnavData = [
  { id: 1, urlPath: '/payments/current', navTitle: 'Current', navClasses: 'current' },
  { id: 2, urlPath: '/payments/upcoming', navTitle: 'Upcoming', navClasses: 'upcoming' },
];
