import VendorImportIednvoicesContainer from 'components/modules/Vendors/Account/ImportedInvoices';

/**
 * Primary UI component for user interaction
 */
const VendorImportIednvoices = () => {
  return <VendorImportIednvoicesContainer />;
};

export default VendorImportIednvoices;
