import React from 'react';
import classNames from 'classnames';
import CardDetail from './CardDetail/CardDetail';
import AddItemCard from '../../../../core/OldComponents/AddItemCard/AddItemCard';
import { PaymentSource } from '../../../../../shared/models/services/paymentsources.model';

interface CardListProps {
  cardData?: PaymentSource[];
  addNewMethodFunction?: any;
  isVisible?: boolean;
  defaultSourceID?: any;
  isFade?: boolean;
  verifyButtonClick?: any;
  cardAdditionalClasses?: string;
  cardListAdditionalClasses?: string;
}

const CardList: React.FC<CardListProps> = ({
  cardData,
  addNewMethodFunction,
  isVisible,
  defaultSourceID,
  isFade = true,
  verifyButtonClick,
  cardAdditionalClasses,
  cardListAdditionalClasses = 'justify-center',
}) => {
  const handleFadeClasses = isFade ? 'opacity-0' : 'opacity-100';

  return (
    <>
      {isVisible && (
        <div
          className={classNames([
            'flex flex-row flex-wrap mb-6 duration-300 ease-out',
            handleFadeClasses,
            cardListAdditionalClasses,
          ])}
        >
          {cardData
            ?.filter((value) => value.object === 'bank_account')
            .map((card, index) => {
              return (
                <CardDetail
                  cardHolderName={card.bank_name}
                  cardNumber={'XXXXX' + card.last4}
                  cardType={card.id === defaultSourceID ? 'black' : 'whiteBorder'}
                  colorType={card.id === defaultSourceID ? 'white' : 'black'}
                  key={index}
                  methodId={card.id}
                  status={card.status}
                  isDefault={card.id === defaultSourceID ? true : false}
                  verifyButtonClick={verifyButtonClick}
                  cardAdditionalClasses={cardAdditionalClasses}
                />
              );
            })}
          <AddItemCard
            methodAddHandler={addNewMethodFunction}
            cardAdditionalClasses={cardAdditionalClasses}
          />
        </div>
      )}
    </>
  );
};

export default CardList;
