import React from 'react';
import Error500Container from '../../../components/modules/Error/Error500/Error500';

/*
 * Primary UI component for user interaction
 */
const Error500 = () => {
  return <Error500Container />;
};

export default Error500;
