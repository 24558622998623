import React from 'react';
import { isPermitted, useGetUserQuery } from 'services/userApi';
import ErrorContainer from '../Error/Error';

/*
 * Primary UI component for user interaction
 */
const Error401Container = () => {
  const { data: user } = useGetUserQuery({});
  const isDashboardURLAccessible = isPermitted(user, 'analytics-menu');
  return (
    <ErrorContainer
      redirectURL={isDashboardURLAccessible ? '/dashboard' : '/invoices/submitted'}
      errorType={401}
      errorText="Invalid authentication, the client request has not been completed"
      buttonLabel="Sign Out"
      isRedirectToLogin={true}
    />
  );
};

export default Error401Container;
