import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import { AddAccountModalProps } from 'shared/models/components/base.model';
import Icon from '../../Media/Icon/Icon';
import Modal from '../Modal/Modal';
import { BankDetails } from './BankDetails/BankDetails';
import { BankSetup } from './BankSetup/BankSetup';
import { loadStripe } from '@stripe/stripe-js';
import { useGetConfigQuery } from '../../../../services/configApi';
import { Elements } from '@stripe/react-stripe-js';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import { useState } from 'react';
import { TypeSelection } from './TypeSelection/TypeSelection';
import { WireTransfer } from './WireTransfer/WireTransfer';
import classNames from 'classnames';
import { FileHandlerButtons } from '../../FileHandlerButtons/FileHandlerButtons';

export const AddPaymentSourceModal = ({
  isOpen = true,
  onClose,
  isIgnoreFirstStep,
  isLastStepVisible,
}: AddAccountModalProps) => {
  const { data, isLoading } = useGetConfigQuery('');

  const intialStepVisibility = {
    step1: true,
    step2: false,
    step3: false,
    step4: false,
  };

  const [stepVisibility, setStepVisibility] = useState(intialStepVisibility);

  const onFirstStepClick = () => {
    setStepVisibility({
      ...stepVisibility,
      step1: false,
      step2: true,
    });
  };

  const onSecondStepClick = () => {
    setStepVisibility({
      ...stepVisibility,
      step2: false,
      step3: true,
    });
  };

  const onCloseEvent = () => {
    onClose && onClose();
    setStepVisibility(intialStepVisibility);
  };

  const onLastStepClick = () => {
    setStepVisibility({
      ...stepVisibility,
      step1: false,
      step4: true,
    });
  };

  const handleModalClasses =
    stepVisibility.step4 || isLastStepVisible
      ? 'w-[612px] max-h-[95vh] overflow-y-auto overflow-x-hidden'
      : 'w-[528px] px-6 ';

  const printDocument = (printable: boolean) => {
    const input: any = document.getElementById('divToPrint');
    html2canvas(input).then((canvas) => {
      const imgData: any = canvas.toDataURL('image/png');
      const pdf: any = new jsPDF();
      pdf.addImage(imgData, 'JPEG', 20, 20, 160, 220);

      if (printable) {
        window.open(pdf.output('bloburl'), '_blank');
      } else {
        pdf.save('download.pdf');
      }
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onCloseEvent}
      modalAdditionalClasses={classNames([
        'min-h-[492px] pt-7 pb-8 rounded-lg bg-white flex-col',
        handleModalClasses,
      ])}
    >
      {isLoading && <LoadingSpinner />}

      <div className="absolute cursor-pointer top-7 right-7 text-grey-600 " onClick={onClose}>
        <Icon iconColor="inherit" iconType="CloseIcon" />
      </div>

      {stepVisibility.step1 && !isIgnoreFirstStep && !isLastStepVisible && (
        <TypeSelection onPaymentClick={onFirstStepClick} onWireTransferClick={onLastStepClick} />
      )}

      {(stepVisibility.step2 || isIgnoreFirstStep) && !isLastStepVisible && (
        <Elements stripe={loadStripe(data?.stripe_api_public_key)}>
          <BankSetup onClick={onSecondStepClick} onClose={onClose} />
        </Elements>
      )}

      {stepVisibility.step3 && !isLastStepVisible && <BankDetails onClose={onCloseEvent} />}

      {(stepVisibility.step4 || isLastStepVisible) && (
        <>
          <div className="w-auto top-6 right-[72px] absolute">
            <FileHandlerButtons
              onClickDownload={() => printDocument(false)}
              onClickPrint={() => printDocument(true)}
            />
          </div>
          <div className="w-[612px]">
            <WireTransfer onClose={onCloseEvent} />
          </div>
        </>
      )}
    </Modal>
  );
};
