import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import Icon from 'components/core/Media/Icon/Icon';
import Modal from '../Modal/Modal';
import { ERROR_MESSAGES } from 'constants/Validation';
import { RecipientDetailsFormParams } from 'shared/models/components/base.model';
import {
  Button,
  Checkbox,
  Grid,
  Input,
  LoadingSpinner,
  Message,
  StateDropDown,
} from 'components/core';
import { AddressPOboxNote } from 'components/core/Notes/AddressPOboxNote/AddressPOboxNote';
import { useUpdateVendorsAccountMutation } from 'services/vendorsApi';
import { useEffect, useState } from 'react';
import { isBillingAndRecipentAddressAreTheSame } from 'shared/utilities/utils';
import { dtoToRecipentFormValues } from 'shared/utilities/dto';
// import { useState } from 'react';

export interface IAddRecipientDetailsModal {
  onModalClose?: () => void;
  vendorData?: any;
  onFormSubmitEvent?: any;
  vendorID?: string;
  setErrorMessage?: any;
}

export type FormProps = {
  address: string;
  line2: string;
  city: string;
  state?: string;
  zip?: string;
};

export const AddRecipientDetailsModal = ({
  onModalClose,
  vendorData,
  onFormSubmitEvent,
  vendorID,
  setErrorMessage,
}: IAddRecipientDetailsModal) => {
  const defaultValues: FormProps = { address: '', line2: '', city: '', zip: '' };
  const [updateVendorsAccount, { isLoading: updateLoading }]: any =
    useUpdateVendorsAccountMutation();
  const [internalErrorMessage, setInternalErrorMessage] = useState('');

  const schema = yup
    .object({
      address: yup.string().typeError(ERROR_MESSAGES.REQUIRED).required(ERROR_MESSAGES.REQUIRED),
      city: yup.string().typeError(ERROR_MESSAGES.REQUIRED).required(ERROR_MESSAGES.REQUIRED),
      zip: yup
        .string()
        .typeError(ERROR_MESSAGES.REQUIRED)
        .required(ERROR_MESSAGES.REQUIRED)
        .max(5, ERROR_MESSAGES.ZIPCODE_INVALID)
        .min(5, ERROR_MESSAGES.ZIPCODE_INVALID),
      state: yup.string().typeError(ERROR_MESSAGES.REQUIRED).required(ERROR_MESSAGES.REQUIRED),
      name: yup
        .string()
        .typeError(ERROR_MESSAGES.REQUIRED)
        .required(ERROR_MESSAGES.REQUIRED)
        .trim()
        .min(2, ERROR_MESSAGES.INVALID_NAME)
        .matches(/[A-Za-z 0-9]/g, ERROR_MESSAGES.INVALID_NAME),
    })
    .required();

  const {
    register,
    handleSubmit,
    setValue,
    // getValues,
    control,
    formState: { errors },
  } = useForm<RecipientDetailsFormParams>({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const formSubmit = handleSubmit(async (data: any) => {
    const response = await updateVendorsAccount({
      obj: {
        id: vendorID,
        default_pay_mode: 'check',
        check_recipient_details: {
          address: data.address,
          line2: data.line2,
          name: data.name,
          zip: data.zip,
          state: data.state,
          city: data.city,
          pay_mode: 'check',
        },
      },
      isCompany: false,
      isMappingRequired: false,
    });

    if (response?.error) {
      if (setErrorMessage) {
        onModalClose && onModalClose();
        setErrorMessage(response?.error?.error ?? response?.error?.message);
      } else {
        setInternalErrorMessage(response?.error?.error ?? response?.error?.message);
      }
    } else {
      onFormSubmitEvent && onFormSubmitEvent();
    }
  });

  const handleAddressIntheIntialLoad = () => {
    let addressObj;

    if (vendorData?.business_type === 'individual') {
      addressObj = vendorData?.individual?.address;
    } else {
      addressObj = vendorData?.company?.address;
    }

    const recipentAddress = vendorData?.stripe_account_metadata;
    const isBothAddressAreTheSame = isBillingAndRecipentAddressAreTheSame(
      addressObj,
      recipentAddress
    );

    if (isBothAddressAreTheSame) {
      setValue('sameAddress', true);
    } else {
      setValue('sameAddress', false);
    }
  };

  useEffect(() => {
    if (vendorData) {
      dtoToRecipentFormValues(setValue, vendorData);
      setValue('name', vendorData?.stripe_account_metadata?.check_recipient_details_name ?? '');
      handleAddressIntheIntialLoad();
    }
  }, [vendorData]);

  const onChangeSameAddressEvent = (event: any) => {
    const targetChecked = event.target.checked;
    let addressObj;

    if (vendorData) {
      if (vendorData?.business_type === 'individual') {
        addressObj = vendorData?.individual?.address;
      } else {
        addressObj = vendorData?.company?.address;
      }
    }

    if (targetChecked && addressObj) {
      setValue('sameAddress', true);
      setValue('city', addressObj.city);
      setValue('address', addressObj.line1);
      setValue('line2', addressObj.line2);
      setValue('state', addressObj.state);
      setValue('zip', addressObj.postal_code);
    } else {
      setValue('sameAddress', false);
      setValue('city', '');
      setValue('address', '');
      setValue('line2', '');
      setValue('state', '');
      setValue('zip', '');
    }
  };

  return (
    <>
      {updateLoading && <LoadingSpinner />}
      <Modal
        isOpen={true}
        onClose={onModalClose}
        modalAdditionalClasses="!w-[392px] flex flex-col bg-white justify-center px-2 py-6"
      >
        <div className="absolute cursor-pointer top-5 right-5 text-grey-600" onClick={onModalClose}>
          <Icon iconColor="inherit" iconType="CloseIcon" />
        </div>
        <div className="flex flex-col w-full px-4">
          <h2 className="mt-0 mb-3 font-medium text-18s text-primary-500">Check Recipient Info</h2>
          <p className="mt-0 mb-6 font-light text-14s text-primary-500">
            Please confirm the following details to get the check to the right individual
          </p>
          {internalErrorMessage !== '' && (
            <div className="flex flex-col w-full">
              <Message type="error">{internalErrorMessage}</Message>
            </div>
          )}
          <div className="flex flex-col w-full">
            <form onSubmit={formSubmit} className="flex flex-col w-full">
              <Controller
                control={control}
                name="name"
                render={({ field: { value } }) => (
                  <Input
                    id="name"
                    labelName={'name'}
                    type="text"
                    label="Recipient Name"
                    {...register('name')}
                    error={errors?.name?.message}
                    value={value}
                    highlightText={'This can be an individual or organization.'}
                  />
                )}
              />

              <Controller
                control={control}
                name={'sameAddress'}
                render={({ field: { value } }) => (
                  <Checkbox
                    register={register}
                    name={'sameAddress'}
                    id={'sameAddress'}
                    label={`Same as ${
                      vendorData && vendorData?.business_type === 'individual'
                        ? 'home'
                        : vendorData?.business_type === 'company'
                        ? 'business'
                        : ''
                    } address`}
                    onChange={onChangeSameAddressEvent}
                  />
                )}
              />
              <Controller
                control={control}
                name="address"
                render={({ field: { value } }) => (
                  <Input
                    id="address"
                    labelName="address"
                    label={'Street'}
                    type="text"
                    {...register('address')}
                    error={errors?.address?.message}
                    value={value}
                  />
                )}
              />

              <Controller
                control={control}
                name="line2"
                render={({ field: { value } }) => (
                  <Input
                    id="line2"
                    type="text"
                    placeholder="Line 2"
                    {...register('line2')}
                    value={value}
                  />
                )}
              />

              <Grid columnCount={3} columnGap={2}>
                <Controller
                  control={control}
                  name="city"
                  render={({ field: { value } }) => (
                    <Input
                      id="city"
                      type="text"
                      label={'City'}
                      labelName="City"
                      {...register('city')}
                      error={errors?.city?.message}
                      value={value}
                    />
                  )}
                />

                <StateDropDown register={register} />

                <Controller
                  control={control}
                  name="zip"
                  render={({ field: { value } }) => (
                    <Input
                      id="zip"
                      labelName={'zip'}
                      type="text"
                      label="Postal Code"
                      {...register('zip')}
                      error={errors?.zip?.message}
                      value={value}
                    />
                  )}
                />
              </Grid>
              <AddressPOboxNote isTopContentVisile />
              <div className="flex w-full pt-4">
                <Button
                  type="submit"
                  buttonLabel={`Save & Continue`}
                  iconType="Spin"
                  isIconEnabled={false}
                  additionalClasses={'w-full'}
                  // isDisabled={}
                  // onClick={() => {
                  //   setDetailSubmitted(true);
                  // }}
                />
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
};
