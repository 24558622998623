export const helperTransactions = () => {
  // used to GetInvoice amount
  const getTransferAmount = (
    selectedRows: any,
    setTlAmount: any,
    setSelectedIds: any,
    isModalTransferGrid = false
  ) => {
    const totalAmount = selectedRows.reduce((previousVal: any, currentVal: any) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const crntVal = parseFloat(currentVal?.original?.amount);

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const preVal = parseFloat(previousVal);
      return (preVal + crntVal).toFixed(2);
    }, 0);
    setTlAmount(totalAmount);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const selecIds = selectedRows.map((item) =>
      !isModalTransferGrid ? item.original?.id : item.original?.transfer?.id
    );
    setSelectedIds(selecIds);
  };

  return [getTransferAmount];
};
