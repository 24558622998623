import React from 'react';
import Navigation from '../Navigation/Navigation';
import { Logo } from 'components/core';
import { OrganizationDropDown } from '../OrganizationDropDown/OrganizationDropDown';

import { isPermitted, useGetUserQuery } from '../../../services/userApi';

/**
 * Primary UI component for user interaction
 */
const SideDrawyer = () => {
  const { data: user } = useGetUserQuery({});
  return (
    <div
      data-testid="drawer"
      className="fixed z-20 flex flex-col items-center h-screen bg-primary-500 w-[92px]"
    >
      <Logo data_testid="logo" />
      {isPermitted(user, 'switch-workspace') && <OrganizationDropDown />}
      <Navigation data_testid="navigation" />
    </div>
  );
};

export default SideDrawyer;
