export const stringOperatorTypes = [{ name: 'contains', label: 'contains' }];

export const valueOperatorTypes = [
  { name: '=', label: 'equalTo' },
  { name: '!=', label: 'not equal' },
];

export const multiSelectOperatorTypes = [
  { name: 'in', label: 'in' },
  { name: 'notIn', label: 'not in' },
];

export const handleAPIData = (data: any) => {
  let propertiesDataValues: any[];
  let vendorsDataValues: any[];

  propertiesDataValues =
    data &&
    data.map((item: any) => {
      return item['property.name'];
    });

  // eslint-disable-next-line array-callback-return
  propertiesDataValues = propertiesDataValues.filter(function (item, pos) {
    if (item !== null) {
      return propertiesDataValues.indexOf(item) === pos;
    }
  });

  vendorsDataValues =
    data &&
    data.map((item: any) => {
      return item?.VENDOR;
    });

  // eslint-disable-next-line array-callback-return
  vendorsDataValues = vendorsDataValues.filter(function (item, pos) {
    if (item !== null) {
      return vendorsDataValues.indexOf(item) === pos;
    }
  });

  return [propertiesDataValues, vendorsDataValues];
};

export const handlePropertyCustomRuleArray = (operator: string, finalValue: any) => {
  let array: any = {
    id: {
      in: finalValue,
    },
  };

  if (operator === 'notIn') {
    array = {
      id: {
        notIn: finalValue,
      },
    };
  }

  return array;
};

export const commonHandlerCustomRuleArray = (operator: string, finalValue: any) => {
  let array: any = {
    in: finalValue,
  };

  if (operator === 'notIn') {
    array = {
      notIn: finalValue,
    };
  }

  return array;
};

export interface ColourOption {
  readonly value: string;
  readonly label: string;
  readonly color: string;
  readonly isFixed?: boolean;
  readonly isDisabled?: boolean;
}

export const setAvailablePayModeOptions: readonly ColourOption[] = [
  {
    value: 'ACH',
    label: 'ACH',
    color: '#00B8D9',
    isFixed: true,
  },
  {
    value: 'CHECK',
    label: 'CHECK',
    color: '#0052CC',
  },
  {
    value: 'DEBIT_CARD',
    label: 'DEBIT CARD',
    color: '#FF8B00',
  },
  {
    value: 'EXTERNAL',
    label: 'EXTERNAL',
    color: '#36B37E',
  },
];

export const handleListItemsArray = (selectedValues: any, isInnerString: boolean) => {
  const listOfSelectedItemsArray: any = [];
  selectedValues &&
    selectedValues.length > 0 &&
    selectedValues.map((item: { value: string; label: string }) =>
      listOfSelectedItemsArray.push(isInnerString ? '"' + item.value + '"' : item.value)
    );

  return [listOfSelectedItemsArray];
};

export const setAvailableStatusOptions: readonly ColourOption[] = [
  {
    value: 'INVOICED',
    label: 'Submitted',
    color: '#E49900',
    isFixed: true,
  },
  {
    value: 'APPROVED',
    label: 'Approved',
    color: '#469721',
  },
  {
    value: 'PAID',
    label: 'Paid',
    color: '#1E188F',
  },
  {
    value: 'DENIED',
    label: 'Void',
    color: '#A4233F',
  },
  {
    value: 'REVERSED',
    label: 'Reversed',
    color: '#DF6881',
  },
];

export const setAvailableCheckStatusOptions: readonly ColourOption[] = [
  {
    value: 'UNPAID',
    label: 'Unpaid',
    color: '#E49900',
  },
  {
    value: 'IN_PROCESS',
    label: 'Check Issued',
    color: '#469721',
  },
  {
    value: 'VOID',
    label: 'Check Voided',
    color: '#1E188F',
  },
  {
    value: 'EXPIRED',
    label: 'Check Expired',
    color: '#A4233F',
  },
  {
    value: 'PRINTED',
    label: 'Check Printed',
    color: '#DF6881',
  },
  {
    value: 'MAILED',
    label: 'Check In Transit',
    color: '#1E188F',
  },
  {
    value: 'FAILED',
    label: 'Check Failed',
    color: '#A4233F',
  },
  {
    value: 'REFUNDED',
    label: 'Check Refunded',
    color: '#DF6881',
  },
  {
    value: 'CHECK_PAID',
    label: 'Check Cashed',
    color: '#A4233F',
  },
];
