import { createApi } from '@reduxjs/toolkit/query/react';
import { prepareBaseQuery } from '../shared/configs/config';

export const analyticsApi = createApi({
  reducerPath: 'analyticsApi',
  baseQuery: prepareBaseQuery(),
  endpoints: (builder) => ({
    getAllPaymentTerms: builder.query({
      query: () => ({
        url: `query/payment-terms`,
        method: 'POST',
      }),
    }),
    getPaymentSummary: builder.query({
      query: (filterObj: any) => ({
        url: `analytics/payment-summary`,
        method: 'POST',
        body: {
          limit: 1000,
          offset: 0,
          filter: filterObj,
        },
      }),
    }),
    getFinancialSummary: builder.query({
      query: (filterObj: any) => ({
        url: `analytics/financial-summary`,
        method: 'POST',
        body: {
          limit: 1000,
          offset: 0,
          filter: filterObj,
        },
      }),
    }),
  }),
});

export const {
  useGetAllPaymentTermsQuery,
  useGetPaymentSummaryQuery,
  useGetFinancialSummaryQuery,
} = analyticsApi;
