import React from 'react';
import { useForm } from 'react-hook-form';

import TabButton from '../../../../core/TabButton/TabButton';
import HookFormsInput from '../../../../core/OldComponents/FormElements/Input/HookFormsInput';
import Card from '../../../../core/OldComponents/Card/Card';
import { useInviteUserMutation } from '../../../../../services/userApi';
import { Button } from 'components/core';

export const InviteUser = () => {
  const { register, handleSubmit } = useForm();
  const [inviteUser] = useInviteUserMutation();
  let messageAlert = '';
  const validateEmail = (email: string) => {
    return String(email)
      .toLowerCase()
      .match(
        // eslint-disable-next-line max-len
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const appSubmit = async (data: any) => {
    if (!data?.inviteUsers || data?.inviteUsers === '' || !validateEmail(data?.inviteUsers)) {
      messageAlert = 'Please input valid email id';
    }
    const response: any = await inviteUser({ role: data?.userType, email: data?.inviteUsers });
    if (response && messageAlert === '') {
      let updateMessage = {
        message: 'Invited User Successfully.',
        messageType: 'success',
        visible: false,
      };
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (response?.error) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const newMessage = response?.error?.data?.error ?? response?.error?.data?.message;
        updateMessage = {
          ...updateMessage,
          message: newMessage,
          messageType: 'error',
        };
      }
      messageAlert = updateMessage.message;
    }
    alert(messageAlert ?? 'Error Sending Invitation');
    window.location.reload();
  };

  return (
    <Card cardType="whiteBorder" additionalClasses="overflow-hidden flex-col px-4 pt-2">
      <div className="flex w-full mb-3">
        <TabButton label={'Invite by email'} active={true} />
      </div>
      <form className="flex w-full" onSubmit={handleSubmit(appSubmit)}>
        <HookFormsInput
          id="inviteUsers"
          labelClasses="w-[85px]"
          placeholder={'Invite more workspace collaborators via email'}
          type="email"
          wrapperClass="flex w-full flex-row relative items-center mb-8"
          additionalClasses={`text-14s w-[334px] h-8 rounded-sm`}
          {...register('inviteUsers')}
        />
        <select
          id="userType"
          defaultValue={'Select Type'}
          {...register('userType')}
          className="form-select h-8 border text-13s text-black-760 appearence-none placeholderColor-gray shadow-none bg-gray-110 font-bold border-none mx-6 rounded-md min-w-[120px]"
        >
          <option value="admin">admin</option>
          <option value="view-only">view-only</option>
          <option value="super-admin">super-admin</option>
        </select>
        <Button
          buttonLabel={'Send Invite'}
          type="submit"
          variants="extraSmall"
          isIconEnabled={false}
          additionalClasses="h-8 !text-14s min-w-[115px]"
        />
      </form>
    </Card>
  );
};
