import { useParams } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import Wrapper from '../../../layouts/Wrapper/Wrapper';
import Headers from '../../../core/Headers/Headers';
import { HeaderTabNavigation } from 'components/core';
import Payment from './Payment/Payment';

import { PropertyDetailsProfileContainer } from './Profile/Block';

import { useGetPropertiesQuery } from 'services/properties';
import { handlePropertiesDetailTabNavData } from 'constants/TabNavData';
import { CONFIG } from 'shared/configs/config';
import { useGetConfigQuery } from 'services/configApi';
import { isPermitted, useGetUserQuery } from 'services/userApi';

export const PropertyDetailsContainer = () => {
  const { id } = useParams();
  const [detailTabNavData] = handlePropertiesDetailTabNavData(id);

  const filterParams = {
    limit: CONFIG.DATA_OVERALL_LIMIT,
    offset: 0,
    filter: {
      id: {
        equalTo: '"' + id + '"',
      },
    },
  };

  const configResponse = useGetConfigQuery('');
  const { data: user } = useGetUserQuery({});
  const { data, isSuccess } = useGetPropertiesQuery(filterParams);

  return (
    <Wrapper additionalClasses="flex-col" pageTitle={'Properties Details'} isBackButtonVisible>
      {isSuccess && (
        <>
          <Headers
            headingContent={data.properties.nodes[0].name}
            buttonLabel={'Delete Property'}
            isButtonVisibled={false}
            isIconEnabled={true}
            variants={'extraSmallOutlined'}
            additionalClasses={'border-none'}
            iconType="TrashIcon"
            iconColor="inherit"
            headerWrapperClasses="border-none"
          />

          <HeaderTabNavigation tabNavData={detailTabNavData} />

          <PropertyDetailsProfileContainer data={data.properties.nodes[0]} user={user} />

          {configResponse?.data?.stripe_api_public_key && (
            <Elements stripe={loadStripe(configResponse?.data?.stripe_api_public_key)}>
              <Payment isPaymentEditAccess={isPermitted(user, 'payment-tab')} />
            </Elements>
          )}
        </>
      )}
    </Wrapper>
  );
};
