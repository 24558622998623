import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Error404Container from '../../../components/modules/Error/Error404/Error404';

/*
 * Primary UI component for user interaction
 */
const Error404 = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate('/404error');
  }, []);
  return <Error404Container />;
};

export default Error404;
