import { Classes } from '../../../shared/configs/componentsClasses';

export const handleClasses = (_messageType?: string) => {
  switch (_messageType) {
    case 'warning':
      return Classes.message.warning;
    case 'success':
      return Classes.message.success;
    case 'error':
      return Classes.message.error;
    case 'neutral':
      return Classes.message.neutral;
    case 'highlight':
      return Classes.message.highlight;
    case 'headsUp':
      return Classes.message.headsUp;
    default:
      break;
  }
};

export const handleIcons = (_messageType?: string) => {
  switch (_messageType) {
    case 'warning':
      return 'WarningIcon';
    case 'success':
      return 'SuccessIcon';
    case 'error':
      return 'WarningIcon';
    case 'neutral':
      return 'NeturalIcon';
    case 'headsUp':
      return 'InfoLinedIcon';
    default:
      break;
  }
};
