import { createApi } from '@reduxjs/toolkit/query/react';
import { prepareBaseQuery } from '../shared/configs/config';

export const resmanPayablesApi = createApi({
  reducerPath: 'resmanPayablesApi',
  tagTypes: ['GroupedBatches', 'ACH'],
  baseQuery: prepareBaseQuery(),
  endpoints: (builder) => ({
    // Update Vendor Term
    getGroupedBatches: builder.query({
      query: (obj: any) => ({
        url: `/query/resman-payables`,
        body: obj,
        method: 'POST',
      }),
      providesTags: ['GroupedBatches'],
    }),
    reconcileCheckBatch: builder.mutation({
      query: (obj: any) => ({
        url: `/payment-detail`,
        body: obj,
        method: 'POST',
      }),
      invalidatesTags: ['GroupedBatches', 'ACH'],
    }),
    getReconciliationData: builder.query({
      query: (key) => ({
        url: `/reconciliation-data`,
        method: 'GET',
      }),
      providesTags: ['ACH'],
    }),
  }),
});
export const {
  useGetGroupedBatchesQuery,
  useReconcileCheckBatchMutation,
  useGetReconciliationDataQuery,
} = resmanPayablesApi;
