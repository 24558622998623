import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import {
  LoadingSpinner,
  VendorPropertiesStatsBar,
  DataList,
  HeaderTabNavigation,
} from '../../../../core';
import ListEmpty from '../../../Common/ListEmpty/ListEmpty';

import { CONFIG } from 'shared/configs/config';

import { useGetPropertiesQuery } from 'services/properties';
import { useGetVendorsQuery } from '../../../../../services/vendorsApi';
import { serverSidePaginationActive, useServerSidePagination } from 'hooks/useServerSidePagination';
import { handleVendorsInnerTabNavData } from 'constants/TabNavData';
import { useGetPaymentMethodsQuery } from 'services/payoutsApi';
import { useShowOptionHandler } from 'hooks/useShowOptionHandler';
import { helperPropertiesColumOrder } from 'helpers/modules/helperProperties';

export const VendorProperties = () => {
  const { id } = useParams();
  const [pageSizeOptions, paginationState, previousButtonClick, nextButtonClick, setPageSize]: any =
    useServerSidePagination();
  const [tableColumns, handleIconClick] = helperPropertiesColumOrder('innerVendorProperties');
  const [vendorsInnerTabNavData] = handleVendorsInnerTabNavData(id);
  const [propertyParams, setProperttyParams]: any = useState([]);
  const [visibleColumns, setVisibleColumns] = useState([]);
  const [filteredRow, setFilteredRow] = useState([]);

  const vendorFilterParam = {
    limit: CONFIG.DATA_OVERALL_LIMIT,
    offset: 0,
    filter: {
      id: {
        equalTo: JSON.stringify(id),
      },
    },
  };

  const { data } = useGetVendorsQuery(vendorFilterParam);
  useEffect(() => {
    if (data) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const propIds = data?.accounts?.nodes[0].property_ids;

      setProperttyParams({
        limit: serverSidePaginationActive ? paginationState.pageSize : CONFIG.DATA_OVERALL_LIMIT,
        offset: serverSidePaginationActive
          ? paginationState.pageIndex * paginationState.pageSize
          : 0,
        orderBy: 'NAME_ASC',
        filter: {
          id: {
            in: propIds?.map((item: any) => JSON.stringify(item)),
          },
          payoutsByPropertyId: {
            account_id: {
              in: [JSON.stringify(id)],
            },
          },
        },
      });
    }
  }, [data, paginationState.pageSize, paginationState.pageIndex]);

  const {
    data: propertyData,
    isSuccess: isPropertyDataLoadSuccess,
    isLoading: isPropertyDataLoading,
  } = useGetPropertiesQuery(propertyParams);
  const totalCount = isPropertyDataLoadSuccess ? propertyData?.properties?.totalCount : 0;
  const propertyDataNodes: any = isPropertyDataLoadSuccess ? propertyData?.properties?.nodes : [];

  const totalRecords: number = propertyData?.properties?.totalCount ?? 0;
  const totalPageCount = Math.ceil(totalRecords / paginationState.pageSize);

  const {
    data: paymentMethodsData,
    isSuccess: isPaymentMethodsDataSuccess,
    isError: isPaymentMethodsDataError,
  } = useGetPaymentMethodsQuery('');

  const [
    hiddenColumns,
    toggleHiddenColumnChanged,
    setToggleHiddenColumnChanged,
    columnList,
    setColumnList,
    propertyNodes,
  ] = useShowOptionHandler({
    tableColumns: tableColumns,
    data: propertyDataNodes,
    additionalColumns: [],
    storageItemName: 'showOptionInnerPropertiesList',
  });

  return (
    <>
      {isPropertyDataLoading && <LoadingSpinner />}
      <HeaderTabNavigation
        tabNavData={vendorsInnerTabNavData}
        additionalWrapperClasses={'mb-3'}
        visibleColumns={visibleColumns}
        filteredRow={filteredRow}
        isDownloadCsvButtonEnabled={isPropertyDataLoadSuccess && totalCount !== 0}
        isShowOptionEnabled={propertyDataNodes && propertyDataNodes.length > 0}
        columns={columnList}
        handleIconClick={(item: any) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          handleIconClick(
            item,
            columnList,
            setColumnList,
            setToggleHiddenColumnChanged,
            toggleHiddenColumnChanged
          );
        }}
      />

      {isPropertyDataLoadSuccess &&
      (isPaymentMethodsDataSuccess || isPaymentMethodsDataError) &&
      propertyNodes &&
      propertyNodes.length > 0 ? (
        <>
          <VendorPropertiesStatsBar data={propertyData} />

          <DataList
            data={propertyNodes}
            isPagination={true}
            tableType="properties"
            subTabType="innerVendorProperties"
            tableWrapperType="grid-height-extraSmall"
            isServerSidePagination={serverSidePaginationActive}
            previousPageClick={previousButtonClick}
            nextPageClick={nextButtonClick}
            setPageSizeValue={setPageSize}
            options={pageSizeOptions}
            totalPageCount={totalPageCount}
            pageSizeVal={paginationState.pageSize}
            pageIndexVal={paginationState.pageIndex}
            setVisibleColumns={setVisibleColumns}
            setFilteredRow={setFilteredRow}
            paymentMethodData={
              isPaymentMethodsDataSuccess ? paymentMethodsData : isPaymentMethodsDataError ? [] : []
            }
            hiddenColumnList={hiddenColumns}
            columnOrder={[
              'AMOUNT PAID FOR VENDORS',
              'AMOUNT OWED BY PROPERTY',
              'payouts_by_property_id',
              'fee',
              'payment_term_id',
            ]}
            toggleHiddenColumnChanged={toggleHiddenColumnChanged}
          />
        </>
      ) : (
        <ListEmpty subText={'There are no properties for the vendor.'} />
      )}
    </>
  );
};
