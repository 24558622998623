import React from 'react';
import { useForm } from 'react-hook-form';

import Label from '../../../../../core/OldComponents/FormElements/Label/Label';
import { Classes } from '../../../../../../shared/configs/componentsClasses';
import { NeMethodProps } from 'shared/models/components/base.model';
import { Button } from 'components/core';

const NeMethod: React.FC<NeMethodProps> = ({
  isVisible,
  onSubmitNewPaymentMethod,
  backButtonEvent,
  isIconLoading,
}) => {
  const { register, handleSubmit } = useForm();
  const inputDefaultClasses = Classes.input.default;

  return (
    <>
      {isVisible && (
        <form onSubmit={handleSubmit(onSubmitNewPaymentMethod)} className={'w-full flex flex-col'}>
          <div className="pl-2 pr-2">
            <div className={'mb-8 flex w-full flex-col'}>
              <Label
                name={'account_holder_name'}
                labelType={'blackSmall'}
                label="Name on Account"
                additionalClasses={'mb-2'}
              />
              <input
                type="text"
                {...register('account_holder_name')}
                className={inputDefaultClasses}
                placeholder={'GetDone'}
                required
              />
            </div>
            <div className={'mb-8 flex w-full flex-col'}>
              <Label
                name={'routing_number'}
                labelType={'blackSmall'}
                label="Routing Number"
                additionalClasses={'mb-2'}
              />
              <input
                type="text"
                {...register('routing_number')}
                className={inputDefaultClasses}
                placeholder={'12345678910'}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                required
              />
            </div>
            <div className={'mb-8 flex w-full flex-col'}>
              <Label
                name={'account_number'}
                labelType={'blackSmall'}
                label="Account Number"
                additionalClasses={'mb-2'}
              />
              <input
                type="text"
                {...register('account_number')}
                className={inputDefaultClasses}
                placeholder={'00000000'}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                required
              />
            </div>
            <div className={'mb-8 flex w-full flex-col'}>
              <Label
                name={'account_number'}
                labelType={'blackSmall'}
                label="Account Holder Type"
                additionalClasses={'mb-2'}
              />
              <select
                {...register('account_holder_type')}
                required
                className={Classes.select.default}
              >
                <option value="individual">Individual</option>
                <option value="company">Company</option>
              </select>
            </div>
          </div>
          <div className="flex items-center w-full pl-2 pr-2 mb-2">
            <span
              className="inline-flex mr-8 font-medium underline cursor-pointer text-black-900 text-16s"
              onClick={backButtonEvent}
            >
              Back
            </span>
            <Button
              variants="default"
              type="submit"
              buttonLabel={`Save`}
              iconType="Spin"
              iconClass="inline-flex ml-2 w-6 h-6"
              isIconEnabled={isIconLoading}
              iconAlignment={'right'}
              additionalClasses={'w-24'}
            />
          </div>
        </form>
      )}
    </>
  );
};

export default NeMethod;
