import classNames from 'classnames';
import moment from 'moment-timezone';
import { useNavigate } from 'react-router-dom';

import AccordionItem from 'components/core/AccordionItem/AccordionItem';

import { handleBalanceAccordionClick } from 'helpers/modules/helperBalance';
import { useCurrencyFormat } from 'hooks/useCurrencyFormat';
import { DailyPayoutsProps } from 'shared/models/components/base.model';
import { CONFIG } from 'shared/configs/config';

import { useGetPendingBalanceApiQuery } from 'services/pastBalanceApi';

export const DailyPayouts = ({
  weekStartDate,
  weekEndDate,
  timeFrame = 'past',
}: DailyPayoutsProps) => {
  const navigate = useNavigate();

  const invoiceParams = {
    first: CONFIG.DATA_OVERALL_LIMIT,
    offset: 0,
    filter: {
      status: { in: ['INVOICED', 'APPROVED', 'PAID'] },
      customer_payment_due_at: {
        lessThan: moment(weekEndDate)
          .tz('America/Chicago')
          .set({ hour: 23, minute: 59, second: 59, millisecond: 999 })
          .format(),
        greaterThan: moment(weekStartDate)
          .tz('America/Chicago')
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
          .format(),
      },
    },
    groupBy: 'CUSTOMER_PAYMENT_DUE_AT_TRUNCATED_TO_DAY_CST',
  };

  const { data, isSuccess, isLoading }: any = useGetPendingBalanceApiQuery(invoiceParams);

  return (
    <div className="flex flex-col w-full">
      {isLoading && (
        <div className="flex flex-row items-center justify-between w-full h-10 pl-4 pr-4 mb-0">
          <div className="inline-flex w-[100px] rounded h-3 bg-grey-100 animate-pulse" />
          <div className="inline-flex w-[80px] rounded h-3 bg-grey-100 animate-pulse" />
        </div>
      )}
      {isSuccess &&
        data?.payouts?.groupedAggregates.map((listItem: any) => {
          const convertedPrice = parseFloat(listItem?.sum?.amount);

          const isPayoutDisabled =
            timeFrame === 'current' &&
            moment(listItem.keys[0]).tz('America/Chicago').isAfter(moment().tz('America/Chicago'));

          return (
            <div className={classNames(['flex flex-col w-full mb-0'])} key={listItem.keys[0]}>
              <AccordionItem
                mainLabel={moment(listItem.keys[0]).tz('America/Chicago').format('MM/DD')}
                rightColContent={useCurrencyFormat(convertedPrice)}
                isInvoiceTag={false}
                // urlPath={`/balance/invoice/week/${timeFrame}${differenceInDays.toFixed()}`}
                isRemoveTopBorder={data?.payouts?.groupedAggregates.length === 1}
                clickAccordion={() =>
                  handleBalanceAccordionClick(listItem.keys[0], navigate, true, timeFrame)
                }
                isLink={false}
                isDisabled={isPayoutDisabled}
              />
            </div>
          );
        })}
    </div>
  );
};
