import { useState } from 'react';
import classNames from 'classnames';

import Modal from '../Modal/Modal';
import { FileHandlerButtons } from '../../FileHandlerButtons/FileHandlerButtons';
import Icon from '../../Media/Icon/Icon';
import { EmptyCellContent } from 'components/core/SuperTable/CellElements/EmptyCellContent/EmptyCellContent';
import { isPermitted } from 'services/userApi';

export interface CheckImageUrlModalProps {
  imageURL?: string;
  checkNumer?: string;
  user: any;
}

export const CheckImageUrlModal = ({ imageURL, checkNumer, user }: CheckImageUrlModalProps) => {
  const [checkImageURLModalOpen, setCheckImageURLModalOpen] = useState(false);
  const commonClasses = 'font-normal capitalize text-14s text-grey-900';

  const isVisibleForUsers = isPermitted(user, 'view-check-image-url');

  return (
    <>
      <div className="flex w-auto">
        {checkNumer ? (
          imageURL && isVisibleForUsers ? (
            <span
              className={`${commonClasses} underline cursor-pointer`}
              onClick={() => setCheckImageURLModalOpen(true)}
            >
              {checkNumer}
            </span>
          ) : (
            <span className={commonClasses}>{checkNumer}</span>
          )
        ) : (
          <EmptyCellContent />
        )}
      </div>
      {checkImageURLModalOpen && (
        <Modal
          isOpen
          onClose={() => setCheckImageURLModalOpen(false)}
          modalAdditionalClasses={classNames([
            '!w-full max-w-[960px] min-h-[376px] overflow-x-hidden pt-10 pb-9 rounded-lg bg-white flex-col justify-center',
          ])}
        >
          <div className="w-auto top-[12px] right-[32px] absolute">
            <FileHandlerButtons isPrintLinkVisible={false} isDownloadLink printURL={imageURL} />
          </div>
          <div
            className="absolute cursor-pointer top-[16px] right-[32px] text-grey-600 "
            onClick={() => setCheckImageURLModalOpen(false)}
          >
            <Icon iconColor="inherit" iconType="CloseIcon" />
          </div>
          <div className="w-full h-auto px-8">
            <img src={imageURL} alt={checkNumer} className="block w-full h-auto" />
          </div>
        </Modal>
      )}
    </>
  );
};
