import React from 'react';
import stateData from '../../../../constants/state.json';

export interface StateDropDownParams {
  register?: any;
  uniqueID?: string;
}

export const StateDropDown = ({ register, uniqueID = 'state' }: StateDropDownParams) => {
  return (
    <div className="relative flex flex-col w-full mb-4 form-input-wrapper focused">
      <select
        {...register(uniqueID)}
        required
        id={uniqueID}
        className="form-select outline-none h-[42px] border border-solid border-grey-100 rounded-lg text-black placeholderColor-gray  text-14s text-grey-900 px-4 focus:!border-grey-100 focus:!shadow-none"
        size={1}
      >
        {stateData &&
          stateData.map((item, index) => (
            <option key={index} value={item.name}>
              {item.name}
            </option>
          ))}
      </select>
      <label
        htmlFor={uniqueID}
        className={'text-14s font-normal text-grey-600 top-3 left-4 absolute'}
      >
        {'State'}
      </label>
    </div>
  );
};
