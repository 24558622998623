export interface AuthResponse {
  name?: string;
  email?: string;
  phone_number?: string;
  phone_verified?: boolean;
  request_language?: string;
  _id?: string;
  id_token?: string;
}

export interface AuthParams {
  phone_number?: string;
  code?: string;
  response?: any;
}

export const InitAuthResponse = {
  name: '',
  email: '',
  phone_number: '',
  phone_verified: false,
  request_language: '',
  _id: '',
  id_token: '',
};

export interface VerifyRequest {
  phone: string;
}

export const InitVerifyRequest = {
  phone: '',
};
