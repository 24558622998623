import { useParams } from 'react-router-dom';

import Wrapper from '../../../layouts/Wrapper/Wrapper';
import Headers from '../../../core/Headers/Headers';

import { PropertyDetailsVendorsContainer } from './Vendors/List';

import { useGetPropertiesQuery } from 'services/properties';
import { CONFIG } from 'shared/configs/config';

export const PropertyVendorsContainer = () => {
  const { id } = useParams();

  const filterParams = {
    limit: CONFIG.DATA_OVERALL_LIMIT,
    offset: 0,
    filter: {
      id: {
        equalTo: '"' + id + '"',
      },
    },
  };

  const { data, isSuccess } = useGetPropertiesQuery(filterParams);

  return (
    <Wrapper additionalClasses="flex-col" pageTitle={'Properties Details'} isBackButtonVisible>
      {isSuccess && (
        <>
          <Headers
            headingContent={data.properties.nodes[0].name}
            buttonLabel={'Delete Property'}
            isButtonVisibled={false}
            isIconEnabled={true}
            variants={'extraSmallOutlined'}
            additionalClasses={'border-none'}
            iconType="TrashIcon"
            iconColor="inherit"
            headerWrapperClasses="border-none"
          />

          <PropertyDetailsVendorsContainer
            data={data.properties.nodes[0].payouts_by_property_id.account_ids}
            id={id}
            propertyName={data.properties.nodes[0].name ?? ''}
          />
        </>
      )}
    </Wrapper>
  );
};
