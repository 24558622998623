import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../Button/Button';

import { HeadersProps } from 'shared/models/components/base.model';
import { Typhography } from '../Typhography/Typhography';

import { logoutApi } from '../../../services/authApi';
import { getLSKey } from '../../../shared/configs/config';
import Icon from '../Media/Icon/Icon';
import { removeLogoutLocalStorages } from 'helpers/modules/helperInvoices';

/**
 * Primary UI component for user interaction
 */
const Headers: React.FC<HeadersProps> = ({
  headingContent = 'Heading',
  tagEnabled = false,
  isSignOutButton = false,
  isButtonVisibled = false,
  additionalClasses,
  buttonLabel,
  variants = 'extraSmall',
  onClick,
  isDisabled,
  isIconEnabled = false,
  iconColor = 'inherit',
  iconType,
  headerWrapperClasses,
  isFileUpload = false,
  onChange,
  tagLabel,
  onClickFileUpload,
}) => {
  const navigate = useNavigate();
  const [doLogout] = logoutApi.useAddNewPostMutation();
  /**
   * Handle Signout functionality
   */
  const HandleSignOut = async () => {
    removeLogoutLocalStorages();
    if (window.location.hostname !== 'localhost') {
      await doLogout({}).unwrap();
    }
    return !localStorage.getItem('id_token') && navigate('/');
  };

  return (
    <div
      className={`flex w-full border-b border-grey-100 border-solid h-[56px] mb-3 justify-between items-center ${
        headerWrapperClasses ? headerWrapperClasses : ''
      }`}
    >
      <div className="flex items-center">
        <Typhography
          component="span"
          type="h6ExtraBold"
          color="text-gray-900"
          additionalClasses="mt-1"
        >
          {headingContent}
        </Typhography>
        {tagEnabled && (
          <Typhography
            component="span"
            type="h1Bold"
            color="text-primary-500"
            additionalClasses="flex w-auto ml-2 h-[22px] rounded justify-center items-center bg-additional-neutral-grey px-1 relative top-1 capitalize"
          >
            {tagLabel ? tagLabel : getLSKey('current_customer_name') ?? `Payup Inc`}
          </Typhography>
        )}
      </div>
      <div className="flex items-center">
        {isFileUpload && (
          <label
            className="flex items-center justify-center w-auto px-3 text-white border-solid rounded-lg cursor-pointer border-primary-500 bg-primary-500 h-7"
            htmlFor="uploadFile"
          >
            <Icon
              iconColor="inherit"
              iconType="UploadIcon"
              additionalClasses="mr-1 relative -top-[1px]"
            />
            <span className="leading-normal text-14s">{buttonLabel}</span>
            <input
              className="hidden w-0 h-0"
              type="file"
              id="uploadFile"
              onChange={onChange}
              onClick={onClickFileUpload}
            />
          </label>
        )}

        {isButtonVisibled && (
          <Button
            variants={variants}
            onClick={onClick}
            additionalClasses={`${additionalClasses} ml-2`}
            buttonLabel={buttonLabel}
            isDisabled={isDisabled}
            isIconEnabled={isIconEnabled}
            iconColor={iconColor}
            iconType={iconType}
          />
        )}

        {isSignOutButton && (
          <Button
            variants="extraSmall"
            onClick={HandleSignOut}
            additionalClasses="w-[114px] ml-2"
            buttonLabel="Sign Out"
          />
        )}
      </div>
    </div>
  );
};

export default Headers;
