import React from 'react';

import classNames from 'classnames';
import { Classes } from '../../../../shared/configs/componentsClasses';
import Icon from '../../Media/Icon/Icon';

interface InputValidationProps {
  /**
   * Set validation message
   */
  message?: string | undefined;
  /**
   * Optional for additional classes
   */
  additionalClasses?: string | undefined;
}

/**
 * Primary UI component for user interaction
 */
const InputValidation: React.FC<InputValidationProps> = ({
  message,
  additionalClasses,
  ...rest
}) => {
  const inputValidationClasses = [Classes.inputValidation.error, additionalClasses];
  return (
    <span role="alert" className={classNames(inputValidationClasses)} {...rest}>
      <Icon iconColor="inherit" iconType="ValidationWarningIcon" additionalClasses="mr-1" />
      {message}
    </span>
  );
};

export default InputValidation;
