import React, { useEffect, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Navigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';

import {
  Typhography,
  Button,
  Input,
  Grid,
  TermsDropdown,
  Message,
  LoadingSpinner,
  Icon,
  CustomDatePicker,
  StateDropDown,
} from 'components/core';

import { useOutsideClickHandler } from '../../../../../../hooks/useOutsideComponent';
import { validateDOB } from '../../../../../../hooks/useDOBValidation';
import { useVendorProfileState } from '../../../../../../hooks/useProfileState';
import { phoneNumberValidation } from '../../../../../../hooks/usePhoneNumberValidation';

import { initAccount } from '../../../../../../shared/models/init/account.init';
import {
  useGetAllPayModesQuery,
  useGetVendorsInfoQuery,
  useUpdateVendorStatusMutation,
} from '../../../../../../services/vendorsApi';
import { useUpdateVendorsAccountMutation } from '../../../../../../services/vendorsApi';
import { useGetPayoutTermInfoMutation } from '../../../../../../services/vendorsApi';

import dtoToFormValues from '../../../../../../shared/utilities/dto';
import { AccountParams } from '../../../../../../shared/utilities/interface';
import { ERROR_MESSAGES } from '../../../../../../constants/Validation';

import {
  cancelAction,
  isNullDOB,
  onDOBChanged,
  onPhoneNumberChangeHandler,
  showEditForm,
} from '../../../../../../helpers/profileHelper';
import { VendorProfileSchemaObj } from '../../../../../../helpers/validationSchemas';
import { VendorProfileProps } from 'shared/models/components/base.model';
import { vendorsStatusDropDownData } from 'constants/statusDropDownData';
import { handleVendorStatus } from 'helpers/modules/helperVendor';
import { isTermEditable } from 'shared/configs/config';

const VendorProfileForm: React.FC<VendorProfileProps> = ({ enableEdit = false, editVendor }) => {
  const { id } = useParams();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { data, isLoadingVendor, isErrorVender, isSuccess } = useGetVendorsInfoQuery(id);
  const [useGetPayouts, netTermData] = useGetPayoutTermInfoMutation();
  const [updateVendorsStatus]: any = useUpdateVendorStatusMutation();
  const { data: payModes } = useGetAllPayModesQuery(null);

  const payoutTerms = netTermData?.data?.terms;
  const [
    updateVendorsAccount,
    {
      isError: updateError,
      isSuccess: updateSuccess,
      error: updateErrorMessage,
      isLoading: updateLoading,
    },
  ]: any = useUpdateVendorsAccountMutation();

  const intitPayoutTerms = async () => {
    await useGetPayouts({ amount: '0', payoutId: id });
  };

  useEffect(() => {
    intitPayoutTerms();
  }, []);

  /**
   * get all useStates form separate hook
   * @return allUseStates
   */
  const [
    enableReadonlyForm,
    setEnableReadonlyForm,
    selectedDate,
    setSelectedDate,
    dobError,
    setDOBError,
    isChecked,
    setIsChecked,
    currentNetTerm,
    selectedNetTerm,
    setSelectedNetTerm,
    newEarning,
    errorMessage,
    isError,
    setShowList,
    setShowDate,
    originalData,
    setOriginalData,
    detailSubmitted,
    setDetailSubmitted,
  ] = useVendorProfileState();

  /**
   * expand the default validation schema for phone and dob custom validations
   */
  const profileSchema = yup
    .object({
      ...VendorProfileSchemaObj,
      phone_number: yup
        .string()
        .required(ERROR_MESSAGES.REQUIRED)
        .test('len', ERROR_MESSAGES.PHONE_NUMBER_INVALID, (value) =>
          phoneNumberValidation(value, detailSubmitted)
        ),
      dob: yup
        .string()
        .typeError(ERROR_MESSAGES.REQUIRED)
        .required(ERROR_MESSAGES.REQUIRED)
        .when({
          is: () => !validateDOB(getValues('dob'), setDOBError),
          then: yup.string().typeError(ERROR_MESSAGES.DATE_INVALID),
        }),
      default_pay_mode: yup
        .string()
        .typeError(ERROR_MESSAGES.REQUIRED)
        .required(ERROR_MESSAGES.REQUIRED),
    })
    .required();

  /**
   * react hook forms & yap init setup
   */
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useForm<AccountParams>({
    resolver: yupResolver(profileSchema),
    defaultValues: initAccount,
  });

  const wrapperRef = useRef(null);
  //handle click outside on state select list
  useOutsideClickHandler(wrapperRef, setShowList);

  const handleChangeCheckbox = () => {
    setIsChecked(!isChecked);
  };

  React.useEffect(() => {
    dtoToFormValues(setValue, data);
    setValue('status', handleVendorStatus(data.status) ?? '');
    setOriginalData(data);
    setEnableReadonlyForm(false);
    if (data && !isNullDOB(data.individual.dob)) {
      //TODO needs to move to dto
      const dob = data?.individual?.dob;
      const formattedDOB: any = moment(new Date(dob.year, dob.month - 1, dob.day));
      setValue('dob', formattedDOB);
      setSelectedDate(new Date(formattedDOB));

      const fullDOB = `${data?.individual?.dob?.day !== null ? data?.individual?.dob?.day : '--'}/${
        data?.individual?.dob?.month !== null ? data?.individual?.dob?.month : '--'
      }/${data?.individual?.dob?.year !== null ? data?.individual?.dob?.year : '--'}`;
      setShowDate(fullDOB);
    }
    if (data) {
      setSelectedNetTerm(data.default_payment_term_id);
    }
  }, [data]);

  useEffect(() => {
    // init edit potion
    showEditForm(!enableEdit, setEnableReadonlyForm);
  }, [enableEdit]);

  const handleTermChange = (e: any) => {
    setValue('default_payment_term_id', e.target.value);
    setSelectedNetTerm(e.target.value);
  };

  /**
   * update basic information of account
   */
  const appSubmit = handleSubmit(async (data: any) => {
    if (moment(data?.dob).isValid()) {
      const dobFormatted = moment(data?.dob).format('MMDDYYYY');
      data.phone_number = data?.phone_number?.replace(/[^0-9]/g, '');
      data.dob = dobFormatted;

      const formData = {
        id: data?.id,
        default_pay_mode: data?.default_pay_mode,
        individual: {
          address: {
            line1: data?.line1,
            line2: data?.line2,
            city: data?.city,
            state: data?.state,
            postal_code: data?.postal_code,
          },
          dob: {
            day: data?.dob?.toString().substring(2, 4),
            month: data?.dob?.toString().substring(0, 2),
            year: data?.dob?.toString().substring(4, 8),
          },
          email: data?.email,
          first_name: data?.first_name,
          last_name: data?.last_name,
          phone: data?.phone_number,
        },
      };

      const selectedVal = data.status;
      await updateVendorsStatus({
        id: id,
        status: selectedVal === 'ACTIVE' ? 'active' : 'inactive',
      });

      const response = await updateVendorsAccount({
        obj: formData,
        isCompany: false,
        isMappingRequired: false,
      });

      if (!response?.error) {
        setTimeout(() => {
          cancelAction(setEnableReadonlyForm, editVendor, setValue, originalData);
        }, 2000);
      }
    }
  });

  const setMinimumAgeLimit = moment().subtract(18, 'years').format();

  return (
    <div className="flex flex-col w-full">
      {/**
       * Handle loader
       */}
      {(isLoadingVendor || updateLoading) && <LoadingSpinner />}

      <Typhography component="p" type="h2" color="text-grey-900 mb-6 uppercase">
        {'Edit Personal Details'}
      </Typhography>

      {(updateError || updateSuccess) && (
        <Message
          type={updateSuccess ? 'success' : 'error'}
          title="Personal Details"
          additionalClasses="mb-6"
        >
          {updateSuccess
            ? 'Your personal details are successfully updated.'
            : updateErrorMessage?.data?.message}
        </Message>
      )}

      <Message type="headsUp" additionalClasses={'mb-6'}>
        <Typhography color="text-inherit" additionalClasses="mb-0" component="p" type="h1">
          <span className="font-bold">{`Heads Up: `}</span>
          {'Inaccurate information can disrupt the vendors ability to receive payments.'}
        </Typhography>
      </Message>

      <div
        className="absolute cursor-pointer top-7 right-4 text-grey-600"
        onClick={() => cancelAction(setEnableReadonlyForm, editVendor, setValue, originalData)}
      >
        <Icon iconColor="inherit" iconType="CloseIcon" />
      </div>
      {/**
       * Error handling
       */}
      {isErrorVender && <Navigate to={'/401'} />}
      {isSuccess && (
        <div className="flex flex-col w-full">
          <form onSubmit={appSubmit} className="flex flex-col w-full">
            <div className="flex flex-col w-full">
              <Grid columnCount={2} columnGap={2}>
                <Controller
                  control={control}
                  name="first_name"
                  render={({ field: { value } }) => (
                    <Input
                      id="firstname"
                      labelName={'firstname'}
                      type="text"
                      label="First Name"
                      {...register('first_name')}
                      error={errors?.first_name?.message}
                      value={value}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="last_name"
                  render={({ field: { value } }) => (
                    <Input
                      id="lastName"
                      labelName={'lastName'}
                      type="text"
                      label="Last Name"
                      {...register('last_name')}
                      error={errors?.last_name?.message}
                      value={value}
                    />
                  )}
                />
              </Grid>

              <Controller
                control={control}
                name="email"
                render={({ field: { value } }) => (
                  <Input
                    id="email"
                    labelName={'Email'}
                    type="text"
                    label="Email"
                    {...register('email')}
                    error={errors?.email?.message}
                    value={value}
                  />
                )}
              />

              <Controller
                control={control}
                name="phone_number"
                render={({ field: { value } }) => (
                  <Input
                    id="phone"
                    labelName={'Phone'}
                    type="text"
                    readOnly={enableReadonlyForm}
                    label="Phone"
                    {...register('phone_number')}
                    error={errors?.phone_number?.message}
                    maxLength={14}
                    onChange={(event) => onPhoneNumberChangeHandler(event, setValue, getValues)}
                    value={value}
                  />
                )}
              />

              <Controller
                control={control}
                name="line1"
                render={({ field: { value } }) => (
                  <Input
                    id="line1"
                    labelName="line1"
                    label={'Street'}
                    type="text"
                    {...register('line1')}
                    error={errors?.line1?.message}
                    value={value}
                  />
                )}
              />

              <Controller
                control={control}
                name="line2"
                render={({ field: { value } }) => (
                  <Input
                    id="line2"
                    type="text"
                    placeholder="Line 2"
                    {...register('line2')}
                    value={value}
                  />
                )}
              />
              <Grid columnCount={3} columnGap={2}>
                <Controller
                  control={control}
                  name="city"
                  render={({ field: { value } }) => (
                    <Input
                      id="city"
                      type="text"
                      label={'City'}
                      labelName="City"
                      {...register('city')}
                      error={errors?.city?.message}
                      value={value}
                    />
                  )}
                />

                <StateDropDown register={register} />

                <Controller
                  control={control}
                  name="postal_code"
                  render={({ field: { value } }) => (
                    <Input
                      id="postal_code"
                      labelName={'postal_code'}
                      type="text"
                      label="Postal Code"
                      {...register('postal_code')}
                      error={errors?.postal_code?.message}
                      value={value}
                    />
                  )}
                />
              </Grid>
              <Grid columnCount={2} columnGap={2}>
                <CustomDatePicker
                  selectedDate={selectedDate}
                  setSelectedDate={(date: any) =>
                    onDOBChanged(date, setSelectedDate, setDOBError, setValue)
                  }
                  error={errors.dob?.message || dobError}
                  isMaxDate={true}
                  maxDate={new Date(setMinimumAgeLimit)}
                  label={'Date of Birth'}
                />
                <div className="relative flex flex-col w-full mb-4 form-input-wrapper focused">
                  <select
                    {...register('status')}
                    id="status"
                    className="form-select outline-none h-[42px] border border-solid border-grey-100 rounded-lg text-black placeholderColor-gray  text-14s text-grey-900 px-4 focus:!border-grey-100 focus:!shadow-none"
                    size={1}
                  >
                    {vendorsStatusDropDownData &&
                      vendorsStatusDropDownData.map((item, index) => (
                        <option key={index} value={item.statusVal}>
                          {item.status}
                        </option>
                      ))}
                  </select>
                  <label
                    htmlFor={'status'}
                    className={'text-14s font-normal text-grey-600 top-3 left-4 absolute'}
                  >
                    {'Status'}
                  </label>
                </div>
              </Grid>
              <Grid columnCount={2} columnGap={2}>
                <div className="relative flex flex-col w-full mb-4 form-input-wrapper focused">
                  <select
                    {...register('default_pay_mode')}
                    id="default_pay_mode"
                    className="form-select outline-none h-[42px] border border-solid border-grey-100 rounded-lg text-black placeholderColor-gray  text-14s text-grey-900 px-4 focus:!border-grey-100 focus:!shadow-none"
                    size={1}
                  >
                    {payModes &&
                      payModes.map((option: any) => (
                        <option key={option?.id} value={option?.id}>
                          {option.modeName}
                        </option>
                      ))}
                  </select>
                  <label
                    htmlFor={'default_pay_mode'}
                    className={'text-14s font-normal text-grey-600 top-3 left-4 absolute'}
                  >
                    {'Pay Mode'}
                  </label>
                </div>
                {data && data.code && (
                  <Controller
                    control={control}
                    name="code"
                    render={({ field: { value } }) => (
                      <Input
                        id="code"
                        labelName={'Code'}
                        type="text"
                        readOnly={true}
                        label="Code"
                        {...register('code')}
                        value={value}
                      />
                    )}
                  />
                )}
              </Grid>

              {data?.default_payment_term_id !== null &&
                netTermData.isSuccess &&
                isTermEditable && (
                  <TermsDropdown
                    netTermData={netTermData}
                    payoutTerms={payoutTerms}
                    selectedNetTerm={selectedNetTerm}
                    currentNetTerm={currentNetTerm}
                    isError={isError}
                    handleChangeCheckbox={handleChangeCheckbox}
                    onChange={handleTermChange}
                    isChecked={isChecked}
                    newEarning={newEarning}
                    errorMessage={errorMessage}
                    enableErrorMessage={false}
                    isConfirmationNoteVisible={data?.default_payment_term_id !== selectedNetTerm}
                  />
                )}
            </div>
            <div className="flex w-full pt-4">
              <Button
                type="submit"
                buttonLabel={`Update`}
                iconType="Spin"
                isIconEnabled={false}
                additionalClasses={'w-full'}
                isDisabled={data?.default_payment_term_id !== selectedNetTerm && !isChecked}
                onClick={() => {
                  setDetailSubmitted(true);
                }}
              />
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default VendorProfileForm;
