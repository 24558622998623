import SubmittedContainer from 'components/modules/Approvals/Submitted';
import React from 'react';

/**
 * Primary UI component for user interaction
 */
const Submitted = () => {
  return <SubmittedContainer />;
};

export default Submitted;
