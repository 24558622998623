import moment from 'moment';
import { useEffect } from 'react';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';

import { SelectBox, Typhography } from 'components/core';
import { SummaryInvoiceGraph } from 'components/modules/Common/GraphInvoiceActivity/GraphInvoiceActivity';
import { updateURLParams, useDateRangeSorting } from 'hooks/useDateRangeSorting';
import { pastDropdownItemsWithoutWeek } from 'constants/DateRangeDropDownDatas';

export const InvoiceSummary = () => {
  const urlParamName = 'invoiceActivityFor';

  const [
    rangeSelector,
    defaultSortingText,
    itemSlectedState,
    showDropdown,
    handlerSortingDropDownClick,
    handlerSortingOption,
  ]: any = useDateRangeSorting('Last 30 Days', pastDropdownItemsWithoutWeek, urlParamName);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    navigate(
      {
        pathname: location.pathname,
        search: `?${createSearchParams(
          updateURLParams(defaultSortingText, urlParamName, location.search)
        )}`,
      },
      { replace: true }
    );
  }, [defaultSortingText]);

  const getGreaterThanDate =
    rangeSelector > 0
      ? moment()
          .subtract(rangeSelector, 'day')
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
          .format()
      : undefined;

  const getLessThanDate = moment()
    .add(1, 'day')
    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    .format();

  return (
    <div className="flex flex-col justify-start w-full mb-8">
      <div className="flex justify-between w-full mb-4">
        <Typhography component="p" type="h2" color="text-grey-900">
          {'INVOICE ACTIVITY'}
        </Typhography>
        <SelectBox
          items={pastDropdownItemsWithoutWeek}
          itemClickHandler={handlerSortingOption}
          defaultSelectBoxText={defaultSortingText}
          itemSelected={itemSlectedState}
          dropDownState={showDropdown}
          dropDownClickHandler={handlerSortingDropDownClick}
          additionalClasses={'-mt-[6px]'}
        />
      </div>
      <SummaryInvoiceGraph
        getGreaterThanDate={getGreaterThanDate}
        getLessThanDate={getLessThanDate}
        rangeSelector={rangeSelector}
      />
    </div>
  );
};
