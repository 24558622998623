const commonBorderdButtonStyles =
  'text-primary-500 border border-solid border-primary-500 bg-white';
const commonSolidButtonStyles = 'text-white border-solid border-primary-500 bg-primary-500';

export const handleButtonClasses = (variants?: string | undefined) => {
  switch (variants) {
    case 'large':
      return `text-16s h-[52px] ${commonSolidButtonStyles}`;
    case 'largeOutlined':
      return `text-16s h-[52px] ${commonBorderdButtonStyles}`;
    case 'small':
      return `text-14s h-9 ${commonSolidButtonStyles}`;
    case 'smallOutlined':
      return `text-14s h-9 ${commonBorderdButtonStyles}`;
    case 'extraSmall':
      return `text-14s h-7 ${commonSolidButtonStyles}`;
    case 'extraSmallOutlined':
      return `text-14s h-7 ${commonBorderdButtonStyles}`;
    case 'defaultOutlined':
      return `text-16s h-11 ${commonBorderdButtonStyles}`;
    case 'default':
      return `text-16s h-11 ${commonSolidButtonStyles}`;
    default:
      break;
  }
};
