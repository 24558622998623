import { getYTD } from 'shared/utilities/dataConversion';
const setYTDValue = getYTD();

interface DropDownItemInterface {
  value: string | number;
  postfix: string;
}

export const defaultDropdownItems = [
  { value: 7, postfix: 'Last 7 Days' },
  { value: 14, postfix: 'Last 14 Days' },
  { value: 21, postfix: 'Last 21 Days' },
  { value: 30, postfix: 'Last 30 Days' },
  { value: setYTDValue, postfix: 'YTD' },
  { value: 'All', postfix: 'All' },
];

export const upcomingDropdownItems = [
  { value: 7, postfix: 'Next 7 Days' },
  { value: 14, postfix: 'Next 14 Days' },
  { value: 21, postfix: 'Next 21 Days' },
  { value: 30, postfix: 'Next 30 Days' },
  { value: 'All', postfix: 'All' },
];

export const nextThirtyDaysDropdownItems = [
  { value: 7, postfix: 'next 7 days' },
  { value: 14, postfix: 'next 14 days' },
  { value: 21, postfix: 'next 21 days' },
  { value: 30, postfix: 'next 30 days' },
  { value: 'All', postfix: 'All' },
];

export const upcomingDropdownItemsWeek = [
  { value: 7, postfix: 'Next Week' },
  { value: 14, postfix: 'Next 2 Weeks' },
  { value: 21, postfix: 'Next 3 Weeks' },
  { value: 28, postfix: 'Next 4 Weeks' },
  { value: 'All', postfix: 'All' },
];

export const pastDropdownItemsWeek = [
  { value: 7, postfix: 'Last Week' },
  { value: 14, postfix: 'Last 2 Weeks' },
  { value: 21, postfix: 'Last 3 Weeks' },
  { value: 28, postfix: 'Last 4 Weeks' },
  { value: setYTDValue, postfix: 'YTD' },
  { value: 'All', postfix: 'All' },
];

export const pastDropdownItemsWithoutWeek = [
  { value: 7, postfix: 'Last 7 Days' },
  { value: 14, postfix: 'Last 14 Days' },
  { value: 21, postfix: 'Last 21 Days' },
  { value: 30, postfix: 'Last 30 Days' },
  { value: setYTDValue, postfix: 'YTD' },
  { value: 'All', postfix: 'All' },
];

export const getAPIValueForText = (
  text?: string,
  dropDownItems?: Array<DropDownItemInterface>
): string => {
  let value: string | number = 'All';
  dropDownItems?.forEach((item) => {
    if (item.postfix === text) {
      value = item.value;
    }
  });

  return value === 'All' ? '500' : String(value);
};
