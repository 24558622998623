import { useEffect, useState } from 'react';

import { Grid } from '../../Grid/Grid';
import { StatusBlock } from '../../StatusBlock/StatusBlock';

import { useCurrencyFormat } from 'hooks/useCurrencyFormat';
import { BalanceInnerListStatsBarProps, IdataObj } from 'shared/models/components/base.model';

import { useGetUpcomingBalanceHeroStatsQuery } from 'services/pastBalanceApi';

const initialDataObj: IdataObj = {
  gridColumnCount: 3,
  totalInvoices: '0',
  amount: useCurrencyFormat(0),
  amountBlockHeading: '',
  dueDate: '',
  amountPaid: useCurrencyFormat(0),
  isAmountPaidColumVisible: false,
};

export const UpcomingBalanceInnerListStatsBar = ({
  timeFrame = 'current',
  dateRangeQuery,
  dueDate,
}: BalanceInnerListStatsBarProps) => {
  const [dataObj, setDataObj] = useState(initialDataObj);

  // set filer object to get hero stats
  const heroStatsParams = {
    filter: {
      customer_payment_due_at: dateRangeQuery,
    },
    timeCycleDays: '' + 1 + '',
  };

  const { data, isSuccess }: any = useGetUpcomingBalanceHeroStatsQuery(heroStatsParams);

  useEffect(() => {
    if (isSuccess) {
      setDataObj({
        ...dataObj,
        gridColumnCount: timeFrame === 'current' ? 4 : 3,
        totalInvoices: `${data.pending_balance.totalCount}`,
        amount: useCurrencyFormat(data?.amount_due?.aggregates?.sum?.amount),
        amountBlockHeading: timeFrame === 'current' ? 'Amount Due' : 'Amount',
        dueDate: dueDate,
        amountPaid: useCurrencyFormat(data?.amount_paid?.aggregates?.sum?.amount),
        isAmountPaidColumVisible: timeFrame === 'current',
      });
    }
  }, [data]);

  return (
    <>
      <Grid columnCount={dataObj.gridColumnCount} additionalClasses={'w-full mb-3'}>
        <StatusBlock
          firstValue={dataObj.totalInvoices}
          blockHeading={'Invoices'}
          isIconVisible={true}
          iconType="InvoiceIcon"
          iconClasses={'relative top-1'}
          isRemoveBorderPadding
        />

        <StatusBlock
          blockHeading={dataObj.amountBlockHeading}
          firstValue={dataObj.amount}
          iconType={'DollarCircled'}
          iconColor={'text-green-400'}
          iconClasses={'relative top-[6px] -left-[2px]'}
          isIconVisible={true}
        />

        {dataObj.isAmountPaidColumVisible && (
          <StatusBlock
            blockHeading={'Amount Recived'}
            firstValue={dataObj.amountPaid}
            iconType={'DollarCircled'}
            iconColor={'text-green-400'}
            iconClasses={'relative top-[6px] -left-[2px]'}
            isIconVisible={true}
          />
        )}

        <StatusBlock
          blockHeading={'Due Date'}
          firstValue={dataObj.dueDate}
          iconType={'TimerIcon'}
          iconClasses={'relative top-1'}
          isIconVisible
        />
      </Grid>
    </>
  );
};
