import React from 'react';
import { isPermitted, useGetUserQuery } from 'services/userApi';
import ErrorContainer from '../Error/Error';

/*
 * Primary UI component for user interaction
 */
const Error403Container = () => {
  const { data: user } = useGetUserQuery({});
  const isDashboardURLAccessible = isPermitted(user, 'analytics-menu');
  return (
    <ErrorContainer
      redirectURL={isDashboardURLAccessible ? '/dashboard' : '/invoices/submitted'}
      errorType={403}
      errorText="You dont have the required permissions"
      buttonLabel="Sign Out"
      isRedirectToLogin={true}
    />
  );
};

export default Error403Container;
