import moment from 'moment';

import { useTitleCase } from 'hooks/useTitleCase';
import {
  getApprovalsStatusSorting,
  getByUserNameSorting,
  getNameFromAccount,
  getPropertyDefautBankAccount,
  getResmanPropDownloadData,
  handleLanguage,
} from 'shared/utilities/utils';
import { getPaidAtDateForInvoices, handleVendorDefaultPayMode } from './modules/helperVendor';
import { getPhoneNumberForCSV, getTermName } from './arrayFormating';
import { useCurrencyFormat } from 'hooks/useCurrencyFormat';
import { handlePropertyStatus } from './modules/helperProperties';

export const handleDownloadCSVDataMapping = (
  tabType: string,
  downloadData: any,
  paymentMethodData: any
) => {
  let result;
  if (tabType === 'vendor' && downloadData?.accounts !== null) {
    const vendorNodes = downloadData?.accounts.nodes;
    result = vendorNodes.map((node: any) => ({
      VENDOR: useTitleCase(getNameFromAccount(node)),
      STATUS: node?.status ? node?.status : null,
      'MODIFIED BY': getByUserNameSorting(node, true, false),
      code:
        node?.coupaSuppliersByAccountId.nodes && node?.coupaSuppliersByAccountId.nodes.length > 0
          ? node?.coupaSuppliersByAccountId.nodes[0].code
          : null,
      'PAY MODE': node?.default_pay_mode
        ? handleVendorDefaultPayMode(node?.default_pay_mode)
        : null,
      default_payment_term_id: node?.default_payment_term_id
        ? getTermName(node?.default_payment_term_id)
        : null,
      phone: getPhoneNumberForCSV(node?.phone),
      email: node?.email ? node?.email : null,
      language: node?.language ? handleLanguage(node?.language) : null,
      'OVERALL EARNINGS':
        node?.payoutsByAccountId?.groupedAggregates.length > 0
          ? node?.payoutsByAccountId?.groupedAggregates?.map((item: any) =>
              useCurrencyFormat(item.sum.payout_amount)
            )
          : null,
      'PAYOUTS SCHEDULED':
        node?.payoutsScheduledByAccountId?.groupedAggregates.length > 0
          ? node?.payoutsScheduledByAccountId?.groupedAggregates?.map((item: any) =>
              useCurrencyFormat(item.sum.payout_amount)
            )
          : null,
      'TOTAL PROCESSING FEE': node?.paidPayoutsByAccountId?.groupedAggregates[0]?.sum
        ?.processing_fee_amount
        ? useCurrencyFormat(
            node?.paidPayoutsByAccountId?.groupedAggregates[0]?.sum?.processing_fee_amount
          )
        : null,
      created_at: node?.created_at ? moment(node.created_at).format('MM/DD/YYYY') : null,
    }));
  } else if (tabType === 'properties' && downloadData.properties !== null) {
    const propertyNodes = downloadData.properties.nodes;
    result = propertyNodes.map((node: any) => ({
      name: node?.name ? useTitleCase(node?.name) : null,
      STATUS: handlePropertyStatus(node?.status, node?.is_delinquent),
      'UPDATED BY': getByUserNameSorting(node, true, false),
      VENDORS: node?.payouts_by_property_id?.aggregates?.distinctCount?.account_id ?? null,
      'DEFAULT BANK':
        paymentMethodData && paymentMethodData.length > 0
          ? getPropertyDefautBankAccount(paymentMethodData, node?.id)
          : null,
      code: node?.code ?? null,
      'PAID TO VENDORS': node?.paid_payouts?.aggregates?.sum?.amount
        ? useCurrencyFormat(node?.paid_payouts?.aggregates?.sum?.amount)
        : null,
      'AMOUNT PAID BY PROPERTY': node?.customer_paid_payouts?.aggregates?.sum?.amount
        ? useCurrencyFormat(node?.customer_paid_payouts?.aggregates?.sum?.amount)
        : null,
      'RECEIVED FROM PROPERTY': node?.customer_paid_payouts?.aggregates?.sum?.amount
        ? useCurrencyFormat(node?.customer_paid_payouts?.aggregates?.sum?.amount)
        : null,
      'AMOUNT IN TRANSITION': node?.amountInTransition?.aggregates?.sum?.total_amount
        ? useCurrencyFormat(node?.amountInTransition?.aggregates?.sum?.total_amount)
        : null,
    }));
  } else if (tabType === 'approve' && downloadData?.payouts !== null) {
    const payoutsNodes = downloadData?.payouts?.nodes;
    result = payoutsNodes.map((node: any) => ({
      invoiced_at: node.invoiced_at ? moment(node.invoiced_at).format('MM/DD/YYYY') : null,
      VENDOR: useTitleCase(getNameFromAccount(node.account)),
      number: node.number ? `ID${node.number}` : null,
      coupa_invoice_number: node.coupa_invoice_number,
      'BATCH #': node.payoutBatchTransfer?.batch_number ?? null,
      description: node.description,
      'property.name': node.property ? useTitleCase(node.property.name) : null,
      STATUS: getApprovalsStatusSorting(
        node.status,
        node.payout_scheduled_at,
        node.pay_mode,
        node.check_status
      ),
      estimated_approved_at: node.estimated_approved_at
        ? moment(node.estimated_approved_at).format('MM/DD/YYYY')
        : null,
      approved_at: node.approved_at ? moment(node.approved_at).format('MM/DD/YYYY') : null,
      payout_term_id: getTermName(node.payout_term_id),
      'VENDOR DUE': node.payout_scheduled_at
        ? moment(node.payout_scheduled_at).format('MM/DD/YYYY')
        : node.payout_due_at
        ? moment(node.payout_due_at).format('MM/DD/YYYY')
        : null,
      paid_at: getPaidAtDateForInvoices(node),
      'PAID BY': getByUserNameSorting(node, false, false),
      customer_payment_due_at: node.customer_payment_due_at
        ? moment(node.customer_payment_due_at).format('MM/DD/YYYY')
        : null,
      'CHECK DATE': getResmanPropDownloadData(node, 'check_date'),
      estimated_arrived_on: node.estimated_arrived_on
        ? moment(node.estimated_arrived_on).format('MM/DD/YYYY')
        : null,
      customer_paid_at: node.customer_paid_at
        ? moment(node.customer_paid_at).format('MM/DD/YYYY')
        : null,
      'AR MODE': getResmanPropDownloadData(node, 'paid_by'),
      'PAY REF': getResmanPropDownloadData(node, 'pay_id'),
      pay_mode: handleVendorDefaultPayMode(node.pay_mode),
      check_image_url: node.check_image_url ? node.check_image_url : null,
      amount: node.amount ? useCurrencyFormat(parseFloat(node.amount)) : null,
      payout_amount: node.payout_amount ? useCurrencyFormat(parseFloat(node.payout_amount)) : null,
      paid_amount: node.paid_amount ? useCurrencyFormat(parseFloat(node.paid_amount)) : null,
      check_number: node.check_number ?? null,
      processing_fee_amount: node.processing_fee_amount
        ? useCurrencyFormat(parseFloat(node.processing_fee_amount))
        : null,
      payout_term_amount: node.payout_term_amount
        ? useCurrencyFormat(parseFloat(node.payout_term_amount))
        : null,
      coupa_draw_account: node.coupa_draw_account,
    }));
  }

  return [result];
};
