import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import Wrapper from '../../../layouts/Wrapper/Wrapper';
import Headers from '../../../core/Headers/Headers';
import { LoadingSpinner } from 'components/core';

import TransactionsList from './List/Transactions/Transactions';

import { useTitleCase } from 'hooks/useTitleCase';

import { isPermitted, useGetUserQuery } from '../../../../services/userApi';
import { useDeleteVendorMutation, useGetVendorsInfoQuery } from '../../../../services/vendorsApi';

const VendorTransactionsContainer = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data, isSuccess, isError, isLoading } = useGetVendorsInfoQuery(id);

  const firstName = data?.individual?.first_name !== null ? data?.individual?.first_name : '';
  const lastName = data?.individual?.last_name !== null ? data?.individual?.last_name : '';
  const getbusinessName = data?.business_profile.name;
  const vendorName = getbusinessName ? getbusinessName : firstName + ' ' + lastName;

  const [mutation] = useDeleteVendorMutation();
  const { data: user } = useGetUserQuery({});

  async function deleteVendor() {
    // eslint-disable-next-line no-restricted-globals
    if (
      // eslint-disable-next-line no-restricted-globals
      confirm(
        `Are you sure you want to delete the vendor ${useTitleCase(
          vendorName
        )}, it cannot be undone?`
      )
    ) {
      const res: FetchBaseQueryError | SerializedError | any = await mutation(id);
      if (res.error && res.error.originalStatus !== 200) {
        alert('Error in deletion');
      }
      navigate('/vendors/active');
    }
  }

  return (
    <div>
      {/**
       * Handle loader
       */}
      {isLoading && <LoadingSpinner />}
      {/**
       * Error handling
       */}
      {isError && <Navigate to={'/401'} />}
      {isSuccess && (
        <Wrapper
          additionalClasses="flex-col items-end"
          pageTitle={useTitleCase(vendorName)}
          isBackButtonVisible
        >
          <Headers
            headingContent={useTitleCase(vendorName)}
            buttonLabel={'Delete Vendor'}
            onClick={deleteVendor}
            isButtonVisibled={isPermitted(user, 'delete-vendor')}
            isIconEnabled={true}
            variants={'extraSmallOutlined'}
            additionalClasses={'border-none'}
            iconType="TrashIcon"
            iconColor="inherit"
            headerWrapperClasses="border-none"
          />

          <TransactionsList />
        </Wrapper>
      )}
    </div>
  );
};

export default VendorTransactionsContainer;
