import React, { useState } from 'react';
import classNames from 'classnames';

import Card from '../../../../../core/OldComponents/Card/Card';
import Typho from '../../../../../core/OldComponents/Typho/Typho';
import Icon from '../../../../../core/Media/Icon/Icon';
import RadioButton from '../../../../../core/OldComponents/FormElements/RadioButton/RadioButton';
import DialogBox from '../../../../../core/OldComponents/DialogBox/DialogBox';
import { Button, StatusTag } from 'components/core';

import {
  useDeleteMutation,
  // useVerifySourceMutation,
  useSetDefaultSourceMutation,
} from '../../../../../../services/paymentSourcesApi';
import Message from '../../../../../core/Message/Message';

interface CardDetailsProps {
  cardType?: 'black' | 'whiteBorder';
  colorType?: 'white' | 'black';
  cardHolderName?: string;
  cardNumber?: string;
  methodId?: string;
  status?: string;
  isDefault?: boolean;
  verifyButtonClick?: any;
  cardAdditionalClasses?: string;
}

const CardDetail: React.FC<CardDetailsProps> = ({
  cardType = 'black',
  colorType = 'white',
  cardHolderName,
  cardNumber,
  methodId,
  status,
  isDefault,
  verifyButtonClick,
  cardAdditionalClasses,
}) => {
  // state to manage modal
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [useDeleteMutationOp] = useDeleteMutation();
  // const [useVerifySourceMutationOp] = useVerifySourceMutation();
  const [useSetDefaultSourceMutationOp] = useSetDefaultSourceMutation();
  const [isFade, setIsFade] = useState(false);
  const [isVerified, setIsVerified] = useState(false);

  async function deletePaymentSource() {
    await useDeleteMutationOp({ id: methodId });
  }

  // async function verifyPaymentSource() {
  //   await useVerifySourceMutationOp({ id: methodId });
  // }

  async function handleDefaultSource(event: any) {
    setIsFade(true);

    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      await useSetDefaultSourceMutationOp({ id: event.target.value });
      setIsFade(true);
    } catch (error) {
      setIsFade(false);
    }
  }

  const handleVerifySOurce = () => {
    setIsVerified(true);
  };

  const handleFadeClasses = isFade ? 'opacity-50' : 'opacity-100';

  return (
    <>
      {isVerified && <Message type="error">Please verify the payment method</Message>}

      <div
        className={classNames([
          'flex w-1/3 pl-2 pr-2 mb-2 duration-300 ease-out',
          handleFadeClasses,
          cardAdditionalClasses,
        ])}
      >
        <Card
          additionalClasses={`text-${colorType} relative px-4 py-4 flex-col items-center justify-center w-full min-h-[138px]`}
          cardType={isFade ? 'whiteBorder' : cardType}
        >
          <div className="absolute inline-flex align-top -top-[3px] right-0 cursor-pointer">
            {isDefault ? (
              <RadioButton
                name={`paymentMethod`}
                value={methodId}
                id={`paymentMethod-${methodId}`}
                isDeafult={false}
                checked
                readOnly
              />
            ) : status !== 'verified' ? (
              <RadioButton
                name={`paymentMethod`}
                value={methodId}
                id={`paymentMethod-${methodId}`}
                onChange={handleVerifySOurce}
                isDeafult={false}
              />
            ) : (
              <RadioButton
                name={`paymentMethod`}
                value={methodId}
                id={`paymentMethod-${methodId}`}
                onChange={handleDefaultSource}
                isDeafult={false}
              />
            )}
          </div>

          {/** Name */}
          <Typho
            size="18s"
            color={colorType}
            weight="bold"
            additionalClasses="mb-2 text-center"
            type="p"
          >
            {cardHolderName}
          </Typho>
          {/** Card Number */}
          <Typho size="10s" color={colorType} weight="medium" additionalClasses="mb-2" type="p">
            {cardNumber}
          </Typho>
          {/** Delete Icon */}
          <div className="inline-flex" onClick={() => setModalIsOpen(true)}>
            <Icon
              iconType="TrashIcon"
              iconColor={colorType !== 'black' ? 'white' : 'gray-720'}
              additionalClasses="cursor-pointer mb-2"
            />
          </div>
          {status === 'verified' ? (
            <>
              <StatusTag status="verified" />
            </>
          ) : (
            <Button
              variants="extraSmall"
              type="button"
              buttonLabel="Verify"
              onClick={verifyButtonClick}
              data-val={methodId}
              additionalClasses="inline-flex w-auto px-[10px] rounded w-[73px] !border-white border-solid border"
            />
          )}
        </Card>
        <DialogBox
          title="Confirmation !"
          content="Are you sure you want to delete ?"
          isConfirmButtonEnabled={true}
          isCancelButtonEnabled={true}
          handleConfirmButton={deletePaymentSource}
          handleCancelButton={() => setModalIsOpen(false)}
          handleOuterClick={() => setModalIsOpen(false)}
          textConfirmButton={'Yes'}
          textCancelButton={'No'}
          dialogBoxState={modalIsOpen}
        />
      </div>
    </>
  );
};

export default CardDetail;
