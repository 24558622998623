import { Navigate, Outlet } from 'react-router-dom';
import { isPermitted, useGetUserQuery } from '../../services/userApi';
import { useGetCustomerPreferenceQuery, useGetCustomersQuery } from '../../services/customersApi';
import { getCurrentCustomerId, setCurrentCustomerId, setLSKey } from '../../shared/configs/config';
import { useGetLoginMutation } from 'services/configApi';
import { useEffect, useState } from 'react';
import { removeLogoutLocalStorages } from 'helpers/modules/helperInvoices';

const PrivateRoute = () => {
  const { data: user, error, isError, isSuccess } = useGetUserQuery({});
  const { data: customers, isSuccess: isGetCustomersSuccess } = useGetCustomersQuery({});
  const { data: settings, isSuccess: isSettingsSuccess } = useGetCustomerPreferenceQuery('setting');
  const [loginMutation] = useGetLoginMutation();
  const [loginState, setLoginState] = useState('');

  const getLogin = async () => {
    // get isLoged in api call __
    const response: any = await loginMutation(null);

    if (response) {
      if (response.error.data === 'ok') {
        setLoginState('ok');
      } else {
        setLoginState('unauthorized');
        removeLogoutLocalStorages();
      }
    }
  };

  useEffect(() => {
    getLogin();
  }, []);

  let httpStatusCode;
  if (isError) {
    httpStatusCode = (error as any)?.originalStatus;
    httpStatusCode === 401 && localStorage.removeItem('id_token');
    httpStatusCode === 401 && localStorage.removeItem('balance_settings');
  } else if (
    isSuccess &&
    isGetCustomersSuccess &&
    isPermitted(user, 'switch-workspace') &&
    !getCurrentCustomerId() &&
    customers?.customers?.nodes.length > 0
  ) {
    setCurrentCustomerId(customers?.customers?.nodes[0].id);
    setLSKey('current_customer_name', customers?.customers?.nodes[0].business_name);
  }

  if (isSettingsSuccess) {
    localStorage.setItem('balance_settings', settings[0]?.data?.balanceCalculation);
  }

  setLSKey('current_customer_name', customers?.customers?.nodes[0]?.business_name);

  return (
    <>
      {loginState === 'ok' && <Outlet />}
      {loginState === 'unauthorized' && <Navigate to="/" />}
    </>
  );
};

export default PrivateRoute;
