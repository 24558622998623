import { Icon } from 'components/core';

import { useTitleCase } from 'hooks/useTitleCase';

import { IPayModeSetModal } from 'shared/models/components/base.model';

import { handlePayModeEditPosibilities } from 'helpers/modules/helperInvoices';
import classNames from 'classnames';
import { useComponentVisible } from 'hooks/useComponentVisible';

export const PayModeSetModal = ({
  pay_mode,
  id,
  status,
  isUserHaveEditAccess,
  invoiceNumber,
  onDisplayModalEvent,
}: IPayModeSetModal) => {
  const propertyHandler = handlePayModeEditPosibilities({ status, pay_mode });
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  const payModeChipClasses =
    'text-inherit rounded border border-solid border-grey-200 w-[90px] inline-flex items-center justify-between h-6 text-12s font-medium relative px-2';
  return (
    <>
      {propertyHandler.is_editable && isUserHaveEditAccess ? (
        <div className="flex flex-col w-[90px] relative" ref={ref}>
          <div
            className={`cursor-pointer opacity-100 ${payModeChipClasses} ${
              isComponentVisible ? 'bg-grey-50' : 'bg-white'
            }`}
            onClick={() => setIsComponentVisible(!isComponentVisible)}
          >
            <span className="text-inherit">
              {pay_mode === 'ACH' ? pay_mode : useTitleCase(pay_mode)}
            </span>
            <Icon iconType="DropDown" iconColor="inherit" />
          </div>
          {isComponentVisible && (
            <div className="absolute left-0 right-0 z-50 flex flex-col py-2 bg-white rounded-lg top-6 shadow-showMoreDropDown">
              <span
                className={classNames([
                  'relative flex py-[6px] px-[10px] font-medium text-center text-12s',
                  pay_mode === 'EXTERNAL'
                    ? 'opacity-50 pointer-events-none'
                    : 'opacity-100 cursor-pointer',
                ])}
                onClick={onDisplayModalEvent}
                data-invoice={invoiceNumber}
                data-id={id}
                data-mode={'external'}
              >
                {'External'}
              </span>
              <span
                className={classNames([
                  'relative flex py-[6px] px-[10px] font-medium text-center text-12s',
                  pay_mode === 'ACH'
                    ? 'opacity-50 pointer-events-none'
                    : 'opacity-100 cursor-pointer',
                ])}
                onClick={onDisplayModalEvent}
                data-invoice={invoiceNumber}
                data-id={id}
                data-mode={'ACH'}
              >
                {'ACH'}
              </span>
            </div>
          )}
        </div>
      ) : (
        <div
          className={`opacity-50 pointer-events-none cursor-auto !justify-center ${payModeChipClasses}`}
        >
          <span className="text-center">{pay_mode === 'ACH' ? 'ACH' : useTitleCase(pay_mode)}</span>
        </div>
      )}
    </>
  );
};
