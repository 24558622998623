import React from 'react';
import { isPermitted, useGetUserQuery } from 'services/userApi';
import ErrorContainer from '../Error/Error';

const Error404Container = () => {
  const { data: user } = useGetUserQuery({});
  const isDashboardURLAccessible = isPermitted(user, 'analytics-menu');

  return (
    <ErrorContainer
      redirectURL={isDashboardURLAccessible ? '/dashboard' : '/invoices/submitted'}
      errorType={404}
      errorText="We cant seem to find the page you are looking for."
      buttonLabel={isDashboardURLAccessible ? 'Back to Home' : 'Back to Approvals'}
    />
  );
};

export default Error404Container;
