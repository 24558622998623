export const getFormatDate = (date: string, value: string) => {
  let formattedValue = value;
  const digitOnlyInput = value.replace(/[^0-9]/g, '');

  if (digitOnlyInput.length < 2) {
    formattedValue = `${digitOnlyInput}`;
  } else if (digitOnlyInput.length === 2) {
    if (date.length >= 5) {
      formattedValue = `${digitOnlyInput}`;
    } else {
      formattedValue = `${digitOnlyInput} - `;
    }
  } else if (digitOnlyInput.length < 4) {
    formattedValue = `${digitOnlyInput.slice(0, 2)} - ${digitOnlyInput.slice(2, 4)}`;
  } else if (digitOnlyInput.length === 4) {
    if (date.length >= 10) {
      formattedValue = `${digitOnlyInput.slice(0, 2)} - ${digitOnlyInput.slice(2, 4)}`;
    } else {
      formattedValue = `${digitOnlyInput.slice(0, 2)} - ${digitOnlyInput.slice(2, 4)} - `;
    }
  } else {
    formattedValue = `${digitOnlyInput.slice(0, 2)} - ${digitOnlyInput.slice(
      2,
      4
    )} - ${digitOnlyInput.slice(4, 8)}`;
  }

  return formattedValue;
};
