import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';

import Wrapper from 'components/layouts/Wrapper/Wrapper';
import {
  HeaderTabNavigation,
  Headers,
  LoadingSpinner,
  Icon,
  StatusTag,
  Button,
  Typhography,
} from 'components/core';
import SuperTable from '../../../core/SuperTable/SuperTable';
import { DataModal } from './PaymentBatches/DataModal';
import { ErrorModal } from './PaymentBatches/ErrorModal';

import { handlePropertiesDetailTabNavData } from 'constants/TabNavData';
import { CONFIG } from 'shared/configs/config';

import { useGetPropertiesQuery } from 'services/properties';
import { isPermitted, useGetUserQuery } from '../../../../services/userApi';
import {
  useAchFileUploadMutation,
  useCreateACHChargeMutation,
  useParseAchFileFromUrlMutation,
  useGetCustomerSourceChargesQuery,
  useCreateTransferFromChargeIdMutation,
} from 'services/payoutsApi';
import { useTitleCase } from 'hooks/useTitleCase';
import { useCurrencyFormat } from 'hooks/useCurrencyFormat';
import { CellContent } from 'components/core/SuperTable/CellElements/CellContent/CellContent';
import {
  DateRangeColumnFilter,
  MultiCheckBoxColumnFilter,
} from 'components/core/SuperTable/CellElements/Filters';
import classNames from 'classnames';
import {
  handlePaymentBatchesLastFourNumber,
  handleThePaymentBatchsesStatus,
} from 'shared/utilities/utils';
import { TextFileModal } from './PaymentBatches/TextFileModal';
// import ReactDOM from 'react-dom';

export const PropertyPaymentBatchesContainer = () => {
  const { id } = useParams();
  const [detailTabNavData] = handlePropertiesDetailTabNavData(id);
  // initialize the states
  const [modalData, setModalData]: any = useState();
  const [summary, setSummary]: any = useState({
    fileName: null,
    totalCount: null,
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [uploadedFilesObject, setUploadedFilesObject] = useState();
  const [readOnlyDataModal, setReadOnlyDataModal] = useState(false);
  const [createInvoiceModal, setCreateInvoiceModal] = useState(false);
  const [selectedSource, setSelectedSource]: any = useState();
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [visibleColumns, setVisibleColumns] = useState([]);
  const [filteredRow, setFilteredRow] = useState([]);

  const filterParams = {
    limit: CONFIG.DATA_OVERALL_LIMIT,
    offset: 0,
    filter: {
      id: {
        equalTo: '"' + id + '"',
      },
    },
  };

  const { data, isSuccess: isPropertyDataLoadSuccess } = useGetPropertiesQuery(filterParams);
  const {
    data: batches,
    isSuccess: isBatchesSuccess,
    isLoading: isBatchesLoading,
  } = useGetCustomerSourceChargesQuery(id, {
    refetchOnMountOrArgChange: true,
    pollingInterval: 10000,
  });

  const { data: user } = useGetUserQuery({});
  const [
    achFileUploadMutation,
    {
      error: uploadError,
      isError: isUploadError,
      isLoading: isUploadLoading,
      // isSuccess: isUploadSuccess,
    },
  ]: any = useAchFileUploadMutation();
  const [
    createACHChargeMutation,
    { error: createChargeError, isError: isCreateChargeError, isLoading: isCreateChargeLoading },
  ]: any = useCreateACHChargeMutation();
  const [
    fillePareseMutation,
    {
      error: fileUploadError,
      // isSuccess: isFileUploadSuccess,
      isLoading: isFileUploadLoading,
      isError: isFileUploadError,
    },
  ]: any = useParseAchFileFromUrlMutation();
  const [
    createTransferFromChargeId,
    {
      data: transferCreateData,
      isLoading: isTransferCreateLoading,
      error: transferCreateError,
      isError: isTransferCreateError,
      isSuccess: isTransferCreateSuccess,
    },
  ]: any = useCreateTransferFromChargeIdMutation();

  /**
   * Table datas
   */
  const tableData = React.useMemo(() => batches?.charges_v1?.nodes, [batches?.charges_v1?.nodes]);
  const columns: any = React.useMemo(
    () => [
      {
        Header: 'INITIATED',
        accessor: (row: any) => moment(row?.achFile?.created_at).format('MM/DD/YYYY'),
        Filter: DateRangeColumnFilter,
        filter: 'dateBetween',
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        Cell: (row) => {
          // eslint-disable-next-line @typescript-eslint/naming-convention,react/destructuring-assignment, no-unsafe-optional-chaining
          const { id } = row?.row?.original;

          return (
            <CellContent>
              {row?.row?.original?.achFile?.created_at ? (
                <span
                  onClick={() => handleFileUrlClick(id)}
                  className={'underline cursor-pointer text-inherit'}
                >
                  {moment(row?.row?.original?.achFile?.created_at).format('MM/DD/YYYY')}{' '}
                </span>
              ) : null}
            </CellContent>
          );
        },
      },
      {
        Header: 'ARRIVED ON',
        accessor: 'arrived_on',
        Filter: DateRangeColumnFilter,
        filter: 'dateBetween',
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        Cell: (row) => {
          return (
            <CellContent>
              {row?.row?.original?.arrived_on
                ? moment(row?.row?.original?.arrived_on).format('MM/DD/YYYY')
                : null}
            </CellContent>
          );
        },
      },
      {
        Header: 'BANK TYPE',
        accessor: (row: any) => row.source?.metadata?.label,
        Filter: MultiCheckBoxColumnFilter,
        filter: 'multiSelect',
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        Cell: (row) => {
          const srcId = row.row.original.bank_source_id;
          const source = batches?.payment_methods.find((source: any) => source.id === srcId);

          // eslint-disable-next-line @typescript-eslint/naming-convention,react/destructuring-assignment
          return <CellContent>{source?.metadata?.label}</CellContent>;
        },
      },
      {
        Header: 'BANK',
        accessor: (row: any) => handlePaymentBatchesLastFourNumber(row, batches),
        Cell: (row: any) => {
          return (
            // eslint-disable-next-line @typescript-eslint/naming-convention,react/destructuring-assignment
            <CellContent wrapperClasses="!text-12s">
              {handlePaymentBatchesLastFourNumber(row?.row?.original, batches) ? (
                <span className="normal-case text-inherit">{`xxxxx${handlePaymentBatchesLastFourNumber(
                  row?.row?.original,
                  batches
                )}`}</span>
              ) : null}
            </CellContent>
          );
        },
      },
      {
        Header: 'STATUS',
        accessor: (row: any) => handleThePaymentBatchsesStatus(row?.status),
        Filter: MultiCheckBoxColumnFilter,
        filter: 'multiSelect',
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        Cell: (row) => {
          // eslint-disable-next-line @typescript-eslint/naming-convention,react/destructuring-assignment

          return (
            <CellContent>
              {row?.row?.original?.status ? (
                <StatusTag status={handleThePaymentBatchsesStatus(row?.row?.original?.status)} />
              ) : null}
            </CellContent>
          );
        },
      },
      {
        Header: 'AMOUNT',
        accessor: (row: any) => row?.achFile?.total_amount,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        Cell: (row) => {
          return (
            <CellContent>
              {row?.row?.original?.achFile?.total_amount
                ? useCurrencyFormat(row.row.original.achFile?.total_amount)
                : null}
            </CellContent>
          );
        },
      },
      {
        Header: 'TRX FEE',
        accessor: 'stripe_transaction_fees',
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        Cell: (row) => {
          return (
            <CellContent wrapperClasses="w-[80px]">
              {row?.row?.original?.stripe_transaction_fees
                ? useCurrencyFormat(row?.row?.original?.stripe_transaction_fees)
                : null}
            </CellContent>
          );
        },
      },
      {
        Header: 'FILE',
        accessor: (row: any) => row?.achFile?.file_name,
        Cell: (row: any) => {
          // eslint-disable-next-line no-unsafe-optional-chaining
          const { achFile } = row?.row?.original;
          const getFileName = achFile.file_name;
          const getTextLength = achFile ? getFileName.length : 0;
          const getFileURL = achFile.s3_url;

          return (
            // eslint-disable-next-line @typescript-eslint/naming-convention,react/destructuring-assignment
            <CellContent
              isTooltipEnabled={getFileName && getTextLength > 15}
              tooltipIcon={'DotsHorizontalMoreIcon'}
              tooltipIconClasses="text-grey-600 top-[2px] relative -left-[5px] cursor-pointer"
              tooltipContainerClass={'right-[20px] -top-[6px] rounded-tl-lg min-w-[200px]'}
              tooltipContent={getFileName ? getFileName : null}
              isWordWrapped
            >
              {getFileName ? <TextFileModal fileURL={getFileURL} fileName={getFileName} /> : null}
            </CellContent>
          );
        },
      },
      {
        Header: '#BATCHES',
        accessor: (row: any) => row?.achFile?.total_entries,
        // headerClasses: 'w-[55px]',
        Cell: (row: any) => {
          return (
            // eslint-disable-next-line @typescript-eslint/naming-convention,react/destructuring-assignment
            <CellContent>{row.row.original?.achFile?.total_entries}</CellContent>
          );
        },
      },
      {
        Header: 'PAYOUTS MADE',
        accessor: (row: any) => row?.payoutBatchTransfersByAchFileChargeId?.totalCount,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        Cell: (row) => {
          return (
            <CellContent>
              {row.row.original?.payoutBatchTransfersByAchFileChargeId?.totalCount
                ? row.row.original?.payoutBatchTransfersByAchFileChargeId?.totalCount
                : null}
            </CellContent>
          );
        },
      },
      {
        Header: '',
        accessor: 'id',
        canFilter: false,
        disableSortBy: true,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        Cell: (row) => {
          // eslint-disable-next-line @typescript-eslint/naming-convention,react/destructuring-assignment
          const { status, id, achFile, payoutBatchTransfersByAchFileChargeId } = row.row.original;

          const getSuccessStatus = status !== 'SUCCEEDED';
          const isAllBatchesArePaid =
            achFile?.total_entries &&
            Number(achFile?.total_entries) ===
              Number(payoutBatchTransfersByAchFileChargeId?.totalCount);

          const isButtonDisabled = getSuccessStatus || (isAllBatchesArePaid && !getSuccessStatus);

          if (!isPermitted(user, 'paynow-option')) {
            return false;
          }

          return (
            <CellContent>
              <div className="flex flex-row">
                <div>
                  <Button
                    type="button"
                    variants="extraSmall"
                    buttonLabel="Payout"
                    onClick={() => handleCreateCharge(id)}
                    additionalClasses="w-[90px] ml-4"
                    isDisabled={isButtonDisabled}
                    iconType="Dollaricon"
                    isIconEnabled
                    iconClass="mr-3"
                  />
                </div>
              </div>
            </CellContent>
          );
        },
      },
    ],
    [batches]
  );

  const [defaultSortOrder] = useState({ id: 'number', desc: true });

  /**
   * Onchange event for file upload
   */
  const handleOnChange = async (event: any) => {
    const files = event.target.files; // get files
    const data = new FormData(); // create new formData object
    setErrorModalOpen(false);

    if (files) {
      setUploadedFilesObject(files);
      data.append('achfile1', files[0]); // append uploaded files to data object

      // call RTK mutation
      const response: any = await achFileUploadMutation(data);

      if (response) {
        // if response true (Error || Success) display modal
        if (!response.error) {
          setCreateInvoiceModal(false);
          setModalOpen(true);
          // if no error set response -> data -> batches -> entires to state
          setModalData(response?.data[0]?.response?.batches[0]?.entries);
          setSummary({
            fileName: response?.data[0].filename,
            totalCount: response?.data[0].response?.batches[0]?.trailer?.totalCreditAmount,
          });
          setErrorModalOpen(false);
        } else {
          setModalOpen(false);
          setErrorModalOpen(true);
        }
      }
    }
  };

  /**
   * Click event for modal close
   */
  const handleModalClose = () => {
    setModalOpen(false);
    setReadOnlyDataModal(false);
  };

  /**
   * Click event for error modal close
   */
  const handleErrorModalClose = () => {
    setErrorModalOpen(false);
  };

  /**
   * Handle confirm button click event
   */
  const handleConfirmButtonClickEvent = async () => {
    if (uploadedFilesObject) {
      const data = new FormData(); // create new formData object
      data.append('source', selectedSource);
      data.append('file', uploadedFilesObject[0]); // append uploaded uploadedFilesObject to data object
      if (id) {
        data.append('property_id', id); // append property Idt to data object
      }

      // call RTK mutation
      const response: any = await createACHChargeMutation(data);

      if (response) {
        // if response true (Error || Success) display modal
        setReadOnlyDataModal(false);
        // handleRefundModalClose();
        handleErrorModalClose();
        setModalOpen(false);

        if (response.error) {
          setErrorModalOpen(true);
        }
      }
    }
  };

  /**
   * Handle ACH File URL click
   */
  const handleFileUrlClick = async (ach_file_charge_id?: string) => {
    // call RTK mutation
    const response: any = await fillePareseMutation({
      ach_file_charge_id: ach_file_charge_id,
    });

    if (response) {
      // if response true (Error || Success) display modal
      handleErrorModalClose();

      if (!response.error) {
        handleErrorModalClose();
        setReadOnlyDataModal(true);
        setCreateInvoiceModal(false);
        // if no error set response -> data -> batches -> entires to state
        setModalData(response?.data[0]?.response?.batches[0]?.entries);
        setSummary({
          fileName: response?.data[0]?.filename,
          totalCount: response?.data[0]?.response?.batches[0]?.trailer?.totalCreditAmount,
        });
        setModalOpen(true);
      } else {
        setModalOpen(false);
        setErrorModalOpen(true);
      }
    }
  };

  /**
   * Handle Create charge by ID
   */

  const handleCreateCharge = async (id: string) => {
    const response = await createTransferFromChargeId({ ach_file_charge_id: id });
    handleErrorModalClose();

    if (response) {
      if (!response.error || !response.error.data.error) {
        const failBatchEntries = response.data.failBatchEntries;
        const successBatchEntries = response.data.successBatchEntries;
        const transferAlreadyThereBatchEntries = response.data.transferAlreadyThereBatchEntries;
        const overallBatchesEntries = [
          ...failBatchEntries,
          ...successBatchEntries,
          ...transferAlreadyThereBatchEntries,
        ];

        const mainProcessErrorIfAny = response.data.mainProcessErrorIfAny;

        if (overallBatchesEntries && overallBatchesEntries.length === 0 && mainProcessErrorIfAny) {
          setModalOpen(false);
          setErrorModalOpen(true);
        } else {
          setCreateInvoiceModal(true);
          setModalData(overallBatchesEntries);
          setSummary({
            fileName: null,
            totalCount: null,
          });
          setModalOpen(true);
        }
      } else {
        setModalOpen(false);
        setErrorModalOpen(true);
      }
    }
  };

  /**
   * Handle error Message for File Upload and Create Charge
   */
  const handleError = () => {
    let error;

    if (isUploadError && uploadError.data.message) {
      error = uploadError.data.message;
    } else if (isUploadError && !uploadError.data.message) {
      error = uploadError.data;
    } else if (isFileUploadError && fileUploadError.data.message) {
      error = fileUploadError.data.message;
    } else if (isFileUploadError && !fileUploadError.data.message) {
      error = fileUploadError.data.error;
    } else if (isCreateChargeError && createChargeError.data.error) {
      error = createChargeError.data.error;
    } else if (isCreateChargeError && !createChargeError.data.error) {
      error = createChargeError.data.message;
    } else if (isTransferCreateError && transferCreateError.data.error) {
      error = transferCreateError.data.error;
    } else if (
      isTransferCreateSuccess &&
      transferCreateData.mainProcessErrorIfAny &&
      modalData.length === 0
    ) {
      error = transferCreateData.mainProcessErrorIfAny;
    }

    return error;
  };

  const isUploadFileButtonDisabled = batches?.payment_methods?.length === 0;

  return (
    <Wrapper
      additionalClasses="flex-col items-start"
      pageTitle={'Properties Details'}
      isBackButtonVisible
    >
      {(isUploadLoading ||
        isCreateChargeLoading ||
        isFileUploadLoading ||
        isTransferCreateLoading ||
        isBatchesLoading) && <LoadingSpinner />}
      {isPropertyDataLoadSuccess && (
        <Headers
          headingContent={data.properties.nodes[0].name}
          headerWrapperClasses="border-none"
        />
      )}

      <HeaderTabNavigation
        tabNavData={detailTabNavData}
        visibleColumns={visibleColumns}
        filteredRow={filteredRow}
        isDownloadCsvButtonEnabled={isBatchesSuccess && batches?.charges_v1?.nodes?.length !== 0}
      />

      {modalOpen && modalData && modalData.length > 0 && !errorModalOpen && (
        <DataModal
          data={modalData}
          sources={!createInvoiceModal ? batches?.payment_methods : []}
          onCloseEvent={handleModalClose}
          onSubmitEvent={handleConfirmButtonClickEvent}
          fileName={!createInvoiceModal && summary.fileName ? useTitleCase(summary.fileName) : null}
          totalCount={
            !createInvoiceModal && summary.totalCount ? useCurrencyFormat(summary.totalCount) : ''
          }
          isReadOnlyModal={readOnlyDataModal}
          setSelectedSource={setSelectedSource}
          isCreateInvoiceClicked={createInvoiceModal}
          propertyName={data.properties.nodes[0].name}
          setModalOpen={setModalOpen}
        />
      )}

      {errorModalOpen && !modalOpen && handleError() && (
        <ErrorModal onCloseEvent={handleErrorModalClose} errorMessage={handleError()} />
      )}

      <>
        {/* {(batches?.sources?.length > 0 || batches?.payment_methods?.length > 0) && ( */}
        <div className="flex flex-col w-full mb-4 -mt-2">
          <div className="flex items-center justify-between w-full">
            <Typhography type="h2" additionalClasses="!font-normal" color="text-grey-900">
              {
                'The Payouts tab shows funds that have been pulled from a property. You can initiate a payout once the funds have successfully arrived.'
              }
            </Typhography>
            {isPermitted(user, 'payment-tab') && (
              <label
                className={classNames([
                  'flex items-center justify-center w-auto px-3 text-white border-solid rounded-lg cursor-pointer border-primary-500 h-7 whitespace-nowrap',
                  isUploadFileButtonDisabled
                    ? 'bg-primary-500 pointer-events-none opacity-[0.38]'
                    : 'bg-primary-500',
                ])}
                htmlFor="uploadFile"
              >
                <Icon
                  iconColor="inherit"
                  iconType="UploadIcon"
                  additionalClasses="mr-2 relative -top-[1px]"
                />
                <span className="leading-normal text-14s">{'NACHA File'}</span>
                <input
                  className="hidden w-0 h-0"
                  type="file"
                  id="uploadFile"
                  onChange={handleOnChange}
                  onClick={(e: any) => {
                    e.target.value = null;
                  }}
                />
              </label>
            )}
          </div>
        </div>

        {batches?.charges_v1?.nodes?.length > 0 && (
          <SuperTable
            columns={columns}
            hiddenColumns={[]}
            data={tableData}
            defaultSortBy={defaultSortOrder}
            paginationOptions={[50, 100, 200, 400, 1000, 2000, 4000, 6000]}
            tableWrapperType="grid-height-batches"
            isPagination
            setVisibleColumns={setVisibleColumns}
            setFilteredRow={setFilteredRow}
          ></SuperTable>
        )}
      </>
    </Wrapper>
  );
};
