import { useEffect, useState } from 'react';
import Qs from 'qs';
import { useLocation } from 'react-router-dom';
import { defaultDropdownItems, getAPIValueForText } from 'constants/DateRangeDropDownDatas';

export const getURLParams = (dateRange: string) => {
  return Qs.stringify({ dateRange });
};

export const updateURLParams = (dateRange: string, paramName: string, params?: string) => {
  if (params) {
    return Qs.stringify({
      ...Qs.parse(params.substring(1)),
      [paramName]: dateRange,
    });
  }
  return Qs.stringify({ [paramName]: dateRange });
};

export const useDateRangeSorting = (
  _initialSortingText = 'All',
  dropDownItems = defaultDropdownItems,
  urlParamName = 'dateRange'
) => {
  const location = useLocation();
  const InitialSortingText = _initialSortingText;
  const [defaultSortingText, setDefaultSortingText] = useState(InitialSortingText);
  const [defaultSortingValue, setDefaultSortingValue] = useState(InitialSortingText);
  const [showDropdown, setShowDropdown] = useState(false);
  const [itemSlectedState, setItemSlectedState] = useState(true);

  useEffect(() => {
    if (location?.search && location.search.substring(1)) {
      const savedDateRangeData = Qs.parse(location.search.substring(1));
      if (savedDateRangeData && savedDateRangeData[urlParamName]) {
        const selectedText = String(savedDateRangeData[urlParamName]);

        setDefaultSortingText(selectedText);
        setDefaultSortingValue(getAPIValueForText(selectedText, dropDownItems));

        setItemSlectedState(true);
      }
    }
  }, []);

  let rangeSelector =
    defaultSortingValue !== 'All' ? parseFloat(defaultSortingValue.replace(/[^0-9]/g, '')) : 500;

  rangeSelector = isNaN(rangeSelector) ? 0 : rangeSelector;

  /**
   * handle sorting functionality
   * TODO need to moved to separate hooks
   */
  const handlerSortingDropDownClick = () => {
    setShowDropdown(!showDropdown);
  };

  /**
   * handle sorting option's functionality
   * TODO need to moved to separate hooks
   */
  const handlerSortingOption = (event: any) => {
    const selectedValue =
      event.target.getAttribute('data-val') === 'All' ? 500 : event.target.getAttribute('data-val');
    const selectedText = event.target.getAttribute('data-text');

    setDefaultSortingText(selectedText);
    setDefaultSortingValue(String(selectedValue));

    setItemSlectedState(true);
    setShowDropdown(!showDropdown);
  };

  return [
    rangeSelector,
    defaultSortingText,
    itemSlectedState,
    showDropdown,
    handlerSortingDropDownClick,
    handlerSortingOption,
  ];
};
