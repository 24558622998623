import React from 'react';
import { NavItem } from '../../core';
import { isPermitted, useGetUserQuery } from '../../../services/userApi';
import { NavigationProp } from 'shared/models/components/layouts.model';

/**
 * Primary UI component for user interaction
 */
const Navigation: React.FC<NavigationProp> = ({ data_testid }) => {
  const { data: user } = useGetUserQuery({});

  let NavData = [
    {
      icon: 'Approvals',
      name: 'Invoices',
      path: '/invoices/submitted',
      indexVal: 'invoices',
      order: 4,
    },
    {
      icon: 'Vendors',
      name: 'Vendors',
      path: '/vendors/active',
      indexVal: 'vendors',
      order: 5,
    },
    {
      icon: 'Properties',
      name: 'Properties',
      path: '/properties/active',
      indexVal: 'properties',
      order: 6,
    },
  ];

  NavData = isPermitted(user, 'transctions-menu')
    ? [
        {
          icon: 'Payments',
          name: 'Payments',
          path: '/payments/current',
          indexVal: 'payments',
          order: 3,
        },
        ...NavData,
      ]
    : NavData;

  NavData = isPermitted(user, 'reconciliation-menu')
    ? [
        {
          icon: 'Reconcile',
          name: 'Reconcile',
          path: '/reconcile/checks',
          indexVal: 'reconcile',
          order: 7,
        },
        ...NavData,
      ]
    : NavData;
  NavData = isPermitted(user, 'balance-menu')
    ? [
        {
          icon: 'Balance',
          name: 'Balance',
          path: '/balance/current',
          indexVal: 'balance',
          order: 2,
        },
        ...NavData,
      ]
    : NavData;

  NavData = isPermitted(user, 'analytics-menu')
    ? [
        {
          icon: 'Home',
          name: 'Home',
          path: '/dashboard',
          indexVal: 'dashboard',
          order: 1,
        },
        ...NavData,
      ]
    : NavData;

  NavData = [...NavData].sort((a: any, b: any) => parseFloat(a.order) - parseFloat(b.order));

  return (
    <nav
      data-testid={data_testid}
      className="absolute bg-black-900 flex self-start w-full top-[111px] bottom-0 flex-col"
    >
      <ul className="flex flex-col justify-center w-full">
        {NavData &&
          NavData.map((item, index) => {
            return (
              <NavItem
                iconType={item.icon}
                name={item.name}
                urlPath={item.path}
                indexVal={item.indexVal}
                key={index}
                listItemClasses={`justify-center items-center`}
              />
            );
          })}
      </ul>
    </nav>
  );
};

export default Navigation;
