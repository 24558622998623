import React from 'react';
import { Classes } from '../../../../shared/configs/componentsClasses';

type TyphoTypes = 'p' | 'span' | 'strong' | 'em' | 'div' | undefined;
type TyphoColorTypes =
  | 'white'
  | 'gray'
  | 'gray720'
  | 'black'
  | 'black650'
  | 'blue'
  | 'inherit'
  | string;
type TyphoFontSizeTypes =
  | 'h1'
  | '14s'
  | '8s'
  | '9s'
  | '10s'
  | '11s'
  | '12s'
  | '16s'
  | '17s'
  | '18s'
  | '20s'
  | '24s'
  | '26s'
  | '32s'
  | '42s'
  | '48s'
  | '54s'
  | '80s'
  | undefined;
type TyhoFontWeightTypes =
  | 'thin'
  | 'extralight'
  | 'light'
  | 'normal'
  | 'medium'
  | 'semibold'
  | 'bold'
  | 'extrabold'
  | 'black';

interface TyphoProps {
  title?: string;
  /**
   * Set element node of the content wrapper
   */
  type?: TyphoTypes;
  /**
   * Set font-color of the content
   */
  color?: TyphoColorTypes;
  /**
   * Set font-size of the content
   */
  size?: TyphoFontSizeTypes;
  /**
   * Set font-weight of the content
   */
  weight?: TyhoFontWeightTypes;
  /**
   * Optional for additional classes
   */
  additionalClasses?: string | undefined;
  /**
   * Set content and child nodes
   */
  children?: React.ReactNode;
}

const handleFontColor = (_fontColor: string | undefined) => {
  switch (_fontColor) {
    case 'white':
      return Classes.typho.white;
    case 'blue':
      return Classes.typho.blue;
    case 'gray':
      return Classes.typho.gray;
    case 'gray720':
      return Classes.typho.gray720;
    case 'black':
      return Classes.typho.black;
    case 'black650':
      return Classes.typho.black650;
    case 'inherit':
      return Classes.typho.inherit;
    default:
      return _fontColor;
  }
};

const handleFontSizeWeight = (_fontSize: string, _fontWeight: string) => {
  return `text-${_fontSize} font-${_fontWeight}`;
};

/**
 * Primary UI component for user interaction
 */
const Typho: React.FC<TyphoProps> = ({
  type,
  color = 'black',
  size = '14',
  weight = 'normal',
  additionalClasses,
  title,
  children,
  ...rest
}) => {
  const tyPhoClasses =
    handleFontSizeWeight(size, weight) + ' ' + handleFontColor(color) + ' ' + additionalClasses;

  return (
    <>
      {type === 'p' ? (
        <p className={tyPhoClasses} {...rest}>
          {children}
        </p>
      ) : type === 'span' ? (
        <span className={tyPhoClasses} {...rest}>
          {children}
        </span>
      ) : type === 'strong' ? (
        <strong className={tyPhoClasses} {...rest}>
          {children}
        </strong>
      ) : type === 'em' ? (
        <em className={tyPhoClasses} {...rest}>
          {children}
        </em>
      ) : type === 'div' ? (
        <div className={tyPhoClasses} {...rest}>
          {children}
        </div>
      ) : (
        <div className={additionalClasses} {...rest}>
          {children}
        </div>
      )}
    </>
  );
};

export default Typho;
