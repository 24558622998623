export const ERROR_MESSAGES = {
  REQUIRED: 'Please complete this field',
  NAME_INVALID: 'Not a valid name',
  TERMS: 'Terms must be accepted to continue',
  EMAIL_INVALID: 'Please enter a valid email address',
  SSN_INVALID_LAST4: 'Input required only 4 digits',
  SSN_INVALID_FULL: 'Input required only 9 digits',
  ACCOUNT_NUMBER_INVALID: 'Input required between 5 and 17 digits',
  ACCOUNT_NUMBER_INVALID_LAST4: 'Input required last 4 digits',
  EIN_INVALID: 'Input required only 9 digits',
  ZIPCODE_INVALID: 'Input required only 5 digits',
  PHONE_NUMBER_INVALID: 'Phone number required 10 digits',
  DATE_INVALID: 'Date needs to > 13',
  REVIEW_MISSING_INFORMATION: 'fill in the missing information',
  CODE_INVALID: 'Invalid code',
  INVALID_NAME: 'Enter valid Name',
};

/*eslint-disable */
export const REGEX = {
  personalName: /^[a-z ,.'-]+$/i,
  email:
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
  idNumber: /[0-9]{4}$/,
  phoneNumber: /[0-9]{10}$/,
  dob: /^(0[1-9]|[12]\d|3[01])$/,
};

export const ValidateIdNumber = (idNum: string) => {
  return idNum.replace(/\D/g, '').length == 4;
};
