import { useEffect, useState, useRef, useCallback } from 'react';
import * as PDFJS from 'pdfjs-dist';

// This line is a must-have! otherwise, pdfjs will NOT work
PDFJS.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${PDFJS.version}/pdf.worker.js`;

interface UsePdfViewerArgs {
  pdfUrl: string;
}

export const usePdfViewer = ({ pdfUrl }: UsePdfViewerArgs) => {
  // eslint-disable-next-line
  const canvasContainerRef = useRef<any>();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [pdfDoc, setPdfDoc] = useState<PDFJS.PDFDocumentProxy>();

  useEffect(() => {
    setLoading(true);
    const loadingTask = PDFJS.getDocument(pdfUrl);
    // eslint-disable-next-line
    loadingTask.promise
      .then((loadedPdf) => {
        setLoading(false);
        // eslint-disable-next-line
        setPdfDoc(loadedPdf);
      })
      .catch((err) => {
        console.error(err);
        setError(true);
      });
  }, [pdfUrl]);

  const renderPage = useCallback((page: PDFJS.PDFPageProxy) => {
    const viewport = page.getViewport({ scale: 1 });
    // eslint-disable-next-line
    let canvas = document.createElement('canvas');
    canvas.id = `${canvasContainerRef.current?.id}.${page.pageNumber}`;
    // eslint-disable-next-line
    let canvasWrapper = document.createElement('div');
    canvasWrapper.classList.add('page');
    canvas.height = viewport.height;
    canvas.width = viewport.width;
    const renderContext = {
      // eslint-disable-next-line
      canvasContext: canvas.getContext('2d') as Object,
      viewport: viewport,
    };

    canvasWrapper.appendChild(canvas);
    canvasContainerRef.current?.appendChild(canvasWrapper);

    page.render(renderContext);
    return page.pageNumber;
  }, []);

  const renderPages = useCallback(
    (pdfDoc: PDFJS.PDFDocumentProxy) => {
      // eslint-disable-next-line
      for (let num = 1; num <= pdfDoc.numPages; num++) {
        pdfDoc.getPage(num).then(renderPage);
      }
    },
    [renderPage]
  );

  useEffect(() => {
    if (pdfDoc) {
      renderPages(pdfDoc);
    }
  }, [pdfDoc, renderPages]);

  return {
    canvasContainerRef,
    pdfDoc,
    loading,
    error,
  };
};
