/* eslint-disable @typescript-eslint/naming-convention */
import { StatusTag } from 'components/core/Chips/StatusTag/StatusTag';
import { useCurrencyFormat } from 'hooks/useCurrencyFormat';
import { useTitleCase } from 'hooks/useTitleCase';
import moment from 'moment';
import {
  getApprovalsStatusSorting,
  getPayoutsEnabledSorting,
  handletraceID,
} from 'shared/utilities/utils';
import { CellContent } from '../CellElements/CellContent/CellContent';
import { DateRangeColumnFilter, MultiCheckBoxColumnFilter } from '../CellElements/Filters';
import Icon from 'components/core/Media/Icon/Icon';
import classNames from 'classnames';
import { handleVendorDefaultPayMode } from 'helpers/modules/helperVendor';
import { handleCheckStatus } from 'helpers/modules/helperInvoices';
import { CheckImageUrlModal } from 'components/core/Modals/CheckImageUrlModal/CheckImageUrlModal';

/**
 * used to handle the columns ArrayObject of Payment Batches Modal
 * @returns coloumns of Modal grid in the Payment Batches Tab
 */
export const handlePaymentBatchModalGridColumn = (
  isCreateInvoiceClicked?: boolean,
  clickHandlerIcon?: any,
  user?: any
) => {
  const columns = [
    {
      Header: '',
      accessor: 'click-arrow',
      headerClasses: 'w-[20px]',
      canFilter: false,
      disableSortBy: true,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention,react/destructuring-assignment
        const { invoices, account } = row.row.original;

        const isArrowClickable = invoices && invoices.length > 0;

        return (
          <>
            <span
              title="icon"
              className={classNames([
                'inline-flex',
                isArrowClickable
                  ? 'cursor-pointer text-primary-500 opacity-100'
                  : 'pointer-events-none opacity-50 text-grey-500',
              ])}
              onClick={() => clickHandlerIcon(invoices, account)}
            >
              <Icon iconColor="inherit" iconType="RightArrow" />
            </span>
          </>
        );
      },
    },
    {
      Header: 'VENDOR ID',
      accessor: 'identificationNumber',
      headerClasses: isCreateInvoiceClicked ? 'w-[130px]' : 'w-200px',
      Filter: MultiCheckBoxColumnFilter,
      filter: 'multiSelect',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention,react/destructuring-assignment
        const { identificationNumber } = row.row.original;
        return (
          <CellContent>
            {identificationNumber ? (
              <span className="!normal-case">{identificationNumber}</span>
            ) : null}
          </CellContent>
        );
      },
    },
    {
      Header: 'VENDOR',
      accessor: 'receivingCompanyName',
      headerClasses: isCreateInvoiceClicked ? 'w-[120px]' : 'w-[200px]',
      Filter: MultiCheckBoxColumnFilter,
      filter: 'multiSelect',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention,react/destructuring-assignment
        const { receivingCompanyName, account } = row.row.original;

        const stringVendorName = useTitleCase(receivingCompanyName);
        const getTextLength = receivingCompanyName ? stringVendorName.length : 0;
        const maxLengthCount = isCreateInvoiceClicked ? 10 : 60;
        return (
          <CellContent
            wrapperClasses={isCreateInvoiceClicked ? 'w-[100px]' : 'w-[220px]'}
            isTooltipEnabled={receivingCompanyName && getTextLength > maxLengthCount}
            tooltipIcon={'DotsHorizontalMoreIcon'}
            tooltipIconClasses="text-grey-600 top-[2px] relative -left-[5px] cursor-pointer"
            tooltipContainerClass={'left-[12px] -top-[6px] rounded-tl-lg min-w-[100px]'}
            tooltipContent={receivingCompanyName ? stringVendorName : null}
            isWordWrapped
            isLink={account ? true : false}
            linkTarget="_self"
            linkHref={`/vendors/account/${account?.id}`}
          >
            {receivingCompanyName ? stringVendorName.substring(0, maxLengthCount) : null}
          </CellContent>
        );
      },
    },
    {
      Header: 'PAYOUTS ENABLED',
      accessor: (row: any) => getPayoutsEnabledSorting(row.account),
      Filter: MultiCheckBoxColumnFilter,
      headerClasses: 'w-[180px]',
      filter: 'multiSelect',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention,react/destructuring-assignment
        const { account } = row.row.original;
        return (
          <CellContent wrapperClasses="w-[100px]">
            <StatusTag status={getPayoutsEnabledSorting(account)} />
          </CellContent>
        );
      },
    },
    {
      Header: 'PAY MODE',
      accessor: (row: any) => handleVendorDefaultPayMode(row?.transfer?.pay_mode),
      Filter: MultiCheckBoxColumnFilter,
      headerClasses: 'w-[130px]',
      filter: 'multiSelect',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention,react/destructuring-assignment
        const { transfer } = row.row.original;

        return (
          <CellContent wrapperClasses="w-[100px]">
            {transfer && transfer?.pay_mode ? handleVendorDefaultPayMode(transfer?.pay_mode) : null}
          </CellContent>
        );
      },
    },
    {
      Header: 'STATUS',
      accessor: (row: any) =>
        getApprovalsStatusSorting(
          row?.transfer?.status,
          undefined,
          row?.transfer?.pay_mode,
          row?.transfer?.check_status
        ),
      headerClasses: isCreateInvoiceClicked ? 'w-[130px]' : 'w-200px',
      Filter: MultiCheckBoxColumnFilter,
      filter: 'multiSelect',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        const { transfer } = row.row.original;
        // eslint-disable-next-line @typescript-eslint/naming-convention,react/destructuring-assignment
        const pay_mode = transfer?.pay_mode;
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const check_status = transfer?.check_status;
        let status = transfer?.status;
        if (pay_mode === 'check' && check_status !== null) {
          status = handleCheckStatus(check_status);
        }
        const check_number = transfer?.check_number ? transfer?.check_number : undefined;

        return (
          <CellContent
            wrapperClasses={`flex flex-row w-full !justify-start justify-items-start !items-start`}
            isWordWrapped
            isTooltipEnabled={pay_mode === 'check' && check_number}
            tooltipIconClasses="text-grey-600 relative -left-[5px] top-[5px] cursor-pointer"
            tooltipContainerClass={'left-[8px] -top-[2px] rounded-tl-lg !min-w-[0px] !w-[150px]'}
            tooltipContent={'Check number : ' + check_number}
          >
            {status ? <StatusTag status={status} /> : null}
          </CellContent>
        );
      },
    },
    {
      Header: 'CHECK NUMBER',
      wrapperClasses: 'w-[100px]',
      headerClasses: 'w-[140px]',
      accessor: (row: any) => handleVendorDefaultPayMode(row?.transfer?.check_number),
      canFilter: false,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention,react/destructuring-assignment
        const { transfer } = row.row.original;

        return (
          <CheckImageUrlModal
            imageURL={transfer?.check_image_url}
            checkNumer={transfer?.check_number}
            user={user}
          />
        );
      },
    },
    {
      Header: '#INVOICES',
      accessor: (row: any) => (row?.invoices ? row?.invoices.length : null),
      headerClasses: 'w-[100px]',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention,react/destructuring-assignment
        const { invoices } = row.row.original;
        return (
          <CellContent wrapperClasses="w-[90px]">{invoices ? invoices.length : null}</CellContent>
        );
      },
    },
    {
      Header: 'AMOUNT',
      accessor: 'amount',
      wrapperClasses: 'w-[80px]',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention,react/destructuring-assignment
        const { amount } = row.row.original;
        return (
          <CellContent wrapperClasses="w-[100px]">
            <span>{amount ? useCurrencyFormat(amount) : null}</span>
          </CellContent>
        );
      },
    },
    {
      Header: 'TRXN NUM',
      accessor: (row: any) => (row.transfer ? row.transfer.payup_transfer_number : null),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention,react/destructuring-assignment
        const { transfer } = row.row.original;
        return (
          <CellContent wrapperClasses="w-[90px]">
            {transfer && transfer.payup_transfer_number ? transfer.payup_transfer_number : null}
          </CellContent>
        );
      },
    },
    {
      Header: 'BATCH ID',
      accessor: (row: any) => handletraceID(row.transfer, row.traceNumber),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention,react/destructuring-assignment
        const { transfer } = row.row.original;

        return (
          <CellContent wrapperClasses="w-[80px]">
            {handletraceID(transfer, row.row.original.traceNumber)
              ? `${parseInt(handletraceID(transfer, row.row.original.traceNumber))}`
              : null}
          </CellContent>
        );
      },
    },
    {
      Header: 'BATCH CREATED',
      accessor: (row: any) => (row.transfer ? row.transfer.created_at : null),
      Filter: DateRangeColumnFilter,
      filter: 'dateBetween',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { transfer } = row.row.original;

        const createdAt =
          transfer && transfer.created_at && moment(transfer.created_at).format('MM/DD/YYYY');

        return <CellContent isWordWrapped>{createdAt}</CellContent>;
      },
    },
    {
      Header: 'PAID ON',
      accessor: (row: any) => (row.transfer ? row.transfer.paid_at : null),
      Filter: DateRangeColumnFilter,
      filter: 'dateBetween',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { transfer } = row.row.original;

        const paidAt =
          transfer && transfer.paid_at && moment(transfer.paid_at).format('MM/DD/YYYY');

        return <CellContent isWordWrapped>{paidAt}</CellContent>;
      },
    },
    {
      Header: 'SETTLED ON',
      accessor: (row: any) => (row.transfer ? row.transfer.expected_settlement_date : null),
      Filter: DateRangeColumnFilter,
      filter: 'dateBetween',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      Cell: (row) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { transfer } = row.row.original;

        let settlementAt =
          transfer &&
          transfer.expected_settlement_date &&
          moment(transfer.expected_settlement_date).format('MM/DD/YYYY');

        // eslint-disable-next-line @typescript-eslint/naming-convention
        const pay_mode = transfer && transfer.pay_mode && transfer.pay_mode;
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const check_paid_at =
          transfer &&
          transfer?.check_paid_on &&
          moment(transfer?.check_paid_on).format('MM/DD/YYYY');
        if (pay_mode === 'check') {
          if (check_paid_at) settlementAt = check_paid_at;
          else settlementAt = null;
        }

        return <CellContent isWordWrapped>{settlementAt}</CellContent>;
      },
    },
  ];

  return columns;
};
