import {
  AddRecipientDetailsModal,
  ConfirmationModal,
  DetailsDataRow,
  EditCard,
  Message,
} from 'components/core';
import Modal from '../../../../../core/Modals/Modal/Modal';
import { useState } from 'react';
import VendorProfileForm from '../../Forms/VendorProfile/VendorProfile';
import BusinessProfileForm from '../../Forms/BusinessProfile/BusinessProfile';
import { maskedInput } from '../../../../../../shared/utilities/inputHandlers';
import { getPrimaryAddressData } from '../../../../../../helpers/profileHelper';
import { getTermName } from '../../../../../../helpers/arrayFormating';
import { useTitleCase } from 'hooks/useTitleCase';
import { useUpdateVendorStatusMutation } from 'services/vendorsApi';
import { useParams } from 'react-router-dom';
import { vendorsStatusDropDownData } from 'constants/statusDropDownData';
import { handleVendorStatus } from 'helpers/modules/helperVendor';
import {
  checkIsAnyOfTheRecipentDetailsAreAddedOrNot,
  getByUserNameSorting,
} from 'shared/utilities/utils';
import moment from 'moment';
import { isPermitted } from 'services/userApi';
import { useFlags } from 'launchdarkly-react-client-sdk';

export const VendorProfileBlock = ({ vendorData, user }: any) => {
  const { id } = useParams();
  const { recipientDetailsFormEnabled } = useFlags();

  const [showEditModal, setShowEditModal] = useState(false);
  const [isPersonnel, setIsPersonnel] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [recipientDetailsModalOpen, setRecipientDetailsModalOpen] = useState(false);
  const [selectedVal, setSelectedVal] = useState('');
  const individualData = vendorData?.individual;
  const companyData = vendorData?.company;
  //get profile full address
  const [vendorPrimaryAddress, secondaryAddress] = getPrimaryAddressData(individualData);

  const fullDOB = `${
    vendorData?.individual?.dob?.day !== null ? vendorData?.individual?.dob?.day : '--'
  }/${vendorData?.individual?.dob?.month !== null ? vendorData?.individual?.dob?.month : '--'}/${
    vendorData?.individual?.dob?.year !== null ? vendorData?.individual?.dob?.year : '--'
  }`;

  const [
    updateVendors,
    { isError: updateError, error: updateErrorMessage, isLoading: updateLoading },
  ]: any = useUpdateVendorStatusMutation();

  const handleStatusChange = async (event: any) => {
    const selectedVal = event.target.getAttribute('data-val');
    setSelectedVal(selectedVal);
    setConfirmModalOpen(true);
  };

  const handleBackEvent = () => {
    setConfirmModalOpen(false);
    setSelectedVal('');
  };

  const handleConfirmButtonClick = async () => {
    await updateVendors({ id: id, status: selectedVal === 'ACTIVE' ? 'active' : 'inactive' });
    setConfirmModalOpen(false);
  };

  const setupdatedByUserName = getByUserNameSorting(vendorData, true, false);
  const setLastEdit = vendorData?.updated_at
    ? moment(vendorData?.updated_at).format('MM/DD/YYYY')
    : null;

  const [isAddressAvailable, isRecipientNameAvailable] =
    checkIsAnyOfTheRecipentDetailsAreAddedOrNot(vendorData);

  return (
    <>
      {updateError && (
        <Message type={'error'} title="Property Summary" additionalClasses="mb-6">
          {updateErrorMessage?.data?.error}
        </Message>
      )}

      <EditCard
        title="VENDOR INFO"
        onEditIconClick={() => {
          setShowEditModal(true);
          setIsPersonnel(true);
        }}
        iconType={'UserIcon'}
        iconClasses={'-ml-[6px]'}
        isEditIconEnabled={isPermitted(user, 'edit-vendor-profile')}
      >
        <DetailsDataRow
          title="Vendor’s Name"
          value={
            individualData?.first_name &&
            `${individualData?.first_name} ${individualData?.last_name}`
          }
          additionalClasses={'capitalize'}
        />
        <DetailsDataRow
          title="Email"
          value={individualData?.email}
          additionalClasses="break-words max-w-[190px]"
        />
        <DetailsDataRow
          title="Phone"
          value={maskedInput(individualData?.phone?.replace('+1', '')) ?? ''}
        />
        <DetailsDataRow
          title="Address"
          value={individualData?.address?.line1 && vendorPrimaryAddress}
        />
        <DetailsDataRow title="City" value={individualData?.address?.city && secondaryAddress} />
        <DetailsDataRow
          title="Pay Mode"
          isPayMode
          value={vendorData?.default_pay_mode}
          vendorId={id}
          isPayModeEditable={isPermitted(user, 'edit-pay-mode')}
          vendorData={vendorData}
        />
        <DetailsDataRow
          title="Net"
          value={
            vendorData?.default_payment_term_id !== null
              ? vendorData?.default_payment_term_id !== 'net30'
                ? getTermName(vendorData?.default_payment_term_id)
                : 'Net 30+'
              : ''
          }
          isTerm
        />
        <DetailsDataRow title="DOB" value={fullDOB} />
        <DetailsDataRow title="Code" value={vendorData?.code} />
        <DetailsDataRow
          title="Payouts Enabled"
          isStatus
          isShowStatusWithDropDown={false}
          value={vendorData.payouts_enabled ? 'Enabled' : 'Disabled'}
          rowWrapperClasses="mb-[14px]"
        />
        <DetailsDataRow
          title="Language"
          value={vendorData?.language}
          additionalClasses={'capitalize'}
        />
        <DetailsDataRow title="Last Edit" value={setLastEdit} />
        <DetailsDataRow
          title="Modified By"
          value={setupdatedByUserName !== '' ? useTitleCase(setupdatedByUserName) : null}
        />
        <DetailsDataRow
          title="Status"
          isStatus
          value={selectedVal !== '' ? selectedVal : handleVendorStatus(vendorData?.status)}
          onStatusChange={handleStatusChange}
          dropDownData={vendorsStatusDropDownData}
          additionalClasses={'!w-[85px] !ml-2'}
          additionalDropDownContainerClasses={'w-[101px]'}
          additionalSpaceSpanClasses={
            handleVendorStatus(vendorData?.status) === 'ACTIVE' || selectedVal === 'ACTIVE'
              ? 'inline-flex w-2'
              : 'inline-flex w-4'
          }
          iconContainerClasses={
            handleVendorStatus(vendorData?.status) === 'ACTIVE' || selectedVal === 'ACTIVE'
              ? 'w-4 inline-flex pl-1'
              : 'inline-flex'
          }
          isShowStatusWithDropDown={isPermitted(user, 'edit-vendor-status')}
          rowWrapperClasses="-mt-[3px] mb-4"
        />
      </EditCard>

      {
        /**
         * Business details
         */
        vendorData?.business_type !== 'individual' && (
          <EditCard
            title="BUSINESS DETAILS"
            onEditIconClick={() => {
              setShowEditModal(true);
              setIsPersonnel(false);
            }}
            isEditIconEnabled={isPermitted(user, 'edit-vendor-profile')}
          >
            <DetailsDataRow
              title="Company Name"
              value={companyData?.name ? useTitleCase(companyData?.name) : null}
              additionalClasses={'capitalize'}
            />
            <DetailsDataRow
              title="Phone"
              value={maskedInput(companyData?.phone?.replace('+1', '')) ?? ''}
            />
            <DetailsDataRow title="Address" value={getPrimaryAddressData(companyData)[0]} />
            <DetailsDataRow title="City" value={getPrimaryAddressData(companyData)[1]} />
            <DetailsDataRow title="Website" value={vendorData?.business_profile?.url} />
            <DetailsDataRow
              title="Industry"
              value={vendorData?.business_profile?.product_description}
            />
          </EditCard>
        )
      }

      {
        /**
         * Recipient Details
         */
        recipientDetailsFormEnabled && (
          <EditCard
            title="CHECK RECIPIENT DETAILS"
            onEditIconClick={() => {
              setRecipientDetailsModalOpen(true);
            }}
            isEditIconEnabled={isPermitted(user, 'edit-vendor-profile')}
            iconType={'InvoiceIcon'}
          >
            <DetailsDataRow
              title="Recipient Name"
              value={
                isRecipientNameAvailable
                  ? useTitleCase(vendorData?.stripe_account_metadata?.check_recipient_details_name)
                  : null
              }
              additionalClasses={'capitalize'}
            />
            <DetailsDataRow
              title="Address"
              value={isAddressAvailable ? getPrimaryAddressData(vendorData, true)[0] : null}
            />
            <DetailsDataRow
              title="City"
              value={isAddressAvailable ? getPrimaryAddressData(vendorData, true)[1] : null}
            />
            <DetailsDataRow
              title="Zip"
              value={isAddressAvailable ? getPrimaryAddressData(vendorData, true)[2] : null}
            />
          </EditCard>
        )
      }

      {confirmModalOpen && (
        <ConfirmationModal
          handleBackEvent={handleBackEvent}
          handleSubmitEvent={handleConfirmButtonClick}
          isIconLoad={updateLoading}
          mainContent={`Are you sure you want to change the status?`}
        />
      )}

      {showEditModal && (
        <Modal
          isOpen={showEditModal}
          onClose={() => setShowEditModal(false)}
          modalAdditionalClasses={'!w-[500px] items-center justify-center px-4 py-8 flex-col'}
        >
          {isPersonnel ? (
            <VendorProfileForm enableEdit={true} editVendor={setShowEditModal} />
          ) : (
            <BusinessProfileForm enableEdit={true} editVendor={setShowEditModal} />
          )}
        </Modal>
      )}

      {recipientDetailsModalOpen && (
        <AddRecipientDetailsModal
          onModalClose={() => setRecipientDetailsModalOpen(false)}
          vendorData={vendorData}
          onFormSubmitEvent={() => setRecipientDetailsModalOpen(false)}
          vendorID={id}
        />
      )}
    </>
  );
};
