import React from 'react';
import classNames from 'classnames';

import { Classes } from 'shared/configs/componentsClasses';
import { CardProps } from 'shared/models/components/base.model';

// handle Card classes according to the parameter
const handleClasses = (_cardType: string | undefined) => {
  switch (_cardType) {
    case 'lightGray':
      return Classes.card.lightGray;
    case 'lightGrayHalf':
      return Classes.card.lightGrayHalf;
    case 'gray':
      return Classes.card.gray;
    case 'white':
      return Classes.card.white;
    case 'black':
      return Classes.card.black;
    case 'whiteBorder':
      return Classes.card.whiteBorder;
    default:
      break;
  }
};

/**
 * Primary UI component for user interaction
 */
const Card: React.FC<CardProps> = ({ additionalClasses, children, cardType = 'gray', ...rest }) => {
  return (
    <div
      className={classNames([Classes.card.common, additionalClasses, handleClasses(cardType)])}
      {...rest}
    >
      {children}
    </div>
  );
};

export default Card;
