export interface AddressPOboxNoteParams {
  isTopContentVisile?: boolean;
}

export const AddressPOboxNote = ({ isTopContentVisile }: AddressPOboxNoteParams) => (
  <>
    {isTopContentVisile && (
      <p className="px-1 mt-1 mb-0 font-light text-gray-900 text-12s">
        {'Use the address where you receive post mail'}
      </p>
    )}
    <p className="px-1 mt-1 mb-1 font-light text-gray-900 text-12s">
      {
        'Your address must be a valid physical address and cannot be a P.O. Box. This address is not shared publicly and can be your personal address.'
      }
    </p>
  </>
);
