import { BankDetailRowProps } from 'shared/models/components/base.model';
import { Flags, LargeIcons } from 'shared/utilities/images';
import { StatusTag } from '../../Chips/StatusTag/StatusTag';
import { EmptyCellContent } from '../../SuperTable/CellElements/EmptyCellContent/EmptyCellContent';
import { Typhography } from '../../Typhography/Typhography';

export const BankDetailRow = ({
  title,
  value,
  isBankAccount = false,
  isCountry = false,
  additionalClasses = '',
  statusType,
  handleStatusVerificationClick,
}: BankDetailRowProps) => {
  return (
    <div className="flex flex-row items-center justify-between w-full mb-4">
      <Typhography
        component="div"
        type="h2"
        color="text-grey-700 w-[147px] min-w-[147px] !font-normal"
      >
        {title}
      </Typhography>
      {value || statusType ? (
        <div className="flex items-center justify-end">
          {isBankAccount && (
            <img src={LargeIcons.BANK_ACCOUNT} alt="USA Flag" height={'14px'} width={'15px'} />
          )}
          {isCountry && <img src={Flags.USA} alt="USA Flag" height={'16px'} width={'16px'} />}
          {!statusType && (
            <Typhography
              component="div"
              type="h2"
              color="text-grey-500 !font-normal text-right"
              additionalClasses={`ml-2 ${additionalClasses}`}
            >
              {value}
            </Typhography>
          )}
          {statusType && (
            <StatusTag
              status={statusType === 'verified' ? statusType : 'verify_now'}
              additionalClasses={
                statusType === 'verified'
                  ? 'pointer-events-none'
                  : 'pointer-events-auto cursor-pointer'
              }
              onClick={handleStatusVerificationClick}
            />
          )}
        </div>
      ) : (
        <EmptyCellContent />
      )}
    </div>
  );
};
