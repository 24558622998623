import { createApi } from '@reduxjs/toolkit/query/react';
import { prepareBaseQuery } from '../shared/configs/config';

export type Role = 'super-admin' | 'admin' | 'view-only';
export type Permission =
  | 'view-dashboard'
  | 'edit-vendor'
  | 'edit-payout'
  | 'switch-workspace'
  | 'invite-users'
  | 'change-role'
  | 'pay-payout'
  | 'add-bank-account'
  | 'import-invoices'
  | 'analytics-menu'
  | 'balance-menu'
  | 'settings-teams'
  | 'setting-balance'
  | 'setting-payment'
  | 'reconciliation-menu'
  | 'payment-tab'
  | 'transctions-menu'
  | 'paynow-option'
  | 'delete-vendor'
  | 'logedin-route'
  | 'edit-pay-mode'
  | 'edit-invoice-status'
  | 'edit-property-status'
  | 'edit-vendor-status'
  | 'edit-property-profile'
  | 'edit-vendor-profile'
  | 'update_users'
  | 'view-check-image-url';

export interface User {
  permissions: Permission[];
  first_name: string;
  phone: string;
  last_name: string;
  email: string;
  roles: Role[];
  id: string;
}

export interface UserInvite {
  customer_id: string;
  status: string;
  valid_upto: string;
  email: string;
  role: Role;
}

export const isPermitted = (user: User | undefined, p: Permission) => {
  return user?.permissions.includes(p);
};

function getPermissionsForRole(primaryRole: Role): Permission[] {
  const viewerPermissions: Permission[] = ['view-dashboard'];
  const adminPermissions: Permission[] = [
    'edit-vendor',
    'edit-payout',
    'change-role',
    'invite-users',
    'payment-tab',
    'edit-invoice-status',
    'edit-property-status',
    'edit-vendor-status',
    'edit-property-profile',
    'edit-vendor-profile',
    'view-check-image-url',
    'edit-pay-mode',
  ];
  const superAdminPermissions: Permission[] = [
    'switch-workspace',
    'pay-payout',
    'add-bank-account',
    'import-invoices',
    'analytics-menu',
    'balance-menu',
    'settings-teams',
    'reconciliation-menu',
    'setting-balance',
    'setting-payment',
    'transctions-menu',
    'paynow-option',
    'delete-vendor',
    'logedin-route',
    'edit-pay-mode',
    'edit-invoice-status',
    'edit-property-status',
    'edit-vendor-status',
    'edit-property-profile',
    'edit-vendor-profile',
    'update_users',
    'view-check-image-url',
  ];
  switch (primaryRole) {
    case 'view-only':
      return viewerPermissions;
    case 'admin':
      return viewerPermissions.concat(adminPermissions);
    case 'super-admin':
      return superAdminPermissions.concat(viewerPermissions).concat(adminPermissions);
    default:
      return [];
  }
}

export const usersApi = createApi({
  reducerPath: 'usersApi',
  tagTypes: ['Users'],
  baseQuery: prepareBaseQuery(),
  endpoints: (builder) => ({
    getUser: builder.query({
      query: (state) => ({
        url: `/user`,
        method: 'GET',
      }),
      providesTags: ['Users'],
      transformResponse: (response: User): User => {
        const permissionsForRoles = response.roles.map((r) => getPermissionsForRole(r)).flat();
        response.permissions = permissionsForRoles;
        return response;
      },
    }),
    getUserCustomerRoles: builder.query({
      query: (state) => ({
        url: `/query/user-customer-roles`,
        method: 'POST',
      }),
      providesTags: ['Users'],
    }),
    setUserRole: builder.mutation({
      query: (data) => ({
        url: `/user/${data.id}/change-role`,
        method: 'POST',
        body: {
          role: data.role,
        },
      }),
      invalidatesTags: ['Users'],
      transformResponse: (response: any): any => {
        return response;
      },
    }),
    inviteUser: builder.mutation({
      query: ({ role, email }) => ({
        url: `/user-invite`,
        method: 'POST',
        body: {
          role: role,
          email: email,
        },
      }),
      invalidatesTags: ['Users'],
    }),
    resendUserInvite: builder.mutation({
      query: ({ id }: any) => ({
        url: `/user-invite/resend/${id}`,
        method: 'POST',
      }),
    }),
    getInvite: builder.query({
      query: ({ id }) => ({
        url: `/user-invite/${id}`,
        method: 'GET',
      }),
    }),
    acceptInvite: builder.mutation({
      query: (args: {
        id: string;
        first_name: string;
        last_name: string;
        email: string;
        phone_number: string;
      }) => ({
        url: `/user-invite/accept-invite/${args.id}`,
        method: 'POST',
        body: {
          first_name: args.first_name,
          email: args.email,
          last_name: args.last_name,
          phone_number: args.phone_number,
        },
      }),
    }),
    updateUserInvite: builder.mutation({
      query: (obj) => ({
        url: `/user/${obj.id}`,
        method: 'POST',
        body: obj.body,
      }),
      invalidatesTags: ['Users'],
    }),
  }),
});

export const {
  useGetUserQuery,
  useGetUserCustomerRolesQuery,
  useSetUserRoleMutation,
  useInviteUserMutation,
  useGetInviteQuery,
  useAcceptInviteMutation,
  useResendUserInviteMutation,
  useUpdateUserInviteMutation,
} = usersApi;
