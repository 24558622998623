import classNames from 'classnames';

import { Icon, Typhography } from 'components/core';
import Modal from 'components/core/Modals/Modal/Modal';
import { useState } from 'react';

export interface TextFileModalProps {
  fileURL?: string;
  fileName?: any;
}

export const TextFileModal = ({ fileURL, fileName }: TextFileModalProps) => {
  const [textModalOpen, setTextModalOpen] = useState(false);
  const onLoad = () => {
    setTextModalOpen(!textModalOpen);
  };

  return (
    <>
      <span onClick={onLoad} className={'underline cursor-pointer text-inherit'}>
        {fileName.substring(0, 15)}
      </span>
      <Modal
        isOpen={textModalOpen}
        onClose={() => setTextModalOpen(false)}
        modalAdditionalClasses={classNames([
          'min-h-[100px] pt-7 pb-8 rounded-lg bg-white flex-col !w-[600px] px-6',
        ])}
      >
        <Typhography component="p" type="h2" color="text-grey-900 mb-6">
          {fileName}
        </Typhography>
        <div
          className="absolute cursor-pointer top-7 right-7 text-grey-600 "
          onClick={() => setTextModalOpen(false)}
        >
          <Icon iconColor="inherit" iconType="CloseIcon" />
        </div>
        <div className="relative flex w-full h-auto">
          <iframe
            width="100%"
            height={'350px'}
            title="textFilemodal"
            id="textFilemodal"
            src={fileURL}
            className="relative flex w-full overflow-y-auto"
          ></iframe>
        </div>
      </Modal>
    </>
  );
};
