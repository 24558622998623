/* eslint-disable no-unused-vars */
import { Grid } from '../../Grid/Grid';
import { StatusBlock } from '../../StatusBlock/StatusBlock';
import { helperPropertiesStats } from '../../../../helpers/modules/helperProperties';
import { PropertiesStatsBarProps } from 'shared/models/components/base.model';
import { useCurrencyFormat } from 'hooks/useCurrencyFormat';

export const ActivePropertiesStatsBar = ({
  dateRange,
  propertiesData,
}: PropertiesStatsBarProps) => {
  const [
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    delinquentTrending,
    activeTrending,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    inactiveTrending,
    isTrendlineLoading,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    delinquentData,
    activePropertyData,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    inActivePropertyData,
  ]: any = helperPropertiesStats(dateRange);

  const isTrendlineVisible = dateRange === 'All' || dateRange === '500' || dateRange === 'YTD';

  const getAmountInTransaction = propertiesData?.filteredTotalAmountInTransition ?? 0;
  const getTotalAmountReceived = propertiesData?.filteredTotalAmountReceived ?? 0;
  //const getAccountDebitsCompleted = propertiesData?.accountDebitsCompleted ?? 0;
  const getTotalPayoutsCompleted = propertiesData?.filteredTotalPayoutsCompletedSum ?? 0;

  const geTotalPayoutsCompletedCount = propertiesData?.filteredTotalPayoutsCompletedCount ?? 0;
  const setTotalPayoutsCompletedCount =
    geTotalPayoutsCompletedCount === 0 ? `0` : geTotalPayoutsCompletedCount;

  const isAmountDigitsLengthGreaterThanThree =
    ('' + getAmountInTransaction).length > 3 ||
    ('' + getTotalAmountReceived).length > 3 ||
    ('' + getTotalPayoutsCompleted).length > 3;

  const handleFirstValueClasses = isAmountDigitsLengthGreaterThanThree ? '!text-20s' : '!text-24s';
  const handleSecondValueClasses = isAmountDigitsLengthGreaterThanThree ? '!text-12s' : '!text-13s';
  const handleThirdValueClasses = isAmountDigitsLengthGreaterThanThree ? '!text-12s' : '!text-13s';

  return (
    <>
      {!isTrendlineLoading && (
        <Grid columnCount={4} additionalClasses={'w-full mb-2'}>
          {/**
           * Active Properties
           */}
          <StatusBlock
            firstValue={
              activePropertyData?.properties?.totalCount === 0
                ? `0`
                : activePropertyData?.properties?.totalCount
            }
            secondValue={
              !isTrendlineVisible
                ? `${activeTrending > 0 ? '+' : ''}${activeTrending?.toString()}`
                : ''
            }
            thirdValue={!isTrendlineVisible ? `${dateRange}` : null}
            blockHeading={'Active Properties'}
            iconType={activeTrending <= 0 ? 'DecreaseMode' : 'IncreaseMode'}
            iconColor={
              activeTrending <= 0
                ? activeTrending === 0
                  ? 'text-grey-400'
                  : 'text-green-400'
                : 'text-red-400'
            }
            secondValueColor={
              activeTrending <= 0
                ? activeTrending === 0
                  ? 'text-grey-400'
                  : 'text-green-400'
                : 'text-red-400'
            }
            isRemoveBorderPadding
            isIconVisible={!isTrendlineVisible}
            firstValueClasses={handleFirstValueClasses}
            secondValueClasses={handleSecondValueClasses}
            thirdValueClasses={handleThirdValueClasses}
          />
          {/**
           * Amount in transition
           */}
          <StatusBlock
            firstValue={useCurrencyFormat(getAmountInTransaction)}
            thirdValue={!isTrendlineVisible ? dateRange : null}
            blockHeading={'Amount in transition'}
            isIconVisible={false}
            firstValueClasses={handleFirstValueClasses}
            secondValueClasses={handleSecondValueClasses}
            thirdValueClasses={handleThirdValueClasses}
          />
          {/**
           * Account Debits Completed
           */}
          {/* <StatusBlock
            firstValue={useCurrencyFormat(getAccountDebitsCompleted)}
            thirdValue={!isTrendlineVisible ? dateRange : null}
            blockHeading={'Account Debits Completed'}
            isIconVisible={false}
            firstValueClasses={handleFirstValueClasses}
            secondValueClasses={handleSecondValueClasses}
            thirdValueClasses={handleThirdValueClasses}
          /> */}
          {/**
           * Total Amount Received
           */}
          <StatusBlock
            firstValue={useCurrencyFormat(getTotalAmountReceived)}
            thirdValue={!isTrendlineVisible ? dateRange : null}
            blockHeading={'Total Amount Received'}
            isIconVisible={false}
            firstValueClasses={handleFirstValueClasses}
            secondValueClasses={handleSecondValueClasses}
            thirdValueClasses={handleThirdValueClasses}
          />
          {/**
           * Total Payouts Completed
           */}
          <StatusBlock
            firstValue={`${setTotalPayoutsCompletedCount} | ${useCurrencyFormat(
              getTotalPayoutsCompleted
            )}`}
            thirdValue={!isTrendlineVisible ? dateRange : null}
            blockHeading={'Total Payouts Completed'}
            isIconVisible={false}
            firstValueClasses={handleFirstValueClasses}
            secondValueClasses={handleSecondValueClasses}
            thirdValueClasses={handleThirdValueClasses}
          />
        </Grid>
      )}
    </>
  );
};
