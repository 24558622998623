import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import {
  Button,
  ConfirmationModal,
  Icon,
  ToolBar,
  TopPaymentBar,
  Typhography,
} from 'components/core';

import Modal from 'components/core/Modals/Modal/Modal';
import { handlePaymentBatchModalGridColumn } from 'components/core/SuperTable/Columns/PaymentBatchModalGridColumn';
import SuperTable from 'components/core/SuperTable/SuperTable';
import { Classes } from 'shared/configs/componentsClasses';
import { helperTransactions } from 'helpers/modules/helperTransactions';
import { isPermitted, useGetUserQuery } from 'services/userApi';
import ListEmpty from 'components/modules/Common/ListEmpty/ListEmpty';
import { helperApprove } from 'helpers/modules/helperInvoices';
import { handleDataModalInvoicesColumns } from 'components/core/SuperTable/Columns/DataModalInvoicesColumn';
import { getNameFromAccount } from 'shared/utilities/utils';
export interface DataModalProps {
  onCloseEvent?: () => void;
  onSubmitEvent?: () => void;
  data?: any;
  sources?: any;
  fileName?: string;
  totalCount?: string | number;
  selectedSource?: string;
  isReadOnlyModal?: boolean;
  setSelectedSource?: any;
  isCreateInvoiceClicked?: boolean;
  propertyName?: string;
  setModalOpen?: any;
}

export const DataModal = ({
  onCloseEvent,
  data,
  sources,
  onSubmitEvent,
  totalCount,
  fileName,
  selectedSource,
  isReadOnlyModal,
  setSelectedSource,
  isCreateInvoiceClicked = false,
  propertyName,
  setModalOpen,
}: DataModalProps) => {
  const isFileUploadOnly = isReadOnlyModal || isCreateInvoiceClicked;
  const [tableColumns, handleIconClick] = helperApprove('BATCHES-INVOICES');

  const [isSourceSelected, setSourceSelected] = useState(false);
  const tableData = React.useMemo(() => data, [data]);
  const [defaultSortOrder] = useState({ id: 'identificationNumber', desc: true });
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const { data: user } = useGetUserQuery({});

  const handleConfirmButtonClick = () => {
    setConfirmationModalOpen(true);
  };

  const handleConfirmationModalClose = () => {
    setConfirmationModalOpen(false);
    setVisibleInvoices(false);
    setSelectedInvoices([]);
  };

  const [getTransferAmount] = helperTransactions();
  const [enableSelect, setEnableSelect] = useState(false);
  // eslint-disable-next-line
  const [selectedRows, setSelectedRows] = useState([]);
  const [tlAmount, setTlAmount]: any = useState();
  const [selectedIds, setSelectedIds]: any = useState();

  const [selectedInvoices, setSelectedInvoices]: any = useState([]);
  const [visibleInvoices, setVisibleInvoices] = useState(false);
  const [visibleColumns, setVisibleColumns] = useState([]);
  const [filteredRow, setFilteredRow] = useState([]);
  const [columnList, setColumnList]: any = useState([]);
  const [toggleHiddenColumnChanged, setToggleHiddenColumnChanged] = useState(false);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    getTransferAmount(selectedRows, setTlAmount, setSelectedIds, true);
  }, [selectedRows]);

  const handlePaynowOption = () => {
    setEnableSelect(true);
    setSelectedIds(null);
    setSelectedRows([]);
  };

  const onClickArrowHandler = (invoices?: any, account?: any) => {
    const getInvoices = [...invoices];

    const newArrayOfInvoices = getInvoices.map(function (ele) {
      return { ...ele, vendor_name: getNameFromAccount(account) };
    });

    setSelectedInvoices(newArrayOfInvoices);
    setVisibleInvoices(true);
  };

  const onClickBackEvent = () => {
    setVisibleInvoices(false);
    setSelectedInvoices([]);
  };

  const columns = React.useMemo(
    () => handlePaymentBatchModalGridColumn(isFileUploadOnly, onClickArrowHandler, user),
    [data, enableSelect]
  );

  const invoicesColumns = React.useMemo(
    () => handleDataModalInvoicesColumns({ user: user }),
    [selectedInvoices, visibleInvoices, toggleHiddenColumnChanged]
  );

  const getCount =
    data &&
    data.filter(
      (item: any) => item.account?.payouts_enabled && !item?.transfer?.paid_at && item.transfer?.id
    ).length;

  const getColumnsList = () => {
    setColumnList(tableColumns);
  };

  const hdColList = [
    ...columnList.filter((item: any) => item.isHidden).map((val: any) => val.accessor),
  ];

  useEffect(() => {
    getColumnsList();
  }, [selectedInvoices]);

  const handleModalCloseEvent = () => {
    onCloseEvent && onCloseEvent();
    onClickBackEvent();
  };

  return (
    <>
      <Modal
        isOpen={true}
        onClose={handleModalCloseEvent}
        modalAdditionalClasses={classNames([
          'pt-7 pb-8 rounded-lg bg-white flex-col  px-6 w-full',
          isFileUploadOnly
            ? '!max-w-[1400px] min-h-[600px] overflow-hidden'
            : '!w-[800px] min-h-[200px]',
        ])}
      >
        <div
          className="absolute z-40 cursor-pointer top-7 right-7 text-grey-600"
          onClick={handleModalCloseEvent}
        >
          <Icon iconColor="inherit" iconType="CloseIcon" />
        </div>
        <div
          className={classNames([
            'relative flex w-full',
            isFileUploadOnly ? 'h-[630px]' : 'h-auto',
          ])}
        >
          {isFileUploadOnly && (
            <div
              className={classNames([
                'flex flex-col w-full transition-all',
                visibleInvoices ? 'left-0 right-0' : '-left-[110%] right-auto',
                isFileUploadOnly ? 'absolute top-0 bottom-0' : 'relative',
              ])}
            >
              <div
                className="absolute top-0 left-0 z-40 cursor-pointer text-primary-500"
                onClick={onClickBackEvent}
              >
                <Icon
                  iconColor="inherit"
                  iconType="RightArrow"
                  additionalClasses="relative rotate-180 !stroke-2"
                />
              </div>
              <Typhography
                component="p"
                type="h2"
                color="text-grey-900 mb-0 uppercase text-center mb-[20px]"
              >
                {`INVOICES`}
              </Typhography>
              <div className="flex items-center justify-between w-full">
                <Typhography
                  component="div"
                  type="h2"
                  additionalClasses="!font-normal mb-3"
                  color="text-grey-900"
                >
                  {`Below, you can find invoices for the selected batch and payout.`}
                </Typhography>
                <div className="inline-flex w-[200px] justify-end items-center">
                  <ToolBar
                    isShowOptionEnabled={true}
                    filteredRow={filteredRow}
                    visibleColumns={visibleColumns}
                    columns={columnList}
                    // isNavigationEnabled={false}
                    isDownloadCsvButtonEnabled={true}
                    handleIconClick={(item: any) => {
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      handleIconClick(
                        item,
                        columnList,
                        setColumnList,
                        setToggleHiddenColumnChanged,
                        toggleHiddenColumnChanged
                      );
                    }}
                  />
                </div>
              </div>

              {selectedInvoices && selectedInvoices.length !== 0 ? (
                <SuperTable
                  columns={invoicesColumns}
                  hiddenColumns={hdColList}
                  data={selectedInvoices}
                  isLink={false}
                  subTabType={'data-modal-invoices'}
                  tableWrapperType="grid-modal-medium"
                  isPagination={true}
                  editNetTerm={true}
                  hiddenColumnList={hdColList}
                  tableType="batchesInvoices"
                  setVisibleColumns={setVisibleColumns}
                  setFilteredRow={setFilteredRow}
                  toggleHiddenColumnChanged={toggleHiddenColumnChanged}
                  paginationOptions={[25, 50, 75, 100]}
                />
              ) : (
                <ListEmpty />
              )}
            </div>
          )}
          <div
            className={classNames([
              'flex flex-col w-full transition-all',
              visibleInvoices ? 'left-[110%] right-auto' : 'left-0 right-0',
              isFileUploadOnly ? 'absolute top-0 bottom-0' : 'relative',
            ])}
          >
            {enableSelect && (
              <TopPaymentBar
                selectedRowsLength={selectedRows?.length}
                setEnableSelect={setEnableSelect}
                totalAmount={tlAmount}
                ids={selectedIds}
                isTransfer
                isFromTransactionModal
                isFixed={false}
                setModalOpen={setModalOpen}
                additionalClasses="-top-7 -left-6 -right-6 w-auto"
              />
            )}

            <Typhography component="p" type="h2" color="text-grey-900 mb-6 uppercase text-center">
              {`PAYOUT BATCHES`}
            </Typhography>

            {(isReadOnlyModal || isCreateInvoiceClicked) && (
              <div className="flex items-center justify-between w-full mb-6">
                <Typhography
                  component="div"
                  type="h2"
                  additionalClasses="!font-normal"
                  color="text-grey-900"
                >
                  {`Below are the payment batches and corresponding vendors as part of the NACHA file. Payouts can be made for vendors onboarded on PayUp.`}
                </Typhography>
                {isPermitted(user, 'paynow-option') && (
                  <>
                    <Button
                      type="button"
                      variants="extraSmall"
                      buttonLabel="Pay Now"
                      additionalClasses="w-[80px] ml-4"
                      onClick={handlePaynowOption}
                      isDisabled={enableSelect || totalCount === 0 || getCount === 0}
                    />
                  </>
                )}
              </div>
            )}

            <div className="flex flex-col w-full">
              <SuperTable
                columns={columns}
                hiddenColumns={
                  !isReadOnlyModal && !isCreateInvoiceClicked
                    ? [
                        'PAYOUTS ENABLED',
                        'TRXN NUM',
                        'BATCH CREATED',
                        'PAID ON',
                        'SETTLED ON',
                        'BATCH ID',
                        '#INVOICES',
                        'click-arrow',
                        'PAY MODE',
                      ]
                    : enableSelect
                    ? ['click-arrow']
                    : []
                }
                data={tableData}
                defaultSortBy={defaultSortOrder}
                paginationOptions={[25, 50, 75, 100]}
                tableWrapperType="grid-modal"
                isPagination
                tableType="transactionsModal"
                enableSelect={enableSelect}
                setSelectedRows={setSelectedRows}
              ></SuperTable>
              <div className="flex flex-col items-center justify-center w-full pt-6">
                {!isReadOnlyModal && !isCreateInvoiceClicked && (
                  <>
                    <select
                      name="payment-sources"
                      className={classNames([Classes.select.default, 'w-[300px] mb-4 capitalize'])}
                      onChange={(event) => {
                        selectedSource = event.target.selectedOptions[0].id;
                        if (selectedSource) {
                          setSourceSelected(true);
                          setSelectedSource(event.target.selectedOptions[0].id);
                        } else {
                          setSourceSelected(false);
                        }
                      }}
                    >
                      <option>Please choose one bank account</option>
                      {sources.map((option: any, index: number) => {
                        const last4 = option.us_bank_account?.last4 ?? option.last4;
                        const name = option.account_holder_name ?? option.billing_details?.name;
                        return (
                          <option key={index} id={option.id}>
                            {name + ' ' + last4}
                          </option>
                        );
                      })}
                    </select>
                    <Button
                      type={'button'}
                      buttonLabel={'Confirm'}
                      variants="default"
                      additionalClasses={'w-[300px] mb-4'}
                      iconType="Spin"
                      iconClass="inline-flex ml-1 w-3 h-3"
                      isIconEnabled={false}
                      iconAlignment={'right'}
                      onClick={handleConfirmButtonClick}
                      isDisabled={!isSourceSelected}
                    />
                    <span
                      className="inline-flex mr-0 font-bold cursor-pointer text-14s text-black-900"
                      onClick={handleModalCloseEvent}
                    >
                      Cancel
                    </span>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {confirmationModalOpen && (
        <ConfirmationModal
          mainContent={`Are you sure you want to process this batch file against ${propertyName}`}
          handleSubmitEvent={onSubmitEvent}
          handleBackEvent={handleConfirmationModalClose}
        />
      )}
    </>
  );
};
