// import React, { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useParams } from 'react-router-dom';

import { ERROR_MESSAGES } from '../../../../../constants/Validation';
import { BankDetailsParams } from '../../../../../shared/utilities/interface';
import { Classes } from '../../../../../shared/configs/componentsClasses';
import { useCreateBankAccountMutation } from '../../../../../services/vendorsApi';

import Message from 'components/core/Message/Message';
import LoadingSpinner from 'components/core/LoadingSpinner/LoadingSpinner';
import { Typhography, Button, Input, Checkbox } from 'components/core';

interface BankDetailsProps {
  setUpdateAllList?: any;
  updateAllList?: any;
  onClose?: any;
}

export const BankDetails = ({ setUpdateAllList, updateAllList, onClose }: BankDetailsProps) => {
  const { id: vendorId } = useParams();
  const inputDefaultClasses = Classes.input.default;
  const [createBankAccount, { isLoading, isError, error, isSuccess }]: any =
    useCreateBankAccountMutation();

  const schema = yup
    .object({
      account_holder_name: yup.string().required(ERROR_MESSAGES.REQUIRED),
      account_number: yup
        .string()
        .required(ERROR_MESSAGES.REQUIRED)
        .min(5, ERROR_MESSAGES.ACCOUNT_NUMBER_INVALID)
        .max(17, ERROR_MESSAGES.ACCOUNT_NUMBER_INVALID),
      account_number_conf: yup
        .string()
        .required(ERROR_MESSAGES.REQUIRED)
        .min(5, ERROR_MESSAGES.ACCOUNT_NUMBER_INVALID)
        .max(17, ERROR_MESSAGES.ACCOUNT_NUMBER_INVALID)
        .oneOf([yup.ref('account_number')], 'Account number does not match'),
      routing_number: yup
        .string()
        .required(ERROR_MESSAGES.REQUIRED)
        .min(9, ERROR_MESSAGES.SSN_INVALID_FULL)
        .max(9, ERROR_MESSAGES.SSN_INVALID_FULL),
      nickname: yup.string().required(ERROR_MESSAGES.REQUIRED),
    })
    .required();

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<BankDetailsParams>({
    resolver: yupResolver(schema),
    mode: 'onBlur', //used to check error in realtime
    shouldFocusError: true,
  });

  const onSubmitNewPaymentMethod = async (data: any) => {
    const response = await createBankAccount({
      account_holder_name: data.account_holder_name,
      routing_number: data.routing_number,
      account_number: data.account_number,
      default_for_currency: data.default_for_currency,
      nickname: data.nickname,
      force: false,
      id: vendorId,
    });

    if (response) {
      setUpdateAllList(!updateAllList);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const getError = response?.error;

      if (getError) {
        setValue('account_holder_name', '');
        setValue('routing_number', '');
        setValue('account_number', '');
        setValue('account_number_conf', '');
        setValue('nickname', '');
      } else {
        setTimeout(() => {
          onClose();
        }, 2000);
      }
    }
  };

  const handleError = () => {
    let errorMessage;

    if (isError && error?.data?.error) {
      errorMessage = error?.data?.error;
    } else if (isError && error?.data?.message) {
      errorMessage = error?.data?.message;
    }

    return errorMessage;
  };

  return (
    <>
      {isLoading && <LoadingSpinner />}
      <Typhography component="p" type="h2" color="text-grey-900 mb-3 uppercase">
        {'Add Your account Details'}
      </Typhography>

      <form
        onSubmit={handleSubmit(onSubmitNewPaymentMethod)}
        className={'w-full flex flex-col max-w-3xl'}
        noValidate
      >
        <div className="flex flex-col w-full min-h-[442px] mb-5 overflow-y-auto pt-3">
          {(isError || isSuccess) && (
            <Message
              title="Manual Bank Setup"
              type={isSuccess ? 'success' : 'error'}
              additionalClasses={'mb-6'}
            >
              {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                isSuccess ? 'Thanks! your account has been successfully created.' : handleError()
              }
            </Message>
          )}

          <Controller
            control={control}
            name="account_holder_name"
            render={({ field: { value } }) => (
              <Input
                label="Account holder name"
                labelName={'account_holder_name'}
                type="text"
                {...register('account_holder_name')}
                className={inputDefaultClasses}
                error={errors?.account_holder_name?.message}
                required
                value={value}
              />
            )}
          />

          <Controller
            control={control}
            name="account_number"
            render={({ field: { value } }) => (
              <Input
                label={'Account number'}
                labelName={'account_number'}
                type="text"
                {...register('account_number')}
                className={inputDefaultClasses}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                error={errors?.account_number?.message}
                maxLength={17}
                additionalClasses={`text-14s w-[334px]`}
                required
                value={value}
              />
            )}
          />
          <Controller
            control={control}
            name="account_number_conf"
            render={({ field: { value } }) => (
              <Input
                label={'Confirm account number'}
                labelName={'account_number_conf'}
                type="text"
                {...register('account_number_conf')}
                className={inputDefaultClasses}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                error={errors?.account_number_conf?.message}
                maxLength={17}
                required
                value={value}
              />
            )}
          />
          <Controller
            control={control}
            name="routing_number"
            render={({ field: { value } }) => (
              <Input
                label="Routing number"
                labelName={'routing_number'}
                type="text"
                {...register('routing_number')}
                className={inputDefaultClasses}
                onKeyPress={(event: { key: string; preventDefault: () => void }) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                error={errors?.routing_number?.message}
                maxLength={9}
                required
                value={value}
              />
            )}
          />
          <Controller
            control={control}
            name="nickname"
            render={({ field: { value } }) => (
              <Input
                label="Nickname"
                labelName={'nickname'}
                type="text"
                {...register('nickname')}
                className={inputDefaultClasses}
                error={errors?.nickname?.message}
                required
                value={value}
              />
            )}
          />

          <Checkbox
            id="check"
            register={register}
            name="default_for_currency"
            label="Make this my default payment method."
          />
        </div>

        <div className="flex items-center w-full">
          <Button
            type="submit"
            buttonLabel={`Add Account`}
            iconType="Spin"
            isIconEnabled={false}
            additionalClasses={'w-full'}
          />
        </div>
      </form>
    </>
  );
};
