import PastContainer from 'components/modules/Balance/Days/Past';
import PastWeekContainer from 'components/modules/Balance/Weeks/Past/Past';

/**
 * Primary UI component for user interaction
 */
const Past = () => {
  const getLocalStorageSettingItem = localStorage.getItem('balance_settings');
  return (
    <>
      {getLocalStorageSettingItem && getLocalStorageSettingItem === 'week' ? (
        <PastWeekContainer />
      ) : (
        <PastContainer />
      )}
    </>
  );
};

export default Past;
