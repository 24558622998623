import {
  IPayModeSetModal,
  IFuncHandlePayModeEditPosibilities,
} from 'shared/models/components/base.model';
import { handleShowIconClick } from 'shared/utilities/utils';

export const helperApprove = (type: string) => {
  const isPaymentbatchesInvoices: boolean = type === 'BATCHES-INVOICES';
  // set constant for Venodor-All-Invoices table check
  const isOnlyForVendorInvoicesTable: boolean = type === 'VENDOR_INVOICES';
  // set constant for Property-All-Invoices table check
  const isOnlyForPropertyInvoicesTable: boolean = type === 'PROPERTIES_INVOICES';
  // set constant for Property-All-Invoices table check
  const isOnlyForApprovedInvoicesTable: boolean = type === 'APPROVED';
  // set constant for Property-All-Invoices table check
  const isOnlyForToApprovedInvoicesTable: boolean = type === 'TO-APPROVED';
  // set constant for Property-All-Invoices table check
  const isOnlyForAllInvoices: boolean = type === 'ALL';

  // set inital empty array
  let tableColumns = [];

  // set default tableColumns array
  tableColumns = [
    {
      Header: 'Invoiced',
      accessor: 'invoiced_at',
      isHidden: false,
      order: 1,
    },
    {
      Header: 'PayUp ID',
      accessor: 'number',
      isHidden: false,
      order: 3,
    },
    {
      Header: 'Invoice',
      accessor: 'coupa_invoice_number',
      isHidden: false,
      order: 4,
    },
    {
      Header: 'Description',
      accessor: 'description',
      isHidden: true,
      order: 4,
    },
    {
      Header: 'Status',
      accessor: 'STATUS',
      isHidden: false,
      order: 6,
    },
    {
      Header: 'Approval Due',
      accessor: 'estimated_approved_at',
      isHidden: true,
      order: 7,
    },
    {
      Header: 'Net',
      accessor: 'payout_term_id',
      isHidden: true,
      order: 9,
    },
    {
      Header: 'Exp. Arrival',
      accessor: 'estimated_arrived_on',
      isHidden: false,
      order: 16,
    },
    {
      Header: 'Invoice Amount',
      accessor: 'amount',
      isHidden: false,
      order: 22,
    },
    {
      Header: 'Payout Amount',
      accessor: 'payout_amount',
      isHidden: false,
      order: 23,
    },
    {
      Header: 'Discount Fee',
      accessor: 'payout_term_amount',
      isHidden: true,
      order: 24,
    },
    {
      Header: 'Draw Account',
      accessor: 'coupa_draw_account',
      isHidden: true,
      order: 25,
    },
  ];

  if (!isOnlyForPropertyInvoicesTable && !isPaymentbatchesInvoices) {
    const tableAllColumns = [
      {
        Header: 'Property',
        accessor: 'property.name',
        isHidden: false,
        order: 5,
      },
    ];

    tableColumns = [...tableColumns, ...tableAllColumns];
  }

  if (!isOnlyForVendorInvoicesTable && !isPaymentbatchesInvoices) {
    const tableAllColumns = [
      {
        Header: 'Vendor',
        accessor: `VENDOR`,
        isHidden: false,
        order: 2,
      },
    ];

    tableColumns = [...tableColumns, ...tableAllColumns];
  }

  // set the the columns for except Approval-Toapprove tab related payout tables
  if (!isOnlyForToApprovedInvoicesTable) {
    const tableColumnsApproved = [
      {
        Header: 'Approved',
        accessor: 'approved_at',
        isHidden: false,
        order: 8,
      },
      {
        Header: 'Vendor due',
        accessor: 'VENDOR DUE',
        isHidden: true,
        order: 10,
      },
      {
        Header: 'Sceduled',
        accessor: 'payout_scheduled_at',
        isHidden: true,
        order: 11,
      },
      {
        Header: 'Paid On',
        accessor: 'paid_at',
        isHidden: isOnlyForApprovedInvoicesTable ? true : false,
        order: 12,
      },
      {
        Header: 'Paid By',
        accessor: 'PAID BY',
        isHidden: isOnlyForApprovedInvoicesTable ? true : false,
        order: 13,
      },
      {
        Header: 'PayUp due',
        accessor: 'customer_payment_due_at',
        isHidden: true,
        order: 14,
      },
      {
        Header: 'Check Date',
        accessor: 'CHECK DATE',
        isHidden: true,
        order: 15,
      },
      {
        Header: 'Received On',
        accessor: 'customer_paid_at',
        isHidden: isOnlyForVendorInvoicesTable ? true : true,
        order: 17,
      },
      {
        Header: 'AR Mode',
        accessor: 'AR MODE',
        isHidden:
          isOnlyForPropertyInvoicesTable ||
          isOnlyForVendorInvoicesTable ||
          isPaymentbatchesInvoices ||
          isOnlyForApprovedInvoicesTable
            ? true
            : false,
        order: 18,
      },
      {
        Header: 'Pay Ref',
        accessor: 'PAY REF',
        isHidden: true,
        order: 19,
      },
      {
        Header: 'Paid Amount',
        accessor: 'paid_amount',
        isHidden: false,
        order: 24,
      },
      {
        Header: 'Processing Fee',
        accessor: 'processing_fee_amount',
        isHidden: false,
        order: 25,
      },
    ];

    tableColumns = [...tableColumns, ...tableColumnsApproved];
  }

  if (isOnlyForVendorInvoicesTable || isOnlyForPropertyInvoicesTable || isOnlyForAllInvoices) {
    const tableColumnsOnlyForAllAndPaidnvoices = [
      {
        Header: 'Pay Mode',
        accessor: 'pay_mode',
        isHidden: false,
        order: 20,
      },
      {
        Header: 'Check Number',
        accessor: 'check_number',
        isHidden: false,
        order: 21,
      },
    ];
    tableColumns = [...tableColumns, ...tableColumnsOnlyForAllAndPaidnvoices];
  }

  // reorder the table colomns according to the order property value
  tableColumns = [...tableColumns].sort(
    (a: any, b: any) => parseFloat(a.order) - parseFloat(b.order)
  );

  // used to GetInvoice amount
  const getInvoiceAmount = (selectedRows: any, setTlAmount: any, setSelectedIds: any) => {
    const totalAmount = selectedRows.reduce((previousVal: any, currentVal: any) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const crntVal = parseFloat(currentVal?.original?.amount);

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const preVal = parseFloat(previousVal);
      return (preVal + crntVal).toFixed(2);
    }, 0);
    setTlAmount(totalAmount);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const selecIds = selectedRows.map((item) => item.original?.id);

    setSelectedIds(selecIds);
  };

  const handleIconClick = (
    item: any,
    columnList: any,
    setColumnList: any,
    setToggleHiddenColumnChanged: any,
    toggleHiddenColumnChanged: any
  ) => {
    handleShowIconClick(
      item,
      columnList,
      setColumnList,
      setToggleHiddenColumnChanged,
      toggleHiddenColumnChanged
    );
  };

  return [tableColumns, handleIconClick, getInvoiceAmount];
};

export const handleTheStatusText = (payoutStatus?: string, payoutScheduledAt?: string) => {
  let buttonText = payoutStatus;

  if (payoutStatus === 'APPROVED' && payoutScheduledAt) {
    buttonText = 'Scheduled';
  }

  return buttonText;
};

export const handleCheckStatus = (checkStatus?: string) => {
  let checkStatusText = checkStatus;

  if (checkStatus === 'PAID') {
    checkStatusText = 'CHECK_PAID';
  }

  return checkStatusText;
};

export const handlePayModeEditPosibilities = ({ status, pay_mode }: IPayModeSetModal) => {
  let obj: IFuncHandlePayModeEditPosibilities = {
    is_editable: false,
    is_set_external: false,
  };

  if (
    (pay_mode === 'external' ||
      pay_mode === 'EXTERNAL' ||
      pay_mode === 'ACH' ||
      pay_mode === 'ach') &&
    status === 'APPROVED'
  ) {
    obj = { ...obj, is_editable: true };
  }

  if ((pay_mode === 'external' || pay_mode === 'EXTERNAL') && status === 'PAID') {
    obj = { is_editable: true, is_set_external: true };
  }

  return obj;
};

export const removeShowListOptionsLocalStorages = () => {
  localStorage.removeItem('showOptionSubmittedList');
  localStorage.removeItem('showOptionApprovedList');
  localStorage.removeItem('showOptionPaidList');
  localStorage.removeItem('showOptionAllList');
  localStorage.removeItem('showOptionVendorList');
};

export const removeLogoutLocalStorages = () => {
  removeShowListOptionsLocalStorages();
  localStorage.removeItem('id_token');
  localStorage.removeItem('user_authentication');
  localStorage.removeItem('user_number');
  localStorage.removeItem('balance_settings');
};
