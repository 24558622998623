import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { useParams } from 'react-router-dom';

import { Grid, StatusBlock, Typhography } from 'components/core';
import EmptyList from 'components/core/EmptyList/EmptyList';

import { VendorSummaryGraphProps } from 'shared/models/components/base.model';
import { useCurrencyFormat, useCurrencyFormatWithoutCents } from 'hooks/useCurrencyFormat';

import { useGetVendorsSummaryGraphDataQuery } from 'services/vendorsApi';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

export const SummaryInvoiceGraph = ({
  rangeSelector = 30,
  getGreaterThanDate,
  getLessThanDate,
}: VendorSummaryGraphProps) => {
  const { id } = useParams();

  /************************
   * paid data query
   ************************/
  const paidGraphFilterParams = {
    filter: {
      id: { equalTo: JSON.stringify(id) },
      payoutsByAccountId: {
        paid_at: {
          lessThan: getLessThanDate,
          greaterThan: getGreaterThanDate,
        },
      },
    },
    groupBy: 'PAID_AT_TRUNCATED_TO_DAY_CST',
  };

  const { data: paidGraphData, isSuccess: isPaidGraphDataSuccess } =
    useGetVendorsSummaryGraphDataQuery(paidGraphFilterParams);
  const getPaidNodes =
    isPaidGraphDataSuccess && paidGraphData.accounts.nodes[0].payoutsByAccountId.groupedAggregates;

  /************************
   * Submitted data query
   ************************/
  const submittedGraphFilterParams = {
    filter: {
      id: { equalTo: JSON.stringify(id) },
      payoutsByAccountId: {
        invoiced_at: {
          lessThan: getLessThanDate,
          greaterThan: getGreaterThanDate,
        },
      },
    },
    groupBy: 'INVOICED_AT_TRUNCATED_TO_DAY_CST',
  };

  const { data: submittedGraphData, isSuccess: isSubmittedGraphDataSuccess } =
    useGetVendorsSummaryGraphDataQuery(submittedGraphFilterParams);
  const getSubmittedNodes =
    isSubmittedGraphDataSuccess &&
    submittedGraphData.accounts.nodes[0].payoutsByAccountId.groupedAggregates;

  /************************
   * Approved data query
   ************************/
  const approvedGraphFilterParams = {
    filter: {
      id: { equalTo: JSON.stringify(id) },
      payoutsByAccountId: {
        approved_at: {
          lessThan: getLessThanDate,
          greaterThan: getGreaterThanDate,
        },
      },
    },
    groupBy: 'APPROVED_AT_TRUNCATED_TO_DAY_CST',
  };

  const { data: approvedGraphData, isSuccess: isApprovedGraphDataSuccess } =
    useGetVendorsSummaryGraphDataQuery(approvedGraphFilterParams);
  const getApprovedNodes =
    isApprovedGraphDataSuccess &&
    approvedGraphData.accounts.nodes[0].payoutsByAccountId.groupedAggregates;

  const [finalChartDataSet, setFinalChartDataSet]: any = useState(null);
  const [chartlabels, setChartLabels]: any = useState(null);
  const allOptionValue = 500;

  const options = {
    elements: {
      point: {
        radius: 1,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        displayColors: true,
        intersect: true,
        backgroundColor: 'rgba(255,255,255,1)',
        titleColor: 'rgba(20,21,24,1)',
        bodyColor: 'rgba(96, 98, 102, 1)',
        borderColor: 'rgba(232, 234, 237, 1)',
        borderWidth: 1,
        boxPadding: 0,
        padding: 8,
        cornerRadius: 4,
        boxWidth: 10,
        boxHeight: 10,
        callbacks: {
          label: (context: any) => {
            let label = ` $0`;

            if (context.parsed.y) {
              label = ` ${useCurrencyFormatWithoutCents(context.parsed.y)}`;
            }
            return label;
          },
        },
      },
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: true,
          drawBorder: true,
        },
        ticks: {
          display: false,
        },
      },
      y: {
        stacked: true,
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          callback: (v: any) => useCurrencyFormatWithoutCents(v),
          color: 'rgba(159, 161, 166, 1)',
          font: {
            size: 12,
          },
        },
      },
    },
  };

  const checkAllNodesResponseLength =
    getPaidNodes.length > 0 || getSubmittedNodes.length > 0 || getApprovedNodes.length > 0;

  useEffect(() => {
    let paidGraphDataSet: any[] = [];
    let submittedGraphDataSet: any[] = [];
    let approvedGraphDataSet: any[] = [];
    let geLastCountDays: any = [];
    let labels: any = [];
    let paidLabels: any = [];
    let submittedLabels: any = [];
    let approvedLabels: any = [];
    let labelsLength = 0;

    const getLastCountDaysArray = () => {
      const days: any[] = [];
      const dateEnd = moment();
      const dateStart = moment().subtract(rangeSelector, 'days');
      while (dateEnd.diff(dateStart, 'days') >= 0) {
        days.push(dateStart.format('MMM DD'));
        dateStart.add(1, 'days');
      }
      return days;
    };

    if (rangeSelector !== allOptionValue) {
      geLastCountDays = getLastCountDaysArray();
      geLastCountDays = geLastCountDays.map(String);

      paidGraphDataSet = new Array(rangeSelector).fill('0');
      submittedGraphDataSet = new Array(rangeSelector).fill('0');
      approvedGraphDataSet = new Array(rangeSelector).fill('0');
    }

    if (checkAllNodesResponseLength) {
      isPaidGraphDataSuccess &&
        getPaidNodes.forEach((row?: any) => {
          paidLabels = [...paidLabels, new Date(row.keys[0])];
        });

      isSubmittedGraphDataSuccess &&
        getSubmittedNodes.forEach((row?: any) => {
          submittedLabels = [...submittedLabels, new Date(row.keys[0])];
        });

      isApprovedGraphDataSuccess &&
        getApprovedNodes.forEach((row?: any) => {
          approvedLabels = [...approvedLabels, new Date(row.keys[0])];
        });

      let updatedLabels: any = [...paidLabels, ...submittedLabels, ...approvedLabels];
      let sorted: any[] = [];

      updatedLabels = updatedLabels.filter((c: any, index: number) => {
        return updatedLabels.indexOf(c) === index;
      });

      sorted = updatedLabels && updatedLabels.sort((date1: any, date2: any) => date1 - date2);

      if (rangeSelector === allOptionValue) {
        sorted.forEach((row?: any) => {
          geLastCountDays = [...geLastCountDays, moment(row).format('MMM DD')];
        });

        labelsLength = sorted.length;

        paidGraphDataSet = new Array(labelsLength).fill('0');
        submittedGraphDataSet = new Array(labelsLength).fill('0');
        approvedGraphDataSet = new Array(labelsLength).fill('0');
      }

      isPaidGraphDataSuccess &&
        getPaidNodes.forEach((row?: any) => {
          const getDate = moment(row.keys[0]).format('MMM DD');
          const getIndex = geLastCountDays.indexOf(getDate);
          if (getIndex !== -1) {
            paidGraphDataSet[getIndex] = row.sum.amount;
          }
        });

      isSubmittedGraphDataSuccess &&
        getSubmittedNodes.forEach((row?: any) => {
          const getDate = moment(row.keys[0]).format('MMM DD');
          const getIndex = geLastCountDays.indexOf(getDate);
          if (getIndex !== -1) {
            submittedGraphDataSet[getIndex] = row.sum.amount;
          }
        });

      isApprovedGraphDataSuccess &&
        getApprovedNodes.forEach((row?: any) => {
          const getDate = moment(row.keys[0]).format('MMM DD');
          const getIndex = geLastCountDays.indexOf(getDate);
          if (getIndex !== -1) {
            approvedGraphDataSet[getIndex] = row.sum.amount;
          }
        });

      paidGraphDataSet = paidGraphDataSet.map(Number);
      submittedGraphDataSet = submittedGraphDataSet.map(Number);
      approvedGraphDataSet = approvedGraphDataSet.map(Number);

      labels = [...geLastCountDays];
      setChartLabels(labels);

      const finalChartData = {
        labels: labels,

        datasets: [
          {
            fill: true,
            data: paidGraphDataSet,
            backgroundColor: 'rgba(174, 170, 255, 1)',
            borderColor: 'rgba(174, 170, 255, 1)',
            borderWidth: 1,
          },
          {
            fill: true,
            data: approvedGraphDataSet,
            backgroundColor: 'rgba(99, 91, 255, 1)',
            borderColor: 'rgba(99, 91, 255, 1)',
            borderWidth: 1,
          },
          {
            fill: true,
            data: submittedGraphDataSet,
            backgroundColor: 'rgba(30, 24, 143, 1)',
            borderColor: 'rgba(30, 24, 143, 1)',
            borderWidth: 1,
          },
        ],
      };

      setFinalChartDataSet(finalChartData);
    }
  }, [
    getPaidNodes,
    getSubmittedNodes,
    getApprovedNodes,
    getGreaterThanDate,
    getLessThanDate,
    setFinalChartDataSet,
    checkAllNodesResponseLength,
  ]);

  const submittedAmount =
    isSubmittedGraphDataSuccess && submittedGraphData.accounts.nodes[0].totalAmount;
  const approvedAmount =
    isApprovedGraphDataSuccess && approvedGraphData.accounts.nodes[0].totalAmount;
  const paidAmount = isPaidGraphDataSuccess && paidGraphData.accounts.nodes[0].totalAmount;

  return (
    <>
      <div className="flex w-full pb-3 mb-6 border-b border-solid bolder-grey-100">
        <Grid columnCount={3} additionalClasses={'w-full '}>
          <StatusBlock
            firstValue={useCurrencyFormat(submittedAmount)}
            blockHeading={'Submitted'}
            isRemoveBorderPadding
            isIconVisible={false}
            titleClasses={
              'inline-flex px-2 h-[20px] rounded !text-white bg-primary-400 !text-12s items-center'
            }
          />
          <StatusBlock
            firstValue={useCurrencyFormat(approvedAmount)}
            blockHeading={'Approved'}
            isIconVisible={false}
            titleClasses={
              'inline-flex px-2 h-[20px] rounded !text-white bg-secondary-500 !text-12s items-center'
            }
          />
          <StatusBlock
            firstValue={useCurrencyFormat(paidAmount)}
            blockHeading={'Paid'}
            isIconVisible={false}
            titleClasses={
              'inline-flex px-2 h-[20px] rounded !text-white bg-primary-200 !text-12s items-center'
            }
          />
        </Grid>
      </div>
      <div className="flex flex-col w-full max-w-[900px]">
        <>
          {checkAllNodesResponseLength ? (
            <>
              <div className="flex w-full">
                {chartlabels && <Line data={finalChartDataSet} options={options} height={'90%'} />}
              </div>
              <div className="flex justify-between pt-4 pl-[50px]">
                <Typhography
                  component="span"
                  type="h1"
                  color="text-grey-400"
                  additionalClasses="!font-normal"
                >
                  {moment(getGreaterThanDate).format('DD MMM YYYY')}
                </Typhography>
                <Typhography
                  component="span"
                  type="h1"
                  color="text-grey-400"
                  additionalClasses="!font-normal"
                >
                  {moment(getLessThanDate).format('DD MMM YYYY')}
                </Typhography>
              </div>
            </>
          ) : (
            <EmptyList />
          )}
        </>
      </div>
    </>
  );
};
