import classNames from 'classnames';
import React from 'react';

interface TagProps {
  /**
   * Set the link type tag
   */
  isLink?: boolean;
  /**
   * Set the child nodes and components
   */
  children?: React.ReactNode;
  /**
   * Set ulr for link (If tag is a link type)
   */
  urlPath?: string;
  /**
   * Optional | Set the additional classes
   */
  additionalClasses?: string;
  /**
   * Optional | enable the underline
   */
  isUnderLine?: boolean;
  /**
   * Enable the onClick Event
   */
  isClickable?: boolean;
  /**
   * Onclick event for tag
   */
  onTagClickEvent?: () => void;
}

/**
 * Primary UI component for user interaction
 */
const Tag: React.FC<TagProps> = ({
  isLink = false,
  children = 'TAG',
  urlPath,
  additionalClasses,
  isUnderLine = false,
  isClickable = false,
  onTagClickEvent,
}) => {
  const handleUnderline = isUnderLine && 'underline';
  const handleLinkClasses =
    isLink && 'hover:text-white hover:bg-grey-900 ease-out duration-300 cursor-pointer';
  const handleDefaultClasses =
    'text-12s text-grey-900 font-normal px-2 py-[2px] rounded bg-grey-100 inline-flex justify-center';
  const tagClasses = [additionalClasses, handleUnderline, handleLinkClasses, handleDefaultClasses];
  return (
    <>
      {!isLink ? (
        isClickable ? (
          <span className={classNames(tagClasses)} onClick={onTagClickEvent}>
            {children}
          </span>
        ) : (
          <span className={classNames(tagClasses)}>{children}</span>
        )
      ) : (
        <a className={classNames(tagClasses)} href={urlPath}>
          <span>{children}</span>
        </a>
      )}
    </>
  );
};

export default Tag;
