import classNames from 'classnames';
import { Button } from 'components/core';
import { BlockConfirmationContentProps } from 'shared/models/components/base.model';
import Modal from '../Modal/Modal';

export const ConfirmationModal = ({
  handleBackEvent,
  handleSubmitEvent,
  isIconLoad,
  mainContent,
  additionalClasses,
  specialNote,
}: BlockConfirmationContentProps) => {
  return (
    <Modal
      isOpen={true}
      onClose={handleBackEvent}
      modalAdditionalClasses={
        'z-20 !w-[370px] flex flex-col bg-white justify-center rounded-lg shadow-importInvoicesModal absolute right-1 opacity-1 pt-6 pb-6 px-6'
      }
    >
      <div
        className={classNames([
          'flex flex-col items-center justify-center w-full',
          additionalClasses,
        ])}
      >
        <div className="flex flex-col w-full mb-4 font-medium text-center text-16s text-primary-500 ">
          <span>{mainContent}</span>
        </div>
        {specialNote && (
          <div className="flex flex-col w-full mb-4 -mt-1 font-medium text-center text-gray-700 text-13s ">
            <span>{specialNote}</span>
          </div>
        )}
        <div className="flex flex-row items-center justify-center w-full px-12">
          <span
            className={classNames([
              'inline-flex font-bold text-14s text-black-900 mr-7',
              isIconLoad ? 'pointer-events-none opacity-50' : 'pointer-events-auto cursor-pointer',
            ])}
            onClick={handleBackEvent}
          >
            Cancel
          </span>

          <Button
            type={'button'}
            buttonLabel={'Yes'}
            variants="extraSmall"
            onClick={handleSubmitEvent}
            additionalClasses={'w-[100px]'}
            iconType="Spin"
            iconClass="inline-flex ml-1 w-3 h-3"
            isIconEnabled={isIconLoad}
            iconAlignment={'right'}
          />
        </div>
      </div>
    </Modal>
  );
};
