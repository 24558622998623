import React, { FC, createElement } from 'react';
import classNames from 'classnames';
import { ReactNode } from 'react';
import { Button } from 'components/core';

export type ClassNameType = string;
export type ChildrenType = ReactNode;

export interface IFormProps {
  children?: ChildrenType;
  buttonLabel?: string;
  onSubmit?: any;
  handleSubmit?: any;
  register?: any;
  className?: ClassNameType;
  isButtonEnabled?: boolean;
  formClasses?: string;
  formContainerClasses?: string;
}

const Form: FC<IFormProps> = ({
  buttonLabel = 'Submit',
  children,
  onSubmit,
  handleSubmit,
  register,
  isButtonEnabled = false,
  formClasses = 'w-full flex',
  formContainerClasses,
  ...rest
}) => {
  return (
    <form {...rest} className={formClasses}>
      <div className={classNames(['flex w-full', formContainerClasses])}>
        {Array.isArray(children)
          ? children.map((child) => {
              return child.props.name
                ? createElement(child.type, {
                    ...{
                      ...child.props,
                      ...register(child.props.name),
                      key: child.props.name,
                      ref: child.props.ref,
                    },
                  })
                : child;
            })
          : children}
      </div>
      {isButtonEnabled && <Button type="submit">{buttonLabel}</Button>}
    </form>
  );
};

export default Form;
