import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import AppRoutes from './components/app/Routes';
import * as FullStory from '@fullstory/browser';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { CONFIG } from 'shared/configs/config';

function App() {
  const { enableFullstoryConfigs } = useFlags();

  /**
   * Enabled and Disabled Fullstory with the feature flags.
   */
  useEffect(() => {
    if (enableFullstoryConfigs) {
      FullStory.init({ orgId: CONFIG.FULLSTORY_ORG_ID });
    }
  }, [enableFullstoryConfigs]);

  return (
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  );
}

export default App;
