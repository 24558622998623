import { IconValuesProps } from 'shared/models/components/base.model';

export const HomeIcon = () => (
  <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.62109 12.1821L11.8902 5.09414C12.5288 4.54674 13.4712 4.54674 14.1098 5.09414L22.3789 12.1821"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.3364 21.8778V4.68316C21.3364 4.39539 21.1031 4.16211 20.8153 4.16211H17.689C17.4013 4.16211 17.168 4.39539 17.168 4.68316V7.39887"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.4414 21.8772V16.1456C10.4414 15.5701 10.908 15.1035 11.4835 15.1035H14.5151C15.0906 15.1035 15.5572 15.5701 15.5572 16.1456V21.8772"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.96246 11.0332V21.8776"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.3789 21.8785H3.62109"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const PropertiesIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.997 3.9967H14.0012C14.5537 3.9967 15.0016 4.44461 15.0016 4.99712V20.0034H3.997C3.44448 20.0034 2.99658 19.5555 2.99658 19.003V4.99712C2.99658 4.44461 3.44448 3.9967 3.997 3.9967Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.0015 7.99829H20.0035C20.5561 7.99829 21.004 8.44619 21.004 8.99871V19.0029C21.004 19.5554 20.5561 20.0033 20.0035 20.0033H15.0015V7.99829Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.99826 7.87333C6.92919 7.87333 6.8732 7.92931 6.8732 7.99838C6.8732 8.06744 6.92919 8.12343 6.99826 8.12343C7.06732 8.12343 7.12331 8.06744 7.12331 7.99838C7.12331 7.92931 7.06732 7.87333 6.99826 7.87333"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.9997 7.87333C10.9307 7.87333 10.8747 7.92931 10.8747 7.99838C10.8747 8.06744 10.9307 8.12343 10.9997 8.12343C11.0688 8.12343 11.1248 8.06744 11.1248 7.99838C11.1248 7.92931 11.0688 7.87333 10.9997 7.87333"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.99826 11.8749C6.92919 11.8749 6.8732 11.9309 6.8732 12C6.8732 12.069 6.92919 12.125 6.99826 12.125C7.06732 12.125 7.12331 12.069 7.12331 12C7.12331 11.9309 7.06732 11.8749 6.99826 11.8749"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.9997 11.8749C10.9307 11.8749 10.8747 11.9309 10.8747 12C10.8747 12.069 10.9307 12.125 10.9997 12.125C11.0688 12.125 11.1248 12.069 11.1248 12C11.1248 11.9309 11.0688 11.8749 10.9997 11.8749"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.99826 15.8766C6.92919 15.8766 6.8732 15.9326 6.8732 16.0017C6.8732 16.0707 6.92919 16.1267 6.99826 16.1267C7.06732 16.1267 7.12331 16.0707 7.12331 16.0017C7.12331 15.9326 7.06732 15.8766 6.99826 15.8766"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.9997 15.8766C10.9307 15.8766 10.8747 15.9326 10.8747 16.0017C10.8747 16.0707 10.9307 16.1267 10.9997 16.1267C11.0688 16.1267 11.1248 16.0707 11.1248 16.0017C11.1248 15.9326 11.0688 15.8766 10.9997 15.8766"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.0026 11.8749C17.9336 11.8749 17.8776 11.9309 17.8776 12C17.8776 12.069 17.9336 12.125 18.0026 12.125C18.0717 12.125 18.1277 12.069 18.1277 12C18.1277 11.9309 18.0717 11.8749 18.0026 11.8749"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.0026 15.8766C17.9336 15.8766 17.8776 15.9326 17.8776 16.0017C17.8776 16.0707 17.9336 16.1267 18.0026 16.1267C18.0717 16.1267 18.1277 16.0707 18.1277 16.0017C18.1277 15.9326 18.0717 15.8766 18.0026 15.8766"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const BalanceIcon = () => (
  <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.1667 14.5827V18.2286C17.1666 19.6668 16.0008 20.8326 14.5626 20.8327H6.22908C5.53863 20.8329 4.87637 20.5588 4.38801 20.0707C3.89965 19.5826 3.62519 18.9205 3.625 18.23V13.0201C3.62481 12.3296 3.89891 11.6674 4.387 11.179C4.8751 10.6907 5.5372 10.4162 6.22766 10.416H8.83333"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.3737 8.33333H8.83203"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.83203 17.6283H8.96879"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="8.83203"
      y="4.16602"
      width="13.5417"
      height="10.4167"
      rx="2.5"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ApprovalsIcon = () => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.5596 7.24219L12.0036 14.7982L8.22656 11.0202"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.5937 14.006V18.9C20.5937 20.06 19.6537 21 18.4937 21H5.69375C4.53375 21 3.59375 20.06 3.59375 18.9V6.1C3.59375 4.94 4.53375 4 5.69375 4H12.0937"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const VendorsIcon = () => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.7933 10.0242C21.5833 10.8142 21.5833 12.0951 20.7933 12.8851C20.0033 13.6751 18.7224 13.6751 17.9324 12.8851C17.1423 12.0951 17.1423 10.8142 17.9324 10.0242C18.7224 9.23413 20.0033 9.23413 20.7933 10.0242"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.2044 6.41236C15.4208 7.62884 15.4208 9.60115 14.2044 10.8176C12.9879 12.0341 11.0156 12.0341 9.79908 10.8176C8.5826 9.60116 8.5826 7.62885 9.79908 6.41236C11.0156 5.19588 12.9879 5.19588 14.2044 6.41236"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.06675 10.0242C6.85679 10.8142 6.85679 12.0951 6.06675 12.8851C5.27672 13.6751 3.99583 13.6751 3.2058 12.8851C2.41577 12.0951 2.41577 10.8142 3.2058 10.0242C3.99584 9.23413 5.27672 9.23413 6.06675 10.0242"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.0002 19.5003V18.4043C23.0002 17.0233 21.8812 15.9043 20.5002 15.9043H19.6992"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 19.5003V18.4043C1 17.0233 2.119 15.9043 3.5 15.9043H4.301"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.3392 19.4994V17.8984C17.3392 15.9654 15.7722 14.3984 13.8392 14.3984H10.1602C8.22716 14.3984 6.66016 15.9654 6.66016 17.8984V19.4994"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const SettingIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.96129 12.2692L3.17597 12.4594C4.07264 12.5998 4.713 13.4211 4.64697 14.3463L4.55747 15.6004C4.53141 15.9657 4.72603 16.3097 5.04838 16.4688L5.94679 16.9114C6.26915 17.0705 6.65405 17.011 6.91732 16.763L7.82008 15.9097C8.48563 15.2805 9.51263 15.2805 10.1791 15.9097L11.0818 16.763C11.3451 17.0119 11.7291 17.0705 12.0523 16.9114L12.9525 16.4679C13.274 16.3097 13.4677 15.9666 13.4417 15.6022L13.3522 14.3463C13.2861 13.4211 13.9265 12.5998 14.8232 12.4594L16.0378 12.2692C16.3915 12.2141 16.6765 11.943 16.7564 11.5857L16.978 10.592C17.0579 10.2347 16.9163 9.86408 16.6209 9.65787L15.6069 8.94772C14.8588 8.42332 14.6303 7.39941 15.0821 6.59504L15.6946 5.50537C15.8728 5.18806 15.8432 4.79077 15.6199 4.50457L14.9987 3.70731C14.7754 3.42111 14.4035 3.3029 14.0612 3.41045L12.8865 3.77841C12.0185 4.05039 11.0931 3.59443 10.7595 2.7314L10.3094 1.5644C10.1773 1.22309 9.85497 0.999114 9.49612 1L8.5004 1.00267C8.14156 1.00356 7.82008 1.22931 7.68975 1.57151L7.25097 2.72518C6.9208 3.59354 5.99111 4.05306 5.1205 3.7793L3.89713 3.39534C3.55393 3.2869 3.18032 3.406 2.95702 3.69398L2.34012 4.49213C2.11682 4.78099 2.08989 5.17917 2.27148 5.49648L2.89793 6.58882C3.3593 7.39408 3.1334 8.42776 2.38096 8.95483L1.37915 9.65698C1.08373 9.86408 0.942109 10.2347 1.02204 10.5911L1.24361 11.5848C1.32267 11.943 1.60766 12.2141 1.96129 12.2692V12.2692Z"
      stroke="#141518"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.6625 7.32636C11.5818 8.26672 11.5818 9.79191 10.6625 10.7323C9.74325 11.6726 8.25227 11.6726 7.333 10.7323C6.41374 9.79191 6.41374 8.26672 7.333 7.32636C8.25227 6.386 9.74325 6.386 10.6625 7.32636"
      stroke="#141518"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ProfileIcon = () => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20 20.5V19.75C20 17.4028 18.0972 15.5 15.75 15.5H8.25C5.90279 15.5 4 17.4028 4 19.75V20.5"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="12"
      cy="7.5"
      r="4"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const SentIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.76286 13.2716L13.4002 3.45827C13.5982 2.92361 13.0775 2.40361 12.5435 2.60161L2.72686 6.24161C2.11286 6.46961 2.16086 7.35361 2.79619 7.51294L7.35219 8.65761L8.49019 13.2016C8.65019 13.8376 9.53486 13.8863 9.76286 13.2716V13.2716Z"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.2468 2.75293L7.35352 8.6596"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const TickIcon = () => (
  <svg width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.1152 0C4.59251 0 0.115234 4.25341 0.115234 9.5C0.115234 14.7466 4.59251 19 10.1152 19C15.638 19 20.1152 14.7466 20.1152 9.5C20.1152 4.25341 15.638 0 10.1152 0ZM14.4498 7.89364C14.5296 7.80696 14.5904 7.706 14.6285 7.59669C14.6666 7.48738 14.6814 7.37193 14.6718 7.25712C14.6623 7.14232 14.6287 7.03049 14.573 6.9282C14.5173 6.82592 14.4407 6.73525 14.3476 6.66152C14.2544 6.5878 14.1467 6.53251 14.0308 6.49892C13.9148 6.46532 13.793 6.4541 13.6724 6.4659C13.5518 6.4777 13.4349 6.51229 13.3286 6.56764C13.2223 6.62299 13.1288 6.69797 13.0534 6.78818L9.14433 11.2437L7.1216 9.32123C6.95014 9.16391 6.7205 9.07686 6.48214 9.07883C6.24378 9.0808 6.01577 9.17162 5.84722 9.33175C5.67866 9.49187 5.58306 9.70848 5.58099 9.93493C5.57891 10.1614 5.67055 10.3795 5.83614 10.5424L8.56342 13.1333C8.65274 13.2181 8.75968 13.2843 8.87743 13.3277C8.99518 13.3711 9.12114 13.3906 9.2473 13.3852C9.37345 13.3798 9.49703 13.3494 9.61015 13.2961C9.72328 13.2427 9.82346 13.1676 9.90433 13.0755L14.4498 7.89364Z"
      fill="#7FC008"
    />
  </svg>
);

export const OrderIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 1.5C7.72386 1.5 7.5 1.72386 7.5 2L7.5 14C7.5 14.2761 7.72386 14.5 8 14.5C8.27614 14.5 8.5 14.2761 8.5 14L8.5 2C8.5 1.72386 8.27614 1.5 8 1.5Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.35355 1.64645C8.15829 1.45118 7.84171 1.45118 7.64645 1.64645L3.64645 5.64645C3.45118 5.84171 3.45118 6.15829 3.64645 6.35355C3.84171 6.54882 4.15829 6.54882 4.35355 6.35355L8 2.70711L11.6464 6.35355C11.8417 6.54882 12.1583 6.54882 12.3536 6.35355C12.5488 6.15829 12.5488 5.84171 12.3536 5.64645L8.35355 1.64645Z"
      fill="currentColor"
    />
  </svg>
);

export const OrderIconASC = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 1.5C7.72386 1.5 7.5 1.72386 7.5 2L7.5 14C7.5 14.2761 7.72386 14.5 8 14.5C8.27614 14.5 8.5 14.2761 8.5 14L8.5 2C8.5 1.72386 8.27614 1.5 8 1.5Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.35355 1.64645C8.15829 1.45118 7.84171 1.45118 7.64645 1.64645L3.64645 5.64645C3.45118 5.84171 3.45118 6.15829 3.64645 6.35355C3.84171 6.54882 4.15829 6.54882 4.35355 6.35355L8 2.70711L11.6464 6.35355C11.8417 6.54882 12.1583 6.54882 12.3536 6.35355C12.5488 6.15829 12.5488 5.84171 12.3536 5.64645L8.35355 1.64645Z"
      fill="currentColor"
    />
  </svg>
);

export const OrderIconDESC = () => (
  <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 21.75C11.5858 21.75 11.25 21.4142 11.25 21L11.25 3C11.25 2.58579 11.5858 2.25 12 2.25C12.4142 2.25 12.75 2.58579 12.75 3L12.75 21C12.75 21.4142 12.4142 21.75 12 21.75Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5303 21.5303C12.2374 21.8232 11.7626 21.8232 11.4697 21.5303L5.46967 15.5303C5.17678 15.2374 5.17678 14.7626 5.46967 14.4697C5.76256 14.1768 6.23744 14.1768 6.53033 14.4697L12 19.9393L17.4697 14.4697C17.7626 14.1768 18.2374 14.1768 18.5303 14.4697C18.8232 14.7626 18.8232 15.2374 18.5303 15.5303L12.5303 21.5303Z"
      fill="currentColor"
    />
  </svg>
);

export const NextIcon = () => (
  <svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.801758 12.0415L6.2967 6.78105L0.801758 1.52063"
      stroke="currentColor"
      strokeWidth="1.50562"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const PrevIcon = () => (
  <svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.23242 1.52127L1.73748 6.78169L7.23242 12.0421"
      stroke="currentColor"
      strokeWidth="1.50562"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const SpinIcon = () => (
  <svg
    className="h-full w-ful text-inherit animate-spin"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <circle
      className="opacity-25"
      cx="12"
      cy="12"
      r="10"
      stroke="currentColor"
      strokeWidth="4"
    ></circle>
    <path
      className="opacity-75"
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    ></path>
  </svg>
);

export const InfoIcon = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.9995 4C5.9305 4 5.8745 4.056 5.875 4.125C5.875 4.194 5.931 4.25 6 4.25C6.069 4.25 6.125 4.194 6.125 4.125C6.125 4.056 6.069 4 5.9995 4"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 10.5V10.5C3.5145 10.5 1.5 8.4855 1.5 6V6C1.5 3.5145 3.5145 1.5 6 1.5V1.5C8.4855 1.5 10.5 3.5145 10.5 6V6C10.5 8.4855 8.4855 10.5 6 10.5Z"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 6V8.5"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const RightArrow = () => (
  <svg width="6" height="12" viewBox="0 0 6 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.714355 11L5.00007 6L0.714355 1"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const DropDownIcon = () => (
  <svg width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 0L5 5L10 0H0Z" fill="currentColor" />
  </svg>
);

export const DropDownArrow = () => (
  <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.07031 1.03516L4.0774 4.04225L7.0845 1.03516"
      stroke="#656565"
      strokeWidth="1.12766"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const WhiteArrowIcon = () => (
  <svg width="16" height="30" viewBox="0 0 16 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.5 2L1.5 15L14.5 28"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const TrashIcon = () => (
  <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.66683 4V10.6667H2.3335V4H7.66683ZM6.66683 0H3.3335L2.66683 0.666667H0.333496V2H9.66683V0.666667H7.3335L6.66683 0ZM9.00016 2.66667H1.00016V10.6667C1.00016 11.4 1.60016 12 2.3335 12H7.66683C8.40016 12 9.00016 11.4 9.00016 10.6667V2.66667Z"
      fill="currentColor"
    />
  </svg>
);

export const AddNewitemIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.582 6.48128V9.05528L0.0479844 9.09128V6.49928L15.582 6.48128ZM9.13798 15.5353H6.56398L6.52798 0.00128055H9.11998L9.13798 15.5353Z"
      fill="currentColor"
    />
  </svg>
);

export const OkIcon = () => (
  <svg width="12" height="10" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22 2L7.71428 18L2 11.6"
      stroke="currentColor"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const EditIcon = () => (
  <svg width="9" height="9" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.3952 3.20951L8.79049 0.604834L8.85588 0.539445C9.57514 -0.179815 10.7413 -0.179815 11.4606 0.539445C12.1798 1.25871 12.1798 2.42486 11.4606 3.14412L11.3952 3.20951ZM10.3345 4.27017L3.38607 11.2186L0 12L0.781401 8.61393L7.72983 1.66549L10.3345 4.27017Z"
      fill="currentColor"
    />
  </svg>
);

export const AddIcon = () => (
  <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.625 1V9M9.625 5L1.625 5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const TickIconGray = () => (
  <svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 4.33333L3.45 7L8 1"
      stroke="#464646"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const CloseIcon = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.4108 0.41107C10.7363 0.0856329 11.2639 0.0856329 11.5893 0.41107C11.9148 0.736507 11.9148 1.26414 11.5893 1.58958L7.17859 6.00033L11.5893 10.4111C11.9148 10.7365 11.9148 11.2641 11.5893 11.5896C11.2639 11.915 10.7363 11.915 10.4108 11.5896L6.00008 7.17884L1.58934 11.5896C1.2639 11.915 0.736263 11.915 0.410826 11.5896C0.0853887 11.2641 0.0853887 10.7365 0.410826 10.4111L4.82157 6.00033L0.410826 1.58958C0.0853892 1.26414 0.0853892 0.736507 0.410826 0.41107C0.736263 0.0856329 1.2639 0.0856329 1.58934 0.41107L6.00008 4.82181L10.4108 0.41107Z"
      fill="currentColor"
    />
  </svg>
);

export const DatePicker = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.0833 1.33341H9.5V0.166748H8.33333V1.33341H3.66667V0.166748H2.5V1.33341H1.91667C1.26917 1.33341 0.755833 1.85841 0.755833 2.50008L0.75 10.6667C0.75 11.3084 1.26917 11.8334 1.91667 11.8334H10.0833C10.725 11.8334 11.25 11.3084 11.25 10.6667V2.50008C11.25 1.85841 10.725 1.33341 10.0833 1.33341ZM10.0833 10.6667H1.91667V4.83341H10.0833V10.6667ZM10.0833 3.66675H1.91667V2.50008H10.0833V3.66675ZM4.25 7.16675H3.08333V6.00008H4.25V7.16675ZM6.58333 7.16675H5.41667V6.00008H6.58333V7.16675ZM8.91667 7.16675H7.75V6.00008H8.91667V7.16675ZM4.25 9.50008H3.08333V8.33341H4.25V9.50008ZM6.58333 9.50008H5.41667V8.33341H6.58333V9.50008ZM8.91667 9.50008H7.75V8.33341H8.91667V9.50008Z"
      fill="currentColor"
    />
  </svg>
);

export const TickIconForSuccess = () => (
  <svg width="68" height="68" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M29.0833 49.1667L52.6667 25.5833L48.8333 21.8333L29.0833 41.5833L19.0833 31.5833L15.3333 35.3333L29.0833 49.1667ZM34 67.3333C29.4445 67.3333 25.1389 66.4583 21.0833 64.7083C17.0278 62.9583 13.4861 60.5694 10.4584 57.5417C7.43057 54.5139 5.04169 50.9722 3.29169 46.9167C1.54169 42.8611 0.666687 38.5556 0.666687 34C0.666687 29.3889 1.54169 25.0556 3.29169 21C5.04169 16.9445 7.43057 13.4167 10.4584 10.4167C13.4861 7.41669 17.0278 5.04169 21.0833 3.29169C25.1389 1.54169 29.4445 0.666687 34 0.666687C38.6111 0.666687 42.9444 1.54169 47 3.29169C51.0556 5.04169 54.5833 7.41669 57.5833 10.4167C60.5833 13.4167 62.9583 16.9445 64.7083 21C66.4583 25.0556 67.3333 29.3889 67.3333 34C67.3333 38.5556 66.4583 42.8611 64.7083 46.9167C62.9583 50.9722 60.5833 54.5139 57.5833 57.5417C54.5833 60.5694 51.0556 62.9583 47 64.7083C42.9444 66.4583 38.6111 67.3333 34 67.3333ZM34 62.3333C41.8889 62.3333 48.5833 59.5694 54.0833 54.0417C59.5833 48.5139 62.3333 41.8333 62.3333 34C62.3333 26.1111 59.5833 19.4167 54.0833 13.9167C48.5833 8.41668 41.8889 5.66669 34 5.66669C26.1667 5.66669 19.4861 8.41668 13.9583 13.9167C8.43057 19.4167 5.66669 26.1111 5.66669 34C5.66669 41.8333 8.43057 48.5139 13.9583 54.0417C19.4861 59.5694 26.1667 62.3333 34 62.3333Z"
      fill="currentColor"
    />
  </svg>
);

export const BlackLeftArrow = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M32 14H7.66L18.84 2.82L16 0L0 16L16 32L18.82 29.18L7.66 18H32V14Z" fill="#1C1B1F" />
  </svg>
);

export const TickIconWhite = () => (
  <svg width="11" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.26287 0.324281C8.63606 -0.0828377 9.26863 -0.110341 9.67575 0.262852C10.0829 0.636044 10.1104 1.26861 9.73718 1.67573L4.23718 7.67573C3.82808 8.12202 3.11938 8.10528 2.7318 7.64019L0.231804 4.64019C-0.12176 4.21591 -0.0644362 3.58535 0.359841 3.23178C0.784118 2.87822 1.41468 2.93554 1.76825 3.35982L3.53589 5.48099L8.26287 0.324281Z"
      fill="currentColor"
    />
  </svg>
);

export const SearchIcon = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.92796 1.80869C3.5951 1.36293 4.37944 1.125 5.1818 1.125C6.2577 1.12507 7.28954 1.5525 8.05032 2.31328C8.8111 3.07405 9.23853 4.10587 9.2386 5.18177C9.2386 5.98413 9.00067 6.7685 8.5549 7.43563C8.10914 8.10277 7.47555 8.62274 6.73427 8.92979C5.99299 9.23684 5.1773 9.31718 4.39036 9.16064C3.60342 9.00411 2.88056 8.61774 2.31321 8.05039C1.74586 7.48303 1.35948 6.76018 1.20295 5.97324C1.04642 5.1863 1.12676 4.37061 1.43381 3.62933C1.74086 2.88805 2.26083 2.25446 2.92796 1.80869ZM5.1818 1.875C6.0588 1.87506 6.89986 2.22348 7.51999 2.84361C8.14012 3.46374 8.48853 4.3048 8.4886 5.1818M5.1818 1.875C4.52778 1.875 3.88843 2.06894 3.34464 2.4323C2.80084 2.79565 2.377 3.3121 2.12672 3.91634C1.87643 4.52058 1.81095 5.18547 1.93854 5.82692C2.06613 6.46838 2.38108 7.05759 2.84354 7.52005C3.306 7.98252 3.89522 8.29746 4.53667 8.42505C5.17813 8.55265 5.84302 8.48716 6.44726 8.23688C7.05149 7.98659 7.56794 7.56276 7.9313 7.01895C8.29465 6.47516 8.48859 5.83581 8.4886 5.1818"
      fill="#606266"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.66355 7.66391C7.80999 7.51747 8.04743 7.51747 8.19388 7.66391L10.7652 10.2352C10.9117 10.3817 10.9117 10.6191 10.7652 10.7656C10.6188 10.912 10.3813 10.912 10.2349 10.7656L7.66355 8.19424C7.5171 8.0478 7.5171 7.81036 7.66355 7.66391Z"
      fill="#606266"
    />
  </svg>
);

export const IncreaseModeIcon = () => (
  <svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14 0L16.29 2.29L11.41 7.17L7.41 3.17L0 10.59L1.41 12L7.41 6L11.41 10L17.71 3.71L20 6V0H14Z"
      fill="currentColor"
    />
  </svg>
);

export const CardClockIcon = () => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.7649 7.65283V11.5303L13.8128 13.3886"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.54614 10.1663C3.99457 6.15545 7.53541 3.21784 11.5601 3.51762C15.5849 3.81741 18.6514 7.24718 18.5007 11.2802C18.35 15.3133 15.036 18.5046 11.0001 18.5031"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const DecreaseModeIcon = () => (
  <svg width="21" height="12" viewBox="0 0 21 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.3333 12L16.6233 9.71L11.7433 4.83L7.74325 8.83L0.333252 1.41L1.74325 0L7.74325 6L11.7433 2L18.0433 8.29L20.3333 6V12H14.3333Z"
      fill="currentColor"
    />
  </svg>
);

export const DownloadIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.3717 8.40723L9.00007 10.7789L6.62842 8.40723"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.99998 3.66504V10.7793"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.3357 11.9639C14.3357 13.2737 13.2738 14.3355 11.964 14.3355H6.03621C4.72638 14.3355 3.66455 13.2737 3.66455 11.9639"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const EyeIcon = () => (
  <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.99984 1.33333C10.5265 1.33333 12.7798 2.75333 13.8798 5C12.7798 7.24667 10.5332 8.66667 7.99984 8.66667C5.4665 8.66667 3.21984 7.24667 2.11984 5C3.21984 2.75333 5.47317 1.33333 7.99984 1.33333ZM7.99984 0C4.6665 0 1.81984 2.07333 0.666504 5C1.81984 7.92667 4.6665 10 7.99984 10C11.3332 10 14.1798 7.92667 15.3332 5C14.1798 2.07333 11.3332 0 7.99984 0ZM7.99984 3.33333C8.91984 3.33333 9.6665 4.08 9.6665 5C9.6665 5.92 8.91984 6.66667 7.99984 6.66667C7.07984 6.66667 6.33317 5.92 6.33317 5C6.33317 4.08 7.07984 3.33333 7.99984 3.33333ZM7.99984 2C6.3465 2 4.99984 3.34667 4.99984 5C4.99984 6.65333 6.3465 8 7.99984 8C9.65317 8 10.9998 6.65333 10.9998 5C10.9998 3.34667 9.65317 2 7.99984 2Z"
      fill="currentColor"
    />
  </svg>
);

export const FilterIcon = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.8862 9.33333H11.3377C11.7035 9.33333 12 9.62924 12 10C12 10.3682 11.7034 10.6667 11.3377 10.6667H3.8862C3.61164 11.4435 2.87081 12 2 12C0.895431 12 0 11.1046 0 10C0 8.89543 0.895431 8 2 8C2.87081 8 3.61164 8.55654 3.8862 9.33333ZM4.1138 6.66667H0.662265C0.296506 6.66667 0 6.37076 0 6C0 5.63181 0.296631 5.33333 0.662265 5.33333H4.1138C4.38836 4.55654 5.12919 4 6 4C6.87081 4 7.61164 4.55654 7.8862 5.33333H11.3377C11.7035 5.33333 12 5.62924 12 6C12 6.36819 11.7034 6.66667 11.3377 6.66667H7.8862C7.61164 7.44346 6.87081 8 6 8C5.12919 8 4.38836 7.44346 4.1138 6.66667ZM8.11064 2.65765C8.07497 2.66358 8.03834 2.66667 8.001 2.66667H0.665668C0.29803 2.66667 0 2.37076 0 2C0 1.63181 0.298326 1.33333 0.665668 1.33333H8.001C8.03835 1.33333 8.07499 1.33639 8.11067 1.34227C8.38269 0.560809 9.12585 0 10 0C11.1046 0 12 0.895431 12 2C12 3.10457 11.1046 4 10 4C9.12582 4 8.38264 3.43915 8.11064 2.65765ZM10 2.66667C10.3682 2.66667 10.6667 2.36819 10.6667 2C10.6667 1.63181 10.3682 1.33333 10 1.33333C9.63181 1.33333 9.33333 1.63181 9.33333 2C9.33333 2.36819 9.63181 2.66667 10 2.66667ZM6 6.66667C6.36819 6.66667 6.66667 6.36819 6.66667 6C6.66667 5.63181 6.36819 5.33333 6 5.33333C5.63181 5.33333 5.33333 5.63181 5.33333 6C5.33333 6.36819 5.63181 6.66667 6 6.66667ZM2 10.6667C2.36819 10.6667 2.66667 10.3682 2.66667 10C2.66667 9.63181 2.36819 9.33333 2 9.33333C1.63181 9.33333 1.33333 9.63181 1.33333 10C1.33333 10.3682 1.63181 10.6667 2 10.6667Z"
      fill="currentColor"
    />
  </svg>
);

export const EyeIconClose = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.1307 11.2973C11.598 12.1956 10.5632 12.6683 9.53543 12.483C8.50765 12.2976 7.70323 11.4932 7.51786 10.4654C7.3325 9.43762 7.80521 8.40285 8.70348 7.87012"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.9951 14.1622C13.5583 15.2519 11.8032 15.8393 9.99999 15.8338C7.01198 15.887 4.25117 14.2448 2.87165 11.5938C2.37612 10.5893 2.37612 9.41153 2.87165 8.4071C3.56157 7.03319 4.66137 5.90761 6.01892 5.18604"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.02 11.7786C17.0534 11.7153 17.0969 11.6585 17.1285 11.5939C17.624 10.5895 17.624 9.41169 17.1285 8.40726C15.749 5.75622 12.9882 4.11406 10.0002 4.16727C9.81283 4.16727 9.63073 4.19227 9.4458 4.20445"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.5004 16.6657L3.33374 2.49902"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const EyeIconLarge = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.59837 10.389C2.46754 10.1465 2.46754 9.85234 2.59837 9.60984C4.17504 6.694 7.08754 4.1665 10 4.1665C12.9125 4.1665 15.825 6.694 17.4017 9.61067C17.5325 9.85317 17.5325 10.1473 17.4017 10.3898C15.825 13.3057 12.9125 15.8332 10 15.8332C7.08754 15.8332 4.17504 13.3057 2.59837 10.389Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.7678 8.23223C12.7441 9.20854 12.7441 10.7915 11.7678 11.7678C10.7915 12.7441 9.20854 12.7441 8.23223 11.7678C7.25592 10.7915 7.25592 9.20854 8.23223 8.23223C9.20854 7.25592 10.7915 7.25592 11.7678 8.23223"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const EditLineIcon = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.295 0.692656L13.3075 1.70516C13.9 2.29016 13.9 3.24266 13.3075 3.82766L3.385 13.7502H0.25V10.6152L8.05 2.80766L10.1725 0.692656C10.7575 0.107656 11.71 0.107656 12.295 0.692656ZM1.75 12.2502L2.8075 12.2952L10.1725 4.92266L9.115 3.86516L1.75 11.2302V12.2502Z"
      fill="currentColor"
    />
  </svg>
);

export const DotsMoreIcon = () => (
  <svg width="4" height="16" viewBox="0 0 4 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 12C3.10457 12 4 12.8954 4 14C4 15.1046 3.10457 16 2 16C0.895431 16 0 15.1046 0 14C0 12.8954 0.895431 12 2 12ZM2 6C3.10457 6 4 6.89543 4 8C4 9.10457 3.10457 10 2 10C0.895431 10 0 9.10457 0 8C0 6.89543 0.895431 6 2 6ZM4 2C4 0.895431 3.10457 0 2 0C0.895431 0 0 0.895431 0 2C0 3.10457 0.895431 4 2 4C3.10457 4 4 3.10457 4 2Z"
      fill="currentColor"
    />
  </svg>
);

export const DotsHorizontalMoreIcon = () => (
  <svg
    width="10"
    height="16"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 122.88 29.96"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="currentColor"
      d="M0,15A15,15,0,1,1,15,30,15,15,0,0,1,0,15Zm92.93,0a15,15,0,1,1,15,15,15,15,0,0,1-15-15ZM46.46,15a15,15,0,1,1,15,15,15,15,0,0,1-15-15Z"
    />
  </svg>
);

export const InfoRoundedIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9 1.5C13.1355 1.5 16.5 4.8645 16.5 9C16.5 13.1355 13.1355 16.5 9 16.5C4.8645 16.5 1.5 13.1355 1.5 9C1.5 4.8645 4.8645 1.5 9 1.5ZM9 0C4.02975 0 0 4.02975 0 9C0 13.9703 4.02975 18 9 18C13.9703 18 18 13.9703 18 9C18 4.02975 13.9703 0 9 0ZM9.9375 12.75C9.9375 13.2675 9.51825 13.6875 9 13.6875C8.48325 13.6875 8.0625 13.2675 8.0625 12.75C8.0625 12.2325 8.48325 11.8125 9 11.8125C9.51825 11.8125 9.9375 12.2325 9.9375 12.75ZM10.9823 5.2515C10.5262 4.7895 9.846 4.53525 9.069 4.53525C7.434 4.53525 6.3765 5.69775 6.3765 7.49775H7.88475C7.88475 6.38325 8.5065 5.988 9.03825 5.988C9.51375 5.988 10.0185 6.30375 10.0612 6.9075C10.1077 7.54275 9.76875 7.86525 9.33975 8.27325C8.28075 9.2805 8.26125 9.768 8.26575 10.8742H9.7695C9.75975 10.3762 9.792 9.972 10.4708 9.24075C10.9785 8.69325 11.61 8.01225 11.6227 6.97425C11.631 6.28125 11.4097 5.685 10.9823 5.2515Z"
      fill="currentColor"
    />
  </svg>
);

export const TermsIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="9"
      y="11.5"
      width="6"
      height="4"
      rx="1.25"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.75 11.5V10.25C13.75 9.2835 12.9665 8.5 12 8.5V8.5C11.0335 8.5 10.25 9.2835 10.25 10.25V11.5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 13.0209C20 16.7956 15.3552 19.5997 13.1401 20.7255C12.4255 21.0909 11.579 21.0916 10.8636 20.7275C8.65012 19.6027 4 16.7918 4 13.0209V7.56938C4.00735 7.05853 4.39423 6.63324 4.9021 6.5777C7.02402 6.41646 9.05835 5.66412 10.7746 4.40594C11.5019 3.86469 12.4981 3.86469 13.2254 4.40594C14.9416 5.66412 16.9759 6.41646 19.0979 6.5777C19.6057 6.63322 19.9926 7.05852 20 7.56938V13.0209Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const RemoveTrashIcon = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1118_22033)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.1875 2.9165C2.1875 2.67488 2.38338 2.479 2.625 2.479H11.375C11.6166 2.479 11.8125 2.67488 11.8125 2.9165V12.8332C11.8125 13.0748 11.6166 13.2707 11.375 13.2707H2.625C2.38338 13.2707 2.1875 13.0748 2.1875 12.8332V2.9165ZM3.0625 3.354V12.3957H10.9375V3.354H3.0625Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.83325 5.396C6.07488 5.396 6.27075 5.59187 6.27075 5.8335V9.62516C6.27075 9.86679 6.07488 10.0627 5.83325 10.0627C5.59163 10.0627 5.39575 9.86679 5.39575 9.62516V5.8335C5.39575 5.59187 5.59163 5.396 5.83325 5.396Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.16675 5.396C8.40837 5.396 8.60425 5.59187 8.60425 5.8335V9.62516C8.60425 9.86679 8.40837 10.0627 8.16675 10.0627C7.92512 10.0627 7.72925 9.86679 7.72925 9.62516V5.8335C7.72925 5.59187 7.92512 5.396 8.16675 5.396Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.729248 2.9165C0.729248 2.67488 0.925123 2.479 1.16675 2.479H12.8334C13.075 2.479 13.2709 2.67488 13.2709 2.9165C13.2709 3.15813 13.075 3.354 12.8334 3.354H1.16675C0.925123 3.354 0.729248 3.15813 0.729248 2.9165Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.2424 0.956205C5.3192 0.8161 5.46627 0.729004 5.62604 0.729004H8.3934C8.55451 0.729004 8.70258 0.817548 8.77882 0.959477L9.71883 2.70948C9.79166 2.84506 9.78789 3.00895 9.7089 3.14104C9.62991 3.27313 9.48732 3.354 9.33341 3.354H4.66675C4.51218 3.354 4.36907 3.27244 4.29031 3.13944C4.21154 3.00644 4.20881 2.84175 4.28311 2.7062L5.2424 0.956205ZM5.88514 1.604L5.40549 2.479H8.60179L8.13178 1.604H5.88514Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_1118_22033">
        <rect width="14" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const RoundTickIcon = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1118_22018)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.56593 2.56568C3.70027 1.43137 5.26857 0.729004 7.00008 0.729004C8.73159 0.729004 10.2999 1.43137 11.4342 2.56569L11.1249 2.87505L11.4342 2.56569C12.5685 3.70002 13.2709 5.26833 13.2709 6.99984C13.2709 8.73135 12.5685 10.2997 11.4342 11.434C10.2999 12.5683 8.73159 13.2707 7.00008 13.2707C5.26857 13.2707 3.70026 12.5683 2.56593 11.434L2.87529 11.1246L2.56593 11.434C1.43162 10.2997 0.729248 8.73135 0.729248 6.99984C0.729248 5.26833 1.43162 3.70002 2.56593 2.56569L2.56593 2.56568ZM7.00008 1.604C5.50996 1.604 4.1616 2.20747 3.18465 3.18441C2.20771 4.16136 1.60425 5.50971 1.60425 6.99984C1.60425 8.48996 2.20772 9.83832 3.18465 10.8153C4.1616 11.7922 5.50996 12.3957 7.00008 12.3957C8.4902 12.3957 9.83856 11.7922 10.8155 10.8153C11.7924 9.83832 12.3959 8.48996 12.3959 6.99984C12.3959 5.50971 11.7924 4.16135 10.8155 3.18441C9.83856 2.20747 8.49021 1.604 7.00008 1.604Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2261 4.94064C10.397 5.1115 10.397 5.3885 10.2261 5.55936L6.72611 9.05936C6.55525 9.23021 6.27824 9.23021 6.10739 9.05936L4.35739 7.30936C4.18653 7.1385 4.18653 6.8615 4.35739 6.69064C4.52824 6.51979 4.80525 6.51979 4.97611 6.69064L6.41675 8.13128L9.60739 4.94064C9.77824 4.76979 10.0553 4.76979 10.2261 4.94064Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_1118_22018">
        <rect width="14" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const ValidationWarningIcon = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.58657 0.981064L11.8123 9.43256C12.2505 10.3089 11.8953 11.3745 11.0189 11.8127C10.7726 11.9359 10.501 12 10.2256 12H1.77406C0.794273 12 0 11.2057 0 10.2259C0 9.95053 0.0641235 9.6789 0.187292 9.43256L4.41304 0.981064C4.85121 0.104717 5.91684 -0.250492 6.79319 0.187681C7.13652 0.359345 7.41491 0.637735 7.58657 0.981064ZM6 7.875C5.37868 7.875 4.875 8.37868 4.875 9C4.875 9.62132 5.37868 10.125 6 10.125C6.62132 10.125 7.125 9.62132 7.125 9C7.125 8.37868 6.62132 7.875 6 7.875ZM6 6.75C6.41421 6.75 6.75 6.51015 6.75 6.21429V3.53571C6.75 3.23985 6.41421 3 6 3C5.58579 3 5.25 3.23985 5.25 3.53571V6.21429C5.25 6.51015 5.58579 6.75 6 6.75Z"
      fill="currentColor"
    />
  </svg>
);

export const WarningIcon = () => (
  <svg width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.869022 16.999C0.483058 17.6656 0.964115 18.5 1.73445 18.5H20.2656C21.0359 18.5 21.5169 17.6656 21.131 16.999L11.8654 0.994829C11.4803 0.329544 10.5197 0.329542 10.1346 0.994827L0.869022 16.999ZM12 14.5C12 15.0523 11.5523 15.5 11 15.5C10.4477 15.5 10 15.0523 10 14.5C10 13.9477 10.4477 13.5 11 13.5C11.5523 13.5 12 13.9477 12 14.5ZM12 10.5C12 11.0523 11.5523 11.5 11 11.5C10.4477 11.5 10 11.0523 10 10.5V8.5C10 7.94772 10.4477 7.5 11 7.5C11.5523 7.5 12 7.94771 12 8.5V10.5Z"
      fill="currentColor"
    />
  </svg>
);

export const NeturalIcon = () => (
  <svg width="22" height="22" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.75 6C6.75 5.58579 6.41421 5.25 6 5.25H4.125C3.71079 5.25 3.375 5.58579 3.375 6C3.375 6.41421 3.71079 6.75 4.125 6.75H5.25V9.75C5.25 10.1642 5.58579 10.5 6 10.5C6.41421 10.5 6.75 10.1642 6.75 9.75V6ZM3 0H9C10.6569 0 12 1.34315 12 3V9C12 10.6569 10.6569 12 9 12H3C1.34315 12 0 10.6569 0 9V3C0 1.34315 1.34315 0 3 0ZM6 4.125C6.62132 4.125 7.125 3.62132 7.125 3C7.125 2.37868 6.62132 1.875 6 1.875C5.37868 1.875 4.875 2.37868 4.875 3C4.875 3.62132 5.37868 4.125 6 4.125Z"
      fill="currentColor"
    />
  </svg>
);

export const SuccessIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM15.6219 6.24401C15.1337 5.75585 14.3422 5.75585 13.8541 6.24401L8.75 11.3572L6.50888 9.11612C6.02073 8.62796 5.22927 8.62796 4.74112 9.11612C4.25296 9.60427 4.25296 10.3957 4.74112 10.8839L7.86612 14.0089C8.35427 14.497 9.14573 14.497 9.63388 14.0089L15.6219 8.01178C16.11 7.52362 16.11 6.73217 15.6219 6.24401Z"
      fill="currentColor"
    />
  </svg>
);

export const DelinquentIcon = () => (
  <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 1H7" stroke="#FC733F" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M1 9H7" stroke="#FC733F" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M2 1V3C2 3.315 2.148 3.611 2.4 3.8L4 5L5.6 3.8C5.852 3.611 6 3.315 6 3V1"
      stroke="#FC733F"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 9V7C6 6.685 5.852 6.389 5.6 6.2L4 5L2.4 6.2C2.148 6.389 2 6.685 2 7V9"
      stroke="#FC733F"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const UploadIcon = () => (
  <svg width="12" height="12" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14 11V14H2V11H0V14C0 15.1 0.9 16 2 16H14C15.1 16 16 15.1 16 14V11H14ZM3 5L4.41 6.41L7 3.83V12H9V3.83L11.59 6.41L13 5L8 0L3 5Z"
      fill="currentColor"
    />
  </svg>
);

export const PropertyIcon = () => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11 7.01576C10.9724 7.01575 10.946 7.02676 10.9266 7.04633C10.9072 7.0659 10.8964 7.0924 10.8966 7.11997C10.8966 7.17752 10.9433 7.22418 11.0008 7.22418C11.0584 7.22418 11.105 7.17752 11.105 7.11997C11.1041 7.06247 11.0575 7.0162 11 7.01576"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.4798 9.61075H3.52026C3.40359 9.61097 3.29163 9.56478 3.20906 9.48236C3.12648 9.39995 3.08008 9.28807 3.08008 9.1714V7.52405C3.08035 7.16537 3.31001 6.84706 3.65032 6.73372L10.7216 4.37607C10.9024 4.31605 11.0977 4.31605 11.2785 4.37607L18.3498 6.73372C18.6901 6.84706 18.9197 7.16537 18.92 7.52405V9.1714C18.92 9.28807 18.8736 9.39995 18.791 9.48236C18.7085 9.56478 18.5965 9.61097 18.4798 9.61075Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.3309 16.0022V9.61035"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.6698 9.61035V16.0022"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.66586 9.61035V16.0022"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.9998 9.61035V16.0022"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.3348 9.61035V16.0022"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.9878 16.002H4.01198C3.6963 16.0021 3.40778 16.1806 3.26667 16.463L2.75146 17.4934C2.69328 17.609 2.66309 17.7366 2.66309 17.8661V18.503H19.3367V17.8661C19.3367 17.7366 19.3065 17.609 19.2483 17.4934L18.7331 16.463C18.592 16.1806 18.3035 16.0021 17.9878 16.002Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.1711 18.5038H1.83008"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const WireTransferIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.50033 13.25H3.25033C2.37533 13.25 1.66699 12.5417 1.66699 11.6667V4.08333C1.66699 3.20833 2.37533 2.5 3.25033 2.5H15.917C16.792 2.5 17.5003 3.20833 17.5003 4.08333V9.16667"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.7412 14.583L17.4912 13.333L16.2412 14.583"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.4912 13.333V16.1672C17.4912 16.9005 16.8912 17.4997 16.1587 17.4997H14.167"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.4258 14.583L11.6758 15.833L12.9258 14.583"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.6758 15.8337V12.9995C11.6758 12.2662 12.2758 11.667 13.0083 11.667H14.9999"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.9821 6.6425C11.7546 7.415 11.7546 8.66833 10.9821 9.44167C10.2096 10.2142 8.95622 10.2142 8.18289 9.44167C7.41039 8.66917 7.41039 7.41583 8.18289 6.6425C8.95622 5.86917 10.2096 5.86917 10.9821 6.6425"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.00033 10.8337H4.16699"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.0003 4.99967H14.167"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const InfoLinedIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.999 7.9985C11.861 7.9985 11.749 8.1105 11.75 8.2485C11.75 8.3865 11.862 8.4985 12 8.4985C12.138 8.4985 12.25 8.3865 12.25 8.2485C12.25 8.1105 12.138 7.9985 11.999 7.9985"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 21.001V21.001C7.029 21.001 3 16.972 3 12.001V12.001C3 7.02998 7.029 3.00098 12 3.00098V3.00098C16.971 3.00098 21 7.02998 21 12.001V12.001C21 16.972 16.971 21.001 12 21.001Z"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 12.0002V17.0002"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const QuestionIcon = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6 1C8.757 1 11 3.243 11 6C11 8.757 8.757 11 6 11C3.243 11 1 8.757 1 6C1 3.243 3.243 1 6 1ZM6 0C2.6865 0 0 2.6865 0 6C0 9.3135 2.6865 12 6 12C9.3135 12 12 9.3135 12 6C12 2.6865 9.3135 0 6 0ZM6.625 8.5C6.625 8.845 6.3455 9.125 6 9.125C5.6555 9.125 5.375 8.845 5.375 8.5C5.375 8.155 5.6555 7.875 6 7.875C6.3455 7.875 6.625 8.155 6.625 8.5ZM7.3215 3.501C7.0175 3.193 6.564 3.0235 6.046 3.0235C4.956 3.0235 4.251 3.7985 4.251 4.9985H5.2565C5.2565 4.2555 5.671 3.992 6.0255 3.992C6.3425 3.992 6.679 4.2025 6.7075 4.605C6.7385 5.0285 6.5125 5.2435 6.2265 5.5155C5.5205 6.187 5.5075 6.512 5.5105 7.2495H6.513C6.5065 6.9175 6.528 6.648 6.9805 6.1605C7.319 5.7955 7.74 5.3415 7.7485 4.6495C7.754 4.1875 7.6065 3.79 7.3215 3.501V3.501Z"
      fill="#606266"
    />
  </svg>
);

export const PrintIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.6665 5.33333V2.66667C4.6665 2.29867 4.96517 2 5.33317 2H10.6665C11.0345 2 11.3332 2.29867 11.3332 2.66667V5.33333"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.66667 11.333H3.33333C2.59667 11.333 2 10.7363 2 9.99967V6.66634C2 5.92967 2.59667 5.33301 3.33333 5.33301H12.6667C13.4033 5.33301 14 5.92967 14 6.66634V9.99967C14 10.7363 13.4033 11.333 12.6667 11.333H11.3333"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.6665 9.2002H11.3332V13.3335C11.3332 13.7015 11.0345 14.0002 10.6665 14.0002H5.33317C4.96517 14.0002 4.6665 13.7015 4.6665 13.3335V9.2002Z"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.6665 7.33333H5.33317"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const InvoiceIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.6667 9.16667V3.97833V3.97833C16.6667 3.16167 16.005 2.5 15.1883 2.5H3.97833C3.16167 2.5 2.5 3.16167 2.5 3.97833V15.1883C2.5 16.005 3.16167 16.6667 3.97833 16.6667H7.5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.87667 9.53985C5.95833 9.62152 5.95833 9.75318 5.87667 9.83485C5.795 9.91652 5.66333 9.91652 5.58167 9.83485C5.5 9.75318 5.5 9.62152 5.58167 9.53985C5.66333 9.45902 5.795 9.45902 5.87667 9.53985"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.5542 5.93766H9.1709"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.87667 5.79245C5.95833 5.87411 5.95833 6.00578 5.87667 6.08745C5.795 6.16911 5.66333 6.16911 5.58167 6.08745C5.5 6.00578 5.5 5.87411 5.58167 5.79245C5.66333 5.71078 5.795 5.71161 5.87667 5.79245"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.5542 9.68766H9.1709"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.208 13.9583V16.875C10.208 17.68 10.8613 18.3333 11.6663 18.3333H16.0413C16.8463 18.3333 17.4997 17.68 17.4997 16.875V13.9583C17.4997 13.1533 16.8463 12.5 16.0413 12.5H11.6663C10.8613 12.5 10.208 13.1533 10.208 13.9583Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.4997 15.1432H10.208"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const TimerIcon = () => (
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.2656 6.65234V10.5298L13.3136 12.3881"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.0459 9.16585C3.49433 5.15496 7.03517 2.21735 11.0599 2.51713C15.0846 2.81692 18.1512 6.24669 18.0005 10.2798C17.8497 14.3128 14.5357 17.5041 10.4998 17.5027"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.16309 13.6681V16.0024C2.16309 16.647 2.68564 17.1696 3.33024 17.1696H6.8317C7.4763 17.1696 7.99885 16.647 7.99885 16.0024V13.6681C7.99885 13.0235 7.4763 12.501 6.8317 12.501H3.33024C2.68564 12.501 2.16309 13.0235 2.16309 13.6681Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.99885 14.6683H2.16309"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const TimerIconSmall = () => (
  <svg width="15" height="14" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.2656 6.65234V10.5298L13.3136 12.3881"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.0459 9.16585C3.49433 5.15496 7.03517 2.21735 11.0599 2.51713C15.0846 2.81692 18.1512 6.24669 18.0005 10.2798C17.8497 14.3128 14.5357 17.5041 10.4998 17.5027"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.16309 13.6681V16.0024C2.16309 16.647 2.68564 17.1696 3.33024 17.1696H6.8317C7.4763 17.1696 7.99885 16.647 7.99885 16.0024V13.6681C7.99885 13.0235 7.4763 12.501 6.8317 12.501H3.33024C2.68564 12.501 2.16309 13.0235 2.16309 13.6681Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.99885 14.6683H2.16309"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const DollarCircled = () => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.5 15C18.88 14.069 19.5 12.068 19.5 11C19.5 6.582 15.918 3 11.5 3C7.082 3 3.5 6.582 3.5 11C3.5 15.418 7.082 19 11.5 19C13.805 19.5164 17.6837 17 18.5 15Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.5 8.13198V7.49498"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.5 13.8588V14.4948"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.84284 13.1759C10.0898 13.5809 10.5118 13.8599 11.0228 13.8599H11.4998H12.0688C12.8098 13.8599 13.4088 13.2599 13.4088 12.5199C13.4088 11.9049 12.9898 11.3699 12.3938 11.2189L10.6038 10.7689C10.0088 10.6199 9.58984 10.0849 9.58984 9.46988C9.58984 8.72888 10.1898 8.12988 10.9298 8.12988H11.4998H11.9768C12.4858 8.12988 12.9078 8.40888 13.1538 8.81288"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const TimeCalculation = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.2799 4.61426V6.28092H15.6133H17.2799"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.32227 8.94096C6.32227 8.26513 6.8706 7.7168 7.54643 7.7168C8.22227 7.7168 8.7706 8.26513 8.7706 8.94096C8.7706 10.471 6.3231 10.471 6.3231 12.2826H8.7706"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.245 10.0253L11.1517 10.0028C11.0333 10.0011 10.9375 9.90527 10.9375 9.78611V7.71777"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.2419 7.7666V12.2833"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.9168 6.28182C15.581 3.86349 12.9852 2.22266 10.0002 2.22266C5.70432 2.22266 2.22266 5.70516 2.22266 10.0002C2.22266 14.2952 5.70432 17.7777 10.0002 17.7777C14.296 17.7777 17.7777 14.2952 17.7777 10.0002"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const CalendarIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.3337 1.66699V5.00033"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.66667 1.66699V5.00033"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.5 7.49967H17.5"
      stroke="#141518"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.8333 3.33301H4.16667C3.24583 3.33301 2.5 4.07884 2.5 4.99967V15.833C2.5 16.7538 3.24583 17.4997 4.16667 17.4997H15.8333C16.7542 17.4997 17.5 16.7538 17.5 15.833V4.99967C17.5 4.07884 16.7542 3.33301 15.8333 3.33301Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.84455 10.6077C5.72955 10.6077 5.63621 10.7011 5.63705 10.8161C5.63705 10.9311 5.73038 11.0244 5.84538 11.0244C5.96038 11.0244 6.05371 10.9311 6.05371 10.8161C6.05371 10.7011 5.96038 10.6077 5.84455 10.6077"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.0106 10.6077C9.89556 10.6077 9.80223 10.7011 9.80306 10.8161C9.80306 10.9311 9.8964 11.0244 10.0114 11.0244C10.1264 11.0244 10.2197 10.9311 10.2197 10.8161C10.2197 10.7011 10.1264 10.6077 10.0106 10.6077"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.1776 10.6077C14.0626 10.6077 13.9692 10.7011 13.9701 10.8161C13.9701 10.9311 14.0634 11.0244 14.1784 11.0244C14.2934 11.0244 14.3867 10.9311 14.3867 10.8161C14.3867 10.7011 14.2934 10.6077 14.1776 10.6077"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.84455 13.9408C5.72955 13.9408 5.63621 14.0341 5.63705 14.1491C5.63705 14.2641 5.73038 14.3574 5.84538 14.3574C5.96038 14.3574 6.05371 14.2641 6.05371 14.1491C6.05371 14.0341 5.96038 13.9408 5.84455 13.9408"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.0106 13.9408C9.89556 13.9408 9.80223 14.0341 9.80306 14.1491C9.80306 14.2641 9.8964 14.3574 10.0114 14.3574C10.1264 14.3574 10.2197 14.2641 10.2197 14.1491C10.2197 14.0341 10.1264 13.9408 10.0106 13.9408"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const Payments = () => (
  <svg width="25" height="25" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.50004 1V3" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5.50004 17V15" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M1.5 12.0067V12.0067C1.5 13.664 2.84267 15.0067 4.5 15.0067H6.69067C8.24133 15.0067 9.5 13.7493 9.5 12.1973V12.1973C9.5 10.9093 8.624 9.78667 7.37467 9.47333L3.62533 8.53333C2.376 8.22 1.5 7.09733 1.5 5.80933V5.80933C1.5 4.25733 2.75733 3 4.30933 3H6.5C8.15733 3 9.5 4.34267 9.5 6V6"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const Reconcile = () => (
  <svg width="20" height="20" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.99984 1.33333C10.5265 1.33333 12.7798 2.75333 13.8798 5C12.7798 7.24667 10.5332 8.66667 7.99984 8.66667C5.4665 8.66667 3.21984 7.24667 2.11984 5C3.21984 2.75333 5.47317 1.33333 7.99984 1.33333ZM7.99984 0C4.6665 0 1.81984 2.07333 0.666504 5C1.81984 7.92667 4.6665 10 7.99984 10C11.3332 10 14.1798 7.92667 15.3332 5C14.1798 2.07333 11.3332 0 7.99984 0ZM7.99984 3.33333C8.91984 3.33333 9.6665 4.08 9.6665 5C9.6665 5.92 8.91984 6.66667 7.99984 6.66667C7.07984 6.66667 6.33317 5.92 6.33317 5C6.33317 4.08 7.07984 3.33333 7.99984 3.33333ZM7.99984 2C6.3465 2 4.99984 3.34667 4.99984 5C4.99984 6.65333 6.3465 8 7.99984 8C9.65317 8 10.9998 6.65333 10.9998 5C10.9998 3.34667 9.65317 2 7.99984 2Z"
      fill="currentColor"
    />
  </svg>
);

export const Dollaricon = () => (
  <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.325 7.08333C3.43333 6.59167 2.825 6.08333 2.825 5.29167C2.825 4.38333 3.66666 3.75 5.075 3.75C6.55833 3.75 7.10833 4.45833 7.15833 5.5H9C8.94166 4.06667 8.06666 2.75 6.325 2.325V0.5H3.825V2.3C2.20833 2.65 0.908331 3.7 0.908331 5.30833C0.908331 7.23333 2.5 8.19167 4.825 8.75C6.90833 9.25 7.325 9.98333 7.325 10.7583C7.325 11.3333 6.91666 12.25 5.075 12.25C3.35833 12.25 2.68333 11.4833 2.59166 10.5H0.758331C0.858331 12.325 2.225 13.35 3.825 13.6917V15.5H6.325V13.7083C7.95 13.4 9.24166 12.4583 9.24166 10.75C9.24166 8.38333 7.21666 7.575 5.325 7.08333Z"
      fill="currentColor"
    />
  </svg>
);

export const UserIcon = () => (
  <svg width="18" height="25" viewBox="0 0 18 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.2044 6.41236C15.4208 7.62884 15.4208 9.60115 14.2044 10.8176C12.9879 12.0341 11.0156 12.0341 9.79908 10.8176C8.5826 9.60116 8.5826 7.62885 9.79908 6.41236C11.0156 5.19588 12.9879 5.19588 14.2044 6.41236"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.3392 19.4994V17.8984C17.3392 15.9654 15.7722 14.3984 13.8392 14.3984H10.1602C8.22716 14.3984 6.66016 15.9654 6.66016 17.8984V19.4994"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const LockedIcon = () => (
  <svg width="9" height="12" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.52381 6.66667V4C9.52381 1.79133 7.81778 0 5.71429 0C3.61079 0 1.90476 1.79133 1.90476 4V6.66667H0V16H11.4286V6.66667H9.52381ZM3.1746 6.66667V4C3.1746 2.52933 4.31365 1.33333 5.71429 1.33333C7.11492 1.33333 8.25397 2.52933 8.25397 4V6.66667H3.1746Z"
      fill="currentColor"
    />
  </svg>
);

export const InstantPaymentsIcon = ({
  width = '16',
  height = '18',
  fillColor = '#0B1848',
}: IconValuesProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.87671 0.576787C10.0012 0.642759 10.0989 0.746523 10.1543 0.871528C10.2096 0.996533 10.2194 1.13558 10.182 1.26648L8.4881 7.17823H14.6684C14.7927 7.17824 14.9143 7.21282 15.0182 7.27772C15.1221 7.34262 15.2038 7.43501 15.2534 7.54354C15.3029 7.65206 15.318 7.772 15.2968 7.88859C15.2757 8.00518 15.2192 8.11336 15.1344 8.19982L6.20577 17.3067C6.10853 17.4061 5.97907 17.4715 5.83811 17.4926C5.69715 17.5137 5.55283 17.4892 5.42823 17.423C5.30364 17.3568 5.20596 17.2527 5.15083 17.1274C5.0957 17.0022 5.0863 16.8629 5.12414 16.7319L6.81802 10.821H0.637718C0.513431 10.821 0.391854 10.7864 0.287933 10.7215C0.184012 10.6566 0.102276 10.5642 0.0527718 10.4557C0.00326801 10.3471 -0.011846 10.2272 0.00928799 10.1106C0.0304219 9.99401 0.0868826 9.88584 0.17173 9.79938L9.10035 0.692545C9.1976 0.5935 9.32688 0.528293 9.46758 0.507314C9.60828 0.486335 9.75231 0.510792 9.87671 0.576787Z"
      fill={fillColor}
    />
  </svg>
);

export const SubmittedIcon = ({ isActive = false }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5 21H6.5C5.395 21 4.5 20.105 4.5 19V5C4.5 3.895 5.395 3 6.5 3H17.5C18.605 3 19.5 3.895 19.5 5V19C19.5 20.105 18.605 21 17.5 21Z"
        fill={isActive ? '#fff' : 'none'}
        fillOpacity="0.8"
        stroke={isActive ? '#0B1848' : '#C5C7CA'}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 7.5H16"
        stroke={isActive ? '#0B1848' : '#C5C7CA'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 10.5H16"
        stroke={isActive ? '#0B1848' : '#C5C7CA'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 15H16"
        stroke={isActive ? '#0B1848' : '#C5C7CA'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 18H16"
        stroke={isActive ? '#0B1848' : '#C5C7CA'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.5 6.75V6"
        stroke={isActive ? '#0B1848' : '#C5C7CA'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.5 11.25V12"
        stroke={isActive ? '#0B1848' : '#C5C7CA'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.198 10.714C8.392 11.032 8.724 11.252 9.125 11.252H9.5H9.947C10.529 11.252 11 10.78 11 10.199C11 9.716 10.671 9.295 10.203 9.177L8.797 8.825C8.329 8.707 8 8.286 8 7.803C8 7.222 8.472 6.75 9.053 6.75H9.5H9.875C10.275 6.75 10.606 6.969 10.8 7.286"
        stroke={isActive ? '#0B1848' : '#C5C7CA'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ApprovedIcon = ({ isActive = false }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 21H5C3.895 21 3 20.105 3 19V5C3 3.895 3.895 3 5 3H19C20.105 3 21 3.895 21 5V19C21 20.105 20.105 21 19 21Z"
        fill={isActive ? '#fff' : 'none'}
        fillOpacity="0.8"
        stroke={isActive ? '#0B1848' : '#C5C7CA'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 14H16"
        stroke={isActive ? '#0B1848' : '#C5C7CA'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 17H16"
        stroke={isActive ? '#0B1848' : '#C5C7CA'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5 10L10 8.5"
        stroke={isActive ? '#0B1848' : '#C5C7CA'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 7.5L11.5 10"
        stroke={isActive ? '#0B1848' : '#C5C7CA'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const PaymentTriggeredIcon = ({ isActive = false }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="3" y="4" width="17" height="13" rx="2" fill={isActive ? '#fff' : 'none'} />
      <path
        d="M14.0007 21.0033V16.4214"
        stroke={isActive ? '#0B1848' : '#C5C7CA'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.0017 21.0033V16.4214"
        stroke={isActive ? '#0B1848' : '#C5C7CA'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.0031 21.0033V16.4214"
        stroke={isActive ? '#0B1848' : '#C5C7CA'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 21.0036H21.0033"
        stroke={isActive ? '#0B1848' : '#C5C7CA'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 16.4214H21.0033V14.6377L17.0017 13L13 14.6377V16.4214Z"
        stroke={isActive ? '#0B1848' : '#C5C7CA'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.0019 6.99777H16.0015"
        stroke={isActive ? '#0B1848' : '#C5C7CA'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.99707 14.0007H6.99749"
        stroke={isActive ? '#0B1848' : '#C5C7CA'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.99901 17.002H5.49714C4.11585 17.002 2.99609 15.8822 2.99609 14.501V6.49762C2.99609 5.83431 3.2596 5.19816 3.72863 4.72912C4.19767 4.26008 4.83382 3.99658 5.49714 3.99658H17.5021C18.8834 3.99658 20.0032 5.11634 20.0032 6.49762V9.99908"
        stroke={isActive ? '#0B1848' : '#C5C7CA'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.73107 12.2676C8.75436 11.2909 8.75436 9.70731 9.73107 8.73059C10.7078 7.75387 12.2914 7.75387 13.2681 8.73059"
        stroke={isActive ? '#0B1848' : '#C5C7CA'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const PaymentOnTheWayIcon = ({ isActive = false }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="3" y="4" width="18" height="14" rx="2" fill={isActive ? '#fff' : 'none'} />
      <path
        d="M17.5 12.5V9.5"
        stroke={isActive ? '#0B1848' : '#C5C7CA'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.5 12.5V9.5"
        stroke={isActive ? '#0B1848' : '#C5C7CA'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 18H6C4.34315 18 3 16.6569 3 15V7C3 5.34315 4.34315 4 6 4H18C19.6569 4 21 5.34315 21 7V13"
        stroke={isActive ? '#0B1848' : '#C5C7CA'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 21L21 19L19 17"
        stroke={isActive ? '#0B1848' : '#C5C7CA'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 19H21"
        stroke={isActive ? '#0B1848' : '#C5C7CA'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <ellipse
        cx="12"
        cy="11"
        rx="2"
        ry="2"
        stroke={isActive ? '#0B1848' : '#C5C7CA'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const PaymentInTransitIcon = ({ isActive = false }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      {/* <rect x="3" y="4" width="18" height="14" rx="2" fill={isActive ? '#fff' : 'none'} /> */}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 21H5C3.895 21 3 20.105 3 19V5C3 3.895 3.895 3 5 3H19C20.105 3 21 3.895 21 5V19C21 20.105 20.105 21 19 21Z"
        fill={isActive ? '#fff' : 'none'}
        fillOpacity="0.8"
        stroke={isActive ? '#0B1848' : '#C5C7CA'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5 12.5V9.5"
        stroke={isActive ? '#0B1848' : '#C5C7CA'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.5 12.5V9.5"
        stroke={isActive ? '#0B1848' : '#C5C7CA'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 17H16"
        stroke={isActive ? '#0B1848' : '#C5C7CA'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <ellipse
        cx="12"
        cy="11"
        rx="2"
        ry="2"
        stroke={isActive ? '#0B1848' : '#C5C7CA'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const PaymentArrivedIcon = ({ isActive = false }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.804 18.2129C17.299 17.7119 14.715 17.7739 12.237 18.3929L12 18.4529C9.366 19.1109 6.619 19.1769 3.957 18.6439L3.804 18.6129C3.336 18.5199 3 18.1089 3 17.6329V6.7939C3 6.1629 3.577 5.6899 4.196 5.8129C6.701 6.3139 9.285 6.2519 11.763 5.6329L12.236 5.5149C14.714 4.8959 17.299 4.8339 19.803 5.3349L20.195 5.4129C20.664 5.5069 21 5.9169 21 6.3939V17.2329C21 17.8639 20.423 18.3369 19.804 18.2129Z"
        fill={isActive ? '#fff' : 'none'}
        stroke={isActive ? '#0B1848' : '#C5C7CA'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.001 9.375V8.5"
        stroke={isActive ? '#0B1848' : '#C5C7CA'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.001 14.625V15.5"
        stroke={isActive ? '#0B1848' : '#C5C7CA'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.483 14C10.71 14.371 11.097 14.628 11.564 14.628H12.002H12.523C13.202 14.628 13.751 14.078 13.751 13.4C13.751 12.836 13.367 12.345 12.821 12.207L11.181 11.795C10.636 11.658 10.251 11.166 10.251 10.602C10.251 9.92302 10.801 9.37402 11.479 9.37402H12H12.438C12.905 9.37402 13.291 9.62902 13.518 9.99902"
        stroke={isActive ? '#0B1848' : '#C5C7CA'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
