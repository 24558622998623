import {
  Button,
  Card,
  Checkbox,
  NotificationSwitch,
  Grid,
  Icon,
  Typhography,
  LoadingSpinner,
  ErrorModal,
} from 'components/core';
import { sendDigest } from 'constants/inits';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  useGetNotificationPreferencesQuery,
  useUpdateNotificationPreferencesMutation,
  useUpdateVendorsAccountMutation,
} from 'services/vendorsApi';

interface INotificationForm {
  vendorData?: any;
  vendorID?: string;
  user?: any;
  isPermitted?: any;
}

export const NotificationForm = ({
  vendorData,
  vendorID,
  user,
  isPermitted,
}: INotificationForm) => {
  const [message, setMessage] = useState('');
  const [mapPrefs, setMapPrefs] = useState(new Map<string, boolean>());

  const updateMapPrefs = (key: string, value: boolean) => {
    setMapPrefs((map) => new Map(map.set(key, value)));
  };

  const { data } = useGetNotificationPreferencesQuery('');
  const [mutation] = useUpdateNotificationPreferencesMutation();
  const [updateVendorsAccount, { isSuccess: updateSuccess, isLoading: updateLoading }]: any =
    useUpdateVendorsAccountMutation();

  /**
   * react hook forms & yap init setup
   */
  const { register, handleSubmit, setValue } = useForm();

  useEffect(() => {
    setValue('email', vendorData.is_email_notification_active);
    setValue('text', vendorData.is_sms_notification_active);
  }, []);

  const fixedNotificationPrefs = [
    'DEFAULT_PAYMENT_TERMS_UPDATED',
    'INVOICE_PAYMENT_TERMS_UPDATED',
    'PAYOUT_COMPLETED',
  ];

  const excludingList = [
    'VENDOR_ONBOARDING_COMPLETE',
    'CUSTOMER_SIGNUP_COMPLETE',
    'OLD_INVOICES_FOUND',
    'DELINQUENT_PROPERTY_INVOICE_SUBMITTED',
    'CUSTOMER_INVITATION',
    'CUSTOMER_SIGN_UP_REQUEST',
    'REFERRED_VENDOR_ONBOARDING_COMPLETE',
    'ACCOUNT_DELETION_REQUEST',
    'CANCEL_ACCOUNT_DELETION_REQUEST',
    'PAYUP_FINANCIAL_CARD',
    'MISSED_APPROVAL',
    'EXPORT_PAYOUTS_CSV_REQUEST',
    'IS_WEEKLY_DIGEST',
  ];

  const nameTags: any = {
    DEFAULT_PAYMENT_TERMS_UPDATED: {
      value: 'Default Payout Terms Changed',
    },
    INVOICE_PAYMENT_TERMS_UPDATED: {
      value: 'Invoice Payout Terms Changed',
    },
    CUSTOMER_PAYOUT_APPROVED: {
      value: 'Invoice Approved',
    },
    INVOICE_DISPUTED: {
      value: 'Invoice Disputed',
    },
    PAYOUT_COMPLETED: {
      value: 'Invoice Paid',
    },
    CUSTOMER_PAYOUT_INVOICED: {
      value: 'Invoice Submitted',
    },
    READERS_DIGEST: {
      value: 'PayUp info digest',
    },
  };

  const weeklyDigestItem = sendDigest
    ? data?.accountNotificationPreferences?.nodes?.filter(
        (item: any) => item?.notification_name === 'IS_WEEKLY_DIGEST'
      )[0]
    : undefined;

  const formSubmit = handleSubmit(async (data: any) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line prefer-const
    for (let [key, value] of mapPrefs.entries()) {
      await mutation({ id: key, is_active: value });
    }

    const formData = {
      id: vendorID,
      is_email_notification_active: data.email,
      is_sms_notification_active: data.text,
    };

    const response = await updateVendorsAccount({
      obj: formData,
      isCompany: false,
      isMappingRequired: true,
    });

    if (response) {
      if (response && response.error) {
        const geterrorResponse = response.error.data.message ?? response?.error?.data;
        setMessage(geterrorResponse);
      } else {
        setMessage('Notification preferences are updated successfully!');
      }

      setTimeout(() => {
        setMessage('');
      }, 6500);
    }
  });

  const handleErrorModalClose = () => {
    setMessage('');
  };

  return (
    <>
      {updateLoading && <LoadingSpinner />}
      <div className="flex w-full">
        <form className="flex flex-col w-full" onSubmit={formSubmit}>
          <div className="flex items-center justify-between w-full mb-4">
            <Typhography component="p" type="h2" color="text-grey-900">
              {'NOTIFICATIONS'}
            </Typhography>
            {isPermitted(user, 'edit-vendor-profile') && (
              <Button
                variants={'extraSmallOutlined'}
                buttonLabel={'Save Changes'}
                additionalClasses="!border-grey-200 w-[121px]"
                type={'submit'}
              />
            )}
          </div>
          <Grid columnCount={2} columnGap={4} additionalClasses="max-w-[900px]">
            <Card cardType="whiteBorder" additionalClasses="px-6 pt-6 pb-3 flex-col">
              <Typhography
                type="h2Bold"
                component="p"
                color="text-grey-900"
                additionalClasses="mb-6"
              >
                WHAT NOTIFICATIONS YOU RECEIVE?
              </Typhography>
              {data?.accountNotificationPreferences?.nodes
                ?.filter((item: any) => !excludingList.includes(item.notification_name))
                .sort((a: any, b: any) => {
                  const a1 = fixedNotificationPrefs.includes(a.notification_name);
                  const b1 = fixedNotificationPrefs.includes(b.notification_name);
                  const c1 = 'READERS_DIGEST' === b.notification_name;
                  if ((a1 && !b1) || c1) {
                    return -1;
                  } else if (!a1 && b1) {
                    return 1;
                  }
                  return 0;
                })
                .map((item: any) => {
                  const getName: any = item.notification_name;

                  return (
                    <div className="flex flex-col w-full mb-[10px]" key={getName}>
                      <NotificationSwitch
                        register={register}
                        name={getName}
                        id={getName}
                        label={nameTags[getName].value}
                        defaultChecked={item.is_active}
                        readonly={
                          fixedNotificationPrefs.includes(getName) ||
                          !isPermitted(user, 'edit-vendor-profile')
                        }
                        value={item.is_active}
                        onChange={(e: any) => {
                          updateMapPrefs(item.id, e.target.checked);
                        }}
                        isDigest={item?.notification_name === 'READERS_DIGEST'}
                        isWeeklySelected={weeklyDigestItem?.is_active}
                        onRadioChange={(isWeekly) => updateMapPrefs(weeklyDigestItem?.id, isWeekly)}
                      />
                    </div>
                  );
                })}
            </Card>

            <Card cardType="whiteBorder" additionalClasses="px-6 pt-6 pb-3 flex-col">
              <Typhography
                type="h2Bold"
                component="p"
                color="text-grey-900"
                additionalClasses="mb-6"
              >
                WHERE YOU RECEIVE NOTIFICATIONS?
              </Typhography>
              <div className="flex w-full mb-[10px]">
                <Checkbox
                  register={register}
                  name={'email'}
                  id="email"
                  label={'Email'}
                  isLeftAligned={false}
                  readonly={!isPermitted(user, 'edit-vendor-profile')}
                />
              </div>
              <div className="flex w-full mb-[10px]">
                <Checkbox
                  register={register}
                  name={'text'}
                  id="text"
                  label={'Text message'}
                  isLeftAligned={false}
                  readonly={!isPermitted(user, 'edit-vendor-profile')}
                />
              </div>
            </Card>
          </Grid>

          <div className="flex items-start justify-between w-full mt-6 mb-6 text-grey-700">
            <div className="flex items-center w-full">
              <Icon
                iconColor="inherit"
                iconType="LockedIcon"
                additionalClasses="relative -top-[2px]"
              />
              <Typhography
                component="p"
                type="h2"
                additionalClasses=" !leading-6 !font-normal mr-6 ml-2 !text-13s"
                color="text-grey-700"
              >
                These messages are required. PayUp will send you an important notifications about
                your payouts.
              </Typhography>
            </div>
          </div>
          {message !== '' && message !== undefined && (
            <ErrorModal
              message={message}
              type={updateSuccess ? 'success' : 'error'}
              title="Notification Preferences"
              onCloseModalEvent={handleErrorModalClose}
              additionalClasses="mb-6"
            />
          )}
        </form>
      </div>
    </>
  );
};
