import React, { InputHTMLAttributes } from 'react';
import classNames from 'classnames';

import Label from '../Label/Label';
import Icon from '../../Media/Icon/Icon';

interface RadioProps extends InputHTMLAttributes<HTMLInputElement> {
  /**
   * Set Input name
   */
  name?: string;
  /**
   * Set Input label name
   */
  label?: string | undefined;
  /**
   * Optional for additional classes
   */
  additionalClasses?: string | undefined;
  id?: any;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  variant?: 'default' | 'box' | 'small';
  wrapperClasses?: string;
  readOnly?: boolean;
}

/**
 * Primary UI component for user interaction
 */
const RadioButton: React.FC<RadioProps> = ({
  name,
  label,
  type = 'text',
  additionalClasses,
  onChange,
  id,
  variant = 'default',
  wrapperClasses = '',
  readOnly,
  ...rest
}) => {
  const defaultClasses = 'opacity-0 absolute -left-4 radio-input';
  const checkboxClasses = [defaultClasses, additionalClasses];

  const handleDisableState = readOnly ? 'opacity-50 pointer-events-none' : 'opacity-100';

  const InputElement = () => {
    return (
      <input
        type="radio"
        name={name}
        className={classNames(checkboxClasses)}
        {...rest}
        id={id}
        onChange={onChange}
      />
    );
  };

  const handleTypes = (variant?: 'default' | 'box' | 'small') => {
    switch (variant) {
      case 'default':
        return (
          <Label
            name={id}
            labelSpanClasses={classNames([
              'radio-span justify-center items-center inline-flex',
              handleDisableState,
            ])}
          >
            {InputElement()}
            <span className="mr-3 radio-mask" />
            {label}
          </Label>
        );
      case 'box':
        return (
          <Label
            name={id}
            labelSpanClasses={classNames([
              'radio-span-box justify-center items-center inline-flex',
              handleDisableState,
            ])}
          >
            {InputElement()}
            <span className="w-5 h-5 border border-t-0 border-r-0 radio-mask-box rounded-tr-md">
              <Icon iconType="OkIcon" iconColor="inherit" />
            </span>
            {label}
          </Label>
        );
      case 'small':
        return (
          <Label
            name={id}
            labelSpanClasses={classNames([
              'radio-span-small justify-center items-center inline-flex text-13s text-grey-700',
              handleDisableState,
            ])}
          >
            {InputElement()}
            <span className="mr-[6px] radio-mask-small" />
            {label}
          </Label>
        );
      default:
        <Label
          name={id}
          labelSpanClasses={classNames([
            'radio-span justify-center items-center inline-flex',
            handleDisableState,
          ])}
        >
          {InputElement()}
          <span className="mr-3 radio-mask" />
          {label}
        </Label>;
    }
  };

  return (
    <div className={classNames(['relative overflow-hidden form-check', wrapperClasses])}>
      {handleTypes(variant)}
    </div>
  );
};

export default RadioButton;
