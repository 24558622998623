import { useCurrencyFormat } from 'hooks/useCurrencyFormat';
import { Grid } from '../../Grid/Grid';
import { StatusBlock } from '../../StatusBlock/StatusBlock';

export const VendorPropertiesStatsBar = ({ data }: any) => {
  const totalInvoicedAmount = data.filteredTotalInvoicedValueSum
    ? data.filteredTotalInvoicedValueSum
    : '0';
  const paidByPropertiesAmount = data.filteredPaidByProptertiesSum
    ? data.filteredPaidByProptertiesSum
    : '0';
  const totalOwnedAmount = data.filteredUnpaidByPropertiesSum
    ? data.filteredUnpaidByPropertiesSum
    : '0';

  return (
    <Grid columnCount={3} additionalClasses={'w-full mb-6'}>
      <StatusBlock
        firstValue={useCurrencyFormat(totalInvoicedAmount)}
        blockHeading={'Total invoiced'}
        isIconVisible={false}
        isRemoveBorderPadding
      />
      <StatusBlock
        firstValue={useCurrencyFormat(paidByPropertiesAmount)}
        blockHeading={'Paid by Property'}
        isIconVisible={false}
      />
      <StatusBlock
        firstValue={useCurrencyFormat(totalOwnedAmount)}
        blockHeading={'Total Owed by Property'}
        isIconVisible={false}
      />
    </Grid>
  );
};
