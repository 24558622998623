import { Grid, LoadingSpinner, Message, SettingBalanceCards, Typhography } from 'components/core';
import { useEffect, useState } from 'react';
import { useUpdateCustomerPreferenceMutation } from 'services/customersApi';
import { Images } from 'shared/utilities/images';

export const BalanceCards = () => {
  const [selectedValue, setSelectedValue]: any = useState();
  const [updateCustomerPreference, { isLoading, isError, isSuccess, error }]: any =
    useUpdateCustomerPreferenceMutation();

  const handleOnChange = async (event: any) => {
    setSelectedValue(event.target.value);

    const setObj = {
      data: {
        balanceCalculation: event.target.value,
      },
      key: 'setting',
    };

    await updateCustomerPreference(setObj);
  };

  useEffect(() => {
    const getLocalStorageSettingItem = localStorage.getItem('balance_settings');
    if (getLocalStorageSettingItem) {
      setSelectedValue(getLocalStorageSettingItem);
    }
  }, []);

  return (
    <div className="flex flex-col w-full">
      {isLoading && <LoadingSpinner />}
      <Typhography type="h2" component="p" color="text-grey-900" additionalClasses="mb-4 uppercase">
        Your Balance view
      </Typhography>

      {(isError || isSuccess) && (
        <Message
          type={isError ? 'error' : 'success'}
          title="Settings - Balance"
          additionalClasses="max-w-[1356px]"
        >
          {isSuccess ? `Balance view successfully updated.` : `${error?.data?.message}.`}
        </Message>
      )}

      <Grid columnCount={2} columnGap={4} additionalClasses="max-w-[1380px] pr-6">
        <SettingBalanceCards
          inputName="calculate"
          inputID="day"
          inputValue="day"
          cardTitle="Calculate by Day"
          cardIcon="TimeCalculation"
          cardContent="Balance helps to keep and track all payments you owe in one place. Your current balance will be shown for the last 24 hours."
          cardImageSrc={Images.CALCULATION_BY_DAY}
          onChange={handleOnChange}
          selectedValue={selectedValue}
        />
        <SettingBalanceCards
          inputName="calculate"
          inputID="week"
          inputValue="week"
          cardTitle="Calculate by Week"
          cardIcon="CalendarIcon"
          cardContent="The week view  will show your current balance and invoices for the last 7 days."
          cardImageSrc={Images.CALCULATION_BY_WEEK}
          onChange={handleOnChange}
          selectedValue={selectedValue}
        />
      </Grid>
    </div>
  );
};
