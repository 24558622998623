import moment from 'moment';
import { Navigate, createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';

import Wrapper from '../../../layouts/Wrapper/Wrapper';
import ListEmpty from '../../Common/ListEmpty/ListEmpty';
import {
  Grid,
  StatusBlock,
  ToolBar,
  Headers,
  DataList,
  SelectBox,
  LoadingSpinner,
} from 'components/core';

import { approvedTabnavData } from 'constants/TabNavData';
import { CONFIG } from 'shared/configs/config';

import { getURLParams, useDateRangeSorting } from 'hooks/useDateRangeSorting';
import { useCurrencyFormat } from 'hooks/useCurrencyFormat';
import {
  serverSidePaginationActiveForInvoices,
  useServerSidePagination,
} from 'hooks/useServerSidePagination';
import { useShowOptionHandler } from 'hooks/useShowOptionHandler';

import { helperApprove } from '../../../../helpers/modules/helperInvoices';

import { useGetPayoutsQuery } from '../../../../services/payoutsApi';
import { isPermitted, useGetUserQuery } from 'services/userApi';

import { toApproveHiddenColumns, toApproveInvoicesColumnOrder } from 'constants/inits';

const SubmittedContainer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { dashboardServerSideFilteration } = useFlags();
  const serverSidePaginationActive =
    dashboardServerSideFilteration && serverSidePaginationActiveForInvoices;

  const [pageSizeOptions, paginationState, previousButtonClick, nextButtonClick, setPageSize]: any =
    useServerSidePagination();
  const [tableColumns, handleIconClick] = helperApprove('TO-APPROVED');
  const [finalQueryParam, setFinalQueryParam]: any = useState();

  const [
    rangeSelector,
    defaultSortingText,
    itemSlectedState,
    showDropdown,
    handlerSortingDropDownClick,
    handlerSortingOption,
  ]: any = useDateRangeSorting('All');

  const intialQuery = {
    status: { equalTo: 'INVOICED' },
    account: { status: { equalTo: 'ACTIVE' } },
    invoiced_at: {
      lessThan: moment()
        .add(1, 'day')
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .format(),
      greaterThan:
        rangeSelector > 0
          ? moment()
              .subtract(rangeSelector, 'day')
              .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
              .format()
          : undefined,
    },
  };

  useEffect(() => {
    setFinalQueryParam(intialQuery);
  }, [defaultSortingText]);

  const onClickFilterApplyButton = (finalQuery: any) => {
    if (finalQuery !== false) {
      const arrangeQuery = { ...intialQuery, ...finalQuery };
      setFinalQueryParam(arrangeQuery);
    } else {
      setFinalQueryParam(intialQuery);
    }
  };

  const toApproveParams = {
    first: serverSidePaginationActive ? paginationState.pageSize : CONFIG.DATA_OVERALL_LIMIT,
    offset: serverSidePaginationActive ? paginationState.pageIndex * paginationState.pageSize : 0,
    orderBy: 'INVOICED_AT_DESC',
    filter: !dashboardServerSideFilteration ? intialQuery : finalQueryParam,
  };

  const [filteredRow, setFilteredRow] = useState([]);
  const [visibleColumns, setVisibleColumns] = useState([]);

  /**
   * handle data request from redux_toolkit
   */
  const { data: user } = useGetUserQuery({});
  const { data, isLoading, isSuccess, isError, isFetching }: any =
    useGetPayoutsQuery(toApproveParams);

  const paramsForDownloadAllData = {
    first: CONFIG.DATA_OVERALL_LIMIT,
    offset: 0,
    orderBy: 'INVOICED_AT_DESC',
    filter: !dashboardServerSideFilteration ? intialQuery : finalQueryParam,
  };

  // set totalCount, totalRecords, & totalPageCount into constant
  const totalCount: number | undefined =
    isSuccess && data?.payouts !== null ? data?.payouts.totalCount : 0;
  const totalRecords: number = isSuccess && data?.payouts !== null ? data?.payouts?.totalCount : 0;
  const totalPageCount = Math.ceil(totalRecords / paginationState.pageSize);

  useEffect(() => {}, [isSuccess]);

  useEffect(() => {
    navigate(
      {
        pathname: location.pathname,
        search: `?${createSearchParams(getURLParams(defaultSortingText))}`,
      },
      { replace: true }
    );
  }, [defaultSortingText]);

  let totalAmount = 0;
  let totalTransactionCount = 0;
  if (filteredRow) {
    // eslint-disable-next-line array-callback-return
    filteredRow?.map((data: any) => {
      const amount = data?.values?.amount;
      totalAmount = totalAmount + Number(amount ?? 0.0);
      totalTransactionCount = totalTransactionCount + 1;
    });
  }

  const [
    hiddenColumns,
    toggleHiddenColumnChanged,
    setToggleHiddenColumnChanged,
    columnList,
    setColumnList,
    payoutNodes,
  ] = useShowOptionHandler({
    tableColumns: tableColumns,
    data: isSuccess ? data?.payouts?.nodes : [],
    additionalColumns: toApproveHiddenColumns,
  });

  // set permision for Hero stats trendlines
  const isPermitToVisbleAllRange =
    defaultSortingText === '500' || defaultSortingText === 'All' || defaultSortingText === 'YTD';
  const setChangePercentageCountOfStatus =
    data?.payouts?.change_percentage_count_of_status_requested ?? null;
  const setChangePercentageOfStatus = data?.payouts?.change_percentage_of_status_requested ?? null;

  return (
    <Wrapper additionalClasses="flex-col items-start" pageTitle="Invoices">
      <Headers
        headingContent="Invoices"
        isButtonVisibled={isPermitted(user, 'paynow-option')}
        buttonLabel={'Pay Invoices Now'}
        isDisabled={true}
      />

      {/**
       * Handle loader
       */}
      {isLoading && <LoadingSpinner />}
      {/**
       * Error handling
       */}
      {isError && <Navigate to={'/401'} />}

      <ToolBar
        navigationData={approvedTabnavData}
        isDownloadCsvButtonEnabled={totalCount !== 0}
        isShowOptionEnabled={totalCount !== 0}
        columns={columnList}
        handleIconClick={(item: any) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          handleIconClick(
            item,
            columnList,
            setColumnList,
            setToggleHiddenColumnChanged,
            toggleHiddenColumnChanged
          );
        }}
        filteredRow={filteredRow}
        visibleColumns={visibleColumns}
        isFilterButtonEnabled
        onClickFilterApplyButton={onClickFilterApplyButton}
        isDownloadCompleteData={true}
        queryParam={paramsForDownloadAllData}
      >
        <SelectBox
          itemClickHandler={handlerSortingOption}
          defaultSelectBoxText={defaultSortingText}
          itemSelected={itemSlectedState}
          dropDownState={showDropdown}
          dropDownClickHandler={handlerSortingDropDownClick}
        />
      </ToolBar>

      {isSuccess && (
        <React.Fragment>
          <Grid columnCount={2} additionalClasses={'w-full mb-2'}>
            <StatusBlock
              firstValue={data.payouts?.totalCount === 0 ? `0` : `${totalCount}`}
              secondValue={
                !isPermitToVisbleAllRange && setChangePercentageCountOfStatus
                  ? '+' + data.payouts?.change_percentage_count_of_status_requested + '%'
                  : ''
              }
              thirdValue={
                !isPermitToVisbleAllRange && setChangePercentageCountOfStatus
                  ? defaultSortingText
                  : ''
              }
              blockHeading={'Invoices Submitted'}
              isIconVisible={!isPermitToVisbleAllRange && setChangePercentageCountOfStatus}
              isRemoveBorderPadding
            />
            <StatusBlock
              firstValue={useCurrencyFormat(data.payouts?.aggregates?.sum.amount)}
              secondValue={
                !isPermitToVisbleAllRange && setChangePercentageOfStatus
                  ? '+' + data.payouts?.change_percentage_of_status_requested + '%'
                  : ''
              }
              thirdValue={
                !isPermitToVisbleAllRange && setChangePercentageOfStatus ? defaultSortingText : ''
              }
              blockHeading={'Amount Submitted'}
              isIconVisible={!isPermitToVisbleAllRange && setChangePercentageOfStatus}
            />
          </Grid>

          {payoutNodes && payoutNodes.length > 0 ? (
            <DataList
              data={payoutNodes}
              setFilteredRow={setFilteredRow}
              editNetTerm={true}
              isLink={true}
              subTabType="toApprove"
              setVisibleColumns={setVisibleColumns}
              hiddenColumnList={hiddenColumns}
              toggleHiddenColumnChanged={toggleHiddenColumnChanged}
              isServerSidePagination={serverSidePaginationActive}
              previousPageClick={previousButtonClick}
              nextPageClick={nextButtonClick}
              setPageSizeValue={setPageSize}
              options={pageSizeOptions}
              totalPageCount={totalPageCount}
              pageSizeVal={paginationState.pageSize}
              pageIndexVal={paginationState.pageIndex}
              columnOrder={toApproveInvoicesColumnOrder}
              isDataFetching={isFetching}
            />
          ) : (
            <ListEmpty />
          )}
        </React.Fragment>
      )}
    </Wrapper>
  );
};

export default SubmittedContainer;
