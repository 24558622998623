import { createApi } from '@reduxjs/toolkit/query/react';
import { prepareBaseQuery } from '../shared/configs/config';
import { PaymentSource } from '../shared/models/services/paymentsources.model';

export const paymentSourcesApi = createApi({
  reducerPath: 'paymentSourcesApi',
  tagTypes: ['PaymentSources'],
  baseQuery: prepareBaseQuery(),
  endpoints: (builder) => ({
    getPaymentSources: builder.query<PaymentSource[], string>({
      query: (state) => ({
        url: `/customer/payment-sources`,
        method: 'GET',
      }),
      providesTags: ['PaymentSources'],
    }),
    delete: builder.mutation({
      query: ({ id }) => ({
        url: `/customer/payment-source/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['PaymentSources'],
    }),
    createSource: builder.mutation({
      query: (state) => ({
        url: `/customer/payment-source`,
        method: 'POST',
        body: state,
      }),
      invalidatesTags: ['PaymentSources'],
    }),
    verifySource: builder.mutation({
      query: (state) => ({
        url: `customer/payment-source/${state.id}/verify`,
        method: 'POST',
        body: { amounts: [state.amount1, state.amount2] },
      }),
      invalidatesTags: ['PaymentSources'],
    }),
    setDefaultSource: builder.mutation({
      query: ({ id }) => ({
        url: `customer`,
        method: 'POST',
        body: { default_payment_source: id },
      }),
      invalidatesTags: ['PaymentSources'],
    }),
    getDefaultPaymentSources: builder.query({
      query: () => ({
        url: `/customer`,
        method: 'GET',
      }),
      providesTags: ['PaymentSources'],
    }),
    getPropertyPaymentSorces: builder.query({
      query: (id) => ({
        url: `/customer/payment-sources`,
        method: 'GET',
        params: {
          property_id: id,
        },
      }),
      providesTags: ['PaymentSources'],
    }),
    updatePaymentSource: builder.mutation({
      query: (object) => ({
        url: `/customer/payment-source/${object.id}`,
        method: 'POST',
        body: {
          account_holder_name: object.account_holder_name,
          account_holder_type: object.account_holder_type,
          label: object.label,
          property_id: object.property_id,
        },
      }),
      invalidatesTags: ['PaymentSources'],
    }),
    createSetupIntent: builder.mutation({
      query: (args) => ({
        url: `/customer/setup-intent`,
        method: 'POST',
        body: args,
      }),
      invalidatesTags: ['PaymentSources'],
    }),
    getSetupIntents: builder.query({
      query: (id) => ({
        url: `/customer/setup-intents`,
        method: 'GET',
        params: {
          property_id: id,
        },
      }),
      providesTags: ['PaymentSources'],
    }),
    cancelSetupIntent: builder.mutation({
      query: (args) => ({
        url: `/customer/cancel-setup-payment-intent`,
        method: 'POST',
        params: {
          setup_payment_intent_id: args,
        },
      }),
      invalidatesTags: ['PaymentSources'],
    }),
  }),
});

export const {
  useGetPaymentSourcesQuery,
  useDeleteMutation,
  useCreateSourceMutation,
  useVerifySourceMutation,
  useSetDefaultSourceMutation,
  useGetDefaultPaymentSourcesQuery,
  useGetPropertyPaymentSorcesQuery,
  useUpdatePaymentSourceMutation,
  useCreateSetupIntentMutation,
  useGetSetupIntentsQuery,
  useCancelSetupIntentMutation,
} = paymentSourcesApi;
