import React, { useState, useMemo } from 'react';

import SuperTable from '../SuperTable';

import { useGetUserQuery } from '../../../../services/userApi';
import { DataListListProps } from 'shared/models/components/base.model';

import { handleVendorsTableColumn } from '../Columns/VendorsTableColumns';
import { handlePropertiesTableColumn } from '../Columns/PropertiesTableColumn';
import { handleApprovalsTableColumn } from '../Columns/ApprovalsTableColumn';
import { InvoicePDFModal } from '../../Modals/InvoicePDFModal/InvoicePDFModal';
import { SingleTermEditModal } from '../../Modals/SingleTermEditModal/SingleTermEditModal';
import { useGetPayoutTermInfoMutation } from 'services/vendorsApi';
import { reOrderingTheTerms } from 'helpers/arrayFormating';
import { handleTransactionTableColumn } from '../Columns/TransactionTableColumn';
import { Controller } from 'react-hook-form';

import {
  Button,
  ConfirmationModal,
  Icon,
  Input,
  Message,
  Textarea,
  Typhography,
} from 'components/core';

import Modal from 'components/core/Modals/Modal/Modal';
import { usePayModeSet } from 'hooks/usePayModeSet';
import classNames from 'classnames';

const DataList: React.FC<DataListListProps> = ({
  data,
  setFilteredRow,
  editNetTerm = false,
  isPagination = true,
  options = [25, 50, 75, 100, 125, 150, 200],
  isLink = false,
  hiddenColumnList,
  toggleHiddenColumnChanged = false,
  setVisibleColumns,
  enableSelect = false,
  setSelectedRows,
  tableType = 'approve',
  subTabType = 'approve',
  tableWrapperType = 'grid-height-normal',
  isServerSidePagination,
  previousPageClick,
  nextPageClick,
  pageSizeVal,
  setPageSizeValue,
  totalPageCount,
  pageIndexVal,
  paymentMethodData,
  columnOrder,
  isDataFetching,
}) => {
  const [useGetPayouts, netTermData] = useGetPayoutTermInfoMutation();
  const payoutTerms = netTermData?.data?.terms ? netTermData?.data?.terms : [];
  const sorted = reOrderingTheTerms(payoutTerms);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPayoutId, setSelectedPayoutId]: any = useState(null);
  const [currentNetTerm, setCurrentNetTerm]: any = useState();

  const handleIsModalOpen = (id: any, payout_term_id: string, amount: string, desc: string) => {
    setCurrentNetTerm(payout_term_id);
    setIsModalOpen(true);
    setSelectedPayoutId(id);
    useGetPayouts({ amount: amount, payoutId: id });
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const { data: user } = useGetUserQuery({});

  const setPaymentMethodsData = useMemo(() => paymentMethodData, [paymentMethodData, data]);

  const [
    modalOpenPayMode,
    confirmModalOpenPayMode,
    isErrorInUnProcessingPayout,
    messagePayMode,
    messageStatePayMode,
    isPMLoading,
    isUMLoading,
    register,
    handleSubmit,
    control,
    errors,
    onPayModeModalDisplayModalEvent,
    onSubmitEvent,
    onCloseModalEvent,
    onBackButtonEvent,
    onConfirmationEvent,
    payModeType,
  ] = usePayModeSet();

  /**
   * Handle the columns for table according to the table type
   */
  const handleColumns = () => {
    switch (tableType) {
      case 'approve':
        return handleApprovalsTableColumn({
          isLink,
          editNetTerm,
          handleIsModalOpen,
          user,
          subTabType,
          onPayModeModalDisplayModalEvent,
        });
      case 'vendor':
        return handleVendorsTableColumn({ subTabType, user });
      case 'properties':
        return handlePropertiesTableColumn({
          subTabType,
          paymentMethodData: setPaymentMethodsData,
          user,
        });
      case 'transactions':
        return handleTransactionTableColumn({ subTabType, user });
      default:
        break;
    }
  };

  const columns = React.useMemo(
    () => handleColumns(),
    [data, toggleHiddenColumnChanged, subTabType, tableType, enableSelect]
  );

  const [invoiceModalOpen, setInvoiceModalOpen] = useState(false);
  const [invoiceID, setInvoiceID] = useState('');

  const handleInvoiceLinkClick = (e: any) => {
    const getPayoutID = e.target.getAttribute('data-id');
    setInvoiceID(getPayoutID);
    setInvoiceModalOpen(true);
  };

  const handleInvoiceModalCloseEvent = () => {
    setInvoiceModalOpen(false);
  };

  return (
    <div
      className={classNames([
        'flex flex-col w-full ease-out duration-300',
        isDataFetching ? 'opacity-30' : 'opacity-100',
      ])}
    >
      <SuperTable
        columns={columns}
        hiddenColumns={hiddenColumnList ? hiddenColumnList : []}
        data={data}
        columnOrder={columnOrder}
        paginationOptions={options}
        setFilteredRow={setFilteredRow}
        isPagination={isPagination}
        setVisibleColumns={setVisibleColumns}
        enableSelect={enableSelect}
        setSelectedRows={setSelectedRows}
        tableWrapperType={tableWrapperType}
        handleInvoiceLinkClick={handleInvoiceLinkClick}
        tableType={tableType}
        isServerSidePagination={isServerSidePagination}
        previousPageClick={previousPageClick}
        nextPageClick={nextPageClick}
        pageSizeVal={pageSizeVal}
        setPageSizeValue={setPageSizeValue}
        totalPageCount={totalPageCount}
        pageIndexVal={pageIndexVal}
      />

      {invoiceModalOpen && (
        <InvoicePDFModal onCloseEvent={handleInvoiceModalCloseEvent} payoutId={invoiceID} />
      )}

      {isModalOpen && (
        <>
          <SingleTermEditModal
            onClose={handleClose}
            defaultTermID={currentNetTerm}
            payoutTerms={sorted}
            payoutId={selectedPayoutId}
            isNetTermsLoading={netTermData.isLoading}
          />
        </>
      )}

      {modalOpenPayMode && (
        <>
          {(!confirmModalOpenPayMode || isErrorInUnProcessingPayout) && (
            <Modal
              isOpen={true}
              modalAdditionalClasses={
                'z-20 !w-[370px] flex flex-col bg-white justify-center rounded-lg shadow-importInvoicesModal absolute right-1 opacity-1 pt-6 pb-6 px-6'
              }
            >
              {!isErrorInUnProcessingPayout && (
                <Typhography component="p" type="h2" color="text-grey-900 mb-6 uppercase">
                  {'Process Single Payout'}
                </Typhography>
              )}

              <div
                className={`absolute cursor-pointer ${
                  isErrorInUnProcessingPayout ? 'top-3 right-3' : 'top-5 right-4'
                } text-grey-600`}
                onClick={onCloseModalEvent}
              >
                <Icon iconColor="inherit" iconType="CloseIcon" />
              </div>

              {messagePayMode !== '' && (
                <Message
                  type={messageStatePayMode}
                  title={
                    payModeType === 'ACH' ? 'Invoice Unreconciled' : 'Invoice Reconciled Externally'
                  }
                  additionalClasses={isErrorInUnProcessingPayout ? 'mb-3 mt-3' : 'mb-6'}
                >
                  {messagePayMode}
                </Message>
              )}

              {!isErrorInUnProcessingPayout && (
                <form
                  onSubmit={handleSubmit(onSubmitEvent)}
                  className={'w-full flex flex-col max-w-3xl'}
                  noValidate
                >
                  <div className="flex flex-col w-full">
                    <Controller
                      control={control}
                      name="mode"
                      render={({ field: { value } }) => (
                        <Input
                          id="mode"
                          labelName={'mode'}
                          type="text"
                          label="Pay Mode"
                          {...register('mode')}
                          error={errors?.mode?.message}
                          value={value}
                          readOnly
                          additionalClasses="capitalize"
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="external_account_payout_id"
                      render={({ field: { value } }) => (
                        <Input
                          id="external_account_payout_id"
                          labelName={'external_account_payout_id'}
                          type="text"
                          label="External Account Payout ID"
                          {...register('external_account_payout_id')}
                          value={value}
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="external_pay_id"
                      render={({ field: { value } }) => (
                        <Input
                          id="external_pay_id"
                          labelName={'external_pay_id'}
                          type="text"
                          label="External Pay Ref"
                          {...register('external_pay_id')}
                          value={value}
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="notes"
                      render={({ field: { value } }) => (
                        <Textarea
                          id="notes"
                          labelName={'notes'}
                          type="text"
                          label="Notes"
                          {...register('notes')}
                          error={errors?.notes?.message}
                          value={value}
                        />
                      )}
                    />
                  </div>
                  <div className="flex w-full pt-4">
                    <Button
                      type="submit"
                      buttonLabel={`Submit`}
                      isIconEnabled={false}
                      additionalClasses={'w-full'}
                    />
                  </div>
                </form>
              )}
            </Modal>
          )}

          {confirmModalOpenPayMode && (
            <ConfirmationModal
              handleBackEvent={onBackButtonEvent}
              handleSubmitEvent={onConfirmationEvent}
              isIconLoad={isPMLoading || isUMLoading}
              mainContent={`Are you sure you want to ${
                payModeType === 'ACH' ? 'undo' : 'process'
              } the external payout?`}
            />
          )}
        </>
      )}
    </div>
  );
};

export default DataList;
