import React from 'react';
import classNames from 'classnames';
import Icon from '../Media/Icon/Icon';
import { Typhography } from '../Typhography/Typhography';
import { defaultDropdownItems } from 'constants/DateRangeDropDownDatas';
import { handlerSelectBoxClasses } from './SelectBox.handler';

interface SelectBoxProps {
  items?: any[];
  itemClickHandler?: (e: React.MouseEvent<HTMLDivElement>) => void;
  dropDownClickHandler?: (e: React.MouseEvent<HTMLDivElement>) => void;
  defaultSelectBoxText?: string;
  itemSelected?: boolean;
  wrapperClasses?: string;
  additionalClasses?: string;
  filterMainLabel?: string;
  filterSubLabel?: string;
  dropDownState?: boolean;
  id?: string;
  textSpace?: string;
  isDisabled?: boolean;
  dropDownType?: 'small' | 'medium';
}

const SelectBox: React.FC<SelectBoxProps> = ({
  items = defaultDropdownItems,
  itemClickHandler,
  dropDownClickHandler,
  defaultSelectBoxText = 'Select Time',
  itemSelected = false,
  wrapperClasses,
  additionalClasses,
  filterMainLabel = '',
  filterSubLabel = '',
  dropDownState,
  id,
  textSpace = 'py-2',
  isDisabled = false,
  dropDownType = 'medium',
}) => {
  const handleClassesForTypes: any = handlerSelectBoxClasses(dropDownType, dropDownState);
  const dropDownSelectedText = defaultSelectBoxText !== '500' ? defaultSelectBoxText : 'All';

  return (
    <div className={classNames([wrapperClasses, 'ml-3 inline-flex items-center'])}>
      <div className={classNames([additionalClasses, 'min-w-[120px] relative inline-flex'])}>
        <div
          className={classNames([
            `inline-flex items-center justify-between px-3 cursor-pointer ${textSpace} w-full border border-solid`,
            handleClassesForTypes.defaultPreviewerClasses,
            handleClassesForTypes.showDropDownStateClasses,
            isDisabled ? 'opacity-50 pointer-events-none' : 'opacity-100',
          ])}
          onClick={dropDownClickHandler}
          id={id}
        >
          <div>
            <Typhography
              component="span"
              type={dropDownType === 'small' ? 'h2' : 'h1'}
              color={handleClassesForTypes.defaultTextColor}
              additionalClasses={itemSelected ? 'opacity-100' : 'opacity-30'}
            >
              {dropDownSelectedText}
            </Typhography>
          </div>
          <div
            className={classNames([
              'cursor-pointer',
              handleClassesForTypes.defaultDropDownIconColor,
            ])}
          >
            <Icon
              iconType={dropDownType === 'small' ? 'DropDownArrow' : 'DropDown'}
              iconColor="inherit"
              additionalClasses={`ease-out duration-300`}
            />
          </div>
        </div>
        {dropDownState && (
          <div
            className={classNames([
              'absolute left-0 right-0 z-50 px-2 py-2 bg-white rounded-lg shadow-dropDown',
              handleClassesForTypes.defaultDropDownWrapperClasses,
            ])}
          >
            {items.map((item, index) => (
              <div
                key={index}
                onClick={itemClickHandler}
                className={classNames([
                  'w-full cursor-pointer bg-white hover:bg-grey-50 ease-out duration-300',
                  handleClassesForTypes.optionClasses,
                  item.postfix === dropDownSelectedText ? 'pointer-events-none opacity-40' : '',
                ])}
                data-val={item.value}
                data-text={item.postfix}
              >
                {item.postfix}
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="inline-flex flex-col">
        <Typhography type="h10" color="text-primary-500" additionalClasses="opacity-50 text-xs">
          {filterMainLabel}
        </Typhography>
        <Typhography type="h10" color="text-primary-500" additionalClasses="opacity-50 text-xs">
          {filterSubLabel}
        </Typhography>
      </div>
    </div>
  );
};

export default SelectBox;
