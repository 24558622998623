import React, { useState } from 'react';

import { LoadingSpinner, Typho } from 'components/core';

import { AuthResponse } from '../../../../shared/models/services/login.model';
import {
  useCreateEmailVerificationCodeMutation,
  useCreateVerificationCodeMutation,
} from '../../../../services/authApi';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface ResendCodeProps {
  verificationData?: (data: AuthResponse) => void;
}

const ResendCode: React.FC<ResendCodeProps> = ({ verificationData }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { enableEmailLogin } = useFlags();

  const [addNewCode] = useCreateVerificationCodeMutation();
  const [addNewPost] = useCreateEmailVerificationCodeMutation();

  const resendText = async (data: any) => {
    if (enableEmailLogin) {
      const getEmail = String(localStorage.getItem('user_email'));
      try {
        setIsLoading(true);
        const data1 = await await addNewPost(getEmail).unwrap();
        setIsLoading(false);
        if (verificationData) {
          data1 && verificationData(data1);
        }
      } catch (error: any) {
        error.status !== null ? setIsLoading(false) : setIsLoading(true);
      }
    } else {
      const getNumber = String(localStorage.getItem('user_number'));
      try {
        setIsLoading(true);
        const data1 = await addNewCode({ ...data, phone: getNumber }).unwrap();
        setIsLoading(false);
        if (verificationData) {
          data1 && verificationData(data1);
        }
      } catch (error: any) {
        error.status !== null ? setIsLoading(false) : setIsLoading(true);
      }
    }
  };

  return (
    <React.Fragment>
      {isLoading && <LoadingSpinner />}
      <div onClick={resendText} className="mt-4">
        <Typho
          color="white"
          type="span"
          additionalClasses="opacity-100 hover:opacity-70 cursor-pointer"
          size="14s"
        >
          Resend Text Code
        </Typho>
      </div>
    </React.Fragment>
  );
};

export default ResendCode;
