import React, { useEffect, useState } from 'react';

import { Button } from '../../Button/Button';
import { BlockConfirmationContent } from '../BlockContents/BlockConfirmationContent';
import { BlockStatusContents } from '../BlockContents/BlockStatusContents';
import { OverlayLayer } from '../OverlayLayer/OverlayLayer';

import { useCreateImportInvoiceMutation } from '../../../../services/payoutsApi';
import { ImportModalProps } from 'shared/models/components/base.model';

const ImportModal = ({
  isBulkImport = false,
  offsetFromBottom = 0,
  targetheight = 0,
  isDisabled = false,
  invoiceIds,
  dependencyVariable,
}: ImportModalProps) => {
  const [modalConfirmationOpen, setModalConfirmationOpen] = useState(false);
  const [modalStatusOpen, setModalStatusOpen] = useState(false);
  const [isImportingInvoice, setIsImportingInvoice] = useState(false);
  const [createImportInvoice] = useCreateImportInvoiceMutation();

  useEffect(() => {
    setTimeout(() => {
      setModalConfirmationOpen(false);
      setModalStatusOpen(false);
    }, 2000);
  }, [dependencyVariable]);

  const handlePickerClose = () => {
    setModalConfirmationOpen(false);
    setModalStatusOpen(false);
  };

  const handleImportSubmit = async () => {
    setIsImportingInvoice(true);
    const invIds = invoiceIds;
    const response: any = await createImportInvoice(invIds);
    if (response) {
      setModalConfirmationOpen(false);
      setIsImportingInvoice(false);
      setModalStatusOpen(true);
    }
  };

  const handleBackEvent = () => {
    setModalConfirmationOpen(false);
    setModalStatusOpen(false);
  };

  const handleModalOpen = () => {
    setModalConfirmationOpen(true);
    setModalStatusOpen(false);
  };

  const handleTheModalOffsetClasses = () => {
    let offSetClasses = isBulkImport ? 'top-8 !opacity-100' : 'top-10 !opacity-100';
    const modalHeight = targetheight;

    if (modalHeight > offsetFromBottom) {
      offSetClasses = 'top-auto bottom-10 !opacity-100';
    }

    if (!isBulkImport && modalHeight < offsetFromBottom) {
      offSetClasses = 'top-10 !opacity-100';
    }

    if (isBulkImport && modalHeight < offsetFromBottom) {
      offSetClasses = 'top-8 !opacity-100';
    }

    return offSetClasses;
  };

  return (
    <div className="relative flex flex-col items-center">
      {!isBulkImport && (
        <Button
          variants="default"
          buttonLabel={isDisabled ? 'Imported' : 'Import'}
          additionalClasses={`w-[110px] ${
            isDisabled ? 'pointer-events-none opacity-30' : 'pointer-events-auto'
          }`}
          onClick={handleModalOpen}
          isDisabled={isDisabled}
        />
      )}

      {isBulkImport && (
        <div
          className={`w-full px-3 font-medium text-16s text-black-900 ${
            invoiceIds?.length > 1 ? 'cursor-pointer' : 'pointer-events-none opacity-30'
          }`}
          onClick={handleModalOpen}
        >
          Import All
        </div>
      )}

      {modalConfirmationOpen && !modalStatusOpen && (
        <>
          <OverlayLayer onOverlayClick={handlePickerClose} />
          <BlockConfirmationContent
            handleBackEvent={handleBackEvent}
            handleSubmitEvent={handleImportSubmit}
            isIconLoad={isImportingInvoice}
            mainContent={
              isBulkImport
                ? `Are you sure you want to import all ${invoiceIds?.length} invoices ?`
                : 'Are you sure you want to import this invoice ?'
            }
            additionalClasses={`px-6 py-9 ${isBulkImport ? 'min-h-[400px]' : 'min-h-[179px]'}`}
            additionalClassesWrapper={handleTheModalOffsetClasses()}
          />
        </>
      )}

      {modalStatusOpen && !modalConfirmationOpen && (
        <>
          <OverlayLayer onOverlayClick={handlePickerClose} />
          <BlockStatusContents
            additionalClasses={`px-6 pt-[20px] pb-[15px] ${
              isBulkImport ? 'min-h-[400px]' : 'min-h-[179px]'
            }`}
            additionalClassesWrapper={handleTheModalOffsetClasses()}
            onCloseButtonClick={handlePickerClose}
            mainContent={
              isBulkImport ? `${invoiceIds?.length} invoices Imported` : 'Invoice Imported'
            }
          />
        </>
      )}
    </div>
  );
};

export default ImportModal;
