import { useParams } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import Wrapper from 'components/layouts/Wrapper/Wrapper';
import { HeaderTabNavigation, Headers } from 'components/core';
import Payment from './Payment/Payment';

import { handlePropertiesDetailTabNavData } from 'constants/TabNavData';
import { CONFIG } from 'shared/configs/config';

import { isPermitted, useGetUserQuery } from 'services/userApi';
import { useGetConfigQuery } from 'services/configApi';
import { useGetPropertiesQuery } from 'services/properties';

export const PropertyPaymentContainer = () => {
  const { id } = useParams();
  const [detailTabNavData] = handlePropertiesDetailTabNavData(id);

  const filterParams = {
    limit: CONFIG.DATA_OVERALL_LIMIT,
    offset: 0,
    filter: {
      id: {
        equalTo: '"' + id + '"',
      },
    },
  };

  const configResponse = useGetConfigQuery('');
  const { data: user } = useGetUserQuery({});
  const { data, isSuccess: isPropertyDataLoadSuccess } = useGetPropertiesQuery(filterParams);

  return (
    <Wrapper
      additionalClasses="flex-col items-start"
      pageTitle={'Properties Details'}
      isBackButtonVisible
    >
      {isPropertyDataLoadSuccess && (
        <Headers
          headingContent={data.properties.nodes[0].name}
          headerWrapperClasses="border-none"
        />
      )}

      <HeaderTabNavigation tabNavData={detailTabNavData} />

      {configResponse?.data?.stripe_api_public_key && (
        <Elements stripe={loadStripe(configResponse?.data?.stripe_api_public_key)}>
          {isPermitted(user, 'payment-tab') && <Payment />}
        </Elements>
      )}
    </Wrapper>
  );
};
