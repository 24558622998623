import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import {
  useProcessSinglePayoutMutation,
  useUnprocessSinglePayoutMutation,
} from 'services/payoutsApi';

import { ERROR_MESSAGES } from 'constants/Validation';
import { initPayoutProcess } from 'constants/inits';

import { dtoSinglePayoutProcess } from 'shared/utilities/dto';
import { IProcessSinglePayout } from 'shared/models/components/service.model';
import { MessageTypes } from 'shared/models/components/common.model';

export const usePayModeSet = () => {
  // initialize states and setStates methods __
  const [modalOpenPayMode, setModalOpenPayMode] = useState<boolean>(false);
  const [confirmModalOpenPayMode, setConfirmModalOpenPayMode] = useState<boolean>(false);
  const [payModeSetFormData, setPayModeSetFormData] = useState<IProcessSinglePayout>({
    ...initPayoutProcess,
    id: '',
  });
  const [isErrorInUnProcessingPayout, setIsErrorInUnProcessingPayout] = useState<boolean>(false);
  const [messagePayMode, setMessagePayMode] = useState<string>('');
  const [messageStatePayMode, setMessageStatePayMode] = useState<MessageTypes>('error');
  const [coupaInvoiceNumber, setCoupaInvoiceNumber] = useState<string | undefined>('');
  const [payotID, setPayotID] = useState<string | undefined>('');
  const [payModeType, setPayModeType] = useState<string | undefined>('');

  // api calls __
  const [
    processMutation,
    { isLoading: isPMLoading, isError: isPMError, error: pMerror, isSuccess: isPMSuccess },
  ]: any = useProcessSinglePayoutMutation();
  const [
    unprocessMutation,
    { isLoading: isUMLoading, isError: isUMError, error: uMerror, isSuccess: isUMSuccess },
  ]: any = useUnprocessSinglePayoutMutation();

  // define react-hook form error schema validations __
  const schema = yup
    .object({
      mode: yup.string().required(ERROR_MESSAGES.REQUIRED),
      external_account_payout_id: yup.string().when({
        is: () => !getValues('external_pay_id'),
        then: yup.string().required(ERROR_MESSAGES.REQUIRED),
      }),
      external_pay_id: yup.string().when({
        is: () => !getValues('external_account_payout_id'),
        then: yup.string().required(ERROR_MESSAGES.REQUIRED),
      }),
      notes: yup.string().required(ERROR_MESSAGES.REQUIRED),
    })
    .required();

  // initializre the react-hook form __
  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<IProcessSinglePayout>({
    resolver: yupResolver(schema),
    defaultValues: initPayoutProcess,
  });

  // common set states methods used in reset events __
  const commonResetState = () => {
    setIsErrorInUnProcessingPayout(false);
    setMessagePayMode('');
    setMessageStatePayMode('error');
  };

  // click event for display the modal __
  const onPayModeModalDisplayModalEvent = (event?: any) => {
    commonResetState();

    const getInvoiceNumber = event.target.getAttribute('data-invoice');
    const getPayoutID = event.target.getAttribute('data-id');
    const getPayModeType = event.target.getAttribute('data-mode');

    setCoupaInvoiceNumber(getInvoiceNumber);
    setPayotID(getPayoutID);
    setPayModeType(getPayModeType);

    if (getPayModeType === 'ACH') {
      setConfirmModalOpenPayMode(true);
      setModalOpenPayMode(true);
    } else {
      setConfirmModalOpenPayMode(false);
      setModalOpenPayMode(true);
    }
  };

  // click event of submit button in pay mode form modal __
  const onSubmitEvent = (data: IProcessSinglePayout) => {
    setPayModeSetFormData({
      ...data,
      id: payotID,
    });

    setConfirmModalOpenPayMode(true);
  };

  // click event for close modal __
  const onCloseModalEvent = () => {
    setModalOpenPayMode(false);
    setConfirmModalOpenPayMode(false);
    dtoSinglePayoutProcess(setValue, initPayoutProcess);
    commonResetState();
  };

  // cancel event in confirmation modal __
  const onBackButtonEvent = () => {
    setConfirmModalOpenPayMode(false);

    if (payModeType === 'ACH') {
      setModalOpenPayMode(false);
      commonResetState();
    }
  };

  // click event of confirm button in confirmation form modal __
  const onConfirmationEvent = async () => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const is_set_external = payModeType === 'ACH';

    let response: any;

    try {
      if (!is_set_external) {
        response = await processMutation(payModeSetFormData);
      }

      if (is_set_external) {
        response = await unprocessMutation(payotID);
      }

      if (response) {
        setConfirmModalOpenPayMode(false);
        setIsErrorInUnProcessingPayout(true);
        setModalOpenPayMode(true);
      }
    } catch (error) {
      console.log(error);
    }

    dtoSinglePayoutProcess(setValue, initPayoutProcess);
  };

  // handle success end error messages __
  const handleMessageContent = () => {
    const setPMError = pMerror?.data?.error ?? pMerror?.data?.message;
    const setUMError = uMerror?.data?.error ?? uMerror?.data?.message;
    const setSuccessMessagePM = `The invoice ${
      coupaInvoiceNumber ? coupaInvoiceNumber : ''
    } has been successfully marked as externally paid.`;
    const setSuccessMessageUM = `The external payout for Invoice ${
      coupaInvoiceNumber ? coupaInvoiceNumber : ''
    } has been unreconciled.`;

    if (isPMError) {
      setMessagePayMode(setPMError);
      setMessageStatePayMode('error');
    } else if (isUMError) {
      setMessagePayMode(setUMError);
      setMessageStatePayMode('error');
    } else if (isUMSuccess) {
      setMessagePayMode(setSuccessMessageUM);
      setMessageStatePayMode('success');
    } else if (isPMSuccess) {
      setMessagePayMode(setSuccessMessagePM);
      setMessageStatePayMode('success');
    } else if (errors?.external_account_payout_id?.message || errors?.external_pay_id?.message) {
      setMessagePayMode('Either external Payout Id or Pay Ref is required');
      setMessageStatePayMode('error');
    } else {
      setMessagePayMode('');
    }
  };

  useEffect(() => {
    handleMessageContent();
  }, [isPMError, isUMError, isUMSuccess, isPMSuccess, errors]);

  return [
    modalOpenPayMode,
    confirmModalOpenPayMode,
    isErrorInUnProcessingPayout,
    messagePayMode,
    messageStatePayMode,
    isPMLoading,
    isUMLoading,
    register,
    handleSubmit,
    control,
    errors,
    onPayModeModalDisplayModalEvent,
    onSubmitEvent,
    onCloseModalEvent,
    onBackButtonEvent,
    onConfirmationEvent,
    payModeType,
  ];
};
