import React, { useEffect, useState } from 'react';
import { createSearchParams, Navigate, useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useFlags } from 'launchdarkly-react-client-sdk';

import Wrapper from '../../../layouts/Wrapper/Wrapper';
import ListEmpty from '../../Common/ListEmpty/ListEmpty';

import {
  Grid,
  StatusBlock,
  ToolBar,
  DataList,
  LoadingSpinner,
  SelectBox,
  TopPaymentBar,
  Headers,
} from 'components/core';

import { approvedTabnavData } from 'constants/TabNavData';
import { CONFIG } from 'shared/configs/config';

import { useCurrencyFormat } from 'hooks/useCurrencyFormat';
import { getURLParams, useDateRangeSorting } from 'hooks/useDateRangeSorting';
import {
  serverSidePaginationActiveForInvoices,
  useServerSidePagination,
} from 'hooks/useServerSidePagination';
import { useShowOptionHandler } from 'hooks/useShowOptionHandler';

import { helperApprove } from '../../../../helpers/modules/helperInvoices';

import { isPermitted, useGetUserQuery } from '../../../../services/userApi';
import { useGetPayoutsQuery } from '../../../../services/payoutsApi';

import { approveHiddenColumns, defaultInvoicesColumnOrder } from 'constants/inits';

const ApproveContainer = () => {
  const [pageSizeOptions, paginationState, previousButtonClick, nextButtonClick, setPageSize]: any =
    useServerSidePagination();
  const [tableColumns, handleIconClick, getInvoiceAmount] = helperApprove('APPROVED');
  const [finalQueryParam, setFinalQueryParam]: any = useState();
  const { dashboardServerSideFilteration } = useFlags();
  const serverSidePaginationActive =
    dashboardServerSideFilteration && serverSidePaginationActiveForInvoices;

  const [
    rangeSelector,
    defaultSortingText,
    itemSlectedState,
    showDropdown,
    handlerSortingDropDownClick,
    handlerSortingOption,
  ]: any = useDateRangeSorting('All');
  const navigate = useNavigate();
  const location = useLocation();

  const intialQuery = {
    approved_at: {
      lessThan: moment()
        .add(1, 'day')
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .format(),
      greaterThan:
        rangeSelector > 0
          ? moment()
              .subtract(rangeSelector, 'day')
              .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
              .format()
          : undefined,
    },
    or: [
      {
        status: { in: ['APPROVED'] },
        account: { status: { equalTo: 'ACTIVE' } },
      },
    ],
  };

  const onClickFilterApplyButton = (finalQuery: any) => {
    if (finalQuery !== false) {
      const arrangeQuery = { ...intialQuery, ...finalQuery };
      setFinalQueryParam(arrangeQuery);
    } else {
      setFinalQueryParam(intialQuery);
    }
  };

  useEffect(() => {
    setFinalQueryParam(intialQuery);
  }, [defaultSortingText]);

  const approvedParams = {
    first: serverSidePaginationActive ? paginationState.pageSize : CONFIG.DATA_OVERALL_LIMIT,
    offset: serverSidePaginationActive ? paginationState.pageIndex * paginationState.pageSize : 0,
    orderBy: 'INVOICED_AT_DESC',
    filter: !dashboardServerSideFilteration ? intialQuery : finalQueryParam,
  };

  const paramsForDownloadAllData = {
    first: CONFIG.DATA_OVERALL_LIMIT,
    offset: 0,
    orderBy: 'INVOICED_AT_DESC',
    filter: !dashboardServerSideFilteration ? intialQuery : finalQueryParam,
  };

  const [filteredRow, setFilteredRow] = useState([]);
  const [visibleColumns, setVisibleColumns] = useState([]);
  const [enableSelect, setEnableSelect] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [tlAmount, setTlAmount]: any = useState();
  const [selectedIds, setSelectedIds]: any = useState();
  const { data: user, isSuccess: userIsSuccess } = useGetUserQuery({});

  /**
   * handle data request from redux_toolkit
   */
  const { data, isLoading, isSuccess, isError, isFetching } = useGetPayoutsQuery(approvedParams);
  const totalCount: number | undefined = data?.payouts !== null ? data?.payouts.totalCount : 0;

  const totalRecords: number = data?.payouts !== null ? data?.payouts?.totalCount : 0;
  const totalPageCount = Math.ceil(totalRecords / paginationState.pageSize);

  useEffect(() => {}, [isSuccess]);

  useEffect(() => {
    navigate(
      {
        pathname: location.pathname,
        search: `?${createSearchParams(getURLParams(defaultSortingText))}`,
      },
      { replace: true }
    );
  }, [defaultSortingText]);

  let totalAmount = 0;
  let totalTransactionCount = 0;
  if (filteredRow) {
    // eslint-disable-next-line array-callback-return
    filteredRow?.map((data: any) => {
      const amount = data?.values?.amount;
      totalAmount = totalAmount + Number(amount ?? 0.0);
      totalTransactionCount = totalTransactionCount + 1;
    });
  }

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    getInvoiceAmount(selectedRows, setTlAmount, setSelectedIds);
  }, [selectedRows]);

  const buttonVisibility = (isPermittedUser: boolean): boolean => {
    return userIsSuccess && isPermittedUser && totalCount !== 0 ? true : false;
  };

  const isPermittedUser = isPermitted(user, 'paynow-option');
  const isPermitToVisbleAllRange =
    defaultSortingText === '500' || defaultSortingText === 'All' || defaultSortingText === 'YTD';

  const handlePaynowOption = () => {
    setEnableSelect(true);
    setSelectedIds(null);
    setSelectedRows([]);
  };

  const [
    hiddenColumns,
    toggleHiddenColumnChanged,
    setToggleHiddenColumnChanged,
    columnList,
    setColumnList,
    payoutNodes,
  ] = useShowOptionHandler({
    tableColumns: tableColumns,
    data: isSuccess ? data?.payouts?.nodes : [],
    additionalColumns: approveHiddenColumns,
    storageItemName: 'showOptionApprovedList',
  });

  const setChangePercentageCountOfStatus =
    data?.payouts?.change_percentage_count_of_status_requested ?? null;
  const setChangePercentageOfStatus = data?.payouts?.change_percentage_of_status_requested ?? null;

  return (
    <>
      {enableSelect && (
        <TopPaymentBar
          selectedRowsLength={selectedRows?.length}
          setEnableSelect={setEnableSelect}
          totalAmount={tlAmount}
          ids={selectedIds}
        />
      )}
      <Wrapper additionalClasses="flex-col items-start" pageTitle="Invoices">
        <Headers
          headingContent="Invoices"
          isButtonVisibled={isPermittedUser && buttonVisibility(isPermittedUser)}
          buttonLabel={'Pay Invoices Now'}
          onClick={handlePaynowOption}
          isDisabled={enableSelect || totalCount === 0}
        />
        {/**
         * Handle loader
         */}
        {isLoading && <LoadingSpinner />}
        {/**
         * Error handling
         */}
        {isError && <Navigate to={'/401'} />}
        <ToolBar
          navigationData={approvedTabnavData}
          isDownloadCsvButtonEnabled={totalCount !== 0}
          isShowOptionEnabled={totalCount !== 0}
          columns={columnList}
          handleIconClick={(item: any) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            handleIconClick(
              item,
              columnList,
              setColumnList,
              setToggleHiddenColumnChanged,
              toggleHiddenColumnChanged
            );
          }}
          filteredRow={filteredRow}
          visibleColumns={visibleColumns}
          isFilterButtonEnabled
          onClickFilterApplyButton={onClickFilterApplyButton}
          isDownloadCompleteData={true}
          queryParam={paramsForDownloadAllData}
        >
          <SelectBox
            itemClickHandler={handlerSortingOption}
            defaultSelectBoxText={defaultSortingText}
            itemSelected={itemSlectedState}
            dropDownState={showDropdown}
            dropDownClickHandler={handlerSortingDropDownClick}
          />
        </ToolBar>
        {isSuccess && (
          <React.Fragment>
            <Grid columnCount={2} additionalClasses={'w-full mb-2'}>
              <StatusBlock
                firstValue={data.payouts?.totalCount === 0 ? `0` : `${totalCount}`}
                secondValue={
                  !isPermitToVisbleAllRange && setChangePercentageCountOfStatus
                    ? '+' + data.payouts?.change_percentage_count_of_status_requested + '%'
                    : ''
                }
                thirdValue={
                  !isPermitToVisbleAllRange && setChangePercentageCountOfStatus
                    ? defaultSortingText
                    : ''
                }
                blockHeading={'Invoices Approved'}
                isRemoveBorderPadding
                isIconVisible={!isPermitToVisbleAllRange && setChangePercentageCountOfStatus}
              />
              <StatusBlock
                firstValue={useCurrencyFormat(data.payouts?.aggregates?.sum.amount)}
                secondValue={
                  !isPermitToVisbleAllRange && setChangePercentageOfStatus
                    ? '+' + data.payouts?.change_percentage_of_status_requested + '%'
                    : ''
                }
                thirdValue={
                  !isPermitToVisbleAllRange && setChangePercentageOfStatus ? defaultSortingText : ''
                }
                blockHeading={'Amount approved'}
                isIconVisible={!isPermitToVisbleAllRange && setChangePercentageOfStatus}
              />
            </Grid>

            {/*<Breadcrumbs selectedVal={`01/30/2022`} additionalClasses={`mb-6`} />*/}
            {payoutNodes && payoutNodes.length > 0 ? (
              <DataList
                data={data?.payouts?.nodes}
                setFilteredRow={setFilteredRow}
                editNetTerm={true}
                isLink={true}
                hiddenColumnList={hiddenColumns}
                toggleHiddenColumnChanged={toggleHiddenColumnChanged}
                setVisibleColumns={setVisibleColumns}
                enableSelect={enableSelect}
                setSelectedRows={setSelectedRows}
                subTabType="approve"
                isServerSidePagination={serverSidePaginationActive}
                previousPageClick={previousButtonClick}
                nextPageClick={nextButtonClick}
                setPageSizeValue={setPageSize}
                options={pageSizeOptions}
                totalPageCount={totalPageCount}
                pageSizeVal={paginationState.pageSize}
                pageIndexVal={paginationState.pageIndex}
                columnOrder={defaultInvoicesColumnOrder}
                isDataFetching={isFetching}
              />
            ) : (
              <ListEmpty />
            )}
          </React.Fragment>
        )}
      </Wrapper>
    </>
  );
};

export default ApproveContainer;
