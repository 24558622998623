import { Grid } from '../../Grid/Grid';
import { StatusBlock } from '../../StatusBlock/StatusBlock';
import moment from 'moment-timezone';
import { useCurrencyFormat } from 'hooks/useCurrencyFormat';
import { useEffect, useState } from 'react';
import { BalanceStatsBarProps, IdataObj } from 'shared/models/components/base.model';

const initialDataObj: IdataObj = {
  gridColumnCount: 3,
  totalInvoices: '0',
  amount: useCurrencyFormat(0),
  amountChangePercentage: '',
  isAmountTrendlineVisible: false,
  isAmountIconVisible: false,
  dueDate: '',
  isDueDateColumnVisible: true,
  pendingBalance: useCurrencyFormat(0),
  pendingBalanceChangePercentage: '',
  isPendingBalanceTrendlineVisible: false,
  isPendingBalanceIconVisible: false,
};

export const BalanceStatsBar = ({
  timeFrame = 'past',
  defaultSortingText,
  data,
}: BalanceStatsBarProps) => {
  const [dataObj, setDataObj] = useState(initialDataObj);
  const isPermitToVisbleAllRange =
    defaultSortingText === '500' || defaultSortingText === 'All' || defaultSortingText === 'YTD';

  useEffect(() => {
    const setPendingBalance =
      timeFrame === 'past'
        ? data.amount_received.aggregates.sum.amount
        : data?.amount_due?.aggregates?.sum?.amount;
    const setPendingBalanceIconVisible =
      data.pending_balance.aggregates.sum.amount === '0' ||
      isPermitToVisbleAllRange ||
      timeFrame !== 'past';
    const setAmountIconVisible =
      data.pending_balance.aggregates.sum.amount === '0' ||
      isPermitToVisbleAllRange ||
      timeFrame !== 'past';
    const setAmountChangePercentage =
      timeFrame === 'past' ? `${data.amount_received.change_percentage}%` : '';
    const setPendingBalanceChangePercentage =
      timeFrame === 'past' ? `${data.pending_balance.change_percentage}%` : '';

    setDataObj({
      ...dataObj,
      gridColumnCount: timeFrame === 'current' ? 4 : 3,
      totalInvoices: `${data.pending_balance.totalCount}`,
      amount: useCurrencyFormat(setPendingBalance),
      amountChangePercentage: setAmountChangePercentage,
      isAmountTrendlineVisible: setPendingBalance === '0' || isPermitToVisbleAllRange,
      isAmountIconVisible: setAmountIconVisible,
      dueDate: moment().tz('America/Chicago').format('MM/DD/YYYY'),
      isDueDateColumnVisible: timeFrame === 'current',
      pendingBalance: useCurrencyFormat(data.pending_balance.aggregates.sum.amount),
      pendingBalanceChangePercentage: setPendingBalanceChangePercentage,
      isPendingBalanceTrendlineVisible:
        data.pending_balance.aggregates.sum.amount === '0' || isPermitToVisbleAllRange,
      isPendingBalanceIconVisible: setPendingBalanceIconVisible,
    });
  }, [data]);

  return (
    <>
      <Grid columnCount={dataObj.gridColumnCount} additionalClasses={'w-full mb-3'}>
        <StatusBlock
          firstValue={dataObj.totalInvoices}
          blockHeading={'Invoices'}
          isIconVisible={true}
          iconType="InvoiceIcon"
          iconClasses={'relative top-1'}
          isRemoveBorderPadding
        />

        <StatusBlock
          blockHeading={'Amount'}
          firstValue={dataObj.amount}
          secondValue={!dataObj.isAmountTrendlineVisible ? dataObj.amountChangePercentage : ''}
          thirdValue={!dataObj.isAmountTrendlineVisible ? defaultSortingText : ''}
          iconType={'IncreaseMode'}
          iconColor={'text-green-400'}
          isIconVisible={!dataObj.isAmountIconVisible}
        />

        {dataObj.isDueDateColumnVisible && (
          <StatusBlock
            blockHeading={'Due Date'}
            firstValue={dataObj.dueDate}
            iconType={'TimerIcon'}
            iconClasses={'relative top-1'}
            isIconVisible
          />
        )}

        <StatusBlock
          blockHeading={'Pending Balance'}
          firstValue={dataObj.pendingBalance}
          secondValue={
            !dataObj.isPendingBalanceTrendlineVisible ? dataObj.pendingBalanceChangePercentage : ''
          }
          thirdValue={!dataObj.isPendingBalanceTrendlineVisible ? defaultSortingText : ''}
          iconType={'DecreaseMode'}
          secondValueColor={'text-red-400'}
          iconColor={'text-red-400'}
          isIconVisible={!dataObj.isPendingBalanceIconVisible}
        />
      </Grid>
    </>
  );
};
