import { CheckboxProps } from 'shared/models/components/base.model';
import Icon from '../../Media/Icon/Icon';
import classNames from 'classnames';

export const Checkbox = ({
  register,
  name,
  id,
  label,
  isLeftAligned = true,
  readonly,
  onChange,
}: CheckboxProps) => {
  return (
    <div
      className={classNames([
        'relative w-full mb-4 isLe',
        isLeftAligned ? 'form-check-box' : 'form-check-box-right-aligned',
        readonly ? 'opacity-50 pointer-events-none' : 'opacity-100',
      ])}
    >
      <input
        type="checkbox"
        id={id}
        {...register(name)}
        className="absolute w-0 h-0 p-0 m-0 overflow-hidden -left-4"
        onChange={onChange}
      />
      {isLeftAligned && (
        <span className="tick-icon">
          <Icon iconType="TickIconWhite" iconColor="white" />
        </span>
      )}
      {label && (
        <label htmlFor={id} className="font-normal bg-transparent text-grey-700 text-13s">
          {label}
        </label>
      )}
      {!isLeftAligned && (
        <span className="tick-icon">
          <Icon iconType="TickIconWhite" iconColor="white" />
        </span>
      )}
    </div>
  );
};
