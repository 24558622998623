import IndeterminateCheckbox from './IndeterminateCheckbox';
import moment from 'moment';
import { useCurrencyFormat } from 'hooks/useCurrencyFormat';
import EditTermModal from 'components/core/Modals/EditTermModal/EditTermModal';
import ImportModal from 'components/core/OldComponents/ImportModal/ImportModal';

import Icon from 'components/core/Media/Icon/Icon';
import { CONFIG } from '../../../../../../shared/configs/config';
import { ColumnSearchFilter } from './columnSearchFilter';
export const COLUMNS = [
  {
    id: 'id',
    accessor: 'id',
    // The header can use the table's getToggleAllRowsSelectedProps method
    // to render a checkbox
    Header: (props: any) => {
      return (
        <label htmlFor="select-all" className="th-checkboxes">
          <IndeterminateCheckbox id="select-all" {...props.getToggleAllRowsSelectedProps()} />
          <span className="non-selected">Select All</span>
          <span className="selected">Unselect All</span>
        </label>
      );
    },
    // The cell can use the individual row's getToggleRowSelectedProps method
    // to the render a checkbox
    Cell: (row: any) => {
      return (
        <div className="flex items-center justify-center w-[75px]">
          <label htmlFor={row?.row?.original?.coupa_invoice_id} className="td-checkboxes">
            <IndeterminateCheckbox
              id={row?.row?.original?.coupa_invoice_id}
              {...row.row.getToggleRowSelectedProps()}
            />
            <span className="w-4 h-4 text-white">
              <Icon iconType="TickIconWhite" iconColor="inherit" />
            </span>
          </label>
        </div>
      );
    },
    additionalClasses: 'w-[140px]',
  },
  {
    Header: 'Invoice Name',
    accessor: 'coupa_invoice_number',
    showSearch: true,
    Filter: ColumnSearchFilter,
    Cell: (row: any) => {
      // eslint-disable-next-line react/destructuring-assignment,@typescript-eslint/naming-convention
      const { coupa_invoice_number } = row.row.original;

      return (
        <div className="font-normal text-black capitalize text-18s">
          {coupa_invoice_number ? `Invoice #${coupa_invoice_number}` : '—'}
        </div>
      );
    },
    additionalClasses: 'w-auto',
  },
  {
    Header: 'Invoice Date',
    accessor: 'coupa_invoice_date',
    Cell: (row: any) => {
      // eslint-disable-next-line react/destructuring-assignment,@typescript-eslint/naming-convention
      const { coupa_invoice_date } = row.row.original;

      return (
        <div className="font-normal text-black capitalize text-18s">
          {coupa_invoice_date ? moment(coupa_invoice_date).format('MM/DD/YYYY') : '—'}
        </div>
      );
    },
    additionalClasses: 'w-60',
  },
  {
    Header: 'PayUp Invoice Date',
    accessor: 'invoiced_at',
    additionalClasses: 'w-auto text-gray-905',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    Cell: (row: any) => {
      // eslint-disable-next-line
      const { invoiced_at } = row.row.original;

      return (
        <div className="font-normal capitalize text-18s">
          {invoiced_at ? (
            <span className="text-inherit">{moment(invoiced_at).format('MM/DD/YYYY')}</span>
          ) : (
            <span className="text-gray-905">{'Assign NET Term first'}</span>
          )}
        </div>
      );
    },
  },
  {
    Header: 'Amount',
    accessor: 'amount',
    canFilter: false,
    additionalClasses: 'w-48',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    Cell: (row: any) => {
      // eslint-disable-next-line react/destructuring-assignment,@typescript-eslint/naming-convention
      const { amount } = row.row.original;
      // eslint-disable-next-line react/destructuring-assignment
      const amountPayout = useCurrencyFormat(parseFloat(amount));
      // eslint-disable-next-line react/destructuring-assignment
      return (
        <div className="font-normal text-black capitalize text-18s">
          {amount ? amountPayout : '—'}
        </div>
      );
    },
  },
  {
    Header: 'NET Term',
    accessor: 'payout_term_id',
    canFilter: false,
    additionalClasses: 'w-[258px]',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    Cell: (row: any) => {
      // eslint-disable-next-line
      const { payout_term_id, id, amount, isCoupa } = row.row.original;

      const curentRowIndex = row?.rows.findIndex((p: any) => p.id === row.row.id);
      const getTotalDataCount = row?.rows?.length;
      const getOffsetPositionLastElement =
        (getTotalDataCount - (curentRowIndex + 1)) * CONFIG.TABLE_ROW_HEIGHT;

      return (
        <div className="font-normal text-black capitalize text-18s">
          <EditTermModal
            isBulkEdit={false}
            payoutId={id}
            defaultTermID={payout_term_id}
            offsetFromBottom={getOffsetPositionLastElement}
            amount={amount}
            isCoupa={isCoupa}
            dependencyVariable={row}
          />
        </div>
      );
    },
  },
  {
    Header: ({ selectedFlatRows }: any) => {
      /*eslint-disable */
      const rowsArray = selectedFlatRows
        .filter((items: any) => {
          if (items.original.isCoupa) {
            return items;
          }
        })
        .map((values: any) => values?.original?.coupa_invoice_id);
      return (
        <ImportModal
          isBulkImport
          offsetFromBottom={0}
          invoiceIds={rowsArray}
          dependencyVariable={rowsArray}
        />
      );
      /*eslint-enable */
    },
    accessor: 'isCoupa',
    canFilter: false,
    additionalClasses: 'w-[110px]',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    Cell: (row: any) => {
      // eslint-disable-next-line react/destructuring-assignment

      const curentRowIndex = row?.rows.findIndex((p: any) => p.id === row.row.id);
      const getTotalDataCount = row?.rows?.length;
      const getOffsetPositionLastElement =
        (getTotalDataCount - (curentRowIndex + 1)) * CONFIG.TABLE_ROW_HEIGHT;
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { isCoupa, coupa_invoice_id } = row.row.original;

      return (
        <div className="flex w-full">
          <ImportModal
            offsetFromBottom={getOffsetPositionLastElement}
            targetheight={190}
            isDisabled={!isCoupa}
            invoiceIds={coupa_invoice_id ? [coupa_invoice_id.toString()] : []}
            dependencyVariable={row}
          />
        </div>
      );
    },
  },
];
