import React, { useEffect } from 'react';
import classNames from 'classnames';
import { useComponentVisible } from 'hooks/useComponentVisible';
import { useGetConfigQuery } from 'services/configApi';
import Icon from 'components/core/Media/Icon/Icon';
import {
  invoiceStatusses,
  propertyStatusses,
  vendorsStatusses,
  invoiceStatussesModal,
} from 'constants/inits';

export const handleStatusses = (gridType?: string) => {
  if (gridType === 'invoices') {
    return invoiceStatusses;
  } else if (gridType === 'properties') {
    return propertyStatusses;
  } else if (gridType === 'vendors') {
    return vendorsStatusses;
  } else if (gridType === 'invoices-modal') {
    return invoiceStatussesModal;
  } else {
    return [];
  }
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export function MultiCheckBoxColumnFilter({
  rows,
  column: { setFilter, preFilteredRows, id, Header, gridType },
}: any) {
  // destructuring useComponentVisible fields to close the modal-box on-clicking outside
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const [allChecked, setAllChecked] = React.useState(true);
  const configResponse = useGetConfigQuery('');
  const delinquentPropertyLocationCodes = configResponse?.data?.delinquent_property_location_codes;

  const [filterChanged, setFilterChanged] = React.useState(false);
  const [initLoad, setInitLoad] = React.useState(true);

  const options = React.useMemo(() => {
    const counts: any = {};
    preFilteredRows.forEach((x: any) => {
      x = x.values[id]?.toString().trim();
      if (x !== undefined) {
        counts[x] = (counts[x] || 0) + 1;
      }
    });
    return counts;
  }, [id, preFilteredRows, allChecked, filterChanged]);

  let arrayObject = [];
  const ObjectAssigned = Object.entries(options);
  const ObjectCheckedAssigned = Object.entries(handleStatusses(gridType));

  if (id === 'STATUS') {
    arrayObject = ObjectCheckedAssigned.filter(([o1]) => {
      return !ObjectAssigned.some(([o2]) => {
        return String(o1) === String(o2);
      });
    });

    arrayObject = [...ObjectAssigned, ...arrayObject];
  } else {
    arrayObject = [...ObjectAssigned];
  }

  const [checked, setChecked] = React.useState(Object.keys(options));

  useEffect(() => {
    initLoad && options?.length > 0 && setChecked(Object.keys(options));
  }, [options, initLoad]);

  const onChange = (e: any) => {
    setFilterChanged(!filterChanged);
    setInitLoad(false);
    const t = e.target.name.toString();

    if (checked && checked.includes(t) && e?.target?.checked) {
      setFilter(checked.filter((v: any) => v !== t));
      setChecked((prevChecked) => {
        if (prevChecked.length === 1) return Object.keys(options);
        return prevChecked.filter((v) => v !== t);
      });
    } else {
      const checkedParams = e?.target?.checked
        ? [...checked, t]
        : checked.filter((item) => item !== t);

      if (checkedParams.length > 0) {
        setChecked(checkedParams);
        setFilter(checkedParams);
      } else {
        setChecked([]);
        setFilter(['all']);
      }
    }

    if (!e.target.checked) {
      setAllChecked(false);
    }

    if (e.target.checked) {
      if (Object.keys(checked).length + 1 === Object.keys(options).length) {
        setAllChecked(true);
      }
    }
  };

  /**
   * showFunction: used to show the location of property in dropDown box
   * @param option: getting an option to compare with the coupa_property_location_code
   * @returns uniqueArray: it returns the array which values are not duplicate
   */
  const showLocation = (option: any) => {
    const array: any = [];
    // if we have an option then we will compare the option with coupa_property_location_code
    if (option) {
      // iterating into the all rows record and comparing option with coupa_property_location_code
      rows.forEach((row: any) => {
        const taxId = row?.original?.coupa_property_location_code;
        if (taxId === option) {
          array.push(row.original.property_location_name);
        }
      });
    }
    // uniqueArray: getting an array with no duplicate values
    const uniqueArray = array.filter(function (item: string, taxId: number) {
      return array.indexOf(item) === taxId;
    });

    return uniqueArray.toString();
  };

  const getPropertyCode = (name: string) => {
    let code = null;
    rows.forEach((row: any) => {
      if (row?.original?.property !== null && name === row?.original?.property?.name) {
        code = row?.original?.property?.code;
      }
    });
    return code;
  };

  /**
   * Handle the element classes via constant __
   */
  const handleDropdownArrowRotation = isComponentVisible ? '-rotate-180' : 'rotate-0';
  const handleDropDwonClasses = !isComponentVisible ? 'bg-white' : 'bg-grey-50';
  const dropdownButtonClasses =
    'w-[full] min-w-[70px] h-6 border border-solid border-grey-100 rounded-md cursor-pointer text-12s text-grey-600 pl-1 pr-2 flex items-center justify-between whitespace-nowrap font-medium';
  const dropdownWrapperClasses =
    'absolute z-10 py-2 bg-white rounded-lg top-[26px] shadow-dropDown mt-[2px]';
  const dropdonUlClasses = 'bg-white max-h-[190px] overflow-y-auto min-w-[170px]';
  const dropdownItemClasses =
    'flex flex-row items-center justify-start w-full py-2 pl-2 font-medium bg-white cursor-pointer whitespace-nowrap text-12s hover:bg-grey-50';

  return (
    <div ref={ref} className="relative flex flex-col w-full mt-0">
      <span
        onClick={() => setIsComponentVisible(!isComponentVisible)}
        className={classNames([handleDropDwonClasses, dropdownButtonClasses])}
      >
        {Header}
        <span className="text-grey-400">
          <Icon
            iconType="DropDown"
            iconColor="inherit"
            additionalClasses={`ml-2 ease-out duration-300 ${handleDropdownArrowRotation}`}
          />
        </span>
      </span>
      {isComponentVisible && (
        <>
          <div className={dropdownWrapperClasses}>
            <ul className={dropdonUlClasses}>
              <li className="relative w-full">
                <label
                  key={'sele-all'}
                  htmlFor={`filter-checkbox-all`}
                  className={classNames([
                    dropdownItemClasses,
                    'text-grey-900',
                    'multiselect-dropdown',
                  ])}
                >
                  All ({preFilteredRows.length})
                  <input
                    type="checkbox"
                    color="primary"
                    id={`filter-checkbox-all`}
                    // size="small"
                    checked={allChecked}
                    name={`filter-checkbox-all`}
                    className="mr-2"
                    onChange={() => {
                      setInitLoad(false);
                      if (!allChecked) {
                        setChecked(Object.keys(options));
                        setFilter([]);
                      } else {
                        setChecked([]);
                        setFilter(['all']);
                      }
                      // setChecked(Object.keys(options));
                      setAllChecked(!allChecked);
                    }}
                  />
                  <span className="tick-icon">
                    <Icon iconType="TickIconWhite" iconColor="white" />
                  </span>
                </label>
              </li>
              {
                /*eslint-disable */
                arrayObject &&
                  arrayObject.map(([option, count], i): JSX.Element | undefined => {
                    // checking the value of option
                    if (option !== 'null null') {
                      return (
                        <>
                          {count !== 0 && (
                            <li key={option + '-' + i} className="relative w-full">
                              <label
                                key={i + option}
                                htmlFor={`filter-checkbox-${count}-${i}`}
                                className={`multiselect-dropdown ${
                                  delinquentPropertyLocationCodes?.includes(getPropertyCode(option))
                                    ? 'text-red-400'
                                    : 'text-grey-900'
                                } ${dropdownItemClasses}`}
                              >
                                {option} ({count}) {showLocation(option)}
                                <input
                                  className="mr-4"
                                  type="checkbox"
                                  // className={classes.checkbox}
                                  color="primary"
                                  id={`filter-checkbox-${count}-${i}`}
                                  // size="small"
                                  name={option}
                                  checked={checked.includes(option) || initLoad}
                                  onChange={(e) => {
                                    onChange(e);
                                  }}
                                  title={`${option} (${count})`}
                                />
                                <span className="tick-icon">
                                  <Icon iconType="TickIconWhite" iconColor="white" />
                                </span>
                              </label>
                            </li>
                          )}
                          {count === 0 && (
                            <li
                              key={option + '-' + i}
                              className="relative w-full opacity-50 pointer-events-none"
                            >
                              <label
                                key={i + option}
                                htmlFor={`filter-checkbox-${count}-${i}`}
                                className={`multiselect-dropdown ${
                                  delinquentPropertyLocationCodes?.includes(getPropertyCode(option))
                                    ? 'text-red-400'
                                    : 'text-grey-900'
                                } ${dropdownItemClasses}`}
                              >
                                {option} ({count}) {showLocation(option)}
                                <input
                                  className="mr-4"
                                  type="checkbox"
                                  // className={classes.checkbox}
                                  color="primary"
                                  id={`filter-checkbox-${count}-${i}`}
                                  // size="small"
                                  name={option}
                                  readOnly
                                  disabled
                                  title={`${option} (${count})`}
                                />
                                <span className="tick-icon">
                                  <Icon iconType="TickIconWhite" iconColor="white" />
                                </span>
                              </label>
                            </li>
                          )}
                        </>
                      );
                    }
                    /*eslint-enable */
                  })
              }
            </ul>
          </div>
        </>
      )}
    </div>
  );
}
