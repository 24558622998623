import Icon from 'components/core/Media/Icon/Icon';
import { FooterNote } from 'components/core/Notes/FooterNote/FooterNote';
import { Typhography } from 'components/core/Typhography/Typhography';
import React from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import {
  useEstablishFinancialConnectionSessionMutation,
  useLinkExternalAccountMutation,
} from '../../../../../services/vendorsApi';

interface BankSetupProps {
  onClick?: (isManual: boolean) => void;
  setUpdateAllList?: any;
  updateAllList?: any;
  onClose?: any;
}

export const BankSetup = ({ onClick, onClose }: BankSetupProps) => {
  const stripe = useStripe();
  const [establishFinancialConnectionSession] = useEstablishFinancialConnectionSessionMutation();
  const [linkExternalAccount] = useLinkExternalAccountMutation();

  const createFinancialConnectionsSession = async () => {
    const connectionResponse: any = await establishFinancialConnectionSession(null);

    try {
      const result: any = await stripe?.collectBankAccountToken({
        clientSecret: connectionResponse?.data?.client_secret,
      });
      if (result.token?.id) {
        await linkExternalAccount({ token: result.token?.id }).then((resp) => {});
      } else {
        //setErrors('Token not fetched');
        console.log('Token not fetched');
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const buttonClasses =
    'mb-4 flex w-full h-16 rounded-lg border border-solid border-frey-100 cursor-pointer items-center px-4 justify-between';
  return (
    <>
      <Typhography component="p" type="h2" color="text-grey-900 mb-6 uppercase">
        {'How do you want to Add Bank Details?'}
      </Typhography>
      <div>
        <Typhography
          component="div"
          type="h2Bold"
          color="text-grey-900"
          additionalClasses={`${buttonClasses} hidden`}
          onClick={createFinancialConnectionsSession}
        >
          <span className="flex items-center text-inherit">
            {'Connect via Bank Login'}{' '}
            <Icon
              iconColor="inherit"
              iconType="TermsIcon"
              iconContainerClasses="ml-2 flex items-center"
            />
          </span>
          <Icon iconColor="inherit" iconType="RightArrow" iconContainerClasses="text-grey-400" />
        </Typhography>
        <Typhography
          component="div"
          type="h2Bold"
          color="text-grey-900"
          additionalClasses={buttonClasses}
          onClick={() => onClick && onClick(true)}
        >
          <span className="text-inherit">{'Set Up Bank Manually'}</span>
          <Icon iconColor="inherit" iconType="RightArrow" iconContainerClasses="text-grey-400" />
        </Typhography>
      </div>
      <div className="absolute bottom-0 left-4 right-4">
        <FooterNote />
      </div>
    </>
  );
};
